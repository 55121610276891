import * as Yup from 'yup';
import { customYupValidators } from '@sb-itops/business-logic/validation/services';

export const saveExpenseSchema = Yup.object().shape({
  expenseDate: Yup.date().required(),
  staffId: customYupValidators.uuid().required(),
  matterId: customYupValidators.uuid().when('showMatterField', {
    is: true,
    then: (matterId) => matterId.required(),
    otherwise: (matterId) => matterId.optional(),
  }),
  taskId: Yup.string().when('utbmsCodesRequiredForMatter', {
    is: true,
    then: (taskId) => taskId.required(),
    otherwise: (taskId) => taskId.optional(),
  }),
  subject: Yup.string().min(1).max(1000).required(),
  quantity: Yup.number().positive().required(),
  priceInCents: Yup.number().integer().required(),
  inputTaxAmountInCents: Yup.number().when('showTaxField', {
    is: true,
    then: (inputTaxAmountInCents) => inputTaxAmountInCents.min(0).required(),
    otherwise: (inputTaxAmountInCents) => inputTaxAmountInCents.nullable(),
  }),
  isTaxInclusive: Yup.boolean().when('showTaxField', {
    is: true,
    then: (isTaxInclusive) => isTaxInclusive.required(),
    otherwise: (isTaxInclusive) => isTaxInclusive.nullable(),
  }),
  isBillable: Yup.boolean().required(),
});
