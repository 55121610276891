import { selectors } from 'web/redux/features/application-state';
import { store } from '@sb-itops/redux';
import { getTrustAccounts, isTrustAccountClosed } from '@sb-billing/redux/bank-account';

import { selectors as commSelectors } from 'web/redux/features/communicate';
import { hasBillingAccess } from 'web/services/user-session-management';

/* global SB_BUILD_VERSION */

angular.module('sb.billing.webapp').controller('sbBillingNavController', function ($scope, $state, sbUserSettingsService, sbLoggerService) {
  'use strict';
  var that = this;

  const log = sbLoggerService.getLogger('sbBillingNavController');

  that.getServerVersion = () => selectors.getServerVersion(store.getState());
  that.SB_BUILD_VERSION = SB_BUILD_VERSION;
  that.savedFilters = {};
  that.saveLeftNavExpanded = saveLeftNavExpanded;
  that.commUnreads = 0;
  that.hasBillingAccess = hasBillingAccess;

  const listeners = [
    $scope.$on('smokeball-data-update-sbUserSettingsService', updateLeftNavExpanded),
    $scope.$on('smokeball-data-update-sbBankAccountService', updateTrustAccounts),
    store.subscribe(() => {
      try {
        const commUnreads = commSelectors.getUnreadConversationCounts(store.getState());
        if (commUnreads) {
          let count = commUnreads.notified;

          if (count === null) {
            count = commUnreads.polled || 0;
          }

          if (count && count > 9) {
            count = "9+";
          } 

          if (that.commUnreads !== count) {
            that.commUnreads = count;
            $scope.$applyAsync();
          }
        }
      } catch (e) {
        log.warn('error fetching communicate unreads')
      }
    }),
  ];

  that.$onDestroy = () => {
    for (let unregister of listeners) {
      unregister();
    }
  };

  updateLeftNavExpanded();
  updateTrustAccounts();

  function updateTrustAccounts() {
    that.trustAccounts = getTrustAccounts().filter((trust) => !isTrustAccountClosed(trust));
  }

  function updateLeftNavExpanded () {
    that.leftNavExpanded = sbUserSettingsService.get().leftNavExpanded;
  }

  function saveLeftNavExpanded () {
    sbUserSettingsService.saveP('leftNavExpanded', that.leftNavExpanded);
  }

  // needed because we can't reset a state when searchOnReload is false
  that.gotoOrReset = function (to) {
    if ($state.current.name !== to) {
      // not at state so let's go
      $state.go(to);
    } else {
      $scope.$broadcast('resetFilters');
      store.dispatch({ type: 'RESET_FILTERS' });
    }
  };
});
