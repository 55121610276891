import { rehydratePersistedStateP } from '@sb-itops/redux';
import { getLogger } from '@sb-itops/fe-logger';
import { envType, getFrontendEnv } from '@sb-itops/environment-config';
import { featureActive, featureData, setFeatureData, enableFeature, disableFeature } from '@sb-itops/feature';

import { sendMetric } from 'web/services/metrics';
import { hackUserIdentity, refreshTokenP } from 'web/services/user-session-management';
import { subscribeToNotifications } from '../subscription-manager';

import { registerReduxReducers } from './register-redux-reducers';
import { authoriseCurrentUserP } from './authorise-current-user';
import { verifyDesktopUserP } from './verify-desktop-user';
import { checkForSubscriptionPaymentMethodUpdate } from './check-for-subscription-payment-method-update';
import { checkStorageQuotasP } from './check-storage-quotas';
import { enableFeaturesViaQueryString } from './enable-features-via-querystring';
import { fetchFirmDetails } from './fetch-firm-details';
import { initialiseAnalytics } from './initialise-analytics';
import { initialiseCommandDispatchListeners } from './initialise-command-dispatch-listeners';
import { initialiseGraphQLCacheP } from './initialise-graphql-cache';
import { initialiseLaunchDarkly } from './initialise-launch-darkly';
import { startCheckingVersionUpdates } from './start-checking-version-updates';
import { initialiseCommunicateUnreadPolling } from './initialise-communicate-unread-polling';
import { initialiseProactiveTokenRefresh } from './initialise-proactive-token-refresh';
import { initialiseLocalisationP } from './initialise-localisation';
import { initialiseGoogleTagManager } from './initialise-google-tag-manager';
import { initialiseChurnZero } from './initialise-churn-zero';
import { initialiseBrainfish } from './initialise-brainfish';
import { initialiseIntercom } from './initialise-intercom';
import { checkNo2faXeroUserP } from './check-no-2fa-xero-user';
import { processPreAuthQueryStrings } from './process-pre-auth-query-strings.js';

const log = getLogger('bootstrapper');
log.setLogLevel('info');

export { checkForOauthFlowRedirects } from './check-for-oauth-flow-redirects';
export { NoTwoFactorXeroUserError } from './check-no-2fa-xero-user';

export const preAuthenticationBootstrapP = async ({ initialiseEntityDatabaseP }) => {
  registerReduxReducers();
  
  processPreAuthQueryStrings();

  await rehydratePersistedStateP();

  // The old smokeballDB indexed db containing entities.
  // This ensures the db is on the correct version and applies any upgrades if necessary.
  await initialiseEntityDatabaseP();

  // Add global debugging functions.
  window.Smokeball = window.Smokeball || {};
  if (getFrontendEnv() !== envType.PRODUCTION) {
    window.Smokeball.hackUserIdentity = hackUserIdentity;
    window.Smokeball.refreshTokenP = refreshTokenP;
    window.Smokeball.featureActive = featureActive;
    window.Smokeball.enableFeature = enableFeature;
    window.Smokeball.disableFeature = disableFeature;
    window.Smokeball.featureData = featureData;
    window.Smokeball.setFeatureData = setFeatureData;
  }

  initialiseBrainfish({ log });
};

// camelCase -> kebab-case and strip out P at the end
const kebabify = (value) =>
  value
    .replace(/([A-Z]+)/g, ',$1')
    .split(',')
    .filter((i) => i && i !== 'P')
    .join('-');
// this can be used if no timers/intervals/polling/subscriptions are used in a bootstrap function
const defaultTeardown = () => null;

let teardowns = null;

export const postAuthenticationBootstrapP = async ({
  loadGenericCacheDataP,
  connectToNotificationServerP,
  createNewFirmEntitiesP,
}) => {
  log.info('Starting post authentication bootstrap');

  if (teardowns !== null) {
    throw new Error('Cannot continue as I have already been bootstrapped and teardown has not been run');
  }
  teardowns = [];

  const startPostAuthTime = window.performance.now();

  log.info('Enabling features');
  enableFeaturesViaQueryString();

  log.info('Checking for payment method update');
  const { skipRouteRedirection, skipAuthorisation } = await checkForSubscriptionPaymentMethodUpdate({
    log: getLogger(`bootstrap-checkForSubscriptionPaymentMethodUpdate`),
    defaultTeardown,
  });

  if (!skipAuthorisation) {  
    log.info('Initialising launch darkly');
    // We make best effort to fetch firm details. In some cases - like expired trials
    // the user will not be able to successfully make calls to graphql. In that case,
    // we still initialise launch darkly and google tag manager, but without the firm details.
    const firmDetails = await fetchFirmDetails({ log });

    // initialise launch darkly here so some initialisations like google tag manager can be feature switched
    const uninitialiseLaunchDarklyP = await initialiseLaunchDarkly({ log: getLogger(`bootstrap-initialiseLaunchDarkly`), firmDetails });
    teardowns.push({ name: 'uninitialiseLaunchDarklyP', teardown: uninitialiseLaunchDarklyP });
    
    const localDevHotReload = getFrontendEnv() === envType.LOCAL && featureActive('BB-10306');

    /* 
      Hey stranger, this has probably changed since the last time you looked at it. Many 
      post-auth-bootstrap-functions created things such as subscriptions and intervals
      which need to be unsubbed/cancelled/teardown on logout. 

      Subsequently your function now should return a cleanup/teardown function that does 
      this work. A function "defaultTeardown" is injected and you can return this function 
      if you don't require any teardown on logout.

      Note that a logger "log" is also injected. Have a look at initialise-analytics.js to see
      how this works in practice. Have a look at initialise-command-dispatch-listeners.js to 
      see how a cleanup works in practice.
    */
    const postAuthBootstrapSteps = [
      { checkNo2faXeroUserP },
      { authoriseCurrentUserP },
      { startCheckingVersionUpdates },
      { verifyDesktopUserP },
      { checkStorageQuotasP },
      { initialiseGoogleTagManager: () => initialiseGoogleTagManager({ log: getLogger(`bootstrap-initialiseGoogleTagManager`), defaultTeardown, firmDetails }) },
      { initialiseAnalytics },
      { initialiseChurnZero },
      { initialiseCommandDispatchListeners },
      { connectToNotificationServerP: () => connectToNotificationServerP(localDevHotReload) },
      { initialiseGraphQLCacheP },
      { initialiseLocalisationP },
      {
        loadGenericCacheDataP: ({ defaultTeardown }) =>
          loadGenericCacheDataP({ skipInitialUpdateBecauseLocalMode: localDevHotReload, defaultTeardown }),
      },
      {
        createNewFirmEntitiesP: ({ defaultTeardown }) =>
          createNewFirmEntitiesP({ skipAwaitBecauseLocalMode: localDevHotReload, defaultTeardown }),
      },
      { initialiseCommunicateUnreadPolling },
      { initialiseProactiveTokenRefresh },
      { initialiseProductIdTokenRefresh: () => subscribeToNotifications({
          notificationIds: ['ProductRegistrationNotifications.ProductIdUpdated'],
          callback: refreshTokenP,
        }),
      },
      { initialiseIntercom },
    ];

    for (const step of postAuthBootstrapSteps) {
      for (const [name, bootstrapFunction] of Object.entries(step)) {
        // people often copy-paste logger and forget to fix the name, so just inject the damned thing
        const loggerName = `bootstrap-${kebabify(name)}`;

        log.info(`running: ${name} with logger: ${loggerName}`);
        const startTs = window.performance.now();

        const result = await bootstrapFunction({
          defaultTeardown,
          log: getLogger(loggerName),
        });

        if (typeof result !== 'function') {
          throw new Error('postAuthBootstrapSteps must return a function which cleans up resources');
        }

        teardowns.push({ name, teardown: result });

        const endTs = window.performance.now();

        log.info(`${name} took: ${Math.floor(endTs - startTs)}ms`);
      }
    }

    const endPostAuthTime = window.performance.now();

    // Metrics tracking is fire and forget, we don't need the client to wait for metrics
    // tracking to complete before showing the customer the app.
    sendMetric('SlowLogin', { postAuthDurationMs: endPostAuthTime - startPostAuthTime });
  }

  log.info('Post authentication bootstrap completed');

  return {
    skipRouteRedirection,
  };
};

export const teardownPostAuthenticationBootstrapP = async () => {
  if (teardowns === null) {
    return false;
  }

  let anyError = false;

  for (const { name, teardown } of teardowns) {
    log.info(`tearing down ${name}`);
    try {
      await teardown();
      log.info(`tear down success of ${name}`);
    } catch (e) {
      log.warn(`tear down failure of ${name}`);
      log.warn(e);
      anyError = true;
    }
  }

  if (anyError) {
    throw new Error('teardownPostAuthenticationBootstrapP has failed and the app now requires a browser refresh');
  } else {
    teardowns = null;
  }
};
