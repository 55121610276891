import * as React from 'react';
import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import { withApolloClient, withReduxProvider } from 'web/react-redux/hocs';
import {
  useCacheQuery,
  useSubscribedQuery,
  useSubscribedLazyQuery,
  useContactTypeaheadData,
  useFirmUtbmsSettings,
} from 'web/hooks';
import {
  ExpenseModalData,
  InitActivityCodes,
  InitFirmTaxSettings,
  InitFirmUtbmsSettings,
  InitOperatingBankAccount,
  InitOperatingChequePrintSettings,
  InitStaffSettings,
  MatterSummaries,
  MatterSummaryData,
  OperatingChequeAvailableNumbers,
} from 'web/graphql/queries';
import { sort as sortItems } from '@sb-itops/sort';
import { debounce } from '@sb-itops/nodash';
import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { EXPENSE_MODAL_ID } from 'web/components';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import { mapActivitiesByCategory, mapTasksByCategory } from '@sb-billing/business-logic/activities/services';
import { entryType as entryTypesEnum, entryTypeLabels } from '@sb-billing/business-logic/shared/entities';
import { facets, hasFacet } from '@sb-itops/region-facets';

import { ExpenseFormsContainer } from './ExpenseModal.forms.container';

const hooks = () => ({
  useExpenseModalProps: () => {
    const onModalClose = () => setModalDialogHidden({ modalId: EXPENSE_MODAL_ID });

    return {
      onModalClose,
    };
  },
  useExpenseModalQuery: ({ expenseId, matterId }) => {
    const isNewExpense = !expenseId;

    // [1] Expense query
    //  * Fetch expense data if it exists
    const {
      data: expenseModalData,
      error: expenseModalDataError,
      loading: loadingExpenseModalData,
    } = useSubscribedQuery(ExpenseModalData, {
      skip: isNewExpense,
      variables: {
        id: expenseId,
      },
    });
    // [2] Operating bank account query
    //  * Required to create operating cheques
    const { data: operatingBankAccountData } = useCacheQuery(InitOperatingBankAccount.query);

    // [3] Matter query
    //  * Expenses can be intrinsically linked to a matter (e.g. expenses on the matter or draft invoice page)
    //  * When creating a new expense, the query to fetch the expense entity is skipped (as it doesn't exist yet). Therefore, we need to query for the matter separately.
    const existingExpense = !!expenseId;
    const newExpenseWithoutLinkToAMatter = !existingExpense && !matterId;

    const {
      data: matterData,
      loading: isLoadingMatterData,
      error: matterDataError,
    } = useSubscribedQuery(MatterSummaryData, {
      skip: existingExpense || newExpenseWithoutLinkToAMatter,
      variables: {
        id: matterId,
      },
    });

    if (expenseModalDataError) {
      throw new Error(expenseModalDataError);
    }

    if (matterDataError) {
      throw new Error(matterDataError);
    }

    return {
      areQueriesLoading: loadingExpenseModalData || isLoadingMatterData,
      expense: expenseModalData?.expense,
      isNewExpense,
      matter: matterData?.matter,
      operatingBankAccountId: operatingBankAccountData.bankAccounts[0]?.id,
    };
  },
  useFirmUtbmsSettings: () => {
    const { isUtbmsEnabledForFirm } = useFirmUtbmsSettings();
    return {
      isUtbmsEnabledForFirm,
    };
  },
  useStaffData: () => {
    const { data: staffData } = useCacheQuery(InitStaffSettings.query);

    const loggedInStaff = staffData?.loggedInStaff;
    const staffMemberOptions = React.useMemo(() => {
      const data = staffData?.staffMembers;

      if (!data) {
        return [];
      }

      const options = data.map((staffMemberEntity) => {
        const { id, initials, name, rate } = staffMemberEntity;

        return {
          value: id,
          label: `${initials} (${name})`,
          entity: {
            ...staffMemberEntity,
            rate: rate || 0,
          },
        };
      });
      const sortedOptions = sortItems(options, ['label'], ['ASC']);

      return sortedOptions;
    }, [staffData?.staffMembers]);

    return {
      loggedInStaff,
      staffMemberOptions,
    };
  },
  useUtbmsData: () => {
    const { data: firmUtbmsSettingsData } = useCacheQuery(InitFirmUtbmsSettings.query);

    const isUtbmsFacetActive = hasFacet(facets.utbms);

    const isUtbmsEnabledForFirm = isUtbmsFacetActive && firmUtbmsSettingsData?.firmUtbmsSettings?.isUtbmsEnabled;
    const areUtbmsCodesRequiredByFirm =
      isUtbmsFacetActive && isUtbmsEnabledForFirm && firmUtbmsSettingsData?.firmUtbmsSettings?.utbmsCodesRequired;

    return {
      areUtbmsCodesRequiredByFirm,
      isUtbmsEnabledForFirm,
    };
  },
  useActivitiesAndTasksData: () => {
    const activityCodesResult = useCacheQuery(InitActivityCodes.query, {
      // The variables must match init query
      variables: {
        includeUtbmsCodes: hasFacet(facets.utbms),
        isUtbmsEnabledCheck: true,
      },
    });
    // [3] Activities
    const activities = React.useMemo(
      () =>
        mapActivitiesByCategory({
          activityCodes: activityCodesResult?.data?.activityCodes,
          utbmsActivityCodes: activityCodesResult?.data?.utbmsActivityCodes,
          filterCustomCodeByTypes: [entryTypesEnum.EXPENSE],
        }),
      [activityCodesResult?.data?.activityCodes, activityCodesResult?.data?.utbmsActivityCodes],
    );

    // [4] Tasks
    const tasks = React.useMemo(
      () =>
        mapTasksByCategory({
          utbmsTaskCodes: activityCodesResult?.data?.utbmsTaskCodes,
          utbmsCustomTaskCodes: activityCodesResult?.data?.utbmsCustomTaskCodes,
          filterCustomCodeByTypes: [entryTypeLabels.EXPENSE],
        }),
      [activityCodesResult?.data?.utbmsTaskCodes, activityCodesResult?.data?.utbmsCustomTaskCodes],
    );

    return {
      activities,
      tasks,
    };
  },
  useTaxData: () => {
    const { data: firmTaxSettingsData } = useCacheQuery(InitFirmTaxSettings.query);
    const { taxRate: taxRateInBasisPoints } = firmTaxSettingsData?.firmTaxSettings || {};

    return {
      taxRateInBasisPoints,
    };
  },
  useOperatingChequePrintData: () => {
    const { data: chequePrintData } = useCacheQuery(InitOperatingChequePrintSettings.query);
    const operatingChequePrintSettings = chequePrintData?.operatingChequePrintSettings?.[0];

    return {
      operatingChequePrintSettings,
    };
  },
  useOperatingChequeNumberData: () => {
    const [getAvailableOperatingChequeNumber, operatingChequeNumberResults] = useSubscribedLazyQuery(
      OperatingChequeAvailableNumbers,
      {
        context: { skipRequestBatching: true },
        variables: {},
      },
    );

    const data = operatingChequeNumberResults.data?.operatingChequeAvailableNumbers;

    const availableOperatingChequeNumber = data?.availableChequeNumbers.length
      ? data.availableChequeNumbers[0]
      : undefined;
    const lastOperatingChequeNumber = data?.lastChequeNumber;

    const onGetAvailableOperatingChequeNumber = debounce(
      ({ operatingChequeNumber }) => {
        getAvailableOperatingChequeNumber({
          variables: {
            filter: {
              chequeNumberFrom: operatingChequeNumber,
              quantity: 1,
            },
          },
        });
      },
      300, // wait in milliseconds
      { leading: false },
    );

    return {
      availableOperatingChequeNumber,
      isLoadingAvailableOperatingChequeNumber: operatingChequeNumberResults.loading,
      lastOperatingChequeNumber,
      onGetAvailableOperatingChequeNumber,
      onClearAvailableChequeNumberCache: operatingChequeNumberResults.onClearListCache,
    };
  },
  useMatterSummariesData: () => {
    const [getMatterSummaries, matterSummariesResult] = useSubscribedLazyQuery(MatterSummaries, {
      context: { skipRequestBatching: true },
      variables: {
        includeMatterHourlyRate: false,
        filter: {
          matterStatus: ['pending', 'open'],
        },
        limit: 25,
        sort: {
          fieldNames: ['statusOpen', 'matterStarted'],
          directions: ['DESC', 'DESC'],
        },
      },
    });

    const results = matterSummariesResult.data?.matterSearch?.results;

    const matterSummaries = React.useMemo(() => {
      const summaries = !results?.length
        ? []
        : results.map((matter) => {
            const typeahead = [
              matter.matterNumber,
              matter.clientDisplay,
              matter.otherSideDisplay,
              matter.matterType?.name,
              matter.attorneyResponsible?.name,
              matter.attorneyResponsible?.initials,
              matter.description,
            ];

            const matterStartedISO = matter.matterStarted ? moment(matter.matterStarted, 'YYYYMMDD').toISOString() : '';

            return {
              ...matter,
              display: getMatterDisplay(matter, matter.matterType?.name),
              matterClientNames: matter.clientNames,
              matterStarted: matter.matterStarted ? new Date(matter.matterStarted) : undefined,
              matterStartedISO,
              typeahead: typeahead.filter((m) => m).join(' '),
            };
          });

      return summaries;
    }, [results]);

    const getMatterSummariesBySearchText = debounce(
      (searchText = '') => {
        getMatterSummaries({
          variables: {
            searchText,
            offset: 0,
          },
        });
      },
      300, // wait in milliseconds
      { leading: false },
    );

    const onFetchMatterSummaries = (searchText = '') => {
      // When the matter typeahead (Select) loses focus, it executes this
      // function with an empty string, returning different results.
      if (searchText.length > 2) {
        getMatterSummariesBySearchText(searchText);
      }

      return searchText;
    };

    const onFetchMoreMatterSummaries = async () => {
      if (!matterSummariesResult.data?.matterSearch?.pageInfo?.hasNextPage) {
        return undefined;
      }

      const fetchMoreResults = await matterSummariesResult.fetchMore({
        variables: {
          offset: matterSummariesResult.data.matterSearch.results.length || 0,
        },
      });

      return fetchMoreResults;
    };

    return {
      matterSummaries,
      matterSummariesDataLoading: matterSummariesResult.loading,
      matterSummariesHasMore: matterSummariesResult.data?.matterSearch?.pageInfo?.hasNextPage || false,
      onFetchMatterSummaries,
      onFetchMoreMatterSummaries,
    };
  },
  useContactTypeaheadData: () => {
    // The "supplier" and "pay to" fields both need their own data/functions from the useContactTypeaheadData hook
    //  * This is to ensure independence
    //  * E.g. Searching/results on one field won't affect the other
    const {
      contactOptions: supplierContactOptions,
      contactOptionsDataLoading: supplierContactOptionsDataLoading,
      contactOptionsHasMore: supplierContactOptionsHasMore,
      onFetchContactOptions: onFetchSupplierContactOptions,
      onFetchMoreContactOptions: onFetchMoreSupplierContactOptions,
    } = useContactTypeaheadData();
    const {
      contactOptions: payToContactOptions,
      contactOptionsDataLoading: payToContactOptionsDataLoading,
      contactOptionsHasMore: payToContactOptionsHasMore,
      onFetchContactOptions: onFetchPayToContactOptions,
      onFetchMoreContactOptions: onFetchMorePayToContactOptions,
    } = useContactTypeaheadData();

    return {
      // Supplier field
      supplierContactOptions,
      supplierContactOptionsDataLoading,
      supplierContactOptionsHasMore,
      onFetchSupplierContactOptions,
      onFetchMoreSupplierContactOptions,
      // Pay To field
      payToContactOptions,
      payToContactOptionsDataLoading,
      payToContactOptionsHasMore,
      onFetchPayToContactOptions,
      onFetchMorePayToContactOptions,
    };
  },
});

const dependentHooks = () => ({});

export const ExpenseModalContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(dependentHooks)(ExpenseFormsContainer))),
);

ExpenseModalContainer.propTypes = {
  sbAsyncOperationsService: PropTypes.object,
  scope: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  onExpenseSave: PropTypes.func,
  onClickLink: PropTypes.func,
};

ExpenseModalContainer.defaultProps = {
  onExpenseSave: undefined,
  onClickLink: undefined,
  // The sbAsyncOperationsService is required for creating operating cheques
  //  * We don't always need this service in cases such as the finalised invoice page (as we cannot create operating cheques there)
  sbAsyncOperationsService: undefined,
};

ExpenseModalContainer.displayName = 'ExpenseModalContainer';
