import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { useSubscribedQuery } from 'web/hooks';
import { CorrespondenceHistoryModalData } from 'web/graphql/queries';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';

import { CorrespondenceHistoryModal } from './CorrespondenceHistoryModal';

export const CORRESPONDENCE_HISTORY_MODAL_ID = 'correspondence-history-modal-id';

const hooks = () => ({
  useModalProps: () => ({
    onModalClose: () => setModalDialogHidden({ modalId: CORRESPONDENCE_HISTORY_MODAL_ID }),
  }),
  useCorrespondenceHistoryData: ({ correspondenceHistoryId }) => {
    const { data, loading, error } = useSubscribedQuery(CorrespondenceHistoryModalData, {
      variables: {
        id: correspondenceHistoryId,
      },
    });

    if (error) {
      throw new Error(error);
    }

    return { correspondenceHistory: data?.correspondenceHistory, isLoading: loading };
  },
});

export const CorrespondenceHistoryModalContainer = withApolloClient(composeHooks(hooks)(CorrespondenceHistoryModal));

CorrespondenceHistoryModalContainer.displayName = 'CorrespondenceHistoryModalContainer';

CorrespondenceHistoryModalContainer.propTypes = {
  correspondenceHistoryId: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
};

CorrespondenceHistoryModalContainer.defaultProps = {};
