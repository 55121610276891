import { cacheFactory, indexTypes, syncTypes } from '@sb-itops/redux';

import domain from '../domain';

const api = cacheFactory({
  domain,
  name: 'invoice-debtor-totals',
  keyPath: 'id',
  ngCacheName: 'sbInvoiceDebtorTotalsService',
  indexes: [{ name: 'byInvoiceId', indexer: (entity) => entity.invoiceId, type: indexTypes.ONE_TO_MANY }],
  syncType: syncTypes.SINCE,
  immutable: false,
});

export const getInvoiceDebtorTotalsByInvoiceId = (invoiceId) => {
  const invoiceDebtorTotalsByInvoiceId = api.getIndex('byInvoiceId');
  return invoiceDebtorTotalsByInvoiceId[invoiceId];
};

export const { getById, getMap, getList, updateCache, clearCache, UPDATE_CACHE, getLastUpdated } = api;
