import * as PropTypes from 'prop-types';
import React from 'react';
import { ScheduleTab, CalendarDataContainer } from 'web/components';

const scope = 'productivity-firm-calendar-route';

export const ProductivityCalendarRouteContainer = ({ matterId, onClickLink }) => {
  const WrappedComponent = CalendarDataContainer(ScheduleTab);
  return <WrappedComponent scope={scope} matterId={matterId} onClickLink={onClickLink} />;
};

ProductivityCalendarRouteContainer.displayName = 'ProductivityCalendarRouteContainer';

ProductivityCalendarRouteContainer.propTypes = {
  matterId: PropTypes.string,
  onClickLink: PropTypes.func,
};

ProductivityCalendarRouteContainer.defaultProps = {
  matterId: undefined,
  onClickLink: () => {},
};
