import { useSingleItemSelection } from '@sb-itops/redux/single-item-select/use-single-item-selection';

const SCOPE = 'IntegrationFailedRequests';

export const useLastFailedTransactionsCount = ({ integration }) => {
  const { selectedItem: lastFailedTransactionsCount, setSelectedItem: setLastFailedTransactionsCount } =
    useSingleItemSelection({
      scope: `${SCOPE}/${integration}/TransactionIssue`,
    });

  return {
    lastFailedTransactionsCount,
    setLastFailedTransactionsCount,
  };
};

export const useLastConnectionIssuesCount = ({ integration }) => {
  const { selectedItem: lastConnectionIssuesCount, setSelectedItem: setLastConnectionIssuesCount } =
    useSingleItemSelection({
      scope: `${SCOPE}/${integration}/ConnectionIssue`,
    });

  return {
    lastConnectionIssuesCount,
    setLastConnectionIssuesCount,
  };
};
