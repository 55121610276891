import { gql } from '@apollo/client';

const query = gql`
  query CorrespondenceHistoryModal($id: ID!) {
    correspondenceHistory(id: $id) {
      id
      bcc
      body
      cc
      from
      internalNote
      lastUpdated
      relatedIds
      sentTimestamp
      status
      to
      user {
        id
        person {
          id
          name
        }
      }
    }
  }
`;

const notificationIds = ['BillingSharedNotifications.CorrespondenceHistoryUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const CorrespondenceHistoryModalData = {
  query,
  notificationIds,
};
