import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { capitalizeAllWords } from '@sb-itops/nodash';
import { LinkableText, Modal, useTranslation } from '@sb-itops/react';
import { InterpolatedDescriptionDisplay } from '@sb-billing/react';
import { localiseTransactionSource } from '@sb-billing/business-logic/transactions/services';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { UserNameDisplay } from 'web/components';

import { DebugEntity } from 'web/react-redux/components/debug-entity';
import { TrustChequePaymentsTable } from 'web/react-redux/components/trust-cheque-payments-table';

import { TrustChequeSplitPaymentsTable } from 'web/react-redux/components/trust-cheque-split-payments-table';
import * as Styles from './TrustChequeDetailsModal.module.scss';

export const TrustChequeDetailsModal = ({
  chequeId,
  amount,
  effectiveDate,
  matterDisplay,
  payorDisplay,
  description,
  bulkDescription,
  source,
  createdBy,
  systemDate,
  reason,
  reversalReason,
  memo,
  isReversed,
  isReversible,
  isBulkTto,
  isSplitVendorPayment,
  isSplitSingleInvoicePayment,
  trustChequePayments,
  isMatterContactBalanceFirm,
  contactsMap,
  invoicesMap,
  bankAccountsMap,
  // other
  isLoading,
  isVisible,
  isReverseCollapsed,
  reasonInputRef,
  showReasonField,
  showBulkTtoReversalWarning,
  showSplitVendorPaymentReversalWarning,
  // form fields, for reason input
  reasonField,
  submitFailed,
  formInitialised,
  // function/callbacks,
  onClickLink,
  onReasonChangedHandler,
  onToggleReversal,
  onClose,
  onProcessReversal,
  onValidateForm,
}) => {
  const { t } = useTranslation();

  const localisedSource = localiseTransactionSource({ source, t });

  const matterSection = (
    <div className={classnames(Styles.columnLeft, Styles.row)}>
      <div className={classnames('col-md-5', Styles.boldLabel)}>Matter</div>
      <div className="col-md-7">{matterDisplay}</div>
    </div>
  );

  const descriptionSection = (
    <div className={classnames(Styles.columnLeft, Styles.row)}>
      <div className={classnames('col-md-5', Styles.boldLabel)}>Description</div>
      <div className="col-md-7">
        <InterpolatedDescriptionDisplay
          onClickLink={onClickLink}
          description={description}
          invoicesMap={invoicesMap}
          bankAccountsMap={bankAccountsMap}
          contactsMap={contactsMap}
          onNavigateAway={onClose}
        />{' '}
      </div>
    </div>
  );
  return (
    <Modal
      className={Styles.trustChequeDetailsModal}
      modalHeaderClassName={Styles.modalHeader}
      modalBodyClassName={Styles.modalBody}
      ignoreModalHeaderLoading
      isVisible={isVisible}
      title={capitalizeAllWords(`${t('cheque')} Details`)}
      isLoading={isLoading || !formInitialised}
      body={
        !isLoading &&
        formInitialised && (
          <div className={Styles.formWrapper}>
            <div className={Styles.trustChequeDetails}>
              <DebugEntity entityCacheName="sbTrustChequeService" entityId={chequeId} />

              <div className={classnames(Styles.columnLeft, Styles.row)}>
                <div className={classnames('col-md-5', Styles.boldLabel)}>Amount</div>
                <div className="col-md-7">
                  <span>{t('cents', { val: amount })}</span>
                </div>
              </div>

              <div className={classnames(Styles.columnRight, Styles.row)}>
                <div className={classnames('col-md-5', Styles.boldLabel)}>Date</div>
                <div className="col-md-7">{t('date', { yyyymmdd: effectiveDate })}</div>
              </div>

              {!isBulkTto && isMatterContactBalanceFirm && (
                <div className={classnames(Styles.columnLeft, Styles.row)}>
                  <div className={classnames('col-md-5', Styles.boldLabel)}>Paid By</div>
                  <div className="col-md-7">{payorDisplay}</div>
                </div>
              )}

              {!isBulkTto && !isMatterContactBalanceFirm && matterSection}

              {isBulkTto && (
                <div className={classnames(Styles.columnLeft, Styles.row)}>
                  <div className={classnames('col-md-5', Styles.boldLabel)}>Description</div>
                  <div className="col-md-7">
                    <InterpolatedDescriptionDisplay
                      onClickLink={onClickLink}
                      description={bulkDescription}
                      invoicesMap={invoicesMap}
                      bankAccountsMap={bankAccountsMap}
                      onNavigateAway={onClose}
                    />
                  </div>
                </div>
              )}

              <div className={classnames(Styles.columnRight, Styles.row)}>
                <div className={classnames('col-md-5', Styles.boldLabel)}>Processed by</div>
                <div className="col-md-7">
                  <UserNameDisplay user={createdBy?.person} inline /> on{' '}
                  {t('date', { ts: systemDate, format: 'YYYY-MM-DD' })} at{' '}
                  <span className="uppercase">{t('date', { ts: systemDate, format: 'hhmmss a' })}</span>
                </div>
              </div>

              {!isBulkTto && !isMatterContactBalanceFirm && descriptionSection}

              {!isBulkTto && isMatterContactBalanceFirm && matterSection}
              {!isBulkTto && isMatterContactBalanceFirm && descriptionSection}

              <div className={classnames(Styles.columnRight, Styles.row)}>
                <div className={classnames('col-md-5', Styles.boldLabel)}>Source</div>
                <div className="capitalise col-md-7">{localisedSource}</div>
              </div>

              {!isReversed && showReasonField && (
                <div className={classnames(Styles.columnLeft, Styles.row)}>
                  <div className={classnames('col-md-5', Styles.boldLabel)}>Reason</div>
                  <div className="col-md-7">{reason}</div>
                </div>
              )}

              {isReversed && (
                <div className={classnames(Styles.columnLeft, Styles.row)}>
                  <div className={classnames('col-md-5', Styles.boldLabel)}>Reason</div>
                  <div className="col-md-7">{reversalReason}</div>
                </div>
              )}

              {hasFacet(facets.chequeMemo) && (
                <div className={classnames(Styles.columnRight, Styles.row)}>
                  <div className={classnames('col-md-5', Styles.boldLabel)}>Check Memo</div>
                  <span className="col-md-7">{memo}</span>
                </div>
              )}

              {(isSplitVendorPayment || isSplitSingleInvoicePayment) && (
                <div className={classnames(Styles.columnBoth, Styles.splitPaymentsTable)}>
                  <TrustChequeSplitPaymentsTable
                    trustChequePayments={trustChequePayments}
                    onClickLink={onClickLink}
                    showPayor={isMatterContactBalanceFirm}
                    showInvoice={isSplitSingleInvoicePayment}
                    onNavigateAway={onClose}
                    source={localisedSource}
                  />
                </div>
              )}

              {isBulkTto && (
                <div className={classnames(Styles.columnBoth, Styles.paymentsTable)}>
                  <TrustChequePaymentsTable
                    trustChequePayments={trustChequePayments}
                    onClickLink={onClickLink}
                    showPayor={isMatterContactBalanceFirm}
                    onNavigateAway={onClose}
                  />
                </div>
              )}

              {isReversible && (
                <div className={Styles.columnBoth}>
                  <div className={classnames('row', Styles.row)}>
                    <div className="col-md-12">
                      <LinkableText
                        text="Reverse"
                        onClickLink={() => onToggleReversal({ isDelete: false })}
                        asLink
                        inline
                        bold
                      />
                      {hasFacet(facets.deleteTransaction) && (
                        <>
                          <span className={Styles.reversalSpacer}>|</span>
                          <LinkableText
                            text="Delete"
                            onClickLink={() => onToggleReversal({ isDelete: true })}
                            asLink
                            inline
                            bold
                          />
                        </>
                      )}
                    </div>
                  </div>

                  {!isReverseCollapsed && (
                    <div>
                      <form name="reversalForm">
                        <div className={classnames('row', Styles.row)}>
                          <div className="col-md-12">
                            <label>Reason</label>
                            <div>
                              <input
                                type="text"
                                name="reason"
                                className={classnames(
                                  'form-control',
                                  (reasonField?.isDirty || submitFailed) && reasonField?.isInvalid && Styles.hasError,
                                )}
                                value={reasonField?.value}
                                onBlur={onValidateForm}
                                onChange={onReasonChangedHandler}
                                ref={reasonInputRef}
                              />
                            </div>
                          </div>
                        </div>

                        {showBulkTtoReversalWarning && (
                          <div className={classnames('row', Styles.row, Styles.warningContainer)}>
                            <div className="col-md-12">
                              <span
                                className={Styles.errorMsg}
                              >{`Warning: This action will reverse all transactions that are a part of the same ${t(
                                'trustToOfficeTransferLabel',
                              )}. The total amount to be reversed is ${t('cents', { val: amount })}.`}</span>
                            </div>
                          </div>
                        )}

                        {(showSplitVendorPaymentReversalWarning || isSplitSingleInvoicePayment) && (
                          <div className={classnames('row', Styles.row, Styles.warningContainer)}>
                            <div className="col-md-12">
                              <span className={Styles.errorMsg}>
                                Warning: This action will reverse the above payments made as part of the same
                                transaction.
                              </span>
                            </div>
                          </div>
                        )}

                        <div className={classnames('row', Styles.row)}>
                          <div className="col-md-4">
                            <button
                              type="button"
                              className={classnames('btn btn-primary', Styles.reverseButtons)}
                              onClick={onProcessReversal}
                            >
                              Process
                            </button>
                          </div>
                          <div className="col-md-4">
                            <button
                              type="button"
                              className={classnames('btn btn-primary', Styles.reverseButtons)}
                              onClick={onClose}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )
      }
      showFooter={false}
      onModalClose={onClose}
    />
  );
};

TrustChequeDetailsModal.displayName = 'TrustChequeDetailsModal';

TrustChequeDetailsModal.propTypes = {
  chequeId: PropTypes.string,
  matterDisplay: PropTypes.string,
  payorDisplay: PropTypes.string,
  amount: PropTypes.number,
  description: PropTypes.string,
  bulkDescription: PropTypes.string,
  effectiveDate: PropTypes.number,
  source: PropTypes.string,
  reason: PropTypes.string,
  createdBy: PropTypes.object,
  systemDate: PropTypes.string,
  reversalReason: PropTypes.string,
  memo: PropTypes.string,
  payments: PropTypes.array,
  isReversed: PropTypes.bool,
  isReversible: PropTypes.bool,
  bankAccountsMap: PropTypes.object,
  invoicesMap: PropTypes.object,
  contactsMap: PropTypes.object,
  isBulkTto: PropTypes.bool,
  isSplitVendorPayment: PropTypes.bool,
  isSplitSingleInvoicePayment: PropTypes.bool,
  trustChequePayments: PropTypes.array,
  isMatterContactBalanceFirm: PropTypes.bool,
  showReasonField: PropTypes.bool,
  showBulkTtoReversalWarning: PropTypes.bool,
  showSplitVendorPaymentReversalWarning: PropTypes.bool,
  // other
  isLoading: PropTypes.bool,
  isVisible: PropTypes.bool,
  isReverseCollapsed: PropTypes.bool,
  isDateReconciled: PropTypes.bool,
  reasonInputRef: PropTypes.object.isRequired,
  reasonField: PropTypes.object,
  submitFailed: PropTypes.bool,
  formInitialised: PropTypes.bool,
  // function/callbacks,
  onClickLink: PropTypes.func,
  onReasonChangedHandler: PropTypes.func.isRequired,
  onToggleReversal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onProcessReversal: PropTypes.func.isRequired,
  onValidateForm: PropTypes.func.isRequired,
};

TrustChequeDetailsModal.defaultProps = {
  chequeId: undefined,
  matterDisplay: undefined,
  payorDisplay: undefined,
  amount: undefined,
  description: undefined,
  bulkDescription: undefined,
  effectiveDate: undefined,
  source: undefined,
  reason: undefined,
  createdBy: undefined,
  systemDate: undefined,
  reversalReason: undefined,
  memo: undefined,
  payments: undefined,
  isMatterContactBalanceFirm: false,
  showReasonField: false,
  showBulkTtoReversalWarning: false,
  showSplitVendorPaymentReversalWarning: false,
  bankAccountsMap: {},
  invoicesMap: {},
  contactsMap: {},
  trustChequePayments: [],
  isBulkTto: false,
  isSplitVendorPayment: false,
  isSplitSingleInvoicePayment: false,
  isReversed: false,
  isReversible: false,
  isLoading: false,
  isVisible: false,
  isDateReconciled: null,
  isReverseCollapsed: true,
  reasonField: undefined,
  submitFailed: false,
  formInitialised: false,
  onClickLink: () => {},
};
