import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { featureActive } from '@sb-itops/feature';
import { useTranslation } from '@sb-itops/react';

const getCheckedValue = (value) => !!value;

const InvoiceAdditionalOptions = memo(
  ({
    showStaffNameOnEntries,
    showRateOnEntries,
    hidePriorBalance,
    hidePaymentSummary,
    showInvoiceSummary,
    showSummaryForTimekeepers,
    showTimekeeperRole,
    showRetainer,
    showDurationOnEntries,
    showAccountSummary,
    showDescriptionForEntries,
    showHoursSummary,
    appendExpenseReceipts,
    hideFeeTotal,
    hideFeeTotalSummary,
    hideDueDate,
    showTransactionHistory,
    hideAmountAndTaxPerLineItem,
    onOptionChanged,
    provideShowRetainerOption,
    hasOpenTrustAccountsForMatter,
    supportsTax,
    isSplitBillingEnabled,
    supportsDisplayExpenseWithFees,
  }) => {
    const { t } = useTranslation();
    const onOptionChangedHandler = useCallback(
      (e) => onOptionChanged(e.target.name, e.target.checked),
      [onOptionChanged],
    );

    const onHideOptionChangedHandler = useCallback(
      (e) => onOptionChanged(e.target.name, !e.target.checked),
      [onOptionChanged],
    );

    return (
      <div className="row invoice-additional-options-collapsible-presets">
        <div className="col-sm-6">
          <label>
            <input
              type="checkbox"
              name="showStaffNameOnEntries"
              checked={getCheckedValue(showStaffNameOnEntries)}
              onChange={onOptionChangedHandler}
            />
            &nbsp;Show staff initials on Time & Fee Entries
          </label>
          <label>
            <input
              type="checkbox"
              name="hidePriorBalance"
              checked={!getCheckedValue(hidePriorBalance)}
              onChange={onHideOptionChangedHandler}
            />
            &nbsp;Show Prior Balances
          </label>
          <label>
            <input
              type="checkbox"
              name="showInvoiceSummary"
              checked={getCheckedValue(showInvoiceSummary)}
              onChange={onOptionChangedHandler}
            />
            &nbsp;Show Invoice Summary
          </label>
          <label>
            <input
              type="checkbox"
              name="showDurationOnEntries"
              checked={getCheckedValue(showDurationOnEntries)}
              onChange={onOptionChangedHandler}
            />
            &nbsp;{supportsDisplayExpenseWithFees ? 'Show Hours/Qty' : 'Show Hours'}
          </label>
          {/* {getCheckedValue(showDurationOnEntries) && ( */}
          <label>
            <input
              type="checkbox"
              name="showHoursSummary"
              checked={getCheckedValue(showHoursSummary)}
              onChange={onOptionChangedHandler}
            />
            &nbsp;{supportsDisplayExpenseWithFees ? 'Show Hours/Qty Summary' : 'Show Hours Summary'}
          </label>
          <label>
            <input
              type="checkbox"
              name="hideFeeTotal"
              checked={!getCheckedValue(hideFeeTotal)}
              onChange={onHideOptionChangedHandler}
            />
            &nbsp;Show Fee Total
          </label>
          <label>
            <input
              type="checkbox"
              name="hideFeeTotalSummary"
              checked={!getCheckedValue(hideFeeTotalSummary)}
              onChange={onHideOptionChangedHandler}
            />
            &nbsp;Show Fee Total Summary
          </label>
          {/* )} */}
          {featureActive('BB-10092') && (
            // This option is in left column when feature flag above is enabled
            // or it is in the right column when disabled
            <label>
              <input
                type="checkbox"
                name="showDescriptionForEntries"
                checked={getCheckedValue(showDescriptionForEntries)}
                onChange={onOptionChangedHandler}
              />
              &nbsp;Show Description for Entries
            </label>
          )}
        </div>
        <div className="col-sm-6">
          {provideShowRetainerOption && (
            <div className="label-icon-container">
              <label>
                <input
                  type="checkbox"
                  name="showRetainer"
                  checked={hasOpenTrustAccountsForMatter ? getCheckedValue(showRetainer) : false}
                  onChange={onOptionChangedHandler}
                  disabled={!hasOpenTrustAccountsForMatter}
                />
                &nbsp;Show Evergreen Retainer Request
              </label>
              {!hasOpenTrustAccountsForMatter && (
                <i
                  className="icon-information"
                  color="lightgrey"
                  title={`Retainer requests require an open ${t(
                    'trustAccount',
                  ).toLowerCase()} available to this matter`}
                />
              )}
            </div>
          )}
          {!featureActive('BB-10092') && (
            <label>
              <input
                type="checkbox"
                name="showDescriptionForEntries"
                checked={getCheckedValue(showDescriptionForEntries)}
                onChange={onOptionChangedHandler}
              />
              &nbsp;Show Description for Entries
            </label>
          )}
          {featureActive('BB-9084') && (
            <label>
              <input
                type="checkbox"
                name="hideDueDate"
                checked={!getCheckedValue(hideDueDate)}
                onChange={onHideOptionChangedHandler}
              />
              &nbsp;Show Due Date
            </label>
          )}
          <label>
            <input
              type="checkbox"
              name="showRateOnEntries"
              checked={getCheckedValue(showRateOnEntries)}
              onChange={onOptionChangedHandler}
            />
            &nbsp;{supportsDisplayExpenseWithFees ? 'Show Rate/Price' : 'Show Rate'}
          </label>
          <label>
            <input
              type="checkbox"
              name="hidePaymentSummary"
              checked={!getCheckedValue(hidePaymentSummary)}
              onChange={onHideOptionChangedHandler}
            />
            &nbsp;Show Payment Summary
          </label>
          <label>
            <input
              type="checkbox"
              name="showSummaryForTimekeepers"
              checked={getCheckedValue(showSummaryForTimekeepers)}
              onChange={onOptionChangedHandler}
            />
            &nbsp;Show Professional Fee Summary
          </label>
          {getCheckedValue(showSummaryForTimekeepers) && featureActive('BB-10855') && (
            <label>
              <input
                type="checkbox"
                name="showTimekeeperRole"
                checked={getCheckedValue(showTimekeeperRole)}
                onChange={onOptionChangedHandler}
              />
              &nbsp;Show Timekeeper Role in Professional Fee Summary
            </label>
          )}
          <label>
            <input
              type="checkbox"
              name="showAccountSummary"
              checked={getCheckedValue(showAccountSummary)}
              onChange={onOptionChangedHandler}
              disabled={isSplitBillingEnabled}
            />
            &nbsp;Show Account Summary
          </label>
          {getCheckedValue(showAccountSummary) && (
            <label>
              <input
                type="checkbox"
                name="showTransactionHistory"
                checked={getCheckedValue(showTransactionHistory)}
                onChange={onOptionChangedHandler}
              />
              &nbsp;Show Transaction History for each Account
            </label>
          )}
          {featureActive('BB-10356') && supportsTax && (
            <div className="label-icon-container">
              <label>
                <input
                  type="checkbox"
                  name="hideAmountAndTaxPerLineItem"
                  checked={!getCheckedValue(hideAmountAndTaxPerLineItem)}
                  onChange={onHideOptionChangedHandler}
                  disabled={getCheckedValue(hideFeeTotal)}
                />
                &nbsp;Show Amount and Tax Per Line Item
              </label>
              {getCheckedValue(hideFeeTotal) && (
                <i
                  className="icon-information"
                  color="lightgrey"
                  title='To edit this option, please first tick the "Show Fee Total" option above.'
                />
              )}
            </div>
          )}
          {featureActive('BB-10092') && (
            <label>
              <input
                type="checkbox"
                name="appendExpenseReceipts"
                checked={getCheckedValue(appendExpenseReceipts)}
                onChange={onOptionChangedHandler}
              />
              &nbsp;Append {t('capitalizeAllWords', { val: 'expense' })} Receipts to PDF Invoice
            </label>
          )}
        </div>
      </div>
    );
  },
);

InvoiceAdditionalOptions.propTypes = {
  onOptionChanged: PropTypes.func.isRequired,
  showStaffNameOnEntries: PropTypes.bool,
  showRateOnEntries: PropTypes.bool,
  hidePriorBalance: PropTypes.bool,
  hidePaymentSummary: PropTypes.bool,
  showInvoiceSummary: PropTypes.bool,
  showSummaryForTimekeepers: PropTypes.bool,
  showTimekeeperRole: PropTypes.bool,
  showRetainer: PropTypes.bool,
  showDurationOnEntries: PropTypes.bool,
  showAccountSummary: PropTypes.bool,
  showDescriptionForEntries: PropTypes.bool,
  showHoursSummary: PropTypes.bool,
  appendExpenseReceipts: PropTypes.bool,
  hideFeeTotal: PropTypes.bool,
  hideFeeTotalSummary: PropTypes.bool,
  hideDueDate: PropTypes.bool,
  showTransactionHistory: PropTypes.bool,
  provideShowRetainerOption: PropTypes.bool,
  hasOpenTrustAccountsForMatter: PropTypes.bool,
  hideAmountAndTaxPerLineItem: PropTypes.bool,
  supportsTax: PropTypes.bool.isRequired,
  isSplitBillingEnabled: PropTypes.bool,
  supportsDisplayExpenseWithFees: PropTypes.bool.isRequired,
};

InvoiceAdditionalOptions.defaultProps = {
  showStaffNameOnEntries: false,
  showRateOnEntries: false,
  hidePriorBalance: false,
  hidePaymentSummary: false,
  showInvoiceSummary: false,
  showSummaryForTimekeepers: false,
  showTimekeeperRole: false,
  showRetainer: false,
  showDurationOnEntries: false,
  showAccountSummary: false,
  showDescriptionForEntries: false,
  showHoursSummary: false,
  appendExpenseReceipts: false,
  hideFeeTotal: false,
  hideFeeTotalSummary: false,
  hideDueDate: false,
  showTransactionHistory: false,
  provideShowRetainerOption: false,
  hasOpenTrustAccountsForMatter: false,
  hideAmountAndTaxPerLineItem: false,
  isSplitBillingEnabled: false,
};

export default InvoiceAdditionalOptions;
