'use strict';
import { featureActive } from '@sb-itops/feature';
/*
This component will download and render a pdf invoice based on the invoice version id. For existing invoices this is simply a case of loading the invoice from the cache
and checking it's current version id. For voided invoices there is a workaround where we need to use the previous version id.

In the case of newly created invoices, the version id of the optimistically cached invoice may not be the final version used for the pdf. This is because 1 command
may trigger many updates, each with its' own version id. A notification will eventually be sent with the version id of the pdf to download. This should all happen
within a couple of seconds, but may take longer.

To improve the user experience we show a spinner while will attempt to download the current version id and, if it fails, we continue to show the spinner in case a
notification is pending.
*/
angular.module('sb.billing.webapp').component('sbBillPdf', {
  templateUrl: 'ng-components/bill-pdf/bill-pdf.html',
  // TODO this checkId should be renamed to chequeId to match how we call it everywhere
  bindings: { invoiceId: '<', debtorId: '<?', checkId: '<?', bankAccountId: '<?' },
  controller: function ($q, $rootScope, $scope, $http, $timeout, sbLoggerService, sbInvoicingService, sbGenericEndpointService, sbMessageDisplayService, sbDispatcherService, sbLocalisationService) {
    const that = this;
    const log = sbLoggerService.getLogger('sbBillPdf');
    const PDFSyncNotificationId = 'PDFSyncNotification';
    const WARNING_MESSAGE_GROUP_NAME = 'INVOICE_WARNING';
    const RETRY_SOFT_LIMIT = 5;
    const RETRY_PERIOD = 1000;
    const NOTIFICATION_TIMEOUT = 20000;

    let unregisterDataUpdate = _.noop;
    let unregisterModalOpen = _.noop;
    let unregisterPaymentDataUpdate = _.noop;

    // log.setLogLevel('info');
    that.t = sbLocalisationService.t;
    that.downloadPresignedUrlCanceller = _.noop;
    that.isReloading = false;
    that.waitForNotification = false;
    that.$actionData = {};

    that.$onInit = () => {
      // Setup listner for cache changes which will trigger a reload if a new version of the invoice has been found.
      unregisterDataUpdate = $rootScope.$on('smokeball-data-update-sbInvoicingService', updateInvoiceVersionId);
      unregisterPaymentDataUpdate = $rootScope.$on('smokeball-data-update-sbPaymentService', updateInvoiceVersionId);

      // Setup handle for notification indicating that new version of an invoice PDF is ready for download.
      that.who = `sbBillPdf:${that.invoiceId}:${$scope.$id}`;
      sbDispatcherService.registerSyncAction(
        PDFSyncNotificationId,
        that.who,
        onPdfReadyNotification,
        message => message.invoiceId === that.invoiceId
      );

      updateInvoiceVersionId();
      printChecks();
    };

    that.$onDestroy = () => {
      unregisterDataUpdate();
      unregisterModalOpen();
      unregisterPaymentDataUpdate();
      sbDispatcherService.unregisterSyncAction(PDFSyncNotificationId, that.who);
      sbMessageDisplayService.dismissGroup(WARNING_MESSAGE_GROUP_NAME);
      $timeout.cancel(that.retryPdfPromise);
      $timeout.cancel(that.waitForNotificationTimeout);
      sbGenericEndpointService.cancelRequest(that.downloadPromise);
      that.downloadPresignedUrlCanceller();
    };

    function printChecks() {
      if (that.checkId) {
        // need to be sure that template has been compiled before running this
        $timeout(() => that.printCheques({ data: { chequeId: that.checkId, bankAccountId: that.bankAccountId } }));
      }
    }

    function updateInvoiceVersionId() {
      const invoice = sbInvoicingService.getInvoice(that.invoiceId);

      // if there is no invoice (something went wrong) OR its an original invoice & the PDF has already loaded:
      // dont load the invoice again
      if (!invoice || (featureActive('BB-1761') && invoice.isOriginalInvoice && that.pdfDataUrl)) {
        return;
      }
      const newInvoiceVersionId = getNewVersionId(invoice);

      if (newInvoiceVersionId && that.currentInvoiceVersionId !== newInvoiceVersionId) {
        log.info(`New version found for invoice '${that.invoiceId}', new versionId: '${newInvoiceVersionId}', old versionId: '${that.currentInvoiceVersionId}'`);
        refreshPdf(newInvoiceVersionId);
      }
    }

    function getNewVersionId (invoice) {
      if (invoice.isOriginalInvoice) {
        return sbInvoicingService.getOriginalInvoiceVersionId(invoice.invoiceId);
      }

      const isInvoiceVoided = sbInvoicingService.isInvoiceVoided(invoice.invoiceId);
      if (isInvoiceVoided) {
        return sbInvoicingService.getClassicInvoiceVersionId(invoice.invoiceId);
      }

      return invoice.versionId;
    }

    function onPdfReadyNotification(message) {
      log.info(`Received ${PDFSyncNotificationId}: ${JSON.stringify(message)}`);

      // If the notification does not match the last version id we sourced from the cache then;
      // it means one of the following two things;
      // 1) We have synced a more recent version ID, in which case we can ignore the notification
      // 2) The PDF Ready Notification beat our cache update, in which case the cache update will trigger a reload, in which case we can ignore the notification.
      if (message.payload.versionId !== that.currentInvoiceVersionId) {
        log.info(`Ignoring ${PDFSyncNotificationId} for mistmatched versions, notification versionId: '${message.payload.versionId}', current versionId: '${that.currentInvoiceVersionId}'`);
        return;
      }

      that.waitForNotification = false;
      refreshPdf(that.currentInvoiceVersionId);
    }

    function refreshPdf(versionId, retryCount = 0) {
      that.isReloading = true;

      // If a download is in progress, shitcan it in flight.
      if (that.downloadPromise) {
        log.info(`Cancelling in flight http request to download out of date invoice version ID '${that.currentInvoiceVersionId}'`);
        sbGenericEndpointService.cancelRequest(that.downloadPromise);
        // If we are downloading PDF from presigned url, we want to cancel it as well
        that.downloadPresignedUrlCanceller();
      }

      if (that.retryPdfPromise && retryCount === 0) {
        log.info(`Cancelling retry request to download out of date invoice version ID '${that.currentInvoiceVersionId}'`);
        $timeout.cancel(that.retryPdfPromise);
      }

      that.currentInvoiceVersionId = versionId;

      // We don't want to start invoice download if we are waiting for notification
      if (that.waitForNotification) {
        return;
      }

      // The next two lines might look weird, but please do not change them unless you understand the implications.
      // The promise returned by downloadPdfP contains the properties required to cancel the request.
      // As soon as we call .then() a new promise is returned without those properties. Be cool.
      that.downloadPromise = downloadPdfP(that.currentInvoiceVersionId);
      that.downloadPromise
        .then(async (payload) => {
          const canceller = $q.defer();
          that.downloadPresignedUrlCanceller = () => canceller.resolve();

          const request = {
            method: 'GET',
            url: payload.preSignedUrl,
            responseType: 'arraybuffer',
            timeout: canceller.promise
          };

          log.info(`Attempting to download invoiceId: '${that.invoiceId}', versionId '${versionId}'`);
          const pdfResponse = await $http(request);
          const pdfDataBlob = new Blob([pdfResponse.data], { type: 'application/pdf' });

          log.info(`Loaded PDF data for invoice version ID '${versionId}'`);
          that.pdfDataUrl = URL.createObjectURL(pdfDataBlob);
          that.isReloading = false;
          that.retryPdfPromise = null;

          sbMessageDisplayService.dismissGroup(WARNING_MESSAGE_GROUP_NAME);
        })
        .catch(err => {
          if (err && err.status === 404 && !that.waitForNotification) {
            that.waitForNotification = true;
            log.info(`Invoice not found, waiting for ${PDFSyncNotificationId} notification`);
            that.waitForNotificationTimeout = $timeout(()=> {
              // We need timeout in case we are waiting for notification and it never comes or 
              // it comes with different (mismatch) Id, see onPdfReadyNotification
              if (that.waitForNotification) {
                log.info(`Cancel waiting for notification and refresh PDF`);
                that.waitForNotification = false;
                refreshPdf(that.currentInvoiceVersionId);
              }
            }, NOTIFICATION_TIMEOUT);
            
            return;
          }
          // Check if this error is a result of the download PDF request 
          if (err && err.status === -1) {
            log.info(`Request to download out of date invoice version ID '${versionId}' was successfully cancelled`);
            return;
          }

          // Once we have hit the soft limit, tell the user that we are still trying to load it
          if (++retryCount === RETRY_SOFT_LIMIT) {
            log.error(`Failed to fetch invoice pdf for invoiceId: '${that.invoiceId}', versionId: '${versionId}'`, err);
            sbMessageDisplayService.warn(
              sbMessageDisplayService
              .builder()
              .text('Downloading your invoice is taking longer than expected. We are still working hard to retrieve your document.')
              .group(WARNING_MESSAGE_GROUP_NAME)
            );
          }

          log.info(`Retry attempt to download invoiceId: '${that.invoiceId}', versionId: '${versionId}' will occur in ${RETRY_PERIOD * retryCount}ms`);
          that.retryPdfPromise = $timeout(() => refreshPdf(versionId, retryCount), RETRY_PERIOD * retryCount);
        })
        .finally(() => {
          that.downloadPromise = null;
          that.downloadPresignedUrlCanceller = _.noop;
        });
    }

    function downloadPdfP(versionId) {
      const payloadConfig = {
        skipCamelCase: true,
      };
      const namespace = `billing/invoice-pdf`;
      const additional = `${versionId}/presigned-url`;

      log.info(`Attempting to get presigned url invoiceId: '${that.invoiceId}', versionId '${versionId}'`);
      return sbGenericEndpointService.getPayloadP(namespace, additional, payloadConfig);
    }

  }
});
