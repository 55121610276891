'use strict';

/**
 * LOD-compatible version of paymentService.isReversiblePayment
 * @param {object} params.payment
 * @returns {boolean}
 */
function isInvoicePaymentReversible({ payment }) {
  if (!payment || !payment.invoices) {
    throw new Error('Payment or invoices on payment is missing, have you fetched this from your query?');
  }
  return !payment.reversedAt && payment.invoices.every((invoice) => !invoice.waived);
}

module.exports = {
  isInvoicePaymentReversible,
};
