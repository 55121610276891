'use strict';

// We should use AU english (cheque, eCheque) but these values are used as `source` on Payment entity so can't be changed now
const PAYMENT_SOURCE = Object.freeze({
  check: 'Check',
  bankTransfer: 'Bank Transfer',
  cash: 'Cash',
  creditCard: 'Credit Card',
  eCheck: 'eCheck',
});

module.exports = {
  PAYMENT_SOURCE,
};
