import React from 'react';
import PropTypes from 'prop-types';
import { Button, CardContainer, FloatingCard, StatusPill, LinkableText, Spinner } from '@sb-itops/react';
import { integrations } from '@sb-billing/business-logic/integration-history/entities';
import classnames from 'classnames';
import { MessageConnection, MessageTransaction, MessageSyncInProgress } from './messages';
import Styles from './IntegrationAccountingSettingsHeader.module.scss';

export const IntegrationAccountingSettingsHeader = ({
  isConnected,
  onConnectClick,
  integrationCfg,
  organisationName,
  integrationLoading,
  hasConnectionIssue,
  syncInProgress,
  onReconnectClick,
  onSyncClick,
  onIgnoreClick,
  failedRequestCount,
}) => {
  if (!integrationCfg) {
    return null;
  }

  return (
    <div className={Styles.integrationAccountingSettingsHeaderContainer}>
      {hasConnectionIssue && (
        <MessageConnection onReconnectClick={onReconnectClick} integrationName={integrationCfg.name} />
      )}
      {!hasConnectionIssue && !syncInProgress && !!failedRequestCount && (
        <MessageTransaction
          onSyncClick={onSyncClick}
          onIgnoreClick={onIgnoreClick}
          transactionCount={failedRequestCount}
          integrationName={integrationCfg.name}
        />
      )}
      {!hasConnectionIssue && syncInProgress && <MessageSyncInProgress />}
      <div className={Styles.settingsPanelContainer}>
        <CardContainer>
          <FloatingCard applyMargin={false}>
            <div className={Styles.settingsPanelCard}>
              <div className={Styles.row}>
                <img alt={`${integrationCfg.name} logo`} src={integrationCfg.logoPath} />
                {integrationLoading && <Spinner small />}
                {!integrationLoading && isConnected && (
                  <>
                    <div className={Styles.connectionEstablishedLabel}>
                      {`Connection established${organisationName?.length > 0 ? `: ${organisationName}` : ''}`}
                    </div>
                    <StatusPill statusLabel="ACTIVE" className={Styles.pillActive} />
                  </>
                )}
                {!integrationLoading && !isConnected && (
                  <Button onClick={onConnectClick}>Connect to {integrationCfg.name}</Button>
                )}
              </div>
              {integrationCfg.type === integrations.MYOB && !isConnected && !integrationLoading && (
                <div className={classnames(Styles.row, Styles.legacyConnection)}>
                  For the older MYOB Essentials v1&nbsp;
                  <LinkableText text="click here" onClickLink={() => onConnectClick({ legacy: true })} asLink />.
                </div>
              )}
            </div>
          </FloatingCard>
        </CardContainer>
      </div>
    </div>
  );
};

IntegrationAccountingSettingsHeader.displayName = 'IntegrationAccountingSettingsHeader';

IntegrationAccountingSettingsHeader.propTypes = {
  integrationLoading: PropTypes.bool.isRequired,
  isConnected: PropTypes.bool.isRequired,
  onConnectClick: PropTypes.func.isRequired,
  organisationName: PropTypes.string,
  integrationCfg: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    logoPath: PropTypes.string.isRequired,
  }).isRequired,
  hasConnectionIssue: PropTypes.bool.isRequired,
  syncInProgress: PropTypes.bool.isRequired,
  onReconnectClick: PropTypes.func.isRequired,
  onSyncClick: PropTypes.func.isRequired,
  onIgnoreClick: PropTypes.func.isRequired,
  failedRequestCount: PropTypes.number.isRequired,
};
IntegrationAccountingSettingsHeader.defaultProps = {
  organisationName: undefined,
};
