import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { entryType } from '@sb-billing/business-logic/shared/entities';
import { isAutoTimeEntry } from '@sb-billing/business-logic/shared/services';
import { featureActive } from '@sb-itops/feature';
import { Checkbox, CurrencyInput2, useTranslation } from '@sb-itops/react';
import { Icon } from '@sb-itops/react/icon';
import { Table, Column, utils } from '@sb-itops/react/table';

const { amountCellLocalisedRendererWithWaived } = utils;

const isRowExpense = ({ rowData, isExpenseMode }) => isExpenseMode || (!isExpenseMode && rowData.displayWithFees);

const isPaidExpense = (rowData) => {
  const isExpensePaid = featureActive('BB-9573') && rowData ? !!rowData?.expensePaymentDetails?.isPaid : false;
  return isExpensePaid;
};

function gripCellRenderer({ rowRef }) {
  return ({ rowIndex }) => (
    <div className="icon-alignment">
      <i
        className={classnames('drag-element', 'icon', 'icon-grab-handle')}
        onMouseDown={() => {
          // Required to prevent other children triggering drag events
          rowRef.current[rowIndex].setAttribute('draggable', 'true');
        }}
        onMouseUp={() => {
          // Required to prevent other children triggering drag events
          rowRef.current[rowIndex].setAttribute('draggable', 'false');
        }}
      />
    </div>
  );
}

function expandHeaderCellRenderer({ expandAll, allRowsExpanded }) {
  return () => (
    <button
      aria-label={allRowsExpanded ? 'Collapse all' : 'Expand all'}
      className={classnames(
        'icon-alignment action-button expand-button',
        allRowsExpanded ? 'icon-arrow-60' : 'icon-arrow-58',
      )}
      onClick={expandAll}
      type="button"
    />
  );
}

function expandCellRenderer({ expandRow, expanded }) {
  return ({ rowData, rowIndex }) => (
    <button
      aria-label={expanded[rowData.id] ? 'Collapse' : 'Expand'}
      className={classnames(
        'icon-alignment action-button expand-button',
        expanded[rowData.id] ? 'icon-arrow-58' : 'icon-arrow-60',
      )}
      disabled={!rowData.notes && !expanded[rowData.id]}
      onClick={() => expandRow(rowData, rowIndex)}
      type="button"
    />
  );
}

function selectHeaderCellRenderer({ props }) {
  const selectedEntriesMap = (props.selectedFeeOrExpenseIds || []).reduce((acc, id) => {
    acc[id] = true;
    return acc;
  }, {});

  const allSelected =
    !!props.feesOrExpenses.length && !props.feesOrExpenses.some((item) => !selectedEntriesMap[item.id]);

  return () => (
    <label className="icon-alignment pointer">
      <Checkbox
        checked={allSelected}
        onChange={() => {
          // We only wish to select visible items as historically that is how this has functioned.
          // Since there may be other items selected we must preserve the contents of selectedFeeOrExpenseIds
          const visibleItemsHash = Object.fromEntries(
            props.feesOrExpenses.map((entry) => [entry.id, !!selectedEntriesMap[entry.id]]),
          );
          if (allSelected) {
            // Remove visible items from selection
            props.onSelect(props.selectedFeeOrExpenseIds.filter((id) => !visibleItemsHash[id]));
          } else {
            // Add visible items to selection, preventing duplicates
            const newSelectedItems = [...props.selectedFeeOrExpenseIds];
            Object.keys(visibleItemsHash).forEach((item) => {
              if (!visibleItemsHash[item]) {
                newSelectedItems.push(item);
              }
            });
            props.onSelect(newSelectedItems);
          }
        }}
      />
    </label>
  );
}

function taxHeaderCellRenderer({ props, t }) {
  return () =>
    featureActive('BB-12987') && props.isExpenseMode ? (
      <span className="header-label" title={`Output ${t('tax')} to be included on invoice`}>
        {t('tax')}
      </span>
    ) : (
      <span className="header-label">{t('tax')}</span>
    );
}

function selectCellRenderer({ props }) {
  return ({ rowData }) => (
    <label className="icon-alignment pointer">
      <Checkbox
        checked={props.selectedFeeOrExpenseIds.includes(rowData.id)}
        onChange={() => {
          const newSelectedFeeOrExpenses = [...props.selectedFeeOrExpenseIds];
          const index = newSelectedFeeOrExpenses.indexOf(rowData.id);
          if (index !== -1) {
            newSelectedFeeOrExpenses.splice(index, 1);
          } else {
            newSelectedFeeOrExpenses.push(rowData.id);
          }
          props.onSelect(newSelectedFeeOrExpenses);
        }}
      />
    </label>
  );
}

function effectiveDateCellRenderer({ t }) {
  return ({ rowData }) => (
    <div className="match-display-padding">{t('date', { yyyymmdd: rowData?.feeDate || rowData.expenseDate })}</div>
  );
}

function descriptionCellRenderer({ props, expanded, t }) {
  return ({ rowData }) => {
    let showWarningIcon = false;
    if (featureActive('BB-9573') && rowData?.isAnticipated) {
      showWarningIcon = !rowData.expensePaymentDetails.isPaid;
    }

    return (
      <div className="subject-description-wrapper">
        <div className="subject-description-container">
          <div>
            <div className="hide-on-focus">{rowData.description}</div>
            <input
              className="autosave-input form-control show-on-focus"
              type="text"
              value={rowData.description}
              onChange={(e) => props.onChange({ field: 'description', value: e.target.value, currentItem: rowData })}
            />
          </div>
          <div>
            {expanded[rowData.id] &&
              (isAutoTimeEntry(rowData) ? (
                <div className="read-only-notes match-display-padding">{rowData.notes}</div>
              ) : (
                <>
                  <div className="hide-on-focus read-only-notes">{rowData.notes}</div>
                  <textarea
                    className="form-control autosave-input show-on-focus"
                    rows="5"
                    value={rowData.notes || ''}
                    onChange={(e) => props.onChange({ field: 'notes', value: e.target.value, currentItem: rowData })}
                  />
                </>
              ))}
          </div>
        </div>
        {showWarningIcon && (
          <Icon
            type="alert-1"
            tooltip={`This anticipated ${t('expense')} has not yet been paid to the supplier`}
            color="orange"
            className="anticipated-disbursement-warning-icon"
          />
        )}
      </div>
    );
  };
}

function staffInitialsCellRenderer({ props }) {
  return ({ rowData }) => {
    const isExpense = isRowExpense({ rowData, isExpenseMode: props.isExpenseMode });
    const cellData = isExpense ? rowData.expenseEarnerStaff : rowData.feeEarnerStaff;

    if (!cellData || !cellData.initials) return undefined;
    return <div className="match-display-padding">{cellData.initials}</div>;
  };
}

function durationQuantityCellRenderer({ props }) {
  return ({ rowData }) => {
    if (rowData.feeType === entryType.FIXED) {
      return <div className="match-display-padding">Fixed</div>;
    }

    let value;

    if (rowData.feeType === entryType.TIME) {
      // Matches FeeTable display format, except we don't show trailing 0's
      value = rowData.duration === '' ? '' : Number((rowData.duration / 60).toFixed(5));
    } else {
      // Matches ExpenseTable display format, except we don't show trailing 0's
      value = rowData.quantity === '' ? '' : Number((Math.abs(rowData.quantity) / 100).toFixed(2));
    }

    if (
      isAutoTimeEntry(rowData) ||
      (isRowExpense({ rowData, isExpenseMode: props.isExpenseMode }) &&
        (rowData.operatingCheque?.id || isPaidExpense(rowData)))
    ) {
      return <div className="match-display-padding">{value}</div>;
    }

    return (
      <>
        <div className="hide-on-focus">{value}</div>
        <input
          className="autosave-input form-control show-on-focus"
          type="number"
          value={value}
          onFocus={(e) => e.target.select()}
          onBlur={(e) => {
            if (e.target.value === '') {
              props.onChange({
                field: rowData.feeType === entryType.TIME ? 'duration' : 'quantity',
                value: 0,
                currentItem: rowData,
              });
            }
          }}
          onChange={(e) => {
            if (rowData.feeType === entryType.TIME) {
              props.onChange({
                field: 'duration',
                value: e.target.value === '' ? '' : Number(Math.max(e.target.value * 60, 0).toFixed(0)),
                currentItem: rowData,
              });
            } else {
              props.onChange({
                field: 'quantity',
                value: e.target.value === '' ? '' : Number(Math.max(e.target.value * 100, 0).toFixed(0)),
                currentItem: rowData,
              });
            }
          }}
        />
      </>
    );
  };
}

function ratePriceCellRenderer({ props, t }) {
  return ({ rowData }) => {
    const isExpense = isRowExpense({ rowData, isExpenseMode: props.isExpenseMode });
    const value = isExpense ? t('cents', { val: rowData.price }) : t('cents', { val: rowData.rate });
    if (isExpense && (rowData.operatingCheque?.id || isPaidExpense(rowData))) {
      return <div className="right-align">{value}</div>;
    }
    return (
      <>
        <div className="hide-on-focus currency">{value}</div>
        <CurrencyInput2
          className="autosave-input show-on-focus"
          hideDollar
          min={0}
          value={isExpense ? rowData.price : rowData.rate}
          onChange={(e) =>
            props.onChange({
              field: isExpense ? 'price' : 'rate',
              value: e.target.value,
              currentItem: rowData,
            })
          }
        />
      </>
    );
  };
}

function taxCellRenderer({ props, t }) {
  return ({ rowData }) => {
    const isExpense = isRowExpense({ rowData, isExpenseMode: props.isExpenseMode });
    if (
      !isExpense ||
      (isExpense && (rowData.operatingCheque?.id || isPaidExpense(rowData) || featureActive('BB-12987')))
    ) {
      return <div className="right-align">{t('cents', { val: rowData.billableTax })}</div>;
    }
    return (
      <>
        <div className="hide-on-focus currency">{t('cents', { val: rowData.billableTax })}</div>
        <CurrencyInput2
          className="autosave-input show-on-focus"
          hideDollar
          value={rowData.billableTax}
          min={0}
          onChange={(e) => {
            props.onChange({ field: 'isTaxOverridden', value: true, currentItem: rowData });
            props.onChange({ field: 'tax', value: e.target.value, currentItem: rowData });
          }}
        />
      </>
    );
  };
}

function writeOffCheckboxCellRenderer({ props }) {
  return ({ rowData }) => (
    <label className="icon-alignment pointer">
      <Checkbox
        checked={rowData.waived}
        disabled={!(rowData.isBillable || rowData.isBillable === null)}
        onChange={(checked) => props.onChange({ field: 'waived', value: checked, currentItem: rowData })}
      />
    </label>
  );
}

function billableCheckboxCellRenderer({ props }) {
  return ({ rowData }) => (
    <label className="icon-alignment pointer">
      <Checkbox
        checked={rowData.isBillable || rowData.isBillable === null}
        onChange={(checked) => {
          props.onChange({ field: 'isBillable', value: checked, currentItem: rowData });

          if (isAutoTimeEntry(rowData)) {
            props.onChange({
              field: 'sourceItems',
              value: rowData.sourceItems.map((item) => ({ ...item, billable: checked })),
              currentItem: rowData,
            });
          }
        }}
      />
    </label>
  );
}

function viewCellRenderer({ props, modalLoading, setModalLoading }) {
  return ({ rowData }) => (
    <button
      aria-label="View details"
      className="icon-alignment action-button"
      onClick={async () => {
        // The openModal function is a promise that resolves when the modal is open.
        // If we have pending inline edits, we need to save them before opening the modal so we can provide the latest version for editing.
        // We would like to prevent the user opening the same modal twice and show a spinner while they are waiting
        setModalLoading({ ...modalLoading, [rowData.id]: true });
        await props.openModal({
          entry: rowData,
          entryType: isRowExpense({ rowData, isExpenseMode: props.isExpenseMode }) ? 'EXPENSE' : 'FEE',
        });
        setModalLoading({ ...modalLoading, [rowData.id]: false });
      }}
      type="button"
    >
      {modalLoading[rowData.id] ? (
        <i className="fa fa-spinner fa-pulse fa-fw" />
      ) : (
        <span className="icon icon-compile" />
      )}
    </button>
  );
}

export const InlineEditTable = (props) => {
  const { dataLoading, feesOrExpenses, onChangeOrder, isExpenseMode, supportsTax } = props;
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState({});
  const [modalLoading, setModalLoading] = useState({});

  // We use a ref to track a dragged item's index as the onDragOver event does not support accessing drag state
  const unprotectedDragIndexRef = useRef(0);
  // Ref so that we can toggle draggable on the whole row.
  // This is required so when the grip icon is dragged it can move the parent entire row
  const rowRef = React.useRef([]);
  // React virtualized hates dynamic row sizes. If you expand a row, you have to ask it nicely to update
  const reactVirtualizedRef = useRef(null);

  const customDnDRowRenderer = ({ className, columns, index, key, style }) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        style={style}
        onDragOver={(event) => {
          event.preventDefault();
          // eslint-disable-next-line no-param-reassign
          event.dataTransfer.effectAllowed = 'move';
        }}
        ref={(el) => {
          rowRef.current[index] = el;
        }}
        onDragStart={(event) => {
          // Unprotected var used as event data is not available during dragover
          unprotectedDragIndexRef.current = index;
          event.stopPropagation();
          // eslint-disable-next-line no-param-reassign
          event.dataTransfer.effectAllowed = 'move';
        }}
        onDrop={() => {
          if (index !== unprotectedDragIndexRef.current) {
            onChangeOrder(unprotectedDragIndexRef.current, index);
            unprotectedDragIndexRef.current = index;
          }
          reactVirtualizedRef.current.recomputeRowHeights({ index: 0 });
        }}
        onDragEnd={() => {
          rowRef.current[index].setAttribute('draggable', 'false');
        }}
      >
        {columns}
      </div>
    );
  };

  const allRowsExpanded = feesOrExpenses.some((entity) => entity.notes && !expanded[entity.id]);

  const expandAll = () => {
    const allExpanded = Object.values(expanded);
    const setAllExpanded = !allExpanded.length || allExpanded.some((val) => !val);
    setExpanded(
      feesOrExpenses.reduce((acc, fee) => {
        if (fee.notes) {
          acc[fee.id] = setAllExpanded;
        }
        return acc;
      }, {}),
    );
    reactVirtualizedRef.current.recomputeRowHeights({ index: 0 });
  };

  const expandRow = (rowData, rowIndex) => {
    if (expanded[rowData.id]) {
      setExpanded({ ...expanded, [rowData.id]: false });
      reactVirtualizedRef.current.recomputeRowHeights({ index: rowIndex });
    } else if (rowData.notes) {
      setExpanded({ ...expanded, [rowData.id]: true });
      reactVirtualizedRef.current.recomputeRowHeights({ index: rowIndex });
    }
  };

  return (
    <Table
      className="inline-edit-table"
      dataLoading={dataLoading}
      loadingBarContainerClassName="loading-bar-container"
      list={feesOrExpenses}
      ref={(el) => {
        reactVirtualizedRef.current = el;
      }}
      rowClassName="editable-row"
      rowHeight={({ index }) => (expanded[feesOrExpenses[index].id] ? 145 : 38)}
      rowRenderer={customDnDRowRenderer}
      style={{
        height: `${Math.min(
          500,
          30 + feesOrExpenses.length * 38 + Object.values(expanded).filter((i) => i).length * (145 - 38),
        )}px`,
      }}
    >
      <Column
        cellRenderer={gripCellRenderer({ rowRef })}
        dataKey="grip"
        disableSort
        label=""
        style={{ paddingLeft: '0' }}
        width={15}
      />
      <Column
        cellRenderer={expandCellRenderer({ expandRow, expanded })}
        dataKey="expanded"
        disableSort
        headerClassName="no-left-padding center-align"
        headerRenderer={expandHeaderCellRenderer({ expandAll, allRowsExpanded })}
        headerStyle={{ borderLeft: 'none' }}
        width={20}
      />
      <Column
        cellRenderer={selectCellRenderer({ props })}
        dataKey="selected"
        disableSort
        headerClassName="no-left-padding center-align"
        headerRenderer={selectHeaderCellRenderer({ props })}
        width={30}
      />
      <Column
        cellRenderer={effectiveDateCellRenderer({ t })}
        dataKey="effectiveDate"
        disableSort
        headerClassName=""
        label="Date"
        width={90}
      />
      <Column
        cellRenderer={descriptionCellRenderer({ props, expanded, t })}
        dataKey="description"
        disableSort
        flexGrow={2}
        label="Subject/Description"
      />
      <Column cellRenderer={staffInitialsCellRenderer({ props })} dataKey="" disableSort label="Staff" width={50} />
      <Column
        cellRenderer={durationQuantityCellRenderer({ props })}
        dataKey="hours"
        disableSort
        label={isExpenseMode ? 'Quantity' : 'Hours'}
        width={75}
      />
      <Column
        cellRenderer={ratePriceCellRenderer({ props, t })}
        dataKey="rate"
        disableSort
        headerClassName="right-align"
        label={isExpenseMode ? 'Price' : 'Rate'}
        width={80}
      />
      {supportsTax && (
        <Column
          cellRenderer={taxCellRenderer({ props, t })}
          dataKey="tax"
          disableSort
          headerClassName="right-align"
          headerRenderer={taxHeaderCellRenderer({ props, t })}
          width={100}
        />
      )}
      <Column
        cellRenderer={amountCellLocalisedRendererWithWaived}
        className="right-align"
        dataKey="billableAmountInclTax"
        disableSort
        label="Total"
        width={100}
      />
      <Column
        cellRenderer={writeOffCheckboxCellRenderer({ props })}
        dataKey="waived"
        disableSort
        headerClassName="no-left-padding center-align"
        label="W/O"
        width={50}
      />
      <Column
        cellRenderer={billableCheckboxCellRenderer({ props })}
        dataKey="isBillable"
        disableSort
        headerClassName="no-left-padding center-align"
        label="Billable"
        width={60}
      />
      <Column
        cellRenderer={viewCellRenderer({ props, modalLoading, setModalLoading })}
        className="no-left-padding"
        dataKey="view"
        disableSort
        headerClassName="no-left-padding center-align"
        label="View"
        width={50}
      />
    </Table>
  );
};

InlineEditTable.displayName = 'InlineEditTable';

InlineEditTable.propTypes = {
  dataLoading: PropTypes.bool,
  feesOrExpenses: PropTypes.arrayOf(PropTypes.object).isRequired,
  isExpenseMode: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeOrder: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  selectedFeeOrExpenseIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  supportsTax: PropTypes.bool.isRequired,
};

InlineEditTable.defaultProps = {
  dataLoading: false,
};
