import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, useTranslation } from '@sb-itops/react';
import { sort as sortItems } from '@sb-itops/sort';
import { saveAs } from 'file-saver';
import { toCsv } from '@sb-billing/business-logic/report/services';
import { PaymentPlanModalDialog } from '../payment-plan-modal-dialog';
import { ContactPaymentPlanTable } from '../contact-payment-plan-table';

import { buildDebtorCsvExportList } from './build-debtor-csv-export-list';
import Styles from './DebtorsWithPaymentPlanPanel.module.scss';

const exportCsv = (debtorsWithPaymentPlans, t) => {
  const listToExport = buildDebtorCsvExportList(debtorsWithPaymentPlans, t);
  const csvString = toCsv(listToExport);
  const csvBlob = new Blob([`\uFEFF${csvString}`], { type: 'data:text/csv;charset=utf-8' });
  saveAs(csvBlob, 'Debtors with payment plans.csv');
};

const DebtorsWithPaymentPlanPanel = memo(
  ({
    dataLoading,
    debtorsWithPaymentPlans,
    disableCreatePaymentPlan,
    paymentPlanModalId,
    sortBy,
    sortDirection,
    isPaymentPlanModalVisible,
    // callbacks & functions
    onPaymentPlanSort,
    onRowClick,
    onShowAddPaymentPlanModalDialog,
    onCloseAddPaymentPlanModalDialog,
  }) => {
    const { t } = useTranslation();
    const onExportCsv = () => {
      const sortedDebtorsForCsvExport = sortItems(debtorsWithPaymentPlans, [sortBy], [sortDirection]);
      exportCsv(sortedDebtorsForCsvExport, t);
    };

    return (
      <div className={Styles.debtorsWithPaymentPlanPanel}>
        {isPaymentPlanModalVisible && (
          <PaymentPlanModalDialog
            paymentPlanModalId={paymentPlanModalId}
            modalDialogTitle="Create Payment Plan"
            onNewPaymentPlanCreated={onCloseAddPaymentPlanModalDialog}
          />
        )}
        <div className="ribbon panel">
          <Button onClick={onExportCsv} disabled={debtorsWithPaymentPlans.length === 0}>
            export csv
          </Button>
          <Button onClick={onShowAddPaymentPlanModalDialog} disabled={disableCreatePaymentPlan}>
            create new plan
          </Button>
        </div>
        <ContactPaymentPlanTable
          dataLoading={dataLoading}
          debtorsWithPaymentPlans={debtorsWithPaymentPlans}
          onPaymentPlanSort={onPaymentPlanSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          onRowClick={onRowClick}
        />
      </div>
    );
  },
);

DebtorsWithPaymentPlanPanel.propTypes = {
  dataLoading: PropTypes.bool,
  debtorsWithPaymentPlans: PropTypes.arrayOf(
    PropTypes.shape({
      debtorId: PropTypes.string,
      displayName: PropTypes.string,
      organization: PropTypes.string,
      phone: PropTypes.string,
      cell: PropTypes.string,
      email: PropTypes.string,
      lastPaymentOnYYYYMMDD: PropTypes.number,
      status: PropTypes.string,
      paid: PropTypes.number,
      outstanding: PropTypes.number,
    }),
  ),
  disableCreatePaymentPlan: PropTypes.bool,
  isPaymentPlanModalVisible: PropTypes.bool.isRequired,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  paymentPlanModalId: PropTypes.string.isRequired,
  // callbacks & functions
  onPaymentPlanSort: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onShowAddPaymentPlanModalDialog: PropTypes.func.isRequired,
  onCloseAddPaymentPlanModalDialog: PropTypes.func.isRequired,
};

DebtorsWithPaymentPlanPanel.defaultProps = {
  dataLoading: false,
  debtorsWithPaymentPlans: [],
  disableCreatePaymentPlan: true,
  sortBy: 'displayName',
  sortDirection: 'asc',
};

DebtorsWithPaymentPlanPanel.displayName = 'DebtorsWithPaymentPlanPanel';

export default DebtorsWithPaymentPlanPanel;
