import * as Yup from 'yup';
import { paymentMethodByName } from '@sb-billing/business-logic/expense-payment-details/entities/constants';
import { PrintManually, PrintNow, PrintLater } from '@sb-billing/business-logic/cheques';
import { uploadFileType } from '@sb-itops/business-logic/files/entities/constants';
import { customYupValidators } from '@sb-itops/business-logic/validation/services';
import { capitalize } from '@sb-itops/nodash';

const chequePrintMethodOptions = [PrintManually, PrintNow, PrintLater];

export const expenseFormSchema = Yup.object().shape({
  /**
   * Expense related fields
   */
  amountIncludesTax: Yup.boolean().when('$showTaxFields', {
    is: true,
    then: (amountIncludesTax) => amountIncludesTax.required(),
    otherwise: (amountIncludesTax) => amountIncludesTax.nullable().optional(),
  }),
  description: Yup.string().nullable().optional(), // marshalled as notes
  expenseDate: Yup.date().required(),
  expenseEarnerStaffId: customYupValidators.uuid().required(),
  isBillable: Yup.boolean().required(),
  matterId: customYupValidators.uuid().required(),
  priceInCents: Yup.number().integer().required(),
  quantity: Yup.number().min(1).required(),
  subject: Yup.string().min(1).max(1000).required(), // marshalled as description
  taxInCents: Yup.number()
    .min(0)
    .when('$showTaxFields', {
      is: (showTaxFields) => showTaxFields,
      then: (taxInCents) => taxInCents.required(),
      otherwise: (taxInCents) => taxInCents.nullable().optional(),
    }),
  outputTaxInCents: Yup.number().when(['$showTaxFields', '$inputOutputTaxEnabled'], {
    is: (showTaxFields, inputOutputTaxEnabled) => showTaxFields && inputOutputTaxEnabled,
    then: (outputTaxInCents) => outputTaxInCents.min(0), // Not required as old expenses may not have this field for editing
    otherwise: (outputTaxInCents) => outputTaxInCents.nullable().optional(),
  }),
  /**
   * Supplier and payment related fields
   */
  expensePaymentDetails: Yup.object({
    id: customYupValidators.uuid(),
    isPaid: Yup.boolean().when('$anticipatedDisbursementsEnabled', {
      is: true,
      then: (isPaid) => isPaid.required(),
      otherwise: (isPaid) => isPaid.nullable().optional(),
    }),
    isPayable: Yup.boolean().when('$anticipatedDisbursementsEnabled', {
      is: true,
      then: (isPayable) => isPayable.required(),
      otherwise: (isPayable) => isPayable.nullable().optional(),
    }),
    paymentAccountName: Yup.string().when(['$isAnticipated', 'isPayable', 'isPaid', 'paymentMethod'], {
      is: (isAnticipated, isPayable, isPaid, paymentMethod) => {
        const isPayableExpense = !isAnticipated && isPayable;
        const isPaidAD = isAnticipated && isPaid;
        const isBankTransferOrDirectDebit =
          paymentMethod === paymentMethodByName.BANK_TRANSFER || paymentMethod === paymentMethodByName.DIRECT_DEBIT;

        return (isPayableExpense || isPaidAD) && isBankTransferOrDirectDebit;
      },
      then: (paymentAccountName) => paymentAccountName.required(),
      otherwise: (paymentAccountName) => paymentAccountName.nullable().optional(),
    }),
    paymentDue: Yup.date().when(['$isAnticipated', 'isPayable', '$anticipatedDisbursementsEnabled'], {
      is: (isAnticipated, isPayable, anticipatedDisbursementsEnabled) => {
        if (!anticipatedDisbursementsEnabled) {
          return false;
        }

        return (!isAnticipated && isPayable) || isAnticipated;
      },
      then: (paymentDue) => paymentDue.required(),
      otherwise: (paymentDue) => paymentDue.nullable().optional(),
    }),
    paymentMethod: Yup.number().when(['$anticipatedDisbursementsEnabled', '$isAnticipated', 'isPayable', 'isPaid'], {
      is: (anticipatedDisbursementsEnabled, isAnticipated, isPayable, isPaid) => {
        const isPayableExpense = !isAnticipated && isPayable;
        const isPaidAD = isAnticipated && isPaid;

        return anticipatedDisbursementsEnabled && (isPayableExpense || isPaidAD);
      },
      then: (paymentMethod) => paymentMethod.required().oneOf(Object.values(paymentMethodByName)),
      otherwise: (paymentMethod) => paymentMethod.nullable().optional(),
    }),
    paymentReference: Yup.string().when(['$isAnticipated', 'isPayable', 'isPaid', 'paymentMethod'], {
      is: (isAnticipated, isPayable, isPaid, paymentMethod) => {
        const isPayableExpense = !isAnticipated && isPayable;
        const isPaidAD = isAnticipated && isPaid;
        const isBankTransferOrDirectDebit =
          paymentMethod === paymentMethodByName.BANK_TRANSFER || paymentMethod === paymentMethodByName.DIRECT_DEBIT;

        return (isPayableExpense || isPaidAD) && isBankTransferOrDirectDebit;
      },
      then: (paymentReference) => paymentReference.required(),
      otherwise: (paymentReference) => paymentReference.nullable().optional(),
    }),
    supplier: Yup.object({
      id: customYupValidators
        .uuid()
        .when(['$isAnticipated', '$expensePaymentDetails', '$anticipatedDisbursementsEnabled'], {
          is: (isAnticipated, expensePaymentDetails, anticipatedDisbursementsEnabled) => {
            if (!anticipatedDisbursementsEnabled) {
              return false;
            }

            return (!isAnticipated && expensePaymentDetails?.isPayable) || isAnticipated;
          },
          then: (id) => id.required(),
          otherwise: (id) => id.nullable().optional(),
        }),
    }),
    supplierReference: Yup.string().when(
      ['$isAnticipated', 'isPayable', 'isPaid', '$anticipatedDisbursementsEnabled'],
      {
        is: (isAnticipated, isPayable, isPaid, anticipatedDisbursementsEnabled) => {
          if (!anticipatedDisbursementsEnabled) {
            return false;
          }

          return (!isAnticipated && isPayable) || (isAnticipated && isPaid);
        },
        then: (supplierReference) => supplierReference.required(),
        otherwise: (supplierReference) => supplierReference.nullable().optional(),
      },
    ),
  }),
  /**
   * Operating cheque
   */
  operatingCheque: Yup.object({
    chequeMemo: Yup.string().nullable().optional(),
    // chequeNumber field
    //  * This field requires some special validation
    //  * Please see useOperatingChequePrintManuallyOption hook for more detail
    chequeNumber: Yup.string().when(
      [
        '$isAnticipated',
        '$anticipatedDisbursementsEnabled',
        '$expensePaymentDetails',
        '$operatingChequePrintOptions',
        '$isNewExpense',
        '$operatingCheque',
      ],
      {
        is: (
          isAnticipated,
          anticipatedDisbursementsEnabled,
          expensePaymentDetails,
          operatingChequePrintOptions,
          isNewExpense,
          operatingCheque,
        ) => {
          const isPrintManuallyMethod = operatingChequePrintOptions?.chequePrintMethod === PrintManually;
          const isNewExpenseAndIsPrintManually = isNewExpense ? isPrintManuallyMethod : false;
          // The cheque print option is not persisted.
          //
          // So for existing expenses, we apply this validation only when the user has to input a value
          //  * e.g. creating an operating cheque with the Print Manually option selected
          //  * In other cases, we bypass this validation
          //    * Otherwise, it can prevent expenses with other printing options (e.g. Print Later) from being updated
          const isExistingExpenseWithoutChequeAndIsPrintManually =
            !isNewExpense && !operatingCheque?.id && isPrintManuallyMethod;

          if (anticipatedDisbursementsEnabled) {
            const isChequePaymentMethod = expensePaymentDetails?.paymentMethod === paymentMethodByName.CHEQUE;
            const isExpenseTypeAndPayable = !isAnticipated && expensePaymentDetails?.isPayable;
            const isADTypeAndIsPaid = isAnticipated && expensePaymentDetails?.isPaid;

            return (
              isChequePaymentMethod &&
              (isExpenseTypeAndPayable || isADTypeAndIsPaid) &&
              (isNewExpenseAndIsPrintManually || isExistingExpenseWithoutChequeAndIsPrintManually)
            );
          }

          return (
            !anticipatedDisbursementsEnabled &&
            operatingChequePrintOptions?.chequePrintActive &&
            (isNewExpenseAndIsPrintManually || isExistingExpenseWithoutChequeAndIsPrintManually)
          );
        },
        then: (chequeNumber) =>
          chequeNumber.test('chequeNumber', '', (chequeNumberValue, { createError, options }) => {
            const {
              t,
              lastOperatingChequeNumber,
              availableOperatingChequeNumber,
              allowChequeNumberDuplication,
              isAnticipated,
              isNewExpense,
              operatingCheque,
            } = options.context;

            // Switching returning to an instantiated form will trigger a validation
            // without the context values being provided. A re-render will re-validate
            // in the meantime we can't ascertain whether the form is valid or not
            if (!t) {
              return true;
            }

            const chequeLabel = capitalize(t('cheque'));
            const blankChequeNumber = chequeNumberValue?.length === 0;
            const isChequeNumberNumeric = /^[0-9]+$/.test(chequeNumberValue);
            const existingChequeNumber =
              availableOperatingChequeNumber?.replace(/^0+/, '') !== chequeNumberValue?.replace(/^0+/, '');

            // [1] Value present
            if (blankChequeNumber) {
              return createError({
                path: 'operatingCheque.chequeNumber',
                message: `Warning: ${chequeLabel} reference is required.`,
              });
            }

            // [2] Numeric value
            if (!isChequeNumberNumeric) {
              return createError({
                path: 'operatingCheque.chequeNumber',
                message: `Warning: ${chequeLabel} reference must be numeric.`,
              });
            }

            // [3] Unique cheque number
            if (existingChequeNumber) {
              // If duplicate cheques are allowed, we do not create a form error, but we still display the message on the UI to inform of the duplicate
              if (allowChequeNumberDuplication) {
                return true;
              }

              const existingExpenseWithCheque = !isAnticipated && !isNewExpense && operatingCheque?.id;
              if (existingExpenseWithCheque) {
                return true;
              }

              return createError({
                path: 'operatingCheque.chequeNumber',
                message: `Warning: ${chequeLabel} reference is already in use. Last ${chequeLabel.toLowerCase()} reference printed was ${lastOperatingChequeNumber}.`,
              });
            }

            return true;
          }),
        otherwise: (chequeNumber) => chequeNumber.nullable().optional(),
      },
    ),
    payTo: Yup.object({
      id: customYupValidators
        .uuid()
        .when(
          [
            '$isAnticipated',
            '$anticipatedDisbursementsEnabled',
            '$expensePaymentDetails',
            '$operatingChequePrintOptions',
          ],
          {
            is: (
              isAnticipated,
              anticipatedDisbursementsEnabled,
              expensePaymentDetails,
              operatingChequePrintOptions,
            ) => {
              if (anticipatedDisbursementsEnabled) {
                const isChequePaymentMethod = expensePaymentDetails?.paymentMethod === paymentMethodByName.CHEQUE;
                const isExpenseTypeAndPayable = !isAnticipated && expensePaymentDetails?.isPayable;
                const isADTypeAndIsPaid = isAnticipated && expensePaymentDetails?.isPaid;

                return isChequePaymentMethod && (isExpenseTypeAndPayable || isADTypeAndIsPaid);
              }

              return !anticipatedDisbursementsEnabled && operatingChequePrintOptions?.chequePrintActive;
            },
            then: (id) => id.required(),
            otherwise: (id) => id.nullable().optional(),
          },
        ),
    }),
  }),
  operatingChequePrintOptions: Yup.object({
    chequePrintMethod: Yup.string().when(
      ['$isAnticipated', '$operatingChequePrintOptions', '$expensePaymentDetails', '$anticipatedDisbursementsEnabled'],
      {
        is: (isAnticipated, operatingChequePrintOptions, expensePaymentDetails, anticipatedDisbursementsEnabled) => {
          if (anticipatedDisbursementsEnabled) {
            const isChequePaymentMethod = expensePaymentDetails?.paymentMethod === paymentMethodByName.CHEQUE;
            const isExpenseTypeAndPayable = !isAnticipated && expensePaymentDetails?.isPayable;
            const isADTypeAndIsPaid = isAnticipated && expensePaymentDetails?.isPaid;

            return isChequePaymentMethod && (isExpenseTypeAndPayable || isADTypeAndIsPaid);
          }

          return !anticipatedDisbursementsEnabled && operatingChequePrintOptions?.chequePrintActive;
        },
        then: (chequePrintMethod) => chequePrintMethod.required().oneOf(chequePrintMethodOptions),
        otherwise: (chequePrintMethod) => chequePrintMethod.nullable().optional(),
      },
    ),
  }),
  /**
   * IDs that map to objects
   */
  // Both custom and UTBMS activities are (always) optional
  activityId: Yup.string().nullable().optional(),
  // UTBMS tasks can be mandatory, based on the areUtbmsCodesRequiredByFirmAndMatter setting
  taskId: Yup.string().when('$areUtbmsCodesRequiredByFirmAndMatter', {
    is: true,
    then: (taskId) => taskId.required(),
    otherwise: (taskId) => taskId.nullable().optional(),
  }),
  /**
   * Attachments
   */
  attachmentFile: Yup.object({
    fileSize: Yup.number()
      .test('size', 'File size is too large', (fileSize) => {
        const sizeLimit = 4 * 1024 * 1024; // 4MB
        return fileSize === undefined || fileSize === null || fileSize <= sizeLimit;
      })
      .nullable()
      .optional(),
    fileType: Yup.string()
      .test('type', 'Unsupported file type', (fileType) => {
        const acceptedFileTypes = [uploadFileType.JPEG, uploadFileType.PDF, uploadFileType.PNG];
        return fileType === undefined || fileType === null || acceptedFileTypes.includes(fileType);
      })
      .nullable()
      .optional(),
  }),
});
