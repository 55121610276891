import { capitalize } from '@sb-itops/nodash';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { sort as sortItems } from '@sb-itops/sort';
import { isMatterContactBalanceType } from '@sb-billing/redux/bank-account-settings';
import {
  BANK_BALANCE_TYPE,
  getBalanceType,
} from '@sb-billing/redux/bank-account-settings';
import { getMatterDisplayById } from '@sb-matter-management/redux/matters';
import { getInvoiceNumberById } from '@sb-billing/redux/invoices';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';


angular.module('sb.billing.webapp').component('sbDataLedger', {
  require: { sbComposeCtrl: '^sbCompose' },
  bindings: {composeKey: '@', invoiceSummaries: '<', invoicePayments: '<', matterId: '<?', payorId: '<?'},
  controller: function (sbLoggerService, sbDateService, sbLocalisationService) {
    const that = this;
    const log = sbLoggerService.getLogger('sbDataLedger');

    that.invoiceSummaries = [];
    that.rawInvoiceSummaries = [];
    that.invoicePayments = [];
    that.rawInvoicePayments = [];

    that.$onInit = () => {
      if (!that.composeKey) {
        throw new Error('Cannot create sbDataLedger with invalid compose key');
      }

      log.info('composeKey', that.composeKey);
    };

    that.$onChanges = (changesObj) => {
      if (changesObj.invoiceSummaries || that.invoiceSummaries) {
        const nextList = changesObj.invoiceSummaries && changesObj.invoiceSummaries.currentValue || that.rawInvoiceSummaries;
        that.rawInvoiceSummaries = nextList;
        that.invoiceSummaries = updateInvoiceSummaries(nextList);
      }

      if (changesObj.invoicePayments || that.invoicePayments ) {
        const nextList = changesObj.invoicePayments && changesObj.invoicePayments.currentValue || that.rawInvoicePayments;
        that.rawInvoicePayments = nextList;
        that.invoicePayments = updateInvoicePayments(nextList, that.invoiceSummaries.length);
      }

      const concatenatedList = that.invoiceSummaries.concat(that.invoicePayments);

      that.sbComposeCtrl.setComposeData(concatenatedList , that.composeKey);
    };

    function updateInvoiceSummaries(rawInvoiceSummaries = [], processOrderFrom = 0) {
      const statusToInclude = ['DRAFT', 'FINAL', 'PAID'];
      const invoiceSummaries = rawInvoiceSummaries
        .filter((summary) => summary.currentVersion && statusToInclude.includes(summary.currentVersion.status.toUpperCase()) && filterEntry(summary.currentVersion.matterId, summary.currentVersion.debtors[0].id))
        .map((summary, index) => {
          const description = summary.currentVersion.status.toUpperCase() === 'DRAFT'
            ? `Draft Invoice invoiceId:${summary.currentVersion.invoiceId}${summary.invoiceNumberSuffix ? `|${summary.invoiceNumberSuffix}|${summary.debtorId}` : ''}`
            : `Invoice invoiceId:${summary.currentVersion.invoiceId}${summary.invoiceNumberSuffix ? `|${summary.invoiceNumberSuffix}|${summary.debtorId}` : ''}`;
          return {
            id: summary.invoiceId,
            matterId: summary.currentVersion.matterId,
            timestamp: summary.finalizedTimestamp || summary.currentVersion.validFrom,
            effectiveDateDisplay: sbDateService.from(summary.currentVersion.issuedDate),
            effectiveDate: summary.currentVersion.issuedDate,
            description,
            contactIds: summary.currentVersion.debtors.map((d) => d.id),
            debtorIds: summary.currentVersion.debtors.map((d) => d.id),  // separate Paid By column from Debtor(s) column for matter ledger
            waived: summary.waived,
            amount: summary.total,
            type: 'DUE_AMOUNT',
            invoice: summary,
            processOrder: index + processOrderFrom,
        }
      });

      log.info('invoice summaries %s', invoiceSummaries.length);
      return invoiceSummaries;
    }

    function updateInvoicePayments(rawPayments = [], processOrderFrom = 0) {
      const trustToOfficePayments = {};

      const payments = rawPayments
        .reduce((acc, payment, index) => {
          if (payment.reversedAt === null && filterEntry(payment.matterId, payment.payorId)) {
            const source = getSource(payment);
            const description = source === 'Credit'
              ? `Credit applied to  ${payment.invoices.length > 1? 'invoices' : 'invoice'} ${payment.invoices.map(i => `invoiceId:${i.invoiceId}${i.invoiceNumberSuffix ? `|${i.invoiceNumberSuffix}|${i.debtorId}` : ''}`).join(' ')}`
              : `Payment for ${payment.invoices.length > 1 ? 'invoices' : 'invoice'} ${payment.invoices.map(i => `invoiceId:${i.invoiceId}${i.invoiceNumberSuffix ? `|${i.invoiceNumberSuffix}|${i.debtorId}` : ''}`).join(' ')}`;
            const amount = _.unique(payment.invoices, 'invoiceId').reduce((sum, invoice) => sum + invoice.amount, 0); // we need to unique this to deal with faulty dev/staging data
            const paymentRecord = {
              id: payment.paymentId,
              isTrustToOffice: payment.isTrustToOffice,
              multiPaymentId: payment.multiPaymentId,
              chequeId: payment.chequeId,
              matterId: payment.matterId,
              invoices: payment.invoices,
              timestamp: payment.timestamp,
              effectiveDateDisplay: sbDateService.from(payment.effectiveDate),
              effectiveDate: payment.effectiveDate,
              description,
              contactIds: (isMatterContactBalanceType() || !payment.sourceAccountId) ? [payment.payorId] : [],
              payorIds: (isMatterContactBalanceType() || !payment.sourceAccountId) ? [payment.payorId] : [], // separate Paid By column from Debtor(s) column for matter ledger
              credit: source === 'Credit' ? amount : undefined,
              waived: 0,
              source,
              sourceAccountId: payment.sourceAccountId,
              destinationAccountId: payment.destinationAccountId,
              amount: source === 'Credit' ? undefined : amount,
              type: 'PAYMENT',
              payment,
              reference: payment.reference,
              reason: payment.reason,
              userId: payment.userId,
              processOrder: index + processOrderFrom,
            };

            if (payment.isTrustToOffice) {
              const id = payment.multiPaymentId || payment.paymentId;
              if (trustToOfficePayments[id]) {
                trustToOfficePayments[id].push(paymentRecord);
              } else {
                trustToOfficePayments[id] = [paymentRecord];
              }
            } else {
              acc.push(paymentRecord);
            }
          }

          return acc;
        }, []);

      const allPayments = [...payments, ...consolidateTrustToOfficePayments(trustToOfficePayments)]

      log.info('invoice payments %s', allPayments.length);
      return allPayments;
    }

    function consolidateTrustToOfficePayments(trustToOfficePayments) {
      const trustToOfficeLabel = sbLocalisationService.t('trustToOfficeLabel');

      const payments = Object.values(trustToOfficePayments).map(multiPayments =>
        multiPayments.reduce(
          (acc, payment) => {
            payment.invoices.forEach((invoice) => {
              if (!acc.allInvoices[invoice.invoiceId]) {
                const invoiceNumber = getInvoiceNumberById(invoice.invoiceId);
                acc.allInvoices[invoice.invoiceId] = {
                  invoiceId: invoice.invoiceId,
                  invoiceNumber,
                };
              }
            });

            return {
              id: payment.multiPaymentId || payment.paymentId, // This is a pseudo-payment object and the multiPaymentId property is common amongst all related payments
              isTrustToOffice: payment.isTrustToOffice,
              multiPaymentId: payment.multiPaymentId,
              chequeId: payment.chequeId,
              // matterId - converted to matterIds
              matterIds: acc.matterIds.includes(payment.matterId) ? acc.matterIds : [...acc.matterIds, payment.matterId],
              timestamp: payment.timestamp,
              effectiveDateDisplay: sbDateService.from(payment.effectiveDate),
              effectiveDate: payment.effectiveDate,
              description: acc.description || `${trustToOfficeLabel} for`,
              allInvoices: acc.allInvoices,
              contactIds: payment.contactIds.length && acc.contactIds.includes(payment.contactIds[0]) ? acc.contactIds : [...acc.contactIds, ...payment.contactIds],
              waived: acc.waived + payment.waived,
              source: getSource(multiPayments[0]),
              sourceAccountId: payment.sourceAccountId, // Assuming that source account ID is the same for all payments
              destinationAccountId: payment.destinationAccountId, // Assuming that destination account ID is the same for all payments
              amount: acc.amount + payment.amount,
              type: multiPayments[0].type,
              // payment - converted to payments
              payments: [...acc.payments, payment],
              reference: payment.reference,
              reason: payment.reason,
              userId: payment.userId,
              processOrder: multiPayments[0].processOrder,
            };
          },
          {
            allInvoices: {},
            matterIds: [],
            contactIds: [],
            waived: 0,
            amount: 0,
            payments: [],
          }
        ),
      ).map(payment => {
        const sortedInvoices = sortItems(Object.values(payment.allInvoices), ['invoiceNumber'], ['ASC']);
        return {
        ...payment,
        matterDisplay: payment.matterIds && payment.matterIds.map(matterId => getMatterDisplayById(matterId)).join(' | '),
        allInvoices: sortedInvoices,
        description: `${payment.description} ${sortedInvoices.length === 1 ? 'invoice' : 'invoices'} ${sortedInvoices
          .map((invoice) => `#invoiceId:${invoice.invoiceId}`)
          .join(', ')}`,
      }});

      return payments;
    }

    function getSource (payment) {
      const sourceBankAccount = getBankAccountById(payment.sourceAccountId);
      const source = (payment.source
        || (sourceBankAccount && sourceBankAccount.accountTypeDisplay)
        || '').toLowerCase();

      if (source === 'trust') {
        return getBankAccountName(sourceBankAccount, sbLocalisationService.t);
      } else if (source === 'echeck') {
        return sbLocalisationService.t('echeque');
      } 

      const sourceLocalised = source.replace(/check/gi, sbLocalisationService.t('cheque'));
      return capitalize(sourceLocalised);
    }

    function filterEntry (matterId, payorId) {
      let keepEntry = true;

      if (that.matterId) {
        keepEntry = (that.matterId === matterId);
      }

      if (getBalanceType() === BANK_BALANCE_TYPE.matterContact && that.payorId) {
        keepEntry = (that.payorId === payorId);
      }

      return keepEntry;
    }
  }
}
);