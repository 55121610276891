import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PaginationSelector } from '@sb-itops/react';
import { ContactPaymentPlanListFilterPanel, DebtorsWithPaymentPlanPanel } from 'web/react-redux';
import Styles from './BillingContactsPaymentPlansRoute.module.scss';

export const BillingContactsPaymentPlansRoute = memo((props) => {
  const {
    contactListFilterScope,
    contactStatusCounts,
    contactStatusSelections,
    hidePagination,
    onClickLink,
    onContactStatusSelect,
    contactStatusCountsLoading,
    currentPaymentPlanPage,
    debtorsWithPaymentPlans,
    disableCreatePaymentPlan,
    paymentPlanDataLoading,
    paymentPlanSortBy,
    paymentPlanSortDirection,
    totalNumberOfPaymentPlanPages,
    onPaymentPlanListPageChange,
    onPaymentPlanSort,
  } = props;

  return (
    <div className={Styles.contactsPanel}>
      <div className="master-detail-panel">
        <div className={Styles.flexCol}>
          <ContactPaymentPlanListFilterPanel
            contactListFilterScope={contactListFilterScope}
            contactStatusCounts={contactStatusCounts}
            contactStatusCountsLoading={contactStatusCountsLoading}
            contactStatusSelections={contactStatusSelections}
            onContactStatusSelect={onContactStatusSelect}
          />
        </div>
        <div className={classnames('panel-body', Styles.contactsPanelBody)}>
          <div className={Styles.contactsListMain}>
            <DebtorsWithPaymentPlanPanel
              onClickLink={onClickLink}
              dataLoading={paymentPlanDataLoading}
              disableCreatePaymentPlan={disableCreatePaymentPlan}
              debtorsWithPaymentPlans={debtorsWithPaymentPlans}
              onPaymentPlanSort={onPaymentPlanSort}
              sortBy={paymentPlanSortBy}
              sortDirection={paymentPlanSortDirection}
            />
            <PaginationSelector
              name="contactPayments"
              className={Styles.paginationSection}
              hidePagination={hidePagination}
              numberOfPagesDisplayed={10}
              selectedPage={currentPaymentPlanPage}
              totalNumberOfPages={totalNumberOfPaymentPlanPages}
              onPageChange={onPaymentPlanListPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

BillingContactsPaymentPlansRoute.displayName = 'BillingContactsPaymentPlansRoute';

BillingContactsPaymentPlansRoute.propTypes = {
  contactListFilterScope: PropTypes.string.isRequired,
  contactStatusCounts: PropTypes.shape({
    current: PropTypes.number,
    deleted: PropTypes.number,
  }).isRequired,
  contactStatusCountsLoading: PropTypes.bool,
  contactStatusSelections: PropTypes.object.isRequired,
  disableCreatePaymentPlan: PropTypes.bool,
  debtorsWithPaymentPlans: PropTypes.array,
  paymentPlanSortBy: PropTypes.string.isRequired,
  paymentPlanSortDirection: PropTypes.string.isRequired,
  paymentPlanDataLoading: PropTypes.bool,
  currentPaymentPlanPage: PropTypes.number.isRequired,
  totalNumberOfPaymentPlanPages: PropTypes.number.isRequired,
  hidePagination: PropTypes.bool,
  onClickLink: PropTypes.func,
  onContactStatusSelect: PropTypes.func.isRequired,
  onPaymentPlanListPageChange: PropTypes.func.isRequired,
  onPaymentPlanSort: PropTypes.func,
};

BillingContactsPaymentPlansRoute.defaultProps = {
  contactStatusCountsLoading: false,
  debtorsWithPaymentPlans: [],
  disableCreatePaymentPlan: false,
  hidePagination: false,
  paymentPlanDataLoading: true,
  onClickLink: () => {},
  onPaymentPlanSort: () => {},
};
