'use strict';

const failedRequestType = Object.freeze({
  CONNECTION: 0,
  TRANSACTION: 1,
});

module.exports = {
  failedRequestType,
};
