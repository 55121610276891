import { featureActive } from '@sb-itops/feature';
import Intercom from "@intercom/messenger-js-sdk";
import { getLoggedInUserId } from '@sb-firm-management/redux/firm-management';
import { getUsername } from 'web/services/user-session-management';
import { fetchGetP } from '@sb-itops/redux/fetch';
import { envType, getAppEnv } from '@sb-itops/app-env';

const APP_ID = "c1sbgle1";

const initialiseIntercom = async ({ defaultTeardown, log }) => {
  // Not ready for prime time yet, let's prevent any mistakes
  if (!featureActive('NUCWEB-914') || getAppEnv() === envType.PRODUCTION || getAppEnv() === envType.QA) {
    return defaultTeardown;
  }

  const ENDPOINT = `/itops/authorisation/intercom/:accountId/`;

  // Deliberately not awaited right now so it doesnt block loading, not sure if this will be necessary in future if we use this for onboarding to prevent it flashing in
  fetchGetP({
    path: ENDPOINT,
  })
    .then((response) => {
      Intercom({
        app_id: APP_ID,
        user_id: getLoggedInUserId(),
        name: getUsername(),
        user_hash: response.body.token,
      });
    })
    .catch((err) => {
      log.error('Error initialising intercom', err)
    });

  return () => {
    Intercom('shutdown');
  };
};

export { initialiseIntercom };
