/**
 * NOTICE:
 *
 * This is a Load on Demand compatible route container, meaning that neither
 * this container nor any of the sub-component/containers should have a
 * dependency on:
 * - Entity caches
 * - Angular services
 *
 * Entity cache version of this component can be found in
 * monorepo/apps/smokeball-billing-web/src/react-redux/components/add-contact-inline-form.2
 *
 * Please consider whether any changes should be reflected in both containers.
 *
 * This container depends on NUCWEB-726 being ON
 */

import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as forms from '@sb-itops/redux/forms2';
import { getRegion } from '@sb-itops/region';
import * as messageDisplay from '@sb-itops/message-display';
import { contactTypes } from '@sb-customer-management/business-logic/contacts/entities';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { CreateEditContactSchema } from 'web/react-redux/components/contact-create-edit/CreateEditContactSchema';
import { AddContactInlineForm } from './AddContactInlineForm';

function marshalEntityBankDetails({ contactId, formFields }) {
  const entityBankDetails = {
    id: contactId,
    accountName: formFields.accountName,
    bankName: formFields.bankName,
    bankBranchNumber: formFields.bankBranchNumber,
    accountNumber: formFields.accountNumber,
  };
  return entityBankDetails;
}

const createContactViaDispatcher = async ({ formFields, isUtbmsEnabledForFirm }) => {
  if (![contactTypes.PERSON, contactTypes.COMPANY, contactTypes.TRUST].includes(formFields.contactType)) {
    throw new Error(`Unsupported contact type '${formFields.contactType}'`);
  }

  let type = 'Integration.CreateCompanyContact';
  if (formFields.contactType === contactTypes.PERSON) {
    type = 'Integration.CreatePersonContact';
  } else if (formFields.contactType === contactTypes.TRUST) {
    type = 'Integration.CreateTrustContact';
  }

  const message = { isUtbmsEnabled: isUtbmsEnabledForFirm, contactFields: formFields };

  const dispatchResult = await dispatchCommand({ type, message });

  return dispatchResult.contact;
};

const create = async ({ formFields, isUtbmsEnabledForFirm }) => {
  const newContact = await createContactViaDispatcher({ formFields, isUtbmsEnabledForFirm });

  const bankAccountFields = formFields.bankAccountFields;

  const entityBankDetails = marshalEntityBankDetails({ contactId: newContact.id, formFields: bankAccountFields });

  if (
    (bankAccountFields.accountName ||
      bankAccountFields.bankName ||
      bankAccountFields.bankBranchNumber ||
      bankAccountFields.accountNumber) &&
    entityBankDetails.id
  ) {
    await dispatchCommand({
      type: 'Billing.Accounts.Messages.Commands.SaveEntityBankDetails',
      message: {
        id: entityBankDetails.id,
        bankName: entityBankDetails.bankName,
        bankBranchNumber: entityBankDetails.bankBranchNumber,
        accountNumber: entityBankDetails.accountNumber,
        accountName: entityBankDetails.accountName,
      },
    });
  }

  return newContact;
};

const scope = 'add-contact-inline-form';

const hooks = () => ({
  useFormData: ({ onContactCreated, isUtbmsEnabledForFirm }) => {
    const { selectors: formSelectors, operations: formOperations } = useScopedFeature(forms, scope);
    const { formSubmitting } = useSelector(formSelectors.getFormState);

    const dispatch = useDispatch();
    return {
      scope,
      isSubmitDisabled: formSubmitting,
      onSave: async (event) => {
        event.preventDefault();
        await dispatch(formOperations.validateSchema({ schema: CreateEditContactSchema(getRegion()) }));
        try {
          await dispatch(
            formOperations.submitFormWithValidationP({
              submitFnP: async (formFields) => {
                const contact = await create({ formFields, isUtbmsEnabledForFirm });

                const displayName =
                  formFields.contactType === contactTypes.PERSON
                    ? [formFields.personFields.lastName, formFields.personFields.firstName]
                        .filter((name) => name)
                        .join(', ')
                    : formFields.companyFields?.companyName || formFields.trustFields?.trustName || '';

                onContactCreated({
                  id: contact.id,
                  displayName,
                });
              },
            }),
          );
        } catch (err) {
          messageDisplay.error('Failed to save contact');
        }
      },
    };
  },
});

export const AddContactInlineFormContainer = withReduxProvider(composeHooks(hooks)(AddContactInlineForm));

AddContactInlineFormContainer.displayName = 'AddContactInlineFormContainer';

AddContactInlineFormContainer.propTypes = {
  onContactCreated: PropTypes.func,
  isUtbmsEnabledForFirm: PropTypes.bool.isRequired,
  messageDisplay: PropTypes.object,
  showBankDetailsTab: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

AddContactInlineFormContainer.defaultProps = {
  onContactCreated: () => {},
  messageDisplay: { success: () => {} },
  showBankDetailsTab: false,
};

export default AddContactInlineFormContainer;
