angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/home/billing/view-split-billing-bill';

  $stateProvider

    .state('home.billing.view-split-billing-bill', {
      url: '/view-bill/:invoiceId/debtor/:debtorId/:checkId/:bankAccountId',
      templateUrl: BASE_DIRECTORY + '/view-split-billing-bill.html',
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      params: {
        openedFromTab: null,
      },
      data: {
        authorized: true,
        navHighlights: ['billing', 'bills']
      }
    });

});
