'use strict';

const { failedRequestTarget } = require('./failed-request-target');
const { failedRequestType } = require('./failed-request-type');
const { integrations, integrationNames } = require('./integrations');

const operationTypes = {
  0: 'Unknown',
  1: 'InvoicePayment',
  2: 'InvoiceTrustPayment',
  3: 'OperatingAccountDeposit',
  4: 'OperatingCheque',
  5: 'OverpaymentToTrust',
  6: 'TrustAccountDeposit',
  7: 'VendorOperatingPayment',
  8: 'VendorTrustPayment',
  9: 'Invoice',
  10: 'InvoiceBatchPayment',
  11: 'InvoiceCreditPayment',
  12: 'InvoiceInterestPayment',
  13: 'InvoiceWaive',
  14: 'Contact',
  15: 'ExpensePayment',
};

module.exports = {
  operationTypes,
  integrations,
  integrationNames,
  failedRequestType,
  failedRequestTarget,
};
