
import { fetchFirmDetails } from 'web/services/bootstrapper/fetch-firm-details';
import { refetchFlags } from '@sb-itops/launch-darkly-client-side';
import {
    getAccountId,
    getUserRegion,
    getOwningCompany,
  } from 'web/services/user-session-management';
import { getLogger } from '@sb-itops/fe-logger';

const log = getLogger('services.subscription');

export const waitForLDFlagRefresh = async () => {
    const firmDetails = await fetchFirmDetails({ log });

    return refetchFlags({
      kind: 'user',
      key: getAccountId(),
      country: getUserRegion(),
      name: firmDetails && firmDetails.firmName,
      creationDate:
        firmDetails && firmDetails.creationDate
          ? moment(firmDetails && firmDetails.creationDate).valueOf() // milliseconds since unix epoch
          : null,
      users: firmDetails && firmDetails.currentUserCount,
      product: getOwningCompany(),
    });
}
