import * as React from 'react';
import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { payButtonEnabledOptionValues } from '@sb-billing/business-logic/matters/invoice-settings';
import {
  formatStaffMemberEmailAddress,
  modifyEmailBody,
  modifyEmailSubject,
} from '@sb-billing/business-logic/invoice-emailing';
import { getLogger } from '@sb-itops/fe-logger';
import { regionType } from '@sb-itops/region';
import { buildContactToAddress } from '@sb-customer-management/business-logic/contacts/services';

import { yupSchemaValidator } from '@sb-itops/business-logic/validation/services';
import { dot as nestedObjectToFlattened } from 'dot-object';
import { InvoiceEmailModal } from './InvoiceEmailModal';

import { multiTabInvoiceEmailFormSchema } from './MultiTabInvoiceEmailForm.yup';

const log = getLogger('MultiTabInvoiceEmailModal.forms.container');

/**
 * Transforms the form data in preparation to send it via email
 *
 * @param {object} params
 * @param {string[]} params.debtorIds
 * @param {object} params.formValues
 * @param {string} params.invoiceId
 * @param {object} params.invoiceTotalDurationsByIdMap
 * @returns {object[]}
 */
function convertToInvoiceEmailRequests({ debtorIds, formValues, invoiceId, invoiceTotalDurationsByIdMap }) {
  if (!formValues || !invoiceId || !debtorIds) {
    return [];
  }

  const invoiceEmailRequests = Object.entries(formValues.tabsFormData).reduce((acc, [debtorId, tabFormData]) => {
    const {
      bccAddress,
      ccAddress,
      fromAddress,
      includeDebtor,
      message,
      sendCopyToMe,
      staffAddress,
      subject,
      toAddress,
    } = tabFormData;

    if (!includeDebtor) {
      return acc;
    }

    let bcc = bccAddress;
    if (sendCopyToMe) {
      bcc = bccAddress ? `${bccAddress}, ${staffAddress}` : staffAddress;
    }

    const request = {
      invoiceIds: [invoiceId],
      invoiceTotalDurationsByIdMap,
      debtorId,
      template: {
        toAddress,
        replyToAddress: fromAddress,
        bcc,
        cc: ccAddress,
        subject,
        message,
      },
    };

    acc.push(request);
    return acc;
  }, []);

  return invoiceEmailRequests;
}

function validateFn(formFields) {
  const tabsFormDataErrors = Object.entries(formFields.tabsFormData).reduce((acc, [debtorId, tabFormData]) => {
    if (tabFormData.includeDebtor) {
      const errors = yupSchemaValidator(tabFormData, multiTabInvoiceEmailFormSchema);
      if (Object.keys(errors).length) {
        acc[debtorId] = errors;
      }
    }
    return acc;
  }, {});

  // Forms 2 expects errors to be reported as flattened dot object notation.
  const errors = Object.keys(tabsFormDataErrors).length
    ? nestedObjectToFlattened({ tabsFormData: tabsFormDataErrors })
    : {};

  return errors;
}

const hooks = () => ({
  useInvoiceEmailModalForm: ({
    debtorIds,
    firmInvoiceEmailSettings,
    invoiceEmailModalData,
    invoiceTotalDurationsByIdMap,
    isConsolidatedMode,
    isLoading: isLoadingQuery,
    loggedInStaffMember,
    region,
    scope,
    // Callbacks
    onModalClose,
    onPreview,
    onSendEmails,
  }) => {
    const invoiceEmailForm = useForm({ scope, validateFn });
    const { formFields, formInitialised, formSubmitting, formValid } = invoiceEmailForm;

    // Multi-tab form is only for single invoice
    const invoice = invoiceEmailModalData.invoices[0];

    /**
     * Email preview
     */
    const [isLoadingPreview, setIsPreviewLoading] = React.useState(true);
    const [previewData, setIsPreviewData] = React.useState({});
    const selectedTabId = invoiceEmailForm.formValues.selectedTabId;
    const previewDataForSelectedTab = previewData[selectedTabId] || {};

    function onPreviewToggled() {
      const isPreviewMode = previewDataForSelectedTab.isPreviewMode;

      if (!isPreviewMode) {
        // Apply any edits made
        setPreviewValues({ formValues: invoiceEmailForm.formValues });
      }

      setIsPreviewData({
        ...previewData,
        [selectedTabId]: {
          ...previewDataForSelectedTab,
          isPreviewMode: !isPreviewMode,
        },
      });
    }

    async function setPreviewValues({ formValues }) {
      const invoiceEmailRequests = convertToInvoiceEmailRequests({
        debtorIds,
        formValues,
        invoiceId: invoice.id,
        invoiceTotalDurationsByIdMap,
      });

      // Convert to previews
      const previews = await Promise.all(
        invoiceEmailRequests.map(async (invoiceEmailRequest) => {
          // Interpolates the email subject and body, providing a preview of the email
          const previewValues = await onPreview({
            invoiceEmailRequest,
          });

          return {
            debtorId: invoiceEmailRequest.debtorId,
            isPreviewMode: true,
            previewSubject: previewValues.subject,
            previewMessage: previewValues.message,
          };
        }),
      );

      // Store previews by debtorId
      const previewsByDebtorId = previews.reduce((acc, preview) => {
        acc[preview.debtorId] = preview;
        return acc;
      }, {});

      setIsPreviewData(previewsByDebtorId);
    }

    /**
     * Form initialisation
     */
    const initialiseForm = !isLoadingQuery && !formInitialised;

    React.useEffect(
      () => () => invoiceEmailForm.onClearForm(),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    function getDefaulFormValues() {
      const { debtors, matter } = invoice;
      const { matterEmailSettings } = matter;
      const { emailBody, emailSubject, sendCopyToUser } = firmInvoiceEmailSettings;

      /** "Send copy.." and "From" fields */
      const formattedStaffMemberEmailAddress = formatStaffMemberEmailAddress({ staffMember: loggedInStaffMember });

      /** "Message" field */
      // If matterInvoiceSettings is null, use firm settings
      const showPayButtons = false; // Not available for multi debtor invoice

      const tabsFormData = debtors.reduce(
        (acc, debtor) => {
          const tabFormDataForDebtor = {
            bccAddress: matterEmailSettings?.bccAddresses.join(', ') ?? '',
            ccAddress: matterEmailSettings?.ccAddresses.join(', ') ?? '',
            fromAddress: formattedStaffMemberEmailAddress,
            includeDebtor: true,
            message: modifyEmailBody({ showPayButtons, emailBody, isConsolidatedMode }),
            sendCopyToMe: sendCopyToUser,
            staffAddress: formattedStaffMemberEmailAddress,
            subject: modifyEmailSubject({ emailSubject, isConsolidatedMode }),
            toAddress: buildContactToAddress({ contact: debtor.contact, region }),
          };

          acc.tabsFormData[debtor.id] = tabFormDataForDebtor;
          return acc;
        },
        {
          selectedTabId: debtors[0].id,
          tabsFormData: {},
        },
      );

      return tabsFormData;
    }

    React.useEffect(() => {
      (async () => {
        if (initialiseForm) {
          const defaultValues = getDefaulFormValues();
          invoiceEmailForm.onInitialiseForm(defaultValues);

          await setPreviewValues({ formValues: defaultValues });
          setIsPreviewLoading(false);

          invoiceEmailForm.onValidateForm();
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialiseForm]);

    /**
     * Form updates
     */
    function onUpdateFormData({ fieldUpdates }) {
      invoiceEmailForm.onUpdateFields(fieldUpdates);
      invoiceEmailForm.onValidateForm();
    }

    function onFieldValueUpdated(field, newValue) {
      const newFieldValue = {
        tabsFormData: {
          [selectedTabId]: {
            [field]: newValue,
          },
        },
      };

      onUpdateFormData({ fieldUpdates: newFieldValue });
    }

    /**
     * Form submission
     */
    async function onFormSubmit() {
      try {
        invoiceEmailForm.onValidateForm();

        const isValid = await invoiceEmailForm.onSubmitFormWithValidation({
          submitFnP: async (formData) => {
            const invoiceEmailRequests = convertToInvoiceEmailRequests({
              debtorIds,
              formValues: formData,
              invoiceId: invoice.id,
              invoiceTotalDurationsByIdMap,
            });

            onSendEmails({ invoiceEmailRequests });
          },
        });

        if (!isValid) {
          log.warn('Invoice email modal form validation failed');
          return;
        }

        onModalClose();
      } catch (error) {
        log.error('Failed to send invoice email', error);
      }
    }

    /**
     * Tabs
     */
    const { tabsFormData } = formFields;

    const selectedTabFormData = tabsFormData?.[selectedTabId];

    const contactByIdMap = React.useMemo(
      () =>
        invoice?.debtors.reduce((acc, debtor) => {
          acc[debtor.id] = debtor.contact;
          return acc;
        }, {}),
      [invoice?.debtors],
    );

    const tabs = tabsFormData
      ? Object.entries(tabsFormData).map(([debtorId, tabFormData]) => ({
          text: contactByIdMap[debtorId].displayNameFirstLast,
          id: debtorId,
          debtorIncluded: tabsFormData[debtorId].includeDebtor.value,
          tabValid: Object.values(tabFormData).every((field) => field.isValid),
        }))
      : [];

    const atLeastOneTabWillSendEmail = tabsFormData
      ? Object.values(tabsFormData).some((tabFormData) => tabFormData.includeDebtor.value)
      : false;

    function onChangeTab({ newlySelectedTabId }) {
      onUpdateFormData({
        fieldUpdates: {
          selectedTabId: newlySelectedTabId,
        },
      });
    }

    return {
      formFields: invoiceEmailForm.formFields,
      formInitialised,
      invoiceEmailForm,
      isLoading: isLoadingQuery || isLoadingPreview,
      isSubmitDisabled: !formValid || formSubmitting || !atLeastOneTabWillSendEmail,
      isPreviewMode: previewDataForSelectedTab.isPreviewMode,
      previewMessage: previewDataForSelectedTab.previewMessage,
      previewSubject: previewDataForSelectedTab.previewSubject,
      // Callbacks
      onFieldValueUpdated,
      onPreviewToggled,
      onSend: onFormSubmit,
      onUpdateFormData,
      // Tabs
      ...selectedTabFormData,
      selectedTabId,
      tabs,
      onChangeTab,
    };
  },
});

const dependentHooks = () => ({});

export const MultiTabInvoiceEmailModalFormsContainer = composeHooks(hooks)(
  composeHooks(dependentHooks)(InvoiceEmailModal),
);

MultiTabInvoiceEmailModalFormsContainer.displayName = 'MultiTabInvoiceEmailModalFormsContainer';

const DebtorEntityType = PropTypes.shape({
  contact: PropTypes.shape({
    id: PropTypes.string.isRequired,
    customLetterName: PropTypes.string,
    displayNameFirstLast: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    letterNameFormat: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
});

const MatterEntityType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  matterEmailSettings: PropTypes.shape({
    id: PropTypes.string.isRequired,
    bccAddresses: PropTypes.arrayOf(PropTypes.string),
    ccAddresses: PropTypes.arrayOf(PropTypes.string),
  }),
  matterInvoiceSettings: PropTypes.shape({
    id: PropTypes.string.isRequired,
    payButtonEnabledOption: PropTypes.oneOf(Object.values(payButtonEnabledOptionValues)),
  }),
});

MultiTabInvoiceEmailModalFormsContainer.propTypes = {
  debtorIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  firmInvoiceEmailSettings: PropTypes.shape({
    emailBody: PropTypes.string.isRequired,
    emailSubject: PropTypes.string.isRequired,
    sendCopyToUser: PropTypes.bool.isRequired,
  }).isRequired,
  invoiceEmailModalData: PropTypes.shape({
    invoice: PropTypes.shape({
      id: PropTypes.string.isRequired,
      debtors: PropTypes.arrayOf(DebtorEntityType),
      matter: MatterEntityType,
    }),
  }), // Required, but is undefined while fetching data
  invoiceTotalDurationsByIdMap: PropTypes.object.isRequired,
  isConsolidatedMode: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loggedInStaffMember: PropTypes.object.isRequired,
  matterIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  region: PropTypes.oneOf(Object.values(regionType)).isRequired,
  showIncludeDebtor: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  isMultiTabForm: PropTypes.bool.isRequired,
  // Callbacks
  onModalClose: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  onSendEmails: PropTypes.func.isRequired,
};

MultiTabInvoiceEmailModalFormsContainer.defaultProps = {
  invoiceEmailModalData: undefined,
};
