import React from 'react';
import PropTypes from 'prop-types';
import { TasksTab } from 'web/components';
import { FeatureLockModal } from 'web/react-redux/components/feature-lock';

const getScope = ({ matterId }) => `view-matter-tasks-route-${matterId}`;

export const ViewMatterTasksRouteContainer = ({ matterId, onClickLink }) => (
  <FeatureLockModal feature="tasks" onClickLink={onClickLink}>
    <TasksTab scope={getScope({ matterId })} matterId={matterId} />
  </FeatureLockModal>
);

ViewMatterTasksRouteContainer.displayName = 'ViewMatterTasksRouteContainer';

ViewMatterTasksRouteContainer.propTypes = {
  matterId: PropTypes.string.isRequired,
  onClickLink: PropTypes.func.isRequired,
};

ViewMatterTasksRouteContainer.defaultProps = {};
