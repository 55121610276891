import React from 'react';
import { LinkableText, Column } from '@sb-itops/react';
import { Table } from '@sb-itops/react/table';
import { Staff, MemoResults } from 'types';
import Styles from './MemoList.module.scss';

const ROW_HEIGHT = 76;

export interface IMemoListProps {
  entities?: MemoResults[];
  searchMeetsMinLength: boolean;
  hideMatter?: boolean;
  loading?: boolean;
  matterNames?: Record<string, string>;
  getPersonByUserId: (id: string) => Staff;
  onClickLink?: (data: { type: string; id: string; params?: string[] }) => void;
}

const regexp = /(.*?)#B#(.*?)#E#/g;

const ContentHighlightRenderer = (content: string) => {
  const element: (string | JSX.Element)[] = [];
  let index = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const m of content.matchAll(regexp)) {
    element.push(m[1]);
    element.push(
      <span key={m.index} className={Styles.highlight}>
        {m[2]}
      </span>,
    );
    index = (m.index || 0) + m[0].length;
  }
  element.push(content.slice(index, content.length));
  return element;
};

const TitleRenderer = ({ rowData }) =>
  rowData.memoTitleHighlight ? ContentHighlightRenderer(rowData.memoTitleHighlight) : rowData.memoTitle;

const MatterNameRenderer =
  (matterNames: Record<string, string>, onClickLink: (data: { type: string; id: string }) => void) =>
  ({ rowData }) => (
    <LinkableText
      text={matterNames[rowData.matterId]}
      onClickLink={() => onClickLink({ type: 'matter', id: rowData.matterId })}
      asLink
      inline
    />
  );

const customRowRenderer = ({ className, columns, index, key, style, rowData, onRowClick }) => {
  const a11yProps = { 'aria-rowindex': index + 1 };
  return (
    <div
      onClick={(event) => onRowClick({ event, index, rowData })}
      className={className}
      {...a11yProps}
      key={key}
      style={style}
    >
      <div style={{ ...style, display: 'flex', height: `${ROW_HEIGHT / 2}px`, top: 0 }}>{columns}</div>
      <div
        className={Styles.highlightContainer}
        style={{ ...style, height: `${ROW_HEIGHT / 2}px`, top: `${ROW_HEIGHT / 2}px`, paddingRight: '12px' }}
      >
        <div className={Styles.textContainer}>{ContentHighlightRenderer(rowData.memoRawTextHighlight || '')}</div>
      </div>
    </div>
  );
};

export const MemoList = ({
  entities,
  getPersonByUserId,
  matterNames,
  onClickLink,
  hideMatter,
  searchMeetsMinLength,
}: IMemoListProps) => (
  <div className={Styles.container}>
    {!searchMeetsMinLength && <div className={Styles.warning}>Searches must contain at least 3 characters</div>}
    <Table
      className={Styles.table}
      onRowClick={({ rowData }) => {
        if (onClickLink) {
          onClickLink({ type: 'matterMemos', id: rowData.matterId, params: [rowData.memoId] });
        }
      }}
      rowHeight={ROW_HEIGHT}
      list={entities || []}
      rowRenderer={customRowRenderer}
      dataLoading={!entities}
    >
      <Column disableSort key="memoTitle" dataKey="memoTitle" label="Title" flexGrow={2} cellRenderer={TitleRenderer} />
      {!hideMatter && onClickLink && (
        <Column
          key="matterId"
          dataKey="matterId"
          label="Matter"
          disableSort
          flexGrow={1}
          cellRenderer={MatterNameRenderer(matterNames || {}, onClickLink)}
        />
      )}
      <Column
        disableSort
        key="authorId"
        dataKey="authorId"
        label="Author"
        flexGrow={1}
        cellRenderer={({ rowData }) => (rowData.authorId ? getPersonByUserId(rowData.authorId)?.name : null)}
      />
    </Table>
  </div>
);
