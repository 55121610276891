'use strict';

const { capitalize } = require('@sb-itops/nodash');

const { PAYMENT_TYPE } = require('./payment-type');
const { PAYMENT_SOURCE } = require('./payment-source');

const getDirectPaymentOptions = (t) => [
  {
    label: capitalize(t('cheque')),
    paymentType: PAYMENT_TYPE.direct,
    paymentSource: PAYMENT_SOURCE.check,
    isDefault: true,
    value: PAYMENT_SOURCE.check,
  },
  {
    label: 'Bank Transfer',
    paymentType: PAYMENT_TYPE.direct,
    paymentSource: PAYMENT_SOURCE.bankTransfer,
    value: PAYMENT_SOURCE.bankTransfer,
  },
  {
    label: 'Cash',
    paymentType: PAYMENT_TYPE.direct,
    paymentSource: PAYMENT_SOURCE.cash,
    value: PAYMENT_SOURCE.cash,
  },
  {
    label: 'Credit Card',
    paymentType: PAYMENT_TYPE.direct,
    paymentSource: PAYMENT_SOURCE.creditCard,
    value: PAYMENT_SOURCE.creditCard,
  },
];

module.exports = {
  getDirectPaymentOptions,
};
