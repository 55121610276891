angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/home/billing/view-split-billing-bill/pdf';

  $stateProvider

    .state('home.billing.view-split-billing-bill.pdf', {
      url: '/pdf',
      templateUrl: BASE_DIRECTORY + '/view-split-billing-bill-pdf.html',
      data: {
        authorized: true,
        navHighlights: ['billing', 'bills'],
        tab: { type: 'invoice' }
      }
    });

});
