import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getLogger } from '@sb-itops/fe-logger';
import { useTranslation } from '@sb-itops/react';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import uuid from '@sb-itops/uuid';
import composeHooks from '@sb-itops/react-hooks-compose';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { featureActive } from '@sb-itops/feature';
import {
  activityCategories,
  activityRateOverrideTypes,
} from '@sb-billing/business-logic/activities/entities/constants';
import { entryType as activityEntryTypes } from '@sb-billing/business-logic/shared/entities';
import { costTypes } from '@sb-billing/business-logic/expense/services';
import { billingType } from '@sb-billing/business-logic/matters/billing-config';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { saveExpenseSchema } from './QuickExpenseEntry.schema';
import { QuickExpenseEntryContainer } from './QuickExpenseEntry.container';

const log = getLogger('QuickExpenseEntry.forms.container');

/**
 * The aim of this container is to manage form initialisation, reset and submission.
 *
 * In case this saves anyone else time, <QuickExpenseEntry /> goes to this form container first,
 * which then calls the QuickExpenseEntry.container.jsx, contrary to how we do it on a lot of the other forms
 */

const hooks = ({
  scope,
  matterSummaries,
  activities,
  tasks,
  quickAddDisabled,
  matter,
  loggedInStaff,
  utbmsCodesRequiredByFirm,
}) => ({
  // Controls the various form fields state.
  useQuickExpenseEntryForm: ({ showMatterField, showTaxField }) => {
    const { t } = useTranslation();
    const quickExpenseEntryForm = useForm({ scope, schema: saveExpenseSchema });
    const [isSubjectOverridable, setIsSubjectOverridable] = useState(true);

    const enabledInputOutputTax = featureActive('BB-12987');
    const enabledDisplayWithFees = hasFacet(facets.displayExpenseWithFees) && featureActive('BB-14971');

    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
      return () => {
        quickExpenseEntryForm.onClearForm();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const utbmsCodesRequiredForMatter =
      (utbmsCodesRequiredByFirm && matter?.billingConfiguration?.isUtbmsEnabled) || false;

    // Initialise the form.
    const getDefaultFieldValues = () => ({
      expenseDate: moment().toDate(),
      staffId: loggedInStaff?.id,
      matterId: matter?.id || '',
      activityId: '',
      isUtbmsActivity: false,
      taskId: '',
      subject: '',
      quantity: 1,
      priceInCents: 0,
      inputTaxAmountInCents: 0,
      outputTaxAmountInCents: 0,
      isTaxInclusive: false, // aka amountIncludesTax in entity
      isInputTaxOverridden: false,
      isOutputTaxOverridden: false,
      isBillable: matter?.billingConfiguration?.billingType !== billingType.NOT_BILLABLE,
      // Data that is derived from form and prop data to show the user in the UI.
      // The following data is not directly editable by the user.
      amountInCents: undefined,
      // for correct form validation
      showMatterField,
      showTaxField,
      utbmsCodesRequiredForMatter,
    });

    if (!quickExpenseEntryForm.formInitialised && (showMatterField || matter)) {
      quickExpenseEntryForm.onInitialiseForm(getDefaultFieldValues());
    }

    // This is a bit hacky. In the form state we store id's where the underlying container expects objects.
    // Forms2 doesn't like changing object instances due to immutability. On the plus side, working with objects
    // makes the underlying container simpler. However, it means that we need to map between ids and objects when
    // we pass props down into the container and when the underlying container updates the object fields.
    // The actual mapping takes place in the returned props from this hook.
    const objectFields = new Set(['matter', 'activity', 'task']);
    const currentMatter = matter || matterSummaries?.find(({ id }) => id === quickExpenseEntryForm.formValues.matterId);
    const currentActivity = activities.find((activity) => activity.id === quickExpenseEntryForm.formValues.activityId);
    const currentTask = tasks.find(
      (task) =>
        task.id === quickExpenseEntryForm.formValues.taskId || task.code === quickExpenseEntryForm.formValues.taskId, // taskId is task.code of custom task code
    );

    const saveExpense = async (formData) => {
      const marshalledData = {
        expenseId: uuid(),
        expenseVersionId: uuid(),
        matterId: formData.matterId,
        isBillable: formData.isBillable,
        expenseEarnerStaffId: formData.staffId,
        expenseActivityId: currentActivity?.category === activityCategories.CUSTOM ? currentActivity?.code : null,
        utbmsActivityCode: currentActivity?.category === activityCategories.UTBMS ? currentActivity?.code : null,
        utbmsTaskCode: currentTask?.code,
        costType: currentActivity?.expenseCostType ?? costTypes.HARD,
        description: formData.subject,
        expenseDate: moment(formData.expenseDate).format('YYYYMMDD'),
        quantity: Math.round(formData.quantity * 100),
        price: formData.priceInCents,
        amountIncludesTax: formData.isTaxInclusive,
        isTaxOverridden: formData.isInputTaxOverridden,
        isOutputTaxOverridden: enabledInputOutputTax ? formData.isOutputTaxOverridden : null,
        tax: formData.inputTaxAmountInCents,
        outputTax: enabledInputOutputTax ? formData.outputTaxAmountInCents : null,
        isAnticipated: false,
        displayWithFees: enabledDisplayWithFees ? currentActivity?.displayWithFees : null,
      };

      await dispatchCommand({ type: 'Billing.Expenses.Commands.SaveExpense', message: marshalledData });
    };

    const onClearForm = ({ clearExpenseDateField = true }) => {
      const defaultFieldValues = getDefaultFieldValues();
      const currentMatterId = quickExpenseEntryForm.formValues.matterId;
      const currentUtbmsCodesRequiredForMatter = quickExpenseEntryForm.formValues.utbmsCodesRequiredForMatter;

      quickExpenseEntryForm.onResetForm({
        ...defaultFieldValues,
        matterId: currentMatterId || defaultFieldValues.matterId,
        expenseDate: clearExpenseDateField ? moment().toDate() : quickExpenseEntryForm.formValues.expenseDate,
        utbmsCodesRequiredForMatter: currentUtbmsCodesRequiredForMatter,
      });
      setIsSubjectOverridable(true);
    };

    const onSubmitForm = async () => {
      try {
        // Validate and submit the form.
        quickExpenseEntryForm.onValidateForm();

        const isValid = await quickExpenseEntryForm.onSubmitFormWithValidation({ submitFnP: saveExpense });
        if (!isValid) {
          log.warn(`Quick ${t('capitalizeAllWords', { val: 'expense' })} entry form validation failed`);
          return;
        }

        displaySuccessToUser(`${t('capitalizeAllWords', { val: 'expense' })} added successfully`);
        // Do not reset expenseDate field after submitting
        onClearForm({ clearExpenseDateField: false });
      } catch (err) {
        log.error(`Failed to save quick ${t('capitalizeAllWords', { val: 'expense' })}`, err);
        displayErrorToUser(`Failed to save ${t('expense')} - please check your connection and try again.`);
      }
    };

    return {
      quickAddDisabled: quickAddDisabled || quickExpenseEntryForm.formSubmitting,
      formReady: quickExpenseEntryForm.formInitialised,
      formData: {
        ...quickExpenseEntryForm.formValues,
        // Map ID fields into objects for use by the underlying container.
        matter: currentMatter,
        activity: currentActivity,
        task: currentTask,
      },
      formErrors: {
        ...quickExpenseEntryForm.formFields,
        // Map ID fields into objects for use by the underlying container.
        matter: { isInvalid: quickExpenseEntryForm.formFields.matterId?.isInvalid },
        activity: { isInvalid: quickExpenseEntryForm.formFields.activityId?.isInvalid },
        task: { isInvalid: quickExpenseEntryForm.formFields.taskId?.isInvalid },
        staffId: { isInvalid: quickExpenseEntryForm.formFields.staffId?.isInvalid },
      },
      isSubjectOverridable,
      setIsSubjectOverridable,
      onUpdateFormData: (fieldUpdates) => {
        // Map object fields used in the underlying container back into ID's for storage in forms2 state.
        const formFieldUpdates = Object.entries(fieldUpdates).reduce((acc, [fieldName, fieldValue]) => {
          if (objectFields.has(fieldName)) {
            acc[`${fieldName}Id`] = fieldValue?.id;
          } else {
            acc[fieldName] = fieldValue;
          }

          return acc;
        }, {});

        // When the activity has changed, keep the isUtbmsActivity form field in sync for accurate validation of task field.
        if (fieldUpdates.activity) {
          formFieldUpdates.isUtbmsActivity = fieldUpdates.activity.category === activityCategories.UTBMS;
        }

        quickExpenseEntryForm.onUpdateFields(formFieldUpdates);

        if (quickExpenseEntryForm.submitFailed) {
          quickExpenseEntryForm.onValidateForm();
        }
      },
      onAddExpenseClicked: onSubmitForm,
      onClearForm,
    };
  },
});

export const QuickExpenseEntryFormsContainer = composeHooks(hooks)(({ formReady, ...containerProps }) => {
  if (!formReady) {
    return null;
  }

  return <QuickExpenseEntryContainer {...containerProps} />;
});

QuickExpenseEntryFormsContainer.displayName = 'QuickExpenseEntryFormsContainer';

QuickExpenseEntryFormsContainer.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      allStaffRate: PropTypes.number,
      category: PropTypes.oneOf(Object.values(activityCategories)),
      code: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      durationMins: PropTypes.number,
      inputTaxRate: PropTypes.number,
      isBillable: PropTypes.bool.isRequired,
      isTaxExempt: PropTypes.bool,
      isTaxInclusive: PropTypes.bool,
      outputTaxRate: PropTypes.number,
      rateOverrideType: PropTypes.oneOf([...Object.values(activityRateOverrideTypes), undefined]),
      type: PropTypes.oneOf([activityEntryTypes.EXPENSE]),
    }).isRequired,
  ).isRequired,
  loggedInStaff: PropTypes.shape({
    id: PropTypes.string,
  }),
  matterSummaries: PropTypes.arrayOf(
    PropTypes.shape({
      // Fields used in MatterTypeahead
      id: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      typeahead: PropTypes.string.isRequired,
      clientDisplay: PropTypes.string,
      otherSideDisplay: PropTypes.string,
      matterStarted: PropTypes.instanceOf(Date).isRequired,
      matterStartedISO: PropTypes.string.isRequired,
      matterNumber: PropTypes.string,
      // Fields required to create a fee
      billingConfiguration: PropTypes.shape({
        isUtbmsEnabled: PropTypes.bool.isRequired,
        billingType: PropTypes.string,
      }),
      matterType: PropTypes.shape({
        name: PropTypes.string,
      }),
    }).isRequired,
  ),
  matterSummariesDataLoading: PropTypes.bool,
  onFetchMatterSummaries: PropTypes.func,
  showTaxField: PropTypes.bool,
  scope: PropTypes.string.isRequired,
  showMatterField: PropTypes.bool,
  showTasksField: PropTypes.bool,
  staffMemberOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      entity: PropTypes.object.isRequired,
    }).isRequired,
  ),
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  ),
  taxRateBasisPoints: PropTypes.number,
};

QuickExpenseEntryFormsContainer.defaultProps = {
  loggedInStaff: undefined,
  matterSummariesDataLoading: undefined,
  onFetchMatterSummaries: undefined,
  showTaxField: false,
  showMatterField: false,
  showTasksField: false,
  staffMemberOptions: undefined,
  tasks: [],
  taxRateBasisPoints: undefined,
};
