import { gql } from '@apollo/client';

const query = gql`
  query SupportDataExplorerInvoice($id: ID!) {
    invoiceVersions(id: $id) {
      id
      versions {
        # id # excluding ID on purpose to avoid overriding Invoice cache with older versions
        debtors {
          id
          contact {
            id
          }
        }
        description
        discount {
          type
          fixedDiscount
          percentage
          description
          applyOnlyToFees
          percentage
        }
        dueDate
        eInvoiceOptions {
          enableDescriptionOnDemand
          descriptionOnDemandLineItemText
        }
        entries {
          id
          feeEntity {
            id
          }
          expenseEntity {
            id
          }
        }
        expensesOverallSummary
        feesOverallSummary
        feeTaxRate
        footer
        hasBeenFinalized
        invoiceNumber
        invoicePdfVersionId
        invoiceStatement {
          id
          number
          status
        }
        invoiceTitle
        invoiceTitleLine2
        isOriginalInvoice
        issuedDate
        layout {
          expenseLineItemConfiguration
          expenseSummaryLineDescription
          feeLineItemConfiguration
          feeSummaryLineDescription
          includeNonBillableItems
          showDescriptionForEntries
          showDurationOnEntries
          showNonBillableExpenses
          showNonBillableFees
          showRateDurationOnEntries
          showRateOnEntries
          showStaffNameOnEntries
        }
        matter {
          id
        }
        merchantPaymentReference
        paidDate
        retainerRequestAmount
        status
        pseudoStatus
        showRetainer
        summary
        surcharge {
          type
          fixedSurcharge
          percentageBp
          description
          applyTo
        }
        template {
          id
        }
        totals {
          id
        }
        user {
          id
          person {
            id
            initials
            name
          }
        }
        validFrom
        validTo
        waived
      }
    }
  }
`;

const notificationIds = ['InvoicingNotifications.InvoiceUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const SupportDataExplorerInvoice = {
  query,
  notificationIds,
};
