import * as React from 'react';
import PropTypes from 'prop-types';

import { facets, hasFacet } from '@sb-itops/region-facets';
import { featureActive } from '@sb-itops/feature';
import { getRegion } from '@sb-itops/region';
import { integerToDate, dateToInteger } from '@sb-itops/date';

import { useForm } from '@sb-itops/redux/forms2/use-form';
import composeHooks from '@sb-itops/react-hooks-compose';
import { capitalize } from '@sb-itops/nodash';
import { useTranslation } from '@sb-itops/react';
import {
  paymentMethodByName,
  paymentMethodLabel,
} from '@sb-billing/business-logic/expense-payment-details/entities/constants';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import { fetchGetP } from '@sb-itops/redux/fetch';
import { getLogger } from '@sb-itops/fe-logger';
import { getAccountId } from 'web/services/user-session-management';
import { activityCategories } from '@sb-billing/business-logic/activities/entities/constants';
import {
  convertToActivityGroupArray,
  convertToTaskGroupArray,
  getApplicableActivityCategories,
  deriveActivityRate,
} from '@sb-billing/business-logic/activities/services';
import {
  getRawAmount,
  calculateTaxAmount,
  calculateOutputTaxAmount,
  costTypes,
  costTypeLabels,
} from '@sb-billing/business-logic/expense/services';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import uuid from '@sb-itops/uuid';
import { PrintNow, PrintManually, PrintLater } from '@sb-billing/business-logic/cheques';
import { useUploadFile } from 'web/hooks';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { PRINT_OPERATING_CHEQUE_MODAL_ID } from 'web/components';

import { ExpenseModal } from './ExpenseModal';
import { expenseTypeEnum } from './ExpenseModalBody';
import { expenseFormSchema } from './ExpenseForm.yup';

const region = getRegion();
const log = getLogger('ExpenseModal.forms.container');

/**
 * getMatterTypeaheadDefaultValue
 *
 * For an existing expense, provide the default data for the matter (typeahead) field
 *
 * @param {Object} params
 * @param {Object} params.matter
 * @returns {MatterSummaries}
 */
function getMatterTypeaheadDefaultValue({ matter }) {
  if (!matter) {
    return [];
  }

  const typeahead = [
    matter.matterNumber,
    matter.clientDisplay,
    matter.otherSideDisplay,
    matter.matterType?.name,
    matter.attorneyResponsible?.name,
    matter.attorneyResponsible?.initials,
    matter.description,
  ];
  const defaultMatterSummaries = [
    {
      ...matter,
      display: getMatterDisplay(matter, matter.matterType?.name),
      matterClientNames: matter.clientNames,
      matterStarted: matter.matterStarted ? new Date(matter.matterStarted) : undefined,
      matterStartedISO: matter.matterStarted ? moment(matter.matterStarted, 'YYYYMMDD').toISOString() : '',
      typeahead: typeahead.filter((m) => m).join(' '),
    },
  ];
  return defaultMatterSummaries;
}

/**
 * getInitiallyLinkedMatter
 *
 * During the initialisation process, an expense can be intrinsically linked to a matter
 *  e.g. new expenses on a matter page (linked to said matter)
 *  e.g. existing expenses (linked to whatever matter was selected when saving)
 *
 * Expenses can also not have an intrinsic link to a matter
 *  e.g. new expenses via the global quick add ("+" component)
 *
 * @param {Object} params
 * @param {Object} [params.expense]
 * @param {boolean} params.isNewExpense
 * @param {Object} [params.matter]
 *
 * @returns {Object|undefined}
 */
function getInitiallyLinkedMatter({ expense, isNewExpense, matter }) {
  // Existing expenses
  if (!isNewExpense && expense) {
    return expense.matter;
  }

  // New expense with a connection to a matter
  if (isNewExpense && matter) {
    return matter;
  }

  // New expense without a connection to a matter
  return undefined;
}

/**
 * Provides the relevant field's ContactTypeahead with:
 *  1. A default option or
 *  2. The last contact selected (to preserve data until cleared or marshalling data)
 *
 * This is required for the "Supplier" and "Pay to" fields
 *
 * @param {Object} params
 * @param {Object} params.contact
 * @param {Object} params.contact.displayName
 * @param {Object} params.contact.id
 * @returns {Array<Object>}
 */
function getDefaultContactTypeaheadOption({ contact }) {
  // If id is missing:
  //  * No contact was linked upon form initialisation or the
  //  * Contact was cleared by user
  if (contact?.id) {
    return [
      {
        data: contact,
        label: contact.displayNameFull || contact.displayName,
        value: contact.id,
      },
    ];
  }

  return [];
}

/**
 * Provides the URL to download the expense attachment
 * @param {Object} params
 * @param {string} params.accountId
 * @param {string} params.filePath
 * @returns {Promise<string>}
 */
async function getAttachmentDownloadUrl({ accountId, filePath }) {
  try {
    const filePathUriEncoded = encodeURIComponent(filePath);
    const path = `/itops/files/${accountId}/FileUploads/?filePath=${filePathUriEncoded}`;
    const response = await fetchGetP({ path });
    return response && response.body && response.body.downloadUrl;
  } catch (error) {
    return log.error('Error occurred while attempting to retrieve the download URL', error);
  }
}

const hooks = () => ({
  useExpenseForm: ({
    activities,
    areQueriesLoading,
    areUtbmsCodesRequiredByFirm,
    availableOperatingChequeNumber,
    expense,
    isNewExpense,
    isUtbmsEnabledForFirm,
    lastOperatingChequeNumber,
    loggedInStaff,
    matter,
    matterId,
    matterSummaries,
    operatingBankAccountId,
    operatingChequePrintSettings,
    sbAsyncOperationsService,
    scope,
    tasks,
    taxRateInBasisPoints,
    // Callbacks
    onClearAvailableChequeNumberCache,
    onClickLink,
    onExpenseSave,
    onGetAvailableOperatingChequeNumber,
    onModalClose,
  }) => {
    const { t } = useTranslation();
    const { uploadFile } = useUploadFile();
    const [isSubjectOverridable, setIsSubjectOverridable] = React.useState(isNewExpense);
    const [isCostTypeOverridable, setIsCostTypeOverridable] = React.useState(isNewExpense);
    // This ref is used for Save & New to fetch and reset the cheque number field
    const previouslySavedChequeNumberRef = React.useRef();

    const anticipatedDisbursementsEnabled = featureActive('BB-9573');
    const allowChequeNumberDuplication = hasFacet(facets.allowDuplicateCheque);
    const inputOutputTaxEnabled = featureActive('BB-12987');
    const enabledHardAndSoftCosts = featureActive('BB-13352');
    const chequeMemoEnabled = hasFacet(facets.chequeMemo);
    const [isDeletingExpense, setIsDeletingExpense] = React.useState(false);

    const [showAddPayToContactForm, setShowAddPayToContactForm] = React.useState(false);
    const [showAddSupplierContactForm, setShowAddSupplierContactForm] = React.useState(false);

    const expenseForm = useForm({
      scope,
      schema: expenseFormSchema,
    });
    const {
      formFields,
      formInitialised,
      formSubmitting,
      formValues,
      submitFailed,
      onClearForm,
      onInitialiseForm,
      onValidateForm,
    } = expenseForm;

    const isExpenseFinalised = !!expense?.finalized;
    const invoice = expense?.invoice;
    const invoiceNumber = invoice?.invoiceNumber;
    const isOnDraftInvoice = !!invoice && !isExpenseFinalised;
    const isOnFinalisedInvoice = !!invoice && isExpenseFinalised;

    const onNavigateToInvoice = () => {
      if (!invoice) {
        return;
      }

      onClickLink({ type: 'invoice', id: invoice.id });
      onModalClose();
    };

    const isAnticipatedDisbursement = !!formValues?.isAnticipated;
    const savedAsAnticipatedDisbursement = !!expense?.isAnticipated;
    const isOnFinalisedInvoiceOrInvoicedExternally = isOnFinalisedInvoice || expense?.isInvoicedExternally;
    const isFormDisabled =
      isOnFinalisedInvoiceOrInvoicedExternally ||
      !formInitialised ||
      formSubmitting ||
      isDeletingExpense ||
      showAddPayToContactForm ||
      showAddSupplierContactForm;
    // When an expense has been paid (and not yet finalised), most of the form is disabled, but parts of the form are still available for editing.
    //
    // Editable fields should consist of:
    //  1. Staff
    //  2. Activity
    //  3. Subject
    //  4. Description
    //  5. Billable
    //  6. Supplier
    //  7. Supplier reference
    //  8. Payment due
    const isPaidExpenseWithAdEnabled =
      anticipatedDisbursementsEnabled && !savedAsAnticipatedDisbursement && !!expense?.expensePaymentDetails?.isPayable;
    const isPaidAD =
      anticipatedDisbursementsEnabled && savedAsAnticipatedDisbursement && !!expense?.expensePaymentDetails?.isPaid;
    const operatingChequeExists = !!expense?.operatingCheque?.id;
    // Criteria on whether expense is paid or not:
    //  1. AD feature enabled:
    //    a. Expense type - expense
    //      * isPayable/isPaid (kept in sync) booleans reflect status
    //    a. Expense type - AD
    //      * isPaid boolean reflects status
    //  2. AD feature disabled:
    //    a. Operating cheque
    //      * Existence reflects status (i.e. if present, it is paid)
    const isPaidExpense = !isNewExpense && (isPaidExpenseWithAdEnabled || isPaidAD || operatingChequeExists);
    // 9. Payment toggles
    //  * Only disabled for paid expenses when they are of payment type "cheque"
    //  * Three toggles are used under different conditions:
    //    - "Print cheque" checkbox (AD disabled)
    //    - "Payable to supplier" toggle (AD enabled - Expense type)
    //    - "Supplier paid" toggle (AD enabled - AD type)
    const selectedPaymentMethod = formValues?.expensePaymentDetails?.paymentMethod;
    const isChequePaymentMethod =
      (anticipatedDisbursementsEnabled && selectedPaymentMethod === paymentMethodByName.CHEQUE) ||
      !anticipatedDisbursementsEnabled; // When the AD feature is disabled, cheques are the only payment method
    const deriveIsPaymentToggledDisabled = () => {
      const isPaidChequeExpense = isChequePaymentMethod && isPaidExpense;
      const nonPayableExpenseOnFinalisedInvoice = isOnFinalisedInvoice && !isPaidExpenseWithAdEnabled;
      const nonPaidAdOnFinalisedInvoice =
        isOnFinalisedInvoice && savedAsAnticipatedDisbursement && !expense?.expensePaymentDetails?.isPaid;

      if (formSubmitting) {
        return true;
      }

      if (isPaidExpense) {
        return isPaidChequeExpense;
      }

      if (anticipatedDisbursementsEnabled) {
        // If an AD was finalised on an invoice when it was "not paid", it can be made "paid" afterwards
        if (nonPaidAdOnFinalisedInvoice) {
          return false;
        }

        // If an expense was finalised on an invoice when it was "not payable", it cannot be made "payable" afterwards
        if (nonPayableExpenseOnFinalisedInvoice) {
          return true;
        }
      }

      return false;
    };

    const deriveIsSupplierDetailsDisabled = () => {
      if (anticipatedDisbursementsEnabled) {
        // The supplier detail fields (including "payment due") are only disabled when both conditions are met:
        //  1. On finalised invoice or invoiced externally and is
        //  2. An AD
        return (
          (isOnFinalisedInvoiceOrInvoicedExternally && isPaidAD) ||
          formSubmitting ||
          showAddSupplierContactForm ||
          showAddPayToContactForm
        );
      }

      return true; // Fields don't exist when AD feature is disabled
    };

    const isPaymentDetailsDisabled =
      isPaidExpense || formSubmitting || showAddSupplierContactForm || showAddPayToContactForm;
    const isPaymentToggleDisabled = deriveIsPaymentToggledDisabled();
    const isSupplierDetailsDisabled = deriveIsSupplierDetailsDisabled();

    // Disable button, only if no changes can be made
    const deriveIsSaveOrUpdateButtonDisabled = () =>
      isFormLoading ||
      formSubmitting ||
      showAddSupplierContactForm ||
      showAddPayToContactForm ||
      (isFormDisabled && isPaymentDetailsDisabled && isPaymentToggleDisabled && isSupplierDetailsDisabled);

    const isTaxFacetEnabled = hasFacet(facets.tax);
    const showTaxFields = isTaxFacetEnabled;

    // eslint-disable-next-line arrow-body-style
    React.useEffect(() => {
      return () => {
        onClearForm();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deriveIsSaveAndNewDisabled = () => {
      const isPrintNowOption =
        formValues?.expensePaymentDetails?.paymentMethod === paymentMethodByName.CHEQUE &&
        formValues?.operatingChequePrintOptions?.chequePrintMethod === PrintNow;
      const isPaymentToggleOrCheckboxEnabled =
        (anticipatedDisbursementsEnabled && formValues?.isAnticipated && !!formValues?.expensePaymentDetails?.isPaid) ||
        (anticipatedDisbursementsEnabled &&
          !formValues?.isAnticipated &&
          !!formValues?.expensePaymentDetails?.isPayable) ||
        (!anticipatedDisbursementsEnabled && !!formValues?.operatingChequePrintOptions?.chequePrintActive);

      if (isFormLoading || isFormDisabled) {
        return true;
      }

      // Normally, if the "Print Now" option is selected, we disable the "Save & New" button
      //  * But if the user disables the toggle or checkbox, we should provide the option to "Save & New" again
      //  * The fields that are hidden by the toggle or checkbox won't be sent to the server in the marshalling process
      return isPaymentToggleOrCheckboxEnabled ? isPrintNowOption : false;
    };

    /**
     * Form initialisation
     */
    const [isGeneratingAttachmentDownloadUrl, setIsGeneratingAttachmentDownloadUrl] = React.useState(false);
    let isReadyToInitialiseForm = !areQueriesLoading && !formInitialised;

    // Re-initialise the form to update operating cheque values if the form is
    // disabled and we fetched newer values. This can happen in rare cases where
    // the user re-opens the modal before a cheque entity is created. We only
    // allow for this if the form is disabled (expense is finalised) to prevent
    // changing user-inputted values.
    if (
      !areQueriesLoading &&
      formInitialised &&
      isFormDisabled &&
      expense?.operatingCheque?.chequeNumber &&
      expense.operatingCheque.chequeNumber !== formValues?.operatingCheque?.chequeNumber
    ) {
      isReadyToInitialiseForm = true;
    }
    const isFormLoading = areQueriesLoading || !formInitialised || isGeneratingAttachmentDownloadUrl;

    const initiallyLinkedMatter = getInitiallyLinkedMatter({
      expense,
      isNewExpense,
      matter,
    });
    const defaultMatterSummaries = formInitialised
      ? getMatterTypeaheadDefaultValue({
          matter: initiallyLinkedMatter,
        })
      : [];

    const defaultSupplierContactOptions = getDefaultContactTypeaheadOption({
      contact: formValues?.expensePaymentDetails?.supplier,
    });

    const defaultPayToOptions = getDefaultContactTypeaheadOption({ contact: formValues?.operatingCheque?.payTo });

    if (isReadyToInitialiseForm) {
      const defaultValues = getDefaultValues();

      if (defaultValues.attachmentFile.location) {
        setIsGeneratingAttachmentDownloadUrl(true);
      }

      onInitialiseForm(defaultValues);
    }

    function getDefaultValues() {
      // Only the activity and task codes get persisted to the expense entity
      //  * The activityCode attribute represents either the custom or UTBMS activity code
      const defaultActivity = isNewExpense
        ? undefined
        : activities.find((activity) => activity.code === expense?.activityCode);
      const defaultTask = isNewExpense ? undefined : tasks.find((task) => task.code === expense?.utbmsTaskCode);

      const getOrInitialiseExpensePaymentDetails = ({ initialisedOperatingCheque }) => {
        const initialisedExpensePaymentDetails = {
          isPaid: false,
          isPayable: false,
          paymentAccountName: undefined,
          paymentDue: undefined,
          paymentMethod: anticipatedDisbursementsEnabled
            ? paymentMethodByName.BANK_TRANSFER
            : paymentMethodByName.CHEQUE,
          paymentReference: undefined,
          supplier: {
            // Need this format for validation purposes
            //  * e.g. to access expensePaymentDetails?.supplier?.id?.isInvalid
            //
            // We need to provide the shape of the object during the initialisation process
            //  * Without this, we don't have access to these properties
            //  * e.g. We would only have expensePaymentDetails?.supplier?.isInvalid
            id: undefined,
            displayName: undefined,
          },
          supplierReference: undefined,
        };
        const existingExpensePaymentDetails = expense?.expensePaymentDetails;

        if (existingExpensePaymentDetails) {
          return {
            ...initialisedExpensePaymentDetails,
            ...existingExpensePaymentDetails,
            paymentDue: existingExpensePaymentDetails.paymentDue
              ? integerToDate(existingExpensePaymentDetails.paymentDue)
              : undefined,
            // Payment method defaults to "bank transfer"
            paymentMethod:
              existingExpensePaymentDetails.paymentMethod === paymentMethodByName.NONE
                ? paymentMethodByName.BANK_TRANSFER
                : existingExpensePaymentDetails.paymentMethod,
            // Supplier can be null for an existing expense
            supplier: {
              id: existingExpensePaymentDetails.supplier?.id,
              displayName: existingExpensePaymentDetails.supplier?.displayNameFull,
            },
          };
        }

        // Need to handle the case where:
        //  * An expense was created when AD feature is disabled and
        //  * The expense is viewed when AD feature is enabled
        //
        // In such cases, expensePaymentDetails is null, but an operatingCheque exists
        //  * If an expense was created when the AD feature was enabled, if an operatingCheque exists, so must the expensePaymentDetails
        if (!existingExpensePaymentDetails && initialisedOperatingCheque.id) {
          return {
            ...initialisedExpensePaymentDetails,
            isPaid: true,
            isPayable: true,
            paymentMethod: paymentMethodByName.CHEQUE,
          };
        }

        return initialisedExpensePaymentDetails;
      };

      const getOrInitialiseOperatingCheque = () => {
        const initialisedCheque = {
          id: undefined,
          chequeNumber: undefined,
          chequeMemo: undefined,
          payTo: {
            // Need this format for validation purposes (read above comment for context)
            id: undefined,
            displayName: undefined,
          },
        };
        const existingOperatingCheque = expense?.operatingCheque;

        if (existingOperatingCheque) {
          return {
            ...initialisedCheque,
            ...existingOperatingCheque,
            // payTo can be null for an existing expense
            payTo: {
              id: existingOperatingCheque.payTo?.id,
              displayName: existingOperatingCheque.payTo?.displayNameFull,
            },
          };
        }

        return initialisedCheque;
      };

      const initialiseOperatingChequePrintOptions = ({ initialisedOperatingCheque }) => {
        const isChequePaid = anticipatedDisbursementsEnabled
          ? expense?.expensePaymentDetails?.isPaid &&
            expense?.expensePaymentDetails?.paymentMethod === paymentMethodByName.CHEQUE
          : !!initialisedOperatingCheque?.id;

        return {
          allowChequeNumberDuplication,
          chequePrintActive: isChequePaid, // Need for backwards compatibility: "Print cheque" checkbox, when AD feature is disabled
          chequePrintMethod: operatingChequePrintSettings?.printMethod, // This value is not persisted to the entity, but it is used for creating expenses and operating cheques
        };
      };

      const initialisedOperatingCheque = getOrInitialiseOperatingCheque();

      const getAmountInCents = () => {
        // Default value
        if (!expense) {
          return 0;
        }

        // Amount including tax
        if (isTaxFacetEnabled && expense.amountIncludesTax) {
          const amountIncludingTax = expense.amountExcTax + expense.tax;
          return amountIncludingTax;
        }

        // Amount excluding tax
        return expense.amountExcTax;
      };

      return {
        // The expenseVersionId:
        //  * Is always required when saving a new or existing expense (and it must be unique)
        //  * It is also used to refresh certain UI elements via React's "key" on a "Save & New"
        //    * E.g. The attachment field (file name), supplier field (selected contact)
        //  * It is *NOT* sent to in the command if the expense is finalised
        expenseVersionId: uuid(),
        // Section 1 - General expense fields
        amountInCents: getAmountInCents(),
        amountIncludesTax: expense?.amountIncludesTax ?? false,
        description: expense?.notes, // Description field maps to notes
        expenseDate: expense?.expenseDate ? integerToDate(expense.expenseDate) : moment().toDate(),
        expenseEarnerStaffId: expense?.expenseEarnerStaff?.id ?? loggedInStaff?.id,
        expenseId: expense?.id,
        costType: expense?.costType ?? costTypes.HARD,
        isAnticipated: expense?.isAnticipated ?? false,
        isBillable: expense?.isBillable ?? true,
        isTaxOverridden: expense?.isTaxOverridden ?? false,
        isOutputTaxOverridden: expense?.isOutputTaxOverridden ?? false,
        priceInCents: expense?.price ?? 0,
        quantity: expense?.quantity ? expense.quantity / 100 : 1,
        subject: expense?.description ?? '', // Subject field maps to description
        taxInCents: expense?.tax ?? 0,
        outputTaxInCents: expense?.outputTax ?? expense?.tax ?? 0,
        isWriteOff: expense?.waived ?? false,
        isDisplayWithFees: expense?.displayWithFees ?? false,
        // IDs that map with objects (for more context, see below)
        activityId: defaultActivity?.id,
        matterId: initiallyLinkedMatter?.id ?? matterId,
        subjectActivityId: undefined,
        taskId: defaultTask?.id,
        // Section 2 - Payment details section
        operatingCheque: initialisedOperatingCheque,
        expensePaymentDetails: getOrInitialiseExpensePaymentDetails({ initialisedOperatingCheque }),
        operatingChequePrintOptions: initialiseOperatingChequePrintOptions({ initialisedOperatingCheque }),
        // Section 3 - Attachment
        attachmentFile: {
          location: expense?.attachmentFile?.location,
          fileName: expense?.attachmentFile?.fileName,
          // URL will be generated and updated in the dependentHooks if an attachment exists
          url: undefined,
          // State required for new uploads
          fileSize: undefined,
          fileType: undefined,
          uploadedFile: undefined,
        },
      };
    }

    // Form state contains IDs (forms2 doesn't like changing object instances due to immutability)
    // Some nested components use objects
    // These objects will be mapped back into IDs during form updates
    const objectFields = new Set(['activity', 'matter', 'subjectActivity', 'task']);
    const currentActivity = activities.find((activity) => activity.id === formValues.activityId);
    const currentMatter =
      matterSummaries?.find(({ id }) => id === formValues.matterId) ||
      defaultMatterSummaries?.find(({ id }) => id === formValues.matterId);
    const currentSubjectActivity = activities.find((activity) => activity.id === formValues.subjectActivityId);
    const currentTask = tasks.find((task) => task.id === formValues.taskId);

    // The form state contains IDs
    // The container and nested components use objects
    // These objects will be mapped back into IDs during field updates
    const formDataMappedObjects = {
      activity: currentActivity,
      matter: currentMatter,
      subjectActivity: currentSubjectActivity,
      task: currentTask,
    };

    /**
     * Form updates
     */

    // All field update functions will call this function to:
    //  1. Prepare data for form
    //  2. Perform any relevant calculations
    //
    // onChequeReferenceFieldUpdate is the one exception
    //  * Because its validation requires data from the server
    //  * It will directly apply the form updates and then manually trigger validation
    function applyFormUpdates({ newFieldValues }) {
      const latestFieldValues = {
        ...formValues,
        ...newFieldValues,
      };

      // [1] Prepare data
      // Expense calculations require quantity to be supplied in basis points
      const quantityInBasisPoints = (latestFieldValues.quantity || 0) * 100;
      const priceInCents = Number.isFinite(latestFieldValues.priceInCents) ? latestFieldValues.priceInCents : 0;

      // [2] Calculate amount and tax
      const amountInCents = getRawAmount({ quantity: quantityInBasisPoints, price: priceInCents });
      let taxInCents = 0;
      let outputTaxInCents = 0;
      const isTaxExempt = newFieldValues?.activity?.isTaxExempt ?? currentActivity?.isTaxExempt;

      if (isTaxFacetEnabled) {
        let activityInputTaxRate;
        let activityOutputTaxRate;
        // newFieldValues only contains the activity object when the activity field is updated
        if (inputOutputTaxEnabled) {
          if (newFieldValues?.activity) {
            activityInputTaxRate = newFieldValues.activity.inputTaxRate;
            activityOutputTaxRate = newFieldValues.activity.outputTaxRate;
          } else {
            activityInputTaxRate = currentActivity?.inputTaxRate;
            activityOutputTaxRate = currentActivity?.outputTaxRate;
          }
        }

        const editedExpense = {
          isTaxOverridden: latestFieldValues.isTaxOverridden,
          isOutputTaxOverridden: latestFieldValues.isOutputTaxOverridden,
          tax: latestFieldValues.taxInCents,
          outputTax: latestFieldValues.outputTaxInCents,
          amountIncludesTax: latestFieldValues.amountIncludesTax,
          quantity: quantityInBasisPoints,
          price: priceInCents,
        };

        if (isTaxExempt) {
          // You have a tax free activity set up for a disbursement, but a user can override the tax
          // & make it inc. tax at the point of entering the disbursement & the overridden tax is then applied.
          // Jess thinks the reason for allowing this would be to allow a level of flexibility to the user.
          // The activity/ disbursement might be tax free most of the time but they might on occasion have the
          // same disbursement but from a different supplier who do charge tax, so they could need to edit it on occasion.
          taxInCents = latestFieldValues.isTaxOverridden ? latestFieldValues.taxInCents : 0;
          // It is a valid scenario that an activity code has tax exempt but with output tax rate, in this case we should re-calculate output tax if it's not overridden
          if (!latestFieldValues.isOutputTaxOverridden) {
            outputTaxInCents =
              activityOutputTaxRate !== undefined && activityOutputTaxRate !== null
                ? calculateOutputTaxAmount({
                    expense: {
                      ...editedExpense,
                      tax: taxInCents,
                    },
                    outputTaxRate: activityOutputTaxRate,
                  })
                : 0; // Tax exemption is really applicable to input tax only, when output tax is not overridden, it should be set to same as the input tax (like how it behaves for non tax exempt activity at below). But because Desktop currently implements this as 0, we’ll keep 0 for consistency for now. I bug ticket (BB-13867) has been raised to address this.
          } else {
            outputTaxInCents = latestFieldValues.outputTaxInCents;
          }
        } else {
          const inputTaxRate =
            activityInputTaxRate !== undefined && activityInputTaxRate !== null
              ? activityInputTaxRate
              : (taxRateInBasisPoints ?? 0); // taxRateInBasisPoints can be undefined

          taxInCents = calculateTaxAmount(
            editedExpense,
            inputTaxRate, // taxRateInBasisPoints can be undefined
          );

          if (activityOutputTaxRate === undefined || activityOutputTaxRate === null) {
            // If no activity selected or the selected activity code selected does not have output tax rates set up
            // Set the the Output tax value its modified value or input tax value
            outputTaxInCents = latestFieldValues.isOutputTaxOverridden
              ? latestFieldValues.outputTaxInCents
              : taxInCents;
          } else {
            outputTaxInCents = calculateOutputTaxAmount({
              expense: {
                ...editedExpense,
                tax: taxInCents,
              },
              outputTaxRate: activityOutputTaxRate,
            });
          }
        }
      }

      // New data to update form with
      const fieldValuesToUpdate = {
        ...newFieldValues,
        amountInCents,
        taxInCents,
        outputTaxInCents,
      };

      return onUpdateForm({
        fieldValues: fieldValuesToUpdate,
      });
    }

    // Updates the form with new values
    function onUpdateForm({ fieldValues }) {
      // Map objects back to IDs for form state
      const mappedFieldValues = Object.entries(fieldValues).reduce((acc, [fieldName, fieldValue]) => {
        if (objectFields.has(fieldName)) {
          acc[`${fieldName}Id`] = fieldValue?.id;
        } else {
          acc[fieldName] = fieldValue;
        }

        return acc;
      }, {});

      expenseForm.onUpdateFields(mappedFieldValues);

      if (submitFailed) {
        validateForm();
      }
    }

    /**
     * Expense section field updates
     */

    // Simple field updates
    function onUpdateField({ field, newValue }) {
      const fieldValuesToUpdate = {
        [field]: newValue,
      };

      applyFormUpdates({ newFieldValues: fieldValuesToUpdate });
    }

    function onUpdateSubjectField({ newSubject }) {
      // The subject should not be overridden if:
      //  1. User has modified the subject and,
      //  2. A subject exists (i.e. not blank)
      setIsSubjectOverridable(newSubject.trim() === '');

      const fieldsToUpdate = {
        subject: newSubject,
      };

      return applyFormUpdates({ newFieldValues: fieldsToUpdate });
    }

    function onUpdateMatterField({ selectedMatter }) {
      const fieldsToUpdate = {};
      const newMatter = selectedMatter?.data; // If cleared, selectedMatter is null

      fieldsToUpdate.matter = newMatter;

      if (isNewExpense) {
        // Clear relevant fields in create mode (preserve values in edit mode, when required)
        fieldsToUpdate.activity = undefined;
        fieldsToUpdate.subject = '';
        fieldsToUpdate.task = undefined;
      }

      // Logic for when:
      //  1. Edit mode
      //  2. A new matter has been selected (does not apply for clearing matters)
      //  3. UTBMS is enabled for firm
      if (!isNewExpense && newMatter && isUtbmsEnabledForFirm) {
        const newMatterHasUtbmsEnabled = newMatter?.billingConfiguration?.isUtbmsEnabled;

        // 4a. When UTBMS codes are required
        if (areUtbmsCodesRequiredByFirm) {
          if (isSubjectOverridable) {
            fieldsToUpdate.subject = '';
          }

          if (newMatterHasUtbmsEnabled) {
            // 5a. When UTBMS is enabled for newly selected matter
            //  * If an activity was previously selected (e.g. post clearing matter), clear it
            fieldsToUpdate.activity = undefined;
          } else {
            // 5b. When UTBMS is disabled for newly selected matter
            //  * If a task was previously selected, clear it
            fieldsToUpdate.task = undefined;
          }
        }

        // 4b. When UTBMS codes are not required
        if (!areUtbmsCodesRequiredByFirm) {
          // 5a. When UTBMS is enabled for newly selected matter
          //  * Preserve task field, unless the new matter does not have UTBMS enabled
          if (!newMatterHasUtbmsEnabled) {
            if (isSubjectOverridable) {
              fieldsToUpdate.subject = '';
            }
            fieldsToUpdate.task = undefined;
          }
        }
      }

      return applyFormUpdates({ newFieldValues: fieldsToUpdate });
    }

    function onUpdateActivityField({ isSelectedFromSubjectDropdown, selectedActivity }) {
      // An activity can be selected via:
      //  1. Activity field dropdown
      //  2. Subject typeahead

      // Clearing the subject field
      if (!selectedActivity) {
        applyFormUpdates({
          newFieldValues: {
            subjectActivity: undefined,
          },
        });
        return;
      }

      const isUtbmsActivity = selectedActivity.category === activityCategories.UTBMS;

      const fieldsToUpdate = {
        activity: selectedActivity,
        task: undefined, // If an activity is selected, the task must be cleared
        subjectActivity: selectedActivity,
      };

      if (isNewExpense) {
        const quantityInBasisPoints = selectedActivity.durationMins || 0; // default quantity is stored in durationMins

        fieldsToUpdate.isBillable = selectedActivity.isBillable;
        fieldsToUpdate.isTaxExempt = selectedActivity.isTaxExempt;
        fieldsToUpdate.amountIncludesTax = selectedActivity.isTaxInclusive ?? false;
        fieldsToUpdate.priceInCents = isUtbmsActivity
          ? formValues.priceInCents
          : deriveActivityRate({ activity: selectedActivity });
        fieldsToUpdate.quantity = isUtbmsActivity ? 1 : quantityInBasisPoints / 100;

        if (isSelectedFromSubjectDropdown || isSubjectOverridable) {
          fieldsToUpdate.subject = selectedActivity.description;
        }

        if (isCostTypeOverridable) {
          fieldsToUpdate.costType = selectedActivity.expenseCostType ?? costTypes.HARD;
        }

        if (
          supportsDisplayWithFees &&
          (formValues?.costType === costTypes.SOFT || fieldsToUpdate.costType === costTypes.SOFT)
        ) {
          fieldsToUpdate.isDisplayWithFees = selectedActivity.displayWithFees ?? false;
        }
      } else {
        // Edit mode - don't clobber data
      }

      if (!isNewExpense && isSelectedFromSubjectDropdown) {
        fieldsToUpdate.subject = selectedActivity.description;
      }

      applyFormUpdates({ newFieldValues: fieldsToUpdate });
    }

    function onUpdateTaskField({ selectedTask }) {
      const fieldsToUpdate = {
        activity: undefined, // If a task is selected, activities are cleared
        task: selectedTask,
      };

      if (isNewExpense) {
        // UTBMS tasks are always set to billable in create mode (unlike custom tasks they do not have isBillable as a property)
        fieldsToUpdate.isBillable = selectedTask.isBillable ?? true;
        if (isSubjectOverridable) {
          fieldsToUpdate.subject = selectedTask.description;
        }
      }

      applyFormUpdates({ newFieldValues: fieldsToUpdate });
    }

    function onUpdateTaxField({ newTaxAmount }) {
      if (!isTaxFacetEnabled) {
        return;
      }

      const fieldsToUpdate = {
        taxInCents: newTaxAmount || 0,
        isTaxOverridden: true,
      };

      applyFormUpdates({ newFieldValues: fieldsToUpdate });
    }

    function onUpdateOutputTaxField({ newOutputTaxAmount }) {
      if (!isTaxFacetEnabled) {
        return;
      }

      const fieldsToUpdate = {
        outputTaxInCents: newOutputTaxAmount || 0,
        isOutputTaxOverridden: true,
      };

      applyFormUpdates({ newFieldValues: fieldsToUpdate });
    }

    function onUpdateBillableField({ newBillableValue }) {
      const markingExpenseAsNonBillable = !newBillableValue;

      const fieldsToUpdate = {
        isBillable: newBillableValue,
      };

      if (markingExpenseAsNonBillable) {
        fieldsToUpdate.isWriteOff = false;
      }

      applyFormUpdates({ newFieldValues: fieldsToUpdate });
    }

    /**
     * Supplier and payment section field updates
     */

    // If the user populates the fields that are displayed/hidden by the "payable to supplier" (expense) or "supplier paid" (AD) toggles, preserve those values, even if the toggle is switched off
    //  * This is for user convenience (e.g. just in case they switched it off by accident or change their mind - they don't have to type it out again)
    //  * Should they end up submitting the form with the switch toggled off, we will clear those values when marshalling the data

    // Some fields are payment method specific
    const isBankTransferPaymentMethod = selectedPaymentMethod === paymentMethodByName.BANK_TRANSFER;
    const isDirectDebitPaymentMethod = selectedPaymentMethod === paymentMethodByName.DIRECT_DEBIT;

    // For simple expensePaymentDetails property updates
    function onExpensePaymentDetailsFieldUpdate({ field, newValue }) {
      const fieldsToUpdate = {};

      // Payment account name and reference are "Bank Transfer" and "Direct Debit" specific fields
      if (
        ['paymentAccountName', 'paymentReference'].includes(field) &&
        !isBankTransferPaymentMethod &&
        !isDirectDebitPaymentMethod
      ) {
        return;
      }

      fieldsToUpdate.expensePaymentDetails = {
        ...formValues.expensePaymentDetails,
        [field]: newValue,
      };

      applyFormUpdates({ newFieldValues: fieldsToUpdate });
    }

    // For simple operating cheque property updates
    function onOperatingChequeFieldUpdate({ field, newValue }) {
      const fieldsToUpdate = {};

      if (!isChequePaymentMethod) {
        return;
      }

      fieldsToUpdate.operatingCheque = {
        ...formValues.operatingCheque,
        [field]: newValue,
      };

      applyFormUpdates({ newFieldValues: fieldsToUpdate });
    }

    // For simple operatingChequePrintOptions property updates
    function onOperatingChequePrintOptionsFieldUpdate({ field, newValue }) {
      const fieldsToUpdate = {};

      if (!isChequePaymentMethod) {
        return;
      }

      fieldsToUpdate.operatingChequePrintOptions = {
        ...formValues.operatingChequePrintOptions,
        [field]: newValue,
      };

      // Form can be initialised with the cheque print method set to PrintManually
      // if the firm's default print method setting for operating cheques is
      // PrintManually, in which case we need to fetch the next available number
      if (
        field === 'chequePrintActive' &&
        newValue === true &&
        formValues.operatingChequePrintOptions?.chequePrintMethod === PrintManually
      ) {
        if (previouslySavedChequeNumberRef.current && availableOperatingChequeNumber) {
          fieldsToUpdate.chequeNumber = undefined;
          onClearAvailableChequeNumberCache();
        } else {
          onGetAvailableOperatingChequeNumber({ operatingChequeNumber: formValues.operatingCheque?.chequeNumber });
        }
      }

      applyFormUpdates({ newFieldValues: fieldsToUpdate });
    }

    // Payable to supplier toggle (for expense type)
    function onPayableToSupplierToggleChange({ newPayableToSupplierValue }) {
      const fieldsToUpdate = {};

      fieldsToUpdate.expensePaymentDetails = {
        ...formValues.expensePaymentDetails,
        isPayable: newPayableToSupplierValue,
        // For expenses (and not AD), "isPayable" and "isPaid" are kept in sync
        isPaid: newPayableToSupplierValue,
      };

      applyFormUpdates({ newFieldValues: fieldsToUpdate });
    }

    // Supplier paid toggle (for AD type)
    function onSupplierPaidToggleChange({ newSupplierPaidValue }) {
      const fieldsToUpdate = {};

      fieldsToUpdate.expensePaymentDetails = {
        ...formValues.expensePaymentDetails,
        isPaid: newSupplierPaidValue,
        // For ADs, isPayable is set to true by default
        isPayable: true,
      };

      applyFormUpdates({ newFieldValues: fieldsToUpdate });
    }

    // Supplier field
    function onSupplierSelected(selectedSupplier) {
      const fieldsToUpdate = {};

      fieldsToUpdate.expensePaymentDetails = {
        ...formValues.expensePaymentDetails,
        supplier: {
          // When cleared, selectedSupplier is null
          id: selectedSupplier ? selectedSupplier.value : undefined,
          displayName: selectedSupplier ? selectedSupplier.label : undefined,
        },
      };

      applyFormUpdates({ newFieldValues: fieldsToUpdate });
    }

    // Payment method field
    function onPaymentMethodChange(selectedPaymentMethodOption) {
      const fieldsToUpdate = {};

      // Update payment method
      fieldsToUpdate.expensePaymentDetails = {
        ...formValues.expensePaymentDetails,
        paymentMethod: selectedPaymentMethodOption.value,
      };

      // If switching to "Cheque" payment method:
      if (selectedPaymentMethodOption.value === paymentMethodByName.CHEQUE) {
        // Firm default operating cheque print method could be set to PrintManually
        // meaning that we need to fetch the available cheque number as soon
        // as the user changes the payment method to cheque
        if (formValues.operatingChequePrintOptions?.chequePrintMethod === PrintManually) {
          onGetAvailableOperatingChequeNumber({ operatingChequeNumber: formValues.operatingCheque.chequeNumber });
        }

        const supplier = formValues.expensePaymentDetails.supplier;

        // 1. Needed for backwards compatibility and required to create cheques
        fieldsToUpdate.operatingChequePrintOptions = {
          ...formValues.operatingChequePrintOptions,
          chequePrintActive: true,
        };

        // 2. Prefill with selected supplier if available
        if (supplier) {
          fieldsToUpdate.operatingCheque = {
            ...formValues.operatingCheque,
            payTo: {
              id: supplier.id,
              displayName: supplier.displayName,
            },
          };
        }
      }

      applyFormUpdates({ newFieldValues: fieldsToUpdate });
    }

    // Pay to field
    function onPayToSelected(selectedPayToContact) {
      const fieldsToUpdate = {};

      if (!isChequePaymentMethod) {
        return;
      }

      fieldsToUpdate.operatingCheque = {
        ...formValues.operatingCheque,
        payTo: {
          // When cleared, selectedPayToContact is null
          id: selectedPayToContact ? selectedPayToContact.value : undefined,
          displayName: selectedPayToContact ? selectedPayToContact.label : undefined,
        },
      };

      applyFormUpdates({ newFieldValues: fieldsToUpdate });
    }

    // Printing method field
    function onChequePrintingMethodChange({ newPrintMethod }) {
      const fieldsToUpdate = {};

      if (!isChequePaymentMethod) {
        return;
      }

      fieldsToUpdate.operatingChequePrintOptions = {
        ...formValues.operatingChequePrintOptions,
        chequePrintMethod: newPrintMethod,
      };

      // If user wants to "print manually":
      //  * Fetch the next available cheque number
      //  * The assignment will be done in the useOperatingChequePrintManuallyOption hook
      if (newPrintMethod === PrintManually) {
        onGetAvailableOperatingChequeNumber({ operatingChequeNumber: formValues.operatingCheque.chequeNumber });
      }

      applyFormUpdates({ newFieldValues: fieldsToUpdate });
    }

    // File attachments
    function onAttachmentFileChange({ downloadUrl, isDeleting, uploadedFile }) {
      const updatedAttachmentFile = {
        ...formValues.attachmentFile,
      };

      if (uploadedFile) {
        updatedAttachmentFile.fileName = uploadedFile.name;
        updatedAttachmentFile.fileSize = uploadedFile.size;
        updatedAttachmentFile.fileType = uploadedFile.type;
        updatedAttachmentFile.uploadedFile = uploadedFile;
      } else if (downloadUrl) {
        updatedAttachmentFile.url = downloadUrl;
      } else if (isDeleting) {
        updatedAttachmentFile.isDeleting = true;
      }

      applyFormUpdates({
        newFieldValues: {
          attachmentFile: updatedAttachmentFile,
        },
      });
    }

    /**
     * Form submission
     */

    async function marshalSaveExpenseData(formData) {
      const expenseId = formData?.expenseId ?? uuid();

      /**
       * Marshalling supplier and payment data
       */

      // There are supplier and payment related fields that are visibly controlled by the toggles
      //  * When AD feature is enabled:
      //    * "Payable to supplier" toggle (Disbursement type)
      //    * "Supplier paid" toggle (AD type)
      //  * When AD feature is disabled:
      //    * "Print cheque" checkbox
      //
      // If they are toggled off, we should clear the data and not send them to the server
      //  * This is done during the marshalling stage, so values can be preserved while the user is still interacting with the form
      const marshalSupplierAndPaymentData = () => {
        const isSupplierPaid = formData.expensePaymentDetails.isPaid;
        const isSupplierPayable = formData.expensePaymentDetails.isPayable;

        let marshalledExpensePaymentDetails;
        let marshalledOperatingChequePrintOptions;

        // Send operatingChequePrintOptions to server only when creating a cheque
        const isCreatingCheque =
          operatingChequePrintSettings?.printingActive &&
          !operatingChequeExists &&
          formData?.operatingChequePrintOptions?.chequePrintActive;

        // Assign a cheque number only when the "Print Manually" option is selected
        //  * Other options will assign a cheque number via the Print Operating Cheque modal
        const chequeNumber =
          formData.operatingChequePrintOptions.chequePrintMethod === PrintManually
            ? formData.operatingCheque.chequeNumber
            : undefined;

        // [1] When AD feature is OFF
        //  * expensePaymentDetails is not relevant (i.e. not created)
        //  * operatingChequePrintOptions is relevant when creating a cheque
        if (!anticipatedDisbursementsEnabled) {
          marshalledOperatingChequePrintOptions = isCreatingCheque
            ? {
                allowChequeNumberDuplication,
                bankAccountId: operatingBankAccountId,
                chequeDate: dateToInteger(new Date()),
                chequeId: formData.operatingCheque.id || uuid(),
                chequeMemo: formData.operatingCheque.chequeMemo,
                chequePrintActive: formData.operatingChequePrintOptions.chequePrintActive,
                chequePrintMethod: formData.operatingChequePrintOptions.chequePrintMethod,
                payToId: formData.operatingCheque.payTo.id,
                reference: chequeNumber,
              }
            : undefined;

          return { marshalledExpensePaymentDetails, marshalledOperatingChequePrintOptions };
        }

        // [2] When AD feature is ON
        const isExpenseAndIsSupplierPayableToggledOff =
          !formData.isAnticipated && !isSupplierPayable && anticipatedDisbursementsEnabled;
        const isAdAndIsSupplierPaidToggledOff =
          formData.isAnticipated && !isSupplierPaid && anticipatedDisbursementsEnabled;
        const togglesSwitchedOff = isExpenseAndIsSupplierPayableToggledOff || isAdAndIsSupplierPaidToggledOff;

        // [3] Toggles are switched OFF
        if (togglesSwitchedOff) {
          // [3a] Expense type
          // When "payable to supplier" is toggled off:
          //  * expensePaymentDetails entity should be created, but have "empty" values
          //  * operatingChequePrintOptions are not sent to the server
          if (!formData.isAnticipated) {
            marshalledExpensePaymentDetails = {
              isPaid: false,
              isPayable: false,
              paymentAccountName: undefined,
              paymentDue: 0,
              paymentMethod: paymentMethodByName.NONE,
              paymentReference: undefined,
              supplierId: undefined,
              supplierReference: undefined,
            };
          }

          // [3b] AD type
          // When "supplier paid" is toggled on, it is very similar to the above, but some expensePaymentDetails fields are preserved
          // * This is because some supplier/payment fields are displayed regardless of the toggle
          if (formData.isAnticipated) {
            marshalledExpensePaymentDetails = {
              isPaid: false,
              isPayable: false,
              paymentAccountName: undefined,
              paymentMethod: paymentMethodByName.NONE,
              paymentReference: undefined,
              // Valid fields to send to server
              paymentDue: moment(formData.expensePaymentDetails.paymentDue).format('YYYYMMDD'), // Required
              supplierReference: formData.expensePaymentDetails.supplierReference, // Optional
              supplierId: formData.expensePaymentDetails.supplier.id, // Required
            };
          }

          // If the toggle is OFF, no cheque should be generated (bug fix - BB-12281)
          //
          // As an example, such a scenario can occur:
          //  1. User fills in required fields
          //    * The payment method is cheque, with the "print now" option
          //  2. Unticks the "supplier paid" toggle
          //  3. Saves the expense
          //
          // As the user had toggled off "supplier paid", the cheque should not have been generated
          //  * However, the bug described in BB-12281 created the cheque
          marshalledOperatingChequePrintOptions = undefined; // explicitly set to undefined
        }

        // [4] Toggles are switched ON
        if (!togglesSwitchedOff) {
          const isBankTransferOrDirectDebit =
            formData?.expensePaymentDetails?.paymentMethod === paymentMethodByName.BANK_TRANSFER ||
            formData?.expensePaymentDetails?.paymentMethod === paymentMethodByName.DIRECT_DEBIT;

          // [4a] Create/update values for the expensePaymentDetails entity
          marshalledExpensePaymentDetails = {
            id: formData.expensePaymentDetails.id,
            isPaid: isSupplierPaid,
            isPayable: isSupplierPayable,
            paymentDue: moment(formData.expensePaymentDetails.paymentDue).format('YYYYMMDD'),
            paymentMethod: formData.expensePaymentDetails.paymentMethod,
            supplierId: formData.expensePaymentDetails.supplier.id,
            supplierReference: formData.expensePaymentDetails.supplierReference,
            // Bank Transfer or Direct Debit specific fields
            paymentAccountName: isBankTransferOrDirectDebit
              ? formData.expensePaymentDetails.paymentAccountName
              : undefined,
            paymentReference: isBankTransferOrDirectDebit ? formData.expensePaymentDetails.paymentReference : undefined,
          };

          // [4b] Create and send the operatingChequePrintOptions only when creating a cheque (for the first time - otherwise, do not send!)
          marshalledOperatingChequePrintOptions = isCreatingCheque
            ? {
                allowChequeNumberDuplication,
                bankAccountId: operatingBankAccountId,
                chequeDate: dateToInteger(new Date()),
                chequeId: uuid(),
                chequeMemo: formData.operatingCheque.chequeMemo,
                chequePrintActive: formData.operatingChequePrintOptions.chequePrintActive,
                chequePrintMethod: formData.operatingChequePrintOptions.chequePrintMethod,
                payToId: formData.operatingCheque.payTo.id,
                reference: chequeNumber,
              }
            : undefined;
        }

        return {
          marshalledExpensePaymentDetails,
          marshalledOperatingChequePrintOptions,
        };
      };

      const { marshalledExpensePaymentDetails, marshalledOperatingChequePrintOptions } =
        marshalSupplierAndPaymentData();

      /**
       * Marshalling attachments
       */

      const processAttachment = async () => {
        // [1] Upload new attachment if provided
        if (formData.attachmentFile.uploadedFile) {
          const filePath = await uploadFile({
            entityFileType: 'Receipt',
            entityId: expenseId,
            entityType: 'Expense',
            file: formData.attachmentFile.uploadedFile,
            fileId: formData.expenseVersionId,
          });

          return {
            location: filePath,
            fileName: formData.attachmentFile.uploadedFile.name,
          };
        }

        // [2] Marked for removal
        //  * This will not actually delete the file (it is kept for archival purposes)
        //  * This will just remove the attachment from the expense entity
        if (formData.attachmentFile.isDeleting) {
          return null; // Explicitly mark as null for removal
        }

        // [3] Default scenario: either keep existing or handle as empty
        return expense?.attachmentFile;
      };

      const marshalledAttachmentFile = await processAttachment();

      const displayWithFees = supportsDisplayWithFees
        ? formData.costType === costTypes.SOFT && formData.isDisplayWithFees // We are just extra defensive here as this shouldn't be set to true for HARD cost type
        : false;

      const marshalledData = {
        // Expense
        amountIncludesTax: formData.amountIncludesTax,
        description: formData.subject,
        expenseActivityId: currentActivity?.category === activityCategories.CUSTOM ? currentActivity?.code : null,
        expenseDate: moment(formData.expenseDate).format('YYYYMMDD'),
        expenseEarnerStaffId: formData.expenseEarnerStaffId,
        expenseId,
        expenseVersionId: isOnFinalisedInvoiceOrInvoicedExternally ? undefined : formData.expenseVersionId,
        costType: formData.costType,
        displayWithFees,
        isAnticipated: formData.isAnticipated,
        isBillable: formData.isBillable,
        isTaxOverridden: formData.isTaxOverridden,
        isOutputTaxOverridden: inputOutputTaxEnabled ? formData.isOutputTaxOverridden : null,
        matterId: formData.matterId,
        notes: formData.description,
        price: formData.priceInCents,
        quantity: Math.round(formData.quantity * 100),
        tax: formData.taxInCents,
        outputTax: inputOutputTaxEnabled ? formData.outputTaxInCents : null,
        utbmsActivityCode: currentActivity?.category === activityCategories.UTBMS ? currentActivity?.code : null,
        utbmsTaskCode: currentTask?.code,
        waived: formData.isBillable && formData.isWriteOff,
        // Supplier and payment details
        expensePaymentDetails: marshalledExpensePaymentDetails,
        // operatingChequePrintOptions details
        //  * While we fetch the operating cheque entity in graphql, we pass operatingChequePrintOptions to the command
        operatingChequePrintOptions: marshalledOperatingChequePrintOptions,
        // Attachment
        attachmentFile: marshalledAttachmentFile,
      };

      return marshalledData;
    }

    async function onFormSubmit(args) {
      try {
        validateForm();

        const isValid = await expenseForm.onSubmitFormWithValidation({
          submitFnP: async (formData) => {
            // [1] Marshal data and send command
            const marshalledData = await marshalSaveExpenseData(formData);

            // When adding a payment to a finalised expense (not AD), we only send a create
            // cheque command, as the expense is locked and any modifications to the
            // expense will be sent to the reject queue
            if (
              isOnFinalisedInvoiceOrInvoicedExternally &&
              marshalledData?.operatingChequePrintOptions &&
              !marshalledData?.expensePaymentDetails
            ) {
              const newOperatingCheque = {
                allowChequeNumberDuplication: marshalledData.operatingChequePrintOptions.allowChequeNumberDuplication,
                bankAccountId: marshalledData.operatingChequePrintOptions.bankAccountId,
                chequeId: marshalledData.operatingChequePrintOptions.chequeId,
                chequeDate: marshalledData.operatingChequePrintOptions.chequeDate,
                expenseIds: [marshalledData.expenseId],
                isManual: marshalledData.operatingChequePrintOptions.chequePrintMethod === PrintManually,
                chequeNumber: marshalledData.operatingChequePrintOptions.reference,
                chequeMemo: marshalledData.operatingChequePrintOptions.chequeMemo,
                payToId: marshalledData.operatingChequePrintOptions.payToId,
              };

              await dispatchCommand({
                type: 'Billing.Accounts.Messages.Commands.CreateOperatingCheque',
                message: newOperatingCheque,
              });

              displaySuccessToUser(`${t('capitalizeAllWords', { val: 'cheque' })} added successfully`);
            } else {
              // Save expense
              await dispatchCommand({ type: 'Billing.Expenses.Commands.SaveExpense', message: marshalledData });

              if (isNewExpense) {
                displaySuccessToUser(`${t('capitalizeAllWords', { val: 'expense' })} saved successfully`);
              }
            }

            // [2] Run any (optional) callbacks required post save
            if (onExpenseSave) {
              onExpenseSave({ marshalledData });
            }

            // [3] Handle submission type logic for:
            // [3a] "Save & New"
            if (args?.saveAndNew) {
              const defaultValues = getDefaultValues();

              // Every field except for the below fields get reset:
              //  1. Date
              //  2. Staff
              //  3. Matter
              //  4. Expense type
              onInitialiseForm({
                ...defaultValues,
                expenseDate: integerToDate(marshalledData.expenseDate),
                matterId: marshalledData.matterId,
                expenseEarnerStaffId: marshalledData.expenseEarnerStaffId,
                isAnticipated: marshalledData.isAnticipated,
              });
              // Store the previously saved cheque number so that we can fetch
              // the next available number and reset the form field when needed
              previouslySavedChequeNumberRef.current = marshalledData?.operatingChequePrintOptions?.reference;
              setIsSubjectOverridable(true);
              setIsCostTypeOverridable(true);
            }
            // [3b] "Save" or "Update"
            else {
              // Open Print Operating Cheque modal if required
              //  * This is only for the "Print Now" option
              //  * The "Save & New" option is not available when this modal is set to be opened
              const isCreatingCheque = !!marshalledData.operatingChequePrintOptions; // Unless creating a cheque, operatingChequePrintOptions is undefined
              const isChequePrintMethodPrintNow =
                marshalledData.operatingChequePrintOptions?.chequePrintMethod === PrintNow;
              const openPrintOperatingChequeModal = isCreatingCheque && isChequePrintMethodPrintNow;

              if (openPrintOperatingChequeModal) {
                const operatingChequeId = marshalledData.operatingChequePrintOptions.chequeId;
                setModalDialogVisible({
                  modalId: PRINT_OPERATING_CHEQUE_MODAL_ID,
                  props: { chequeIds: [operatingChequeId], sbAsyncOperationsService },
                });
              }

              onModalClose();
            }
          },
        });

        if (!isValid) {
          log.warn('Expense form validation failed');
        }
      } catch (error) {
        log.error('Failed to save expense', error);
        displayErrorToUser(`Failed to save ${t('expense')}. Please check your connection and try again.`);
      }
    }

    /**
     * Additional data required by fields
     */
    const isUtbmsEnabledForMatter = hasFacet(facets.utbms) && !!currentMatter?.billingConfiguration?.isUtbmsEnabled;
    const isUtbmsEnabledForFirmAndMatter = isUtbmsEnabledForFirm && isUtbmsEnabledForMatter;
    const areUtbmsCodesRequiredByFirmAndMatter = areUtbmsCodesRequiredByFirm && isUtbmsEnabledForMatter;

    const showTaskField = hasFacet(facets.utbms);
    const showChequeMemoField = chequeMemoEnabled;
    const isActivityFieldDisabled = areUtbmsCodesRequiredByFirmAndMatter;
    const isTaskFieldDisabled = !isUtbmsEnabledForFirmAndMatter || isFormDisabled;
    const showPrintingMethodField = anticipatedDisbursementsEnabled
      ? !formValues?.operatingCheque?.id && !!formValues?.expensePaymentDetails?.isPaid && isChequePaymentMethod
      : !formValues?.operatingCheque?.id; // show only in create mode
    const supportsDisplayWithFees = hasFacet(facets.displayExpenseWithFees) && featureActive('BB-14971');

    // The Activity and Task dropdowns require activities/tasks to be grouped
    const activitiesGroupArray = React.useMemo(() => {
      const availableActivityCategories = getApplicableActivityCategories({
        utbmsEnabledForMatter: isUtbmsEnabledForMatter,
        utbmsCodesRequiredByFirm: areUtbmsCodesRequiredByFirm,
      });
      return convertToActivityGroupArray({ activities, filter: { types: availableActivityCategories } });
    }, [activities, isUtbmsEnabledForMatter, areUtbmsCodesRequiredByFirm]);

    const tasksGroupArray = React.useMemo(() => convertToTaskGroupArray({ tasks, filter: {} }), [tasks]);

    // Provides the available options in the subject typeahead dropdown
    const subjectOptions = React.useMemo(() => {
      // If UTBMS codes are required for the matter, do not allow selection of custom activities (activity field is also disabled in this case)
      if (areUtbmsCodesRequiredByFirmAndMatter) {
        return [];
      }

      const options = activities.filter(
        (activity) => isUtbmsEnabledForFirmAndMatter || activity.category === activityCategories.CUSTOM,
      );
      return options;
    }, [activities, areUtbmsCodesRequiredByFirmAndMatter, isUtbmsEnabledForFirmAndMatter]);

    /**
     * Form validation
     */

    function validateForm() {
      // A context is provided to the schema, which provides relevant data that can be used and referenced when validating
      //
      // This is especially useful for certain validation rules that depend on values from both:
      //  * Parent fields
      //  * Sibling fields

      const context = {
        allowChequeNumberDuplication,
        anticipatedDisbursementsEnabled,
        areUtbmsCodesRequiredByFirmAndMatter,
        availableOperatingChequeNumber,
        chequeMemoEnabled,
        expensePaymentDetails: formValues?.expensePaymentDetails,
        isAnticipated: formValues?.isAnticipated,
        isNewExpense,
        lastOperatingChequeNumber,
        operatingCheque: formValues?.operatingCheque,
        operatingChequePrintOptions: formValues?.operatingChequePrintOptions,
        showTaxFields,
        inputOutputTaxEnabled,
        t,
      };

      onValidateForm(context);
    }

    /**
     * Expense cost type selection
     */

    const costTypeButtonList = enabledHardAndSoftCosts
      ? [
          {
            id: costTypes.HARD,
            label: costTypeLabels.HARD,
            active: formValues?.costType === costTypes.HARD,
          },
          {
            id: costTypes.SOFT,
            label: costTypeLabels.SOFT,
            active: formValues?.costType === costTypes.SOFT,
          },
        ]
      : undefined;

    function onSelectCostType({ newCostType }) {
      setIsCostTypeOverridable(false);

      const newFieldValues = { costType: newCostType };

      if (supportsDisplayWithFees) {
        // displayWithFees is only relevant for soft costs and defaults to true
        newFieldValues.isDisplayWithFees = newCostType === costTypes.SOFT;
      }

      return applyFormUpdates({ newFieldValues });
    }

    /**
     * Expense type tabs
     */

    const expenseTypeButtonList = anticipatedDisbursementsEnabled
      ? [
          {
            id: expenseTypeEnum.EXPENSE,
            label: t('capitalizeAllWords', { val: 'expense' }),
            active: !isAnticipatedDisbursement,
          },
          {
            id: expenseTypeEnum.ANTICIPATED_DISBURSEMENT,
            label: `Anticipated ${t('capitalizeAllWords', { val: 'expense' })}`,
            active: isAnticipatedDisbursement,
          },
        ]
      : undefined;

    function onSelectExpenseType({ newExpenseType }) {
      const fieldsToUpdate = {};
      const selectedAnticipatedDisbursement = newExpenseType === expenseTypeEnum.ANTICIPATED_DISBURSEMENT;

      if (!anticipatedDisbursementsEnabled) {
        return;
      }

      // 1. Update expense type
      fieldsToUpdate.isAnticipated = selectedAnticipatedDisbursement;

      // 2. Turn off the relevant toggle when switching expense types
      //  * Other field values are preserved
      fieldsToUpdate.expensePaymentDetails = {
        ...formValues.expensePaymentDetails,
        isPaid: false,
        // If selecting the AD type:
        //  * "isPayable" - Set to true by default
        //  * An AD can be considered payable even if it is not paid
        // If selecting the expense type:
        //  * "isPayable" - reflects toggle state
        isPayable: selectedAnticipatedDisbursement,
      };

      applyFormUpdates({ newFieldValues: fieldsToUpdate });
    }

    /**
     * Payment details
     */
    function getPaymentMethodOptions() {
      const bankTransferOption = {
        label: paymentMethodLabel[paymentMethodByName.BANK_TRANSFER],
        value: paymentMethodByName.BANK_TRANSFER,
      };
      const directDebitOption = {
        label: paymentMethodLabel[paymentMethodByName.DIRECT_DEBIT],
        value: paymentMethodByName.DIRECT_DEBIT,
      };
      const paymentOptions = [bankTransferOption, directDebitOption];
      if (operatingChequePrintSettings?.printingActive) {
        const chequeOption = { label: `${capitalize(t('cheque'))}`, value: paymentMethodByName.CHEQUE };
        paymentOptions.splice(1, 0, chequeOption);
      }

      return paymentOptions;
    }

    /**
     * Expense deletion
     */

    const provideDeleteExpenseLink =
      !isNewExpense && !isFormDisabled && !formValues.operatingCheque?.id && !isPaidExpense;

    async function onDeleteExpense() {
      try {
        const message = {
          expenseId: formValues.expenseId,
          expenseVersionId: uuid(),
        };
        setIsDeletingExpense(true);
        await dispatchCommand({ type: 'Billing.Expenses.Commands.DeleteExpense', message });
      } catch (error) {
        log.error('Failed to delete expense', error);
        displayErrorToUser(`Failed to delete ${t('expense')}. Please check your connection and try again.`);
      } finally {
        setIsDeletingExpense(false);
        onModalClose();
      }
    }

    return {
      activitiesGroupArray,
      allowChequeNumberDuplication,
      anticipatedDisbursementsEnabled,
      defaultMatterSummaries,
      defaultPayToOptions,
      defaultSupplierContactOptions,
      expenseForm,
      expenseTypeButtonList,
      costTypeButtonList,
      formData: formValues,
      formDataMappedObjects,
      formErrors: formFields, // Contains data related to form errors
      formInitialised,
      invoiceNumber,
      isActivityFieldDisabled,
      isChequePaymentMethod,
      isPaidExpense,
      isFormDisabled,
      isGeneratingAttachmentDownloadUrl,
      isLoading: isFormLoading,
      isOnDraftInvoice,
      isOnFinalisedInvoice,
      isPaymentDetailsDisabled,
      isPaymentToggleDisabled,
      isSaveOrUpdateButtonDisabled: deriveIsSaveOrUpdateButtonDisabled(),
      isSaveAndNewDisabled: deriveIsSaveAndNewDisabled(),
      isSubjectOverridable,
      isSupplierDetailsDisabled,
      isTaskFieldDisabled,
      matterSummaries,
      paymentMethodOptions: getPaymentMethodOptions(),
      previouslySavedChequeNumberRef,
      provideDeleteExpenseLink,
      region,
      showChequeMemoField,
      showPayableToSupplierToggle: !isAnticipatedDisbursement,
      showPrintingMethodField,
      showTaskField,
      showTaxFields,
      showDisplayWithFeesField: supportsDisplayWithFees,
      displayWithFeesDisabled: formValues?.costType !== costTypes.SOFT,
      subjectOptions,
      tasksGroupArray,
      // inline contact add
      showAddPayToContactForm,
      showAddSupplierContactForm,
      setShowAddPayToContactForm,
      setShowAddSupplierContactForm,
      // Callbacks
      onAttachmentFileChange,
      onChequePrintingMethodChange,
      onSelectCostType,
      onDeleteExpense,
      onExpensePaymentDetailsFieldUpdate,
      onFormSubmit,
      onNavigateToInvoice,
      onOperatingChequeFieldUpdate,
      onOperatingChequePrintOptionsFieldUpdate,
      onPayableToSupplierToggleChange,
      onPaymentMethodChange,
      onPayToSelected,
      onSelectExpenseType,
      onSetIsGeneratingAttachmentDownloadUrl: setIsGeneratingAttachmentDownloadUrl,
      onSupplierPaidToggleChange,
      onSupplierSelected,
      onUpdateActivityField,
      onUpdateBillableField,
      onUpdateField,
      onUpdateMatterField,
      onUpdateSubjectField,
      onUpdateTaskField,
      onUpdateTaxField,
      onUpdateOutputTaxField,
      validateForm,
    };
  },
});

const dependentHooks = () => ({
  useGetExpenseAttachment: ({
    expense,
    formInitialised,
    onAttachmentFileChange,
    onSetIsGeneratingAttachmentDownloadUrl,
  }) => {
    // Generate the download URL for an expense with an existing attachment
    React.useEffect(() => {
      let isComponentMounted = true;

      async function generateDownloadUrl() {
        if (formInitialised && expense?.attachmentFile?.location) {
          try {
            if (isComponentMounted) {
              const url = await getAttachmentDownloadUrl({
                accountId: getAccountId(),
                filePath: expense.attachmentFile.location,
              });
              onAttachmentFileChange({ downloadUrl: url });
            }
          } catch (error) {
            log.error('Failed to generate download url for expense attachment', error);
          } finally {
            if (isComponentMounted) {
              onSetIsGeneratingAttachmentDownloadUrl(false);
            }
          }
        }
      }

      generateDownloadUrl();

      return () => {
        isComponentMounted = false;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formInitialised, expense]);
  },
  useOperatingChequePrintManuallyOption: ({
    allowChequeNumberDuplication,
    availableOperatingChequeNumber,
    expenseForm,
    formData,
    isChequePaymentMethod,
    isLoadingAvailableOperatingChequeNumber,
    isPaidExpense,
    lastOperatingChequeNumber,
    previouslySavedChequeNumberRef,
    onGetAvailableOperatingChequeNumber,
    onOperatingChequeFieldUpdate,
    validateForm,
  }) => {
    const { t } = useTranslation();

    // We only need to validate the operating cheque number when:
    //  1. Payment method is "Cheque"
    //  2. The "Print Manually" option is selected
    //    * Other printing options do not require a cheque number upon submission
    //  2. Creating a cheque
    //    * Paid cheques have their number already assigned
    const isChequePrintMethodManual = formData?.operatingChequePrintOptions?.chequePrintMethod === PrintManually;
    const isCreatingAndPrintingChequeManually = isChequePaymentMethod && isChequePrintMethodManual && !isPaidExpense;
    const chequeNumber = formData?.operatingCheque?.chequeNumber;

    const [hasInitialisedChequeNumber, setHasInitialisedChequeNumber] = React.useState(false);
    const [triggerValidate, setTriggerValidate] = React.useState(false);

    /**
     * Cheque number initialisation - assign the default cheque number
     *
     * The cheque number field is required if Cheque printing method is set to
     * Print Manually. In all other cases the cheque number is set at the time
     * of printing the cheque.
     */
    React.useEffect(() => {
      // Make sure the printing method is set to Print Manually and that we have
      // fetched the next available operating cheque number
      if (!isCreatingAndPrintingChequeManually || !availableOperatingChequeNumber) {
        return;
      }

      // If the cheque number has not been assigned since the modal was opened,
      // we can set the next available number now; alternatively
      // If the user has set the cheque number in a previous save (ie, via Save
      // and New), we need to make sure that the field was reset, and that we
      // have fetched the new available cheque number before updating the field.
      if (
        !hasInitialisedChequeNumber ||
        (chequeNumber === undefined && availableOperatingChequeNumber !== previouslySavedChequeNumberRef.current)
      ) {
        onOperatingChequeFieldUpdate({ field: 'chequeNumber', newValue: availableOperatingChequeNumber });
        setHasInitialisedChequeNumber(true);
        // eslint-disable-next-line no-param-reassign
        previouslySavedChequeNumberRef.current = undefined;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasInitialisedChequeNumber, availableOperatingChequeNumber, chequeNumber, isCreatingAndPrintingChequeManually]);

    /**
     * Cheque number validation on user input
     */
    React.useEffect(() => {
      if (!isCreatingAndPrintingChequeManually || !hasInitialisedChequeNumber) {
        return;
      }

      // Before checking the server for available cheque numbers, ensure the provided cheque number:
      //  1. Is not blank
      //  2. Is numeric
      const isValidChequeNumberToCheck = !!chequeNumber?.length && !!/^[0-9]+$/.test(chequeNumber);

      if (isValidChequeNumberToCheck && availableOperatingChequeNumber !== chequeNumber) {
        // Need to check server if the new chequeNumber (entered by the user) is available
        onGetAvailableOperatingChequeNumber({ operatingChequeNumber: chequeNumber });
      } else {
        setTriggerValidate(!triggerValidate);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chequeNumber, hasInitialisedChequeNumber, availableOperatingChequeNumber]);

    React.useEffect(() => {
      if (!isCreatingAndPrintingChequeManually || !hasInitialisedChequeNumber) {
        return;
      }

      // Changing the form value will trigger immediate validation before we can
      // fetch the new availableOperatingChequeNumber value. Instead we wait until loading
      // is complete. Can't rely on availableOperatingChequeNumber changing because if the
      // user enters any used value, availableOperatingChequeNumber is likely going to stay
      // the same
      if (availableOperatingChequeNumber && isLoadingAvailableOperatingChequeNumber === false) {
        setTriggerValidate(!triggerValidate);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableOperatingChequeNumber, isLoadingAvailableOperatingChequeNumber]);

    React.useEffect(() => {
      if (!isCreatingAndPrintingChequeManually || !hasInitialisedChequeNumber || !expenseForm.submitFailed) {
        return;
      }

      validateForm();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerValidate]);

    // This function skips validation and directly updates the form
    //  * This will be validated manually, by the above hook
    //  * This is to wait for data from the server, before validating
    function onChequeReferenceFieldUpdate({ newChequeReference }) {
      expenseForm.onUpdateFields({
        operatingCheque: {
          ...formData.operatingCheque,
          chequeNumber: newChequeReference,
        },
      });
    }

    function getChequeNumberDuplicationWarningMessage() {
      // If cheque number duplication is not allowed:
      //  * The form validation will prevent the submission and display the error message
      //  * Therefore, this message is not needed
      if (
        !allowChequeNumberDuplication ||
        !isCreatingAndPrintingChequeManually ||
        !hasInitialisedChequeNumber ||
        !lastOperatingChequeNumber
      ) {
        return '';
      }

      const chequeLabel = capitalize(t('cheque'));
      const existingChequeNumber =
        availableOperatingChequeNumber?.replace(/^0+/, '') !== chequeNumber?.replace(/^0+/, '');

      // If cheque number duplication is allowed:
      //  * We will display the below message as a warning
      return existingChequeNumber
        ? `Warning: ${chequeLabel} reference is already in use. Last ${chequeLabel.toLowerCase()} reference printed was ${lastOperatingChequeNumber}.`
        : '';
    }

    return {
      chequeNumberDuplicationWarningMessage: getChequeNumberDuplicationWarningMessage(),
      isChequeReferenceFieldDisabled: !hasInitialisedChequeNumber,
      onChequeReferenceFieldUpdate,
    };
  },
});

export const ExpenseFormsContainer = composeHooks(hooks)(composeHooks(dependentHooks)(ExpenseModal));

ExpenseFormsContainer.propTypes = {
  sbAsyncOperationsService: PropTypes.object.isRequired,
  onClickLink: PropTypes.func,
  /** Form */
  activities: PropTypes.arrayOf(PropTypes.object).isRequired,
  areQueriesLoading: PropTypes.bool.isRequired,
  areUtbmsCodesRequiredByFirm: PropTypes.bool.isRequired,
  defaultChequePrintMethod: PropTypes.oneOf([PrintNow, PrintManually, PrintLater]),
  expense: PropTypes.object,
  isLoadingAvailableOperatingChequeNumber: PropTypes.bool.isRequired,
  isNewExpense: PropTypes.bool.isRequired,
  isUtbmsEnabledForFirm: PropTypes.bool.isRequired,
  loggedInStaff: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  scope: PropTypes.string.isRequired,
  lastOperatingChequeNumber: PropTypes.string,
  matter: PropTypes.object,
  matterId: PropTypes.string,
  matterSummaries: PropTypes.arrayOf(PropTypes.object).isRequired,
  matterSummariesDataLoading: PropTypes.bool.isRequired,
  matterSummariesHasMore: PropTypes.bool.isRequired,
  availableOperatingChequeNumber: PropTypes.string,
  operatingBankAccountId: PropTypes.string,
  operatingChequePrintSettings: PropTypes.PropTypes.shape({
    id: PropTypes.string.isRequired,
    printMethod: PropTypes.oneOf([PrintNow, PrintManually, PrintLater]),
    printingActive: PropTypes.bool.isRequired,
  }).isRequired,
  payToContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  payToContactOptionsDataLoading: PropTypes.bool.isRequired,
  payToContactOptionsHasMore: PropTypes.bool.isRequired,
  supplierContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  supplierContactOptionsDataLoading: PropTypes.bool.isRequired,
  supplierContactOptionsHasMore: PropTypes.bool.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object),
  taxRateInBasisPoints: PropTypes.number,
  // Callbacks
  onExpenseSave: PropTypes.func,
  onFetchMatterSummaries: PropTypes.func.isRequired,
  onFetchMoreMatterSummaries: PropTypes.func.isRequired,
  onFetchMorePayToContactOptions: PropTypes.func.isRequired,
  onFetchMoreSupplierContactOptions: PropTypes.func.isRequired,
  onFetchPayToContactOptions: PropTypes.func.isRequired,
  onFetchSupplierContactOptions: PropTypes.func.isRequired,
  onGetAvailableOperatingChequeNumber: PropTypes.func.isRequired,
  /** Modal */
  showModal: PropTypes.bool.isRequired,
  // Callbacks
  onModalClose: PropTypes.func.isRequired,
};

ExpenseFormsContainer.defaultProps = {
  defaultChequePrintMethod: undefined,
  expense: undefined,
  lastOperatingChequeNumber: undefined,
  matter: undefined,
  matterId: undefined,
  nextAvailableOperatingChequeNumber: undefined,
  operatingBankAccountId: undefined,
  sbAsyncOperationsService: undefined,
  tasks: undefined,
  tasksGroupArray: undefined,
  taxRateInBasisPoints: undefined,
  // Callbacks
  onClickLink: undefined,
  onExpenseSave: undefined,
};

ExpenseFormsContainer.displayName = 'ExpenseFormsContainer';
