import { authenticationStatuses } from './types';

export const isAuthenticated = (state) => state.authStatus === authenticationStatuses.AUTHENTICATED;
export const isAuthenticationInProgress = (state) => state.loginInProgress || state.refreshInProgress;
export const isLoginInProgress = (state) => state.loginInProgress;
export const isLoginChallenged = (state) => state.isLoginChallenged;
export const isAuthRefreshInProgress = (state) => state.refreshInProgress;
export const isLogoutInProgress = (state) => state.logoutInProgress;
export const isChallengeResponseInProgress = (state) => state.challengeResponseInProgress;

export const getAuthenticationFailure = (state) => state.failureDetails;

// This redux feature is written to operate with the UserIdentity and Challenge concepts.
// The userIdentity and challenge structures are exposed on the basis that the UserIdentity and Challenge are generic concepts from '@sb-itops/business-logic/authenication.
export const getUserIdentity = (state) => state.userIdentity;
export const getChallenge = (state) => state.challenge;
export const getChallengeType = (state) => state.challenge && state.challenge.challengeType;
export const getPasswordPolicy = (state) =>
  state.challenge && state.challenge.custom && state.challenge.custom.passwordPolicy;

// These are provided for convenience, the client of this feature could just as easily access them from getUserIdentity.
export const getUserRegion = (state) => state.userIdentity.region;
export const getAuthToken = (state) => state.userIdentity.authToken;
export const getAccessToken = (state) => state.userIdentity?.custom?.access;
export const getUsername = (state) => state.userIdentity.username;
export const getUserId = (state) => state.userIdentity.userId;
export const getAccountId = (state) => state.userIdentity.accountId;
export const getProvider = (state) => state.userIdentity.provider;
export const getProductTier = (state) => state.userIdentity.productTier;
export const getUserRoles = (state) => state.userIdentity.roles || [];
export const isFirmOwner = (state) => state.userIdentity.isFirmOwner;
export const isInternalUser = (state) => getUserRoles(state).includes('InternalUser');

export const isMissingProductClaim = (state) => state.userIdentity.isMissingProductClaim;

// The owning company is one of 'Smokeball'|'TriConvey'|'PracticeEvolve' or potentially others in the future.
export const getOwningCompany = (state) => state.userIdentity.owningCompany;
