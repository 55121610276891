import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { LinkableText, Modal, Button, buttonTypes } from '@sb-itops/react';
import { TriangleExclamation } from 'web/components/icons';
import classnames from 'classnames';

import Styles from './Message.module.scss';

export const MessageTransaction = ({ onSyncClick, onIgnoreClick, transactionCount, integrationName }) => {
  const [modalIgnoreOpened, setModalIgnoreOpened] = useState(false);
  const [modalSyncOpened, setModalSyncOpened] = useState(false);

  return (
    <>
      <div className={classnames(Styles.messageContainer, Styles.warning)}>
        <TriangleExclamation fill="#8a6d3b" />
        &nbsp; You have {transactionCount} failed transaction{transactionCount > 1 ? 's' : ''} in the last 7 days.&nbsp;
        <LinkableText
          text="Retry Sync"
          onClickLink={() => {
            setModalSyncOpened(true);
          }}
          asLink
        />
        &nbsp;or&nbsp;
        <LinkableText
          text="Ignore"
          onClickLink={() => {
            setModalIgnoreOpened(true);
          }}
          asLink
        />
      </div>
      {modalIgnoreOpened && confirmIgnoreModal({ onIgnoreClick, integrationName, setModalIgnoreOpened })}
      {modalSyncOpened && confirmSyncModal({ onSyncClick, transactionCount, setModalSyncOpened })}
    </>
  );
};

const confirmIgnoreModal = ({ onIgnoreClick, integrationName, setModalIgnoreOpened }) => (
  <Modal
    className={Styles.messageModal}
    isVisible
    title="Ignore"
    body={
      <div className={Styles.modalBody}>
        <p>{`Ignoring will dismiss this warning & will not send the failed transaction to your ${integrationName}.`}</p>
        <p>Do you wish to Ignore?</p>
      </div>
    }
    footer={
      <div className={Styles.modalFooter}>
        <Button
          type={buttonTypes.primary}
          onClick={() => {
            setModalIgnoreOpened(false);
            onIgnoreClick();
          }}
        >
          Yes
        </Button>
        <Button
          type={buttonTypes.secondary}
          onClick={() => {
            setModalIgnoreOpened(false);
          }}
        >
          No
        </Button>
      </div>
    }
    onModalClose={() => {
      setModalIgnoreOpened(false);
    }}
  />
);
const confirmSyncModal = ({ onSyncClick, transactionCount, setModalSyncOpened }) => (
  <Modal
    className={Styles.messageModal}
    isVisible
    title="Retry Sync"
    body={
      <div className={Styles.modalBody}>
        <p>{`Replay ${transactionCount} errored transaction${transactionCount > 1 ? 's' : ''} for this account`}</p>
      </div>
    }
    footer={
      <div className={Styles.modalFooter}>
        <Button
          type={buttonTypes.primary}
          onClick={() => {
            setModalSyncOpened(false);
            onSyncClick();
          }}
        >
          Confirm
        </Button>
        <Button
          type={buttonTypes.secondary}
          onClick={() => {
            setModalSyncOpened(false);
          }}
        >
          Cancel
        </Button>
      </div>
    }
    onModalClose={() => {
      setModalSyncOpened(false);
    }}
  />
);

MessageTransaction.displayName = 'MessageTransaction';

MessageTransaction.propTypes = {
  onSyncClick: PropTypes.func.isRequired,
  onIgnoreClick: PropTypes.func.isRequired,
  transactionCount: PropTypes.number.isRequired,
  integrationName: PropTypes.string.isRequired,
};
MessageTransaction.defaultProps = {};
