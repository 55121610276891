/* eslint-disable func-names */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Table, Column, utils } from '@sb-itops/react/table';
import { ContactDisplay } from '@sb-customer-management/react/contact-display';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { MatterDisplay } from '@sb-matter-management/react/matter-display';
import { MattersDisplay } from '@sb-matter-management/react/matters-display';
import { InterpolatedDescription } from 'web/react-redux/components/interpolated-description';
import { sort as sortItems } from '@sb-itops/sort';
import { featureActive } from '@sb-itops/feature';
import Styles from './LedgerTransactionTable.module.scss';

const { balanceCellLocalisedRenderer, yyyymmddLocalisedRenderer, timestampLocalisedRenderer, conditionalRenderer } =
  utils;

const descriptionCellRenderer =
  ({ onClickLink }) =>
  ({ rowData, cellData }) => (
    <InterpolatedDescription description={cellData} deleted={rowData.isHidden} onClickLink={onClickLink} />
  );

const contactsDisplayCellRenderer =
  ({ onClickLink }) =>
  ({ cellData }) => {
    if (!cellData) {
      return null;
    }

    const tooltip = cellData.map((debtorId) => getContactDisplay(debtorId, { showLastNameFirst: true })).join(' | ');

    return (
      <div className={Styles.invoiceDebtors}>
        <span className={Styles.debtorsDisplay}>
          {cellData
            .map((debtorId) => (
              <ContactDisplay
                key={debtorId}
                onClickLink={onClickLink}
                asLink
                contactId={debtorId}
                inline
                className={Styles.debtor}
                showLastNameFirst
                tooltip={tooltip}
              />
            ))
            .reduce((acc, elem) => {
              if (acc === null) {
                return [elem];
              }
              return [...acc, ' | ', elem];
            }, null)}
        </span>
        {cellData.length > 1 && (
          <span className={Styles.multiDebtorsIcon}>
            <i title={tooltip} className={classnames('icon', 'icon-contacts-1')} />
          </span>
        )}
      </div>
    );
  };

const matterDisplayCellRenderer =
  ({ onClickLink }) =>
  ({ cellData, rowData }) => {
    if (cellData) {
      return <MatterDisplay matterId={cellData} onClickLink={onClickLink} asLink />;
    }

    if (rowData.matterIds && rowData.matterIds.length) {
      return <MattersDisplay matterIds={rowData.matterIds} onClickLink={onClickLink} asLink />;
    }

    return null;
  };

const footerBalanceRenderer =
  (field) =>
  ({ footerData }) =>
    balanceCellLocalisedRenderer({ cellData: footerData[field] });

const ROW_TYPES = {
  dueAmount: 'DUE_AMOUNT',
  payment: 'PAYMENT',
};

const LedgerTransactionTable = memo(
  ({
    transactions,
    sortBy,
    onRowClick,
    sortDirection,
    showEnteredDateColumn,
    showMatterColumn,
    showContactColumn,
    onClickLink,
    onSort,
  }) => {
    // loop in transaction and get the summary based on the totals and create the list with the balance.
    const transactionsData = useMemo(() => {
      // this is going to process the data and generate the balances and summary for this table. we need to sort in asc order to calculate them.
      // depending on how we implement the container we may need to move this to the redux container in the future.
      const { summary, list } = sortItems(transactions, ['effectiveDate', 'timestamp'], ['asc', 'asc']).reduce(
        (acc, txn) => {
          const balanceDelta = txn.type === ROW_TYPES.payment ? (txn.amount || txn.credit) * -1 : txn.amount;

          if (txn.waived) {
            acc.summary.balance += balanceDelta - txn.waived;
          } else {
            acc.summary.balance += balanceDelta;
          }

          acc.summary.dueInvoice += txn.type === ROW_TYPES.dueAmount ? txn.amount : 0;
          acc.summary.waived += txn.waived || 0;
          acc.summary.payment += txn.type === ROW_TYPES.payment && txn.amount ? txn.amount : 0;
          acc.summary.credit += txn.credit || 0;

          acc.list.push({
            ...txn,
            balance: acc.summary.balance,
          });

          return acc;
        },
        { summary: { balance: 0, dueInvoice: 0, waived: 0, payment: 0, credit: 0 }, list: [] },
      );

      return {
        summary,
        // once we have the balances following their order we need to apply the users order
        list: sortItems(
          list,
          ['effectiveDate', 'timestamp', 'processOrder'],
          [sortDirection, sortDirection, sortDirection],
        ),
      };
    }, [transactions, sortDirection]);

    const onRowClickHandler = (e) => onRowClick(e.event, e.rowData);

    return (
      <Table
        list={transactionsData.list}
        summary={transactionsData.summary}
        onRowClick={onRowClickHandler}
        sortBy={sortBy}
        sortDirection={sortDirection}
        sort={onSort}
        showFooter
      >
        <Column
          className={Styles.effectiveDate}
          dataKey="effectiveDate"
          label="Transaction"
          flexGrow={0.6}
          cellRenderer={yyyymmddLocalisedRenderer}
        />
        {showEnteredDateColumn && (
          <Column
            dataKey="timestamp"
            label="Entered"
            flexGrow={0.6}
            cellRenderer={conditionalRenderer(
              ({ rowData }) => rowData.type === ROW_TYPES.payment,
              ({ cellData }) => timestampLocalisedRenderer({ cellData }),
            )}
          />
        )}
        <Column
          dataKey="description"
          label="Description"
          cellRenderer={descriptionCellRenderer({ onClickLink })}
          flexGrow={1}
          disableSort
        />
        {showContactColumn && !featureActive('BB-9790') && (
          <Column
            dataKey="contactIds"
            label={featureActive('BB-4416') ? 'Contact(s)' : 'Contact'}
            cellRenderer={contactsDisplayCellRenderer({ onClickLink })}
            flexGrow={1}
            disableSort
          />
        )}
        {showContactColumn && featureActive('BB-9790') && (
          <Column
            dataKey="payorIds"
            label="Paid By"
            cellRenderer={contactsDisplayCellRenderer({ onClickLink })}
            flexGrow={1}
            disableSort
          />
        )}
        {showContactColumn && featureActive('BB-9790') && (
          <Column
            dataKey="debtorIds"
            label="Debtor(s)"
            cellRenderer={contactsDisplayCellRenderer({ onClickLink })}
            flexGrow={1}
            disableSort
          />
        )}
        {showMatterColumn && (
          <Column
            dataKey="matterId"
            label="Matter"
            cellRenderer={matterDisplayCellRenderer({ onClickLink })}
            flexGrow={1}
            disableSort
          />
        )}
        <Column
          className={Styles.numericColumn}
          dataKey="amount"
          label="Invoice"
          cellRenderer={conditionalRenderer(
            ({ rowData }) => rowData.type === ROW_TYPES.dueAmount,
            balanceCellLocalisedRenderer,
          )}
          footerRenderer={footerBalanceRenderer('dueInvoice')}
          flexGrow={0.6}
          disableSort
        />
        <Column
          dataKey="amount"
          className={Styles.numericColumn}
          label="Payment"
          cellRenderer={conditionalRenderer(
            ({ rowData }) => rowData.type === ROW_TYPES.payment && !!rowData.amount,
            balanceCellLocalisedRenderer,
          )}
          footerRenderer={footerBalanceRenderer('payment')}
          flexGrow={0.6}
          disableSort
        />
        <Column dataKey="source" label="Payment Source" flexGrow={0.6} disableSort />
        <Column
          dataKey="credit"
          className={Styles.numericColumn}
          label="Credit"
          cellRenderer={conditionalRenderer(({ cellData }) => !!cellData, balanceCellLocalisedRenderer)}
          footerRenderer={balanceCellLocalisedRenderer}
          flexGrow={0.6}
          disableSort
        />
        <Column
          dataKey="waived"
          className={Styles.numericColumn}
          label="Waived"
          cellRenderer={conditionalRenderer(({ cellData }) => !!cellData, balanceCellLocalisedRenderer)}
          footerRenderer={balanceCellLocalisedRenderer}
          flexGrow={0.6}
          disableSort
        />
        <Column
          dataKey="balance"
          className={Styles.numericColumn}
          label="Balance"
          cellRenderer={balanceCellLocalisedRenderer}
          footerRenderer={balanceCellLocalisedRenderer}
          flexGrow={0.6}
          disableSort
        />
      </Table>
    );
  },
);

LedgerTransactionTable.displayName = 'LedgerTransactionTable';

LedgerTransactionTable.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      effectiveDateDisplay: PropTypes.objectOf(Date),
      timestamp: PropTypes.string,
      description: PropTypes.string,
      contactId: PropTypes.string,
      amount: PropTypes.number,
      source: PropTypes.string,
      waived: PropTypes.number,
    }),
  ),
  onSort: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  showEnteredDateColumn: PropTypes.bool,
  showMatterColumn: PropTypes.bool,
  showContactColumn: PropTypes.bool,
  sortDirection: PropTypes.string,
  sortBy: PropTypes.string,
};

LedgerTransactionTable.defaultProps = {
  transactions: [],
  sortDirection: 'desc',
  sortBy: 'effectiveDateDisplay',
  showEnteredDateColumn: false,
  showMatterColumn: false,
  showContactColumn: false,
};

export default LedgerTransactionTable;
