import React from 'react';
import classnames from 'classnames';
import { Button, buttonTypes, Spinner, Tooltip, useTranslation } from '@sb-itops/react';
import * as messageDisplay from '@sb-itops/message-display';
import { Staff, File, Document } from 'types';
import { FileDetailQuery } from 'web/graphql/types/graphql';
import { nonNullishFieldsGuardFactory } from '@sb-itops/type-helpers';
import { featureActive } from '@sb-itops/feature';
import { sendMetric } from 'web/services/metrics';
import Styles from './ViewFile.module.scss';
import ModalStyles from './Modal.module.scss';

const isImageMap = {
  '.jpg': true,
  '.jpeg': true,
  '.png': true,
  '.gif': true,
  '.tiff': true,
};

export interface IViewFileProps {
  setVersionId: (id: string) => void;
  documentURL: string | undefined;
  versionId: string;
  matterId: string;
  loading: boolean;
  setLoading: (id: boolean) => void;
  replaceFile: (files: FileList | globalThis.File[] | null) => Promise<void>;
  restoreFile: (versionId: string) => Promise<void>;
  downloadFile: (versionId: string) => Promise<void>;
  fileDetailResult: { data: FileDetailQuery | undefined; loading: boolean; refetch?: Function };
  file: File;
  getPersonByUserId: (id: string) => Staff;
  shareFile: (props: { file: File; isLiving: boolean }) => void;
  onFileEditing: (file: Document) => void;
  onUriHandled: () => void;
  isFileManagerInstalled: boolean;
  oneDriveIsUnauthorized: boolean;
  isOpeningDocumentOnWeb: boolean;
  openDocumentOnWeb: (fileId: string) => Promise<void>;
}

const detailsGuard = nonNullishFieldsGuardFactory(['versionId', 'changeType']);

export const ViewFileV2 = ({
  setVersionId,
  documentURL,
  versionId,
  fileDetailResult,
  file,
  shareFile,
  isFileManagerInstalled,
  onUriHandled,
  oneDriveIsUnauthorized,
  isOpeningDocumentOnWeb,
  openDocumentOnWeb,
  matterId,
  onFileEditing,
}: IViewFileProps) => {
  const { t } = useTranslation();

  let filePreviewContent = (
    <div className={Styles.transform}>
      <Spinner small />
    </div>
  );
  if (documentURL !== '' && !isImageMap[file.data.fileExtension]) {
    filePreviewContent = (
      <object data={documentURL} className={Styles.viewer}>
        <p>This document cannot be previewed</p>
      </object>
    );
  } else if (documentURL !== '' && isImageMap[file.data.fileExtension]) {
    filePreviewContent = <img src={documentURL} className={Styles.viewer} alt="This file cannot be previewed" />;
  }

  const formatFileFolderChangeTimeStamp = (ts) => {
    const monthAndDay = t('date', { ts, format: 'D MMM YYYY' });
    const formattedTimeStamp = `${monthAndDay}`;
    return formattedTimeStamp;
  };

  const isEmail = ['.msg', '.eml'].includes(file.data.fileExtension);

  const isFileEditEnabled = !['.eml', '.msg'].includes(file.data.fileExtension);

  const editOnWebAvailable =
    featureActive('NV-5392') &&
    file.type === 'file' &&
    file.data?.fileExtension === '.docx' &&
    oneDriveIsUnauthorized === false;

  const showOpenDesktop = featureActive('NUCWEB-728') && file.type === 'file' && isFileEditEnabled;

  return (
    <div className={ModalStyles.mainForm}>
      <div className={classnames(Styles.viewFileContainer, Styles.viewFileV2)}>
        <div className={Styles.fileDetail}>
          <div className={Styles.fileInfoV2}>
            {isEmail && (
              <div className={Styles.text}>
                <div className={Styles.title}>To</div>
                {file.data.to}
              </div>
            )}
            {isEmail && (
              <div className={Styles.text}>
                <div className={Styles.title}>From</div>
                {file.data.from}
              </div>
            )}
            <div className={Styles.text}>
              <div className={Styles.title}>Date Modified</div>
              {t('date', { date: file.data.dateModified, format: 'hhmm a DD/MM/YYYY' })}
            </div>
            <div className={Styles.text}>
              <div className={Styles.title}>Date Created</div>
              {t('date', { date: file.data.dateCreated, format: 'hhmm a DD/MM/YYYY' })}
            </div>
          </div>
          <div className={Styles.versions}>
            {fileDetailResult?.loading && (
              <div className={Styles.loading}>
                <Spinner small />
              </div>
            )}
            {(fileDetailResult?.data?.fileDetail?.versions || []).filter(detailsGuard).map((item, index) => (
              <Button
                key={item.versionId + item.clientTimestamp}
                type="tertiary"
                locked={!item.versionId}
                className={classnames(
                  Styles.version,
                  versionId === (index === 0 ? '' : item.versionId) && Styles.selected,
                )}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  // downloadFile(item.versionId);
                  if (file.data.id !== item.versionId || index === 0) {
                    setVersionId(index === 0 ? '' : item.versionId);
                  } else {
                    messageDisplay.info('The selected version cannot be previewed');
                  }
                }}
              >
                {`Version ${item.versionNumber}, ${formatFileFolderChangeTimeStamp(
                  item.timestamp || item.clientTimestamp,
                )}`}
              </Button>
            ))}
          </div>
        </div>
        <hr className={Styles.verticalSeparator} />
        <div className={Styles.embeddedPdf}>{filePreviewContent}</div>
      </div>
      <div className={ModalStyles.footer}>
        {featureActive('LOOP-561') && file.type === 'file' && (
          <Button
            type={buttonTypes.tertiary}
            disabled={!documentURL}
            onClick={() => shareFile({ file, isLiving: true })}
          >
            Share a link
          </Button>
        )}
        {featureActive('LOOP-561') && file.type === 'file' && (
          <Button
            type={buttonTypes.tertiary}
            disabled={!documentURL}
            onClick={() => shareFile({ file, isLiving: false })}
          >
            Send a copy
          </Button>
        )}
        {showOpenDesktop && (
          <Tooltip title="You need the smokeball file manager installed to open a file directly on the desktop">
            <Button
              type={buttonTypes.secondary}
              disabled={!isFileManagerInstalled}
              onClick={(e) => {
                e.stopPropagation();

                if (!isFileEditEnabled) {
                  return;
                }

                sendMetric('DocumentsOpenDesktop');

                // smokeballfilemanager://DownloadFile?matterId=MATTERID&fileId=FILEID&fileName=FILENAME.EXTENSION
                document.location = `smokeballfilemanager://DownloadFile?matterId=${encodeURIComponent(
                  matterId,
                )}&fileId=${encodeURIComponent(file.data.id)}&fileName=${encodeURIComponent(
                  `${file.data.name || ''}${file.data.fileExtension || ''}`,
                )}`;
                setTimeout(() => {
                  if (isFileManagerInstalled) {
                    return;
                  }
                  if (document.hasFocus()) {
                    onFileEditing(file);
                  } else {
                    onUriHandled();
                  }
                }, 100);
              }}
            >
              Open in Desktop
            </Button>
          </Tooltip>
        )}
        {editOnWebAvailable && (
          <Button
            type={buttonTypes.secondary}
            locked={isOpeningDocumentOnWeb}
            onClick={(e) => {
              e.stopPropagation();

              sendMetric('DocumentsOpenWeb');
              openDocumentOnWeb(file.data.id);
            }}
          >
            Open in Web
          </Button>
        )}
        {/* <Button
          type={buttonTypes.tertiary}
          disabled={!documentURL}
          onClick={() => {
            // TODO
            document.getElementsByClassName(Styles.embeddedPdf)[0].print()
          }}
        >
          Print
        </Button> */}
        <Button
          type={buttonTypes.tertiary}
          disabled={!documentURL}
          onClick={() => {
            if (documentURL === undefined) {
              return;
            }
            sendMetric('DocumentsDownload');
            const link = document.createElement('a');
            link.download = `${file.data.name}.pdf`;

            link.href = documentURL;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }}
        >
          Download
        </Button>
      </div>
    </div>
  );
};
