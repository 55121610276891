import React from 'react';
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withApolloClient, withReduxProvider } from 'web/react-redux/hocs';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { integrations } from '@sb-billing/business-logic/integration-history/entities';
import { PageFailedTransactionsNotifierIntegrationContainer } from './PageFailedTransactionsNotifierIntegration.container';

const hooks = () => ({
  useSettings: () => ({
    isXeroSupported: hasFacet(facets.integrationXero) && featureActive('BB-6222'),
    isMyobSupported: hasFacet(facets.integrationMyob),
  }),
});

export const PageFailedTransactionsNotifierContainer = withApolloClient(
  withReduxProvider(
    composeHooks(hooks)(({ isXeroSupported, isMyobSupported, onClickLink }) => (
      <>
        {isXeroSupported && (
          <PageFailedTransactionsNotifierIntegrationContainer
            integration={integrations.XERO}
            onClickLink={onClickLink}
          />
        )}
        {isMyobSupported && (
          <PageFailedTransactionsNotifierIntegrationContainer
            integration={integrations.MYOB}
            onClickLink={onClickLink}
          />
        )}
      </>
    )),
  ),
);

PageFailedTransactionsNotifierContainer.displayName = 'PageFailedTransactionsNotifierContainer';

PageFailedTransactionsNotifierContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
};
