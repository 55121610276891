import { gql } from '@apollo/client';

const query = gql`
  query SupportDataExplorerExpense($id: ID!) {
    expenseVersions(id: $id) {
      id
      versions {
        # id # excluding ID on purpose to avoid overriding Expense cache with older versions
        activityCode
        amountExcTax
        amountIncludesTax
        attachmentFile {
          location
          fileName
        }
        createdByUserId
        createdByStaff {
          id
          initials
          isFormerStaff
          name
        }
        description
        expenseActivityId
        expenseDate
        expenseEarnerStaff {
          id
          initials
        }
        expensePaymentDetails {
          id
          supplier {
            id
          }
          supplierReference
          paymentDue
          isPayable
          isPaid
          paymentMethod
          paymentReference
          paymentAccountName
          lastUpdated
        }
        finalized
        invoice {
          id
        }
        isAnticipated
        isBillable
        isDeleted
        isInvoicedExternally
        isTaxOverridden
        matter {
          id
        }
        notes
        operatingCheque {
          id
        }
        price
        quantity
        tax
        utbmsActivityCode
        utbmsTaskCode
        validFrom
        validTo
        waived
        displayWithFees
      }
    }
  }
`;

const notificationIds = ['ExpensesNotifications.ExpenseUpdated', 'ExpensesNotifications.ExpenseDeleted'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const SupportDataExplorerExpense = {
  query,
  notificationIds,
};
