import { fetchGetP } from '@sb-itops/redux/fetch';

export const getInfoTrackUrl = async (matterId: string, matterTypeId: string, documentId?: string) => {
  const basePath = `/integration/endpoint-locator/infotrack/:accountId/${matterId}/${matterTypeId}/${documentId}`;

  const response = await fetchGetP({
    path: basePath,
    fetchOptions: {},
  });

  if (response.status === 200) {
    const result = response.body;
    const url = result.url;

    return url;
  }

  throw new Error(`unexpected status: ${response.status}`);
};
