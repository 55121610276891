/* eslint-disable func-names */
import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';
import { ContactDisplay } from '@sb-customer-management/react/contact-display';
import { MatterDisplay } from '@sb-matter-management/react/matter-display';
import { InterpolatedDescription } from 'web/react-redux/components/interpolated-description';
import { getById as getPaymentById } from '@sb-billing/redux/payments';
import { sort as sortItems } from '@sb-itops/sort';
import { integerToDate } from '@sb-itops/date';

import { CreditNoteTransactionDetailsModal, CreditNotePaymentDetailsModal } from '../credit-note-details';
import Styles from './CreditNoteLedgerTransactionTable.module.scss';

const { balanceCellLocalisedRenderer, timestampLocalisedRenderer, yyyymmddLocalisedRenderer } = utils;

const descriptionCellRenderer =
  ({ onClickLink }) =>
  ({ rowData, cellData }) => (
    <InterpolatedDescription description={cellData} deleted={rowData.isHidden} onClickLink={onClickLink} />
  );

const contactsDisplayCellRenderer =
  ({ onClickLink }) =>
  ({ cellData }) => {
    if (!cellData) {
      return null;
    }

    return (
      <ContactDisplay
        key={cellData}
        onClickLink={onClickLink}
        asLink
        contactId={cellData}
        inline
        className={Styles.debtor}
        showLastNameFirst
      />
    );
  };

const matterDisplayCellRenderer =
  ({ onClickLink }) =>
  ({ cellData }) => {
    if (cellData) {
      return <MatterDisplay matterId={cellData} onClickLink={onClickLink} asLink />;
    }

    return null;
  };

const CreditNoteLedgerTransactionTable = memo(
  ({
    transactions,
    sortBy,
    sortDirection,
    showEnteredDateColumn,
    showMatterColumn,
    showContactColumn,
    onClickLink,
    onSort,
  }) => {
    const [showTransactionDetailsModal, setShowTransactionDetailsModal] = useState(false);
    const [showPaymentDetailsModal, setShowPaymentDetailsModal] = useState(false);
    const [transactionIdToShow, setTransactionIdToShow] = useState(undefined);

    const openTransactionDetailsModal = (transactionId) => {
      setShowTransactionDetailsModal(true);
      setTransactionIdToShow(transactionId);
    };

    const closeTransactionDetailsModal = () => {
      setShowTransactionDetailsModal(false);
      setTransactionIdToShow(undefined);
    };

    const openPaymentDetailsModal = (transactionId) => {
      setShowPaymentDetailsModal(true);
      setTransactionIdToShow(transactionId);
    };

    const closePaymentDetailsModal = () => {
      setShowPaymentDetailsModal(false);
      setTransactionIdToShow(undefined);
    };

    // create transaction list with running balance, also adding summary balance
    const transactionsData = useMemo(() => {
      // we need to sort in asc order to calculate the balance correctly.
      const { summary, list } = sortItems(transactions, ['effectiveDate', 'timestamp'], ['asc', 'asc']).reduce(
        (acc, transaction) => {
          const balanceDelta = transaction.amount;
          acc.summary.balance += balanceDelta;

          const payment = transaction.paymentId ? getPaymentById(transaction.paymentId) : undefined;
          const invoiceIds =
            payment &&
            payment.invoices &&
            payment.invoices.map((invoice) => `#invoiceId:${invoice.invoiceId}`).join(' ');

          // for credit applied to an invoice, we'll manually generate a description, i.e. ignoring
          // the description generated by the system (because there's no .net resources to do this work)
          const description = transaction.paymentId ? `Credit applied to invoice ${invoiceIds}` : 'Matter credited';

          const isReversal = !!transaction.reversedFromTransactionId;
          acc.list.push({
            transactionId: transaction.id,
            effectiveDate: transaction.effectiveDate,
            effectiveDateDisplay: transaction.effectiveDate && integerToDate(transaction.effectiveDate),
            timestamp: transaction.timestamp,
            description: `${isReversal ? 'Reversal: ' : ''}${description}`,
            contactId: transaction.contactId,
            matterId: transaction.matterId,
            amount: transaction.amount,
            isHidden: transaction.isHidden,
            isPayment: !!transaction.paymentId,
            balance: acc.summary.balance,
          });

          return acc;
        },
        { summary: { balance: 0 }, list: [] },
      );

      return {
        summary,
        // once we have the balances, we need to apply the user selected sort order
        list:
          sortBy === 'timestamp'
            ? sortItems(list, ['timestamp'], [sortDirection]) // sort by entered date (system timestamp)
            : sortItems(list, ['effectiveDate', 'timestamp'], [sortDirection, sortDirection]), // default
      };
    }, [transactions, sortDirection, sortBy]);

    const onRowClickHandler = (e) => {
      if (e.rowData.isPayment) {
        openPaymentDetailsModal(e.rowData && e.rowData.transactionId);
      } else {
        openTransactionDetailsModal(e.rowData && e.rowData.transactionId);
      }
    };

    return (
      <>
        {showTransactionDetailsModal && transactionIdToShow && (
          <CreditNoteTransactionDetailsModal
            transactionId={transactionIdToShow}
            onClose={closeTransactionDetailsModal}
            onClickLink={onClickLink}
            isVisible={showTransactionDetailsModal}
          />
        )}
        {showPaymentDetailsModal && transactionIdToShow && (
          <CreditNotePaymentDetailsModal
            transactionId={transactionIdToShow}
            onClose={closePaymentDetailsModal}
            onClickLink={onClickLink}
            isVisible={showPaymentDetailsModal}
          />
        )}
        <Table
          list={transactionsData.list}
          summary={transactionsData.summary}
          onRowClick={onRowClickHandler}
          sortBy={sortBy}
          sortDirection={sortDirection}
          sort={onSort}
          showFooter
        >
          <Column
            className={Styles.effectiveDate}
            dataKey="effectiveDate"
            label="Transaction"
            flexGrow={0.6}
            cellRenderer={yyyymmddLocalisedRenderer}
          />
          {showEnteredDateColumn && (
            <Column dataKey="timestamp" label="Entered" flexGrow={0.6} cellRenderer={timestampLocalisedRenderer} />
          )}
          <Column
            dataKey="description"
            label="Description"
            cellRenderer={descriptionCellRenderer({ onClickLink })}
            flexGrow={1}
            disableSort
          />
          {showMatterColumn && (
            <Column
              dataKey="matterId"
              label="Matter"
              cellRenderer={matterDisplayCellRenderer({ onClickLink })}
              flexGrow={1}
              disableSort
            />
          )}
          {showContactColumn && (
            <Column
              dataKey="contactId"
              label="Contact"
              cellRenderer={contactsDisplayCellRenderer({ onClickLink })}
              flexGrow={1}
              disableSort
            />
          )}
          <Column
            className={Styles.numericColumn}
            dataKey="amount"
            label="Amount"
            cellRenderer={balanceCellLocalisedRenderer}
            flexGrow={0.6}
            disableSort
          />
          <Column
            dataKey="balance"
            className={Styles.numericColumn}
            label="Balance"
            cellRenderer={balanceCellLocalisedRenderer}
            footerRenderer={balanceCellLocalisedRenderer}
            flexGrow={0.6}
            disableSort
          />
        </Table>
      </>
    );
  },
);

CreditNoteLedgerTransactionTable.displayName = 'CreditNoteLedgerTransactionTable';

CreditNoteLedgerTransactionTable.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      effectiveDate: PropTypes.number,
      timestamp: PropTypes.string,
      note: PropTypes.string,
      contactId: PropTypes.string,
      matterId: PropTypes.string,
      paymentId: PropTypes.paymentId,
      amount: PropTypes.number,
    }),
  ),
  onSort: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  showEnteredDateColumn: PropTypes.bool,
  showMatterColumn: PropTypes.bool,
  showContactColumn: PropTypes.bool,
  sortDirection: PropTypes.string,
  sortBy: PropTypes.string,
};

CreditNoteLedgerTransactionTable.defaultProps = {
  transactions: [],
  sortDirection: 'desc',
  sortBy: 'effectiveDateDisplay',
  showEnteredDateColumn: false,
  showMatterColumn: false,
  showContactColumn: false,
};

export default CreditNoteLedgerTransactionTable;
