/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import classnames from 'classnames';
import { Button } from '../button';
import { LoadingBarInfinite } from '../loading-bar-infinite';

import Styles from './Modal.module.scss';
// ariaHideApp is an accessability thing (hence aria).
// we need to turn it off until we can start setting the appElement of the react stuff

const Modal = (props) => {
  const {
    titleIcon,
    titleIconClassName,
    title,
    body,
    footer,
    onModalClose,
    shouldCloseOnOverlayClick,
    className,
    modalHeaderClassName,
    modalBodyClassName,
    ignoreModalHeaderLoading, // don't change modal header based on loading
    modalBodyLoadingMinHeight,
    modalFooterClassName,
    isLoading,
    isModalBodyScrollable,
    isVisible,
    showFooter,
    showModalBodyPadding,
  } = props;
  const onClose = (event) => onModalClose(event);

  const defaultFooter = <Button onClick={onClose}>OK</Button>;
  const cssClasses = classnames('sb-modal', className);

  const modalHeaderClassNames = classnames(
    'sb-modal-header',
    isLoading && !ignoreModalHeaderLoading ? Styles.modalHeaderLoading : modalHeaderClassName,
  );
  const modalBodyClassNames = classnames(
    'sb-modal-body',
    showModalBodyPadding ? 'sb-modal-body-with-padding' : undefined,
    isModalBodyScrollable ? 'sb-modal-body-scrollable' : undefined,
    isLoading ? Styles.modalBodyLoading : modalBodyClassName,
  );
  const modalFooterClassNames = classnames(
    'sb-modal-footer',
    isLoading ? Styles.modalFooterLoading : modalFooterClassName,
  );

  return (
    <ReactModal
      className={cssClasses}
      overlayClassName="overlay"
      ariaHideApp={false}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      isOpen={isVisible !== undefined ? isVisible : true} // Bonkers backward compatibility
    >
      {props.title && (
        <div className={modalHeaderClassNames}>
          {titleIcon && <span className={classnames('title-icon-container', titleIconClassName)}>{titleIcon}</span>}
          <h1>{title}</h1>
          <button type="button" className="close-icon" onClick={onClose} />
        </div>
      )}
      {isLoading ? (
        <div className={Styles.modalBodyLoading} style={{ minHeight: modalBodyLoadingMinHeight }}>
          <LoadingBarInfinite loading={isLoading} />
        </div>
      ) : (
        <div className={modalBodyClassNames}>{body}</div>
      )}
      {showFooter && <div className={modalFooterClassNames}>{footer || defaultFooter}</div>}
    </ReactModal>
  );
};

Modal.displayName = 'Modal';

Modal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleIcon: PropTypes.node,
  titleIconClassName: PropTypes.string, // A css class used to style the title icon, typically to set the colour.
  body: PropTypes.node.isRequired,
  onModalClose: PropTypes.func.isRequired,
  shouldCloseOnOverlayClick: PropTypes.bool,
  footer: PropTypes.node,
  className: PropTypes.string,
  modalHeaderClassName: PropTypes.string,
  modalBodyClassName: PropTypes.string,
  modalBodyLoadingMinHeight: PropTypes.string,
  modalFooterClassName: PropTypes.string,
  ignoreModalHeaderLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  isModalBodyScrollable: PropTypes.bool,
  isVisible: PropTypes.bool,
  showFooter: PropTypes.bool,
  showModalBodyPadding: PropTypes.bool,
};

Modal.defaultProps = {
  shouldCloseOnOverlayClick: false,
  footer: undefined,
  title: undefined,
  titleIcon: undefined,
  titleIconClassName: undefined,
  className: undefined,
  modalHeaderClassName: undefined,
  modalBodyClassName: undefined,
  modalBodyLoadingMinHeight: '400px',
  modalFooterClassName: undefined,
  ignoreModalHeaderLoading: false,
  isLoading: false,
  isModalBodyScrollable: true,
  isVisible: undefined,
  showFooter: true,
  showModalBodyPadding: true,
};

export { Modal };
export default Modal;
