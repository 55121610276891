import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

import { DatePicker } from '@sb-itops/react/date-picker.2';
import { WarningList } from '@sb-itops/react/warning-list';
import { Spinner } from '@sb-itops/react/spinner';
import { capitalize } from '@sb-itops/nodash';
import { SlidingToggle, useTranslation } from '@sb-itops/react';

import { assignReferenceNumberToCheques } from './assign-reference-number-to-cheques';
import { UnprintedChequeTable } from '../unprinted-cheque-table';

import styles from './PrintChequesForm.module.scss';

const REDUX_DATE_FORMAT = 'YYYYMMDD';

const PrintChequesForm = ({
  formInitialised,
  unprintedCheques,
  sortDirection,
  accountName,
  overrideChequeDate,
  availableChequeNumbers,
  lastChequeNumberFound,
  firstChequeNumber,
  chequeNumberPadding,
  selectedChequeIds,
  overrideChequeMemos,
  showLoadingIndicator,
  errors,
  showDuplicateChequeNumberToggle,
  allowDuplicateChequeNumbers,
  // callbacks/functions
  onFieldValueChanged,
  onFieldValuesChanged,
  onSortDirectionChanged,
  // config
  showChequeMemo,
}) => {
  const { t } = useTranslation();
  const CHEQUE_LABEL = t('cheque');

  const onChequeSelectionChange = (chequeIdsToSelect) => {
    if (!errors.firstChequeNumber) {
      // create a map of assigned reference number
      const { cheques: chequesWithReference } = assignReferenceNumberToCheques(unprintedCheques, {
        chequesSelected: chequeIdsToSelect,
        firstChequeNumber,
        chequeNumberPadding,
        availableChequeNumbers,
        allowDuplicateNumbers: allowDuplicateChequeNumbers,
      });
      const assignedChequeReference = chequesWithReference.reduce((acc, cheque) => {
        acc[cheque.chequeId] = cheque.reference;
        return acc;
      }, {});

      onFieldValuesChanged({
        assignedChequeReference,
        selectedChequeIds: chequeIdsToSelect,
      });
    } else {
      // nullify assigned reference for all cheques
      const assignedChequeReference = unprintedCheques.reduce((acc, cheque) => {
        acc[cheque.chequeId] = undefined;
        return acc;
      }, {});

      onFieldValuesChanged({
        assignedChequeReference,
        selectedChequeIds: chequeIdsToSelect,
      });
    }
  };

  let chequesWithReferencePreview = unprintedCheques;
  let lastAssignedChequeNumber = '';
  if (!errors.firstChequeNumber) {
    // assigning cheque reference for preview purpose only, the actual assigning
    // of reference (chequeNumber) is performed by the .net backend upon printing cheque
    ({ cheques: chequesWithReferencePreview, lastChequeNumber: lastAssignedChequeNumber } =
      assignReferenceNumberToCheques(unprintedCheques, {
        chequesSelected: selectedChequeIds,
        firstChequeNumber,
        chequeNumberPadding,
        availableChequeNumbers,
        allowDuplicateNumbers: allowDuplicateChequeNumbers,
      }));
  }

  const onChequeNumberChange = (e) => {
    onFieldValueChanged('firstChequeNumber', e.target.value);
  };

  const onDateChange = (date) => {
    if (date === undefined) {
      onFieldValueChanged('overrideChequeDate', undefined);
    } else {
      onFieldValueChanged('overrideChequeDate', moment(date).format(REDUX_DATE_FORMAT));
    }
  };

  const onMemoTextChange = (chequeId, memoText) => {
    const oldOverrides = overrideChequeMemos || {};
    const newOverrides = {
      ...oldOverrides,
      [chequeId]: memoText,
    };
    onFieldValueChanged('overrideChequeMemos', newOverrides);
  };

  const onChequeSelected = ({ chequeId }, checked) => {
    const oldChequeIdsSelected = selectedChequeIds;
    const newChequeIdsSelected = {
      ...oldChequeIdsSelected,
      [chequeId]: !checked,
    };
    onChequeSelectionChange(newChequeIdsSelected);
  };

  const onToggleAllCheques = (toggleState) => {
    const newChequeIdsSelected = unprintedCheques.reduce((acc, { chequeId }) => {
      acc[chequeId] = toggleState;
      return acc;
    }, {});
    onChequeSelectionChange(newChequeIdsSelected);
  };

  const onToggleAllowDuplicateChequeNumbers = (checked) => {
    onFieldValueChanged('allowDuplicateChequeNumbers', checked);
  };

  return (
    <div className={styles.printChequeFormContainer}>
      {formInitialised && (
        <>
          <div className={styles.row}>
            <div className={classnames(styles.cell, styles.cell50, 'input-group')}>
              <label>{accountName}</label>
              <input className="form-control" value={accountName} type="text" disabled />
            </div>
            <div className={classnames(styles.cell, styles.cell50, errors.overrideChequeDate && styles.hasError)}>
              <label>{CHEQUE_LABEL} Date Override</label>
              <DatePicker value={overrideChequeDate} onSelect={onDateChange} format="DD/MM/YYYY" />
            </div>
          </div>
          <div className={styles.row}>
            <div
              className={classnames(
                styles.cell,
                styles.cell50,
                'input-group',
                errors.firstChequeNumber && styles.hasError,
              )}
            >
              <label>First {CHEQUE_LABEL} #</label>
              <input className="form-control" value={firstChequeNumber} type="text" onChange={onChequeNumberChange} />
            </div>
            <div className={classnames(styles.cell, 'input-group', styles.cell50)}>
              <label>Last {CHEQUE_LABEL} #</label>
              <input className="form-control" value={lastAssignedChequeNumber} type="text" disabled />
            </div>
          </div>
          {showDuplicateChequeNumberToggle ? (
            <div className={styles.row}>
              <div className={classnames(styles.cell)}>
                <SlidingToggle
                  scope="allowDuplicateChequeNumbers"
                  name="allowDuplicateChequeNumbers"
                  selected={allowDuplicateChequeNumbers}
                  onChange={(_name, checked) => onToggleAllowDuplicateChequeNumbers(checked)}
                />
              </div>
              <div className={classnames(styles.cell)}>
                <label>Allow duplicate {t('cheque')} numbers</label>
              </div>
            </div>
          ) : null}
          <WarningList
            list={[
              errors.firstChequeNumberMustBeNumeric && {
                text: `Warning: ${capitalize(CHEQUE_LABEL)} reference must be numeric. `,
              },
              errors.firstChequeNumberIsAlreadyInUse && {
                text: `Warning: ${capitalize(
                  CHEQUE_LABEL,
                )} reference is already in use. Last ${CHEQUE_LABEL} reference printed was ${lastChequeNumberFound}. `,
              },
            ].filter(Boolean)}
          />
          <div className={styles.chequesTable}>
            {showLoadingIndicator && (
              <div className={styles.spinnerContainer}>
                <Spinner height={200} />
              </div>
            )}
            {!showLoadingIndicator && (
              <UnprintedChequeTable
                cheques={chequesWithReferencePreview}
                selectedChequeIds={selectedChequeIds}
                overrideChequeMemos={overrideChequeMemos}
                onChequeSelected={onChequeSelected}
                onToggleAllCheques={onToggleAllCheques}
                onChequeMemoUpdated={onMemoTextChange}
                onSortDirectionChanged={onSortDirectionChanged}
                sortDirection={sortDirection}
                isFirstChequeNumberInvalid={false}
                showChequeMemo={showChequeMemo}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

PrintChequesForm.displayName = 'PrintChequesForm';

PrintChequesForm.propTypes = {
  formInitialised: PropTypes.bool.isRequired,
  unprintedCheques: PropTypes.arrayOf(
    PropTypes.shape({
      chequeId: PropTypes.string.isRequired,
      effectiveDate: PropTypes.number.isRequired,
      matters: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          matterNumber: PropTypes.string,
          clientDisplay: PropTypes.string,
          otherSideDisplay: PropTypes.string,
          matterType: PropTypes.shape({
            matterTypeId: PropTypes.string,
            name: PropTypes.string,
          }),
        }),
      ).isRequired,
      payee: PropTypes.string,
      reference: PropTypes.string,
      amount: PropTypes.number.isRequired,
      chequeMemo: PropTypes.string,
    }),
  ),
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  accountName: PropTypes.string.isRequired,
  overrideChequeDate: PropTypes.instanceOf(Date),
  availableChequeNumbers: PropTypes.array,
  lastChequeNumberFound: PropTypes.string,
  firstChequeNumber: PropTypes.string.isRequired,
  chequeNumberPadding: PropTypes.number,
  selectedChequeIds: PropTypes.object,
  overrideChequeMemos: PropTypes.object,
  showLoadingIndicator: PropTypes.bool,
  errors: PropTypes.object,
  showDuplicateChequeNumberToggle: PropTypes.bool,
  allowDuplicateChequeNumbers: PropTypes.bool,
  // callbacks/functions
  onFieldValueChanged: PropTypes.func.isRequired,
  onFieldValuesChanged: PropTypes.func.isRequired,
  onSortDirectionChanged: PropTypes.func.isRequired,
  // config
  showChequeMemo: PropTypes.bool.isRequired,
};

PrintChequesForm.defaultProps = {
  unprintedCheques: [],
  sortDirection: 'asc',
  overrideChequeDate: undefined,
  availableChequeNumbers: [],
  lastChequeNumberFound: undefined,
  chequeNumberPadding: 0,
  selectedChequeIds: {},
  overrideChequeMemos: {},
  showLoadingIndicator: false,
  errors: {},
  showDuplicateChequeNumberToggle: false,
  allowDuplicateChequeNumbers: false,
};

export default PrintChequesForm;
