import { gql } from '@apollo/client';

const query = gql`
  query SupportDataExplorerOperatingCheque($id: ID!) {
    operatingCheque(id: $id) {
      id
      chequeDate
      bankAccount {
        id
      }
      expenses {
        id
      }
      payTo {
        id
      }
      createdBy {
        id
        person {
          id
          name
          initials
        }
      }
      isPrinted
      lastUpdated
      isHidden
      reversed
      chequeMemo
      reversalReason
    }
  }
`;

const notificationIds = [
  'ExpensesNotifications.ExpenseUpdated',
  'ExpensesNotifications.ExpensePaymentDetailsUpdated',
  'AccountsNotifications.OperatingChequeUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const SupportDataExplorerOperatingCheque = {
  query,
  notificationIds,
};
