import React, { useEffect, useState } from 'react';
import { getLogger } from '@sb-itops/fe-logger';
import PropTypes from 'prop-types';

import { statusByName as invoiceStatusByName } from '@sb-billing/business-logic/invoice/entities';
import {
  getContactMailingAddress,
  getContactOrganisationIdsForMatter,
  getFirstOrganisationInList,
  getContactName,
  getContactSalutation,
} from '@sb-customer-management/business-logic/contacts/services';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import composeHooks from '@sb-itops/react-hooks-compose';
import { fetchGetP } from '@sb-itops/redux/fetch';

import {
  DraftInvoiceExistingInvoice,
  DraftInvoicePreviewMatterData,
  InitOperatingBankAccount,
} from 'web/graphql/queries';
import { useCacheQuery, useSubscribedQuery } from 'web/hooks';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';

import { SplitBillingInvoicePreviewContainer } from './SplitBillingInvoicePreview.container';
import { InvoicePreviewContainer } from './InvoicePreview.container';

export const DRAFT_INVOICE_PREVIEW_MODAL_ID = 'draft-invoice-preview-modal';

const log = getLogger('DraftInvoicePreviewContainer');

// we can retire this method if we make buildContext.js conform with graphql schema
function extractFeesAndExpenses(invoice) {
  return (invoice.entries || []).reduce(
    (acc, entry) => {
      // We need to preserve order of ids to correctly order fee table entries which may include expense as fee
      acc.entryIds.push(entry.id);
      // send only fee/expense ids to server to maximise the number of entries allowed with draft invoice preview
      if (featureActive('BB-12895')) {
        if (entry.feeEntity) {
          acc.fees.push({ feeId: entry.id });
        }
        if (entry.expenseEntity) {
          acc.expenses.push({ expenseId: entry.id });
        }
        return acc;
      }

      // send all entry details to server if BB-12895 is off
      // below can be cleaned up as part of BB-12895
      if (entry.feeEntity) {
        acc.fees.push({
          feeId: entry.id,
          feeEarner: {
            firstName: entry.feeEntity.feeEarnerStaff?.firstName,
            lastName: entry.feeEntity.feeEarnerStaff?.lastName,
            initials: entry.feeEntity.feeEarnerStaff?.initials,
            firmRole: entry.feeEntity.feeEarnerStaff?.firmRole,
          },
          matterId: entry.feeEntity.matterId,
          notes: entry.feeEntity.notes,
          invoiceId: entry.feeEntity.invoiceId,
          feeDate: entry.feeEntity.feeDate,
          duration: entry.feeEntity.duration,
          feeType: entry.feeEntity.feeType,
          waived: entry.feeEntity.waived,
          tax: entry.feeEntity.tax,
          billableTax: entry.feeEntity.billableTax,
          amountIncludesTax: entry.feeEntity.amountIncludesTax,
          isTaxExempt: entry.feeEntity.isTaxExempt,
          source: entry.feeEntity.source,
          sourceItems: entry.feeEntity.sourceItems,
          utbmsActivityCode: entry.feeEntity.utbmsActivityCode,
          utbmsTaskCode: entry.feeEntity.utbmsTaskCode,
          description: entry.feeEntity.description,
          isBillable: entry.feeEntity.isBillable,
          rate: entry.feeEntity.rate,
          feeEarnerStaffId: entry.feeEntity.feeEarnerStaff?.id,
          amount: entry.feeEntity.amount,
        });
      }
      if (entry.expenseEntity) {
        acc.expenses.push({
          expenseId: entry.id,
          expenseEarner: {
            initials: entry.expenseEntity.expenseEarnerStaff?.initials,
          },
          isBillable: entry.expenseEntity.isBillable,
          expenseEarnerStaffId: entry.expenseEntity.expenseEarnerStaff?.id,
          matterId: entry.expenseEntity.matterId,
          description: entry.expenseEntity.description,
          notes: entry.expenseEntity.notes,
          utbmsActivityCode: entry.expenseEntity.utbmsActivityCode,
          utbmsTaskCode: entry.expenseEntity.utbmsTaskCode,
          invoiceId: entry.expenseEntity.invoiceId,
          expenseDate: entry.expenseEntity.expenseDate,
          quantity: entry.expenseEntity.quantity,
          price: entry.expenseEntity.price,
          waived: entry.expenseEntity.waived,
          source: entry.expenseEntity.source,
          tax: entry.expenseEntity.tax,
          amountIncludesTax: entry.expenseEntity.amountIncludesTax,
          attachmentFile: entry.expenseEntity.attachmentFile,
          amount: entry.expenseEntity.amount,
          isAnticipated: entry.expenseEntity.isAnticipated,
          expensePaymentDetails: {
            isPaid: entry.expenseEntity.expensePaymentDetails?.isPaid,
            isPayable: entry.expenseEntity.expensePaymentDetails?.isPayable,
          },
          displayWithFees: entry.expenseEntity.displayWithFees,
        });
      }
      return acc;
    },
    { fees: [], expenses: [], entryIds: [] },
  );
}

const hooks = () => ({
  useFetchEntryDetails: () => ({
    fetchEntryDetails: featureActive('BB-12895'),
  }),
  useFetchInvoicePreviewData: ({ invoiceId, draftInvoiceOverrides }) => {
    if (!invoiceId && !draftInvoiceOverrides) {
      throw new Error('One of invoiceId or draftInvoiceOverrides must be provided');
    }

    const { data, loading } = useSubscribedQuery(DraftInvoiceExistingInvoice, {
      variables: {
        invoiceId,
      },
    });

    const { data: operatingBankAccountData } = useCacheQuery(InitOperatingBankAccount.query);

    if (loading) {
      return {
        invoicePreviewData: null,
      };
    }

    const supportsDisplayExpenseWithFees = hasFacet(facets.displayExpenseWithFees) && featureActive('BB-14971');

    // Three scenarios
    // 1. Draft from ledger invoice: only invoiceId provided
    // 2. New Draft Invoice: not saved yet (no invoiceNumber) - this still has an invoiceId allocated
    // 3. Editing exiting Draft Invoice (has invoiceNumber)
    let invoicePreviewData;
    const isNewInvoice =
      draftInvoiceOverrides &&
      draftInvoiceOverrides.invoiceVersion &&
      !draftInvoiceOverrides.invoiceVersion.invoiceNumber;
    if (isNewInvoice) {
      const invoice = {
        ...draftInvoiceOverrides.invoiceVersion,
        status: invoiceStatusByName.DRAFT,
      };
      const { fees, expenses, entryIds } = extractFeesAndExpenses(invoice);
      if (featureActive('BB-12895')) {
        delete invoice.entries;
      }
      invoicePreviewData = {
        invoice,
        invoiceSettings: draftInvoiceOverrides.invoiceVersion.template.settings,
        invoiceTotals: draftInvoiceOverrides.invoiceTotals,
        quickPayments: draftInvoiceOverrides.quickPayments,
        operatingAccount: operatingBankAccountData?.bankAccounts?.[0],
        fees,
        expenses,
        entryIds: supportsDisplayExpenseWithFees ? entryIds : undefined,
      };
    } else if (data?.invoice) {
      const invoice = {
        ...data.invoice,
        ...draftInvoiceOverrides?.invoiceVersion,
        invoiceId: data.invoice.id,
        matterId: data.invoice.matter.id,
        status: invoiceStatusByName.DRAFT,
      };
      const { fees, expenses, entryIds } = extractFeesAndExpenses(invoice);
      if (featureActive('BB-12895')) {
        // most of the invoice version passed through is not used when previewing an invoice.
        // Not sending this means we can cater for draft invoice with much larger entries and
        // which wouldn’t hit the payload limit imposed on the server end, e.g. VPC Lambda proxy
        delete invoice.entries;
      }
      invoicePreviewData = {
        invoice,
        invoiceSettings: invoice.template.settings,
        invoiceTotals: draftInvoiceOverrides?.invoiceTotals,
        quickPayments: draftInvoiceOverrides?.quickPayments,
        operatingAccount: operatingBankAccountData?.bankAccounts?.[0],
        fees,
        expenses,
        entryIds: supportsDisplayExpenseWithFees ? entryIds : undefined,
      };
    } // else wait till data comes back

    return { invoicePreviewData };
  },
});

// we thought about moving some of the data fetching below to invoice-pdf endpoint which would reduce
// the complexity of data fetching on the frontend, unfortunately invoice-pdf is in the billing domain
// and would violate domain boundaries if we make it fetch customer-management data or make use of
// customer-management/business-logic. It should be noted that invoice-pdf endpoint is already violating
// domain boundaries indirectly by fetching from itops/graphql but we don't want to exacerbate this
// further for now until a decision is made on where things like invoice-pdf endpoints should go, e.g.
// should it go into the integration domain?
const dependentHooks = () => ({
  useFetchDebtorAndClientsWithAddress: ({ invoicePreviewData }) => {
    const [clients, setClients] = useState(undefined);
    const invoiceDebtorIds = invoicePreviewData?.invoice?.debtors.map((debtor) => debtor.id);

    const { data: draftInvoicePreviewMatterData } = useSubscribedQuery(DraftInvoicePreviewMatterData, {
      skip: !invoicePreviewData,
      variables: {
        matterId: invoicePreviewData?.invoice.matterId,
      },
    });

    const matterClientIds = draftInvoicePreviewMatterData?.matter?.clients?.map((client) => client.id);

    // I originally tried to get the clients data as part of DraftInvoicePreviewMatterData query but it wasn't straightforward
    // and would require rewriting related BL. The graphql Contact type is different than Contact entity and getInvoiceClients function
    // in this file uses several BL functions which expect the Contact entity.
    //
    // This is not an issue for invoice generation as that uses itops graphql-server which uses the Contact entity. The logic in
    // getInvoiceClients (this file) is implemented as a resover in itops/endpoints/graphql-server/billing/types.js:365 so I couldn't reuse it here.
    //
    // While it would be good to reimplement getInvoiceClients logic for integration graphql, it is way beyond the current scope of work.
    useEffect(() => {
      async function fetchClients() {
        try {
          const clientsWithAddressDetails = await fetchClientsP({
            invoiceDebtorIds,
            matterClientIds,
            isSplitBillingInvoice:
              featureActive('BB-9790') && invoicePreviewData.invoice.splitBillingSettings?.isEnabled,
          });
          setClients(clientsWithAddressDetails);
        } catch (error) {
          log.error(error);
        }
      }

      if (clients || !invoicePreviewData || !draftInvoicePreviewMatterData) {
        return;
      }
      // Only when we have all the Ids, we want to fetch the contacts

      if (Array.isArray(invoiceDebtorIds) && Array.isArray(matterClientIds)) {
        fetchClients();
      }
    }, [clients, invoicePreviewData, draftInvoicePreviewMatterData, invoiceDebtorIds, matterClientIds]);

    // Return invoicePreviewData only when we have all data ready
    if (!invoicePreviewData || !draftInvoicePreviewMatterData || !clients) {
      return { invoicePreviewData: undefined };
    }

    const invoicePreviewDataWithClientsAndAddressDetails = {
      ...invoicePreviewData,
      invoice: { ...invoicePreviewData.invoice, clients },
      addressesOverridden: !!draftInvoicePreviewMatterData?.matter?.matterInvoiceSettings?.addressesOverridden,
      overriddenDebtorAddresses:
        draftInvoicePreviewMatterData?.matter?.matterInvoiceSettings?.overriddenDebtorAddresses,
    };

    return {
      isSplitBilling:
        featureActive('BB-9790') &&
        invoicePreviewData.invoice.splitBillingSettings?.isEnabled &&
        clients &&
        clients.length > 1,
      invoicePreviewData: invoicePreviewDataWithClientsAndAddressDetails,
    };
  },
});

async function fetchClientsP({ invoiceDebtorIds, matterClientIds, isSplitBillingInvoice }) {
  // deduplicate array so we don't fetch the same contactId multiple times
  const contactIds = [...new Set([...invoiceDebtorIds, ...matterClientIds])];

  const debtorAndMatterClientContacts = [];

  await Promise.all(
    contactIds.map(async (debtorId) => {
      const contact = await fetchGetP({ path: `/customer-management/contact/:accountId/${debtorId}` });

      if (contact?.body?.payload) {
        debtorAndMatterClientContacts.push(contact.body.payload);
      }
    }),
  ).catch((error) => {
    log.error('Failed to fetch some contacts', error);
  });

  const invoiceClients = buildInvoiceClientsWithAddressDetails(
    invoiceDebtorIds,
    matterClientIds,
    debtorAndMatterClientContacts,
    isSplitBillingInvoice,
  );
  return invoiceClients;
}

// we thought about moving this to some business-logic folder, unfortunately we don't have a good place to put it
// as it aggegrates data from customer-management and billing domains.
function buildInvoiceClientsWithAddressDetails(
  invoiceDebtorIds,
  matterClientIds,
  debtorAndMatterClientContacts,
  isSplitBillingInvoice,
) {
  const clients =
    invoiceDebtorIds &&
    invoiceDebtorIds.map((debtorId) => {
      const debtorContact = debtorAndMatterClientContacts.find((contact) => contact.id === debtorId);
      const debtorOrganisationIdsForMatter = getContactOrganisationIdsForMatter(debtorContact, matterClientIds);
      const debtorOrganisationsForMatter = debtorOrganisationIdsForMatter.reduce((acc, organisationId) => {
        const organisation = debtorAndMatterClientContacts.find((contact) => contact.id === organisationId);
        if (organisation) {
          acc.push(organisation);
        }
        return acc;
      }, []);
      const firstDebtorOrganisationForMatter = getFirstOrganisationInList(debtorOrganisationsForMatter);

      const client = {
        contactId: debtorId,
        name: getContactName(debtorContact),
        address: getContactMailingAddress(firstDebtorOrganisationForMatter || debtorContact) || {},
        salutation: getContactSalutation(debtorContact),
      };

      if (isSplitBillingInvoice && debtorContact && debtorContact.person) {
        // Show person type debtor as [Last Name] [Suffix], [First Name] [Middle Name] in debtor selector dropdown for split billing invoice
        const { firstName, middleName, lastName, nameSuffix } = debtorContact.person;
        client.debtorLabel = [
          [lastName, nameSuffix].filter(Boolean).join(' '),
          [firstName, middleName].filter(Boolean).join(' '),
        ]
          .filter(Boolean)
          .join(', ');
      } else {
        // Show name used on pdf for other type contact in debtor selector dropdown
        client.debtorLabel = client.name;
      }

      return client;
    });

  return clients;
}

export const DraftInvoicePreviewContainer = withApolloClient(
  composeHooks(hooks)(
    composeHooks(dependentHooks)((props) =>
      props.isSplitBilling ? (
        <SplitBillingInvoicePreviewContainer {...props} />
      ) : (
        <InvoicePreviewContainer {...props} />
      ),
    ),
  ),
);

DraftInvoicePreviewContainer.displayName = 'DraftInvoicePreviewContainer';

DraftInvoicePreviewContainer.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  draftInvoiceOverrides: PropTypes.shape({
    invoiceVersion: PropTypes.object,
    invoiceTotals: PropTypes.object,
    quickPayments: PropTypes.shape({
      operating: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        source: PropTypes.string.isRequired,
        sourceAccountType: PropTypes.string.isRequired,
        sourceAccountId: PropTypes.string,
        matterId: PropTypes.string.isRequired,
      }),
      trust: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        source: PropTypes.string.isRequired,
        sourceAccountType: PropTypes.string.isRequired,
        matterId: PropTypes.string.isRequired,
      }),
      credit: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        source: PropTypes.string.isRequired,
        sourceAccountType: PropTypes.string.isRequired,
        sourceAccountId: PropTypes.string,
        matterId: PropTypes.string.isRequired,
      }),
    }),
  }),
};

DraftInvoicePreviewContainer.defaultProps = {
  draftInvoiceOverrides: undefined,
};
