'use strict';

const transactionType = Object.freeze({
  Deposit: 'Deposit',
  DepositReversal: 'DepositReversal',
  InvoicePayment: 'InvoicePayment',
  InvoicePaymentReversal: 'InvoicePaymentReversal',
  MatterAdjustment: 'MatterAdjustment',
  MatterAdjustmentReversal: 'MatterAdjustmentReversal',
  Transfer: 'Transfer',
  VendorPayment: 'VendorPayment',
  VendorPaymentReversal: 'VendorPaymentReversal',
  BankFees: 'BankFees',
  BankFeesReversal: 'BankFeesReversal',
  WithholdingTax: 'WithholdingTax',
  WithholdingTaxReversal: 'WithholdingTaxReversal',
  Interest: 'Interest',
  InterestReversal: 'InterestReversal',
});

// Any additional regexes added must be added to the ANY regex for interpolated description display to work
const interpolatedDescriptionRegexes = {
  INVOICE_ID: /#invoiceId:([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/g,
  BANK_ACCOUNT_ID: /accountId:([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\/?(?:Trust|Operating)?)/g,
  MATTER_ID: /matterId:([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/g,
  CONTACT_ID: /contactId:([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/g,
  ANY: /#invoiceId:([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})|accountId:([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\/?(?:Trust|Operating)?)|contactId:([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})|matterId:([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/g,
};

module.exports = { transactionType, interpolatedDescriptionRegexes };
