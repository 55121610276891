
import * as LDClient from 'launchdarkly-js-client-sdk';

const noop = () => {};

let ldClient;

export const initialiseP = ({clientSideId, context, onFeatureUpdates = noop}) => {
  return new Promise((resolve) => {
    // Protect against multiple initialisations.
    if (ldClient) {
      resolve();
    }

    ldClient = LDClient.initialize(clientSideId, context, {
      bootstrap: 'localStorage',
    });

    ldClient.on('ready', () => {
      // Subscribe to any changes to feature switch states in launch darkly.
      ldClient.on('change', onFeatureUpdates);

      // Apply the initial feature switch states that we received from launch darkly.
      resolve(ldClient.allFlags());
    });
  });
};

export const refetchFlags = (context) => {
  if (!ldClient) {
    return;
  }
  return ldClient.identify(context, null);
}

export const getAllFlags = () => {
  return ldClient.allFlags();
}

export const uninitialise = () => {
  ldClient.close();
  ldClient = null;
}
