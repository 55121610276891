import * as React from 'react';
import PropTypes from 'prop-types';

import { DebugEntity } from 'web/react-redux/components/debug-entity';

export const InvoiceStatementDebugEntities = ({ invoiceStatementId, debtor, invoices }) => (
  <>
    <DebugEntity entityCacheName="sbInvoiceStatementService" entityId={invoiceStatementId} />
    <DebugEntity
      entityCacheName="sbContactsMbService"
      entityId={debtor?.id}
      groupEntityCacheName="sbInvoiceStatementService"
      groupEntityId={invoiceStatementId}
    />
    {invoices.map((invoice) => (
      <DebugEntity
        entityCacheName="sbInvoicingService"
        entityId={invoice.id}
        groupEntityCacheName="sbInvoiceStatementService"
        groupEntityId={invoiceStatementId}
      />
    ))}
  </>
);

InvoiceStatementDebugEntities.propTypes = {
  invoiceStatementId: PropTypes.string,
  debtor: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
};

InvoiceStatementDebugEntities.defaultProps = {
  invoiceStatementId: undefined,
  debtor: undefined,
  invoices: [],
};
