import * as React from 'react';
import PropTypes from 'prop-types';

import { DebugEntity } from 'web/react-redux/components/debug-entity';

export const MatterDebugEntities = ({ matterId, matterTypeId }) => (
  <>
    <DebugEntity entityCacheName="sbMattersMbService" entityId={matterId} />
    <DebugEntity
      entityCacheName="sbBillingConfigurationService"
      entityId={matterId}
      groupEntityCacheName="sbMattersMbService"
      groupEntityId={matterId}
    />
    <DebugEntity
      entityCacheName="sbInterestSettingsService"
      entityId={matterId}
      groupEntityCacheName="sbMattersMbService"
      groupEntityId={matterId}
    />
    <DebugEntity
      entityCacheName="sbMatterHourlyRateService"
      entityId={matterId}
      groupEntityCacheName="sbMattersMbService"
      groupEntityId={matterId}
    />
    <DebugEntity
      entityCacheName="sbMatterInvoiceSettingsService"
      entityId={matterId}
      groupEntityCacheName="sbMattersMbService"
      groupEntityId={matterId}
    />
    <DebugEntity
      entityCacheName="sbMatterTypeConfigurationsService"
      entityId={matterTypeId}
      groupEntityCacheName="sbMattersMbService"
      groupEntityId={matterId}
    />
    <DebugEntity
      entityCacheName="sbMatterTypesMbService"
      entityId={matterTypeId}
      groupEntityCacheName="sbMattersMbService"
      groupEntityId={matterId}
    />
  </>
);

MatterDebugEntities.propTypes = {
  matterId: PropTypes.string.isRequired,
  matterTypeId: PropTypes.string.isRequired,
};
