import PropTypes from 'prop-types';
import { useState } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import { getContactDisplay as getContactDisplayFromContact } from '@sb-customer-management/business-logic/contacts/services';
import { sort } from '@sb-itops/sort';

import TrustChequePaymentsTable from './TrustChequePaymentsTable';

const hooks = ({ trustChequePayments }) => ({
  useTrustChequePaymentsTable: () => {
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortBy, setSortBy] = useState('invoiceSort');

    const onSort = (sortProps) => {
      setSortDirection(sortProps.sortDirection);
      setSortBy(sortProps.sortBy);
    };

    const { paymentsList, summary } = trustChequePayments.reduce(
      (acc, trustChequePayment) => {
        const { transaction, payment } = trustChequePayment;
        if (!payment || !payment.invoices.length > 0) {
          return acc;
        }

        payment.invoices.forEach((invoice) => {
          acc.summary.amount += invoice?.amount || 0;

          acc.paymentsList.push({
            amount: invoice?.amount,
            matter: transaction?.matter,
            matterSort: getMatterDisplay(transaction?.matter, transaction?.matter?.matterType?.name),
            invoice: invoice?.invoice,
            invoiceSort: invoice?.invoice?.invoiceNumber,
            payor: payment?.payor,
            payorSort: getContactDisplayFromContact(payment?.payor, { showLastNameFirst: true }),
          });
        });

        return acc;
      },
      {
        paymentsList: [],
        summary: { amount: 0 },
      },
    );

    return {
      payments: sort(paymentsList, [sortBy], [sortDirection]),
      summary,
      sortDirection,
      sortBy,
      onSort,
    };
  },
});

const TrustChequePaymentsTableContainer = withReduxProvider(composeHooks(hooks)(TrustChequePaymentsTable));

TrustChequePaymentsTableContainer.propTypes = {
  payments: PropTypes.array,
  onClickLink: PropTypes.func,
};

TrustChequePaymentsTableContainer.defaultProps = {
  payments: [],
  onClickLink: () => {},
};

TrustChequePaymentsTableContainer.displayName = 'TrustChequePaymentsTableContainer';

export default TrustChequePaymentsTableContainer;
