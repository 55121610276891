import React from 'react';
import PropTypes from 'prop-types';
import { Info } from 'web/components/icons';
import Style from './PageBannerXero.module.scss';

export const PageBannerXero = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <div className={Style.pageBanner}>
      <div className={Style.bannerContainer}>
        <Info className={Style.infoIcon} />
        <div className={Style.bannerText}>{message}</div>
      </div>
    </div>
  );
};

PageBannerXero.propTypes = {
  message: PropTypes.any,
};

PageBannerXero.defaultProps = {
  message: null,
};

PageBannerXero.displayName = 'PageBannerXero';
