'use strict';

import { getAccountId } from 'web/services/user-session-management';
import { featureActive } from '@sb-itops/feature';
import { integrations } from '@sb-billing/business-logic/integration-history/entities';
import xeroLogo from '../../assets/integration-xero.png'

angular.module('sb.billing.webapp').component('sbXeroLogin', {
  templateUrl: 'ng-components/xero/xero-login.html',
  bindings: { 
    isConnected: '<',
    onLoginFailure: '<?',
    onLoginStarted: '<?',
    onLoginSuccess: '<?',
    disconnectXero: '<?'
  },
  controller: function ($scope, sbLoggerService, sbEnvironmentConfigService, sbMessageDisplayService, sbNotifiedOperationP) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbXeroLogin');

    ctrl.integration = integrations.XERO;
    ctrl.logoSrc = xeroLogo;
    ctrl.onConnect = onConnect;
    ctrl.onConnectClick = onConnectClick;
    ctrl.onReconnectClick = onReconnectClick;
    ctrl.xeroUrl = '';
    ctrl.showConfirmationModal = () => {}; // noop, is overwritten by modal

    ctrl.$postLink = () => {
      const accountId = getAccountId();
      const smokeballDomain = sbEnvironmentConfigService.getDotNetHostUrl();
      ctrl.xeroUrl = `${smokeballDomain}/integration/Xero/XeroHome?accountId=${accountId}`;
    };

    function onConnectClick() {
      if (!featureActive('BB-13236')) {
        window.open(ctrl.xeroUrl, '_blank', 'noopener,noreferrer');
        onConnect();
        return;
      }

      ctrl.showConfirmationModal().then(() => {
        // This is resolved only when primary button (confirm) is clicked
        window.open(ctrl.xeroUrl, '_blank', 'noopener,noreferrer');
        onConnect();
      });
    }

    async function onReconnectClick() {
      try {
        await ctrl.disconnectXero()
        onConnectClick();
      } catch (err) {
        log.info('Failed to reconnect from Xero');
      }
    }

    async function onConnect() {
      try {
        ctrl.onLoginStarted();
        await waitForAuthedNotificationP();
        ctrl.onLoginSuccess();
      } catch (err) {
        log.error('Xero authorisation failed', err);
        ctrl.onLoginFailure();
      }
    }

    async function waitForAuthedNotificationP() {
      await sbNotifiedOperationP(() => Promise.resolve(), {
        completionNotification: 'XeroIntegration',
        completionFilterFn: message => message.messageId === 'XeroAccessTokenUpdated',
        timeoutMs: 60000,
      });
    }
  }
});
