'use strict';

/**
 * A LOD-compatible rewrite of the isReconciled function on sbTransactionService
 * @param {object} params.transaction LOD transaction object with reconciliation object already fetched
 * @returns boolean
 */
const isTransactionReconciled = ({ transaction }) => {
  if (!transaction || !('reconciliationId' in transaction)) {
    throw new Error('Transaction with reconciliationId is missing, have you fetched this from your query?');
  }

  // For transactions on bank rec setups, reconciliationId is set to initial_reconciliation
  // If we got reconciliationId and reconciliation is null, assume it be from bank rec setup
  if (
    (transaction.reconciliation && transaction.reconciliation.status === 1) ||
    (!!transaction.reconciliationId && transaction.reconciliation === null)
  ) {
    return true;
  }

  return false;
};

module.exports = {
  isTransactionReconciled,
};
