import {
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/invoice-debtor-totals';

angular
  .module('@sb-billing/services')
  .service('sbInvoiceDebtorTotalsService', function(
    sbGenericCacheService,
    sbEndpointType,
  ) {
    const invoiceDebtorTotalsEndpoint = `billing/invoice-debtor-totals`;

    sbGenericCacheService.setupCache({
      name: 'sbInvoiceDebtorTotalsService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_SINCE, stub: invoiceDebtorTotalsEndpoint },
        poll: 60,
        subscriptions: 'notifier.BillingMattersNotifications.BillingInvoiceTotalsUpdated'
      },
      updateRedux,
      clearRedux,
    });
  });
