/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Menu, MenuItem } from '@sb-itops/react';
import { ChevronDown, ChevronUp, Lock } from 'web/components/icons';
import archieSparkles from 'web/assets/sparkles.png';
import Styles from './PageNav.module.scss';

// Gap hardcoded in style to prevent CSS variable changes causing bugs in the width calculation
const GAP = 2;

const NavItem = ({ navCurrentPath, item, onClickItem, hidden, order, className }) => (
  <li
    style={{ order }}
    className={classnames(
      item.className,
      className,
      hidden && Styles.hidden,
      item.disabled && 'disabled',
      navCurrentPath.includes(item.active || item.path) && 'active',
      item.lock && Styles.lockBackground,
    )}
  >
    <button type="button" onClick={() => (item.disabled ? {} : onClickItem(item))} title={item.tooltip}>
      {!!item.prefix && <span className={item.prefix.className}>{item.prefix.display}</span>}
      {item.display}
      {item.display === 'Archie' && <img className={Styles.archieSparkles} src={archieSparkles} alt="" />}
      {item.lock && (
        <div className={Styles.lock}>
          <Lock />
        </div>
      )}
    </button>
  </li>
);

export const PageNav = ({ navCurrentPath, navItems, onGotoOrReset }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [maxItems, setMaxItems] = useState(null);
  const navListRef = useRef(null);

  const observer = useMemo(() => {
    const resizeFn = () => {
      let newMaxItemsCount = 0;
      if (navListRef.current) {
        // Account for gap on either side of the container
        let cumulativeWidth = GAP * 2;
        const containerWidth = navListRef.current.getBoundingClientRect().width;
        const viewMoreWidth =
          navListRef.current.children[navListRef.current.children.length - 1].getBoundingClientRect().width;
        const container = navListRef.current.children;
        for (let i = 0; i < container.length - 1; i += 1) {
          const itemWidth = container[i].getBoundingClientRect().width;
          cumulativeWidth += itemWidth + GAP;

          // No need to keep looping if this item is overflowing the container
          if (cumulativeWidth > containerWidth) {
            break;
          }
          // Including the view more button, will this item fit in the container?
          if (cumulativeWidth <= containerWidth - viewMoreWidth) {
            newMaxItemsCount = i + 2;
          }
          // Excluding the view more button, will all items fit in the container?
          if (cumulativeWidth <= containerWidth && i === container.length - 2) {
            newMaxItemsCount = 99;
          }
        }
      }
      if (newMaxItemsCount) {
        setMaxItems(Math.max(newMaxItemsCount, 2));
      } else {
        setMaxItems(99);
      }
    };
    resizeFn();
    return new ResizeObserver(resizeFn);
  }, []);

  const assignRef = useCallback(
    (node) => {
      if (navListRef.current) {
        observer.unobserve(navListRef.current);
      }
      if (node) {
        navListRef.current = node;
        observer.observe(node);
      }
    },
    [observer],
  );

  const onClickItem = (item) => {
    if (item.disabled) {
      return;
    }

    onGotoOrReset(item.path);
    setAnchorEl(null);
  };

  if (!navItems?.length) {
    return null;
  }

  const navList = navItems;
  let navMoreList = [];
  if (maxItems > 1 && navItems.length > maxItems) {
    navMoreList = navItems.slice(maxItems - 1);
  }

  // Minimal use of CSS modules here so that the tabs would match other angular
  // tabs in the webapp. Once we decide to move the tabs from all pages from
  // angular to react we can move the CSS into a module here.
  return (
    <div className={Styles.container}>
      <ul className="nav nav-pills" style={{ gap: `0 ${GAP}px` }} ref={assignRef}>
        {navList.map((item, i) => (
          <NavItem
            hidden={i >= maxItems - 1}
            order={i}
            className={Styles.item}
            key={item.path}
            navCurrentPath={navCurrentPath}
            item={item}
            onClickItem={onClickItem}
          />
        ))}
        <li
          style={{ order: maxItems - 2 }}
          className={classnames(Styles.subMenuContainer, maxItems > navItems?.length && Styles.hidden)}
        >
          <button
            id="view-more-button"
            onClick={(event) => setAnchorEl(anchorEl ? null : event.currentTarget.parentElement)}
            type="button"
            className={Styles.viewMoreButton}
          >
            View More {anchorEl ? <ChevronUp /> : <ChevronDown />}
          </button>

          <Menu
            id="view-more-button"
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            marginThreshold={5}
          >
            {navMoreList.map((item) => (
              <MenuItem
                key={item.path}
                onClick={() => onClickItem(item)}
                className={classnames(
                  Styles.subMenuItem,
                  item.className,
                  item.disabled && 'disabled',
                  item.lock && Styles.lockBackground,
                )}
                tooltip={item.tooltip}
                active={item.path === navCurrentPath}
              >
                {!!item.prefix && <span className={item.prefix.className}>{item.prefix.display}</span>}
                {item.display}
                {item.lock && (
                  <div className={Styles.lock}>
                    <Lock />
                  </div>
                )}
              </MenuItem>
            ))}
          </Menu>
        </li>
      </ul>
    </div>
  );
};

PageNav.displayName = 'PageNav';

PageNav.propTypes = {
  navCurrentPath: PropTypes.string.isRequired,
  navItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  onGotoOrReset: PropTypes.func.isRequired,
};

PageNav.defaultProps = {};
