import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getById as getInvoiceById } from '@sb-billing/redux/invoices';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { FinalisedSplitBillingInvoiceHeader } from './FinalisedSplitBillingInvoiceHeader';

const hooks = ({ debtorId, invoiceId, onClickLink }) => ({
  useInvoiceData: ({ billingMode }) => {
    const invoice = getInvoiceById(invoiceId);
    const isNewBillingMode = billingMode === 'new';

    const splitBillingSettingsDebtors = invoice?.currentVersion?.splitBillingSettings?.debtors;
    const invoiceNumberSuffix = splitBillingSettingsDebtors?.find((d) => d.debtorId === debtorId)?.invoiceNumberSuffix;
    const invoiceNumber = invoice?.currentVersion?.invoiceNumber;

    const matterId = invoice?.matterId;

    return {
      isNewBillingMode,
      invoiceNumber,
      invoiceNumberSuffix,
      matterId,
      onClickLink,
    };
  },
  useDebtorDropdown: ({ bankAccountId, checkId }) => {
    const invoice = getInvoiceById(invoiceId);
    const invoiceDebtorIds = invoice?.currentVersion?.debtors?.map((d) => d.id);

    const debtorOptions = (invoiceDebtorIds || []).map((id) => ({
      label: getContactDisplay(id, { showLastNameFirstFull: true }) || 'Not found',
      value: id,
    }));

    const onChangeDebtor = (debtor) => {
      onClickLink({ type: 'invoice', id: invoiceId, params: [checkId, bankAccountId, debtor.value] });
    };

    return {
      debtorId,
      debtorOptions,
      onChangeDebtor,
    };
  },
});

export const FinalisedSplitBillingInvoiceHeaderContainer = composeHooks(hooks)(FinalisedSplitBillingInvoiceHeader);

FinalisedSplitBillingInvoiceHeaderContainer.propTypes = {
  bankAccountId: PropTypes.string,
  billingMode: PropTypes.string.isRequired,
  checkId: PropTypes.string,
  debtorId: PropTypes.string.isRequired,
  invoiceId: PropTypes.string.isRequired,
  onClickLink: PropTypes.func.isRequired,
};

FinalisedSplitBillingInvoiceHeaderContainer.defaultProps = {
  bankAccountId: undefined,
  checkId: undefined,
};

FinalisedSplitBillingInvoiceHeaderContainer.displayName = 'FinalisedSplitBillingInvoiceHeaderContainer';
