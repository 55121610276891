'use strict';

const { integrations } = require('./integrations');

const failedRequestTarget = Object.freeze({
  // UNKNOWN: 0, // defined by .NET but we won't really use it
  [integrations.MYOB]: 1,
  [integrations.XERO]: 2,
});

module.exports = {
  failedRequestTarget,
};
