import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Modal, useTranslation } from '@sb-itops/react';
import { sendStatuses } from '@sb-billing/business-logic/correspondence-history';

import Styles from './CorrespondenceHistoryModal.module.scss';

export const CorrespondenceHistoryModal = ({
  correspondenceHistory,
  isLoading,
  showModal,
  // Callbacks
  onModalClose,
}) => {
  const { t } = useTranslation();
  const {
    bcc,
    body: emailBody,
    cc,
    from,
    internalNote,
    lastUpdated,
    sentTimestamp,
    status,
    to,
    user,
  } = correspondenceHistory ?? {};
  const timestamp = sentTimestamp || lastUpdated; // if sentTimestamp exists we use it, otherwise use lastUpdated
  const sentBy = user?.person?.name;

  return (
    <Modal
      isVisible={showModal}
      isLoading={isLoading}
      title="Client Communication"
      modalBodyClassName={Styles.correspondenceHistoryModal}
      body={
        <>
          <div className={Styles.row}>
            {/* Status */}
            {Number.isInteger(status) && (
              <div className={Styles.fieldContainer}>
                <label className={Styles.label}>Status:</label>
                {status === 0 && 'In progress'}
                {status === 1 && 'Success'}
                {status === 2 && 'Fail'}
              </div>
            )}
            {/* Date */}
            {timestamp && (
              <div className={Styles.fieldContainer}>
                <label className={Styles.label}>Date:</label>
                {t('date', {
                  yyyymmdd: timestamp,
                })}
              </div>
            )}
          </div>
          {to && (
            <div className={Styles.row}>
              {/* To */}
              <div className={Styles.fieldContainer}>
                <label className={Styles.label}>To:</label>
                {to}
              </div>
            </div>
          )}
          {(cc || bcc) && (
            <div className={Styles.row}>
              {/* CC */}
              {cc && (
                <div className={Styles.fieldContainer}>
                  <label className={Styles.label}>CC:</label>
                  {cc}
                </div>
              )}
              {/* BCC */}
              {bcc && (
                <div className={Styles.fieldContainer}>
                  <label className={Styles.label}>BCC:</label>
                  {bcc}
                </div>
              )}
            </div>
          )}
          {(from || sentBy) && (
            <div className={Styles.row}>
              {/* From */}
              {from && (
                <div className={Styles.fieldContainer}>
                  <label className={Styles.label}>From:</label>
                  {from}
                </div>
              )}
              {/* Sent by */}
              {sentBy && (
                <div className={Styles.fieldContainer}>
                  <label className={Styles.label}>Sent by:</label>
                  {sentBy}
                </div>
              )}
            </div>
          )}
          {emailBody && (
            <div className={Styles.row}>
              {/* Email body */}
              <div className={classNames(Styles.fieldContainer, Styles.columnDirection)}>
                <label className={Styles.label}>Body of email:</label>
                {/* eslint-disable-next-line react/no-danger */}
                <div className={Styles.emailBody} dangerouslySetInnerHTML={{ __html: emailBody }} />
              </div>
            </div>
          )}
          {internalNote && (
            <div className={Styles.row}>
              <div className={classNames(Styles.fieldContainer, Styles.columnDirection)}>
                {/* Internal note */}
                <label className={Styles.label}>Notes:</label>
                <div className={Styles.internalNote}>{internalNote}</div>
              </div>
            </div>
          )}
        </>
      }
      showFooter={false}
      onModalClose={onModalClose}
    />
  );
};

CorrespondenceHistoryModal.displayName = 'CorrespondenceHistoryModal';

CorrespondenceHistoryModal.propTypes = {
  correspondenceHistory: PropTypes.shape({
    id: PropTypes.string,
    bcc: PropTypes.string,
    body: PropTypes.string,
    cc: PropTypes.string,
    from: PropTypes.string,
    internalNote: PropTypes.string,
    lastUpdated: PropTypes.string,
    relatedItems: PropTypes.arrayOf(PropTypes.object),
    sentTimestamp: PropTypes.string,
    status: PropTypes.oneOf(Object.values(sendStatuses)),
    to: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      person: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  }),
  isLoading: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  // Callbacks
  onModalClose: PropTypes.func.isRequired,
};

CorrespondenceHistoryModal.defaultProps = {
  correspondenceHistory: undefined,
};
