'use strict';

/**
 * A default is chosen using the following rules in order of preference for a split billing invoice:
 *
 * If money is available in one of the accounts for the specified debtor
 *  * We'll apply the balance in order of preference as per preferredBankAccountTypes from Finalize with Payment settings
 *  * When a contact balance is applied automatically, paid by should be locked and should = contact on type (same as now)
 *  * Never pre-select total combined for any account, but user is able to manually select this.
 * If no money is on account
 *  * default to check for US and bank transfer for AU, UK (this can be achieved by setting the isDefault flag in the relevant option)
 */
const getDefaultPaymentSourceForSplitBillingInvoice = ({
  paymentSourceOptions,
  preferredBankAccountTypes,
  debtorId,
  isMatterContactBalanceFirm,
}) => {
  let defaultOptionFound;

  if (preferredBankAccountTypes && preferredBankAccountTypes.length > 0) {
    for (let i = 0; !defaultOptionFound && i < preferredBankAccountTypes.length; i += 1) {
      defaultOptionFound = getDefaultOption(
        paymentSourceOptions,
        preferredBankAccountTypes[i],
        debtorId,
        isMatterContactBalanceFirm,
      );
    }
  }

  if (!defaultOptionFound) {
    defaultOptionFound = paymentSourceOptions.find((option) => option.isDefault);
  }

  return defaultOptionFound;
};

const getDefaultOption = (options, preferredBankAccountType, debtorId, isMatterContactBalanceFirm) => {
  let opt;
  if (isMatterContactBalanceFirm) {
    opt = options.find(
      (option) =>
        option.paymentType.toUpperCase() === preferredBankAccountType.toUpperCase() &&
        !option.isCombinedBalance &&
        option.contactId === debtorId,
    );
  } else {
    // never pre-select total combined for any account, user is able to manually select these
    opt = options.find(
      (option) => option.paymentType.toUpperCase() === preferredBankAccountType.toUpperCase() && option.balance > 0,
    );
  }

  return opt;
};

module.exports = {
  getDefaultPaymentSourceForSplitBillingInvoice,
};
