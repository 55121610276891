import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  Button,
  buttonTypes,
  CurrencyDisplay,
  CurrencyInput2,
  Icon,
  LoadingBarInfinite,
  SlidingToggle,
  useTranslation,
  TimeConversionInput,
  DurationTypePicker,
} from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';
import { DurationPicker, displayModes } from '@sb-itops/react/duration-picker/DurationPicker';
import { ActivityDropdown, CheckboxListPopup, TaskDropdown } from '@sb-billing/react';
import { regionType } from '@sb-itops/region';
import {
  durationType as durationTypeEnum,
  entryType as entryTypeEnum,
} from '@sb-billing/business-logic/shared/entities';
import { ExpandCollapseButton } from '@sb-billing/react/expand-collapse-button';
import {
  activityCategoryLabels,
  activityCategories,
  taskCategoryLabels,
} from '@sb-billing/business-logic/activities/entities/constants';
import { DebugEntity } from 'web/react-redux/components/debug-entity';

import { FeeSourceItemsEntries } from '../fee-source-items-entries';

import Styles from './FeePopOutEditor.module.scss';

export const ExpandedFeePopOutEditor = ({
  formInitialised,
  loading,
  // Editor header
  heading,
  // Editor body
  activities,
  autoTimeSummary,
  descriptionFieldMaxLength,
  durationFieldInputDisabled,
  durationFieldTypeDisabled,
  formData,
  formDataMappedObjects,
  formErrors,
  hasDurationTypeChanged,
  invoiceNumber,
  isAutoTimeFee,
  isFormDirty,
  isFormDisabled,
  matterBillableMinutes,
  onDeleteFee,
  onDurationBilledFieldBlur,
  onDurationWorkedFieldBlur,
  onHandleTaxOptionsBlur,
  onShowTaxOptionsToggle,
  onUpdateActivityField,
  onUpdateBillableField,
  onUpdateDurationFieldType,
  onUpdateDurationBilledFieldValue,
  onUpdateDurationWorkedFieldValue,
  onUpdateField,
  onUpdateSourceItemsField,
  onUpdateTaskField,
  onUpdateTaxField,
  region,
  setHasDurationTypeChanged,
  showTaskField,
  showTaxField,
  showTaxOptions,
  sourceItemsInitialValue,
  taskFieldEnabled,
  tasks,
  taxOptions,
  writeOffFieldEnabled,
  // Editor footer
  feeIndex,
  feeListCount,
  hideFormActionButtons,
  isOnDraftInvoice,
  isOnFinalisedInvoice,
  onCollapseFeePopOutEditor,
  onFormCancel,
  onFormSubmit,
  onNavigateToInvoice,
  onNavigateToNextFee,
  onNavigateToPreviousFee,
}) => {
  const {
    amountExclTaxInCents,
    description,
    durationBilled,
    durationWorked,
    durationType,
    isBillable,
    isWriteOff,
    rateInCents,
    sourceItems,
    subject,
    taxAmountInCents,
    timeBilledInHoursAndMinutes,
    timeWorkedInHoursAndMinutes,
  } = formData;
  const { activity, task } = formDataMappedObjects;
  const { t } = useTranslation();

  if (loading || !formInitialised) {
    return (
      <div className={Styles.expandedFeePopOutEditorLoadingContainer}>
        <LoadingBarInfinite loading={loading} />
      </div>
    );
  }

  return (
    <div className={Styles.expandedFeePopOutEditor}>
      <DebugEntity entityCacheName="sbFeeService" entityId={formData.id} />
      {/* Editor header */}
      <div className={Styles.editorHeader}>
        <div className={Styles.headingContainer}>
          <h3>
            {heading}
            {isFormDirty && '*'}
          </h3>
        </div>
      </div>
      {/* Editor body */}
      <div className={Styles.editorBody}>
        <form>
          <fieldset className={Styles.fieldSet} disabled={isFormDisabled}>
            {/* Subject */}
            <div className={Styles.row}>
              <div
                className={classNames(
                  Styles.subjectFieldContainer,
                  formErrors?.subject?.isInvalid && Styles.invalidSubjectField,
                )}
              >
                <input
                  type="text"
                  className="form-control"
                  value={subject}
                  onChange={(e) =>
                    onUpdateField({
                      field: 'subject',
                      newValue: e.target.value,
                    })
                  }
                  disabled={isFormDisabled}
                />
              </div>
            </div>
            {!isAutoTimeFee && (
              <div className={Styles.row}>
                <div className={Styles.activityAndTaskFieldsGroupContainer}>
                  {/* Activity */}
                  <div className={Styles.activityFieldContainer}>
                    <label>Activity</label>
                    <ActivityDropdown
                      activities={activities}
                      disabled={isFormDisabled}
                      hideSelectedOptionDescription
                      noDefaultStyling
                      groupLabelHeight={26}
                      optionLabelHeight={26}
                      placeholder=""
                      selectedActivityCode={activity?.code}
                      onSelectionChange={(selectedActivity) => onUpdateActivityField({ selectedActivity })}
                      selectClassName={Styles.selectContainerStyling}
                    />
                  </div>
                  {/* Task */}
                  <div className={Styles.taskFieldContainer}>
                    {showTaskField && (
                      <>
                        <label>Task</label>
                        <TaskDropdown
                          disabled={!taskFieldEnabled || isFormDisabled}
                          hasError={formErrors?.taskId?.isInvalid}
                          onSelectionChange={(selectedTask) => onUpdateTaskField({ selectedTask })}
                          optionLabelHeight={26}
                          groupLabelHeight={26}
                          placeholder=""
                          selectedTaskCode={task?.code}
                          tasks={tasks}
                          selectClassName={Styles.selectContainerStyling}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            {/* Description */}
            <div className={Styles.row}>
              {isAutoTimeFee ? (
                <div className={Styles.autoTimeDescriptionFieldContainer}>
                  <FeeSourceItemsEntries
                    hasDurationTypeChanged={hasDurationTypeChanged}
                    setHasDurationTypeChanged={setHasDurationTypeChanged}
                    durationType={durationType}
                    feeDurationBilled={durationBilled}
                    feeDurationWorked={durationWorked}
                    initialValue={sourceItemsInitialValue}
                    interval={matterBillableMinutes}
                    isDisabled={isFormDisabled}
                    sourceItems={sourceItems}
                    onDeleteFee={onDeleteFee}
                    onSourceItemsChange={onUpdateSourceItemsField}
                  />
                  <div className={Styles.autoTimeSummary}>
                    <Icon type="information" className={Styles.infoIcon} />
                    <p>{autoTimeSummary}</p>
                  </div>
                </div>
              ) : (
                <div className={Styles.descriptionFieldContainer}>
                  <label>Description</label>
                  <textarea
                    className="field-content form-control"
                    value={description || ''}
                    onChange={(e) => onUpdateField({ field: 'description', newValue: e.target.value })}
                    disabled={isFormDisabled}
                    maxLength={descriptionFieldMaxLength}
                    rows={5}
                  />
                </div>
              )}
            </div>
            <div className={Styles.row}>
              <div className={Styles.durationTimeAndRateFieldsGroupContainer}>
                {!featureActive('BB-13563') && (
                  <>
                    {/* Duration */}
                    <div className={Styles.durationFieldContainer}>
                      <label>Duration</label>
                      <DurationPicker
                        className={Styles.durationField}
                        displayMode={displayModes.STANDARD}
                        duration={durationType === durationTypeEnum.FIXED ? 'N/A' : durationBilled || ''}
                        durationDisabled={durationFieldInputDisabled || isFormDisabled}
                        durationHasError={formErrors?.durationBilled?.isInvalid}
                        durationType={durationType}
                        durationTypeDisabled={durationFieldTypeDisabled || isFormDisabled}
                        onDurationBlur={onDurationBilledFieldBlur}
                        onDurationChanged={(newDurationValue) => onUpdateDurationBilledFieldValue({ newDurationValue })}
                        onDurationTypeChanged={(selectedDurationType) =>
                          onUpdateDurationFieldType({ selectedDurationType })
                        }
                      />
                    </div>
                    {/* Time */}
                    <div className={Styles.timeFieldContainer}>
                      <label>Time</label>
                      <input
                        className="form-control"
                        type="text"
                        value={durationType === durationTypeEnum.FIXED ? 'N/A' : timeBilledInHoursAndMinutes}
                        disabled
                      />
                    </div>
                  </>
                )}

                {featureActive('BB-13563') && (
                  <div className={Styles.durationFieldContainer}>
                    <label>Duration Type</label>
                    <DurationTypePicker
                      className={Styles.durationField}
                      allowFixed={!isAutoTimeFee}
                      displayMode={displayModes.STANDARD}
                      durationType={durationType}
                      durationTypeDisabled={isFormDisabled}
                      onDurationTypeChanged={(selectedDurationType) =>
                        onUpdateDurationFieldType({ selectedDurationType })
                      }
                    />
                  </div>
                )}

                {featureActive('BB-13563') && featureActive('BB-5345') && (
                  <div className={classNames(Styles.timeFieldContainer, isFormDisabled && Styles.formDisabled)}>
                    <label>Working</label>
                    <TimeConversionInput
                      className="form-control"
                      convertedValue={durationType === durationTypeEnum.FIXED ? 'N/A' : timeWorkedInHoursAndMinutes}
                      duration={durationType === durationTypeEnum.FIXED ? 'N/A' : durationWorked || ''}
                      durationType={durationType}
                      durationDisabled={durationFieldInputDisabled || isFormDisabled}
                      durationHasError={formErrors?.durationWorked?.isInvalid}
                      onDurationBlur={onDurationWorkedFieldBlur}
                      onDurationChanged={(newDurationValue) => onUpdateDurationWorkedFieldValue({ newDurationValue })}
                      disabled
                      showHrs={false}
                      smallerInput
                    />
                  </div>
                )}

                {featureActive('BB-13563') && featureActive('BB-5345') && (
                  <div className={classNames(Styles.timeFieldContainer, isFormDisabled && Styles.formDisabled)}>
                    <label>Billing</label>
                    <TimeConversionInput
                      className="form-control"
                      convertedValue={durationType === durationTypeEnum.FIXED ? 'N/A' : timeBilledInHoursAndMinutes}
                      duration={durationType === durationTypeEnum.FIXED ? 'N/A' : durationBilled || ''}
                      durationType={durationType}
                      durationDisabled={durationFieldInputDisabled || isFormDisabled}
                      durationHasError={formErrors?.durationBilled?.isInvalid}
                      onDurationBlur={onDurationBilledFieldBlur}
                      onDurationChanged={(newDurationValue) => onUpdateDurationBilledFieldValue({ newDurationValue })}
                      disabled
                      showHrs={false}
                      smallerInput
                    />
                  </div>
                )}

                {/* Rate */}
                <div className={Styles.rateFieldContainer}>
                  <label>Rate</label>
                  <CurrencyInput2
                    disabled={isFormDisabled}
                    hasError={formErrors?.rateInCents?.isInvalid}
                    name="rate"
                    value={rateInCents}
                    onChange={(e) =>
                      onUpdateField({
                        field: 'rateInCents',
                        newValue: e.target.value || 0,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className={Styles.row}>
              <div className={Styles.amountTaxBillableAndWriteOffFieldsGroupContainer}>
                {/* Amount */}
                <div className={Styles.amountFieldContainer}>
                  <label>Amount</label>
                  <CurrencyDisplay
                    name="amount"
                    amount={amountExclTaxInCents}
                    region={region}
                    hideDollar
                    formatAmount
                  />
                </div>
                {/* Tax */}
                {showTaxField && (
                  <div className={Styles.taxFieldContainer}>
                    <label>{t('tax')}</label>
                    <div className={Styles.taxField}>
                      <CurrencyDisplay name="tax" amount={taxAmountInCents} region={region} hideDollar formatAmount />
                      <div className={Styles.taxOptions} id="fee-pop-out-editor-form-tax-option-dropdown-group">
                        <Icon
                          type="ellipsis-v"
                          className={classNames(Styles.taxOptionsIcon, isFormDisabled && Styles.disabled)}
                          onClick={isFormDisabled ? () => {} : onShowTaxOptionsToggle}
                          isRelatable
                        />
                        {showTaxOptions && (
                          <CheckboxListPopup
                            list={taxOptions}
                            onOptionSelected={onUpdateTaxField}
                            onBlur={onHandleTaxOptionsBlur}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className={Styles.billableAndWriteOffFieldsGroupContainer}>
                  {/* Billable */}
                  <div className={Styles.billableFieldContainer}>
                    <label>Billable</label>
                    <SlidingToggle
                      disabled={isFormDisabled}
                      id="fee-billable-toggle"
                      name="fee-billable-toggle"
                      scope="fee-pop-out-editor"
                      onChange={(id, newValue) => onUpdateBillableField({ newValue })}
                      selected={isBillable}
                    />
                  </div>
                  {/* Write off */}
                  <div className={Styles.writeOffFieldContainer}>
                    <label>Write off</label>
                    <SlidingToggle
                      disabled={!writeOffFieldEnabled || isFormDisabled}
                      id="fee-write-off-toggle"
                      name="fee-write-off-toggle"
                      scope="fee-pop-out-editor"
                      onChange={(id, newValue) => onUpdateField({ field: 'isWriteOff', newValue })}
                      selected={isWriteOff}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames(Styles.row, Styles.expand)}>
              <div className={Styles.previewContainer}>
                <label>Preview</label>
                <div className={Styles.preview}>
                  <p>{subject}</p>
                  {isAutoTimeFee ? (
                    <>
                      {sourceItems.map(
                        ({
                          id,
                          description: sourceItemDescription,
                          durationBilled: sourceItemDurationInMins,
                          billable,
                        }) => {
                          const sourceItemDurationInHours = +(sourceItemDurationInMins / 60).toFixed(5);

                          return (
                            <p key={id}>
                              - {sourceItemDescription}{' '}
                              {`(${(sourceItemDurationInHours || 0).toFixed(2)}hrs${
                                billable ? '' : ' - Non-Billable'
                              })`}
                            </p>
                          );
                        },
                      )}
                    </>
                  ) : (
                    description.split('\n').map((note, index) => <p key={index}>{note}</p>)
                  )}
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      {/* Editor footer */}
      <div className={Styles.editorFooter}>
        <div className={Styles.feeNavigatorContainer}>
          <div className={classNames(Styles.feeNavigator, feeListCount < 2 && Styles.hidden)}>
            <button
              type="button"
              aria-label="Navigate to previous fee"
              className={classNames(Styles.arrow, 'fa', 'fa-angle-left')}
              onClick={onNavigateToPreviousFee}
            />
            <span className={Styles.pageNumbers}>
              {feeIndex + 1} of {feeListCount}
            </span>
            <button
              type="button"
              aria-label="Navigate to next fee"
              className={classNames(Styles.arrow, 'fa', 'fa-angle-right')}
              onClick={onNavigateToNextFee}
            />
          </div>
        </div>
        <div className={Styles.onDraftInvoiceWarningContainer}>
          {isOnDraftInvoice && invoiceNumber && (
            <div className={Styles.onDraftInvoiceWarning}>
              <span className={classNames('icon', 'icon-alert-1', Styles.warningIcon)} />
              <p>{`On draft invoice #${invoiceNumber}`}</p>
            </div>
          )}
        </div>
        <div className={Styles.formActions}>
          {isOnFinalisedInvoice && invoiceNumber ? (
            <div className={Styles.linkToFinalisedInvoiceContainer}>
              <Button onClick={onNavigateToInvoice}>{`ON ${t(
                'finalised',
              ).toUpperCase()} INVOICE #${invoiceNumber}`}</Button>
            </div>
          ) : (
            !hideFormActionButtons && (
              <div>
                <Button type={buttonTypes.link} onClick={onFormSubmit} disabled={isFormDisabled}>
                  Save
                </Button>
                <Button type={buttonTypes.link} onClick={onFormCancel} disabled={isFormDisabled}>
                  Cancel
                </Button>
              </div>
            )
          )}
          <div className={Styles.expandCollapseButtonContainer}>
            <ExpandCollapseButton open onExpandCollapseClick={onCollapseFeePopOutEditor} />
          </div>
        </div>
      </div>
    </div>
  );
};

ExpandedFeePopOutEditor.displayName = 'ExpandedFeePopOutEditor';

const SourceItemType = PropTypes.shape({
  activityCount: PropTypes.number,
  activityRelatedId: PropTypes.string,
  activityType: PropTypes.number,
  billable: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  durationBilled: PropTypes.number.isRequired,
  durationWorked: PropTypes.number.isRequired,
  id: PropTypes.string, // Added (not part of entity)
  originalBillable: PropTypes.bool,
  sourceActivityIds: PropTypes.arrayOf(PropTypes.string).isRequired,
});

const ActivityType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  isBillable: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(Object.values(entryTypeEnum)),
  units: PropTypes.number,
  isTaxInclusive: PropTypes.bool,
  isTaxExempt: PropTypes.bool,
  rateOverrideType: PropTypes.oneOf([0, 1, 2, undefined]),
  allStaffRate: PropTypes.number,
  ratesPerStaff: PropTypes.arrayOf(
    PropTypes.shape({
      staffId: PropTypes.string.isRequired,
      rate: PropTypes.number.isRequired,
    }).isRequired,
  ),
  description: PropTypes.string.isRequired,
  category: PropTypes.oneOf(Object.values(activityCategories)),
});

const TaskType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

const StaffEntityType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  isFormerStaff: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
});

const MatterSummaryType = PropTypes.shape({
  attorneyResponsible: PropTypes.shape({
    id: PropTypes.string.isRequired,
    initials: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  billingConfiguration: PropTypes.shape({
    billingType: PropTypes.string,
    id: PropTypes.string.isRequired,
    isUtbmsEnabled: PropTypes.bool.isRequired,
  }),
  clientDisplay: PropTypes.string.isRequired,
  clientNames: PropTypes.string.isRequired,
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  matterHourlyRate: PropTypes.shape({
    allStaffRate: PropTypes.number,
    billableMinutes: PropTypes.number,
    id: PropTypes.string.isRequired,
    rateOverrideType: PropTypes.oneOf([0, 1, 2, 3, undefined]),
    rateSet: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      versions: PropTypes.arrayOf(
        PropTypes.shape({
          ratesPerStaff: PropTypes.arrayOf(
            PropTypes.shape({
              rate: PropTypes.number.isRequired,
              staffId: PropTypes.string.isRequired,
            }),
          ),
        }),
      ),
    }),
    ratesPerStaff: PropTypes.arrayOf(PropTypes.object),
  }),
  matterNumber: PropTypes.string.isRequired,
  matterStarted: PropTypes.instanceOf(Date),
  matterType: PropTypes.shape({
    name: PropTypes.string,
  }),
  otherSide: PropTypes.arrayOf(PropTypes.object),
  otherSideDisplay: PropTypes.string,
  status: PropTypes.string.isRequired,
  display: PropTypes.string.isRequired,
  matterClientNames: PropTypes.string.isRequired,
  matterStartedISO: PropTypes.string.isRequired,
  typeahead: PropTypes.string.isRequired,
});

ExpandedFeePopOutEditor.propTypes = {
  // Editor
  formInitialised: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  // Editor header
  heading: PropTypes.string.isRequired, // Matter display
  // Editor body
  autoTimeSummary: PropTypes.string,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.oneOf(Object.values(activityCategoryLabels)),
      activities: PropTypes.arrayOf(ActivityType),
    }),
  ).isRequired,
  descriptionFieldMaxLength: PropTypes.number.isRequired,
  durationFieldInputDisabled: PropTypes.bool,
  durationFieldTypeDisabled: PropTypes.bool,
  formData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string,
    durationInMins: PropTypes.number,
    feeDate: PropTypes.instanceOf(Date),
    isBillable: PropTypes.bool,
    isTaxExempt: PropTypes.bool,
    isTaxInclusive: PropTypes.bool,
    isWriteOff: PropTypes.bool,
    rateInCents: PropTypes.number,
    sourceItems: PropTypes.arrayOf(SourceItemType),
    staffId: PropTypes.string,
    subject: PropTypes.string,
    // Derived values required in form submission and/or form fields
    amountExclTaxInCents: PropTypes.number,
    billableTaxAmountInCents: PropTypes.number,
    taxAmountInCents: PropTypes.number,
    // Form state not required in form submission (can be edited by user)
    durationBilled: PropTypes.string, // Duration Billed field value (hours/units)
    durationWorked: PropTypes.string, // Duration Worked field value (hours/units)
    durationType: PropTypes.oneOf(Object.values(durationTypeEnum)),
    // Form state not required in form submission (cannot be edited by user)
    billableAmountExclTaxInCents: PropTypes.number,
    isInvoicedExternally: PropTypes.bool,
    nonBillableAmountExclTaxInCents: PropTypes.number,
    timeBilledInHoursAndMinutes: PropTypes.string,
    timeWorkedInHoursAndMinutes: PropTypes.string,
    // IDs that map with objects used by containers and form components
    activityId: PropTypes.string,
    matterId: PropTypes.string,
    subjectActivityId: PropTypes.string,
    taskId: PropTypes.string,
  }).isRequired,
  formDataMappedObjects: PropTypes.shape({
    activity: ActivityType,
    matter: MatterSummaryType,
    staff: StaffEntityType,
    subjectActivity: ActivityType,
    task: TaskType,
  }).isRequired,
  formErrors: PropTypes.object.isRequired,
  hasDurationTypeChanged: PropTypes.bool.isRequired,
  hideFormActionButtons: PropTypes.bool.isRequired,
  isAutoTimeFee: PropTypes.bool.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  matterBillableMinutes: PropTypes.number,
  onDeleteFee: PropTypes.func.isRequired,
  onDurationBilledFieldBlur: PropTypes.func,
  onDurationWorkedFieldBlur: PropTypes.func,
  onHandleTaxOptionsBlur: PropTypes.func,
  onShowTaxOptionsToggle: PropTypes.func,
  onUpdateActivityField: PropTypes.func,
  onUpdateBillableField: PropTypes.func,
  onUpdateDurationFieldType: PropTypes.func,
  onUpdateDurationBilledFieldValue: PropTypes.func,
  onUpdateDurationWorkedFieldValue: PropTypes.func,
  onUpdateField: PropTypes.func,
  onUpdateSourceItemsField: PropTypes.func,
  onUpdateTaskField: PropTypes.func,
  onUpdateTaxField: PropTypes.func,
  region: PropTypes.oneOf(Object.values(regionType)),
  showTaskField: PropTypes.bool,
  showTaxField: PropTypes.bool,
  showTaxOptions: PropTypes.bool,
  sourceItemsInitialValue: PropTypes.arrayOf(SourceItemType).isRequired,
  taskFieldEnabled: PropTypes.bool,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.oneOf(Object.values(taskCategoryLabels)),
      tasks: PropTypes.arrayOf(TaskType.isRequired),
    }),
  ).isRequired,
  taxOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
    }),
  ),
  writeOffFieldEnabled: PropTypes.bool,
  // Editor footer
  feeIndex: PropTypes.number.isRequired,
  feeListCount: PropTypes.number.isRequired,
  invoiceNumber: PropTypes.number,
  isOnDraftInvoice: PropTypes.bool.isRequired,
  isOnFinalisedInvoice: PropTypes.bool.isRequired,
  onCollapseFeePopOutEditor: PropTypes.func.isRequired,
  onFormCancel: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onNavigateToInvoice: PropTypes.func.isRequired,
  onNavigateToNextFee: PropTypes.func.isRequired,
  onNavigateToPreviousFee: PropTypes.func.isRequired,
  onSetIsExpanded: PropTypes.func.isRequired,
  setHasDurationTypeChanged: PropTypes.func.isRequired,
};

ExpandedFeePopOutEditor.defaultProps = {
  autoTimeSummary: undefined,
  invoiceNumber: undefined,
  /** Required props (that are undefined until containers complete) */
  durationFieldInputDisabled: undefined,
  durationFieldTypeDisabled: undefined,
  matterBillableMinutes: undefined,
  onDurationBilledFieldBlur: undefined,
  onDurationWorkedFieldBlur: undefined,
  onHandleTaxOptionsBlur: undefined,
  onShowTaxOptionsToggle: undefined,
  onUpdateActivityField: undefined,
  onUpdateBillableField: undefined,
  onUpdateDurationFieldType: undefined,
  onUpdateDurationBilledFieldValue: undefined,
  onUpdateDurationWorkedFieldValue: undefined,
  onUpdateField: undefined,
  onUpdateSourceItemsField: undefined,
  onUpdateTaskField: undefined,
  onUpdateTaxField: undefined,
  region: undefined,
  showTaskField: undefined,
  showTaxField: undefined,
  showTaxOptions: undefined,
  taskFieldEnabled: undefined,
  taxOptions: undefined,
  writeOffFieldEnabled: undefined,
};
