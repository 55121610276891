import { gql } from '@apollo/client';

const query = gql`
  query InitActivityCodes(
    $includeUtbmsCodes: Boolean!
    $isUtbmsEnabledCheck: Boolean
    $utbmsCustomTaskCodeFilter: UtbmsCustomTaskCodeFilter
  ) {
    activityCodes {
      id
      code
      isBillable
      type
      durationMins
      description
      expenseCostType
      inputTaxRate
      isDeleted
      isTaxInclusive
      isTaxExempt
      allStaffRate
      ratesPerStaff {
        staffId
        rate
      }
      outputTaxRate
      rateOverrideType
      displayWithFees
    }
    utbmsActivityCodes(isUtbmsEnabledCheck: $isUtbmsEnabledCheck) @include(if: $includeUtbmsCodes) {
      id
      code
      type
      description
    }
    utbmsTaskCodes(isUtbmsEnabledCheck: $isUtbmsEnabledCheck) @include(if: $includeUtbmsCodes) {
      id
      code
      type
      description
    }
    utbmsCustomTaskCodes(filter: $utbmsCustomTaskCodeFilter) @include(if: $includeUtbmsCodes) {
      id
      code
      entryType
      description
      isBillable
    }
  }
`;

const notificationIds = [
  'BillingActivitiesNotifications.BillingActivityUpdated',
  'BillingActivitiesNotifications.BillingActivityDeleted',
  'BillingActivitiesNotifications.BillingUtbmsSettingsUpdated',
  'BillingActivitiesNotifications.CustomTaskCodeUpdated',
];

/**
 * @type {import('./types').CacheSubscriptionQuery}
 */
export const InitActivityCodes = {
  query,
  notificationIds,
};
