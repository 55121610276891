import { gql } from '@apollo/client';

const query = gql`
  query InitBulkFinalizeSettings {
    bulkFinalizeSettings {
      preferredBankAccountTypes
    }
  }
`;

const notificationIds = ['InvoicingNotifications.BulkFinalizeSettingsUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const InitBulkFinalizeSettings = {
  query,
  notificationIds,
};
