import React from 'react';
import PropTypes from 'prop-types';

const InterpolatedDescriptionDisplay = ({ splitDescription }) => (
  <>
    {splitDescription.map((section, idx) => (
      <React.Fragment key={idx}>{section}</React.Fragment>
    ))}
  </>
);

InterpolatedDescriptionDisplay.propTypes = {
  splitDescription: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])).isRequired,
};

export default InterpolatedDescriptionDisplay;
