import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import {
  statusByName as invoiceStatusByName,
  statusByValue as invoiceStatusByValue,
} from '@sb-billing/business-logic/invoice/entities';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { addDays, dateToInteger, today } from '@sb-itops/date';
import { featureActive } from '@sb-itops/feature';
import { getLogger } from '@sb-itops/fe-logger';
import {
  error as displayErrorToUser,
  info as displayInfoToUser,
  warn as displayWarningToUser,
} from '@sb-itops/message-display';
import { withOnLoad } from '@sb-itops/react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { isModalVisible, setModalDialogHidden, setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import uuid from '@sb-itops/uuid';
import { entryTypeLabelToValueMap } from '@sb-billing/business-logic/shared/entities';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import * as billsListFilters from 'web/redux/route/home-billing-bills-list';

import { BulkCreateInvoicesModal } from './BulkCreateInvoicesModal';

const BULK_CREATE_INVOICES_CONFIRM_MODAL_ID = 'BULK_CREATE_INVOICES_CONFIRM_MODAL_ID';
const SCOPE = 'bulk-create-invoices-modal';
const log = getLogger('BulkCreateInvoicesModal.container');

const BulkCreateInvoicesModalSchema = yup.object().shape({
  issuedDate: yup.string().required(),
  dueDate: yup.string().required(),
  invoiceStatus: yup.number().required(),
});

const sendBulkCreateInvoicesRequest = async ({
  invoiceStatus,
  issuedDate,
  dueDate,
  startDate,
  endDate,
  matters,
  entryTypes,
  // functions/callbacks
  deselectAllMatters,
  navigateToBillsList,
  onCloseModal,
}) => {
  if (!matters || !matters.length) {
    log.error('Error while creating bulk invoices. No matters specified.');
    throw new Error('Error while creating bulk invoices. No matters specified. ');
  }

  try {
    const matterItems = matters.map((matter) => ({
      versionId: uuid(),
      invoiceId: uuid(),
      matterId: matter.matterId,
      matterNumber: matter.matterNumber,
      matterTitle: matter.matterTitle,
      matterDescription: matter.matterDescription,
      clientDisplay: matter.clientDisplay,
      matterTypeName: matter.matterTypeName,
      clients: matter.clients,
    }));

    const message = {
      asDraft: invoiceStatus === invoiceStatusByName.DRAFT,
      isOriginalInvoice: featureActive('BB-1761'),
      dueDate: dateToInteger(dueDate),
      invoicedDate: dateToInteger(issuedDate),
      startDate,
      endDate,
      matterItems,
      entryTypes,
      supportsDisplayExpenseWithFees: hasFacet(facets.displayExpenseWithFees) && featureActive('BB-14971'),
    };

    const response = await dispatchCommand({ type: 'Integration.BulkCreateInvoices', message });
    const fail = response.results.fail || [];
    const success = response.results.success || [];
    if (fail && fail.length) {
      const failedMatterItems = fail.reduce((acc, failedMessage) => acc.concat(failedMessage.matterItems), []);
      displayErrorToUser(
        `${failedMatterItems.length} matter${failedMatterItems.length > 1 ? 's' : ''} failed to create invoices.`,
      );
    } else if (success && success.length === 0) {
      displayErrorToUser(`Failed to create invoice(s)`);
      log.error('Failed to create invoice(s): ', JSON.stringify(response));
    } else {
      const successMatterItems = success.reduce((acc, successMessage) => acc.concat(successMessage.matterItems), []);
      displayInfoToUser(
        `Processing ${successMatterItems.length} invoice${
          successMatterItems.length === 1 ? '' : 's'
        }. This can take a number of minutes to complete.`,
      );
    }

    onCloseModal();
    deselectAllMatters();
    navigateToBillsList();
  } catch (error) {
    displayErrorToUser(`Failed to create invoice(s)`);
    log.error('Failed to create invoice(s): ', error);
  }
};

const hooks = (props) => ({
  useFormFields: () => {
    const {
      formFields,
      formValues,
      formInitialised,
      onInitialiseForm,
      onUpdateFieldValues,
      onSubmitFormWithValidation,
      onValidateForm,
    } = useForm({ scope: SCOPE, schema: BulkCreateInvoicesModalSchema });

    const isConfirmModalVisible = useSelector(() => isModalVisible({ modalId: BULK_CREATE_INVOICES_CONFIRM_MODAL_ID }));
    const openConfirmModal = () => {
      setModalDialogVisible({ modalId: BULK_CREATE_INVOICES_CONFIRM_MODAL_ID });
    };
    const closeConfirmModal = () => {
      setModalDialogHidden({ modalId: BULK_CREATE_INVOICES_CONFIRM_MODAL_ID });
    };

    const { selectedMatters, startDate, endDate, selectedEntryTypes } = props;
    const paymentDueDays = Number.isInteger(props.paymentDueDays) ? props.paymentDueDays : 7;
    const selectedMatterIds = Object.keys(selectedMatters);
    const selectedMatterCount = selectedMatterIds.length;
    const { hasUnpaidAD, zeroBalanceInvoiceCount, finalMatters } = selectedMatterIds.reduce(
      (acc, matterId) => {
        const matter = selectedMatters[matterId];
        if (matter) {
          if (featureActive('BB-9573') && !acc.hasUnpaidAD && matter.hasUnpaidAD) {
            acc.hasUnpaidAD = true;
          }
          if (featureActive('BB-7088') && matter.totalAmount - matter.writtenOffAmountExcTax === 0) {
            acc.zeroBalanceInvoiceCount += 1;
          }
          if (featureActive('BB-1761')) {
            const issuedDate = dateToInteger(formFields?.issuedDate?.value);
            // if there are no previous invoices, then the new (soon to be created) invoice will always be valid
            if (!matter.lastInvoicedDate || (matter.lastInvoicedDate && issuedDate > matter.lastInvoicedDate)) {
              acc.finalMatters.push(matter);
            } else {
              // eslint-disable-next-line no-console
              console.warn(
                `Selected matter ${matterId}'s last invoiced date ${matter.lastInvoicedDate} is newer than selected issue date ${issuedDate}, skipping`,
              );
            }
          } else {
            acc.finalMatters.push(matter);
          }
        } else {
          // eslint-disable-next-line no-console
          console.warn(`Selected matter ${matterId} not found, skipping`);
        }
        return acc;
      },
      { hasUnpaidAD: false, zeroBalanceInvoiceCount: 0, finalMatters: [] },
    );

    const dispatch = useDispatch();
    const navigateToBillsList = () => {
      const invoiceStatusFilterValue = invoiceStatusByValue[formValues.invoiceStatus];
      dispatch(billsListFilters.actions.onFilterInvoiceStatus([invoiceStatusFilterValue]));
      props.onNavigateTo('home.billing.bills.list');
    };

    const [isSaving, setIsSaving] = useState(false);
    const bulkCreateInvoices = async () => {
      const { invoiceStatus, issuedDate, dueDate } = formValues;
      // Map entry type to actual Entry Types (integers)
      const entryTypes = (selectedEntryTypes || [])
        .map((entryTypeLabel) => entryTypeLabelToValueMap[entryTypeLabel])
        .sort();
      setIsSaving(true);
      await sendBulkCreateInvoicesRequest({
        invoiceStatus,
        issuedDate,
        dueDate,
        startDate,
        endDate,
        matters: finalMatters,
        entryTypes,
        onCloseModal: props.onCloseModal,
        deselectAllMatters: props.deselectAllMatters,
        navigateToBillsList,
      });
      setIsSaving(false);
    };

    return {
      isConfirmModalVisible,
      isSaving,
      formFields,
      formInitialised,
      selectedMatters,
      zeroBalanceInvoiceCount,
      invoiceCount: selectedMatterCount,
      hasUnpaidAD,

      // function/callbacks
      onUpdateFieldValues,
      onUpdateIssuedDate: (date) => {
        onUpdateFieldValues('issuedDate', date);
        onUpdateFieldValues('dueDate', addDays(date, paymentDueDays));
      },
      onLoad: () => {
        onInitialiseForm({
          issuedDate: today(),
          dueDate: addDays(today(), paymentDueDays),
          invoiceStatus: invoiceStatusByName.DRAFT,
        });
      },
      onSave: async () => {
        onValidateForm();
        await onSubmitFormWithValidation({
          submitFnP: async (finalFormValues) => {
            if (finalFormValues.invoiceStatus === invoiceStatusByName.FINAL) {
              if (finalMatters.length === 0) {
                displayErrorToUser(
                  'No invoices can be issued on the date selected: Issue dates must come after the latest issued invoice for the same matter & debtor(s).',
                );
                return;
              }

              if (finalMatters.length !== selectedMatterCount) {
                const invoicesNotCreatedCount = selectedMatterCount - finalMatters.length;
                displayWarningToUser(
                  `${invoicesNotCreatedCount} invoice${
                    invoicesNotCreatedCount === 1 ? '' : 's'
                  } cannot be issued on the date selected: Issue date(s) must come after the latest issued invoice for the same matter & debtor(s).`,
                );
              }

              if (hasUnpaidAD || zeroBalanceInvoiceCount > 0) {
                openConfirmModal();
              } else {
                bulkCreateInvoices();
              }
            } else {
              bulkCreateInvoices();
            }
          },
        });
      },
      onConfirm: async () => {
        bulkCreateInvoices();
        closeConfirmModal();
      },
      onCancel: () => {
        closeConfirmModal();
      },
    };
  },
});

export const BulkCreateInvoicesModalContainer = withApolloClient(
  composeHooks(hooks)(withOnLoad(BulkCreateInvoicesModal)),
);

BulkCreateInvoicesModalContainer.displayName = 'BulkCreateInvoicesModal.container';

BulkCreateInvoicesModalContainer.propTypes = {
  // form state
  isVisible: PropTypes.bool.isRequired,
  selectedMatters: PropTypes.object.isRequired,
  paymentDueDays: PropTypes.number.isRequired,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  selectedEntryTypes: PropTypes.array.isRequired,

  // function/callbacks
  onCloseModal: PropTypes.func.isRequired,
  onNavigateTo: PropTypes.func.isRequired,
  deselectAllMatters: PropTypes.func.isRequired,
};

BulkCreateInvoicesModalContainer.defaultProps = {
  startDate: undefined,
  endDate: undefined,
};
