/* eslint-disable func-names */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Table, Column, utils } from '@sb-itops/react/table';
import { Checkbox } from '@sb-itops/react/checkbox';
import { MattersDisplayFromMatters } from '@sb-matter-management/react';

import Styles from './PaymentPlanInclusionsTable.module.scss';

const { balanceCellLocalisedRenderer } = utils;
const REASON_FOR_EXCLUSION = 'This matter cannot be added to a payment plan as all invoices have multiple debtors.';

const checkboxHeaderCellRenderer =
  ({ matters, inclusions, onSelectAll }) =>
  () => {
    const numberOfSelectedMatters = Object.keys(inclusions).filter(
      (selectedMatter) => !!inclusions[selectedMatter],
    ).length;
    const numberOfMattersThatCanBeIncludedInPaymentPlan = matters.filter(
      (candidateMatter) => candidateMatter.canIncludeInPaymentPlan,
    ).length;

    const headerCheckboxDisabled = numberOfMattersThatCanBeIncludedInPaymentPlan === 0;
    const headerCheckboxSelected =
      numberOfMattersThatCanBeIncludedInPaymentPlan > 0 &&
      numberOfSelectedMatters === numberOfMattersThatCanBeIncludedInPaymentPlan;
    return (
      <div className={Styles.inclusionCheckbox}>
        <Checkbox checked={headerCheckboxSelected} disabled={headerCheckboxDisabled} onChange={onSelectAll} />
      </div>
    );
  };

const checkboxCellRenderer =
  ({ inclusions, onSelectMatter }) =>
  ({ rowData }) => (
    <div
      title={rowData.canIncludeInPaymentPlan ? undefined : REASON_FOR_EXCLUSION}
      className={Styles.inclusionCheckbox}
    >
      <Checkbox
        checked={inclusions[rowData.matterId]}
        disabled={!rowData.canIncludeInPaymentPlan}
        onChange={() => {
          onSelectMatter({ ...rowData });
        }}
      />
    </div>
  );

const dueHeaderRenderer = () => (
  <div className={Styles.lastColumn}>
    <span className="header-label">Due</span>
  </div>
);

function dueCellRenderer() {
  return ({ rowData }) => (
    <div
      title={rowData.canIncludeInPaymentPlan ? undefined : REASON_FOR_EXCLUSION}
      className={classnames(Styles.lastColumn, rowData.canIncludeInPaymentPlan ? undefined : Styles.disabledText)}
    >
      {balanceCellLocalisedRenderer({ cellData: rowData.due })}
    </div>
  );
}

const dueForThisDebtorOnlyHeaderRenderer = () => (
  <div className={Styles.lastColumn}>
    <span className="header-label">Amount to Include</span>
  </div>
);

function dueForThisDebtorOnlyCellRenderer() {
  return ({ rowData }) => (
    <div
      title={rowData.canIncludeInPaymentPlan ? undefined : REASON_FOR_EXCLUSION}
      className={classnames(Styles.lastColumn, rowData.canIncludeInPaymentPlan ? undefined : Styles.disabledText)}
    >
      {balanceCellLocalisedRenderer({ cellData: rowData.dueForThisDebtorOnly })}
    </div>
  );
}

function matterCellRenderer() {
  return ({ rowData }) => (
    <MattersDisplayFromMatters
      className={classnames(
        Styles.italics,
        Styles.matterTextLabel,
        rowData.canIncludeInPaymentPlan ? undefined : Styles.disabledText,
      )}
      matters={[rowData]}
      tooltip={rowData.canIncludeInPaymentPlan ? undefined : REASON_FOR_EXCLUSION}
    />
  );
}

const PaymentPlanInclusionsTable = memo(({ matters, inclusions, onSelectMatter, onSelectAll }) => {
  // show due column only when due amount and amount to include don't match for some
  // matters which the debtor owes money on. This can happen when there are shared debt.
  const showDueColumn = matters.some((matter) => matter.dueForThisDebtorOnly !== matter.due);
  return (
    <Table
      className={Styles.paymentPlanTableContainer}
      list={matters}
      onRowClick={(e) => e.rowData.canIncludeInPaymentPlan && onSelectMatter(e.rowData)}
    >
      <Column dataKey="matterId" label="Matter" cellRenderer={matterCellRenderer()} flexGrow={16} disableSort />
      {showDueColumn && (
        <Column
          dataKey="due"
          label="Due"
          flexGrow={2}
          width={120}
          cellRenderer={dueCellRenderer()}
          disableSort
          headerRenderer={dueHeaderRenderer}
        />
      )}
      <Column
        dataKey="dueForThisDebtorOnly"
        label="Amount to Include"
        flexGrow={2}
        width={120}
        cellRenderer={dueForThisDebtorOnlyCellRenderer()}
        disableSort
        headerRenderer={dueForThisDebtorOnlyHeaderRenderer}
      />
      <Column
        dataKey="UNNEEDED"
        label=""
        flexGrow={1}
        width={34}
        cellRenderer={checkboxCellRenderer({ inclusions, onSelectMatter })}
        headerRenderer={checkboxHeaderCellRenderer({ matters, inclusions, onSelectAll })}
        disableSort
      />
    </Table>
  );
});

PaymentPlanInclusionsTable.displayName = 'PaymentPlanInclusionsTable';

PaymentPlanInclusionsTable.propTypes = {
  matters: PropTypes.arrayOf(
    PropTypes.shape({
      matterId: PropTypes.string.isRequired,
      due: PropTypes.number.isRequired, // total amount due on the matter for associated debtor, including both single and multi-debtor invoices
      dueForThisDebtorOnly: PropTypes.number.isRequired, // total amount due on the matter for this debtor, not including multi-debtor invoices
      canIncludeInPaymentPlan: PropTypes.bool.isRequired,
    }),
  ),
  inclusions: PropTypes.object,
  onSelectMatter: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
};

PaymentPlanInclusionsTable.defaultProps = {
  matters: [],
  inclusions: {},
};

export default PaymentPlanInclusionsTable;
