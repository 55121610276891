import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button, Icon, LoadingBarInfinite, Modal, buttonTypes, useTranslation } from '@sb-itops/react';
import { regionType } from '@sb-itops/region';

import { ExpenseModalBody } from './ExpenseModalBody';
import Styles from './ExpenseModal.module.scss';

export const EXPENSE_MODAL_ID = 'expense-modal';

export const ExpenseModal = ({
  isFormDisabled,
  /** Modal */
  invoiceNumber,
  isLoading,
  isNewExpense,
  isOnDraftInvoice,
  showModal,
  // Callbacks
  onModalClose,
  onFormSubmit,
  /** Form */
  activitiesGroupArray,
  anticipatedDisbursementsEnabled,
  chequeNumberDuplicationWarningMessage,
  defaultMatterSummaries,
  defaultPayToOptions,
  defaultSupplierContactOptions,
  costTypeButtonList,
  expenseTypeButtonList,
  formData,
  formDataMappedObjects,
  formErrors,
  isActivityFieldDisabled,
  isChequeReferenceFieldDisabled,
  isLoadingAvailableOperatingChequeNumber,
  isOnFinalisedInvoice,
  isPaidExpense,
  isPaymentDetailsDisabled,
  isPaymentToggleDisabled,
  isSaveAndNewDisabled,
  isSaveOrUpdateButtonDisabled,
  isSupplierDetailsDisabled,
  isTaskFieldDisabled,
  matterSummaries,
  matterSummariesDataLoading,
  matterSummariesHasMore,
  paymentMethodOptions,
  payToContactOptions,
  payToContactOptionsDataLoading,
  payToContactOptionsHasMore,
  provideDeleteExpenseLink,
  operatingChequePrintSettings,
  region,
  showChequeMemoField,
  showPayableToSupplierToggle,
  showPrintingMethodField,
  showTaskField,
  showTaxFields,
  showDisplayWithFeesField,
  displayWithFeesDisabled,
  staffMemberOptions,
  subjectOptions,
  supplierContactOptions,
  supplierContactOptionsDataLoading,
  supplierContactOptionsHasMore,
  tasksGroupArray,
  // inline add contact
  isUtbmsEnabledForFirm,
  showAddPayToContactForm,
  showAddSupplierContactForm,
  setShowAddPayToContactForm,
  setShowAddSupplierContactForm,
  // Callbacks
  onAttachmentFileChange,
  onChequePrintingMethodChange,
  onSelectCostType,
  onChequeReferenceFieldUpdate,
  onDeleteExpense,
  onExpensePaymentDetailsFieldUpdate,
  onFetchMatterSummaries,
  onFetchMoreMatterSummaries,
  onFetchMorePayToContactOptions,
  onFetchMoreSupplierContactOptions,
  onFetchPayToContactOptions,
  onFetchSupplierContactOptions,
  onNavigateToInvoice,
  onOperatingChequeFieldUpdate,
  onOperatingChequePrintOptionsFieldUpdate,
  onPayableToSupplierToggleChange,
  onPaymentMethodChange,
  onPayToSelected,
  onPrintChequeChange,
  onSelectExpenseType,
  onSupplierPaidChange,
  onSupplierPaidToggleChange,
  onSupplierSelected,
  onUpdateActivityField,
  onUpdateBillableField,
  onUpdateField,
  onUpdateMatterField,
  onUpdateSubjectField,
  onUpdateTaskField,
  onUpdateTaxField,
  onUpdateOutputTaxField,
}) => {
  const { t } = useTranslation();

  const getModalTitle = () => {
    const capitalisedAndLocalisedLabel = t('capitalizeAllWords', { val: 'expense' });

    if (isNewExpense) {
      return `New ${capitalisedAndLocalisedLabel} Entry`;
    }

    return `${capitalisedAndLocalisedLabel} Details`;
  };

  return (
    <Modal
      className={Styles.expenseModal}
      modalHeaderClassName={Styles.modalHeaderContainer}
      modalBodyClassName={Styles.modalBodyContainer}
      modalFooterClassName={Styles.modalFooterContainer}
      isVisible={showModal}
      title={getModalTitle()}
      body={
        isLoading ? (
          <div className={Styles.modalBodyLoadingContainer}>
            <LoadingBarInfinite loading={isLoading} />
          </div>
        ) : (
          <ExpenseModalBody
            activitiesGroupArray={activitiesGroupArray}
            anticipatedDisbursementsEnabled={anticipatedDisbursementsEnabled}
            chequeNumberDuplicationWarningMessage={chequeNumberDuplicationWarningMessage}
            defaultMatterSummaries={defaultMatterSummaries}
            defaultPayToOptions={defaultPayToOptions}
            defaultSupplierContactOptions={defaultSupplierContactOptions}
            expenseTypeButtonList={expenseTypeButtonList}
            costTypeButtonList={costTypeButtonList}
            formData={formData}
            formDataMappedObjects={formDataMappedObjects}
            formErrors={formErrors}
            invoiceNumber={invoiceNumber}
            isActivityFieldDisabled={isActivityFieldDisabled}
            isChequeReferenceFieldDisabled={isChequeReferenceFieldDisabled}
            isLoadingAvailableOperatingChequeNumber={isLoadingAvailableOperatingChequeNumber}
            isFormDisabled={isFormDisabled}
            isOnFinalisedInvoice={isOnFinalisedInvoice}
            isPaidExpense={isPaidExpense}
            isPaymentDetailsDisabled={isPaymentDetailsDisabled}
            isPaymentToggleDisabled={isPaymentToggleDisabled}
            isSupplierDetailsDisabled={isSupplierDetailsDisabled}
            isTaskFieldDisabled={isTaskFieldDisabled}
            matterSummaries={matterSummaries}
            matterSummariesDataLoading={matterSummariesDataLoading}
            matterSummariesHasMore={matterSummariesHasMore}
            paymentMethodOptions={paymentMethodOptions}
            payToContactOptions={payToContactOptions}
            payToContactOptionsDataLoading={payToContactOptionsDataLoading}
            payToContactOptionsHasMore={payToContactOptionsHasMore}
            provideDeleteExpenseLink={provideDeleteExpenseLink}
            operatingChequePrintSettings={operatingChequePrintSettings}
            region={region}
            showChequeMemoField={showChequeMemoField}
            showPayableToSupplierToggle={showPayableToSupplierToggle}
            showPrintingMethodField={showPrintingMethodField}
            showTaskField={showTaskField}
            showTaxFields={showTaxFields}
            showDisplayWithFeesField={showDisplayWithFeesField}
            displayWithFeesDisabled={displayWithFeesDisabled}
            staffMemberOptions={staffMemberOptions}
            subjectOptions={subjectOptions}
            supplierContactOptions={supplierContactOptions}
            supplierContactOptionsDataLoading={supplierContactOptionsDataLoading}
            supplierContactOptionsHasMore={supplierContactOptionsHasMore}
            tasksGroupArray={tasksGroupArray}
            // inline add contact
            isUtbmsEnabledForFirm={isUtbmsEnabledForFirm}
            showAddPayToContactForm={showAddPayToContactForm}
            showAddSupplierContactForm={showAddSupplierContactForm}
            setShowAddPayToContactForm={setShowAddPayToContactForm}
            setShowAddSupplierContactForm={setShowAddSupplierContactForm}
            // Callbacks
            onAttachmentFileChange={onAttachmentFileChange}
            onChequePrintingMethodChange={onChequePrintingMethodChange}
            onSelectCostType={onSelectCostType}
            onChequeReferenceFieldUpdate={onChequeReferenceFieldUpdate}
            onDeleteExpense={onDeleteExpense}
            onExpensePaymentDetailsFieldUpdate={onExpensePaymentDetailsFieldUpdate}
            onFetchMatterSummaries={onFetchMatterSummaries}
            onFetchMoreMatterSummaries={onFetchMoreMatterSummaries}
            onFetchMorePayToContactOptions={onFetchMorePayToContactOptions}
            onFetchMoreSupplierContactOptions={onFetchMoreSupplierContactOptions}
            onFetchPayToContactOptions={onFetchPayToContactOptions}
            onFetchSupplierContactOptions={onFetchSupplierContactOptions}
            onNavigateToInvoice={onNavigateToInvoice}
            onOperatingChequeFieldUpdate={onOperatingChequeFieldUpdate}
            onOperatingChequePrintOptionsFieldUpdate={onOperatingChequePrintOptionsFieldUpdate}
            onPayableToSupplierToggleChange={onPayableToSupplierToggleChange}
            onPaymentMethodChange={onPaymentMethodChange}
            onPayToSelected={onPayToSelected}
            onPrintChequeChange={onPrintChequeChange}
            onSelectExpenseType={onSelectExpenseType}
            onSupplierPaidChange={onSupplierPaidChange}
            onSupplierPaidToggleChange={onSupplierPaidToggleChange}
            onSupplierSelected={onSupplierSelected}
            onUpdateActivityField={onUpdateActivityField}
            onUpdateBillableField={onUpdateBillableField}
            onUpdateField={onUpdateField}
            onUpdateMatterField={onUpdateMatterField}
            onUpdateSubjectField={onUpdateSubjectField}
            onUpdateTaskField={onUpdateTaskField}
            onUpdateTaxField={onUpdateTaxField}
            onUpdateOutputTaxField={onUpdateOutputTaxField}
          />
        )
      }
      footer={
        <div className={Styles.modalFooter}>
          <div className={classnames(Styles.formButtonsGroupContainer, !isNewExpense && Styles.singleButton)}>
            <Button
              className={Styles.formButtonContainer}
              disabled={isSaveOrUpdateButtonDisabled}
              onClick={onFormSubmit}
            >
              {isNewExpense ? 'Save' : 'Update'}
            </Button>
            {isNewExpense && (
              <Button
                className={Styles.formButtonContainer}
                type={buttonTypes.secondary}
                disabled={isSaveAndNewDisabled}
                onClick={() => onFormSubmit({ saveAndNew: true })}
              >
                Save & New
              </Button>
            )}
          </div>
          <div className={Styles.warningGroupContainer}>
            {isOnDraftInvoice && invoiceNumber && (
              <div className={Styles.warningContainer}>
                <Icon type="alert-1" className={Styles.warningIcon} />
                <span>On draft invoice #{invoiceNumber}</span>
              </div>
            )}
            {!isOnFinalisedInvoice && isPaidExpense && (
              <div className={Styles.warningContainer}>
                <Icon type="alert-1" className={Styles.warningIcon} />
                <span className="warning-text">
                  {anticipatedDisbursementsEnabled
                    ? `This ${t('expense')} has been paid to the supplier. Editing is restricted.`
                    : `This ${t('expense')} is on an ${t('operatingCheque')}. Editing is restricted.`}
                </span>
              </div>
            )}
          </div>
        </div>
      }
      onModalClose={onModalClose}
    />
  );
};

ExpenseModal.propTypes = {
  invoiceNumber: PropTypes.number,
  isFormDisabled: PropTypes.bool.isRequired,
  /** Modal specific */
  isLoading: PropTypes.bool.isRequired,
  isNewExpense: PropTypes.bool.isRequired,
  isOnDraftInvoice: PropTypes.bool.isRequired,
  isSaveOrUpdateButtonDisabled: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  // Callbacks
  onFormSubmit: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  /** Form specific */
  activitiesGroupArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  anticipatedDisbursementsEnabled: PropTypes.bool.isRequired,
  chequeNumberDuplicationWarningMessage: PropTypes.string.isRequired,
  defaultMatterSummaries: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultPayToOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultSupplierContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  costTypeButtonList: PropTypes.arrayOf(PropTypes.object),
  expenseTypeButtonList: PropTypes.arrayOf(PropTypes.object),
  formData: PropTypes.object.isRequired,
  formDataMappedObjects: PropTypes.shape({
    activity: PropTypes.object,
  }).isRequired,
  formErrors: PropTypes.object.isRequired,
  isActivityFieldDisabled: PropTypes.bool.isRequired,
  isChequeReferenceFieldDisabled: PropTypes.bool.isRequired,
  isLoadingAvailableOperatingChequeNumber: PropTypes.bool.isRequired,
  isOnFinalisedInvoice: PropTypes.bool.isRequired,
  isPaidExpense: PropTypes.bool.isRequired,
  isPaymentDetailsDisabled: PropTypes.bool.isRequired,
  isPaymentToggleDisabled: PropTypes.bool.isRequired,
  isSaveAndNewDisabled: PropTypes.bool.isRequired,
  isSupplierDetailsDisabled: PropTypes.bool,
  isTaskFieldDisabled: PropTypes.bool.isRequired,
  matterSummaries: PropTypes.arrayOf(PropTypes.object).isRequired,
  matterSummariesDataLoading: PropTypes.bool.isRequired,
  matterSummariesHasMore: PropTypes.bool.isRequired,
  paymentMethodOptions: PropTypes.arrayOf(PropTypes.object),
  payToContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  payToContactOptionsDataLoading: PropTypes.bool.isRequired,
  payToContactOptionsHasMore: PropTypes.bool.isRequired,
  provideDeleteExpenseLink: PropTypes.bool.isRequired,
  operatingChequePrintSettings: PropTypes.PropTypes.shape({
    id: PropTypes.string.isRequired,
    printingActive: PropTypes.bool.isRequired,
  }).isRequired,
  region: PropTypes.oneOf(Object.values(regionType)).isRequired,
  showChequeMemoField: PropTypes.bool,
  showPayableToSupplierToggle: PropTypes.bool,
  showPrintingMethodField: PropTypes.bool.isRequired,
  showTaskField: PropTypes.bool.isRequired,
  showTaxFields: PropTypes.bool.isRequired,
  showDisplayWithFeesField: PropTypes.bool.isRequired,
  displayWithFeesDisabled: PropTypes.bool.isRequired,
  staffMemberOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  subjectOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  supplierContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  supplierContactOptionsDataLoading: PropTypes.bool.isRequired,
  supplierContactOptionsHasMore: PropTypes.bool.isRequired,
  tasksGroupArray: PropTypes.arrayOf(PropTypes.object),
  // inline add contact
  isUtbmsEnabledForFirm: PropTypes.bool.isRequired,
  showAddPayToContactForm: PropTypes.bool.isRequired,
  showAddSupplierContactForm: PropTypes.bool.isRequired,
  setShowAddPayToContactForm: PropTypes.func.isRequired,
  setShowAddSupplierContactForm: PropTypes.func.isRequired,
  // Callbacks
  onAttachmentFileChange: PropTypes.func.isRequired,
  onChequePrintingMethodChange: PropTypes.func.isRequired,
  onSelectCostType: PropTypes.func.isRequired,
  onChequeReferenceFieldUpdate: PropTypes.func.isRequired,
  onDeleteExpense: PropTypes.func.isRequired,
  onExpensePaymentDetailsFieldUpdate: PropTypes.func.isRequired,
  onFetchMatterSummaries: PropTypes.func.isRequired,
  onFetchMoreMatterSummaries: PropTypes.func.isRequired,
  onFetchMorePayToContactOptions: PropTypes.func.isRequired,
  onFetchMoreSupplierContactOptions: PropTypes.func.isRequired,
  onFetchPayToContactOptions: PropTypes.func.isRequired,
  onFetchSupplierContactOptions: PropTypes.func.isRequired,
  onNavigateToInvoice: PropTypes.func.isRequired,
  onOperatingChequeFieldUpdate: PropTypes.func.isRequired,
  onOperatingChequePrintOptionsFieldUpdate: PropTypes.func.isRequired,
  onPayableToSupplierToggleChange: PropTypes.func.isRequired,
  onPaymentMethodChange: PropTypes.func,
  onPayToSelected: PropTypes.func.isRequired,
  onPrintChequeChange: PropTypes.func,
  onSelectExpenseType: PropTypes.func,
  onSupplierPaidChange: PropTypes.func,
  onSupplierPaidToggleChange: PropTypes.func.isRequired,
  onSupplierSelected: PropTypes.func,
  onUpdateActivityField: PropTypes.func.isRequired,
  onUpdateBillableField: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  onUpdateMatterField: PropTypes.func.isRequired,
  onUpdateSubjectField: PropTypes.func.isRequired,
  onUpdateTaskField: PropTypes.func.isRequired,
  onUpdateTaxField: PropTypes.func.isRequired,
  onUpdateOutputTaxField: PropTypes.func.isRequired,
};

ExpenseModal.defaultProps = {
  costTypeButtonList: undefined,
  expenseTypeButtonList: undefined,
  invoiceNumber: undefined,
  isSupplierDetailsDisabled: undefined,
  paymentMethodOptions: undefined,
  showChequeMemoField: undefined,
  showPayableToSupplierToggle: undefined,
  tasksGroupArray: undefined,
  // Callbacks
  onPaymentMethodChange: undefined,
  onPrintChequeChange: undefined,
  onSelectExpenseType: undefined,
  onSupplierPaidChange: undefined,
  onSupplierSelected: undefined,
};

ExpenseModal.displayName = 'ExpenseModal';
