import { constants as invoiceSettingsConstants, isValidLines, applySupplementaryTablesSettingToTemplate } from '@sb-billing/business-logic/invoice-settings';
import { fileSize } from '@sb-itops/business-logic/files/entities/constants';
import { getInvoiceDetailRequestMacros } from '@sb-billing/business-logic/description-on-demand/get-invoice-detail-request-macros';

import { getTemplateById, getApplicationDefault, getFirmDefault, saveTemplate, getList as getInvoiceTemplates }  from '@sb-billing/redux/invoice-settings-template';
import { operations, selectors }  from '@sb-billing/redux/invoice-settings-template-files';
import { getSelectedTemplateId, selectTemplate, getTemplateById as getLocalTemplateById } from 'web/redux/route/home-settings-invoice';
import { store } from '@sb-itops/redux';
import { featureActive } from '@sb-itops/feature';
import uuid from '@sb-itops/uuid';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { surchargeApplyToValues, surchargeTypeValues, defaultSurchargeSettings, isSurchargeValid as _isSurchargeValid } from '@sb-billing/business-logic/invoice-surcharge';
import { fileTypeSignature } from '@sb-itops/business-logic/files/entities/constants';
import { getFileSignature, isValidByFileTypeSignatures } from '@sb-itops/business-logic/files/services';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getRegion } from '@sb-itops/region';
import {
  getBankAccountStrategy,
  bankAccountStrategies,
} from '@sb-billing/business-logic/bank-account/entities/strategies';
import { getOperatingAccount } from '@sb-billing/redux/bank-account';

const { getState, getInvoiceSettingsTemplateFilesById } = selectors;
const {
  uploadAttachmentP,
  saveInvoiceSettingsTemplateFiles,
  opdateCache,
  rollbackOpdateCache,
} = operations;

// A magical constant ID that means the current selected template doesn't already exist and is a "Create New"
const ADD_NEW_ID = 'add-new2';

angular.module('sb.billing.webapp').controller('SbInvoiceSettingsController', function ($scope, $state, sbInvoiceSettingsService, sbGstTaxSettingsService, sbLoggerService, sbMessageDisplayService,
  sbInvoiceSettingsSampleDataService, sbLocalisationService) {
  const that = this;
  const log = sbLoggerService.getLogger('SbInvoiceSettingsController');

  that.model = {
    basic: {
      pageMargins: invoiceSettingsConstants.defaultPageMargins,
    },
    invoices: {},
    einvoices: {},
  };
  that.view = {
    fonts: invoiceSettingsConstants.fonts,
    firmNameFontSizes: invoiceSettingsConstants.firmNameFontSizes,
    showRateLabel: hasFacet(facets.displayExpenseWithFees) ? 'Show Rate/Price' : 'Show Rate',
    showHoursLabel: hasFacet(facets.displayExpenseWithFees) ? 'Show Hours/Qty' : 'Show Hours',
    showHoursSummaryLabel: hasFacet(facets.displayExpenseWithFees) ? 'Show Hours/Qty Summary' : 'Show Hours Summary',
  };

  let defaultTemplate = null;

  defaultTemplate = getFirmDefault() || getApplicationDefault();

  // This view.selectedTemplateId param is only used for passing into the legacy sb-invoice-logo in invoice-settings-basic.html
  // Every other reference to view.selectedTemplateId has been changed to getSelectedTemplateId() which references the redux store
  that.view.selectedTemplateId = getSelectedTemplateId() || defaultTemplate.id;
  if (!getSelectedTemplateId()) {
    selectTemplate(defaultTemplate.id);
  }

  that.previewModel = {};

  const entryDisplayOptions = invoiceSettingsConstants.entryDisplayOptions;

  that.submit = submit;
  that.syncInputText = syncInputText;
  that.updateView = updateView;
  that.updateField = updateField;
  that.titleLine1OptionChanged = titleLine1OptionChanged;
  that.titleLine1TextChanged = titleLine1TextChanged;
  that.titleLine2OptionChanged = titleLine2OptionChanged;
  that.titleLine2TextChanged = titleLine2TextChanged;
  that.onFocusNumberInput = onFocusNumberInput;
  that.onChangeShowAccountSummary = onChangeShowAccountSummary;
  that.onSelectTemplate = onSelectTemplate;
  that.showInititialInvoiceNumber = showInititialInvoiceNumber;
  that.generatedTemplateId = undefined;
  that.lastSavedState = undefined;
  that.reactQuillChanged = false;
  that.unsavedChanges = false;
  that.onDiscardCancel = onDiscardCancel;
  that.onDiscardConfirm = onDiscardConfirm;
  that.saving = false;
  that.onFirmDetailsChange = onFirmDetailsChange;
  that.onFooterTextChange = onFooterTextChange;
  that.onChangeEnableDescriptionOnDemand = onChangeEnableDescriptionOnDemand;
  that.errors = {
    firmDetails: false,
    footerText: false,
  };
  that.firmDetailsError = firmDetailsError;
  that.footerTextError = footerTextError;
  that.uploadAttachment = uploadAttachment;
  that.deleteAttachment = deleteAttachment;
  that.disableAttachmentUpload = disableAttachmentUpload;
  that.disableAttachmentDelete = disableAttachmentDelete;
  that.dodMacros = getInvoiceDetailRequestMacros();
  that.selectedEinvoiceMacro = that.dodMacros[0].name;
  that.setSelectedEinvoiceMacro = setSelectedEinvoiceMacro;
  that.onEinvoiceMacroInserted = onEinvoiceMacroInserted;

  that.onSurchargeTypeChange = onSurchargeTypeChange;
  that.onSurchargePercentageChange = onSurchargePercentageChange;
  that.onSurchargeAmountChange = onSurchargeAmountChange;
  that.onSurchargeApplyToChange = onSurchargeApplyToChange;
  that.onSurchargeLabelChange = onSurchargeLabelChange;
  that.isSurchargeValid = isSurchargeValid;

  that.t = sbLocalisationService.t;

  that.onSignatureNameChange = onSignatureNameChange;
  that.updatePreviewData = updatePreviewData;

  that.descriptionOnDemandFeatureEnabled = featureActive('BB-5725') && featureActive('BB-6865');
  that.getSelectedTemplateIdOrLocalTemplateIdIfNew = getSelectedTemplateIdOrLocalTemplateIdIfNew;
  // N.B. This is separate to tax support, check facet invoiceHideTaxDisplay for more details
  that.taxDisplaySettingsEnabled = featureActive('BB-14974') && hasFacet(facets.tax) && hasFacet(facets.invoiceHideTaxDisplay);

  updateView();

  $scope.uploadedFiles = {};
  that.isUploadingAttachment = false;
  that.isDeletingAttachment = false;

  $scope.$watch('templateId', (newVal, oldVal) => {
    if (newVal && oldVal && newVal !== oldVal) {
      updateView();
    }
  });

  // On first load for a firm the template is set to placeholder id: "application-default"
  // But on first sync an actual template is created automatically with its own ID
  // We need to listen for when this new template is created and point this angular page at the new ID instead of the placeholder
  // Otherwise if the client edits and saves the template it creates a new default template since the ids are different
  const unsubscribeFromStore = store.subscribe(_.debounce(() => {
    updateTemplateListData();
    $scope.$applyAsync();
  }, 100));

  const updateTemplateListData = () => {
    const templateList = getInvoiceTemplates();
    const selectedTemplateId = getSelectedTemplateId();

    // If selected template doesn't exist, set selected to firm default or application default
    // Unless they click add new in which case it doesn't exist but we don't want to set to default
    if (!templateList.find(t => t.id === selectedTemplateId) && selectedTemplateId !== ADD_NEW_ID) {
      const defaultTemplateId = (getFirmDefault() || getApplicationDefault()).id
      if (defaultTemplateId && templateList.find(t => t.id === defaultTemplateId)) {
        that.view.selectedTemplateId = defaultTemplateId;
        selectTemplate(defaultTemplateId);
      } else {
        // select first in list if no default or default not in list
        // shouldn't happen but just in case
        if (templateList.length > 0) {
          that.view.selectedTemplateId = templateList[0].id;
          selectTemplate(templateList[0].id);
        } 
      }
    }
  }

  const deregisterFns = [
    $scope.$on('$stateChangeStart', (event, toState, toParams) => {
      if (that.unsavedChanges) {
        event.preventDefault();
        that.belayedAction = () => $state.go(toState, toParams);
        that.showDiscardDialog = true;
        return;
      }
    }),
    $scope.$on('smokeball-data-update-sbInvoiceSettingsService', updateView),
    $scope.$watch('invoiceSettings.model', updatePreviewData, true),
    $scope.$watch('invoiceSettings.model', checkUnsaved, true),
    $scope.$on('$destroy', deregister)
  ];

  function deregister() {
    selectTemplate(undefined);
    deregisterFns.forEach(fn => fn());
    unsubscribeFromStore();
  }

  function checkUnsaved () {
    // If it is new template unsaved changes are always set to true, to enable save button
    const template = getTemplateById(getSelectedTemplateId());
    if (template && template.createNew) {
      that.unsavedChanges = true;
      return;
    }

    const currentState = constructData(that.model);
    that.unsavedChanges = !_.isEqual(that.lastSavedState, currentState);
  }

  function updateField (modelPath, value) {
    _.set(that, modelPath, value);
    if (modelPath === 'model.basic.logo') {
      if (getSelectedTemplateId() === defaultTemplate.id) {
        that.model.basic.hasLogo = false;
      } else {
        that.model.basic.hasLogo = !!value;
      }
    }
  }

  function getSelectedTemplateIdOrLocalTemplateIdIfNew() {
    const selectedTemplateId = getSelectedTemplateId();
    if (selectedTemplateId === ADD_NEW_ID) {
      return that.generatedTemplateId;
    }
    return selectedTemplateId;
  }

  function titleLine1OptionChanged(value) {
    that.model.invoices.titleLine1Option = value;
  }

  function titleLine1TextChanged(value) {
    that.model.invoices.titleLine1CustomText = value;
  }

  function titleLine2OptionChanged(value) {
    that.model.invoices.titleLine2Option = value;
  }

  function titleLine2TextChanged(value) {
    that.model.invoices.titleLine2CustomText = value;
  }

  function onSurchargeTypeChange(newValue) {
    that.model.invoices.surcharge.type = newValue;
  }

  function onSurchargePercentageChange(newValue) {
    const newSurchargeValue = newValue || 0;

    that.model.invoices.surcharge.percentageBp = newSurchargeValue;
  }

  function onSurchargeAmountChange(newValue) {
    const newSurchargeValue = newValue || 0;
  
    that.model.invoices.surcharge.fixedSurcharge = newSurchargeValue;
  }

  function onSurchargeApplyToChange(newValue) {
    that.model.invoices.surcharge.applyTo = newValue;
  }

  function onSurchargeLabelChange(newValue) {
    that.model.invoices.surcharge.description = newValue;
  }

  function isSurchargeValid() {
    if(!featureActive('BB-7270')) {
      return true;
    }
    
    return _isSurchargeValid(that.model.invoices.surcharge);
  }

  function onSignatureNameChange(newValue) {
    that.model.invoices.signatureName = newValue;
  }

  function updateView() {
    that.saving = false;
    let currentSettings = sbInvoiceSettingsService.get();
    that.currentSettingsLoaded = !!currentSettings;

    const template = getTemplateById(getSelectedTemplateId());

    if (template.createNew) {
      // When we click 'Add New' we generate a template ID and then pass that to the button group
      // This template ID is stored in the home-invoice-settings redux state which is for LOCAL state (unsaved) with the name
      const templateId = that.generatedTemplateId || uuid();
      that.generatedTemplateId = templateId;
      that.unsavedChanges = true;
    }
    currentSettings = template.settings;

    if(_.isEmpty(currentSettings)){
      return;
    }

    //populate basic data
    that.model.basic.headingFont =  getFont(that.view.fonts, currentSettings.headingFont);
    that.model.basic.bodyFont =     getFont(that.view.fonts, currentSettings.bodyFont);
    that.model.basic.firmDetails =  currentSettings.letterhead;
    that.model.basic.firmNameFont = getFont(that.view.fonts, currentSettings.firmNameFont) || that.model.firmNameFont;
    that.model.basic.firmNameFontSize = _.find(that.view.firmNameFontSizes, (fontSize) => fontSize.value === currentSettings.firmNameFontSize) || that.view.firmNameFontSizes[0];
    that.model.basic.letterheadLayout = currentSettings.letterHeadLayout || 'lh-justified-left'; //TODO change this to be 'letterheadLayout'
    that.model.basic.gridStyle =        currentSettings.gridStyle || 'modern';

    that.model.basic.pageMargins = currentSettings.pageMargins ? {
      top: currentSettings.pageMargins.top / 100, // TODO: decimal-operation
      right: currentSettings.pageMargins.right / 100, // TODO: decimal-operation
      bottom: currentSettings.pageMargins.bottom /100 , // TODO: decimal-operation
      left: currentSettings.pageMargins.left / 100, // TODO: decimal-operation
      unit: 'in',
    } : that.model.basic.pageMargins;

    that.model.basic.hasLogo = currentSettings.hasLogo;
    that.model.basic.logo = undefined;

    //populate invoicing settings
    that.view.interestTextType =               that.view.interestTextType || (currentSettings.overdueText ? 'CUSTOM' : 'NONE');
    that.model.invoices.overdueText =          that.view.interestTextType === 'CUSTOM' ? currentSettings.overdueText : undefined;
    that.model.invoices.paymentDelayDays =     currentSettings.paymentDueDays;
    that.model.invoices.initialInvoiceNumber = currentSettings.initialInvoiceNumber || currentSettings.initialInvoiceNumber === 0
      ? currentSettings.initialInvoiceNumber
      : (defaultTemplate && defaultTemplate.settings.initialInvoiceNumber);
    that.model.invoices.titleLine2Option =     currentSettings.titleLine2Option;
    that.model.invoices.titleLine2CustomText = currentSettings.titleLine2CustomText;
    that.model.invoices.sampleMatterDescription = '[matter description will appear here]';
    that.model.invoices.sampleMatterTitle = '[matter title will appear here]';
    that.model.invoices.titleLine1Option =     currentSettings.titleLine1Option || 'MatterTitle';
    that.model.invoices.titleLine1CustomText = currentSettings.titleLine1CustomText;

    if (featureActive('BB-7270')) {
      that.model.invoices.surcharge = currentSettings.surcharge
        ? { ...currentSettings.surcharge }
        : {
          type: surchargeTypeValues.NONE,
          percentageBp: 0,
          fixedSurcharge: 0,
          description: 'Surcharge',
          applyTo: surchargeApplyToValues.FEES_AND_EXPENSES,
        };
    }

    if (featureActive('BB-10409')) {
      that.model.invoices.signatureName = currentSettings.signatureName || '';
    }

    if (currentSettings.defaultLayout) {
      updateFeeLineItemDisplay(currentSettings);
      updateExpenseLineItemDisplay(currentSettings);

      that.model.invoices.feeSummaryLineDesc =           currentSettings.defaultLayout.feeSummaryLineDescription;
      that.model.invoices.expenseSummaryLineDesc =       currentSettings.defaultLayout.expenseSummaryLineDescription;
      that.model.invoices.showStaffName =                currentSettings.defaultLayout.showStaffNameOnEntries;

      that.model.invoices.showRateOnEntries =     currentSettings.defaultLayout.showRateOnEntries;
      that.model.invoices.showDurationOnEntries = currentSettings.defaultLayout.showDurationOnEntries;

      that.model.invoices.showDescriptionForEntries = currentSettings.defaultLayout.showDescriptionForEntries;
      that.model.invoices.includeNonBillableItems =   currentSettings.defaultLayout.includeNonBillableItems;
    }

    that.model.invoices.footerText = currentSettings.footer;
    that.model.invoices.showHoursSummary = currentSettings.invoiceAdditionalOptions.showHoursSummary;

    const updatedTemplateSettings = applySupplementaryTablesSettingToTemplate({
      invoiceSettingsTemplate: that.model.invoices,
      invoiceSettingsAdditionalOptions: currentSettings.invoiceAdditionalOptions,
      invoiceSettingsSectionOptions: currentSettings.sectionOptions,
      invoiceSupplementaryTablesPageBreakEnabled: featureActive('BB-12385'),
      timekeeperRoleEnabled: featureActive('BB-10855'),
    });
    that.model.invoices = updatedTemplateSettings;

    if (featureActive('BB-12394')) {
      that.model.invoices.showItemNumbers = currentSettings.invoiceAdditionalOptions.showItemNumbers;
    }

    if(featureActive('BB-10855')) {
      that.model.invoices.showTimekeeperRole = currentSettings.invoiceAdditionalOptions.showSummaryForTimekeepers ? currentSettings.invoiceAdditionalOptions.showTimekeeperRole : false;
    }

    that.model.invoices.showFeeTotal = !currentSettings.invoiceAdditionalOptions.hideFeeTotal;
    that.model.invoices.showFeeTotalSummary = !currentSettings.invoiceAdditionalOptions.hideFeeTotalSummary;
    
    if(featureActive('BB-6908')) {
      that.model.invoices.showRetainer = currentSettings.invoiceAdditionalOptions.showRetainer || false;
    }

    if(featureActive('BB-10092')) {
      that.model.invoices.appendExpenseReceipts = currentSettings.invoiceAdditionalOptions.appendExpenseReceipts || false;
    }

    if (featureActive('BB-9084')) {
      that.model.invoices.hideDueDate = !!currentSettings.invoiceAdditionalOptions.hideDueDate;
    }

    if (hasFacet(facets.tax) && featureActive('BB-10356')) {
      // If show fee total is disabled, we don't show these columns
      that.model.invoices.hideAmountAndTaxPerLineItem = !currentSettings.invoiceAdditionalOptions.hideFeeTotal ? !!currentSettings.invoiceAdditionalOptions.hideAmountAndTaxPerLineItem : true;
    }

    if (that.taxDisplaySettingsEnabled) {
      that.model.invoices.hideTaxDisplay = !!currentSettings.invoiceAdditionalOptions.hideTaxDisplay;
    }

    if (featureActive('BB-5725') && featureActive('BB-6865')) {
      //populate e-invoicing settings
      if (currentSettings.eInvoiceOptions) {
        that.model.einvoices.enableDescriptionOnDemand = !!currentSettings.eInvoiceOptions.enableDescriptionOnDemand;
        that.model.einvoices.descriptionOnDemandLineItemText = currentSettings.eInvoiceOptions.descriptionOnDemandLineItemText;
      } else {
        that.model.einvoices = getApplicationDefault().settings.eInvoiceOptions;
      }
    } else {
      that.model.einvoices = undefined;
    }
    
    that.view.firmDetails = that.model.basic.firmDetails;
    that.view.footerText = that.model.invoices.footerText;

    const templateFiles = getInvoiceSettingsTemplateFilesById(getState(), { templateId: getSelectedTemplateIdOrLocalTemplateIdIfNew() });
    that.model.invoices.attachment = templateFiles && templateFiles.attachment;
    that.pdfNotFound = !(templateFiles && templateFiles.attachment);

    updatePreviewData();

    that.lastSavedState = constructData();
    that.reactQuillChanged = false;
  }

  function updateFeeLineItemDisplay(currentSettings) {
    const feeLineItemConfig = invoiceSettingsConstants.lineItemConfig[currentSettings.defaultLayout.feeLineItemConfiguration];

    that.model.invoices.appendFeeEntries = false;
    if (!feeLineItemConfig) {
      log.warn('Fee line item config not recognised', currentSettings.defaultLayout.feeLineItemConfiguration);
    } else {
      switch (feeLineItemConfig.toUpperCase()) {
        case entryDisplayOptions.list:
          that.model.invoices.feesLineDisplayType = 'list';
          break;
        case entryDisplayOptions.summary:
          that.model.invoices.feesLineDisplayType = 'summary';
          break;
        case entryDisplayOptions.summaryWithList:
          that.model.invoices.feesLineDisplayType = 'summary';
          that.model.invoices.appendFeeEntries = true;
          break;
        default:
          log.warn('Unexpected fee line config', feeLineItemConfig.toUpperCase());
      }
    }
  }

  function updateExpenseLineItemDisplay(currentSettings) {
    const expenseLineItemConfig = invoiceSettingsConstants.lineItemConfig[currentSettings.defaultLayout.expenseLineItemConfiguration];

    that.model.invoices.appendExpenseEntries = false;

    if (!expenseLineItemConfig) {
      log.warn('Expense line item config not recognised', currentSettings.defaultLayout.expenseLineItemConfiguration);
    } else {
      switch (expenseLineItemConfig.toUpperCase()) {
        case entryDisplayOptions.list:
          that.model.invoices.expenseLineDisplayType = 'list';
          break;
        case entryDisplayOptions.summary:
          that.model.invoices.expenseLineDisplayType = 'summary';
          break;
        case entryDisplayOptions.summaryWithList:
          that.model.invoices.expenseLineDisplayType = 'summary';
          that.model.invoices.appendExpenseEntries = true;
          break;
        default:
          log.warn('Unexpected expense line config', expenseLineItemConfig.toUpperCase());
      }
    }
  }

  function resolveLineItemConfig(lineDisplayType, appendEntries) {
    if (lineDisplayType === 'list') {
      return 0;
    }

    if (appendEntries) {
      return 2;
    }

    return 1;
  }

  // converts the .model object into the form that the endpoints expect
  function constructData() {
    const data = {
      headingFont:      that.model.basic.headingFont.value,
      bodyFont:         that.model.basic.bodyFont.value,
      letterhead:       that.model.basic.firmDetails && that.model.basic.firmDetails.replace(/p>/g, 'div>'),
      firmNameFont:     that.model.basic.firmNameFont.value,
      firmNameFontSize: that.model.basic.firmNameFontSize.value,
      letterHeadLayout: that.model.basic.letterheadLayout,
      gridStyle:        that.model.basic.gridStyle,
      pageMargins: {
        top:    Math.floor(that.model.basic.pageMargins.top * 100),    // TODO: decimal-operation
        right:  Math.floor(that.model.basic.pageMargins.right * 100),  // TODO: decimal-operation
        bottom: Math.floor(that.model.basic.pageMargins.bottom * 100), // TODO: decimal-operation
        left:   Math.floor(that.model.basic.pageMargins.left * 100),   // TODO: decimal-operation
        unit:   that.model.basic.pageMargins.unit,
      },
      hasLogo:              that.model.basic.hasLogo,
      paymentDueDays:       that.model.invoices.paymentDelayDays,
      initialInvoiceNumber: that.model.invoices.initialInvoiceNumber,
      titleLine2Option:     that.model.invoices.titleLine2Option,
      titleLine2CustomText: that.model.invoices.titleLine2Option === 'Custom' ? that.model.invoices.titleLine2CustomText : '',
      titleLine1Option:     that.model.invoices.titleLine1Option,
      titleLine1CustomText: that.model.invoices.titleLine1Option === 'Custom' ? that.model.invoices.titleLine1CustomText : '',
      sampleMatterDescription: that.model.invoices.sampleMatterDescription,
      sampleMatterTitle: that.model.invoices.sampleMatterTitle,
      defaultLayout: {
        expenseLineItemConfiguration:  resolveLineItemConfig(that.model.invoices.expenseLineDisplayType, that.model.invoices.appendExpenseEntries),
        expenseSummaryLineDescription: that.model.invoices.expenseSummaryLineDesc,
        feeLineItemConfiguration:      resolveLineItemConfig(that.model.invoices.feesLineDisplayType, that.model.invoices.appendFeeEntries),
        feeSummaryLineDescription:     that.model.invoices.feeSummaryLineDesc,
        includeNonBillableItems:       that.model.invoices.includeNonBillableItems,
        showDescriptionForEntries:     that.model.invoices.showDescriptionForEntries,
        showStaffNameOnEntries:        that.model.invoices.showStaffName,
      },
      invoiceAdditionalOptions: {
        showHoursSummary:       that.model.invoices.showHoursSummary,
        hideFeeTotal:           !that.model.invoices.showFeeTotal,
        hideFeeTotalSummary:    !that.model.invoices.showFeeTotalSummary,
        showItemNumbers:        featureActive('BB-12394') ? that.model.invoices.showItemNumbers : undefined,
      },
      sectionOptions: {},
      overdueText: that.model.invoices.overdueText,
      footer:      that.model.invoices.footerText,
    };

    if (featureActive('BB-6908')) {
      data.invoiceAdditionalOptions.showRetainer = that.model.invoices.showRetainer;
    }

    if (featureActive('BB-10092')) {
      data.invoiceAdditionalOptions.appendExpenseReceipts = that.model.invoices.appendExpenseReceipts;
    }

    if (featureActive('BB-9084')) {
      data.invoiceAdditionalOptions.hideDueDate = that.model.invoices.hideDueDate;
    }

    if (hasFacet(facets.tax)) {
      data.taxSettings = sbGstTaxSettingsService.getTaxSettings();

      if (featureActive('BB-10356')) {
        // If show fee total is disabled, we don't show these columns
        data.invoiceAdditionalOptions.hideAmountAndTaxPerLineItem = that.model.invoices.showFeeTotal ? that.model.invoices.hideAmountAndTaxPerLineItem : true;
      }
    }

    if (that.taxDisplaySettingsEnabled) {
      data.invoiceAdditionalOptions.hideTaxDisplay = !!that.model.invoices.hideTaxDisplay;
    }

    if (featureActive('BB-12792') && getBankAccountStrategy(getRegion(), bankAccountStrategies.PAYMENT_DETAILS_ON_INVOICES)) {
      const operatingAccount = getOperatingAccount(store.getState());
      if (operatingAccount) {
        data.operatingAccount = operatingAccount;
      }
    }

    data.defaultLayout.showRateOnEntries =     that.model.invoices.showRateOnEntries;
    data.defaultLayout.showDurationOnEntries = that.model.invoices.showDurationOnEntries;

    // Supplementary Tables
    // Save those value in invoiceAdditionalOptions for backwards compatibility
    data.invoiceAdditionalOptions.showSummaryForTimekeepers = that.model.invoices.showProfessionalFeeSummary;
    if (featureActive('BB-10855')) {
      data.invoiceAdditionalOptions.showTimekeeperRole = that.model.invoices.showProfessionalFeeSummary ? that.model.invoices.showTimekeeperRole : false;
    }
    data.invoiceAdditionalOptions.hidePriorBalance = !that.model.invoices.showPriorBalances;
    data.invoiceAdditionalOptions.hidePaymentSummary = that.model.invoices.hidePaymentSummary;
    data.invoiceAdditionalOptions.showInvoiceSummary = that.model.invoices.showInvoiceSummary;
    data.invoiceAdditionalOptions.showAccountSummary = that.model.invoices.showAccountSummary;
    data.invoiceAdditionalOptions.showTransactionHistory = that.model.invoices.showTxnHistoryPerAccount;

    if (featureActive('BB-12385')) {
      data.sectionOptions.feeSummary = {
        showSection: that.model.invoices.showProfessionalFeeSummary,
        lineBreakBefore: that.model.invoices.newPageForProfessionalFeeSummary,
        ...(featureActive('BB-10855') ? { showTimekeeperRole: that.model.invoices.showProfessionalFeeSummary ? that.model.invoices.showTimekeeperRole : false } : {})
      }

      data.sectionOptions.priorBalances = {
        showSection: that.model.invoices.showPriorBalances,
        lineBreakBefore: that.model.invoices.newPageForPriorBalances
      }

      data.sectionOptions.paymentSummary = {
        showSection: !that.model.invoices.hidePaymentSummary,
        lineBreakBefore: that.model.invoices.newPageForPaymentSummary
      }

      data.sectionOptions.invoiceSummary = {
        showSection: that.model.invoices.showInvoiceSummary,
        lineBreakBefore: that.model.invoices.newPageForInvoiceSummary
      }

      data.sectionOptions.accountSummary = {
        showSection: that.model.invoices.showAccountSummary,
        lineBreakBefore: that.model.invoices.newPageForAccountSummary
      }
      data.sectionOptions.transactionHistory = {
        showSection: that.model.invoices.showTxnHistoryPerAccount,
        lineBreakBefore: that.model.invoices.newPageForTxnHistoryPerAccount
      }
    }


    if (featureActive('BB-5725') && featureActive('BB-6865')) {
      data.eInvoiceOptions = {
        enableDescriptionOnDemand: that.model.einvoices.enableDescriptionOnDemand,
        descriptionOnDemandLineItemText: that.model.einvoices.descriptionOnDemandLineItemText,
      };
    } else {
      data.eInvoiceOptions = undefined;
    }

    if (featureActive('BB-7270')) {
      const surcharge = that.model.invoices.surcharge;
      data.surcharge = { 
        type: surcharge.type,
        fixedSurcharge: surcharge.type === surchargeTypeValues.FIXED ? surcharge.fixedSurcharge || 0 : 0,
        percentageBp: surcharge.type === surchargeTypeValues.PERCENTAGE ? surcharge.percentageBp || 0 : 0,
        applyTo: surcharge.type !== surchargeTypeValues.NONE ? surcharge.applyTo : defaultSurchargeSettings.applyTo,
        description: surcharge.type !== surchargeTypeValues.NONE ? surcharge.description : defaultSurchargeSettings.description,
      };
    }

    if (featureActive('BB-10409')) {
      data.signatureName = that.model.invoices.signatureName || '';
    }

    data.templateId = getSelectedTemplateIdOrLocalTemplateIdIfNew();

    return data;
  }

  /**
   * @return {{success:Boolean, templateId:String}}
   * True/false and Id indicating whether the invoice settings/template was successfully saved.
   */
  async function submit() {
    that.saving = true;
    const data = constructData();
    log.info('saving invoice data : ' + JSON.stringify(data));

    const selectedTemplateId = getSelectedTemplateId();
    let newTemplate = undefined;

    // If template is newly created
    // Need to get local template information like the generated ID and the template name and then put that into the data to save
    if (selectedTemplateId === ADD_NEW_ID) {
      const localTemplate = getLocalTemplateById(that.generatedTemplateId);
      if (!(localTemplate && localTemplate.name)) {
        sbMessageDisplayService.error('Failed to save invoice template: Please enter a template name and try again.');
        that.saving = false;
        return { success: false, templateId: "" };
      }
      newTemplate = {
        id: that.generatedTemplateId,
        name: localTemplate && localTemplate.name,
        isDefault: false,
        createNew: true,
        settings: data,
      };
      // generate new uuid
      that.generatedTemplateId = uuid();
    } else {
      // If existing template just get the details from existing template and then put the new settings to those details
      const template = getTemplateById(selectedTemplateId);
      newTemplate = {
        id: selectedTemplateId,
        name: template && template.name,
        isDefault: template && template.isDefault,
        createNew: false,
        settings: data,
      };
    }

    // the new template id will equal `application-default` if no template has ever been saved.
    if (newTemplate.id === 'application-default') {
      newTemplate.id = uuid();
      selectTemplate(newTemplate.id);
      that.view.selectedTemplateId = newTemplate.id;
      newTemplate.isDefault = true;
    }

    try {
      await saveTemplate(newTemplate);
      sbMessageDisplayService.success(`Invoice template '${newTemplate.name}' saved`);
      that.lastSavedState = data;
      that.unsavedChanges = false;
      that.view.selectedTemplateId = newTemplate.id;
    } catch (err) {
      sbMessageDisplayService.error(
        `Failed to save invoice template '${newTemplate.name}'. Please check your connection and try again.`,
      );
      that.saving = false;
      return { success: false, templateId: newTemplate.id };
    }

    that.saving = false;
    return { success: true, templateId: newTemplate.id };
  }

  function updatePreviewData() {
    that.previewModel = sbInvoiceSettingsSampleDataService.getData(constructData());
    log.info('preview model:', that.previewModel);
  }

  function syncInputText(inputName, modelPath, inputText, isValid) {
    if (isValid) {
      _.set(that.model, modelPath, inputText);
    }
    that.invoiceSettingsForm[inputName].$setValidity(inputName, isValid);
  }

  function getFont(fonts, font) {
    const _font = fonts.find((option) => option.value === font);

    if (_.isEmpty(_font)) {
      return fonts[0];
    }
    return _font;
  }

  function onFocusNumberInput(event) {
    event.target.select();
  }

  function onChangeShowAccountSummary () {
    that.model.invoices.showTxnHistoryPerAccount = that.model.invoices.showTxnHistoryPerAccount && that.model.invoices.showAccountSummary;
    that.model.invoices.newPageForTxnHistoryPerAccount = that.model.invoices.newPageForTxnHistoryPerAccount && that.model.invoices.showAccountSummary;
  }

  function onDiscardCancel() {
    that.showDiscardDialog = false;
  }

  function onDiscardConfirm() {
    that.unsavedChanges = false;
    that.belayedAction();
  }

  function onSelectTemplate(id) {
    if (that.unsavedChanges) {
      that.belayedAction = () => that.onSelectTemplate(id);
      that.showDiscardDialog = true;
      return;
    } else {
      that.showDiscardDialog = false;
    }
    selectTemplate(id);
    that.view.selectedTemplateId = id;
    updateView();
  }

  function showInititialInvoiceNumber() {
    return defaultTemplate.id === getSelectedTemplateId();
  }

  function onChangeEnableDescriptionOnDemand(_name, enableDescriptionOnDemand) {
    that.model.einvoices.enableDescriptionOnDemand = enableDescriptionOnDemand;
  }

  function onFirmDetailsChange(value, delta, source) {
    if (source !== "user") {
      // Ignore any changes made by react-quill itself instead of user input
      return;
    }
    
    const parser = new DOMParser();
    const html = parser.parseFromString(value, "text/html");
    const isValid = isValidLines(html, invoiceSettingsConstants.firmDetailsMaxLines);
    that.errors.firmDetails = !isValid;
    that.view.firmDetails = value;
    if (isValid) {
      _.set(that.model, 'basic.firmDetails', value);
    }
  }

  const debouncedFooterTextChange = _.debounce((value) => {
    _.set(that.model, 'invoices.footerText', value);
  }, 300)

  function onFooterTextChange(value, delta, source) {
    if (source !== "user") {
      // Ignore any changes made by react-quill itself instead of user input
      return;
    }

    const parser = new DOMParser();
    const html = parser.parseFromString(value, "text/html")
    let isValid = isValidLines(html, invoiceSettingsConstants.footerTextMaxLines);

    that.errors.footerText = !isValid;
    that.view.footerText = value;

    if (isValid) {
      debouncedFooterTextChange(value);
    }
  }

  function firmDetailsError() {
    return that.errors.firmDetails;
  }

  function footerTextError() {
    return that.errors.footerText;
  }

  /**
   * Uploads pdf attachment
   */
  async function uploadAttachment() {
    if (!getSelectedTemplateIdOrLocalTemplateIdIfNew() || !$scope.uploadedFiles.invoiceAttachment) {
      return undefined;
    }

    if ($scope.uploadedFiles.invoiceAttachment.size > fileSize.THREE_MB) {
      sbMessageDisplayService.error(`Upload PDF attachment failed: Attachment size cannot exceed 3MB.`);
      return undefined;
    }

    const isPdfFile = await isAllowedFileType($scope.uploadedFiles.invoiceAttachment)
    if (!isPdfFile) {
      sbMessageDisplayService.error(`Upload PDF attachment failed: Attachment type must be valid PDF.`);
      return undefined;
    }

    const reader = new FileReader();

    that.isUploadingAttachment = true;
    reader.readAsDataURL($scope.uploadedFiles.invoiceAttachment);
    reader.onload = async () => {
      try {
        const attachment = await uploadAttachmentP({
          store,
          templateId: getSelectedTemplateIdOrLocalTemplateIdIfNew(),
          pdfFile: reader.result,
          fileName: $scope.uploadedFiles.invoiceAttachment.name,
          fetchPostP,
          opdateCache,
          rollbackOpdateCache,
        });

        that.model.invoices.attachment = attachment;
        sbMessageDisplayService.success(`PDF attachment '${$scope.uploadedFiles.invoiceAttachment.name}' saved`);
      } catch(err) {
        sbMessageDisplayService.error(`Upload PDF attachment failed: ${err.message}`);
      } finally {
        $scope.uploadedFiles = {};
        angular.element('.attachment-upload').val('');
        that.isUploadingAttachment = false;
      }
    }
  }

  async function isAllowedFileType(file) {
    const allowedSignatures = [fileTypeSignature.PDF];
    return file.arrayBuffer().then((fileBuffer) => {
      const fileSignature = getFileSignature(fileBuffer);
      return isValidByFileTypeSignatures({
        fileSignature,
        validFileTypeSignatures: allowedSignatures,
      });
    });
  }  

  function disableAttachmentUpload() {
    return that.isUploadingAttachment || that.isDeletingAttachment || _.isEmpty($scope.uploadedFiles);
  }

  /**
   * Deletes pdf attachment
   */
  async function deleteAttachment() {
    if (!getSelectedTemplateId()) {
      return undefined;
    }
    that.isDeletingAttachment = true;

    try {
      await saveInvoiceSettingsTemplateFiles({
        store,
        templateId: getSelectedTemplateIdOrLocalTemplateIdIfNew(),
        attachment: undefined,
        fetchPostP,
        opdateCache,
        rollbackOpdateCache,
      });

      that.isDeletingAttachment = false;
      sbMessageDisplayService.success(`PDF attachment '${that.model.invoices.attachment.fileName}' removed`);
      that.model.invoices.attachment = undefined;
    } catch (err) {
      sbMessageDisplayService.error(`Delete PDF attachment failed. err: ${err.message}`);
      that.isDeletingAttachment = false;
    }
  }

  function disableAttachmentDelete() {
    if (!getSelectedTemplateIdOrLocalTemplateIdIfNew()) {
      return true;
    }
    return !that.model.invoices.attachment;
  }

  function setSelectedEinvoiceMacro(name) {
    that.selectedEinvoiceMacro = name;
  }

  function onEinvoiceMacroInserted() {
    that.model.einvoices.descriptionOnDemandLineItemText += that.selectedEinvoiceMacro;
  }

});
