import * as authProviderClient from '@sb-itops/business-logic/authentication/services/sb-billing-auth-provider-client';
import { getRestApiUrl } from '@sb-itops/environment-config';
import { getLogger } from '@sb-itops/fe-logger';
import { featureActive } from '@sb-itops/feature';
import { store, flushPersistedStateP } from '@sb-itops/redux';
import { actions, selectors, operations } from '@sb-itops/redux/auth.2';
import { resourceIds } from '@sb-itops/business-logic/authorisation';

import { selectors as appSelectors } from 'web/redux/features/application-state';
import { productTierIdsByName } from '@sb-finance/business-logic/subscription/entities/constants';

const log = getLogger('user-session-management');
log.setLogLevel('info');

// Until we can figure out how to fully deal with some legacy behaviour, we need to propagate some auth events.
export const userSessionEvents = new EventTarget();

/**
 * loginP
 * Authenticates the username and password combination.
 * @param {string} username
 * @param {string} password
 */
export const loginP = async ({ username, password }) => {
  const loginFnP = () =>
    authProviderClient.loginP({
      authServiceUrl: getRestApiUrl(),
      username,
      password,
    });

  await store.dispatch(operations.loginP({ loginFnP }));
  await flushPersistedStateP();
};

/**
 * respondToChallengeP
 * Responds to a pending authentication challenge.
 * @param {*} challengeResponse
 */
export const respondToChallengeP = async ({ solution }) => {
  // The challenge object will be provided from redux state in the respondToChallenge operation.
  const respondToChallengeFnP = ({ challenge }) =>
    authProviderClient.respondToChallengeP({ serviceUrl: getRestApiUrl(), challenge, solution });
  await store.dispatch(operations.respondToChallengeP({ respondToChallengeFnP }));
  await flushPersistedStateP();
};

/**
 * refreshTokenP
 * Refreshes the UserIdentity currently in state in the auth redux feature.
 */
export const refreshTokenP = async () => {
  try {
    const refreshTokenFnP = ({ userIdentity }) =>
      authProviderClient.refreshTokenP({ serviceUrl: getRestApiUrl(), userIdentity });
    await store.dispatch(operations.refreshTokenP({ refreshTokenFnP }));
    await flushPersistedStateP();
  } catch (err) {
    // The swallowing of the exception here is deliberate, it shouldn't be propagted.
    // The reasoning is that ideally nothing else in the application should decide
    // what to do if a refresh attempt fails. If we could route directly to logout
    // here we would. Unfortunately, we have to raise an event which is handled by the
    // pre-authentication route as most of the tear-down logic lives in angular.
    log.warn('Failed to refresh token authentication token', err);
    userSessionEvents.dispatchEvent(new Event('failed-to-refresh-token'));
  }
};

/**
 * logoutP
 * Logs out the UserIdentity currently in state in the auth redux feature.
 */
export const logoutP = async () => {
  const logoutFnP = async ({ userIdentity }) => {
    await authProviderClient.logoutP({ serviceUrl: getRestApiUrl(), userIdentity });
  };

  try {
    await store.dispatch(operations.logoutP({ logoutFnP }));
  } catch (e) {
    log.error(`Problem logging out${e}`);
  } finally {
    log.info('flushing state');
    await flushPersistedStateP();
  }
};

/**
 * logoutP
 * Logs the UserIdentity out of all sessions across all devices.
 */
export const logoutAllP = async () => {
  const logoutFnP = async ({ userIdentity }) => {
    await authProviderClient.logoutAllP({ serviceUrl: getRestApiUrl(), userIdentity });
  };

  await store.dispatch(operations.logoutP({ logoutFnP }));
  await flushPersistedStateP();
};

/**
 * hackUserIdentity
 *
 * Useful function for debugging auth related issues. Should not be called directly.
 * Should be exposed on window.Smokeball in non-live envs.
 *
 * @param {Object} hackedIdentity A partial UserIdentity.
 */
export const hackUserIdentity = (hackedIdentity = {}) => {
  const currentUserIdentity = selectors.getUserIdentity(store.getState());
  log.warn('User identity is going to be hacked, current identity is', currentUserIdentity);

  const userIdentity = { ...currentUserIdentity, ...hackedIdentity };
  log.warn('New user identity being set to', userIdentity);

  store.dispatch(actions.forceUserIdentity({ userIdentity }));
};

// These convenience functions should be preferred for usage in the web app over directly calling the redux feature.
// Doing so has several benefits:
//   1. No need to worry about injecting state manually.
//   2. No need to change other parts of the application if the feature interface changes / or state managed moves away from redux
//   3. Protects against accidentally writing code that tightly couples to a specific Auth Provider.
// getProvider is deliberately hidden and getUserIdentity return value is reduced to achieve #3.
export const assumeUserIsAuthenticated = () => store.dispatch(actions.assumeUserIsAuthenticated());

export const getIsAuthenticated = () => selectors.isAuthenticated(store.getState());

export const getIsAuthenticationInProgress = () => selectors.isAuthenticationInProgress(store.getState());

export const getIsLoginInProgress = () => selectors.isLoginInProgress(store.getState());

export const getIsLoginChallenged = () => selectors.isLoginChallenged(store.getState());

export const getIsChallengeResponseInProgress = () => selectors.isChallengeResponseInProgress(store.getState());

export const getIsAuthRefreshInProgress = () => selectors.isAuthRefreshInProgress(store.getState());

export const getIsLogoutInProgress = () => selectors.isLogoutInProgress(store.getState());

export const getUserRegion = () => selectors.getUserRegion(store.getState());

export const getUserPermissions = () => appSelectors.getUserPermissions(store.getState());

export const hasBillingAccess = () => {
  if (!featureActive('BB-11975')) {
    return true;
  }

  const userPermissions = getUserPermissions();
  const billingPermission = userPermissions && userPermissions[resourceIds.BILLING_DATA];
  return billingPermission && billingPermission.isAuthorized;
};

export const getAuthToken = () => selectors.getAuthToken(store.getState());

export const getAccessToken = () => selectors.getAccessToken(store.getState());

export const getAuthenticationFailure = () => selectors.getAuthenticationFailure(store.getState());

export const getProductTier = () => selectors.getProductTier(store.getState());

export const isUserBillBoost = () => [productTierIdsByName.BILL, productTierIdsByName.BOOST].includes(selectors.getProductTier(store.getState()));

export const getIsFirmOwner = () => selectors.isFirmOwner(store.getState());

export const getIsInternalUser = () => selectors.isInternalUser(store.getState());

export const getIsMissingProductClaim = () => selectors.isMissingProductClaim(store.getState());

export const getUsername = () => selectors.getUsername(store.getState());

export const getUserId = () => selectors.getUserId(store.getState());

export const getAccountId = () => selectors.getAccountId(store.getState());

export const getOwningCompany = () => selectors.getOwningCompany(store.getState()) || appSelectors.getOwningCompanyInsecure(store.getState());

export const getUserIdentity = () => {
  // eslint-disable-next-line no-unused-vars
  const { provider, custom, ...commonFields } = selectors.getUserIdentity(store.getState()); // See above comment.
  return commonFields;
};

export const getChallenge = () => selectors.getChallenge(store.getState());
export const getChallengeType = () => selectors.getChallengeType(store.getState());
export const getPasswordPolicy = () => selectors.getPasswordPolicy(store.getState()); // Only present during a NEW_PASSWORD_REQUIRED challenge.
export const setLoginFailed = () => store.dispatch(actions.loginFailure());