import moment from 'moment';
import { getDebtorsFromInvoiceOrMatter } from '@sb-billing/business-logic/invoice/services';
import {
  defaultSurchargeSettings,
  determineSurchargeSettings,
  surchargeTypeValues,
} from '@sb-billing/business-logic/invoice-surcharge';
import { PrintNotApplicable } from '@sb-billing/business-logic/cheques';
import { getSplitBillingDebtorsPercentageLookup } from '@sb-billing/business-logic/split-billing';
import { featureActive } from '@sb-itops/feature';
import { decodeAndClean64Html } from '@sb-itops/html';
import uuid from '@sb-itops/uuid';
import {
  type as BALANCE_TYPE,
  byName as BALANCE_BY_NAME,
} from '@sb-billing/business-logic/bank-account-settings/entities/constants';
import { entryType as entryTypeEnum } from '@sb-billing/business-logic/shared/entities';

// a function compatible with .sort that sorts fees or expenses by date
export const byDate = (a, b) => {
  if (a.feeId) {
    return a.feeDate - b.feeDate || new Date(a.createdTimeStamp).getTime() - new Date(b.createdTimeStamp).getTime();
  }

  if (a.expenseId) {
    return (
      a.expenseDate - b.expenseDate || new Date(a.createdTimeStamp).getTime() - new Date(b.createdTimeStamp).getTime()
    );
  }

  return 0;
};

/**
 * Derive form field values
 *
 * @param {Object} param
 * @param {Invoice} [param.invoice]
 * @param {Matter} param.matter
 * @param {Number} param.paymentDueDays
 * @param {Array.<Fee|Expense>} param.sortedFeeTableEntries
 * @param {Expense[]} param.sortedExpenseTableEntries
 * @param {String} param.creditBankAccountId
 * @param {String} param.operatingBankAccountId
 * @param {String} param.trustBankAccountId
 * @param {Number} param.lessFundsInTrustAmount
 * @param {Boolean} param.surchargeEnabled
 * @param {MatterInvoiceSettings} [param.matterInvoiceSettings]
 * @param {InvoiceSettingsTemplate} param.template - Current template from config, or firm default
 * @param {Number} param.bankBalanceType - Balance type: 0:MatterContact, 1:Matter
 * @param {Number} [param.lastInvoiceIssuedDate] - Last invoice issued date for combination of the matter and debtors
 * @returns {Object}
 */
export const getDefaultFieldValues = ({
  invoice = {},
  matter,
  paymentDueDays,
  sortedFeeTableEntries,
  sortedExpenseTableEntries,
  preselectedExpenseIds,
  preselectedFeeIds,
  creditBankAccountId,
  operatingBankAccountId,
  trustBankAccountId,
  surchargeEnabled,
  matterInvoiceSettings,
  template,
  bankBalanceType,
  lastInvoiceIssuedDate,
  supportsDisplayExpenseWithFees,
}) => {
  let includeNonBillableItems;
  let showNonBillableFees;
  let showNonBillableExpenses;
  let expenseLineItemConfiguration;
  let expenseSummaryLineDescription;
  let feeLineItemConfiguration;
  let feeSummaryLineDescription;

  if (invoice.layout) {
    ({
      includeNonBillableItems,
      showNonBillableFees,
      showNonBillableExpenses,
      expenseLineItemConfiguration,
      expenseSummaryLineDescription,
      feeLineItemConfiguration,
      feeSummaryLineDescription,
    } = invoice.layout);
  } else {
    ({
      includeNonBillableItems,
      showNonBillableFees,
      showNonBillableExpenses,
      expenseLineItemConfiguration,
      expenseSummaryLineDescription,
      feeLineItemConfiguration,
      feeSummaryLineDescription,
    } = template.settings.defaultLayout);
    if (includeNonBillableItems) {
      showNonBillableFees = true;
      showNonBillableExpenses = true;
    }
  }

  let showExpenseEntriesAs = 'LIST';
  let expenseListAppend = false;
  if (expenseLineItemConfiguration) {
    showExpenseEntriesAs = 'SUMMARY';
    if (expenseLineItemConfiguration === 2) {
      expenseListAppend = true;
    }
  }
  let showFeesEntriesAs = 'LIST';
  let feeListAppend = false;
  if (feeLineItemConfiguration) {
    showFeesEntriesAs = 'SUMMARY';
    if (feeLineItemConfiguration === 2) {
      feeListAppend = true;
    }
  }

  let preselectFeeIds = preselectedFeeIds;
  let preselectExpenseIds = preselectedExpenseIds;
  // If we want to preselect expenses using preselectedExpenseIds, we need to make sure that:
  //  - preselectExpenseIds has only ids for expenses which are not 'expenses as fees'
  //  - preselectFeeIds has ids for fees and 'expenses as fees'
  // Even thought we save 'expense as fee' id to selectedExpenseIds (so for selecting we treat it as expense),
  // we need to calculate indices for position in the fee/expense table based on what we display
  // (sortedFeeTableEntries/sortedExpenseTableEntries), not where we save selection.
  if (supportsDisplayExpenseWithFees && !invoice.entries && Array.isArray(preselectedExpenseIds)) {
    const entriesMap = sortedFeeTableEntries.concat(sortedExpenseTableEntries).reduce((acc, entry) => {
      acc[entry.id] = entry;
      return acc;
    }, {});
    const feeTableIds = preselectedFeeIds;
    const expenseTableIds = [];
    // if some of the expense ids are 'expenses as fees', we need to move them to preselectFeeIds
    (preselectedExpenseIds || []).forEach((expenseId) => {
      if (entriesMap[expenseId]?.displayWithFees) {
        feeTableIds.push(expenseId);
      } else {
        expenseTableIds.push(expenseId);
      }
    });

    const getFeeTableIdsOrderedByEntryData = (ids) =>
      ids.sort((a, b) => {
        const entryA = entriesMap[a];
        const entryB = entriesMap[b];
        return (
          (entryA.feeDate || entryA.expenseDate) - (entryB.feeDate || entryB.expenseDate) ||
          new Date(entryA.createdTimestamp).getTime() - new Date(entryB.createdTimestamp).getTime()
        );
      });

    preselectExpenseIds = expenseTableIds;
    // 1) preselectedFeeIds can be empty array which selects nothing, or falsey which would fall back to select all (see below)
    //    so we overwrite it only if it's an array in first place to keep same behaviour.
    // 2) We need these in correct order. While this is not explicit, preselectedFeeIds is normally sorted by date of the fee.
    //    Therefore, after appending 'expense as fee' entries, we need to make sure that final array is sorted by date.
    preselectFeeIds = Array.isArray(preselectedFeeIds)
      ? getFeeTableIdsOrderedByEntryData(feeTableIds)
      : preselectFeeIds;
  }

  /* The initial sort order of fees and expenses should be defined by:
    1. Selected items
      a. Selected items should follow the prefill order supplied by entries or preselectedFees/Expenses
      b. If there is no prefill data, sort by date
    2. Unselected items should be ordered by the date of the item
  */

  // Select fees from prefill data (preserving the supplied ordering), fall
  // back to the fees for the matter sorted by date.
  // This reducer needs to allow for 3 different input types:
  // 1. invoice.entities objects that contain either feeEntity or expenseEntity objects
  // 2. preselectFeeIds - ID strings passed in from another component (eg, bulk create invoice)
  // 3. sortedFeeTableEntries - combination of invoice fee entities and matter unbilled fee entities. Can contain 'expense as fee'.
  const selectedFeeTableIdsMap = (
    invoice.entries ||
    preselectFeeIds ||
    sortedFeeTableEntries.filter((fee) => fee.isBillable || fee.isBillable === null || includeNonBillableItems)
  ).reduce((acc, entry) => {
    // Handle preselectFeeIds
    if (typeof entry === 'string') {
      acc[entry] = true;
      return acc;
    }

    // Invoices have an intermediary object called entries that either contains
    // feeEntity or expenseEntity, whereas sortedFeeTableEntries contain the entity directly

    if (entry.type !== undefined) {
      // Handle invoice.entries, we select all fees and expenses as fees
      if (entry.type !== entryTypeEnum.EXPENSE && entry.feeEntity) {
        acc[entry.id] = true;
      }
      if (entry.type === entryTypeEnum.EXPENSE && entry.expenseEntity?.displayWithFees) {
        acc[entry.id] = true;
      }
      return acc;
    }

    // Handle sortedFeeTableEntries, we just pre-select all
    if (entry) {
      acc[entry.id] = true;
    }

    return acc;
  }, {});
  const selectedFeeTableIds = Object.keys(selectedFeeTableIdsMap);

  // Calculate the unselected fees ordering by date
  const unselectedFeeTableIds = sortedFeeTableEntries.reduce((acc, entry) => {
    if (!selectedFeeTableIdsMap[entry.id]) {
      acc.push(entry.id);
    }
    return acc;
  }, []);

  // Selected fees should be at the top, unselected fees on the bottom
  const feeTableIndex = selectedFeeTableIds.concat(unselectedFeeTableIds).reduce(
    (acc, id, index) => {
      acc[id] = index;
      return acc;
    },
    { notEmptyToPreventFormsBug: true },
  );

  // Expense ordering is calculated in the same manner as fees
  const selectedExpenseTableIdsMap = (
    invoice.entries ||
    preselectExpenseIds ||
    sortedExpenseTableEntries.filter((expense) => expense.isBillable || includeNonBillableItems)
  ).reduce((acc, entry) => {
    // Handle preselectExpenseIds
    if (typeof entry === 'string') {
      acc[entry] = true;
      return acc;
    }

    if (entry.type !== undefined) {
      // Handle invoice.entries, we select all expenses which are not expenses as fees
      if (entry.type === entryTypeEnum.EXPENSE && entry.expenseEntity && !entry.expenseEntity?.displayWithFees) {
        acc[entry.id] = true;
      }

      return acc;
    }

    // Handle sortedExpenseTableEntries, we just pre-select all
    if (entry) {
      acc[entry.id] = true;
    }

    return acc;
  }, {});
  const selectedExpenseTableIds = Object.keys(selectedExpenseTableIdsMap);

  const unselectedExpenseTableIds = sortedExpenseTableEntries.reduce((acc, entry) => {
    if (!selectedExpenseTableIdsMap[entry.id]) {
      acc.push(entry.id);
    }
    return acc;
  }, []);

  const expenseTableIndex = selectedExpenseTableIds.concat(unselectedExpenseTableIds).reduce(
    (acc, id, index) => {
      acc[id] = index;
      return acc;
    },
    { notEmptyToPreventFormsBug: true },
  );

  // Recalculate the selected fee and expense ids based on type of the entry, rather than where it is displayed.
  // We can't use selectedFeeTableIds/selectedExpenseTableIds, because they are based on where the entry is displayed but if entry is selected
  // in form is based on its type (fee/expense). So selecting 'expense as fee' in fee table is saved in selectedExpenseIds, not selectedFeeIds.
  const allEntries = [...sortedFeeTableEntries, ...sortedExpenseTableEntries];
  const selectedExpenseIds = [];
  const selectedFeeIds = [];
  allEntries.forEach((entry) => {
    const isFee = entry.feeType === entryTypeEnum.TIME || entry.feeType === entryTypeEnum.FIXED;
    if (isFee && selectedFeeTableIdsMap[entry.id]) {
      selectedFeeIds.push(entry.id);
    } else if (
      !isFee &&
      (selectedExpenseTableIdsMap[entry.id] || (entry.displayWithFees && selectedFeeTableIdsMap[entry.id]))
    ) {
      selectedExpenseIds.push(entry.id);
    }
  });

  const selectedContacts = getDebtorsFromInvoiceOrMatter({ invoice, matter }).map((contact) => ({
    id: contact.id,
    displayName: contact.displayNameFull || contact.displayName,
  }));

  const matterSplitBillingEnabled =
    featureActive('BB-9790') && !!matter?.billingConfiguration?.splitBillingSettings?.isEnabled;

  let splitBillingDebtorsPercentageLookup;
  if (matterSplitBillingEnabled) {
    splitBillingDebtorsPercentageLookup = getSplitBillingDebtorsPercentageLookup({
      matterSplitBillingEnabled,
      invoiceVersion: invoice,
      matterSplitBillingSettings: matter?.billingConfiguration?.splitBillingSettings,
    });
  }

  let issueDate = invoice.issuedDate ? moment(invoice.issuedDate, 'YYYYMMDD') : moment();

  if (featureActive('BB-7210') && lastInvoiceIssuedDate) {
    // In dev only, we would like issue date should be the LATER of
    // 1. the current date
    // 2. the last issued invoice date
    // This way when there is an invoice in the future we dont need to click finalise before discovering the issue date is invalid
    issueDate = moment.max(moment(lastInvoiceIssuedDate, 'YYYYMMDD').add(1, 'day'), moment());
  }

  const dueDate = invoice.dueDate
    ? moment(invoice.dueDate, 'YYYYMMDD').toDate()
    : moment(issueDate).add(paymentDueDays, 'day').toDate();

  const showLessFundsInTrust =
    invoice && invoice.additionalOptions && invoice.additionalOptions.showLessFundsInTrust && trustBankAccountId;

  let surchargeSettings;
  if (surchargeEnabled) {
    const invoiceSettingsTemplate = template.settings;
    surchargeSettings = determineSurchargeSettings({
      surchargeEnabled,
      invoiceVersion: invoice,
      matterInvoiceSettings,
      invoiceSettingsTemplate,
      defaultSurchargeSettings,
    });
  }

  const shouldApplySurcharge =
    surchargeSettings?.type &&
    [surchargeTypeValues.FIXED, surchargeTypeValues.PERCENTAGE].includes(surchargeSettings.type);

  return {
    // Generate a uuid if one doesn't exist otherwise previews and the email modal will fail
    invoiceId: invoice.id || uuid(),
    saveType: 'DRAFT',
    paymentMethod: 'NO_PAYMENT',

    // This gets set to false if any of the invoice invoice template settings change
    isTemplateWithDefaults: true,

    autoAllocate: false,
    isMatterBalanceType: bankBalanceType === BALANCE_BY_NAME[BALANCE_TYPE.matter],
    sendNow: false,
    showDiscountWarning: false,
    quickPayments: getInitialQuickPayments({
      matterId: matter.id,
      operatingBankAccountId,
      trustBankAccountId,
      creditBankAccountId,
    }),
    showNonBillableFees,
    showNonBillableExpenses,
    multiPayments: [],
    discount: invoice.discount
      ? { ...invoice.discount, enabled: true }
      : { enabled: false, type: 0, percentage: 0, fixedDiscount: 0 },
    surcharge: surchargeEnabled ? { ...surchargeSettings, enabled: shouldApplySurcharge } : undefined,
    issueDate: issueDate.toDate(),
    dueDate,
    selectedContacts,
    matterSplitBillingEnabled,
    feeTableIndex, // Index of entries in the fee table, the table can have fees mixed with 'expenses as fees'
    expenseTableIndex, // Index of entries in the expense table, these are expenses without 'expenses as fees'
    selectedFeeIds, // Selected fees, these are always feeIds
    selectedExpenseIds, // Selected expenses, these are always expenseIds, even for 'expenses as fees'
    splitBillingDebtorsPercentageLookup,
    chequePrintingMethod: PrintNotApplicable,
    summary: decodeAndClean64Html({ base64EncodedHtml: invoice.summary }),
    showExpenseEntriesAs,
    showFeesEntriesAs,
    expenseListAppend,
    feeListAppend,
    feeSummaryLineDescription,
    expenseSummaryLineDescription,
    showLessFundsInTrust,
    // always pre-allocate merchantPaymentReference if it's not already allocated and stored
    // as part of existing invoice, but this value may be discarded when saving invoice depending
    // on the current state of payment provider settings, which could have changed since initialising
    merchantPaymentReference: invoice?.merchantPaymentReference || uuid(),
  };
};

export const getInitialQuickPayments = ({
  matterId,
  operatingBankAccountId,
  trustBankAccountId,
  creditBankAccountId,
}) => ({
  operating: {
    amount: 0,
    source: 'Operating',
    sourceAccountType: 'Operating',
    sourceAccountId: operatingBankAccountId,
    matterId,
  },
  trust: {
    amount: 0,
    source: 'Trust',
    sourceAccountType: 'Trust',
    sourceAccountId: trustBankAccountId,
    matterId,
  },
  credit: {
    amount: 0,
    source: 'Credit',
    sourceAccountType: 'Credit',
    sourceAccountId: creditBankAccountId,
    matterId,
  },
});
