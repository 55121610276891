import { useEffect, useState } from 'react';
import { clientSdkGetContact, clientSdkGetMatterItems } from 'web/react-redux/components/sb-client-sdk/getters';
import { fetchGetP } from '@sb-itops/redux/fetch';

const getContactName = (contact) => {
  const firstName = contact?.firstName || '';
  const lastName = contact?.lastName || '';
  const separator = firstName && lastName && ' ';

  // Names not shown Last, First as the ordering is done via matter items, not alphabetical
  return contact?.name || firstName + separator + lastName;
};

export interface Party {
  id: string;
  name: string;
  role: string;
  displayName: string;
  email: string;
  matterItemId: string;
}

export const useMatterParties = (matterId: string | undefined) => {
  const [selectableContacts, setSelectableContacts] = useState<Party[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!matterId) {
      return;
    }

    setLoading(true);

    clientSdkGetMatterItems(matterId)
      .then(async ({ items }) => {
        const flattenedItems = items.reduce(
          (acc, item) => {
            if (item.id) {
              acc.push({ name: item.name, id: item.id });
            }
            (item.subItems || []).forEach((subItem) => {
              if (subItem.id) {
                acc.push({ name: `${item.name} ${subItem.name}`, id: subItem.id });
              }
            });
            return acc;
          },
          [] as { name: string; id: string }[],
        );

        const matterItemContacts = {};

        const path = `/matter-management/matter-roles/:accountId/${matterId}`;

        const responseData = await fetchGetP({ path });

        const roles = responseData.body.roles;

        await Promise.all(
          roles
            .filter((role) => role.contactId && role.contactId !== 'MyFirm')
            .map(async (role) => {
              const rawContact = await clientSdkGetContact(role.contactId, true);
              const contact = rawContact.company || rawContact.person;

              if (rawContact?.type === 'groupOfPeople') {
                return;
              }
              if (contact) {
                matterItemContacts[role.id] = [contact];
                matterItemContacts[role.id][0].id = role.contactId;
              }
              const relationships = role.relationships || [];

              await Promise.all(
                relationships
                  .filter((relationship) => relationship.contactId && relationship.contactId !== 'MyFirm')
                  .map(async (relationship) => {
                    const relationshipContactRaw = await clientSdkGetContact(relationship.contactId, true);
                    const relationshipContact = relationshipContactRaw.company || relationshipContactRaw.person;

                    if (relationshipContactRaw?.type === 'groupOfPeople') {
                      return;
                    }
                    if (relationshipContact) {
                      matterItemContacts[relationship.id] = [relationshipContact];
                      matterItemContacts[relationship.id].id = relationship.contactId;
                    }
                  }),
              );
            }),
        );

        setSelectableContacts(
          flattenedItems
            .filter((item) => !!matterItemContacts[item.id]?.length)
            .flatMap((item) => {
              const contacts = matterItemContacts[item.id];

              return contacts.map((contact, contactIndex) => ({
                id: contact.id,
                name: getContactName(contact),
                displayName: `${getContactName(contact)} (${item.name})`,
                email: contact?.email,
                role: item.name,
                matterItemId: contactIndex === 0 ? item.id : `${item.id}-${contactIndex}`,
              }));
            }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [!matterId]);
  return { selectableContacts, loading };
};
