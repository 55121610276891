import { isNonEmptyString, capitalizeAllWords } from '@sb-itops/nodash';
import { getById as getMatterById } from '@sb-matter-management/redux/matters';
import { getTypeOrBaseTypeById as getMatterTypeByMatterId } from '@sb-matter-types/redux/matter-types';
import { getById as getContactById } from '@sb-customer-management/redux/contacts-summary';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import * as forms from '@sb-itops/redux/forms2';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { getById as getReconById } from '../bank-reconciliations';
import { getById as getInvoiceById } from '../invoices';
import { getById as getTransactionById, getByPaymentId } from '../transactions';
import { getById as getBulkDepositById } from '../bulk-deposit';
import { getTransactionReceiptById } from '../transaction-receipts';
import { getById as getDepositSlipById } from '../deposit-slip';

const iconComponent = {
  matter: 'Folder',
  invoice: 'InvoiceIcon',
  draftinvoice: 'FilePen',
  'invoice-statement': 'InvoiceIcon',
  contact: 'Contact',
  'advanced-search': 'Search',
  'add-matter': 'FolderPlus',
  'edit-matter': 'FolderPen',
  'view-combined-pdf': 'Files',
  'deposit-receipt': 'InvoiceIcon',
  'transfer-receipt': 'InvoiceIcon',
  'trust-to-office-transfer-receipt': 'InvoiceIcon',
  'vendor-proof-of-payment': 'InvoiceIcon',
  'invoice-proof-of-payment': 'InvoiceIcon',
  'bank-reconciliation': 'Landmark',
  'bank-reconciliation-setup': 'Landmark',
  'bank-reconciliation-pdf': 'Landmark',
  'view-transaction-details-pdf': 'File',
  'end-of-month-pdf': 'File',
  'create-deposit-slip': 'ClipboardPen',
  'edit-deposit-slip': 'ClipboardPen',
  'deposit-slip-pdf': 'File',
  'trust-cheques-pdf': 'File',
  'view-operating-cheque-pdf': 'File',
  support: 'Book',
  'finalize-invoice-payments': 'InvoiceIcon',
};

/**
 * Finds correct icon for a given tab type
 * @param {String} type
 * @returns {String} Icon name
 */
const getTabIconByType = (type) => iconComponent[type];

/**
 * Remove empty element from array of strings
 * @param {String} el
 * @returns bool
 */
const filterEmptyElements = (el) => isNonEmptyString(el);

/**
 * Joins any Array of string with given join element
 * @param {*[]} args
 * @param {String} join
 * @returns {String} Array as a string
 */
const getTabToolTip = (args, join = ' / ') => args.filter(filterEmptyElements).join(join);

/**
 * Build label info from matter and matter type
 * @param {String} matterId
 * @returns {{primaryLabel: string, secondaryLabel: string | undefined}}
 */
const getMatterWithType = (matterId) => {
  const { clientNames: primaryLabel, matterTypeId, isLead } = getMatterById(matterId);
  const { name: secondaryLabel } = getMatterTypeByMatterId(matterTypeId);

  return { primaryLabel, secondaryLabel, isLead };
};

/**
 * Gets contact name
 * @param {Number} contactId
 * @returns {String} The name of the contact
 */
const getContactName = (contactId) => {
  const { displayName } = getContactById(contactId);

  return displayName;
};

/**
 * Builds the tab information
 * @param {Object} params
 * @param {String} params.primaryLabel
 * @param {String} [params.secondaryLabel]
 * @param {String} params.icon
 * @param {String} [params.overrideTooltip]
 * @param {String} [params.kbd]
 * @returns {{primaryLabel: string, secondaryLabel: string | undefined, icon: string, tooltip: string, kbd: string | undefined}}
 */
const getDefaultTabInfo = ({ primaryLabel, secondaryLabel, icon, overrideTooltip, kbd }) => {
  const tooltip = overrideTooltip || getTabToolTip([primaryLabel, secondaryLabel]);

  return { primaryLabel, secondaryLabel, icon, tooltip, kbd };
};

/**
 * Builds Draft Invoice Tab info
 * @param {Object} params
 * @param {String} params.primaryLabel
 * @param {String} [params.secondaryLabel]
 * @param {String} params.icon
 * @returns {{primaryLabel: string, secondaryLabel: string | undefined, icon: string, tooltip: string, kbd: string | undefined}}
 */
const getDraftInvoiceInfo = (params) => {
  const primaryLabel = 'Draft Invoice';
  const secondaryLabel = getTabToolTip([params.primaryLabel, params.secondaryLabel]);
  const icon = params.icon;

  return getDefaultTabInfo({ primaryLabel, secondaryLabel, icon, overrideTooltip: secondaryLabel });
};

/**
 * Builds Edit Matter Tab Info
 * @param {String} matterId
 * @param {String} icon
 * @returns {{primaryLabel: string, secondaryLabel: string | undefined, icon: string, tooltip: string, kbd: string | undefined}}
 */
const getEditMatterTabInfo = (matterId, icon) => {
  const { primaryLabel, secondaryLabel, isLead } = getMatterWithType(matterId);
  const overrideTooltip = getTabToolTip([primaryLabel, secondaryLabel]);
  return getDefaultTabInfo({
    primaryLabel: isLead ? 'Edit Lead' : 'Edit Matter',
    secondaryLabel: primaryLabel,
    icon,
    overrideTooltip,
  });
};

/**
 * Builds Bank Reconciliation Tab Info
 * @param {Object} params
 * @param {Object} params.state
 * @param {String} params.id
 * @param {String} params.icon
 * @param {String} [params.extendedTooltip='']
 * @returns {{primaryLabel: string, secondaryLabel: string | undefined, icon: string, tooltip: string, kbd: string | undefined}}
 */
const getBankReconTabInfo = ({ state, id, icon, t, extendedTooltip = '' }) => {
  const scope = `billing-create-bank-reconciliation-form-${id}`;

  const { selectors: formSelectors } = withScopedFeature({ scope })(forms);
  const { fields } = formSelectors.getFormState(state);
  const { startDate, endDate } = fields;
  const primaryLabel = 'Bank Reconciliation';
  const secondaryLabel = `${startDate?.value ? t('date', { yyyymmdd: startDate?.value }) : ''} - ${
    endDate?.value ? t('date', { yyyymmdd: endDate?.value }) : ''
  }`;
  const overrideTooltip = `${primaryLabel} ${extendedTooltip}`.trim();

  return getDefaultTabInfo({ primaryLabel, secondaryLabel, icon, overrideTooltip });
};

/**
 * Builds Bank Reconciliation PDF Tab Info
 * @param {Object} params
 * @returns {{primaryLabel: string, secondaryLabel: string | undefined, icon: string, tooltip: string, kbd: string | undefined}}
 */
const getBankReconPDFTabInfo = ({ bankReconciliationId, icon, t }) => {
  const primaryLabel = `Bank Reconciliation`;
  const bankRecon = getReconById(bankReconciliationId);
  if (!bankRecon) {
    return getDefaultTabInfo({ primaryLabel, icon });
  }
  const { startDate, endDate } = bankRecon;
  const secondaryLabel = `${t('date', { yyyymmdd: startDate })} - ${t('date', { yyyymmdd: endDate })}`;
  const overrideTooltip = `${primaryLabel} PDF`;

  return getDefaultTabInfo({ primaryLabel, secondaryLabel, icon, overrideTooltip });
};

/**
 * Builds Invoice Info
 * @param {Object} params
 * @param {String} params.invoiceId
 * @param {String} params.icon
 * @param {String} [params.overridePrimaryLabel]
 * @returns {{primaryLabel: string, secondaryLabel: string | undefined, icon: string, tooltip: string, kbd: string | undefined}}
 */
const getInvoiceTabInfo = ({ invoiceId, icon, overridePrimaryLabel }) => {
  const invoice = getInvoiceById(invoiceId);
  const invoiceLabel = 'Invoice';

  if (!invoice) {
    return getDefaultTabInfo({ primaryLabel: invoiceLabel, icon });
  }

  const { currentVersion, matterId } = getInvoiceById(invoiceId);
  const matter = getMatterWithType(matterId);
  const primaryLabel = overridePrimaryLabel || `${invoiceLabel} #${currentVersion.invoiceNumber}`;
  const secondaryLabel = getTabToolTip([matter.primaryLabel, matter.secondaryLabel]);
  const overrideTooltip = getTabToolTip([overridePrimaryLabel || invoiceLabel, secondaryLabel], ' - ');

  return getDefaultTabInfo({ primaryLabel, secondaryLabel, icon, overrideTooltip });
};

/**
 * Builds Vendor Proof Tab Info
 * @param {Object} params
 * @param {String} params.transactionId
 * @param {String} params.icon
 * @returns {{primaryLabel: string, secondaryLabel: string | undefined, icon: string, tooltip: string, kbd: string | undefined}}
 */
const getVendorProofTabInfo = ({ transactionId, icon, t }) => {
  const primaryLabel = `${t('trust')} Payment Receipt`;
  const transaction = getTransactionById(transactionId);
  if (!transaction) {
    return getDefaultTabInfo({ primaryLabel, icon });
  }
  const { matterId } = transaction;
  const matter = getMatterWithType(matterId);
  const secondaryLabel = getTabToolTip([matter.primaryLabel, matter.secondaryLabel]);
  const overrideTooltip = getTabToolTip([primaryLabel, secondaryLabel], ' - ');

  return getDefaultTabInfo({ primaryLabel, secondaryLabel, icon, overrideTooltip });
};

/**
 * Builds Deposit Receipt Tab Info
 * @param {Object} params
 * @param {String} [params.transactionId]
 * @param {String} [params.bulkDepositId]
 * @param {String} params.icon
 * @returns {{primaryLabel: string, secondaryLabel: string | undefined, icon: string, tooltip: string, kbd: string | undefined}}
 */
const getDepositReceiptTabInfo = ({ transactionId, bulkDepositId, icon, t }) => {
  let transactionReceipt;
  let secondaryLabel;

  if (bulkDepositId) {
    transactionReceipt = getTransactionReceiptById(bulkDepositId);
    const bulkDeposit = getBulkDepositById(transactionReceipt?.id);
    if (!transactionReceipt || !bulkDeposit) {
      // transaction still processing
      return getDefaultTabInfo({ primaryLabel: 'Receipt', icon });
    }

    if (transactionId) {
      // if we have transactionId as well, we use it to get a context
      const transaction = getTransactionById(transactionId);
      const { matterId } = transaction;
      const matter = getMatterWithType(matterId);

      secondaryLabel = getTabToolTip([matter.primaryLabel, matter.secondaryLabel]);
    } else {
      const accountLabel =
        bulkDeposit.bankAccountType.toUpperCase() === bankAccountTypeEnum.OPERATING
          ? t('operatingAccountType')
          : t('trustAccountType');
      secondaryLabel = `Bulk ${accountLabel?.toLowerCase()} deposit`;
    }
  } else {
    transactionReceipt = getTransactionReceiptById(transactionId);
    const transaction = getTransactionById(transactionId);
    if (!transactionReceipt || !transaction) {
      // transaction still processing
      return getDefaultTabInfo({ primaryLabel: 'Receipt', icon });
    }

    const { matterId } = transaction;
    const matter = getMatterWithType(matterId);

    secondaryLabel = getTabToolTip([matter.primaryLabel, matter.secondaryLabel]);
  }

  const primaryLabel = `Receipt #${transactionReceipt.receiptNumber}`;
  const overrideTooltip = getTabToolTip(['Receipt', secondaryLabel], ' - ');

  return getDefaultTabInfo({ primaryLabel, secondaryLabel, icon, overrideTooltip });
};

/**
 * Builds Transfer Receipt Tab Info
 * @param {Object} params
 * @param {String} params.paymentId
 * @param {String} params.icon
 * @returns {{primaryLabel: string, secondaryLabel: string | undefined, icon: string, tooltip: string, kbd: string | undefined}}
 */
const getTransferReceiptTabInfo = ({ paymentId, icon }) => {
  const primaryLabel = 'Transfer';
  const payment = getByPaymentId(paymentId)?.find(({ amount }) => amount > 0);
  if (!payment) {
    return getDefaultTabInfo({ primaryLabel, icon });
  }
  const { matterId } = payment;
  const matter = getMatterWithType(matterId);
  const secondaryLabel = getTabToolTip(['From', matter.primaryLabel, matter.secondaryLabel], ' - ');
  const overrideTooltip = `${primaryLabel} ${secondaryLabel}`;

  return getDefaultTabInfo({ primaryLabel, secondaryLabel, icon, overrideTooltip });
};

/**
 * Builds Cheque Trust Tab Info
 * @param {Object} params
 * @param {String} params.icon
 * @returns {{primaryLabel: string, secondaryLabel: string | undefined, icon: string, tooltip: string, kbd: string | undefined}}
 */
const getTrustChequeTabInfo = ({ icon, t }) => {
  const primaryLabel = `${capitalizeAllWords(t('trustCheque'))}`;
  const overrideTooltip = primaryLabel;

  return getDefaultTabInfo({ primaryLabel, icon, overrideTooltip });
};

/**
 * Builds Cheque Operating Tab Info
 * @param {Object} params
 * @param {String} params.icon
 * @returns {{primaryLabel: string, secondaryLabel: string | undefined, icon: string, tooltip: string, kbd: string | undefined}}
 */
const getOperatingChequeTabInfo = ({ icon, t }) => {
  const primaryLabel = `${capitalizeAllWords(t('operatingCheque'))}`;
  const overrideTooltip = primaryLabel;

  return getDefaultTabInfo({ primaryLabel, icon, overrideTooltip });
};

/**
 * Builds Deposit Slip Tab Info
 * @param {Object} params
 * @param {String} params.slipId
 * @param {String} params.icon
 * @returns {{primaryLabel: string, secondaryLabel: string | undefined, icon: string, tooltip: string, kbd: string | undefined}}
 */
const getDepositSlipTabInfo = ({ slipId, icon, t }) => {
  const depositLabel = 'Deposit Slip';
  const depositSlip = getDepositSlipById(slipId);
  if (!depositSlip) {
    return getDefaultTabInfo({ primaryLabel: depositLabel, icon });
  }
  const { depositDate, depositSlipNumber } = depositSlip;
  const primaryLabel = `${depositLabel} #${depositSlipNumber}`;
  const secondaryLabel = t('date', { yyyymmdd: depositDate });
  const overrideTooltip = primaryLabel;

  return getDefaultTabInfo({ primaryLabel, secondaryLabel, icon, overrideTooltip });
};

/**
 * Builds Tab infos for an array of tabs
 * @param {Object} tabs
 * @param {Object} state
 * @returns {[{primaryLabel: string, secondaryLabel: string | undefined, icon: string, tooltip: string, kbd: string | undefined}] | []}
 */
export const getAllTabsInfo =
  ({ tabs, t }) =>
  (state) =>
    tabs.map((tab) => getTabInfo({ tab, t }, state));

/**
 * Builds Tab info for a given Type
 * @param {Object} tab
 * @param {Object} state
 * @returns {{primaryLabel: string, secondaryLabel: string | undefined, icon: string, tooltip: string, kbd: string | undefined} | undefined}
 */
export const getTabInfo = ({ tab, t }, state) => {
  const { matterId, type, searchTerm, contactId, month, id, primaryLabel, secondaryLabel } = tab;

  const icon = getTabIconByType(type);
  switch (type) {
    case 'matter':
      return getDefaultTabInfo({ ...(getMatterWithType(matterId) || {}), icon });
    case 'invoice':
      return getInvoiceTabInfo({ invoiceId: id, icon });
    case 'draftinvoice':
      return getDraftInvoiceInfo({ ...(getMatterWithType(matterId) || {}), icon });
    case 'contact':
      return getDefaultTabInfo({ primaryLabel: getContactName(contactId), icon });
    case 'advanced-search':
      return getDefaultTabInfo({
        primaryLabel: 'Search:',
        secondaryLabel: searchTerm,
        icon,
        overrideTooltip: `Search results for '${searchTerm}'`,
      });
    case 'add-matter':
      return getDefaultTabInfo({ primaryLabel: 'Add Matter', icon });
    case 'edit-matter':
      return getEditMatterTabInfo(matterId, icon);
    case 'view-combined-pdf':
      return getDefaultTabInfo({
        primaryLabel: 'Combined Invoices',
        icon,
        overrideTooltip: 'Invoices combined in PDF',
      });
    case 'invoice-statement':
      return getDefaultTabInfo({
        primaryLabel: primaryLabel || 'Statement',
        secondaryLabel: secondaryLabel || '',
        icon,
      });
    case 'deposit-receipt':
      return getDepositReceiptTabInfo({ transactionId: tab.transactionId, bulkDepositId: tab.bulkDepositId, icon, t });
    case 'transfer-receipt':
      return getTransferReceiptTabInfo({ paymentId: id, icon });
    case 'trust-to-office-transfer-receipt':
      return getDefaultTabInfo({
        primaryLabel: `${t('trustToOffice')} Transfer`,
        icon,
        overrideTooltip: `${t('trustToOffice')} Transfer`,
      });
    case 'vendor-proof-of-payment':
      return getVendorProofTabInfo({ transactionId: id, icon, t });
    case 'invoice-proof-of-payment':
      return getInvoiceTabInfo({ invoiceId: id, icon, overridePrimaryLabel: 'Invoice Payment Detail' });
    case 'bank-reconciliation':
      return getBankReconTabInfo({ state, id, icon, t });
    case 'bank-reconciliation-setup':
      return getDefaultTabInfo({ primaryLabel: 'Bank Reconciliation', secondaryLabel: 'Setup', icon });
    case 'bank-reconciliation-pdf':
      return getBankReconPDFTabInfo({ bankReconciliationId: id, icon, t });
    case 'view-transaction-details-pdf':
      return getDefaultTabInfo({
        primaryLabel: `Bulk ${t('trust')} Payment for Invoice`,
        icon,
        overrideTooltip: `Bulk ${t('trust')} Payment for Invoice`,
      });
    case 'end-of-month-pdf':
      return getDefaultTabInfo({ primaryLabel: 'End Of Month Report', secondaryLabel: month, icon });
    case 'create-deposit-slip':
      return getDefaultTabInfo({ primaryLabel: 'Deposit Slip', icon, overrideTooltip: 'Deposit Slips' });
    case 'edit-deposit-slip':
    case 'deposit-slip-pdf':
      return getDepositSlipTabInfo({ slipId: id, icon, t });
    case 'trust-cheques-pdf':
      return getTrustChequeTabInfo({ icon, t });
    case 'view-operating-cheque-pdf':
      return getOperatingChequeTabInfo({ icon, t });
    case 'support':
      return getDefaultTabInfo({
        primaryLabel: 'Support',
        icon,
        overrideTooltip: 'Stats',
        kbd: 'Ctrl+Alt+s',
      });
    case 'finalize-invoice-payments':
      return getDefaultTabInfo({
        primaryLabel: `${t('capitalize', { val: 'finalise' })} Invoices with Payments`,
        icon,
      });
    default:
      return undefined;
  }
};
