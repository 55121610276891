import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  bankAccountState,
  bankAccountStateByValue,
  bankAccountTypeEnum,
} from '@sb-billing/business-logic/bank-account/entities/constants';
import { featureActive } from '@sb-itops/feature';
import { debounce, cleanObject } from '@sb-itops/nodash';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getRegion } from '@sb-itops/region';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { getDebtorsFromInvoiceOrMatter } from '@sb-billing/business-logic/invoice/services';
import { status as invoiceStatus } from '@sb-billing/business-logic/invoice/entities';
import {
  BankAccountsWithBalances,
  DraftInvoice,
  DraftInvoiceExistingInvoice,
  DraftInvoiceExpenses,
  DraftInvoiceFees,
  InitActivityCodes,
  InitBankAccountSettings,
  InitBulkFinalizeSettings,
  InitFirmTaxSettings,
  InitFirmUtbmsSettings,
  InitPaymentProviderSettings,
  InitTrustChequePrintSettings,
  InitUserBillingAttributes,
  InvoiceSettingsTemplate,
  InvoiceSettingsTemplateFirmDefault,
  InvoiceSettingsTemplateTypeahead,
  InvoiceForDebtors,
  MatterTrustBankAccountsData,
  TrustChequeAvailableNumbers,
} from 'web/graphql/queries';
import {
  type as BALANCE_TYPE,
  byName as BALANCE_BY_NAME,
} from '@sb-billing/business-logic/bank-account-settings/entities/constants';
import { useCacheQuery, useSubscribedQuery, useSubscribedLazyQuery, useContactTypeaheadData } from 'web/hooks';
import { useTranslation } from '@sb-itops/react';
import { findDefaultTrustAccountForMatter } from '@sb-billing/business-logic/matters';
import { getDefaultTrustChequePrintSettings } from '@sb-billing/business-logic/cheques';
import { convertSettingsFromGQL } from '@sb-billing/business-logic/payment-provider/services';
import { getLocationFromMatterType } from '@sb-matter-types/business-logic/matter-types/services';
import { constructBalances } from './construct-balances';
import { BillingDraftInvoiceRouteFormsContainer } from './BillingDraftInvoiceRoute.forms.container';

const REGION = getRegion();
const SCOPE = 'draft-invoice-route';

const hooks = () => ({
  useScope: ({ matterId, invoiceId }) => ({
    region: REGION,
    scope: `${SCOPE}-${invoiceId}-${matterId}`,
  }),
  useUserBillingAttributesData: () => {
    const { data, error } = useCacheQuery(InitUserBillingAttributes.query);

    if (error) {
      throw new Error(error);
    }

    const userViewedMessages = data?.userBillingAttributes?.viewedMessages || [];

    return { userViewedMessages };
  },
  useInvoiceData: ({ invoiceId }) => {
    const isNewInvoice = !invoiceId || invoiceId === 'draft-default';

    const { data: invoiceData, error: invoiceError } = useSubscribedQuery(DraftInvoiceExistingInvoice, {
      skip: isNewInvoice,
      variables: {
        invoiceId,
      },
    });

    if (invoiceError) {
      throw new Error(invoiceError);
    }

    const invoice = useMemo(() => {
      let invoiceResult = invoiceData?.invoice;

      // We need to remove null values from additionalOptions and invoiceAdditionalOptions as graphQL returns null instead of undefined for some properties.
      // This was problem as in get-invoice-configuration.js and its related business logic we merge (spread) multiple objects to get correct config.
      // The null values from graphQL caused some properties were incorrectly overwritten with null which is not what we want.
      if (invoiceResult?.additionalOptions) {
        invoiceResult = {
          ...invoiceResult,
          additionalOptions: cleanObject(invoiceResult.additionalOptions, { removeValues: [null] }),
        };
      }

      if (invoiceResult?.template?.settings?.invoiceAdditionalOptions) {
        invoiceResult = {
          ...invoiceResult,
          template: {
            ...invoiceResult.template,
            settings: {
              ...invoiceResult.template.settings,
              invoiceAdditionalOptions: cleanObject(invoiceResult.template.settings.invoiceAdditionalOptions, {
                removeValues: [null],
              }),
            },
          },
        };
      }

      // filter out any debtors where contact could not be fetched to prevent page from crashing,
      // we ran into a bug previously with bulk invoice creation where the debtorId extracted
      // and saved is incorrect resulting in a blank draft invoice page
      if (invoiceResult?.debtors) {
        // debtor is considered valid if related contact is present
        invoiceResult.debtors = invoiceResult.debtors.filter((debtor) => debtor.contact);
      }

      // fees could be moved from one matter to another, so we need to exclude them
      // here if they don't belong to the current matter anymore, otherwise user
      // might still be able to select these for inclusion in the draft invoice
      // which would result in an invalid invoice that cannot be finalised
      if (invoiceResult?.entries && invoiceResult?.matter?.id) {
        invoiceResult.entries = invoiceResult.entries.filter(
          (entry) =>
            entry?.feeEntity?.matter?.id === invoiceResult.matter.id ||
            entry?.expenseEntity?.matter?.id === invoiceResult.matter.id,
        );
      }

      return invoiceResult;
    }, [invoiceData?.invoice]);

    return {
      invoice,
      isNewInvoice,
    };
  },
  useMatterData: ({ matterId }) => {
    const { data: matterData, error: matterError } = useSubscribedQuery(DraftInvoice, {
      variables: {
        matterId,
        filter: {
          // Include non-billable leads so we don't end up in infinite loading loop as we always expect matter to exist
          includeNonBillableLeadMatters: true,
        },
      },
    });

    if (matterError) {
      throw new Error(matterError);
    }

    // We need to remove null values from invoiceAdditionalOptions as graphQL returns null instead of undefined for some properties.
    // This was problem as in get-invoice-configuration.js and its related business logic we merge (spread) multiple objects to get correct config.
    // The null values from graphQL caused some properties were incorrectly overwritten with null which is not what we want.
    const matterInvoiceSettings = useMemo(() => {
      let matterInvoiceSettingsResult = matterData?.matterInvoiceSettings?.[0];

      if (matterInvoiceSettingsResult?.template?.settings?.invoiceAdditionalOptions) {
        matterInvoiceSettingsResult = {
          ...matterInvoiceSettingsResult,
          template: {
            ...matterInvoiceSettingsResult.template,
            settings: {
              ...matterInvoiceSettingsResult.template.settings,
              invoiceAdditionalOptions: cleanObject(
                matterInvoiceSettingsResult.template.settings.invoiceAdditionalOptions,
                {
                  removeValues: [null],
                },
              ),
            },
          },
        };
      }

      return matterInvoiceSettingsResult;
    }, [matterData?.matterInvoiceSettings]);

    return {
      matter: matterData?.matter,
      matterInvoiceSettings,
      matterDisplay: getMatterDisplay(matterData?.matter, matterData?.matter?.matterType?.name),
      provideShowRetainerOption:
        featureActive('BB-6908') && !!matterData?.matter?.billingConfiguration?.minimumTrustRetainerActive,
      supportsTax: hasFacet(facets.tax),
    };
  },
  useMatterTrustBankAccountsData: ({ matterId }) => {
    const { data: matterTrustData, error: matterTrustError } = useSubscribedQuery(MatterTrustBankAccountsData, {
      skip: !featureActive('BB-6908'),
      variables: {
        matterId,
        filter: {
          checkTransactions: true,
          state: [bankAccountStateByValue[bankAccountState.OPEN]],
        },
      },
    });

    if (matterTrustError) {
      throw new Error(matterTrustError);
    }

    return {
      hasOpenTrustAccountsForMatter: !!matterTrustData?.matterTrustBankAccounts?.length,
    };
  },
  useTrustChequeData: () => {
    const [getAvailableTrustChequeNumbers, trustChequeNumberResults] = useSubscribedLazyQuery(
      TrustChequeAvailableNumbers,
      {
        context: { skipRequestBatching: true },
        variables: {},
      },
    );

    const onFetchAvailableTrustChequeNumbers = debounce(
      ({ bankAccountId, trustChequeReference }) => {
        if (!bankAccountId) {
          // eslint-disable-next-line no-console
          console.warn('onFetchAvailableTrustChequeNumbers missing bankAccountId');
          return;
        }

        getAvailableTrustChequeNumbers({
          variables: {
            filter: {
              bankAccountId,
              chequeNumberFrom: trustChequeReference,
              quantity: 1,
            },
          },
        });
      },
      300, // wait in milliseconds
      { leading: false },
    );

    const data = trustChequeNumberResults.data?.trustChequeAvailableNumbers;

    const lastTrustChequeNumber = data?.lastChequeNumber;
    const nextTrustChequeNumber = data?.availableChequeNumbers?.length ? data.availableChequeNumbers[0] : undefined;

    return {
      lastTrustChequeNumber,
      nextTrustChequeNumber,
      trustChequeNumberLoading: trustChequeNumberResults.loading,
      onFetchAvailableTrustChequeNumbers,
    };
  },
  useMatterFeeData: ({ matterId }) => {
    const [getFees, feesResults] = useSubscribedLazyQuery(DraftInvoiceFees, {
      variables: {
        matterId,
      },
    });

    const onFetchUnbilledFees = ({ includeNonBillableItems }) => {
      getFees({
        variables: {
          includeNonBillableItems,
        },
      });
    };

    return {
      unbilledFees: feesResults.data?.unbilledFees,
      unbilledFeesLoading: feesResults.loading,
      onFetchUnbilledFees,
    };
  },
  useMatterExpenseData: ({ matterId }) => {
    const [getExpenses, expensesResults] = useSubscribedLazyQuery(DraftInvoiceExpenses, {
      variables: {
        matterId,
      },
    });

    const onFetchUnbilledExpenses = ({ includeNonBillableItems }) => {
      getExpenses({
        variables: {
          includeNonBillableItems,
        },
      });
    };

    return {
      unbilledExpenses: expensesResults.data?.unbilledExpenses,
      unbilledExpensesLoading: expensesResults.loading,
      onFetchUnbilledExpenses,
      supportsDisplayExpenseWithFees: hasFacet(facets.displayExpenseWithFees) && featureActive('BB-14971'),
    };
  },
  useContactTypeaheadData: () => {
    const {
      contactOptions,
      contactOptionsDataLoading,
      contactOptionsHasMore,
      onFetchContactOptions,
      onFetchMoreContactOptions,
    } = useContactTypeaheadData();

    return {
      contactOptions,
      contactOptionsDataLoading,
      contactOptionsHasMore,
      onFetchContactOptions,
      onFetchMoreContactOptions,
    };
  },
  useInvoiceSettingsTemplateFirmDefaultData: () => {
    const { data: firmDefaultTemplateData, error: firmDefaultTemplateError } = useSubscribedQuery(
      InvoiceSettingsTemplateFirmDefault,
    );

    if (firmDefaultTemplateError) {
      throw new Error(firmDefaultTemplateError);
    }

    // We need to remove null values from invoiceAdditionalOptions as graphQL returns null instead of undefined for some properties.
    // This was problem as in get-invoice-configuration.js and its related business logic we merge (spread) multiple objects to get correct config.
    // The null values from graphQL caused some properties were incorrectly overwritten with null which is not what we want.
    const invoiceSettingsTemplateFirmDefault = useMemo(() => {
      let invoiceSettingsTemplateFirmDefaultResult = firmDefaultTemplateData?.invoiceSettingsTemplateFirmDefault;

      if (invoiceSettingsTemplateFirmDefaultResult?.settings?.invoiceAdditionalOptions) {
        invoiceSettingsTemplateFirmDefaultResult = {
          ...invoiceSettingsTemplateFirmDefaultResult,
          settings: {
            ...invoiceSettingsTemplateFirmDefaultResult.settings,
            invoiceAdditionalOptions: cleanObject(
              invoiceSettingsTemplateFirmDefaultResult.settings.invoiceAdditionalOptions,
              {
                removeValues: [null],
              },
            ),
          },
        };
      }

      return invoiceSettingsTemplateFirmDefaultResult;
    }, [firmDefaultTemplateData?.invoiceSettingsTemplateFirmDefault]);

    return {
      invoiceSettingsTemplateFirmDefault,
    };
  },
  useInvoiceSettingsTemplateTypeaheadData: () => {
    const [getInvoiceSettingsTemplate, invoiceSettingsTemplateResults] = useSubscribedLazyQuery(
      InvoiceSettingsTemplateTypeahead,
      {
        context: { skipRequestBatching: true },
        variables: {
          limit: 25,
        },
      },
    );

    const results = invoiceSettingsTemplateResults.data?.invoiceSettingsTemplateSearch?.results;

    const invoiceSettingsTemplateOptions = useMemo(() => {
      const options = !results?.length
        ? []
        : results.map((invoiceSettingsTemplate) => ({
            value: invoiceSettingsTemplate.id,
            label: invoiceSettingsTemplate.isDeleted
              ? `${invoiceSettingsTemplate.name} (Deleted)`
              : invoiceSettingsTemplate.name,
            data: invoiceSettingsTemplate,
          }));

      return options;
    }, [results]);

    const getInvoiceSettingsTemplateBySearchText = debounce(
      (searchText = '') => {
        getInvoiceSettingsTemplate({
          variables: {
            searchText,
            offset: 0,
          },
        });
      },
      300, // wait in milliseconds
      { leading: false },
    );

    const onFetchInvoiceSettingsTemplateOptions = (searchText = '') => {
      // Note: When the select component loses focus, it executes this
      // function with an empty string.
      getInvoiceSettingsTemplateBySearchText(searchText);

      return searchText;
    };

    const onFetchMoreInvoiceSettingsTemplateOptions = async () => {
      if (!invoiceSettingsTemplateResults.data?.invoiceSettingsTemplateSearch?.pageInfo?.hasNextPage) {
        return undefined;
      }

      const fetchMoreResults = await invoiceSettingsTemplateResults.fetchMore({
        variables: {
          offset: invoiceSettingsTemplateResults.data.invoiceSettingsTemplateSearch.results.length || 0,
        },
      });

      return fetchMoreResults;
    };

    return {
      invoiceSettingsTemplateOptions,
      invoiceSettingsTemplateOptionsDataLoading: invoiceSettingsTemplateResults.loading,
      invoiceSettingsTemplateOptionsHasMore:
        invoiceSettingsTemplateResults.data?.invoiceSettingsTemplateSearch?.pageInfo?.hasNextPage || false,
      onFetchInvoiceSettingsTemplateOptions,
      onFetchMoreInvoiceSettingsTemplateOptions,
    };
  },
  useInvoiceSettingsTemplateData: () => {
    const [getInvoiceTemplateEntity, invoiceSettingsTemplateEntityResults] = useSubscribedLazyQuery(
      InvoiceSettingsTemplate,
      {
        context: { skipRequestBatching: true },
        notifyOnNetworkStatusChange: true, // Show loading state every time
        variables: {},
      },
    );

    // We need to remove null values from invoiceAdditionalOptions as graphQL returns null instead of undefined for some properties.
    // This was problem as in get-invoice-configuration.js and its related business logic we merge (spread) multiple objects to get correct config.
    // The null values from graphQL caused some properties were incorrectly overwritten with null which is not what we want.
    // Warning: We need to use useMemo here as invoiceSettingsTemplateEntity is used as useEffect dependency in forms container
    const invoiceSettingsTemplate = useMemo(() => {
      let invoiceSettingsTemplateResult = invoiceSettingsTemplateEntityResults.data?.invoiceSettingsTemplate;
      if (invoiceSettingsTemplateResult?.settings?.invoiceAdditionalOptions) {
        invoiceSettingsTemplateResult = {
          ...invoiceSettingsTemplateResult,
          settings: {
            ...invoiceSettingsTemplateResult.settings,
            invoiceAdditionalOptions: cleanObject(invoiceSettingsTemplateResult.settings?.invoiceAdditionalOptions, {
              removeValues: [null],
            }),
          },
        };
      }

      return invoiceSettingsTemplateResult;
    }, [invoiceSettingsTemplateEntityResults.data?.invoiceSettingsTemplate]);

    const onFetchInvoiceSettingsTemplateEntity = (templateId) => {
      if (!templateId) {
        return undefined;
      }

      return getInvoiceTemplateEntity({ variables: { id: templateId } });
    };

    return {
      invoiceSettingsTemplateEntity: invoiceSettingsTemplate,
      invoiceSettingsTemplateEntityLoading: invoiceSettingsTemplateEntityResults.loading,
      onFetchInvoiceSettingsTemplateEntity,
    };
  },
  useTaxSettings: () => {
    const { data: firmTaxSettingsData } = useCacheQuery(InitFirmTaxSettings.query);

    const { taxRate: firmTaxRateBasisPoints = 0 } = firmTaxSettingsData?.firmTaxSettings || {};

    return {
      firmTaxRateBasisPoints,
    };
  },
  useActivityCodes: () => {
    const activityCodesResult = useCacheQuery(InitActivityCodes.query, {
      // The variables must match init query
      variables: {
        includeUtbmsCodes: hasFacet(facets.utbms),
        isUtbmsEnabledCheck: true,
      },
    });

    return {
      activityCodes: activityCodesResult?.data?.activityCodes,
    };
  },
  useBankAccountSettings: () => {
    const { data: bankAccountSettingsData } = useCacheQuery(InitBankAccountSettings.query);

    const { bankBalanceType, defaultTrustAccounts } = bankAccountSettingsData?.bankAccountSettings || {};

    return {
      bankBalanceType,
      defaultTrustAccountForLocations: defaultTrustAccounts,
    };
  },
  usePreferredBankAccountTypes: () => {
    // We have bulk finalise only in US we need preferredBankAccountTypes in all regions as they are used in the form validation
    const { data: bulkFinalizeSettingsData } = useCacheQuery(InitBulkFinalizeSettings.query);

    const { preferredBankAccountTypes } = bulkFinalizeSettingsData?.bulkFinalizeSettings || {};

    return {
      preferredBankAccountTypes,
    };
  },
  useUtbmsSettings: () => {
    const { data: firmUtbmsSettingsData } = useCacheQuery(InitFirmUtbmsSettings.query, {
      skip: !hasFacet(facets.utbms),
    });

    const { isUtbmsEnabled } = firmUtbmsSettingsData?.firmUtbmsSettings || {};

    return {
      isUtbmsEnabledForFirm: !!isUtbmsEnabled,
    };
  },
  usePaymentProviderSettings: () => {
    const { data: paymentProviderSettingsData } = useCacheQuery(InitPaymentProviderSettings.query);

    const { activeProvider, providers } = paymentProviderSettingsData?.paymentProviderSettings || {};
    const activeProviderFormattedSettings = activeProvider
      ? convertSettingsFromGQL({ providerType: activeProvider, formattedSettingsFromGQL: providers?.[activeProvider] })
      : {};

    return {
      activeProviderType: activeProvider,
      activeProviderFormattedSettings,
    };
  },
});

const dependentHooks = () => ({
  useBankAccountsWithBalances: ({ matterId, bankBalanceType, matter, defaultTrustAccountForLocations }) => {
    const { t } = useTranslation();
    const isMatterContactBalanceType = bankBalanceType === BALANCE_BY_NAME[BALANCE_TYPE.matterContact];

    const { data: bankAccountsData, error: bankAccountsError } = useSubscribedQuery(BankAccountsWithBalances, {
      variables: {
        includeContactBalances: isMatterContactBalanceType,
        includeMatterBalances: true,
        balanceFilter: {
          matterId,
        },
        bankAccountFilter: {
          accountTypes: [bankAccountTypeEnum.TRUST, bankAccountTypeEnum.OPERATING, bankAccountTypeEnum.CREDIT],
          state: [bankAccountStateByValue[bankAccountState.OPEN]],
        },
      },
    });

    const { data: trustChequePrintSettingsData } = useCacheQuery(InitTrustChequePrintSettings.query);
    const allTrustChequePrintSettings = trustChequePrintSettingsData?.trustChequePrintSettings || [];

    if (bankAccountsError) {
      throw new Error(bankAccountsError);
    }
    const bankAccountsWithBalances = bankAccountsData?.bankAccounts || [];

    const defaultTrustAccount = findDefaultTrustAccountForMatter({
      matterLocationId: getLocationFromMatterType(matter?.matterType, matter?.matterTypeId),
      matterDefaultTrustAccountId: matter?.billingConfiguration?.defaultTrustAccountId,
      activeTrustAccounts: bankAccountsWithBalances.filter(
        (account) => account.accountType === bankAccountTypeEnum.TRUST,
      ),
      defaultTrustAccountForLocations,
    });

    const protectedTrustFundsAmount =
      defaultTrustAccount?.bankAccountBalances?.matterBalances?.[0]?.protectedBalance || 0;

    const { balances, trustAccountsDisabled, matterTrustBalance, isBalanceAvailable } = constructBalances({
      bankAccountsWithBalances,
      defaultTrustAccountId: defaultTrustAccount?.id,
      isMatterContactBalanceType,
      t,
    });

    const trustAccountChequeSettings =
      defaultTrustAccount?.id && allTrustChequePrintSettings.find((settings) => settings.id === defaultTrustAccount.id);
    const trustChequeEnabled = (trustAccountChequeSettings || getDefaultTrustChequePrintSettings({ region: REGION }))
      .printingActive;

    return {
      balances,
      trustAccountsDisabled,
      matterTrustBalance,
      isBalanceAvailable,
      trustChequeEnabled,
      protectedTrustFundsAmount,
    };
  },
  useLastInvoice: ({ matterId, isNewInvoice, invoice, matter }) => {
    // We use same function to init selectedContacts in draft invoice form
    const selectedContactIds = getDebtorsFromInvoiceOrMatter({ invoice, matter }).map((c) => c.id);

    // We need to wait until matter and invoice (unless it is new invoice) is loaded otherwise the selected contacts above may be incorrect
    const shouldLoad =
      featureActive('BB-7210') && matter?.id && (isNewInvoice || invoice?.id) && selectedContactIds.length > 0;

    const { data: invoiceForDebtorsData, loading } = useSubscribedQuery(InvoiceForDebtors, {
      skip: !shouldLoad,
      variables: {
        filter: {
          matterIds: [matterId],
          statuses: [invoiceStatus.FINAL, invoiceStatus.PAID],
          allDebtorIds: selectedContactIds,
        },
        limit: 1,
        sort: {
          fieldNames: ['issuedDate'],
          directions: ['DESC'],
        },
      },
    });

    return {
      lastInvoice: invoiceForDebtorsData?.invoiceForDebtorsList?.results?.[0],
      lastInvoiceLoading: loading,
    };
  },
});

export const BillingDraftInvoiceRouteContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(dependentHooks)(BillingDraftInvoiceRouteFormsContainer))),
);

BillingDraftInvoiceRouteContainer.displayName = 'BillingDraftInvoiceRouteContainer';

BillingDraftInvoiceRouteContainer.propTypes = {
  matterId: PropTypes.string.isRequired,
  invoiceId: PropTypes.string,
  onClickLink: PropTypes.func.isRequired,
  overrideRedirect: PropTypes.func.isRequired,
  closeCurrentTab: PropTypes.func.isRequired,
  preselectedExpenseIds: PropTypes.arrayOf(PropTypes.string),
  preselectedFeeIds: PropTypes.arrayOf(PropTypes.string),
  // The sbAsyncOperationsService is needed (temporarily until an alternative is found)
  //  * This is required by the LOD Expense modal
  //  * More specifically, it is required by the LOD Print Operating Cheque modal, which can be opened after saving an expense
  sbAsyncOperationsService: PropTypes.object.isRequired,
};

BillingDraftInvoiceRouteContainer.defaultProps = {
  invoiceId: undefined,
  preselectedExpenseIds: undefined,
  preselectedFeeIds: undefined,
};
