'use strict';

const integrations = Object.freeze({
  MYOB: 'MYOB',
  XERO: 'XERO',
  QUICKBOOKS: 'QUICKBOOKS',
});

const integrationNames = Object.freeze({
  [integrations.MYOB]: 'MYOB',
  [integrations.XERO]: 'Xero',
  [integrations.QUICKBOOKS]: 'QuickBooks',
});

module.exports = {
  integrations,
  integrationNames,
};
