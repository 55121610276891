import { gql } from '@apollo/client';

const query = gql`
  query SupportDataExplorerCorrespondenceHistory($id: ID!) {
    correspondenceHistory(id: $id) {
      id
      relatedItems {
        id
        type
        contactEntity {
          id
          displayNameFirstLast
        }
      }
      operationType
      from
      to
      cc
      bcc
      subject
      body
      internalNote
      user {
        id
        person {
          id
          name
        }
      }
      matter {
        id
      }
      sentTimestamp
      lastUpdated
      status
      errorMessage
      exceptionDetails
      sentVia
    }
  }
`;

const notificationIds = ['BillingSharedNotifications.CorrespondenceHistoryUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const SupportDataExplorerCorrespondenceHistory = {
  query,
  notificationIds,
};
