import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  CollapsibleHeader,
  PaginationSelector,
  PanelExpander,
  Spinner,
  ToggleListFilter,
  useTranslation,
} from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';
import { MatterRecentOnlyFilter, MatterTable, MatterTypeSelector, FilterExpandCollapse } from 'web/components';
import { StaffSelectorList } from '@sb-firm-management/react';
import { AddLeadModal } from 'web/react-redux';
import Styles from './BillingLeadsRoute.module.scss';

export const BillingLeadsRoute = memo((props) => {
  const { t } = useTranslation();
  const { matterFilterScope, onClickLink, expanded, toggleExpanded } = props;
  const { matters, mattersLoading } = props;
  const { isPaginationHidden, mattersCurrentPage, mattersTotalNumberOfPages, mattersOnPageChange } = props;
  const { mattersOnSort, mattersSortBy, mattersSortDirection } = props;
  const { staffMembers, staffSelectedIds, staffOnToggle, staffOnSelect, staffOnDeselectAll } = props;
  const {
    mattersOnSelectStatus,
    mattersSelectedStatusIds,
    mattersStatusAllLabel,
    mattersStatusOptions,
    matterStatusCounts,
  } = props;
  const { matterTypes, matterTypesOnSelect, matterTypeSelectedIds } = props;
  const { matterRecentlyViewed, matterRecentlyViewedToggle } = props;
  const { hasBillingAccess } = props;

  const [showAddLeadsModal, setShowAddLeadsModal] = useState(false);

  return (
    <div className={Styles.billingLeadsRoute}>
      <div className="page-top-bar">
        <div className="page-name">
          <h1 className="page-name-heading">Leads</h1>
        </div>
        <div className="sb-tab-header">
          <ul className="nav nav-pills">
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => onClickLink({ type: 'matterList' })}>Matters</a>
            </li>
            <li className="active">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>Leads</a>
            </li>
          </ul>
        </div>
      </div>
      <div className={classnames('master-detail-panel', Styles.mainBody)}>
        {/* Start Filter Panel */}
        <div className={Styles.leadsSidePanel}>
          <FilterExpandCollapse className="panel-filter" scope={matterFilterScope}>
            {({ hiddenFilters, onToggleFilterVisibility }) => (
              <PanelExpander className="panel-filter" expanded={expanded} toggleExpanded={toggleExpanded}>
                {/* Matter Type Filter */}
                <CollapsibleHeader
                  text="Lead Type"
                  onClick={onToggleFilterVisibility}
                  name="matterType"
                  collapsed={hiddenFilters?.matterType}
                >
                  {matterTypes ? (
                    <MatterTypeSelector
                      matterTypes={matterTypes}
                      onSelectMatterType={matterTypesOnSelect}
                      selectedMatterTypeIds={matterTypeSelectedIds}
                    />
                  ) : (
                    <Spinner className={Styles.leadTypesLoading} small />
                  )}
                </CollapsibleHeader>

                {/* Matter Status Filter */}
                <CollapsibleHeader
                  text="Lead Status"
                  onClick={onToggleFilterVisibility}
                  name="matterStatus"
                  collapsed={hiddenFilters?.matterStatus}
                >
                  <MatterRecentOnlyFilter
                    recentlyViewed={matterRecentlyViewed}
                    toggleRecentlyViewed={matterRecentlyViewedToggle}
                  />
                  <ToggleListFilter
                    onSelect={mattersOnSelectStatus}
                    options={mattersStatusOptions}
                    optionCounts={matterStatusCounts}
                    selected={mattersSelectedStatusIds}
                    showAllLabel={mattersStatusAllLabel}
                    showCount
                    countDeleted
                  />
                </CollapsibleHeader>

                {/* Attorney Responsible Filter */}
                <CollapsibleHeader
                  text={t('personResponsible')}
                  onClick={onToggleFilterVisibility}
                  name="attorneyResponsible"
                  collapsed={hiddenFilters?.attorneyResponsible}
                >
                  <StaffSelectorList
                    staff={staffMembers}
                    selectedStaffIds={staffSelectedIds}
                    onToggleStaff={staffOnToggle}
                    onSelectStaff={staffOnSelect}
                    onDeselectAllStaff={staffOnDeselectAll}
                    showNoneOption
                  />
                </CollapsibleHeader>
              </PanelExpander>
            )}
          </FilterExpandCollapse>
        </div>
        {/* End Filter Panel */}

        <div className={classnames('panel-body', Styles.panelContent)}>
          <MatterTable
            allowTrustOverdraw={false}
            isLeads
            showFinancials={hasBillingAccess && featureActive('BB-6595')}
            matters={matters}
            mattersLoading={mattersLoading}
            contactsAsLink
            dataLoading={mattersLoading}
            onClickLink={onClickLink}
            sort={mattersOnSort}
            sortBy={mattersSortBy}
            sortDirection={mattersSortDirection}
            setShowAddLeadsModal={setShowAddLeadsModal}
          />
          <PaginationSelector
            name="leads"
            className={Styles.paginationSection}
            hidePagination={isPaginationHidden}
            numberOfPagesDisplayed={10}
            selectedPage={mattersCurrentPage}
            totalNumberOfPages={mattersTotalNumberOfPages}
            onPageChange={mattersOnPageChange}
          />
        </div>
      </div>
      {showAddLeadsModal && <AddLeadModal onClose={() => setShowAddLeadsModal(false)} />}
    </div>
  );
});

BillingLeadsRoute.displayName = 'BillingLeadsRoute';
BillingLeadsRoute.propTypes = {
  expanded: PropTypes.bool.isRequired,
  isPaginationHidden: PropTypes.bool.isRequired,
  hasBillingAccess: PropTypes.bool.isRequired,
  matterFilterScope: PropTypes.string.isRequired,
  matters: PropTypes.arrayOf(PropTypes.object).isRequired,
  mattersLoading: PropTypes.bool.isRequired,
  mattersOnSort: PropTypes.func.isRequired,
  mattersOnSelectStatus: PropTypes.func.isRequired,
  mattersSelectedStatusIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  mattersStatusAllLabel: PropTypes.string.isRequired,
  matterStatusCounts: PropTypes.object.isRequired,
  mattersStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,
  mattersSortBy: PropTypes.string.isRequired,
  mattersSortDirection: PropTypes.string.isRequired,
  mattersCurrentPage: PropTypes.number.isRequired,
  mattersOnPageChange: PropTypes.func.isRequired,
  mattersTotalNumberOfPages: PropTypes.number.isRequired,
  matterRecentlyViewed: PropTypes.bool.isRequired,
  matterRecentlyViewedToggle: PropTypes.func.isRequired,
  matterTypes: PropTypes.arrayOf(PropTypes.object),
  matterTypesOnSelect: PropTypes.func.isRequired,
  matterTypeSelectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClickLink: PropTypes.func.isRequired,
  staffMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      initials: PropTypes.string,
    }),
  ).isRequired,
  staffSelectedIds: PropTypes.object.isRequired,
  staffOnToggle: PropTypes.func.isRequired,
  staffOnSelect: PropTypes.func.isRequired,
  staffOnDeselectAll: PropTypes.func.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  usedBaseMatterTypeIdsMap: PropTypes.object,
};

BillingLeadsRoute.defaultProps = {
  matterTypes: undefined,
  usedBaseMatterTypeIdsMap: {},
};
