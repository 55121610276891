import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, ClickableIcon } from '@sb-itops/react';
import { CreateEditContactForm } from 'web/react-redux/components/contact-create-edit';
import Styles from './AddContactInlineForm.module.scss';

export const AddContactInlineForm = React.memo(({ onSave, onClose, isSubmitDisabled, scope, showBankDetailsTab }) => (
  <div className={Styles.contactFormContainer}>
    <h3 className={Styles.header}>
      New Contact{' '}
      <ClickableIcon
        className={classnames('icon-bin-2', Styles.binIcon)}
        onClick={() => {
          onClose();
        }}
      />
    </h3>
    <CreateEditContactForm showBankDetailsTab={showBankDetailsTab} scope={scope} inline />
    <Button
      className={Styles.saveButton}
      onClick={onSave}
      disabled={isSubmitDisabled}
      form={scope}
      locked={isSubmitDisabled}
    >
      Save New Contact
    </Button>
  </div>
));

AddContactInlineForm.displayName = 'AddContactInlineForm.2';

AddContactInlineForm.propTypes = {
  scope: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  showBankDetailsTab: PropTypes.bool,
};

AddContactInlineForm.defaultProps = {
  isSubmitDisabled: false,
  showBankDetailsTab: false,
};
