import { gql } from '@apollo/client';

const query = gql`
  query IntegrationFailedRequests($filter: IntegrationFailedRequestFilter) {
    integrationFailedRequests(filter: $filter) {
      id
      accountId
      integrationTarget
      type
      isIgnored
      isResolved
      replayInProgress
      timestamp
    }
  }
`;

const notificationIds = ['BillingIntegrationsNotifications.IntegrationFailedRequestUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const IntegrationFailedRequests = {
  query,
  notificationIds,
};
