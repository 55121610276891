import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { INVOICE_STATEMENT_ADD_PAYMENT_MODAL_ID } from 'web/components';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withApolloClient, withReduxProvider } from 'web/react-redux/hocs';
import {
  AddPaymentModalContactDetails,
  AddPaymentModalInvoiceSummaries,
  InitPaymentProviderSettings,
  InitOperatingBankAccount,
  InitStaffSettings,
  InitFirmDetails,
  InvoiceStatementData,
} from 'web/graphql/queries';
import {
  useCacheQuery,
  useContactTypeaheadData,
  useSubscribedLazyQuery,
  useSubscribedQuery,
  useFirmUtbmsSettings,
} from 'web/hooks';
import {
  convertSettingsFromGQL,
  isPaymentProviderEnabledForBankAccount,
} from '@sb-billing/business-logic/payment-provider/services';
import { useTranslation } from '@sb-itops/react';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { PAYMENT_TYPE, PAYMENT_SOURCE, getDirectPaymentOptions } from '@sb-billing/business-logic/payment-source';
import {
  paymentMethodTypes,
  paymentMethodTypesByProvider,
} from '@sb-billing/business-logic/payment-provider/entities/constants';
import { paymentMethods as lawpayPaymentMethods } from '@sb-billing/business-logic/payment-provider/services/lawpay';
import { todayAsInteger } from '@sb-itops/date';
import { InvoiceStatementAddPaymentModalFormsContainer } from './InvoiceStatementAddPaymentModal.forms.container';

const hooks = () => ({
  useAddPaymentModalProps: () => {
    const onModalClose = () => setModalDialogHidden({ modalId: INVOICE_STATEMENT_ADD_PAYMENT_MODAL_ID });

    return {
      onModalClose,
    };
  },
  useInvoiceStatementData: ({ invoiceStatementId }) => {
    const { data: invoiceStatementData, loading: invoiceStatementLoading } = useSubscribedQuery(InvoiceStatementData, {
      variables: {
        invoiceStatementId,
      },
    });

    const { number: invoiceStatementNumber, debtor, invoices } = invoiceStatementData?.invoiceStatement || {};
    const debtorId = debtor?.id;
    const invoiceIds = invoices?.map((i) => i.id);

    return {
      invoiceStatementLoading,
      invoiceStatementNumber,
      debtorId,
      defaultDebtor: debtor,
      invoiceIds,
    };
  },
  useFirmUtbmsSettings: () => {
    const { isUtbmsEnabledForFirm } = useFirmUtbmsSettings();
    return {
      isUtbmsEnabledForFirm,
    };
  },
  useStaffData: () => {
    const { data: staffData } = useCacheQuery(InitStaffSettings.query);
    const loggedInStaffMember = staffData?.loggedInStaff || {};

    return {
      loggedInStaffName: loggedInStaffMember.name,
    };
  },
  useFirmData: () => {
    const { data: firmData } = useCacheQuery(InitFirmDetails.query);
    const firm = firmData?.firm || {};

    return {
      firmName: firm.name,
    };
  },
  useContactDetails: () => {
    const [getContactDetails, contactDetailsResult] = useSubscribedLazyQuery(AddPaymentModalContactDetails, {
      variables: {},
    });

    const onGetPaidByContactDetails = (contactId) => {
      if (!contactId) {
        return;
      }
      getContactDetails({
        variables: { contactId },
      });
    };

    return {
      onGetPaidByContactDetails,
      paidByContactDetailsLoading: contactDetailsResult.loading,
      paidByContactDetails: contactDetailsResult.data?.contact,
    };
  },
  useContactTypeaheadData: () => {
    const {
      contactOptions: paidByContactOptions,
      contactOptionsDataLoading: paidByContactOptionsDataLoading,
      contactOptionsHasMore: paidByContactOptionsHasMore,
      onFetchContactOptions: onFetchPaidByContactOptions,
      onFetchMoreContactOptions: onFetchMorePaidByContactOptions,
    } = useContactTypeaheadData();

    return {
      // Paid By field
      paidByContactOptions,
      paidByContactOptionsDataLoading,
      paidByContactOptionsHasMore,
      onFetchPaidByContactOptions,
      onFetchMorePaidByContactOptions,
    };
  },
  usePaymentProviderSettings: () => {
    const { data: paymentProviderSettingsData } = useCacheQuery(InitPaymentProviderSettings.query);

    const { activeProvider, providers } = paymentProviderSettingsData?.paymentProviderSettings || {};
    const activeProviderFormattedSettings = activeProvider
      ? convertSettingsFromGQL({ providerType: activeProvider, formattedSettingsFromGQL: providers?.[activeProvider] })
      : {};

    return {
      activeProviderType: activeProvider,
      activeProviderFormattedSettings,
    };
  },
  useOperatingBankAccount: () => {
    const { data: operatingBankAccountData } = useCacheQuery(InitOperatingBankAccount.query);
    const operatingAccount = operatingBankAccountData?.bankAccounts?.[0];

    return {
      operatingAccount,
    };
  },
  useInvoiceSummaries: () => {
    const [getInvoiceSummaries, invoiceSummariesResult] = useSubscribedLazyQuery(AddPaymentModalInvoiceSummaries, {
      variables: {
        sort: {
          fieldNames: ['dueDate'],
          directions: ['ASC'],
        },
        paymentPlanStatusAsOfDate: todayAsInteger(),
      },
    });

    const results = invoiceSummariesResult.data?.invoiceList?.results;
    const invoiceSummaries = results || [];

    const onFetchInvoiceSummaries = ({ invoiceIds } = []) => {
      if (invoiceIds && invoiceIds.length === 0) {
        return [];
      }
      const invoiceFilter = { invoiceStatuses: ['FINAL'] };
      const ids = invoiceIds;

      return getInvoiceSummaries({ variables: { invoiceFilter, ids } });
    };

    return {
      invoiceSummariesLoading: invoiceSummariesResult.loading,
      invoiceSummaries,
      onFetchInvoiceSummaries,
    };
  },
});

const dependentHooks = () => ({
  usePaymentSourcesData: ({ activeProviderType, activeProviderFormattedSettings, operatingAccount }) => {
    const { t } = useTranslation();
    const paymentSourceOptions = getDirectPaymentOptions(t);

    const hasPaymentProviderConfiguredForSource = useCallback(
      (paymentSource) => {
        if (!operatingAccount?.id) {
          return false;
        }

        const getPaymentMethodFromSource = () => {
          // This is used for Lawpay only, other payment providers just ignore paymentMethod.
          // We just add it anyway to avoid check for specific payment provider.
          switch (paymentSource.paymentSource) {
            case PAYMENT_SOURCE.creditCard:
              return lawpayPaymentMethods.CREDIT_CARD;
            case PAYMENT_SOURCE.eCheck:
              return lawpayPaymentMethods.ECHEQUE;
            default:
              return null;
          }
        };

        return isPaymentProviderEnabledForBankAccount({
          formattedProviderSpecificSettings: activeProviderFormattedSettings,
          providerType: activeProviderType,
          bankAccountId: operatingAccount?.id,
          providerSpecificFields: { paymentMethod: getPaymentMethodFromSource() },
        });
      },
      [activeProviderFormattedSettings, activeProviderType, operatingAccount?.id],
    );

    const paymentSourceOptionsMemo = useMemo(() => {
      const paymentSources = [...paymentSourceOptions];

      const supportedPaymentMethodTypes = paymentMethodTypesByProvider[activeProviderType];
      const eChequePaymentSource = {
        label: t('echeque'),
        paymentType: PAYMENT_TYPE.direct,
        paymentSource: PAYMENT_SOURCE.eCheck,
        value: PAYMENT_SOURCE.eCheck,
      };

      if (
        hasFacet(facets.echeque) &&
        !!activeProviderType &&
        (supportedPaymentMethodTypes || []).includes(paymentMethodTypes.DIRECT_DEBIT) &&
        hasPaymentProviderConfiguredForSource(eChequePaymentSource)
      ) {
        paymentSources.push(eChequePaymentSource);
      }

      return paymentSources;
    }, [paymentSourceOptions, activeProviderType, hasPaymentProviderConfiguredForSource, t]);

    return {
      paymentSourceOptions: paymentSourceOptionsMemo,
      hasPaymentProviderConfiguredForSource,
    };
  },
});

export const InvoiceStatementAddPaymentModalContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(dependentHooks)(InvoiceStatementAddPaymentModalFormsContainer))),
);

InvoiceStatementAddPaymentModalContainer.propTypes = {
  invoiceStatementId: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  scope: PropTypes.string.isRequired,
};

InvoiceStatementAddPaymentModalContainer.defaultProps = {};

InvoiceStatementAddPaymentModalContainer.displayName = 'InvoiceStatementAddPaymentModalContainer';
