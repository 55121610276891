import { gql } from '@apollo/client';

const query = gql`
  query ProductivityFirmActivities($filter: ActivityFilter, $offset: Int, $limit: Int) {
    activityList(filter: $filter, offset: $offset, limit: $limit) {
      results {
        id
        user {
          id
          person {
            id
            initials
            name
          }
        }
        matter {
          id
          matterNumber
          status
          clientDisplayName @client
          clients {
            id
            displayName
          }
          description
          otherSide {
            id
            displayName
          }
          attorneyResponsible {
            id
            initials
          }
          matterType {
            matterTypeId
            name
          }
          isLead
        }
        isRemoved
        activityType
        activityData
        description
        dateTime
        billingFeeId
        durationInMinutes
      }
    }
  }
`;

const notificationIds = ['ItOps.Timeline'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const ProductivityFirmActivities = {
  query,
  notificationIds,
};
