import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, PaginationSelector, DropdownMenuButton, Spinner } from '@sb-itops/react';

import { CorrespondenceHistoryTable } from 'web/components';
import Styles from './MatterClientCommunications.module.scss';

export const MatterClientCommunications = ({
  /** Exports */
  exportMenuOptions,
  isGeneratingPDFReport,
  /** Table data */
  correspondenceHistory,
  correspondenceHistoryDataLoading,
  correspondenceHistorySortBy,
  correspondenceHistorySortDirection,
  // Callbacks
  onClickLink,
  onOpenCorrespondenceHistoryModal,
  onCorrespondenceHistoryOnSort,

  /** Pagination */
  correspondenceHistoryCurrentPage,
  correspondenceHistoryTotalNumberOfPages,
  hidePagination,
  // Callbacks
  onCorrespondenceHistoryOnPageChange,
}) => (
  <div className={classnames('panel-body', Styles.matterClientCommunications)}>
    <div className="ribbon panel panel-primary">
      {isGeneratingPDFReport && (
        <Button disabled>
          Export <Spinner small />
        </Button>
      )}
      {!isGeneratingPDFReport && <DropdownMenuButton label="Export" menuOptions={exportMenuOptions} />}
    </div>
    <div className={Styles.correspondenceHistoryTable}>
      <CorrespondenceHistoryTable
        correspondenceHistory={correspondenceHistory}
        dataLoading={correspondenceHistoryDataLoading}
        onClickLink={onClickLink}
        onClickRow={onOpenCorrespondenceHistoryModal}
        onSort={onCorrespondenceHistoryOnSort}
        sortBy={correspondenceHistorySortBy}
        sortDirection={correspondenceHistorySortDirection}
      />
    </div>
    <PaginationSelector
      name="matterClientCommunications"
      className={Styles.paginationSection}
      hidePagination={hidePagination}
      numberOfPagesDisplayed={10}
      selectedPage={correspondenceHistoryCurrentPage}
      totalNumberOfPages={correspondenceHistoryTotalNumberOfPages}
      onPageChange={onCorrespondenceHistoryOnPageChange}
    />
    <div className={Styles.correspondenceHistoryPrint}>
      <iframe id="correspondenceHistoryPdf" title="smokeball-report-viewer" />
    </div>
  </div>
);

MatterClientCommunications.displayName = 'MatterClientCommunications';

MatterClientCommunications.propTypes = {
  /** Exports */
  exportMenuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  isGeneratingPDFReport: PropTypes.bool,
  /** Table data */
  correspondenceHistory: PropTypes.arrayOf(PropTypes.object),
  correspondenceHistoryDataLoading: PropTypes.bool.isRequired,
  correspondenceHistorySortBy: PropTypes.string.isRequired,
  correspondenceHistorySortDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,
  // Callbacks
  onClickLink: PropTypes.func.isRequired,
  onOpenCorrespondenceHistoryModal: PropTypes.func.isRequired,
  onCorrespondenceHistoryOnSort: PropTypes.func.isRequired,

  /** Pagination */
  hidePagination: PropTypes.bool.isRequired,
  correspondenceHistoryCurrentPage: PropTypes.number.isRequired,
  correspondenceHistoryTotalNumberOfPages: PropTypes.number.isRequired,
  // Callbacks
  onCorrespondenceHistoryOnPageChange: PropTypes.func.isRequired,
};

MatterClientCommunications.defaultProps = {
  correspondenceHistory: undefined,
  isGeneratingPDFReport: undefined,
};
