import * as Yup from 'yup';
import { isDateReconciled } from '@sb-billing/business-logic/bank-reconciliation';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { customYupValidators } from '@sb-itops/business-logic/validation/services';

// Context we need to pass when validating:
// requiresReasonField
// checkIsStatutoryDepositMatter function
// bankReconciliationLatestCompleted
// bankReconciliationSetup
// bankAccountOptions
// matterTrustBankAccountIds
// matterTrustBalancesMap
// t function

// matterDetails
// matterClientRequiredForTrustDeposit
// matterDescriptionRequiredForTrustDeposit
// matterClientAddressRequiredForTrustDeposit
// matterClientOrDescriptionRequired
// clientsMissingAddresses

export const depositFundsFormSchema = Yup.object().shape({
  // entry row matter
  matter: Yup.object({
    id: customYupValidators.uuid().nullable(),
    displayName: Yup.string().nullable(),
  })
    .test('is-valid-matter', 'is-valid-matter', (matter, { createError, parent: formValues }) => {
      const { rows, amount } = formValues;
      const matterRows = Object.values(rows || {}).filter((row) => !!row?.matterId);

      // no matters added yet
      if (matterRows.length === 0 && !matter?.id) {
        // error matter if no matter selected
        return createError({
          path: 'matter.id',
          message: 'is-valid-matter',
        });
      }
      // some matters are already added
      if (matterRows.length > 0 && !matter?.id && Number.isInteger(amount) && amount > 0) {
        // error matter if amount is entered but no matter selected
        return createError({
          path: 'matter.id',
          message: 'is-valid-matter',
        });
      }

      return true;
    })
    .test('matter-already-exists', 'matter-already-exists', (matter, { createError, parent: formValues }) => {
      const { rows } = formValues;
      const matterRows = Object.values(rows || {}).filter((row) => !!row?.matterId);

      const existingMatter = matterRows.find((row) => row?.matterId === matter?.id);

      if (existingMatter) {
        return createError({
          path: 'matter.id',
          message: 'This matter already exists in the list',
        });
      }

      return true;
    })
    .test(
      'matter-valid-for-account',
      'matter-valid-for-account',
      (matter, { createError, options, parent: formValues }) => {
        const { bankAccountOptions, matterTrustBankAccountIds, t } = options.context || {};

        if (!matter?.id || matterTrustBankAccountIds === undefined) {
          // Skip validation if we don't have required data. Data are likely loading and validation will be triggered again later
          return true;
        }
        const { bankAccountId } = formValues;

        const bankAccount = bankAccountOptions?.find((ba) => ba.value === bankAccountId)?.data;
        const isTrustAccount = bankAccount?.accountType === bankAccountTypeEnum.TRUST;

        if (isTrustAccount && !matterTrustBankAccountIds?.includes(bankAccountId)) {
          return createError({
            path: 'matter.id',
            message: `Error: This matter does not have the selected ${t('trustAccount')?.toLowerCase()} available`,
          });
        }

        return true;
      },
    )
    .test('matter-valid-for-deposit', 'matter-valid-for-deposit', (matter, { createError, options }) => {
      const {
        matterDetails,
        matterClientRequiredForTrustDeposit,
        matterDescriptionRequiredForTrustDeposit,
        matterClientAddressRequiredForTrustDeposit,
        matterClientOrDescriptionRequired,
        clientsMissingAddresses,
        t,
      } = options.context || {};

      if (matterDetails === undefined) {
        // loading
        return true;
      }

      const errors = [];
      const hasClients = matterHasClients({ matterDetails, required: matterClientRequiredForTrustDeposit });
      const hasDescription = matterHasDescription({
        matterDetails,
        required: matterDescriptionRequiredForTrustDeposit,
      });

      if (matterClientOrDescriptionRequired && !(hasClients && hasDescription)) {
        if (hasClients && !hasDescription) {
          errors.push(
            `A matter description has not been added to the matter. Please add a description before making a ${t(
              'trust',
            )?.toLowerCase()} deposit.`,
          );
        } else if (!hasClients && hasDescription) {
          errors.push(
            `A client has not been added to the matter. Please add a client before making a ${t(
              'trust',
            )?.toLowerCase()} deposit.`,
          );
        } else {
          errors.push(
            `A matter description and client has not been added to the matter. Please add before making a ${t(
              'trust',
            )?.toLowerCase()} deposit.`,
          );
        }
      }

      if (matterClientAddressRequiredForTrustDeposit && clientsMissingAddresses.length > 0) {
        errors.push(...clientsMissingAddresses.map((client) => `contactId:${client.id}'s address is incomplete.`));
      }

      if (errors.length > 0) {
        return createError({
          path: 'matter.id',
          message: errors.join('\n'),
        });
      }

      return true;
    }),

  // entry row amount
  amount: Yup.number()
    .notRequired()
    .test('is-valid-sdm-amount', 'is-valid-sdm-amount', (amount, { createError, options, parent: formValues }) => {
      const { checkIsStatutoryDepositMatter, matterTrustBalancesMap, t } = options.context || {};

      const balancesLoading = matterTrustBalancesMap === undefined;
      const balanceAfterDeposit = (matterTrustBalancesMap?.[formValues?.bankAccountId]?.availableBalance || 0) + amount;
      const matterIsStatutoryDeposit = checkIsStatutoryDepositMatter({ matterId: formValues?.matter?.id });

      if (matterIsStatutoryDeposit && !balancesLoading && balanceAfterDeposit > 0) {
        return createError({
          message: `Statutory deposit matter balance cannot exceed ${t('currencySymbol')}0`,
        });
      }

      return true;
    })
    .test('is-valid-amount', 'is-valid-amount', (amount, { parent: formValues }) => {
      const { rows } = formValues;

      const matterRows = Object.values(rows || {}).filter((row) => !!row?.matterId);

      // no matters added yet
      if (matterRows.length === 0) {
        // amount must be a number greater than 0
        return Number.isInteger(amount) && amount > 0;
      }

      // some matters are already added
      if (matterRows.length > 0 && formValues?.matter?.id) {
        // when matter is selected, we require amount
        return Number.isInteger(amount) && amount > 0;
      }

      return true;
    }),

  // received from
  contact: Yup.object({
    id: customYupValidators.uuid().required(),
    displayName: Yup.string().notRequired(), // technically required, but we care only about id
  }),

  bankAccountId: Yup.string().required(),
  depositSourceId: Yup.string().required(),

  effectiveDate: customYupValidators
    .integerDate()
    .required()
    .test('is-reconciled', 'is-reconciled', (effectiveDate, { createError, options, parent: formValues }) => {
      if (Object.keys(options.context || {}).length === 0) {
        return true; // skip validation if no context is provided
      }

      const { bankAccountOptions, bankReconciliationLatestCompleted, bankReconciliationSetup } = options.context || {};
      const { bankAccountId } = formValues;

      const bankAccount = bankAccountOptions?.find((ba) => ba.value === bankAccountId)?.data;
      const isTrustAccount = bankAccount?.accountType === bankAccountTypeEnum.TRUST;

      if (
        isTrustAccount &&
        effectiveDate &&
        isDateReconciled({
          yyyymmdd: effectiveDate,
          bankReconciliationLatestCompleted,
          bankReconciliationSetup,
        })
      ) {
        return createError({
          message: 'Warning: the date selected has already been reconciled',
        });
      }

      return true;
    }),

  reason: Yup.string().when('$requiresReasonField', {
    is: true,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),

  comment: Yup.string().notRequired(),

  rows: Yup.lazy((rows) => {
    const emptyRowSchema = Yup.object().notRequired();
    // In Yup, we usually validate by keys, but here the keys are dynamic so can't really use them.
    // To go around it, we dynamically validate only values (rows) and not the row key
    if (!rows) {
      return emptyRowSchema;
    }

    const rowValidationObject = Yup.object({
      // When removing a row in the modal, we set the keys to undefined so for validation all must be as "notRequired"
      matterId: Yup.string().notRequired(),
      matterDisplay: Yup.string().notRequired(),
      bankAccountId: Yup.string()
        .notRequired()
        .test('row-bank-account-valid', 'row-bank-account-valid', (bankAccountId, { options }) => {
          // We can't use parent here as it points to a row, not the top level form values.
          const from = options?.from || []; // contains all the "parents"
          const formValues = from?.[from.length - 1]?.value; // we use the last which is top level
          if (formValues && formValues?.bankAccountId !== bankAccountId) {
            // This really shouldn't happen but if for some reason it does, we don't want to let them send the form
            return false;
          }
          return true;
        }),
      balance: Yup.number().notRequired(),
      amount: Yup.number()
        .notRequired()
        .test('row-amount-valid', 'row-amount-valid', (amount) => {
          if (!Number.isInteger(amount) || amount <= 0) {
            return false;
          }

          return true;
        })
        .test('row-is-valid-sdm-amount', 'row-is-valid-sdm-amount', (amount, { createError, options, parent: row }) => {
          const { checkIsStatutoryDepositMatter, t } = options.context || {};

          const balanceAfterDeposit = (row?.balance || 0) + amount;

          const matterIsStatutoryDeposit = checkIsStatutoryDepositMatter({ matterId: row?.matterId });

          if (matterIsStatutoryDeposit && balanceAfterDeposit > 0) {
            return createError({
              message: `Statutory deposit matter balance cannot exceed ${t('currencySymbol')}0`,
            });
          }

          return true;
        }),
    });

    const newEntries = Object.keys(rows).reduce((acc, rowKey) => {
      const row = rows[rowKey];
      // We need to skip normal row validation for rows which were removed (that is, they don't have matterId set)
      return {
        ...acc,
        [rowKey]: row.matterId ? rowValidationObject : emptyRowSchema,
      };
    }, {});

    return Yup.object().shape(newEntries);
  }),
});

const matterHasClients = ({ matterDetails, required }) => {
  if (!required) {
    // this function is used to display correct error so if matter client is not required
    // we treat it as that matter has clients
    return true;
  }

  return (matterDetails?.clients || []).length > 0;
};

const matterHasDescription = ({ matterDetails, required }) => {
  if (!required) {
    // this function is used to display correct error so if description is not required
    // we treat it as that matter has description
    return true;
  }

  return (matterDetails?.description || '').length > 0;
};
