import PropTypes from 'prop-types';
import * as React from 'react';

import { LinkableText } from '@sb-itops/react/linkable-text';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { interpolatedDescriptionRegexes } from '@sb-billing/business-logic/transactions/entities/constants';
import { useTranslation } from '@sb-itops/react';

import composeHooks from '@sb-itops/react-hooks-compose';

import InterpolatedDescriptionDisplay from './InterpolatedDescriptionDisplay';

const hooks = ({
  description,
  invoicesMap,
  bankAccountsMap,
  contactsMap,
  mattersMap,
  onClickLink,
  onNavigateAway,
}) => ({
  useInterpolatedDescriptionDisplay: () => {
    const { t } = useTranslation();
    const interpolationHandlers = {
      invoiceId: ({ id }) => {
        if (!(id in invoicesMap)) {
          throw new Error('Invoice info needs to be passed into invoicesMap before it can be interpolated');
        }
        const invoice = invoicesMap[id];
        return (
          <LinkableText
            text={`#${invoice?.invoiceNumber}`}
            onClickLink={() => {
              onClickLink({ type: 'invoice', id });
              onNavigateAway();
            }}
            asLink
            inline
            bold
          />
        );
      },
      accountId: ({ id }) => {
        if (!(id in bankAccountsMap)) {
          throw new Error('Bank account info needs to be passed into bankAccountsMap before it can be interpolated');
        }
        const bankAccount = bankAccountsMap[id];
        if (bankAccount?.accountType === bankAccountTypeEnum.OPERATING) {
          return (
            <LinkableText
              text={t('operating')}
              onClickLink={() => {
                onClickLink({ type: 'transactions', id: 'ledger' });
                onNavigateAway();
              }}
              asLink
              inline
              bold
            />
          );
        }
        if (bankAccount?.accountType === bankAccountTypeEnum.TRUST) {
          return (
            <LinkableText
              text={bankAccount?.accountName}
              onClickLink={() => {
                onClickLink({ type: 'transactions', id: bankAccount?.accountType?.toLowerCase(), params: [id] });
                onNavigateAway();
              }}
              asLink
              inline
              bold
            />
          );
        }
        return bankAccount?.accountName;
      },
      contactId: ({ id }) => {
        if (!(id in contactsMap)) {
          throw new Error('Contact info needs to be passed into contactsMap before it can be interpolated');
        }
        const contact = contactsMap[id];
        return (
          <LinkableText
            text={contact?.displayName}
            onClickLink={() => {
              onClickLink({ type: 'contact', id });
              onNavigateAway();
            }}
            asLink
            inline
            bold
          />
        );
      },
      matterId: ({ id }) => {
        if (!(id in mattersMap)) {
          throw new Error('Matter info needs to be passed into mattersMap before it can be interpolated');
        }
        const matter = mattersMap[id];
        return (
          <LinkableText
            text={matter?.matterNumber}
            onClickLink={() => {
              onClickLink({ type: 'matter', id });
              onNavigateAway();
            }}
            asLink
            inline
            bold
          />
        );
      },
    };

    const splitDescription = [];
    const matches = (description || '').matchAll(interpolatedDescriptionRegexes.ANY);
    let startIdx = 0;
    // matches will always match left to right in string
    // only supports when no interpolations are duplicated multiple times in same string
    matches.forEach((match, index) => {
      const [matchString] = match;
      const [type, id] = matchString.split(':');
      const interpolated = interpolationHandlers[stripHash(type)]({ id });
      // for each match, we know there are no interpolated values to the left of the match, after the last match, or the start if it is the first match
      // so we can just add the string section to the description
      splitDescription.push(description.slice(startIdx, description.indexOf(matchString)));
      startIdx = description.indexOf(matchString) + matchString.length;
      // then add the interpolated react element
      splitDescription.push(interpolated);
      // then if this is the last match, add the rest of the string
      if (index === matches.length - 1) {
        splitDescription.push(description.split(matchString)[1]);
      }
    });

    // Final splitDescription will be an array of strings and react elements that can be rendered in order
    return {
      splitDescription,
    };
  },
});

/**
 * Some types like invoiceId come with hashes that we will need to strip
 * The hashes need to be included in the regex as we want the hashes to be a part of the generated link
 *
 * @param {string} typeString
 * @returns {string}
 */
function stripHash(typeString) {
  if (!typeString) {
    return typeString;
  }
  return typeString.replace('#', '');
}

const InterpolatedDescriptionDisplayContainer = composeHooks(hooks)(InterpolatedDescriptionDisplay);

InterpolatedDescriptionDisplayContainer.propTypes = {
  description: PropTypes.string,
  bankAccountsMap: PropTypes.object,
  invoicesMap: PropTypes.object,
  onClickLink: PropTypes.func.isRequired,
  onNavigateAway: PropTypes.func,
};

InterpolatedDescriptionDisplayContainer.defaultProps = {
  description: undefined,
  bankAccountsMap: undefined,
  invoicesMap: undefined,
  onNavigateAway: () => {},
};

export default InterpolatedDescriptionDisplayContainer;
