import composeHooks from '@sb-itops/react-hooks-compose';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  InitUserBillingAttributes,
  BillingDashboardRecentMatters,
  DashboardInvoiceTotals,
  DashboardTotalsData,
  DashboardInvoicesCount,
  MatterAnyCheckData,
  UserTwoFactorStatus,
} from 'web/graphql/queries';
import moment from 'moment';
import { useSubscribedQuery, useDashboardFees, useCacheQuery } from 'web/hooks';
import { useDispatch } from 'react-redux';
import { status as invoiceStatus } from '@sb-billing/business-logic/invoice/entities';
import { owningCompanyBrandNames } from '@sb-itops/business-logic/white-label';

import { getActiveTrustAccounts } from '@sb-billing/redux/bank-account';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getLastCompleted as getLastCompletedBankRecon } from '@sb-billing/redux/bank-reconciliations';
import * as billsListFilters from 'web/redux/route/home-billing-bills-list';
import { forgetAccountStateParams } from 'web/redux/features/transactions-recent-account-selection/actions';
import { getMfaSetupUrl } from '@sb-itops/environment-config';
import { helpLinkByRegion } from 'web/components/login-xero-two-factor-notice/LoginXeroTwoFactorNotice.container';
import { getRegion } from '@sb-itops/region';
import { getOwningCompany, getUserId } from 'web/services/user-session-management';
import { getActiveProvider } from '@sb-billing/redux/payment-provider-settings/selectors';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { useState } from 'react';
import { sendMetric } from 'web/services/metrics';
import { withReduxProvider } from '../../hocs/withReduxProvider';
import { withApolloClient } from '../../hocs/withApolloClient';
import { BillingDashboardRoute } from './BillingDashboardRoute';

const WELCOME_MODAL_ID = 'welcomeModal';

const hooks = () => ({
  useInvoiceFilterActions: () => {
    const dispatch = useDispatch();
    return {
      forgetAccountPageParams: () => {
        dispatch(
          forgetAccountStateParams({
            parentPageName: 'home.billing.accounts.transactions',
          }),
        );
      },
      onFilterByRecent: (value) => {
        dispatch(billsListFilters.actions.onFilterByRecent(value));
      },
      onFilterInvoiceStatus: (status) => {
        dispatch(billsListFilters.actions.onFilterInvoiceStatus(status));
      },
    };
  },
  useGraphQLforRecentMatters: () => {
    const { data, loading, error } = useSubscribedQuery(BillingDashboardRecentMatters, {
      variables: {
        includeMatterBalance: false,
        filter: {
          includeNonBillableLeadMatters: true,
          includeDeleted: false,
          showUserRecentMatter: true,
        },
        sort: {
          fieldNames: ['recentlyViewed'],
          directions: ['DESC'],
        },
        limit: 5,
      },
    });

    if (error) {
      throw error;
    }
    return {
      recentMatters: {
        matters: data?.matterList?.results || [],
        loading,
      },
    };
  },
  useFirmTotals: () => {
    const {
      data: firmTotalsData,
      loading: firmTotalsLoading,
      error: firmTotalsError,
    } = useSubscribedQuery(DashboardTotalsData, {});
    const {
      data: invoicesData,
      loading: invoicesLoading,
      error: invoicesError,
    } = useSubscribedQuery(DashboardInvoiceTotals, {
      variables: {
        filter: {
          pivotDate: Number(moment().format('YYYYMMDD')),
          statuses: [invoiceStatus.FINAL],
        },
      },
    });

    if (firmTotalsError || invoicesError) {
      throw firmTotalsError || invoicesError;
    }

    const showControlledMoneyAccount = featureActive('BB-6381') && hasFacet(facets.CMA);

    return {
      accounts: {
        loading: invoicesLoading || firmTotalsLoading,
        totals: {
          credit: firmTotalsData?.firmBalancesSummary?.credit || 0,
          controlledMoney: (showControlledMoneyAccount && firmTotalsData?.firmBalancesSummary?.controlledMoney) || 0,
          trust: firmTotalsData?.firmBalancesSummary?.trust || 0,
          operating: firmTotalsData?.firmBalancesSummary?.operating || 0,
          unbilled: firmTotalsData?.firmBalancesSummary?.unbilled || 0,
          unpaid: firmTotalsData?.firmBalancesSummary?.unpaidExcInterest || 0,
          overdue: invoicesData?.invoiceTotalSummary?.overdue || 0,
        },
        showControlledMoneyAccount,
      },
    };
  },
  useUserBillingAttributesData: () => {
    const [instantDismissPaid, setInstantDismissPaid] = useState(false);
    const [instantDismiss2FA, setInstantDismiss2FA] = useState(false);
    const { data, error, loading: userViewedLoading } = useCacheQuery(InitUserBillingAttributes.query);

    const { data: userTwoFactorStatusData } = useSubscribedQuery(UserTwoFactorStatus, {
      skip: !featureActive('NUCWEB-799'),
      variables: {
        id: getUserId(),
      },
    });
    const { twoFactorEnabled } = userTwoFactorStatusData?.userTwoFactorStatus || {};

    if (error) {
      throw new Error(error);
    }

    const userViewedMessages = data?.userBillingAttributes?.viewedMessages || [];

    const getPaidScope = 'GET_PAID_PROMO';
    const twoFAScope = '2FA_PROMO';

    const dismissedPromoOnce = userViewedMessages.includes(getPaidScope);
    const dismissedPromoTwice = userViewedMessages.includes(`${getPaidScope}_1`);
    const dismissedPromoThrice = userViewedMessages.includes(`${getPaidScope}_2`);

    const showGetPaidPromoCard =
      featureActive('NUCWEB-793') &&
      !instantDismissPaid &&
      ((!dismissedPromoOnce && !userViewedLoading && !getActiveProvider()) || featureActive('NUCWEB-799-DEBUG'));

    const showGetPaidPromoBanner =
      !showGetPaidPromoCard &&
      featureActive('NUCWEB-793') &&
      !instantDismissPaid &&
      (((!dismissedPromoTwice || !dismissedPromoThrice) && !userViewedLoading && !getActiveProvider()) ||
        featureActive('NUCWEB-799-DEBUG'));

    const dismissed2FAOnce = userViewedMessages.includes(twoFAScope);
    const dismissed2FATwice = userViewedMessages.includes(`${twoFAScope}_1`);
    const dismissed2FAThrice = userViewedMessages.includes(`${twoFAScope}_2`);

    const show2FAPromoCard =
      featureActive('NUCWEB-799') &&
      !instantDismiss2FA &&
      ((!dismissed2FAOnce && !userViewedLoading && !twoFactorEnabled) || featureActive('NUCWEB-799-DEBUG'));

    const show2FAPromoBanner =
      !show2FAPromoCard &&
      featureActive('NUCWEB-799') &&
      !instantDismiss2FA &&
      (((!dismissed2FATwice || !dismissed2FAThrice) && !userViewedLoading && !twoFactorEnabled) ||
        featureActive('NUCWEB-799-DEBUG'));

    const onSetup2FA = () => {
      sendMetric('promoCardClicked', { scope: twoFAScope });
      const branding = getOwningCompany();
      const brandName = owningCompanyBrandNames[branding] || owningCompanyBrandNames.Smokeball;
      const mfaSetupUrl = getMfaSetupUrl();
      const mfaSetupUrlWithBrandName = `${mfaSetupUrl}${brandName}`;
      window.open(mfaSetupUrlWithBrandName, '_blank', 'noopener,noreferrer');
    };
    const onFindOutMore2FA = () => {
      sendMetric('promoCardFindOutMoreClicked', { scope: twoFAScope });
      const helpGuideUrl = helpLinkByRegion[getRegion()];
      window.open(helpGuideUrl, '_blank', 'noopener,noreferrer');
    };

    return {
      onSetup2FA,
      onFindOutMore2FA,
      showGetPaidPromoBanner,
      showGetPaidPromoCard,
      show2FAPromoBanner,
      show2FAPromoCard,
      onDismissPaid: () => {
        sendMetric('promoCardDismissed', { scope: getPaidScope });
        setInstantDismissPaid(true);

        if (!dismissedPromoOnce) {
          dispatchCommand({
            type: 'Billing.Shared.Messages.Commands.SaveBillingUserAttributes',
            message: { viewedMessage: getPaidScope },
          });
        } else if (!dismissedPromoTwice) {
          dispatchCommand({
            type: 'Billing.Shared.Messages.Commands.SaveBillingUserAttributes',
            message: { viewedMessage: `${getPaidScope}_1` },
          });
        } else if (!dismissedPromoThrice) {
          dispatchCommand({
            type: 'Billing.Shared.Messages.Commands.SaveBillingUserAttributes',
            message: { viewedMessage: `${getPaidScope}_2` },
          });
        }
      },
      onDismiss2FA: () => {
        sendMetric('promoCardDismissed', { scope: twoFAScope });
        setInstantDismiss2FA(true);

        if (!dismissed2FAOnce) {
          dispatchCommand({
            type: 'Billing.Shared.Messages.Commands.SaveBillingUserAttributes',
            message: { viewedMessage: twoFAScope },
          });
        } else if (!dismissed2FATwice) {
          dispatchCommand({
            type: 'Billing.Shared.Messages.Commands.SaveBillingUserAttributes',
            message: { viewedMessage: `${twoFAScope}_1` },
          });
        } else if (!dismissed2FAThrice) {
          dispatchCommand({
            type: 'Billing.Shared.Messages.Commands.SaveBillingUserAttributes',
            message: { viewedMessage: `${twoFAScope}_2` },
          });
        }
      },
      userViewedMessages,
    };
  },
  useInvoiceCount: () => {
    const {
      data: invoiceCount,
      loading: invoiceCountLoading,
      error: invoiceCountError,
    } = useSubscribedQuery(DashboardInvoicesCount, {
      variables: {
        pivotDate: Number(moment().format('YYYYMMDD')),
      },
    });
    if (invoiceCountError) {
      throw invoiceCountError;
    }

    return {
      invoiceData: {
        invoiceCount: invoiceCount?.invoiceCount || {},
        loading: invoiceCountLoading,
      },
    };
  },
  useBankRecData: () => {
    const trustAccounts = getActiveTrustAccounts();
    let lastReconciled = null;
    const numTransactionsNotReconciled = trustAccounts.reduce((count, trust) => {
      const lastCompletedBankRecon = getLastCompletedBankRecon(trust.id);
      if (!lastCompletedBankRecon) {
        return count;
      }
      const trustLastReconciled = lastCompletedBankRecon?.reconciledDate;
      if (trustLastReconciled && (!lastReconciled || lastReconciled < trustLastReconciled)) {
        lastReconciled = trustLastReconciled;
      }
      return count + (lastCompletedBankRecon?.unbankedTransactionIds?.length || 0);
    }, 0);
    return {
      lastReconciled,
      numTransactionsNotReconciled,
    };
  },
  useFeeListData: () => {
    const fees = useDashboardFees({});

    return {
      fees,
    };
  },
  useHasAnyMatter: () => {
    // Not using useSubscribedQuery as we dont want to sub to updates. After the welcome modal is closed this is no longer relevant anyway
    const { data, error } = useCacheQuery(InitUserBillingAttributes.query);
    if (error) {
      throw new Error(error);
    }

    const userViewedMessages = data?.userBillingAttributes?.viewedMessages || [];

    const skipHasMatterQuery = !featureActive('BB-8062') || userViewedMessages.includes(WELCOME_MODAL_ID);

    const {
      data: matterData,
      error: matterError,
      loading: hasMatterLoading,
    } = useQuery(MatterAnyCheckData.query, {
      skip: skipHasMatterQuery,
      variables: {
        matterListFilter: {
          excludeBillableLeadMatters: false,
          includeDeleted: true,
        },
        offset: 0,
        limit: 1,
      },
    });

    if (matterError) {
      throw matterError;
    }

    const hasMatter = !!matterData?.matterList?.results?.length;
    const showWelcomeModal = !skipHasMatterQuery && (!featureActive('BB-13823') || !(hasMatterLoading || hasMatter));

    return {
      showWelcomeModal,
    };
  },
});

export const BillingDashboardRouteContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(BillingDashboardRoute)),
);

BillingDashboardRouteContainer.propTypes = {
  onClickLink: PropTypes.func,
};

BillingDashboardRouteContainer.defaultProps = {
  onClickLink: () => {},
};
