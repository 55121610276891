import { useSort } from '@sb-itops/redux/sort/use-sort';
import { usePagination, useSubscribedQuery } from 'web/hooks';
import { CorrespondenceHistoryTableData } from 'web/graphql/queries';

// The correspondence history table data is used in multiple places: Firm Accounts, Matter Transactions. Instead of
// duplicating the same logic in those containers, it's consolidated in this hook

export function useCorrespondenceHistoryTableData({ scopePagination, scopeSorting, fetchLimit, filters }) {
  // Pagination
  const {
    currentPage: correspondenceHistoryCurrentPage,
    setPageNumber,
    getPagination,
  } = usePagination({
    scope: scopePagination,
    fetchLimit,
  });

  const correspondenceHistoryOnPageChange = ({ selected: pageNumber }) => setPageNumber(pageNumber);

  // Sorting
  const { sortBy, setSortBy, sortDirection, setSortDirection } = useSort({
    scope: scopeSorting,
    initialSortBy: 'sentTimestamp',
    initialSortDirection: 'desc',
  });

  const onSort = (sortProps) => {
    setSortBy(sortProps.sortBy);
    setSortDirection(sortProps.sortDirection);
  };

  // Query 1: correspondenceHistory
  const correspondenceHistoryQueryResult = useSubscribedQuery(CorrespondenceHistoryTableData, {
    context: { skipRequestBatching: true },
    variables: {
      filter: filters,
      offset: correspondenceHistoryCurrentPage * fetchLimit,
      limit: fetchLimit,
      sort: !sortBy ? undefined : { fieldNames: [sortBy], directions: [`${sortDirection || 'ASC'}`.toUpperCase()] },
    },
  });

  if (correspondenceHistoryQueryResult.error) {
    throw new Error(correspondenceHistoryQueryResult.error);
  }

  const { data: correspondenceHistoryData, loading } = correspondenceHistoryQueryResult;

  // Need to separate out the invoice and contact related items because react virtualized table doesn't support
  // sending anything that isn't the dataKey in the sort function
  // and we need to tell which column we're trying to sort on in the resolver
  const correspondenceHistory =
    correspondenceHistoryData?.correspondenceHistoryList?.results.map((history) => ({
      ...history,
      invoiceRelatedItems: history.relatedItems.filter((item) => !!item?.invoiceEntity?.id),
      contactRelatedItems: history.relatedItems.filter((item) => !!item?.contactEntity?.id),
    })) || [];
  const totalCount = correspondenceHistoryData?.correspondenceHistoryList?.totalCount || 0;

  const { hidePagination, totalNumberOfPages } = getPagination({
    totalCount,
    loading,
  });

  return {
    correspondenceHistory,
    correspondenceHistoryCurrentPage,
    correspondenceHistoryOnPageChange,
    correspondenceHistoryOnSort: onSort,
    correspondenceHistorySortBy: sortBy,
    correspondenceHistorySortDirection: sortDirection,
    correspondenceHistoryDataLoading: correspondenceHistory.length === 0 && loading,
    correspondenceHistoryTotalNumberOfPages: totalNumberOfPages,
    hidePagination,
  };
}
