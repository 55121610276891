import { gql } from '@apollo/client';

const query = gql`
  query AddPaymentModalInvoiceSummaries(
    $ids: [ID]
    $invoiceFilter: InvoiceListFilter
    $sort: ListSort
    $paymentPlanStatusAsOfDate: Int
  ) {
    invoiceList(ids: $ids, filter: $invoiceFilter, sort: $sort, paymentPlanStatusAsOfDate: $paymentPlanStatusAsOfDate) {
      results {
        id
        invoiceNumber
        dueDate
        matter {
          id
        }
        debtors {
          id
          contact {
            id
            displayName
            displayNameFull
          }
        }
        debtorTotals {
          invoiceId
          debtorId
          total
          interest
          paid
          unpaid
          unpaidExcInterest
        }
        totals {
          id
          total
          unpaid
          paid
          unpaidExcInterest
          interest
        }
        listItemProperties {
          hasUnpaidAnticipatedDisbursements
          activePaymentPlanDetails {
            id
            autoCharge
            behindOnDate
            debtorId
            installmentAmount
            installmentFrequency
            lastPaymentDate
            nextPaymentDate
            paymentPlanPosition {
              status
              paymentMethod {
                status
                statusDisplay
              }
            }
            status
          }
        }
        splitBillingSettings {
          isEnabled
          debtors {
            debtorId
            invoiceNumberSuffix
          }
        }
      }
    }
  }
`;

const notificationIds = [
  'WebQueryInvoicingNotifications.InvoiceUpdated',
  'WebQueryDebtorsNotifications.PaymentPlanUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const AddPaymentModalInvoiceSummaries = {
  query,
  notificationIds,
};
