// TODO: only show payments belong to this debtor

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  var BASE_DIRECTORY = 'ng-routes/home/billing/view-split-billing-bill/payments';

  $stateProvider

  .state('home.billing.view-split-billing-bill.payments', {
    url: '/payments',
    templateUrl: BASE_DIRECTORY + '/view-split-billing-bill-payments.html',
    data: {
      authorized: true,
      navHighlights: ['billing', 'bills'],
      tab: { type: 'invoice' }
    }
  });

});
