import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tooltip } from '@sb-itops/react';
import * as Icons from 'web/components/icons';
import NavTabMenu from './NavTabMenu';
import * as Styles from './NavTabs.module.scss';

const NavTab = ({
  id,
  primaryLabel,
  secondaryLabel,
  icon,
  tooltip,
  kbd,
  isActive,
  onNavigateToTab,
  onCloseTab,
  isTooltipOpen,
  showTooltip,
  hideTooltip,
  isContextOpen,
  showContext,
  hideContext,
  menuOptions,
}) => {
  const NewIcon = Icons[icon];

  return (
    <div
      onClick={onNavigateToTab}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      onContextMenu={showContext}
      className={classnames(Styles.navTab, {
        [Styles.active]: isActive,
      })}
    >
      <Tooltip
        className={Styles.fullHeight}
        html={<span>{tooltip}</span>}
        isOpen={isTooltipOpen}
        onRequestClose={hideTooltip}
      >
        <Tooltip
          html={<NavTabMenu tabId={id} menuOptions={menuOptions} />}
          isOpen={isContextOpen}
          onRequestClose={hideContext}
        >
          <div className={Styles.tabBody}>
            <div className={Styles.newIcon}>
              <NewIcon />
            </div>
            <span className={Styles.tabValue}>
              <span className={Styles.primaryLabel}>
                {primaryLabel}
                {kbd && <kbd>{kbd}</kbd>}
              </span>
              <span className={Styles.secondaryLabel}>{secondaryLabel}</span>
            </span>
            <span className={Styles.tabClose} onClick={onCloseTab}>
              &#10006;
            </span>
          </div>
        </Tooltip>
      </Tooltip>
    </div>
  );
};

NavTab.propTypes = {
  id: PropTypes.number.isRequired,
  primaryLabel: PropTypes.string,
  secondaryLabel: PropTypes.string,
  icon: PropTypes.string.isRequired,
  menuOptions: PropTypes.array.isRequired,
  tooltip: PropTypes.string.isRequired,
  onNavigateToTab: PropTypes.func.isRequired,
  onCloseTab: PropTypes.func.isRequired,
  kbd: PropTypes.string,
  isActive: PropTypes.bool,
  isTooltipOpen: PropTypes.bool.isRequired,
  showTooltip: PropTypes.func.isRequired,
  hideTooltip: PropTypes.func.isRequired,
  isContextOpen: PropTypes.bool.isRequired,
  showContext: PropTypes.func.isRequired,
  hideContext: PropTypes.func.isRequired,
};

NavTab.defaultProps = {
  primaryLabel: undefined,
  secondaryLabel: undefined,
  kbd: undefined,
  isActive: false,
};

NavTab.displayName = 'NavTab';

export default NavTab;
