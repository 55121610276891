import { gql } from '@apollo/client';

const query = gql`
  query TrustChequeDetailsModalData($id: ID!) {
    trustCheque(id: $id) {
      id
      chequeDate
      chequeMemo
      lastUpdated
      isTrustToOffice
      bankAccount {
        id
        accountName
        displayName
        accountType
      }
      payments {
        transaction {
          id
          amount
          type
          matter {
            id
            description
            status
            otherSideDisplay
            matterNumber
            clientDisplay
            matterType {
              name
              matterTypeId
            }
          }
          contact {
            id
            displayName
            displayNameFirstLast
          }
          description
          note
          reason
          reference
          source
          reversed
          bankAccount {
            id
          }
          user {
            id
            person {
              id
              name
            }
          }
          reconciliationId
          reconciliation {
            id
            status
          }
          reversedToTransaction {
            id
            reason
          }
          lastUpdated
        }
        # this can be invoice payment id OR vendor payment id
        # the Payment type is invoice payment, VendorPayment is vendor
        # https://dev.to/rohinivsenthil/graphql-unions-customize-your-results-54h5
        payment {
          __typename
          ... on Payment {
            id
            multiPaymentId
            reversalReason
            source
            sourceAccount {
              id
              accountName
              displayName
              accountType
            }
            destinationAccount {
              id
              accountName
              displayName
              accountType
            }
            payor {
              id
              displayName
              displayNameFirstLast
            }
            invoices {
              invoice {
                id
                invoiceNumber
                waived
                totals {
                  id
                  total
                }
              }
              amount
            }
          }
          ... on VendorPayment {
            id
            multiPaymentId
          }
        }
      }
    }
  }
`;

const notificationIds = ['AccountsNotifications.TrustChequeUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const TrustChequeDetailsModalData = {
  query,
  notificationIds,
};
