'use strict';

const PAYMENT_TYPE = Object.freeze({
  direct: 'Direct',
  trust: 'Trust',
  operating: 'Operating',
  credit: 'Credit',
});

module.exports = {
  PAYMENT_TYPE,
};
