import * as Yup from 'yup';

import { entryType } from '@sb-billing/business-logic/shared/entities';
import { customYupValidators } from '@sb-itops/business-logic/validation/services';
import { maxGraphQlInt } from '@sb-itops/business-logic/validation/constants';

export const feeFormSchema = Yup.object().shape({
  description: Yup.string(),
  // duration: form duration field value (editable by user)
  durationBilled: Yup.number().when('feeType', {
    is: entryType.FIXED,
    then: (duration) => duration.oneOf([0]).required(),
    otherwise: (duration) => duration.moreThan(0).lessThan(maxGraphQlInt).required(),
  }),
  durationWorked: Yup.number().when('feeType', {
    is: entryType.FIXED,
    then: (duration) => duration.oneOf([0]).required(),
    otherwise: Yup.number().when('$isTimeWorkedEnabled', {
      is: true,
      then: (schema) => schema.moreThan(0).lessThan(maxGraphQlInt).required(),
      otherwise: (schema) => schema,
    }),
  }),
  // durationInMins: fee entity duration value
  // For time fees, the value should be >0
  // For fixed fees, the value should be 0 (we handle this when we marshal the data as we need to preserve this value when switching between duration types to access the previous state)
  durationBilledInMins: Yup.number().integer().required(),
  feeActivityId: Yup.string(),
  feeDate: Yup.date().required(),
  staffId: customYupValidators.uuid().required(),
  feeType: Yup.number().oneOf(Object.values(entryType)).required(),
  isBillable: Yup.boolean().required(),
  isTaxExempt: Yup.boolean().required(),
  isTaxInclusive: Yup.boolean().required(),
  isWriteOff: Yup.boolean().required(),
  matterId: customYupValidators.uuid().required(),
  rateInCents: Yup.number().integer().required(),
  sourceItems: Yup.array(
    Yup.object({
      description: Yup.string().required(),
      durationBilled: Yup.number().moreThan(0).required(),
      durationWorked: Yup.number().nullable().moreThan(0),
      billable: Yup.boolean().required(),
      sourceActivityIds: Yup.array(Yup.string().required()).required(),
    }),
  ).required(),
  subject: Yup.string().min(1).max(1000).required(),
  activityId: Yup.string().when('utbmsCodesRequiredForMatter', {
    is: true,
    then: (activityId) => activityId.required(),
    otherwise: (activityId) => activityId.optional(),
  }),
  taskId: Yup.string().when(['isUtbmsActivity', 'utbmsCodesRequiredForMatter'], {
    is: (isUtbmsActivity, utbmsCodesRequiredForMatter) => isUtbmsActivity || utbmsCodesRequiredForMatter,
    then: (taskId) => taskId.required(),
    otherwise: (taskId) => taskId.optional(),
  }),
  // Derived values
  amountExclTaxInCents: Yup.number().required(),
  billableTaxAmountInCents: Yup.number(),
  taxAmountInCents: Yup.number(),
});
