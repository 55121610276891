import PropTypes from 'prop-types';
import { useState } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getContactDisplay as getContactDisplayFromContact } from '@sb-customer-management/business-logic/contacts/services';
import { sort } from '@sb-itops/sort';

import TrustChequeSplitPaymentsTable from './TrustChequeSplitPaymentsTable';

const hooks = ({ trustChequePayments, showInvoice, source }) => ({
  useTrustChequeSplitPaymentsTable: () => {
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortBy, setSortBy] = useState('paidBySort');

    const onSort = (sortProps) => {
      setSortDirection(sortProps.sortDirection);
      setSortBy(sortProps.sortBy);
    };

    const { paymentsList } = trustChequePayments.reduce(
      (acc, trustChequePayment) => {
        const { transaction, payment } = trustChequePayment;
        if (!payment) {
          return acc;
        }

        acc.paymentsList.push({
          invoice: showInvoice && payment?.invoices?.[0].invoice,
          amount: transaction?.amount,
          source: showInvoice ? source : transaction?.source,
          paidBy: transaction?.contact,
          paidBySort: getContactDisplayFromContact(transaction?.contact, { showLastNameFirst: true }),
        });

        return acc;
      },
      {
        paymentsList: [],
      },
    );

    return {
      payments: sort(paymentsList, [sortBy], [sortDirection]),
      sortDirection,
      sortBy,
      onSort,
    };
  },
});

const TrustChequeSplitPaymentsTableContainer = composeHooks(hooks)(TrustChequeSplitPaymentsTable);

TrustChequeSplitPaymentsTableContainer.propTypes = {
  payments: PropTypes.array,
  onClickLink: PropTypes.func,
  onNavigateAway: PropTypes.func,
  showPayor: PropTypes.bool,
};

TrustChequeSplitPaymentsTableContainer.defaultProps = {
  payments: [],
  onClickLink: () => {},
  onNavigateAway: () => {},
  showPayor: false,
};

TrustChequeSplitPaymentsTableContainer.displayName = 'TrustChequeSplitPaymentsTableContainer';

export default TrustChequeSplitPaymentsTableContainer;
