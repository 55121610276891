/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import classnames from 'classnames';
import { featureActive, featureData } from '@sb-itops/feature';
import {
  BarChartBig,
  Blocks,
  BookOpenCheck,
  CalendarDays,
  ChevronLeft,
  ChevronRight,
  Contact,
  HelpCircle,
  InvoiceIcon,
  LayoutDashboard,
  ListTimeline,
  ListTodo,
  Lock,
  MatterIcon,
  MessagesSquare,
  Settings,
  Timer,
} from 'web/components/icons';
import { getProductTier, getIsFirmOwner, getIsInternalUser } from 'web/services/user-session-management';
import { getSupportHubUrl } from '@sb-itops/environment-config';
import { Tooltip, Menu, MenuItem } from '@sb-itops/react';
import { manage as manageProvider } from '@sb-billing/business-logic/payment-provider/services/client-api';
import { getIntakeConfig } from 'web/services/intake-features';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { getActiveProvider } from '@sb-billing/redux/payment-provider-settings/selectors';
import { providers, providerNames } from '@sb-billing/business-logic/payment-provider/entities/constants';
import * as messageDisplay from '@sb-itops/message-display';
import { capitalizeAllWords } from '@sb-itops/nodash';
import { useSelector } from 'react-redux';
import { isBarUser } from '@sb-finance/redux/subscription';
import Styles from './BillingNav2.module.scss';

type ArgumentTypes = Parameters<typeof Tooltip>[0] & { expanded: boolean };

const ConditionalTooltip = ({ expanded, children, className, ...props }: ArgumentTypes) =>
  !expanded ? (
    <Tooltip {...props} className={className} position="right" interactive={false} delay={100}>
      {children}
    </Tooltip>
  ) : (
    <div className={className}>{children}</div>
  );

const showIntakeFileMenuItem = () => getIntakeConfig().showIntakeFileMenuItem;
const showFeeWiseMenuItem = () => getActiveProvider() === providers.FEE_WISE;

export const BillingNav2 = (props: {
  onGotoOrReset: (path: string) => void;
  loggedInStaffName: string;
  navHighlights?: { [key: string]: boolean };
  trustAccounts: any[];
  commUnreads: number | string;
  // buildVersion: string;
  // serverVersion: string;
  hasBillingAccess: boolean;
  showGlobalAccounts: boolean;
  showGlobalReports: boolean;
  // isTriConveyBranded: boolean;
  isMainMenuExpanded: boolean;
  toggleMainMenuExpanded: () => void;
  onClickLink: (options: any) => void;
}) => {
  const {
    onGotoOrReset,
    navHighlights,
    trustAccounts,
    commUnreads,
    loggedInStaffName,
    // buildVersion,
    // serverVersion,
    hasBillingAccess,
    showGlobalAccounts,
    showGlobalReports,
    isMainMenuExpanded,
    toggleMainMenuExpanded,
    // isTriConveyBranded,
    onClickLink,
  } = props;

  const [feeWiseMenuItemLoading, setFeeWiseMenuItemLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isBillTier = useSelector(() => ['SMK001'].includes(getProductTier()));
  const isBar = useSelector(isBarUser);
  const isBillFeatureLockOn = featureActive('NUCWEB-794');
  const showLock = isBillTier && isBillFeatureLockOn && isBar;

  const onFeeWiseMenuItemClick = async (event) => {
    event.stopPropagation();
    try {
      setFeeWiseMenuItemLoading(true);
      const response = await manageProvider({
        fetchPostP,
        providerType: providers.FEE_WISE,
        providerSpecificFields: { actionType: 'GET_MAGIC_LINK' },
      });
      const feeWiseDashboardUrl = response.url;
      window.open(feeWiseDashboardUrl, '_blank', 'noopener');
    } catch (err: any) {
      if (err.payload && err.payload.status === 404) {
        messageDisplay.error(`User account for ${providerNames.FEE_WISE} not found. Please contact support.`);
      } else {
        messageDisplay.error(`Failed to get management URL for ${providerNames.FEE_WISE} account`);
      }
    } finally {
      setFeeWiseMenuItemLoading(false);
      setAnchorEl(null);
    }
  };

  const activeStyle = (page) => (navHighlights?.[page] ? Styles.active : undefined);
  return (
    <div className={classnames(Styles.billingNav, Styles.newMenu, isMainMenuExpanded && Styles.expanded)}>
      <ul className={Styles.navigation}>
        {featureActive('NUCWEB-123') && (
          <ConditionalTooltip expanded={isMainMenuExpanded} title="Dashboard">
            <li
              onClick={() => onGotoOrReset('home.billing.dashboard')}
              className={classnames(activeStyle('dashboard'))}
            >
              <div className={Styles.menuItem}>
                <LayoutDashboard />
                {isMainMenuExpanded && <span>Dashboard</span>}
              </div>
            </li>
          </ConditionalTooltip>
        )}

        <ConditionalTooltip expanded={isMainMenuExpanded} title="Matters">
          <li onClick={() => onGotoOrReset('home.billing.matters')} className={classnames(activeStyle('matters'))}>
            <div className={Styles.menuItem}>
              <MatterIcon />
              {isMainMenuExpanded && <span>Matters</span>}
            </div>
          </li>
        </ConditionalTooltip>

        <ConditionalTooltip expanded={isMainMenuExpanded} title="Contacts">
          <li
            onClick={() => onGotoOrReset('home.billing.contacts.list')}
            className={classnames(activeStyle('contacts'))}
          >
            <div className={Styles.menuItem}>
              <Contact />
              {isMainMenuExpanded && <span>Contacts</span>}
            </div>
          </li>
        </ConditionalTooltip>

        {(featureActive('NUCWEB-82') || showLock) && (
          <ConditionalTooltip expanded={isMainMenuExpanded} title="Calendar">
            <li
              onClick={() => onGotoOrReset('home.billing.productivity.calendar')}
              className={classnames(activeStyle('calendar'), showLock && Styles.lockBackground)}
            >
              <div className={Styles.menuItem}>
                <CalendarDays />
                {isMainMenuExpanded && <span>Calendar</span>}
                {isMainMenuExpanded && showLock && (
                  <div className={Styles.lock}>
                    <Lock />
                  </div>
                )}
              </div>
            </li>
          </ConditionalTooltip>
        )}

        {(featureActive('NUCWEB-179') || showLock) && (
          <ConditionalTooltip expanded={isMainMenuExpanded} title="Tasks">
            <li
              onClick={() => onGotoOrReset('home.billing.productivity.tasks')}
              className={classnames(activeStyle('tasks'), showLock && Styles.lockBackground)}
            >
              <div className={Styles.menuItem}>
                <ListTodo />
                {isMainMenuExpanded && <span>Tasks</span>}
                {isMainMenuExpanded && showLock && (
                  <div className={Styles.lock}>
                    <Lock />
                  </div>
                )}
              </div>
            </li>
          </ConditionalTooltip>
        )}
        {featureActive('NUCWEB-894') && (
          <ConditionalTooltip expanded={isMainMenuExpanded} title="Activities">
            <li
              onClick={() => onGotoOrReset('home.billing.productivity.activities')}
              className={classnames(activeStyle('activities'))}
            >
              <div className={Styles.menuItem}>
                <ListTimeline fill="#4B5B81" />
                {isMainMenuExpanded && <span>Activities</span>}
              </div>
            </li>
          </ConditionalTooltip>
        )}

        {hasBillingAccess && (
          <ConditionalTooltip expanded={isMainMenuExpanded} title="Time/Fees">
            <li
              onClick={() => onGotoOrReset('home.billing.entries.time')}
              className={classnames(activeStyle('entries'))}
            >
              <div className={Styles.menuItem}>
                <Timer />
                {isMainMenuExpanded && <span>Time/Fees</span>}
              </div>
            </li>
          </ConditionalTooltip>
        )}

        {hasBillingAccess && (
          <ConditionalTooltip expanded={isMainMenuExpanded} title="Invoices">
            <li onClick={() => onGotoOrReset('home.billing.bills.list')} className={classnames(activeStyle('bills'))}>
              <div className={Styles.menuItem}>
                <InvoiceIcon />
                {isMainMenuExpanded && <span>Invoices</span>}
              </div>
            </li>
          </ConditionalTooltip>
        )}

        {hasBillingAccess && showGlobalAccounts && (
          <ConditionalTooltip expanded={isMainMenuExpanded} title="Accounts">
            <li
              onClick={() =>
                onGotoOrReset(`home.billing.accounts.transactions.${trustAccounts.length ? 'trust' : 'operating'}`)
              }
              className={classnames(activeStyle('accounts'))}
            >
              <div className={Styles.menuItem}>
                <BookOpenCheck />
                {isMainMenuExpanded && <span>Accounts</span>}
              </div>
            </li>
          </ConditionalTooltip>
        )}

        {hasBillingAccess && showGlobalReports && (
          <ConditionalTooltip expanded={isMainMenuExpanded} title="Reports">
            <li
              onClick={() => onGotoOrReset('home.billing.external-reports')}
              className={classnames(activeStyle('external-reports'))}
            >
              <div className={Styles.menuItem}>
                <BarChartBig />
                {isMainMenuExpanded && <span>Reports</span>}
              </div>
            </li>
          </ConditionalTooltip>
        )}

        {(featureActive('NUCWEB-98') || showLock) && (
          <ConditionalTooltip expanded={isMainMenuExpanded} title="Messages">
            <li
              className={classnames('communicate-badge', activeStyle('communicate'), showLock && Styles.lockBackground)}
              data-count={commUnreads}
            >
              <div className={Styles.menuItem} onClick={() => onGotoOrReset('home.billing.communicate')}>
                <div>
                  <MessagesSquare />
                </div>
                {isMainMenuExpanded && <span>Messages</span>}
                {isMainMenuExpanded && showLock && (
                  <div className={Styles.lock}>
                    <Lock />
                  </div>
                )}
              </div>
            </li>
          </ConditionalTooltip>
        )}
        {featureActive('NUCWEB-657') && featureData('NUCWEB-657') && (
          <ConditionalTooltip expanded={isMainMenuExpanded} title="Marketplace">
            <li className={classnames(activeStyle('marketplace'))}>
              <div className={Styles.menuItem} onClick={() => onGotoOrReset('home.billing.marketplace')}>
                <Blocks />
                {isMainMenuExpanded && <span>Marketplace</span>}
              </div>
            </li>
          </ConditionalTooltip>
        )}
        <ConditionalTooltip className={Styles.help} expanded={isMainMenuExpanded} title="Help">
          <li
            onClick={() => {
              if (featureActive('BB-12890')) {
                window.Brainfish.HelpWidget.open('brainfish-trigger-button');
                return;
              }

              const tier = getProductTier();
              const helpLink =
                (featureActive('BB-8892') && featureData('BB-8892')[tier || 'SMK001']) || getSupportHubUrl();
              window.open(helpLink, '_blank');
            }}
            className={Styles.help}
          >
            <div className={Styles.menuItem}>
              <HelpCircle />
              {isMainMenuExpanded && <span>Help</span>}
            </div>
          </li>
        </ConditionalTooltip>
        <ConditionalTooltip expanded={isMainMenuExpanded} title="Settings">
          <li
            onClick={(event) => {
              setAnchorEl(event.currentTarget.querySelector('#main-menu-icon') as HTMLElement);
            }}
          >
            <Menu id="basic-menu" anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
              <div className={Styles.menuTitle}>{`Hi ${loggedInStaffName}!`}</div>
              {hasBillingAccess && (
                <MenuItem
                  onClick={() => {
                    if (!featureActive('BB-7844')) {
                      onClickLink({ type: 'activities' });
                    } else {
                      onClickLink({ type: 'firmDetails' });
                    }
                    setAnchorEl(null);
                  }}
                >
                  Firm Settings
                </MenuItem>
              )}
              {featureActive('NUCWEB-360') && (
                <MenuItem
                  onClick={() => {
                    if (featureActive('BB-13182')) {
                      onClickLink({ type: 'accountSecurity' });
                      setAnchorEl(null);
                      return;
                    }

                    if (!featureActive('NUCWEB-450')) {
                      onClickLink({ type: 'emailSettings' });
                    } else {
                      onClickLink({ type: 'myDetails' });
                    }
                    setAnchorEl(null);
                  }}
                >
                  My Preferences
                </MenuItem>
              )}
              {featureActive('BB-13250') && ['SMK001', 'SMK004'].includes(getProductTier()) && getIsFirmOwner() && (
                <MenuItem
                  onClick={() => {
                    onClickLink({ type: 'manageSubscription' });
                    setAnchorEl(null);
                  }}
                >
                  My Subscription
                </MenuItem>
              )}
              {featureActive('BB-14064') && (
                <MenuItem
                  onClick={() => {
                    onClickLink({ type: 'paymentProviders' });
                    setAnchorEl(null);
                  }}
                >
                  Online Payments
                </MenuItem>
              )}
              {showIntakeFileMenuItem() && (
                <MenuItem
                  onClick={() => {
                    onClickLink({ type: 'intake' });
                    setAnchorEl(null);
                  }}
                >
                  Intake Forms
                </MenuItem>
              )}
              {featureActive('NUCWEB-662') && (
                <MenuItem
                  onClick={() => {
                    onClickLink({ type: 'dataMigration' });
                    setAnchorEl(null);
                  }}
                >
                  {featureActive('NUCWEB-900') && (getIsFirmOwner() || getIsInternalUser())
                    ? 'Data Import/Export'
                    : 'Data Import'}
                </MenuItem>
              )}
              {showFeeWiseMenuItem() && featureActive('BB-12038') && (
                <MenuItem
                  onClick={(event) => {
                    onFeeWiseMenuItemClick(event);
                    setAnchorEl(null);
                  }}
                >
                  {capitalizeAllWords(providerNames.FEE_WISE)}
                  {feeWiseMenuItemLoading && (
                    <span>
                      <i className="fa fa-spinner fa-spin" />
                    </span>
                  )}
                </MenuItem>
              )}
              <MenuItem onClick={() => onClickLink({ type: 'logout' })}>Logout</MenuItem>
            </Menu>
            <div className={Styles.menuItem}>
              <Settings id="main-menu-icon" />
              {isMainMenuExpanded && <span>Settings</span>}
            </div>
          </li>
        </ConditionalTooltip>
        <li className={Styles.separator} />
        <li className={Styles.expander} onClick={() => toggleMainMenuExpanded()}>
          <div className={Styles.menuItem}>{isMainMenuExpanded ? <ChevronLeft /> : <ChevronRight />}</div>
        </li>
      </ul>
    </div>
  );
};
