import { isEmpty } from 'lodash';
import * as invoiceEntities from '@sb-billing/business-logic/invoice/entities';
import { CORRESPONDENCE_STATUS, sentViaTypes } from '@sb-billing/business-logic/correspondence-history';
import { getPersonInitials } from '@sb-billing/business-logic/invoice-via-communicate';
import { isPaymentProviderEnabledForBankAccount, getActiveProvider, getAllSettings as getAllPaymentProviderSettings } from '@sb-billing/redux/payment-provider-settings/selectors';
import uuid from '@sb-itops/uuid';
import { featureActive } from '@sb-itops/feature';
import {
  getLatestInvoice,
} from '@sb-billing/redux/invoices';
import { getOperatingAccount } from '@sb-billing/redux/bank-account';
import { getStaffEmailDetails } from '@sb-firm-management/redux/firm-management';
import { dispatchCommand } from '@sb-integration/web-client-sdk';

import { opdateCache as opdateInvoiceCache, rollbackOpdateCache as rollbackOpdateInvoiceCache } from '@sb-billing/redux/invoices';
import { opdateCache as opdateExpenseCache, rollbackOpdateCache as rollbackOpdateExpenseCache } from '@sb-billing/redux/expenses';
import { opdateCache as opdateInvoiceTotalsCache, rollbackOpdateCache as rollbackOpdateInvoiceTotalsCache } from '@sb-billing/redux/invoice-totals';
import { opdateCache as opdatePaymentCache, rollbackOpdateCache as rollbackOpdatePaymentCache } from '@sb-billing/redux/payments';
import { opdateCache as opdateBankAccountBalancesCache, rollbackOpdateCache as rollbackOpdateBankAccountBalancesCache } from '@sb-billing/redux/bank-account-balances';

angular.module('@sb-billing/services').service('sbInvoiceCreateService', function (
  sbLoggerService, $rootScope, sbDateService, sbGenericEndpointService, sbInvoicingService, sbUuidService, 
  sbGstTaxSettingsService, sbCorrespondenceHistoryService, sbInvoiceSendService, sbLocalisationService ) {
  const svc = this;
  const log = sbLoggerService.getLogger('sbInvoiceCreateService');
  const saveInvoiceEndpoint = '/billing/invoice';

  svc.saveInvoiceP = saveInvoiceP;
  svc.t = sbLocalisationService.t;

  async function saveInvoiceP(invoiceVersion, quickPayments, sendInfo, isFinal, opdates, isBulkOperation = false) {
    function processSaveInvoiceRespP() {
      $rootScope.$broadcast('smokeball-saved-invoices');
      invoiceVersion.status = invoiceEntities.statusByValue[invoiceVersion.status]; // change number to string
      invoiceVersion.invoiceNumber = invoiceVersion.invoiceNumber || 'PENDING';
      return invoiceVersion;
    }

    // if we are editing an invoice that has been finalized already, we need to check if the new issue date is valid
    if (featureActive('BB-1761') && invoiceVersion.hasBeenFinalized) {
      checkInvoicePosition(invoiceVersion);
    }

    const isSendViaCommunicate = featureActive('BB-9097') && !isEmpty(sendInfo) && sendInfo.sendMethod === sentViaTypes.COMMUNICATE;
    let sendInfoAll
    // When send via communicate is enabled, and the sendMethod is communicate
    if (isSendViaCommunicate) {
      const communicateSendInfo = Array.isArray(sendInfo.communicateMessageDetails) && sendInfo.communicateMessageDetails.length > 0 ? sendInfo.communicateMessageDetails : sendInfo && [sendInfo];

      if (!isEmpty(communicateSendInfo)) {
        const invoiceCommunicateMessages = await Promise.all(communicateSendInfo.map(async communicateSentRequest => {
          // quickPaymentsTotalAmount is not used for interpolate at the moment since we use hardcode default message
          // but we might need to support templates and all placeholders later, so gather it here to prepare for future
          const quickPaymentsTotalAmount = quickPayments && quickPayments.length > 0
          ? quickPayments.reduce((acc, { amount }) => acc + amount, 0)
          : 0;
          const { message, linksMap } = await sbInvoiceSendService.getInterpolatedValuesForCommunicate({ invoiceCommunicateRequest: communicateSentRequest, preDraftMode: !!sendInfo.preDraftInvoiceId, quickPaymentsTotalAmount });

          const { email: staffEmailAddress } = getStaffEmailDetails({ userId: communicateSentRequest.template.fromUserId}) || {};

          return {
            invoiceIds: communicateSentRequest.invoiceIds,
            toAddress: communicateSentRequest.template.toAddress,
            replyToAddress: staffEmailAddress, // For updating correspondence history
            fromUserId: communicateSentRequest.template.fromUserId,
            message,
            linksMap,
            debtorId: communicateSentRequest.debtorId,
            debtorFirstName: communicateSentRequest.template.debtorFirstName,
            debtorLastName: communicateSentRequest.template.debtorLastName,
            correspondenceId: uuid(),
            sentVia: sentViaTypes.COMMUNICATE,
            matterIds: [invoiceVersion.matterId]
          }
        }));

        sendInfoAll = invoiceCommunicateMessages;
      }
    } else {
      const emailSendInfo = !isEmpty(sendInfo) && Array.isArray(sendInfo.emailDetails) && sendInfo.emailDetails.length > 0 ? sendInfo.emailDetails : sendInfo && [sendInfo];

      if (!isEmpty(emailSendInfo)) {
        // Create a total for quickPayments
        // This needs to be passed to the email template to optimistically subtract
        // this value from invoice/debtor owing amounts
        const quickPaymentsTotalAmount = quickPayments && quickPayments.length > 0
          ? quickPayments.reduce((acc, { amount }) => acc + amount, 0)
          : 0;

        const invoiceEmails = await Promise.all(emailSendInfo.map(async invoiceEmailRequest => {
          // Get interpolated values
          const { subject, message } = await sbInvoiceSendService.getInterpolatedValuesForEmail({ invoiceEmailRequest, preDraftMode: !!sendInfo.preDraftInvoiceId, quickPaymentsTotalAmount });

          return {
            invoiceIds: invoiceEmailRequest.invoiceIds,
            toAddress: invoiceEmailRequest.template.toAddress,
            replyToAddress: invoiceEmailRequest.template.replyToAddress,
            bcc: invoiceEmailRequest.template.bcc,
            cc: invoiceEmailRequest.template.cc,
            subject,
            message,
            debtorId: invoiceEmailRequest.debtorId,
            correspondenceId: uuid(),
            sentVia: sentViaTypes.EMAIL,
            matterIds: [invoiceVersion.matterId]
          }
        }));

        sendInfoAll = invoiceEmails; // Keep this for correspondence history and backwards compatibility
      }
    }

    // sendInfoAll will be empty when when finalise invoice without sending email or Communicate message, which should skip below correspondence updates
    if (!isEmpty(sendInfoAll)) {

      // Set correspondence status to "In progress" for all requests
      await applyCorrespondenceChangesetByInvoiceSendDetails(sendInfoAll, { status: CORRESPONDENCE_STATUS.inProgress });

      // Save correspondence status
      sbCorrespondenceHistoryService.saveP(sendInfoAll)
        .catch((err) => {
          log.error(`Failed to update correspondence requests: ${err}`);
        });
    }

    // Generate a new merchant payment reference, provided:
    // 1. A payment provider is connected
    // 2. The Operating account is configured for the payment provider in firm settings
    // 3. The Show Invoice Link and/or Show Scan to Pay options are selected in the payment provider settings
    if (!invoiceVersion.merchantPaymentReference) {
      const paymentProviderSettings = getAllPaymentProviderSettings();
      const providerType = getActiveProvider();
      const isPaymentProviderEnabledForOperating = providerType && isPaymentProviderEnabledForBankAccount({ bankAccountId: getOperatingAccount().id, providerType });

      const providerSpecificSettings = (paymentProviderSettings && providerType && paymentProviderSettings.providers[providerType]) || {};

      const showScanToPay = providerSpecificSettings.showScanToPay;
      const showInvoiceLink = providerSpecificSettings.showInvoiceLink;

      if (isPaymentProviderEnabledForOperating && (showInvoiceLink || showScanToPay)) {
        invoiceVersion.merchantPaymentReference = sbUuidService.get();
      }
    }

    invoiceVersion.feeTaxRate = sbGstTaxSettingsService.getTaxRate();
    invoiceVersion.isOriginalInvoice = featureActive('BB-1761');

    if (isFinal) {
      const latestInvoice = getLatestInvoice({
        matterId: invoiceVersion.matterId,
        debtorIds: invoiceVersion.debtors.map((debtor) => debtor.id),
      });

      const latestIssueDate = latestInvoice ? latestInvoice.issuedDate : undefined;

      // if the invoice is NOT being re-finalised & its before another invoice on the matter, throw an error
      if (featureActive('BB-1761') && !invoiceVersion.hasBeenFinalized && invoiceVersion.issuedDate <= latestIssueDate) {
        log.info('Failed to finalise invoice: invalid issue date');
        if (!isEmpty(sendInfoAll)) {
          sendInfoAll.forEach(sendEach => {
            sbCorrespondenceHistoryService.saveP([{
              ...sendEach,
              errorMessage: 'Failed to finalise and send invoice: invalid issue date'
            }], sendEach.correspondenceIdsByInvoice)
              .catch((err) => {
                log.error(`Failed to update correspondence requests: ${err}`);
              });
          });
        }
        const errorMessage= `Issue date must come later than the latest issued invoice for this matter & debtor(s). The latest invoice issue date is currently ${svc.t('date', {yyyymmdd: latestIssueDate})}.`;
        log.error(`InvoiceId: ${invoiceVersion.invoiceId}. ${errorMessage}`);
        throw new Error(errorMessage);
      }

      const finalizeCmd = createFinalizeCmd(invoiceVersion, quickPayments, sendInfoAll);

      log.info('saving finalized invoice:', finalizeCmd);

      const message = {
        ...finalizeCmd,
        version: { ...finalizeCmd.version, userId: undefined },
        sendDetails: finalizeCmd.sendDetails ? finalizeCmd.sendDetails.map((sendDetail) => ({
          ...sendDetail,
          fromUserId: undefined, // remove field as we can't pass userId or accountId value to web-command manager as it is considered insecure and command fails
          sendTo: undefined, // replace sendTo with to
          to: sendDetail.sendTo,
        })) : undefined,
        isBulkOperation,
        // add feature switches as command manages doesn't have them
        eInvoiceEnabled: featureActive('BB-5725'),
        invoiceViaCommunicateEnabled: featureActive('BB-9097'),
      };
      try {
        // Please don't be me and do not replicate this pattern of manually applying opdates. The correct way to
        // apply cache opdates is to define legacy opdater for given command in 'services/legacy-cache-opdaters/index.js'.
        // I only did it because of time constraints and because we are in process of removing some of the caches which
        // may simplify the logic. The used 'opdates' object is constructed by using other angular services and I would
        // have to rewrite all the relevant logic to do it right way as legacy opdater.
        applyFinalizeInvoiceOpdates(opdates);

        const responseBody = await dispatchCommand({
          type: 'Integration.FinalizeInvoice',
          message,
        });

        return processSaveInvoiceRespP(responseBody);
      } catch (err) {
        rollbackFinalizeInvoiceOpdates(opdates);

        if (!isEmpty(sendInfoAll)) {
          sendInfoAll.forEach((sendEach) => {
            sbCorrespondenceHistoryService
              .saveP(
                [{ ...sendEach, errorMessage: 'Failed to finalize and send invoice' }],
                sendEach.correspondenceIdsByInvoice,
              )
              .catch((err2) => {
                log.error(`Failed to update correspondence requests: ${err2}`);
              });
          });
        }

        throw err;
      }
    } else {
      if (!isEmpty(sendInfoAll)) {
        sendInfoAll.forEach(sendEach => {
          sbCorrespondenceHistoryService.saveP([{
            ...sendEach,
            errorMessage: 'Failed to finalize and send invoice'
          }], sendEach.correspondenceIdsByInvoice)
            .catch((err) => {
              log.error(`Failed to update correspondence requests: ${err}`);
            });
        });
      }
    }

    log.info('saving invoice:', invoiceVersion);
    return sbGenericEndpointService
      .postPayloadP(saveInvoiceEndpoint, undefined, invoiceVersion, 'POST', {changeset: opdates})
      .then(processSaveInvoiceRespP);
  }

  function checkInvoicePosition (invoiceVersion) {
    const matterId = invoiceVersion.matterId;
    const debtorIds = invoiceVersion.debtors.map((debtor) => debtor.id);

    // find the order of the current invoice relative to other invoices for same matter & debtor(s)
    const neighbours = sbInvoicingService.getInvoiceNeighbours(invoiceVersion.invoiceId, matterId, debtorIds);

    // if there are no neighbours we are good to go!
    if (!neighbours || (!neighbours.prior.date && !neighbours.post.date)) {
      return;
    }

    const issuedDate = invoiceVersion.issuedDate;

    // if the specified issue date is valid, we are good to continue
    if (issuedDate > neighbours.prior.date && issuedDate < neighbours.post.date) {
      return;
    }

    // if the issue date is the same as another invoice, throw an error
    if (issuedDate === neighbours.prior.date || issuedDate === neighbours.post.date) {
      throw new Error(`Issue date cannot be the same date as another invoice for this matter & debtor(s)`);
    }

    // if the issue date would change this invoice's relative position to its neighbouring invoices, throw an error
    if (neighbours.prior.date && neighbours.post.date && (issuedDate < neighbours.prior.date || issuedDate > neighbours.post.date)) {
      throw new Error(
        `Cannot move the issue date outside the range of ${svc.t('date', {yyyymmdd: neighbours.prior.date})}–${svc.t('date', {yyyymmdd: neighbours.post.date})}`,
      );
    }

    // if the issue date would move the invoice before the previous one, don't allow it
    if (issuedDate < neighbours.prior.date) {
      throw new Error(
        `Cannot move the issue date before the previous issued invoice on ${svc.t('date', {yyyymmdd: neighbours.prior.date})}`,
      );
    }

    // if the issue date would move the invoice after the following one, don't allow it
    if (issuedDate > neighbours.post.date) {
      throw new Error(
        `Cannot move the issue date after the following issued invoice on ${svc.t('date', {yyyymmdd: neighbours.post.date})}`,
      );
    }
  }

  function createFinalizeCmd(invoiceVersion, quickPayments, sendInfo) {
    const finalizeCmd = {
      version: invoiceVersion,
    };

    if (!isEmpty(quickPayments)) {
      finalizeCmd.quickPayments = quickPayments;
    }

    if (!isEmpty(sendInfo)) {
      // sendInfo is an array of sendDetails objects
      // each object has a property `SendVia` to tell .net which send method(email or Communicate message) it should use
      const invoiceSendDetails = sendInfo;
      finalizeCmd.sendDetails = invoiceSendDetails.map(invoiceSendDetailsItem => {
        if (invoiceSendDetailsItem.sentVia === sentViaTypes.COMMUNICATE) {
          const debtorInitials = getPersonInitials({ firstName: invoiceSendDetailsItem.debtorFirstName, lastName: invoiceSendDetailsItem.debtorLastName});

          return {
            from: invoiceSendDetailsItem.replyToAddress, // For updating correspondence history after sent
            fromUserId: invoiceSendDetailsItem.fromUserId,
            sendTo: invoiceSendDetailsItem.toAddress,
            correspondenceId: invoiceSendDetailsItem.correspondenceId,
            body: invoiceSendDetailsItem.message,
            linksMap: invoiceSendDetailsItem.linksMap, // An object stores the link info for linkable placeholders
            debtorId: invoiceSendDetailsItem.debtorId,
            debtorFirstName: invoiceSendDetailsItem.debtorFirstName,
            debtorLastName: invoiceSendDetailsItem.debtorLastName,
            debtorInitials: debtorInitials,
            debtorRoleDisplay: '', // Communicate requires this field, it accepts empty string
            sendVia: sentViaTypes.COMMUNICATE,
          }
        }

        return {
          from: invoiceSendDetailsItem.replyToAddress,
          sendTo: invoiceSendDetailsItem.toAddress,
          subject: invoiceSendDetailsItem.subject,
          bcc: invoiceSendDetailsItem.bcc || undefined, // Express validator v2.17.1 .optional() expects the value not to exist, cannot handle 'falsey' values. Fix is in v2.18.0
          cc: invoiceSendDetailsItem.cc || undefined,
          correspondenceId: invoiceSendDetailsItem.correspondenceId,
          body: invoiceSendDetailsItem.message, // by-passes .net email wrapper
          footer: invoiceSendDetailsItem.footer,
          debtorId: invoiceSendDetailsItem.debtorId,
          sendVia: sentViaTypes.EMAIL,
        }
      });

    }
    return finalizeCmd;
  }

  function applyCorrespondenceChangesetByInvoiceSendDetails(invoiceSendDetails, changes) {
    return sbCorrespondenceHistoryService
      .applyCorrespondenceChangesetByInvoiceSendDetails(invoiceSendDetails, changes)
      .catch(err => {
        log.error(`Failed to opdate correspondence requests ${JSON.stringify(invoiceSendDetails.map(email => email.correspondenceId))}`, err);
      });
  }

  function applyFinalizeInvoiceOpdates(opdates) {
    if (opdates.sbInvoicingService && opdates.sbInvoicingService.length > 0) {
      opdateInvoiceCache({ optimisticEntities: opdates.sbInvoicingService });
    }
    if (opdates.sbExpenseService && opdates.sbExpenseService.length > 0) {
      opdateExpenseCache({ optimisticEntities: opdates.sbExpenseService });
    }
    if (opdates.sbInvoiceTotalsService && opdates.sbInvoiceTotalsService.length > 0) {
      opdateInvoiceTotalsCache({ optimisticEntities: opdates.sbInvoiceTotalsService });
    }
    if (opdates.sbPaymentService && opdates.sbPaymentService.length > 0) {
      opdatePaymentCache({ optimisticEntities: opdates.sbPaymentService });
    }
    if (opdates.sbBankAccountBalancesService && opdates.sbBankAccountBalancesService.length > 0) {
      opdateBankAccountBalancesCache({ optimisticEntities: opdates.sbBankAccountBalancesService });
    }
  }

  function rollbackFinalizeInvoiceOpdates(opdates) {
    if (opdates.sbInvoicingService && opdates.sbInvoicingService.length > 0) {
      rollbackOpdateInvoiceCache({ optimisticEntities: opdates.sbInvoicingService });
    }
    if (opdates.sbExpenseService && opdates.sbExpenseService.length > 0) {
      rollbackOpdateExpenseCache({ optimisticEntities: opdates.sbExpenseService });
    }
    if (opdates.sbInvoiceTotalsService && opdates.sbInvoiceTotalsService.length > 0) {
      rollbackOpdateInvoiceTotalsCache({ optimisticEntities: opdates.sbInvoiceTotalsService });
    }
    if (opdates.sbPaymentService && opdates.sbPaymentService.length > 0) {
      rollbackOpdatePaymentCache({ optimisticEntities: opdates.sbPaymentService });
    }
    if (opdates.sbBankAccountBalancesService && opdates.sbBankAccountBalancesService.length > 0) {
      rollbackOpdateBankAccountBalancesCache({ optimisticEntities: opdates.sbBankAccountBalancesService });
    }
  }
});
