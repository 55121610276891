'use strict';

const { getTransactionsByCheque } = require('./get-transactions-by-cheque');
const { consolidateChequeTransactions } = require('./consolidate-cheque-transactions');
const {
  createChequeDescription,
  buildBulkTtoChequeDescription,
  buildChequeDescription,
} = require('./create-cheque-description');
const { trustTransactionFilter } = require('./trust-transaction-filter');
const {
  consolidateTrustTransactions,
  consolidateTrustToOfficeTransactions,
} = require('./consolidate-trust-transactions');
const { consolidateOperatingTransactions } = require('./consolidate-operating-transactions');
const {
  deriveTransactionIdsFromConsolidatedTrustTransactions,
} = require('./derive-transaction-ids-from-consolidated-trust-transactions');

const { otherTransactionsFilter } = require('./other-transactions-filter');
const { getUniqueTrustAccountsFromTransactions } = require('./get-unique-trust-accounts-from-transactions');
const { localiseTransactionSource } = require('./localise-transaction-source');
const { isReversible, isTransactionReversible } = require('./is-reversible');
const { isTransactionBulkDeposit } = require('./is-transaction-bulk-deposit');
const {
  isBulkDepositReversalId,
  getBulkDepositReversalIdFromId,
  getBulkDepositIdFromReversalId,
} = require('./bulk-deposit-reversal-id-functions');
const { isTransactionReconciled } = require('./is-transaction-reconciled');

module.exports = {
  getTransactionsByCheque,
  buildChequeDescription,
  consolidateChequeTransactions,
  buildBulkTtoChequeDescription,
  createChequeDescription,
  trustTransactionFilter,
  otherTransactionsFilter,
  consolidateTrustTransactions,
  consolidateTrustToOfficeTransactions,
  consolidateOperatingTransactions,
  deriveTransactionIdsFromConsolidatedTrustTransactions,
  getUniqueTrustAccountsFromTransactions,
  localiseTransactionSource,
  isReversible,
  isTransactionBulkDeposit,
  isTransactionReconciled,
  isTransactionReversible,
  isBulkDepositReversalId,
  getBulkDepositReversalIdFromId,
  getBulkDepositIdFromReversalId,
};
