import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, LinkableText, LoadingBarInfinite, useTranslation } from '@sb-itops/react';

import classNames from 'classnames';
import Styles from './UnbilledMatterEntries.module.scss';

const EntryDisplay = ({ entry, onEditEntry }) => {
  const { t } = useTranslation();

  let letter = '';
  let tooltip = '';
  if (entry.feeType === 0) {
    letter = 'F';
    tooltip = 'fee';
  } else if (entry.feeType === 1) {
    letter = 'T';
    tooltip = 'time';
  } else {
    const expenseLocalised = t('expense');
    letter = expenseLocalised[0].toUpperCase();
    tooltip = expenseLocalised.toLowerCase();
  }

  return (
    <div className={Styles.entry}>
      <div className={Styles.entryIconContainer}>
        <span
          title={tooltip}
          className={classNames(
            Styles.entryIcon,
            entry.feeType === 1 && Styles.timeEntry,
            entry.feeType === 0 && Styles.feeEntry,
            entry.feeType === undefined && Styles.expenseEntry,
          )}
        >
          {letter}
        </span>
      </div>
      <div className={Styles.entryDetails}>
        <div className={Styles.topLine}>
          <div className={Styles.description} title={entry.description}>
            {entry.description}
          </div>
          <div className={classNames(Styles.amount, entry.waived && Styles.waived)}>
            <span>
              <span>{t('cents', { val: entry.amountExcTax })}</span>
            </span>
          </div>
        </div>
        <div className={Styles.bottomLine}>
          <div>
            {t('date', { yyyymmdd: entry.feeDate || entry.expenseDate })}
            &nbsp;-&nbsp;
            {entry?.feeEarnerStaff?.initials || entry?.expenseEarnerStaff?.initials}
          </div>
          <LinkableText className={Styles.editLink} text="edit" onClickLink={() => onEditEntry(entry)} asLink />
        </div>
      </div>
    </div>
  );
};

export const UnbilledMatterEntries = memo((props) => (
  <div className={Styles.unbilledMatterEntriesContainer}>
    <div className={Styles.loadingBar}>
      <LoadingBarInfinite loading={props.loading} />
    </div>
    <div className={Styles.buttonsSection}>
      <Button type="tertiary" size="full-width" onClick={props.onGoToDraft} className={Styles.actionButton}>
        GO TO DRAFT
      </Button>
      <Button type="tertiary" size="full-width" onClick={props.onViewMatter} className={Styles.actionButton}>
        VIEW MATTER
      </Button>
    </div>
    <div className={Styles.entriesSection}>
      {props.billableEntries.length > 0 && (
        <>
          <h3> UNBILLED ENTRIES </h3>
          {props.billableEntries.map((entry) => (
            <EntryDisplay key={entry.id} entry={entry} onEditEntry={props.onEditEntry} />
          ))}
        </>
      )}

      {props.nonBillableEntries.length > 0 && (
        <>
          <h3> NON-BILLABLE ENTRIES </h3>
          {props.nonBillableEntries.map((entry) => (
            <EntryDisplay key={entry.id} entry={entry} onEditEntry={props.onEditEntry} />
          ))}
        </>
      )}
    </div>
  </div>
));

UnbilledMatterEntries.displayName = 'UnbilledMatterEntries';

const FeeEntryPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  feeType: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  feeDate: PropTypes.number.isRequired,
});

const ExpenseEntryPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  expenseDate: PropTypes.number.isRequired,
});

const EntryPropType = PropTypes.oneOfType([FeeEntryPropType, ExpenseEntryPropType]);

UnbilledMatterEntries.propTypes = {
  matterId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  billableEntries: PropTypes.arrayOf(EntryPropType),
  nonBillableEntries: PropTypes.arrayOf(EntryPropType),
  // callback/functions
  onGoToDraft: PropTypes.func.isRequired,
  onViewMatter: PropTypes.func.isRequired,
  onEditEntry: PropTypes.func.isRequired,
};

UnbilledMatterEntries.defaultProps = {
  loading: true,
  billableEntries: [],
  nonBillableEntries: [],
};
