const {
  integrationNames,
  failedRequestType,
  integrations,
} = require('@sb-billing/business-logic/integration-history/entities');
const {
  warn: displayWarningToUser,
  builder: buildMessage,
  dismissGroup: dismissMessageGroup,
} = require('@sb-itops/message-display');

const showMessage = ({ integration, messageType, onClickLink }) => {
  const integrationName = integrationNames[integration];
  let message;
  let linkType;

  if (messageType === failedRequestType.CONNECTION) {
    message = `There was a problem connecting to ${integrationName}.`;
  } else if (messageType === failedRequestType.TRANSACTION) {
    message = `There was a problem syncing all of your ${integrationName} transactions.`;
  }

  if (integration === integrations.XERO) {
    linkType = 'settingsXero';
  } else if (integration === integrations.MYOB) {
    linkType = 'settingsMyob';
  }

  const messageGroup = `integrationFailedTransaction/${integration}/${messageType}`;

  displayWarningToUser(
    buildMessage()
      .text(message)
      .group(messageGroup)
      .action(() => {
        dismissMessageGroup(messageGroup);
        onClickLink({ type: linkType });
      })
      .actionText('Go To Settings'),
  );
};

const closeMessage = ({ integration, messageType }) => {
  const messageGroup = `integrationFailedTransaction/${integration}/${messageType}`;
  dismissMessageGroup(messageGroup);
};

module.exports = { showMessage, closeMessage };
