import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import composeHooks from '@sb-itops/react-hooks-compose';
import {
  SupportDataExplorerCorrespondenceHistory,
  SupportDataExplorerExpense,
  SupportDataExplorerFee,
  SupportDataExplorerInvoice,
  SupportDataExplorerOperatingCheque,
} from 'web/graphql/queries';
import { useSubscribedQuery } from 'web/hooks';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import {
  actions as supportDebugActions,
  selectors as supportDebugSelectors,
} from 'web/redux/route/billing-support-debug';
import { BillingSupportDataExplorer } from './BillingSupportDataExplorer';

const { sortByProperty } = require('@sb-itops/nodash');

const queryMap = {
  sbCorrespondenceHistoryService: SupportDataExplorerCorrespondenceHistory,
  sbExpenseService: SupportDataExplorerExpense,
  sbFeeService: SupportDataExplorerFee,
  sbInvoicingService: SupportDataExplorerInvoice,
  sbOperatingChequesService: SupportDataExplorerOperatingCheque,
};

const compatibleCachesMap = Object.keys(queryMap).reduce((acc, cache) => {
  acc[cache] = true;
  return acc;
}, {});

const cacheOptions = Object.keys(queryMap)
  .sort()
  .map((cacheName) => ({ value: cacheName, label: cacheName }));

const hooks = () => ({
  useDebugEntity: () => {
    const debugEntity = useSelector(supportDebugSelectors.getDebugEntity) ?? { entityCacheName: 'sbFeeService' };
    const [formValues, setFormValues] = useState({
      entityCacheName: debugEntity?.entityCacheName,
      entityId: debugEntity?.entityId ?? '',
    });
    const onSetFormValues = (newValues) => {
      setFormValues({ ...formValues, ...newValues });
    };

    const dispatch = useDispatch();

    /**
     * @param {object} debugEntity
     * @param {string} debugEntity.entityCacheName
     * @param {string} debugEntity.entityId
     * @returns
     */
    const setDebugEntity = ({ entityCacheName, entityId }) =>
      dispatch(supportDebugActions.setDebugEntity({ entityCacheName, entityId }));

    const onClickLookup = ({ entityCacheName, entityId } = {}) => {
      if (entityCacheName && entityId) {
        setDebugEntity({ entityCacheName, entityId });
        return;
      }

      setDebugEntity({ entityCacheName: formValues.entityCacheName, entityId: formValues.entityId });
    };

    const onGoToEntity = (newValues) => {
      onSetFormValues(newValues);
      onClickLookup(newValues);
    };

    const query = queryMap[debugEntity?.entityCacheName];
    const { data, error, loading } = useSubscribedQuery(query || queryMap.sbFeeService, {
      // I know it looks weird, but we need to provide a valid query as fallback
      // to prevent errors. It won't actually be used as we skip fetching if the
      // query is not valid
      skip: !debugEntity?.entityId || !query,
      variables: {
        id: debugEntity?.entityId,
      },
    });

    if (error) {
      throw new Error(error);
    }

    const fieldName = data && Object.keys(data)[0];
    const versions = data?.[fieldName]?.versions && [...data[fieldName].versions];
    const entityData = data?.[fieldName]
      ? {
          ...data[fieldName],
          // Adding the ID field manually here as we have excluded it from the
          // query to prevent the normalised cache being overwritten by old
          // versions of the entity.
          id: formValues.entityId || undefined,
          versions: sortByProperty(versions || [], 'validFrom', 'desc'),
        }
      : null;

    return {
      cacheOptions,
      compatibleCachesMap,
      setDebugEntity,
      entityData,
      formValues,
      loading,
      onClickLookup,
      onGoToEntity,
      onSetFormValues,
    };
  },
});

export const BillingSupportDataExplorerContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(BillingSupportDataExplorer)),
);

BillingSupportDataExplorerContainer.displayName = 'BillingSupportDataExplorerContainer';

BillingSupportDataExplorerContainer.propTypes = {};
BillingSupportDataExplorerContainer.defaultProps = {};
