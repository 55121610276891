import React from 'react';

import { CircleInfo } from 'web/components/icons';
import classnames from 'classnames';

import Styles from './Message.module.scss';

export const MessageSyncInProgress = () => (
  <div className={classnames(Styles.messageContainer, Styles.info)}>
    <CircleInfo fill="#31708f" />
    &nbsp; Transactions are syncing. Please come back later.
  </div>
);

MessageSyncInProgress.displayName = 'MessageSyncInProgress';

MessageSyncInProgress.propTypes = {};
MessageSyncInProgress.defaultProps = {};
