import { Button, Modal } from '@sb-itops/react';
import { MatterTypeahead2 } from '@sb-matter-management/react';
import React from 'react';
import Styles from './Modal.module.scss';

export interface ISelectMatterModalProps {
  isLoading: boolean;
  isSubmitting: boolean;
  matterSummariesHasMore: boolean;
  selectedMatterId?: string;
  matterSummaries?: any;
  onFetchMatterSummaries: (...args: any[]) => any;
  onFetchMoreMatterSummaries: any;
  onMatterSelected: (...args: any[]) => any;
  onClose: () => void;
  onSubmit: (matterId: string) => void;
}

export const SelectMatterModal = ({
  isLoading,
  matterSummaries,
  matterSummariesHasMore,
  selectedMatterId,
  onFetchMatterSummaries,
  onFetchMoreMatterSummaries,
  onMatterSelected,
  onClose,
  onSubmit,
  isSubmitting,
}: ISelectMatterModalProps) => (
  <Modal
    className={Styles.modal}
    title="Select Matter"
    body={
      <MatterTypeahead2
        isLoading={isLoading}
        matters={matterSummaries}
        maxMenuHeight={250}
        placeholder="Select a matter..."
        actionList={
          matterSummariesHasMore === true
            ? [
                {
                  displayComponent: (
                    <span>
                      <i className="fa fa-plus" /> &emsp;Show more results
                    </span>
                  ),
                  callback: () => {
                    if (isLoading) {
                      return;
                    }

                    onFetchMoreMatterSummaries();
                  },
                },
              ]
            : []
        }
        onInputChange={onFetchMatterSummaries}
        onMatterSelected={onMatterSelected}
        selectedMatterId={selectedMatterId}
      />
    }
    footer={
      <>
        <Button
          disabled={isLoading || isSubmitting || !selectedMatterId}
          size="full-width"
          onClick={() => onSubmit(selectedMatterId!)}
          locked={isLoading}
        >
          Select
        </Button>
        <Button disabled={isSubmitting} size="full-width" onClick={onClose} type="secondary">
          Cancel
        </Button>
      </>
    }
    onModalClose={onClose}
  />
);
