/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Modal, FormLabel } from '@sb-itops/react';
import { getAccountId } from 'web/services/user-session-management';
import { getClientRoleOptions, getOtherSideRole } from '@sb-matter-listing/redux/matter-type-configurations';
import { getById as getAutoNumberDefinitionByAccountId } from '@sb-matter-management/redux/auto-number-definition';
import { matterFieldsRegionStrategy } from '../add-edit-matter/matter-fields-region-strategy';
import Styles from './AddMatterWizardModal.module.scss';

import { MatterDetails } from './matter-details';
import { MatterStaff } from './matter-staff';
import { MatterContacts } from './matter-contacts';
import { MatterBilling } from './matter-billing';
import { Wizard } from '../wizard';
import { MatterTypeSelect } from '../matter-type-select';
import { IWizardProps } from '../wizard/Wizard';

export const ADD_MATTER_WIZARD_MODAL_ID = 'ADD_MATTER_WIZARD_MODAL_ID';

const Step1 = ({ form: { onUpdateFieldValues, formFields, submitFailed, formSubmitting } }) => (
  <>
    <FormLabel uppercase={false} label="Matter Type" field={formFields.matterTypeId} submitFailed={submitFailed} />
    <div>
      <MatterTypeSelect
        locationId={formFields.locationId && formFields.locationId.value}
        matterTypeCategory={formFields.matterTypeCategory && formFields.matterTypeCategory.value}
        matterTypeId={formFields.matterTypeId && formFields.matterTypeId.value}
        onLocationUpdated={(newLocationId) => {
          onUpdateFieldValues('locationId', newLocationId);
          onUpdateFieldValues('matterTypeCategory', undefined);
          onUpdateFieldValues('matterTypeId', undefined);
          onUpdateFieldValues('clientRole', undefined);
        }}
        onCategoryUpdated={(newCategory) => {
          onUpdateFieldValues('matterTypeCategory', newCategory);
          onUpdateFieldValues('matterTypeId', undefined);
          onUpdateFieldValues('clientRole', undefined);
        }}
        onMatterTypeUpdated={(newMatterType) => {
          // newMatterType has the shape of { label, value, originalMatterTypeId }
          const newMatterTypeId = newMatterType && newMatterType.value; // selected matter type id always has location suffix, e.g. [GUID]_[NSW]

          // pre-select client role for user if there's only one to choose from
          const originalMatterTypeId = newMatterType && newMatterType.originalMatterTypeId; // could look like [GUID] or [GUID]_[NSW]
          const clientRoleOptions = getClientRoleOptions(originalMatterTypeId);
          const clientRole =
            clientRoleOptions && clientRoleOptions.length === 1 ? clientRoleOptions[0].value : undefined;
          const otherSideRole = getOtherSideRole(originalMatterTypeId, clientRole);

          onUpdateFieldValues('matterTypeId', newMatterTypeId);
          onUpdateFieldValues('originalMatterTypeId', originalMatterTypeId);
          onUpdateFieldValues('clientRole', clientRole);
          onUpdateFieldValues('otherSideRole', otherSideRole);
        }}
        disabled={formSubmitting}
        isRequired
        showError={submitFailed}
        showOnlyLeads={false}
      />
    </div>
  </>
);
const Step1Validation = (formFields) => !!formFields.matterTypeId;

const Step2 = ({ onClickLink, form, setAddContactCallback }) => (
  <MatterContacts
    formInitialised={form.formInitialised}
    formFields={form.formFields}
    formFieldValues={form.formValues}
    formDisabled={form.formSubmitting}
    formSubmitting={form.formSubmitting}
    submitFailed={form.submitFailed}
    onFieldValuesUpdated={form.onUpdateFieldValues}
    onFieldValueSet={form.onFieldValueSet}
    onClickLink={onClickLink}
    setAddContactCallback={setAddContactCallback}
  />
);

const Step2Validation = (formFields) =>
  !!formFields?.clientCustomerIds?.length && formFields.clientCustomerIds.length > 0 && formFields.clientRole;

const Step3 = ({ form }) => (
  <MatterDetails
    formInitialised={form.formInitialised}
    formFields={form.formFields}
    formFieldValues={form.formValues}
    formDisabled={form.formSubmitting}
    formSubmitting={form.formSubmitting}
    submitFailed={form.submitFailed}
    onFieldValuesUpdated={form.onUpdateFieldValues}
  />
);
const Step3Validation = (formFields) => {
  const validMatterNumber =
    !matterFieldsRegionStrategy.matterNumberRequired ||
    !!getAutoNumberDefinitionByAccountId(getAccountId())?.useAutoReference ||
    formFields.matterNumber;
  const validDescription = !matterFieldsRegionStrategy.matterDescriptionRequired || formFields.description;
  return validDescription && validMatterNumber;
};

const Step4 = ({ form, setAddContactCallback }) => (
  <MatterStaff
    formInitialised={form.formInitialised}
    formFields={form.formFields}
    formFieldValues={form.formValues}
    formDisabled={form.formSubmitting}
    formSubmitting={form.formSubmitting}
    submitFailed={form.submitFailed}
    onFieldValuesUpdated={form.onUpdateFieldValues}
    setAddContactCallback={setAddContactCallback}
  />
);
const Step4Validation = () => true;

const Step5 = ({ onClickLink, form, setAddContactCallback }) => (
  <MatterBilling
    formInitialised={form.formInitialised}
    formFields={form.formFields}
    formFieldValues={form.formValues}
    formDisabled={form.formSubmitting}
    formSubmitting={form.formSubmitting}
    submitFailed={form.submitFailed}
    onFieldValuesUpdated={form.onUpdateFieldValues}
    onFieldValueSet={form.onFieldValueSet}
    onClickLink={onClickLink}
    setAddContactCallback={setAddContactCallback}
  />
);
const Step5Validation = () => true;

interface IWizardModal {
  hasBillingAccess: boolean;
  isLoading?: boolean;
  isVisible?: boolean;
  scope: string;
  form: any;
  // callbacks
  addContactCallback: (contact: any) => void;
  setAddContactCallback: (addContactCallback: ((contact: any) => void) | null) => void;
  onCancel: () => void;
  onClickLink: () => void;
  onSubmit: () => void;
}

export const AddMatterWizardModal = ({
  hasBillingAccess,
  isVisible,
  isLoading,
  scope,
  form,
  // callbacks
  addContactCallback,
  setAddContactCallback,
  onCancel,
  onClickLink,
  onSubmit,
}: IWizardModal) => {
  const steps: IWizardProps['steps'] = [
    {
      label: 'Matter Type',
      step: Step1({ form }),
      validation: Step1Validation,
    },
    {
      label: 'Parties',
      step: Step2({ onClickLink, form, setAddContactCallback }),
      validation: Step2Validation,
    },
    {
      label: 'Matter Details',
      step: Step3({ form }),
      validation: Step3Validation,
    },
    {
      label: 'Staff',
      step: Step4({ form, setAddContactCallback }),
      validation: Step4Validation,
      canSave: !hasBillingAccess,
    },
  ];
  if (hasBillingAccess) {
    steps.push({
      label: 'Billing',
      step: Step5({ onClickLink, form, setAddContactCallback }),
      validation: Step5Validation,
      canSave: true,
    });
  }

  return (
    <Modal
      title=""
      showModalBodyPadding={false}
      isModalBodyScrollable={false}
      showFooter={false}
      modalBodyClassName={Styles.modalBody}
      body={
        <Wizard
          showAddContact={!!addContactCallback}
          onContactAdded={(contact) => {
            if (contact && addContactCallback) {
              addContactCallback(contact);
            }
            setAddContactCallback(null);
          }}
          scope={scope}
          title="New Matter"
          steps={steps}
          onSubmit={onSubmit}
          onClose={onCancel}
        />
      }
      isLoading={isLoading || false}
      isVisible={isVisible || false}
      onModalClose={onCancel}
      shouldCloseOnOverlayClick={false}
    />
  );
};
