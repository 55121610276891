import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { PAYMENT_TYPE } from '@sb-billing/business-logic/payment-source';
import uuid from '@sb-itops/uuid';
import { PrintNotApplicable, printMethodsByCode, printMethodsByValue } from '@sb-billing/business-logic/cheques';

export function marshalData({
  allowOverdraw,
  formData,
  invoice,
  isSplitBillingInvoice,
  isChequeMemoVisible,
  isMatterContactBalanceFirm,
  isTrustChequePrintingActive,
  operatingAccount,
  supportsTtoNumbering,
  userId,
}) {
  const { paymentSource } = formData;
  const isDirectPayment = paymentSource.paymentType === PAYMENT_TYPE.direct;
  const isTrustPayment = paymentSource.paymentType === PAYMENT_TYPE.trust;
  const isMatterBalanceFirm = !isMatterContactBalanceFirm;

  // [1] Initialise with required data
  const marshalledData = {
    effectiveDate: formData.effectiveDate,
    debtorId: isSplitBillingInvoice ? formData.debtorId : undefined,
    invoiceId: invoice.id,
    matterBalanceType: isMatterContactBalanceFirm ? 'CONTACT' : 'MATTER',
    matterId: formData.matterId,
    note: formData.comment?.length ? formData.comment : null,
    reference: formData.reference?.length ? formData.reference : null,
    userId,
    waiveBalance: formData.waiveRemainingBalance,
  };

  // [2] Reason field
  //  * AU/GB - Field is present and editable
  //  * US - Field is hidden, but we populate it with a value if it is a trust payment
  const reasonFieldEnabled = featureActive('BB-5508') && hasFacet(facets.reasonField);
  const reasonForTrustPaymentsEnabled = hasFacet(facets.trustPaymentReasonField) && isTrustPayment; // US facet

  if (reasonFieldEnabled) {
    marshalledData.reason = formData.reason;
  } else if (reasonForTrustPaymentsEnabled) {
    marshalledData.reason = `Legal Fees on Invoice #${invoice.invoiceNumber}`;
  }

  // [3] Payment source related
  if (isDirectPayment) {
    marshalledData.source = paymentSource.paymentSource; // E.g. Bank transfer or cash
  } else {
    marshalledData.sourceAccountType = paymentSource.paymentType;
    marshalledData.sourceBankAccountId = paymentSource.bankAccountId;
    marshalledData.sourceAccountBalanceType = 'MATTER'; // Legacy requirement - Always set it to MATTER
  }

  if (isDirectPayment || isMatterBalanceFirm) {
    marshalledData.payorId = isDirectPayment ? formData.paidById : undefined;
    marshalledData.amount = formData.amount;
    marshalledData.transactionId = isTrustPayment && isMatterBalanceFirm ? uuid() : null;
  }

  // The endpoint requires either:
  //  1. Payors
  //  2. Payment ID
  if (!isDirectPayment && isMatterContactBalanceFirm) {
    marshalledData.payors = marshalPayors({ formData, isDirectPayment });
  } else {
    marshalledData.paymentId = uuid();
  }

  // [4] Cheque related
  // Check if the printingMethod field is present upon submitting because:
  //  * The user could have initially selected a printing method (e.g. Trust payment + Print later)
  //  * But then changed to a payment option without a printing method (e.g. Bank Transfer)
  const printingMethodFieldPresent = isTrustPayment && isTrustChequePrintingActive;
  const chequePrintActive = printingMethodFieldPresent && formData.printingMethodId !== PrintNotApplicable;

  // If chequePrintActive is false, set chequePrintMethod to the first print method options
  const chequePrintMethod = chequePrintActive
    ? printMethodsByValue[formData.printingMethodId].code
    : printMethodsByCode[0].code;

  // Generate id (for the trust -> operating transaction) now so that it can be passed to Print Cheque Modal if necessary
  marshalledData.chequeId = chequePrintActive ? uuid() : undefined;
  marshalledData.chequePrintActive = chequePrintActive;
  marshalledData.chequePrintMethod = chequePrintMethod;

  marshalledData.chequeMemo = isChequeMemoVisible ? formData.chequeMemo : undefined;
  marshalledData.isElectronicPayment =
    isTrustPayment && supportsTtoNumbering && formData.printingMethodId === PrintNotApplicable;

  // [5] Trust account related
  // Need to generate transaction Id (for the trust -> operating transaction) now so that it can be passed to the TTO Transfer pdf
  if (isTrustPayment && formData.pdfOnTrustPayment) {
    marshalledData.transferBetweenAccountsTransactionId = uuid();
    marshalledData.pdfOnTrustPayment = true;
  }

  // [6] Operating account related
  const addOperatingAccountDetails =
    hasFacet(facets.operatingAccountDetail) &&
    featureActive('BB-5509') &&
    paymentSource.paymentType !== PAYMENT_TYPE.operating;

  if (addOperatingAccountDetails) {
    marshalledData.destinationBankAccountName = operatingAccount.accountName;
    marshalledData.destinationBankAccountNumber = operatingAccount.accountNumber;
    marshalledData.destinationBankBranchNumber = operatingAccount.branchNumber;
  }

  // [7] Other
  marshalledData.allowOverdraw = allowOverdraw;

  return marshalledData;
}

// Payors need to be specified when:
//  1. Non direct payment
//  2. Account type is: Contact/Matter
function marshalPayors({ formData, isDirectPayment }) {
  const { paymentSource, payorAmounts } = formData;

  if (isDirectPayment) {
    return undefined;
  }

  // Combined payment source
  //  * E.g. multiple contacts paying for an invoice from a (combined) trust account source
  if (paymentSource.isCombinedBalance) {
    const multiplePayorsSource = Object.entries(payorAmounts).reduce((acc, [payorId, amount]) => {
      const validAmount = Number.isInteger(amount) && amount > 0;

      if (!validAmount) {
        return acc;
      }

      acc.push({
        payorId,
        amount,
        paymentId: uuid(),
        transactionId: uuid(),
      });
      return acc;
    }, []);

    return multiplePayorsSource;
  }

  // Non-combined payment source
  //  * E.g. a single contact with a deposit
  const singlePayorForNonCombinedSource = [
    {
      payorId: paymentSource.contactId,
      amount: formData.amount,
      paymentId: uuid(),
      transactionId: uuid(),
    },
  ];

  return singlePayorForNonCombinedSource;
}
