import React, { useEffect, useRef } from 'react';

import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useDispatch } from 'react-redux';

import * as communicateFeature from 'web/redux/features/communicate';
import { CommIframePosition } from 'web/redux/features/communicate/types';
import { FeatureLockModal } from 'web/react-redux/components/feature-lock';

const positionChanged = (a: CommIframePosition, b: CommIframePosition) =>
  !(a.height === b.height && a.width === b.width && a.left === b.left && a.top === b.top);

export const CommunicateEmbedded = (props: { matterId: string | null }) => {
  const dispatch = useDispatch();

  const thingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const matterId = props.matterId || null;

    let oldMatterId: string | null = null;
    let oldPosition: CommIframePosition | null = null;

    const positionIframe = () => {
      if (thingRef.current) {
        const div = thingRef.current;
        const matterTab = div.offsetParent as HTMLDivElement;
        if (matterTab) {
          const position: CommIframePosition = {
            left: matterTab.offsetLeft,
            top: matterTab.offsetTop,
            width: matterTab.offsetWidth,
            height: matterTab.offsetHeight,
          };

          if (!oldPosition || positionChanged(oldPosition, position) || matterId !== oldMatterId) {
            oldPosition = position;
            oldMatterId = matterId;
            dispatch(
              communicateFeature.actions.showIframe({
                position,
                matterId,
              }),
            );
          }
        }
      }
    };

    positionIframe();
    window.addEventListener('resize', positionIframe, false);
    // rarely div repositioning could cause happen outside the resize
    const resizeInterval = setInterval(positionIframe, 1000);
    const resizeTiemout1 = setTimeout(positionIframe, 100);
    const resizeTiemout2 = setTimeout(positionIframe, 500);
    const resizeTiemout3 = setTimeout(positionIframe, 1000);

    return () => {
      window.removeEventListener('resize', positionIframe, false);
      clearInterval(resizeInterval);
      clearTimeout(resizeTiemout1);
      clearTimeout(resizeTiemout2);
      clearTimeout(resizeTiemout3);
      dispatch(communicateFeature.actions.hideIframe({ matterId }));
    };
  }, []);

  return (
    <div ref={thingRef} style={{ width: '100%', height: '100%', backgroundColor: '#d3d3d3' }}>
      <p>&nbsp;</p>
    </div>
  );
};

const Container = ({ matterId, onClickLink }) => (
  <FeatureLockModal feature="messages" onClickLink={onClickLink}>
    <CommunicateEmbedded matterId={matterId || null} />
  </FeatureLockModal>
);

export const CommunicateEmbeddedContainer = withReduxProvider(Container);
