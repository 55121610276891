import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withOnLoad } from '@sb-itops/react';
import { useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { GoogleMarkerFromAddress } from 'web/components';
import { subscribeToNotifications } from 'web/services/apollo';
import { BillingViewContactDetailsRouteData } from 'web/graphql/queries';
import { setModalDialogVisible, setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { deleteContact, restoreDeletedContact } from '@sb-customer-management/redux/contacts';
import { withReduxProvider } from '../../hocs/withReduxProvider';
import { withApolloClient } from '../../hocs/withApolloClient';
import { BillingViewContactDetailsRoute } from './BillingViewContactDetailsRoute';
import { extractAddresses } from './extractAddresses';

const deleteContactModalId = 'delete-contact-confirmation';
const restoreContactModalId = 'restore-contact-confirmation';

const hooks = ({ contactId, onClickLink }) => ({
  useOnLoad: () => ({
    onLoad: () => {
      const unsubscribeFromNotifications = subscribeToNotifications({
        notificationIds: ['CustomerManagementNotifications'],
      });

      const onUnload = () => unsubscribeFromNotifications();
      return onUnload;
    },
  }),
  useGraphQL: () => {
    // TODO: This needs to be refactored to use useSubscribedQuery then the useOnLoad hook can be removed
    const { data, loading, error } = useQuery(BillingViewContactDetailsRouteData, {
      variables: {
        contactId,
      },
    });

    if (error) {
      throw new Error(error);
    }
    const contact = data?.contact;
    const addresses = extractAddresses(contact || {});
    const [activeAddressType, setActiveAddressType] = useState();

    useEffect(
      () => {
        if (!loading) {
          setActiveAddressType(addresses?.[0]?.type);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [loading],
    );

    const contactCards = contact ? [contact, ...(contact.people || [])] : [];

    const activeAddress = addresses.find((address) => address.type === activeAddressType);

    return {
      addresses,
      loading,
      error,
      contactCards,
      activeAddress,
      activeAddressType,
      setActiveAddressType,
    };
  },
  useModals: () => {
    const [editContactId, setEditContactId] = useState('');
    const [showContactModal, setShowContactModal] = useState(false);
    const [deleteContactId, setDeleteContactId] = useState('');
    const [restoreContactId, setRestoreContactId] = useState('');

    return {
      editContactId,
      setEditContactId,
      showContactModal,
      setShowContactModal,
      deleteContactModalId,
      onDeleteContact: () => {
        setModalDialogHidden({ modalId: deleteContactModalId });
        deleteContact(deleteContactId);
        setDeleteContactId('');

        // If the contact who we are viewing was deleted, navigate back to the contact list
        if (deleteContactId === contactId) {
          onClickLink({ type: 'contactList' });
        }
      },
      showDeleteModal: (id) => {
        setModalDialogVisible({ modalId: deleteContactModalId });
        setDeleteContactId(id);
      },
      restoreContactModalId,
      onRestoreContact: () => {
        setModalDialogHidden({ modalId: restoreContactModalId });
        restoreDeletedContact(restoreContactId);
        setRestoreContactId('');
      },
      showRestoreModal: (id) => {
        setModalDialogVisible({ modalId: restoreContactModalId });
        setRestoreContactId(id);
      },
    };
  },
});

export const BillingViewContactDetailsRouteContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(GoogleMarkerFromAddress(withOnLoad(BillingViewContactDetailsRoute)))),
);

BillingViewContactDetailsRouteContainer.displayName = 'BillingViewContactDetailsRouteContainer';

BillingViewContactDetailsRouteContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  category: PropTypes.string,
  contactId: PropTypes.string.isRequired,
};

BillingViewContactDetailsRouteContainer.defaultProps = { category: '' };
