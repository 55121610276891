import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { featureActive } from '@sb-itops/feature';
import { SplitPaymentsList } from './SplitPaymentsList';

const getTotalsAndInvoices = ({ invoiceSummaries, payments }) => {
  const totalsAndInvoices = (invoiceSummaries || []).reduce(
    (acc, invoice) => {
      const invoiceId = invoice.id;
      const invTotals = {
        total: invoice.totals.total || 0,
        unpaidExcInterest: invoice.totals.unpaidExcInterest || 0,
        interest: invoice.totals.interest || 0,
        paid: invoice.totals.paid || 0,
        unpaid: invoice.totals.unpaid || 0,
      };
      const unpaidInterest =
        invTotals.unpaidExcInterest > 0 ? invTotals.interest : invTotals.total - invTotals.paid + invTotals.interest;

      // Invoices
      acc.invoices.push({
        invoiceId,
        invoiceNumber: invoice.invoiceNumber,
        invoiceNumberSuffix: invoice.invoiceNumberSuffix, // set only for split billing invoice
        debtorDisplayName: invoice.debtorDisplayName, // set only for split billing invoice
        dueDate: invoice.dueDate, // in form YYYYMMDD
        matterId: invoice.matter.id,
        due: invTotals.unpaid, // cents
        unpaidExcInterest: invTotals.unpaidExcInterest,
        interest: unpaidInterest,
        hasUnpaidAnticipatedDisbursements:
          featureActive('BB-9573') && !!invoice?.listItemProperties?.hasUnpaidAnticipatedDisbursements,
        paymentPlan: invoice?.listItemProperties?.activePaymentPlanDetails,
      });

      // Totals
      acc.totals.due += invTotals.unpaid;
      acc.totals.paid += payments[invoiceId] || 0;
      acc.totals.interest += invTotals.interest;
      acc.totals.unpaidExcInterest += invTotals.unpaidExcInterest;

      return acc;
    },
    {
      totals: { due: 0, paid: 0, interest: 0, unpaidExcInterest: 0 },
      invoices: [],
    },
  );

  return totalsAndInvoices;
};

const hooks = ({ invoices: invoiceSummaries, onUpdate, payments, isLoading }) => ({
  useSplitPayments: () => {
    const { invoices, totals } = getTotalsAndInvoices({ invoiceSummaries, payments });

    return {
      isLoading,
      // we don't want to show error message when invoiceSummaries is undefined as it means we don't know if they exist or not
      showErrorWhenNoInvoices: Array.isArray(invoiceSummaries) && !isLoading,
      invoices,
      payments,
      totals,
      allSelected: !!invoices.length && totals.paid === totals.due,
      onSelectAll: () => {
        let newPayments = {};
        if (totals.paid !== totals.due) {
          newPayments = invoices.reduce((acc, invoice) => {
            const invoiceIdWithSuffix = `${invoice.invoiceId}${
              invoice.invoiceNumberSuffix ? `|${invoice.invoiceNumberSuffix}` : ''
            }`;
            acc[invoiceIdWithSuffix] = invoice.due;
            return acc;
          }, {});
        }
        onUpdate(newPayments);
      },
      onChange: (invoiceId, amount) => {
        const newPayments = { ...payments, [invoiceId]: amount };
        onUpdate(newPayments);
      },
    };
  },
});

const SplitPaymentsListContainer = composeHooks(hooks)(SplitPaymentsList);

SplitPaymentsListContainer.displayName = 'SplitPaymentsListContainer';

SplitPaymentsListContainer.propTypes = {
  invoices: PropTypes.array,
  payments: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

SplitPaymentsListContainer.defaultProps = {
  invoices: undefined,
  payments: {},
  isLoading: false,
};

export default SplitPaymentsListContainer;
