import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getById as getMatterById } from '@sb-matter-management/redux/matters';
import { useSelector } from 'react-redux';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { setModalDialogHidden, setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { CONTACT_CREATE_EDIT_MODAL_ID } from 'web/react-redux/components/contact-create-edit-modal';
import { findContactsWithMissingStreetOrPOAddress } from '@sb-customer-management/redux/contacts/index';
import { useTranslation } from '@sb-itops/react';
import { DepositAddressIncompleteButton } from './DepositAddressIncompleteButton';

const clientAddressRequired = hasFacet(facets.matterClientAddressRequiredForTrustDeposit);

const hooks = ({ matterId, id, onAddressComplete, title }) => ({
  useDepositAddressIncompleteButton: () => {
    const { t } = useTranslation();
    const matter = useSelector(() => getMatterById(matterId) || {});
    const modalId = `address-incomplete-modal-${id}`; // id needed to differentiate multiple instances of the different buttons' modals e.g. differentiating the matter deposit funds from global, or deposit request from credit card deposit request
    const [contactsWithNoAddress, setContactsWithNoAddress] = useState([]);

    useEffect(() => {
      let isUnmounted = false;

      const fetchData = async () => {
        const contacts = await findContactsWithMissingStreetOrPOAddress({
          contactIds: matter.clientCustomerIds,
          spreadGroupsOfPeople: true,
        });
        setContactsWithNoAddress(contacts);
      };

      if (clientAddressRequired && matter?.clientCustomerIds && !isUnmounted) {
        fetchData();
      }

      return () => {
        isUnmounted = true;
      };
    }, [matter]);

    const onConfirmMissingAddressModal = () => {
      setModalDialogHidden({ modalId });
      setModalDialogVisible({
        modalId: CONTACT_CREATE_EDIT_MODAL_ID,
        props: {
          contactId: getContactIdByIndex(contactsWithNoAddress, 0),
          onContactEdited: onContactEdited(0),
        },
      });
    };

    const onButtonClick = () => {
      if (!clientAddressRequired) {
        onAddressComplete();
        return;
      }

      if (contactsWithNoAddress.length === 0) {
        onAddressComplete();
        return;
      }

      setModalDialogVisible({ modalId });
    };

    const onContactEdited = (index) => {
      // Which modal do we want to open afterwards?
      // 1. Are there any more clients to chain?
      if (index < contactsWithNoAddress.length - 1) {
        const nextIndex = index + 1;
        return () =>
          setModalDialogVisible({
            modalId: CONTACT_CREATE_EDIT_MODAL_ID,
            props: {
              contactId: getContactIdByIndex(contactsWithNoAddress, nextIndex),
              onContactEdited: onContactEdited(nextIndex),
            },
          });
      }

      // 2. No, open whichever final modal we were trying to go to
      return onAddressComplete;
    };

    const getContactIdByIndex = (clients, index) => {
      if (!clients || clients.length === 0) {
        return '';
      }
      return clients[index]?.id;
    };

    return {
      t,
      matter,
      modalId,
      contactsWithNoAddress,
      onButtonClick,
      onConfirmMissingAddressModal,
      buttonTitle: title,
    };
  },
});

const DepositAddressIncompleteButtonContainer = withReduxProvider(composeHooks(hooks)(DepositAddressIncompleteButton));

DepositAddressIncompleteButtonContainer.displayName = 'DepositAddressIncompleteButton';

DepositAddressIncompleteButtonContainer.propTypes = {
  // id uniquely identifies the button and modal
  // important when there are multiple buttons on same page
  id: PropTypes.string.isRequired,
  onAddressComplete: PropTypes.func,
  matterId: PropTypes.string.isRequired,
  modalId: PropTypes.string,
  label: PropTypes.string.isRequired,
  locked: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

DepositAddressIncompleteButtonContainer.defaultProps = {
  locked: false,
  disabled: false,
  title: undefined,
  onAddressComplete: () => {},
};

export default DepositAddressIncompleteButtonContainer;
