/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AtSign, Contact, CopyIcon, Phone, InvoiceIcon, Smartphone, InvoiceDottedIcon } from 'web/components/icons';
import { useTranslation, Chip, LoadingPlaceholderElement } from '@sb-itops/react';
import classNames from 'classnames';
import { BarChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import moment from 'moment';
import emptyBox from 'web/assets/empty-box.svg';
import { featureActive } from '@sb-itops/feature';
import Styles from './BillingMatterDashboardRoute.module.scss';
import { DailyDigestWidget } from '../../components/daily-digest-widget';

const CustomTickRenderer = (tickProps) => (
  <text
    key={tickProps.payload.value}
    x={tickProps.x}
    y={tickProps.y}
    stroke={tickProps.stroke}
    textAnchor={tickProps.textAnchor}
  >
    {tickProps.payload.value.split(' ').map((v, i) => (
      <tspan
        key={tickProps.payload.value + v}
        fill={i ? '#6B7280' : '#000'}
        x={tickProps.x}
        dy={`${String(0.71 * (i + 1))}em`}
      >
        {v}
      </tspan>
    ))}
  </text>
);

const ContactDisplay = ({ client, onClickLink }) => (
  <div className={Styles.contactDetails}>
    <div>
      <Contact />
      <div onClick={() => onClickLink({ type: 'contact', id: client.id })} className={Styles.contactName}>
        {client.displayNameFirstLast}
      </div>
    </div>
    {(client.phone || client.phone2) && (
      <div className={Styles.phone}>
        <Phone />
        <a href={`tel:${client.phone || client.phone2}`}>{client.phone || client.phone2}</a>
      </div>
    )}
    {client.cell && (
      <div className={Styles.phone}>
        <Smartphone />
        <a href={`tel:${client.cell}`}>{client.cell}</a>
      </div>
    )}
    {client.email && (
      <div className={Styles.email}>
        <AtSign />
        <a href={`mailto:${client.email}`}>{client.email}</a>
        <CopyIcon onClick={() => navigator.clipboard.writeText(client.email)} className={Styles.copy} />
      </div>
    )}
  </div>
);

const getTimestampDisplay = (d) => {
  const day = moment(d, 'YYYYMMDD');
  const diff = moment().startOf('day').diff(day, 'days');

  if (diff === 0) {
    return <Chip className={Styles.chip} label="Today" />;
  }
  if (diff === 1) {
    return <Chip className={Styles.chip} label="Yesterday" />;
  }
  if (diff === -1) {
    return <Chip className={Styles.chip} label="Tomorrow" />;
  }
  if (diff > 1) {
    return <Chip className={Styles.chip} label={day.format('dddd D MMMM')} />;
  }
  return <Chip className={Styles.chip} label={day.format('dddd D MMMM')} />;
};

export const BillingMatterDashboardRoute = (props) => {
  const { onClickLink, matterId, onFilterByRecent, onFilterInvoiceStatus } = props;
  const [pastActivity, setTab] = useState(true);

  const { t } = useTranslation();

  const getText = (item, isPastActivity, isLead) => {
    const matterText = isLead ? 'Lead' : 'Matter';
    switch (item.type) {
      case 'matterOpened':
        return <strong>{`${matterText} opened`}</strong>;
      case 'matterClosed':
        return <strong>{`${matterText} closed`}</strong>;
      case 'issued':
        return (
          <span>
            <span className={Styles.activityLink} onClick={() => onClickLink({ type: 'invoice', id: item.invoiceId })}>
              {`Invoice #${item.invoiceNumber}`}
            </span>
            {isPastActivity ? ' was ' : ' is '}
            issued
          </span>
        );
      case 'finalised':
        return (
          <span>
            <span className={Styles.activityLink} onClick={() => onClickLink({ type: 'invoice', id: item.invoiceId })}>
              {`Invoice #${item.invoiceNumber}`}
            </span>
            {isPastActivity ? ' was ' : ' is '}
            created
          </span>
        );
      case 'due':
        return (
          <span>
            <span className={Styles.activityLink} onClick={() => onClickLink({ type: 'invoice', id: item.invoiceId })}>
              {`Invoice #${item.invoiceNumber}`}
            </span>
            {isPastActivity ? ' was ' : ' is '}
            due
          </span>
        );
      case 'paid':
        return (
          <span>
            <span className={Styles.activityLink} onClick={() => onClickLink({ type: 'invoice', id: item.invoiceId })}>
              {`Invoice #${item.invoiceNumber}`}
            </span>
            {isPastActivity ? ' was ' : ' is '}
            paid
          </span>
        );
      default:
        return '';
    }
  };

  // Both graphs should have the same max height
  const graphYAxisDomain = [
    -0.1,
    // Both graphs should have the same max height
    Math.max(
      ...props.fees.summaryPerDay.map((d) => d.Billable),
      ...props.fees.summaryPerDay.map((d) => d['Non-Billable']),
      ...props.fees.summaryToday.map((d) => d.Billable),
      ...props.fees.summaryToday.map((d) => d['Non-Billable']),
      8,
    ),
  ];

  // Default ticks should be 0, 2, 4, 6, 8
  // Otherwise we should 5 ticks
  const tickSize = graphYAxisDomain[1] / 4;
  const ticks = [0, Math.round(tickSize), Math.round(tickSize * 2), Math.round(tickSize * 3), graphYAxisDomain[1]];

  return (
    <div className={Styles.billingMatterDashboardRoute}>
      <div className={Styles.wrapper}>
        <div className={Styles.widgetsContainer}>
          <div className={Styles.widgets}>
            <div className={Styles.col1}>
              {props.matterDetails.loading && (
                <div className={classNames(Styles.floater, Styles.staffDetails)}>
                  {[...Array(2)].map((_, i) => (
                    <div key={i} className={Styles.flexer}>
                      <LoadingPlaceholderElement className={Styles.placeholderContent} isLine />
                    </div>
                  ))}
                </div>
              )}
              {!props.matterDetails.loading &&
                props.hasMultipleStaff &&
                props.matterDetails?.matter?.attorneyResponsible?.name && (
                  <div className={classNames(Styles.floater, Styles.staffDetails)}>
                    <div className={Styles.staffTag}>Staff</div>
                    <div className={Styles.title}>
                      {props.matterDetails.matter.attorneyResponsible.name +
                        (props.matterDetails.matter.attorneyResponsible.isFormerStaff ? ' (Former Staff)' : '')}
                    </div>
                    <div className={Styles.subTitle}>{t('capitalizeAllWords', { val: 'personResponsible' })}</div>
                    {props.matterDetails?.matter?.personAssisting?.name && (
                      <>
                        <div className={Styles.title}>
                          {props.matterDetails.matter.personAssisting.name +
                            (props.matterDetails.matter.personAssisting.isFormerStaff ? ' (Former Staff)' : '')}
                        </div>
                        <div className={Styles.subTitle}>Person Assisting</div>
                      </>
                    )}
                  </div>
                )}
              {props.matterDetails.loading && (
                <div className={classNames(Styles.floater, Styles.matterDetails)}>
                  {[...Array(5)].map((_, i) => (
                    <div key={i} className={Styles.flexer}>
                      <LoadingPlaceholderElement className={Styles.placeholderContent} isLine />
                    </div>
                  ))}
                </div>
              )}
              {!props.matterDetails.loading && (
                <div className={classNames(Styles.floater, Styles.matterDetails)}>
                  <div className={Styles.clientTag}>Client</div>
                  <div
                    onClick={() => onClickLink({ type: 'contact', id: props.clients?.defaultClient?.id })}
                    className={Styles.title}
                  >
                    {props.clients?.defaultClient?.displayNameFirstLast || ''}
                  </div>
                  {props.clients.defaultClient && (
                    <div className={Styles.contactDetails}>
                      {(props.clients.defaultClient.phone || props.clients.defaultClient.phone2) && (
                        <div className={Styles.phone}>
                          <Phone />
                          <a href={`tel:${props.clients.defaultClient.phone || props.clients.defaultClient.phone2}`}>
                            {props.clients.defaultClient.phone || props.clients.defaultClient.phone2}
                          </a>
                        </div>
                      )}
                      {props.clients.defaultClient.cell && (
                        <div className={Styles.phone}>
                          <Smartphone />
                          <a href={`tel:${props.clients.defaultClient.cell}`}>{props.clients.defaultClient.cell}</a>
                        </div>
                      )}
                      {props.clients.defaultClient.email && (
                        <div className={Styles.email}>
                          <AtSign />
                          <a href={`mailto:${props.clients.defaultClient.email}`}>
                            {props.clients.defaultClient.email}
                          </a>
                          <CopyIcon
                            onClick={() => navigator.clipboard.writeText(props.clients.defaultClient.email)}
                            className={Styles.copy}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
              {props.clients.loading && (
                <div className={Styles.floater}>
                  {[...Array(3)].map((_, i) => (
                    <div key={i} className={Styles.flexer}>
                      <LoadingPlaceholderElement isLine />
                    </div>
                  ))}
                </div>
              )}
              {!props.clients.loading && !!props.clients.otherClients.length && (
                <div className={Styles.floater}>
                  <div className={Styles.otherClientsTag}>{`Other ${
                    props.matterDetails?.matter?.isLead ? 'Prospect' : 'Client'
                  }${props.clients.otherClients.length > 1 ? 's' : ''}`}</div>
                  {props.clients.otherClients.map((client) => (
                    <ContactDisplay key={client.id} onClickLink={onClickLink} client={client} />
                  ))}
                </div>
              )}
              {!props.clients.loading && !!props.clients.debtors.length && (
                <div className={Styles.floater}>
                  <div className={Styles.debtorsTag}>{`Debtor${props.clients.debtors.length > 1 ? 's' : ''}`}</div>
                  {props.clients.debtors.map((client) => (
                    <ContactDisplay key={client.id} onClickLink={onClickLink} client={client} />
                  ))}
                </div>
              )}
              {!props.clients.loading && !!props.clients.otherSide.length && (
                <div className={Styles.floater}>
                  <div className={Styles.otherSideTag}>Other side</div>
                  {props.clients.otherSide.map((client) => (
                    <ContactDisplay key={client.id} onClickLink={onClickLink} client={client} />
                  ))}
                </div>
              )}
            </div>
            <div className={Styles.col2}>
              <div className={Styles.accounts}>
                {props.accounts.loading && (
                  <div className={Styles.floater}>
                    <div className={Styles.heading}>Invoicing</div>
                    {[...Array(3)].map((_, i) => (
                      <div key={i} className={Styles.item}>
                        <LoadingPlaceholderElement isLine />
                      </div>
                    ))}
                  </div>
                )}
                {!props.accounts.loading && (
                  <div className={Styles.floater}>
                    <div className={Styles.heading}>Invoicing</div>
                    <div onClick={() => onClickLink({ type: 'matterFees', id: matterId })} className={Styles.item}>
                      <div className={classNames(Styles.icon, Styles.greenIcon)}>
                        <InvoiceDottedIcon />
                      </div>
                      <div title={t('cents', { val: props?.accounts.totals?.UNBILLED })} className={Styles.amount}>
                        {t('cents', { val: props?.accounts.totals?.UNBILLED })}
                        <div className={Styles.amountTitle}>Unbilled</div>
                      </div>
                    </div>
                    <div className={classNames(Styles.item, Styles.middle)}>
                      <div className={classNames(Styles.icon, Styles.greenIcon)}>
                        <InvoiceIcon />
                      </div>
                      <div
                        onClick={() => {
                          onFilterByRecent(false);
                          onFilterInvoiceStatus(['FINAL', 'OVERDUE']);
                          onClickLink({ type: 'matterBills', id: matterId });
                        }}
                        title={t('cents', { val: props?.accounts?.totals?.UNPAID })}
                        className={classNames(Styles.amount)}
                      >
                        {t('cents', { val: props?.accounts?.totals?.UNPAID })}
                        <div className={Styles.amountTitle}>Unpaid</div>
                      </div>
                    </div>
                    <div className={classNames(Styles.item)}>
                      <div className={classNames(Styles.icon, Styles.redIcon)}>
                        <InvoiceIcon />
                      </div>
                      <div
                        onClick={() => {
                          onFilterByRecent(false);
                          onFilterInvoiceStatus(['OVERDUE']);
                          onClickLink({ type: 'matterBills', id: matterId });
                        }}
                        title={t('cents', { val: props?.accounts?.totals?.OVERDUE })}
                        className={classNames(Styles.amount)}
                      >
                        {t('cents', { val: props?.accounts?.totals?.OVERDUE })}
                        <div className={Styles.amountTitle}>Overdue</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {featureActive('BB-13753') && (
                <div className={Styles.floater}>
                  <DailyDigestWidget onClickLink={onClickLink} matterId={matterId} />
                </div>
              )}
              <div className={Styles.pastActivity}>
                <div className={Styles.floater}>
                  <div className={Styles.switch}>
                    <div
                      onClick={() => setTab(true)}
                      className={classNames(pastActivity && Styles.active, Styles.switchText)}
                    >
                      Recent activity
                    </div>
                    <div
                      onClick={() => setTab(false)}
                      className={classNames(!pastActivity && Styles.active, Styles.switchText)}
                    >
                      Coming up soon
                    </div>
                  </div>
                  {!pastActivity && !props.activities.futureActivities.length && (
                    <div className={Styles.empty}>
                      <img src={emptyBox} alt="Nothing here!" />
                      <div className={Styles.text}>Looks like you don&apos;t have any upcoming activity yet</div>
                    </div>
                  )}
                  {pastActivity && props.activities.loading && <LoadingPlaceholderElement isLine />}
                  {(!pastActivity ? props.activities.futureActivities : props.activities.pastActivities).map(
                    ({ date, items }, i) => (
                      <div key={`${date}${i}`} className={Styles.block}>
                        {getTimestampDisplay(date)}
                        {items.map((item, index) => (
                          <div key={`${item.invoiceNumber}${index}`} className={Styles.item}>
                            <div className={classNames(Styles.dot, Styles[item.type])} />
                            <div className="text">
                              {getText(item, pastActivity, props.matterDetails?.matter?.isLead)}
                            </div>
                          </div>
                        ))}
                        <div className={Styles.line} />
                      </div>
                    ),
                  )}
                </div>
              </div>
              <div className={Styles.billingGraph}>
                <div className={Styles.floater}>
                  <div className={Styles.heading}>
                    Billable vs Non-billable<div className={Styles.subTitle}>Last 7 days</div>
                    <div className={Styles.chartHighlight} />
                  </div>
                  <ResponsiveContainer height={300}>
                    <BarChart
                      width={500}
                      height={300}
                      onClick={() => onClickLink({ type: 'matterFees', id: matterId })}
                      data={props.fees.summaryPerDay.map((d) => ({
                        // Add range to data
                        ...d,
                        Billable: [-0.1, d.Billable],
                        'Non-Billable': [-0.1, d['Non-Billable']],
                      }))}
                      margin={{
                        top: 5,
                        right: 0,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <XAxis height={50} axisLine={false} tickLine={false} dataKey="name" tick={CustomTickRenderer} />
                      <Tooltip
                        position={{ y: 50 }}
                        labelFormatter={() => {}}
                        formatter={(value) => `${value[1] || 0} hrs`}
                      />
                      <YAxis ticks={ticks} tickLine={false} axisLine={false} domain={graphYAxisDomain} />
                      <Legend height={22} iconType="square" align="left" />
                      <Bar dataKey="Billable" radius={[5, 5, 5, 5]} fill="#4BE9A7" />
                      <Bar dataKey="Non-Billable" radius={[5, 5, 5, 5]} fill="#DDE0E9" />
                    </BarChart>
                  </ResponsiveContainer>
                  <ResponsiveContainer height={300}>
                    <BarChart
                      width={150}
                      onClick={() => onClickLink({ type: 'matterFees', id: matterId })}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 5,
                        bottom: 5,
                      }}
                      data={props.fees.summaryToday.map((d) => ({
                        // Add range to data to show a small line even when data is 0
                        ...d,
                        Billable: [-0.1, d.Billable],
                        'Non-Billable': [-0.1, d['Non-Billable']],
                      }))}
                    >
                      <Tooltip
                        position={{ y: 50 }}
                        labelFormatter={() => {}}
                        formatter={(value) => `${value[1] || 0} hrs`}
                      />
                      <XAxis height={50} tick={CustomTickRenderer} axisLine={false} tickLine={false} dataKey="name" />
                      <YAxis ticks={ticks} tickLine={false} axisLine={false} hide domain={graphYAxisDomain} />
                      <Bar dataKey="Billable" radius={[5, 5, 5, 5]} fill="#4BE9A7" />
                      <Bar dataKey="Non-Billable" radius={[5, 5, 5, 5]} fill="#DDE0E9" />
                      <Legend height={22} iconType="square" align="left" payload={[]} />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BillingMatterDashboardRoute.displayName = 'BillingMatterDashboardRoute';
BillingMatterDashboardRoute.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  onFilterByRecent: PropTypes.func.isRequired,
  onFilterInvoiceStatus: PropTypes.func.isRequired,
  matterId: PropTypes.string.isRequired,
  fees: PropTypes.object.isRequired,
  accounts: PropTypes.object.isRequired,
  matterDetails: PropTypes.object.isRequired,
  clients: PropTypes.object.isRequired,
  activities: PropTypes.object.isRequired,
  hasMultipleStaff: PropTypes.bool.isRequired,
};

BillingMatterDashboardRoute.defaultProps = {};
