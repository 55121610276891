'use strict';

// The below is migrated from: monorepo/apps/smokeball-billing-web/src/redux/selectors/payment-source.js

/**
 * Returns the payment source for a particular payment type or undefined if the payment type is not provided or not found, this function is going to be use when we want
 * to get a particular payment source.
 * @param {Array<Payment_Source>} paymentSources
 * @param {PAYMENT_TYPE} paymentType
 * @return {Payment_Source|undefined} options, payment source or undefined
 */
const getPaymentSource = (paymentSources, paymentType) => {
  if (paymentSources) {
    if (paymentType) {
      const opt =
        paymentSources.find((option) => option.paymentType === paymentType && option.isCombinedBalance) ||
        paymentSources.find((option) => option.paymentType === paymentType);

      return opt;
    }
  }
  return undefined;
};

module.exports = {
  getPaymentSource,
};
