import React from 'react';
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider, withApolloClient } from 'web/react-redux/hocs';
import {
  integrations,
  integrationNames,
  failedRequestType,
  failedRequestTarget,
} from '@sb-billing/business-logic/integration-history/entities';
import xeroLogo from 'web/assets/integration-xero.png';
import myobLogo from 'web/assets/integration-myob.png';
import { IntegrationFailedRequests, MyobConnectionIssue, XeroConnectionIssue } from 'web/graphql/queries';
import { useSubscribedQuery } from 'web/hooks';
import { daysAgo } from '@sb-itops/date';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import * as messageDisplay from '@sb-itops/message-display';
import { getAccountId, getUserId } from 'web/services/user-session-management';
import { getLogger } from '@sb-itops/fe-logger';
import {
  useLastConnectionIssuesCount,
  useLastFailedTransactionsCount,
} from 'web/components/page-failed-transactions-notifier';
import { IntegrationAccountingSettingsHeader } from './IntegrationAccountingSettingsHeader';

const log = getLogger('IntegrationAccountingSettingsHeader.container');
const TWO_MINUTES_IN_SECONDS = 120;

const configByIntegration = {
  [integrations.XERO]: {
    type: integrations.XERO,
    name: integrationNames[integrations.XERO],
    logoPath: xeroLogo,
  },
  [integrations.MYOB]: {
    type: integrations.MYOB,
    name: integrationNames[integrations.MYOB],
    logoPath: myobLogo,
  },
};

const hooks = () => ({
  useIntegrationPanel: ({ integrationType, onConnectClick }) => {
    const integrationCfg = configByIntegration[integrationType];
    return {
      onConnectClick,
      integrationCfg,
    };
  },
  useIntegrationFailedConnection: ({ integrationType }) => {
    const { data: xeroData, loading: xeroDataLoading } = useSubscribedQuery(XeroConnectionIssue, {
      skip: integrationType !== integrations.XERO,
    });
    const { data: myobData, loading: myobDataLoading } = useSubscribedQuery(MyobConnectionIssue, {
      skip: integrationType !== integrations.MYOB,
    });

    const connectionIssueData =
      integrationType === integrations.XERO ? xeroData?.xeroAccessToken : myobData?.myobAccessToken;

    const { lastConnectionIssuesCount, setLastConnectionIssuesCount } = useLastConnectionIssuesCount({
      integration: integrationType,
    });

    if (
      Number.isInteger(connectionIssueData?.connectionIssuesCount) &&
      (!Number.isInteger(lastConnectionIssuesCount) ||
        lastConnectionIssuesCount !== connectionIssueData?.connectionIssuesCount)
    ) {
      setLastConnectionIssuesCount(connectionIssueData?.connectionIssuesCount);
    }

    return {
      isConnected: !!connectionIssueData?.id,
      hasConnectionIssue: connectionIssueData?.connectionIssuesCount > 0,
      connectionIssuesCount: connectionIssueData?.connectionIssuesCount,
      integrationLoading: xeroDataLoading || myobDataLoading,
      organisationName: connectionIssueData?.organisationName, // organisationName is only available for Xero
    };
  },
});

const dependentHooks = () => ({
  useIntegrationFailedTransaction: ({ isConnected, connectionIssuesCount, integrationLoading, integrationType }) => {
    // We need to load transactions only when there is no connection issue
    const skipQuery =
      !isConnected || integrationLoading || !Number.isInteger(connectionIssuesCount) || connectionIssuesCount > 0;

    const sevenDaysAgoDate = daysAgo(7).toISOString(); // begining of day 7 days ago

    const { data: failedRequestsData } = useSubscribedQuery(IntegrationFailedRequests, {
      skip: skipQuery,
      variables: {
        filter: {
          since: sevenDaysAgoDate,
          integrationTargets: [failedRequestTarget[integrationType]],
          failureTypes: [failedRequestType.TRANSACTION, failedRequestType.CONNECTION],
          includeIgnored: false,
          includeResolved: false,
        },
      },
    });

    // lastFailedTransactionsCount is used in the main app to decide if we should show the warning message or not.
    // We set it here, so when user closes settings and return to main app, it doesn't trigger the warning message.
    // Please see PageFailedTransactionsNotifier component for more details.
    const { lastFailedTransactionsCount, setLastFailedTransactionsCount } = useLastFailedTransactionsCount({
      integration: integrationType,
    });

    if (
      Array.isArray(failedRequestsData?.integrationFailedRequests) &&
      (!Number.isInteger(lastFailedTransactionsCount) ||
        lastFailedTransactionsCount !== failedRequestsData?.integrationFailedRequests?.length)
    ) {
      setLastFailedTransactionsCount(failedRequestsData?.integrationFailedRequests?.length);
    }

    return {
      failedRequestTransactions: failedRequestsData?.integrationFailedRequests || [],
    };
  },
});

export const IntegrationAccountingSettingsHeaderContainer = withApolloClient(
  withReduxProvider(
    composeHooks(hooks)(
      composeHooks(dependentHooks)((props) => {
        const { failedRequestTransactions, syncInProgress } = filterFailedRequestTransactions({
          failedRequests: props.failedRequestTransactions,
        });
        const failedRequestCount = failedRequestTransactions.length;

        const onSyncClick = async () => {
          try {
            const ids = failedRequestTransactions.map((failedTxn) => failedTxn.id);
            log.info(
              `Resending integration transactions: ${JSON.stringify({
                accontId: getAccountId(),
                userId: getUserId(),
                integration: props.integrationType,
                failedRequestIds: ids,
              })}`,
            );
            const transactionPromises = ids.map((failedTxnId) =>
              dispatchCommand({
                type: 'Billing.Integrations.Messages.ResendIntegrationMessageRequest',
                message: { integrationHistoryId: failedTxnId },
              }),
            );
            await Promise.all(transactionPromises);
            messageDisplay.info('Resending transactions... please check again in a few minutes.');
          } catch (e) {
            messageDisplay.error('Error resending transactions, please contact support.');
          }
        };

        const onIgnoreClick = async () => {
          try {
            log.info(
              `Ignoring integration transactions: ${JSON.stringify({
                accontId: getAccountId(),
                userId: getUserId(),
                integration: props.integrationType,
                failedRequestIds: failedRequestTransactions.map((failedTxn) => failedTxn.id),
              })}`,
            );

            const transactionPromises = failedRequestTransactions.map((failedTxn) =>
              dispatchCommand({
                type: 'Billing.Integrations.Messages.UpdateIntegrationFailedRequest',
                message: {
                  id: failedTxn.id,
                  type: failedTxn.type,
                  integrationTarget: failedTxn.integrationTarget,
                  isResolved: failedTxn.isResolved,
                  replayInProgress: failedTxn.replayInProgress,
                  isIgnored: true,
                },
              }),
            );
            await Promise.all(transactionPromises);
            messageDisplay.info('Ignoring transactions... please check again in a few seconds.');
          } catch (e) {
            messageDisplay.error('Error ignoring transactions, please contact support.');
          }
        };

        return (
          <IntegrationAccountingSettingsHeader
            {...props}
            failedRequestCount={failedRequestCount}
            hasConnectionIssue={!!props.hasConnectionIssue}
            syncInProgress={syncInProgress}
            onSyncClick={onSyncClick}
            onIgnoreClick={onIgnoreClick}
          />
        );
      }),
    ),
  ),
);

/**
 * Get transactions, which we can replay/ignore. Those are:
 * - failed transactions, which are not "in progress" (currently being replayed)
 * - failed transactions, which are "in progress" for more then 2 minutes. Normal replay, including retry
 *   with backoff, takes around 60s. Therefore, if transaction is "in progress" after 2 minutes, it is likely stuck.
 */
const filterFailedRequestTransactions = ({ failedRequests }) => {
  const { syncInProgress, failedRequestTransactions } = failedRequests.reduce(
    (acc, failedRequest) => {
      // include txn if is not in progress
      if (!failedRequest.replayInProgress) {
        acc.failedRequestTransactions.push(failedRequest);
      }
      // if txn is in progress, check when it was last time updated
      if (failedRequest.replayInProgress) {
        const startTime = moment();
        const endTime = moment(failedRequest.timestamp);
        const diffInSeconds = startTime.diff(endTime, 'seconds');

        if (diffInSeconds > TWO_MINUTES_IN_SECONDS) {
          // Ignore "in progress" flag and consider it as not "in progress"
          acc.failedRequestTransactions.push(failedRequest);
        } else {
          // Otherwise, we have some transactions in progress so we want to disallow user to click Sync/Ignore again.
          acc.syncInProgress = true;
        }
      }

      return acc;
    },
    {
      syncInProgress: false,
      failedRequestTransactions: [],
    },
  );

  return {
    failedRequestTransactions,
    syncInProgress,
  };
};

IntegrationAccountingSettingsHeaderContainer.displayName = 'IntegrationAccountingSettingsHeaderContainer';

IntegrationAccountingSettingsHeaderContainer.propTypes = {
  integrationType: PropTypes.string.isRequired,
  onConnectClick: PropTypes.func,
  onReconnectClick: PropTypes.func,
};

IntegrationAccountingSettingsHeaderContainer.defaultProps = {
  onConnectClick: () => {},
  onReconnectClick: () => {},
};
