'use strict';

import * as cacheQuery from './cache-query';
import { upgradeCachesP, DICT_STORE } from './upgrade-caches';
import { store } from '@sb-itops/redux';
import { toCamelCase } from '@sb-itops/camel-case';
import { getUpdates, clearUpdates } from '@sb-itops/redux/cache-update';
import { getLogger } from '@sb-itops/fe-logger';
import { fastSync } from './fast-sync';

// To enable Streaming JSON (JSONL) support for a cache, the endpoint needs to have
// the `fileFormat` argument to getPayload and s3BulkSync due to backwards compatibility
const FAST_SYNC_CACHES = [
  'sbInvoicingService',
  'sbExpenseService',
  'sbContactsMbService',
  'sbCorrespondenceHistoryService',
  'sbTransactionService',
  'sbBulkDepositService',
  'sbInvoiceTotalsService',
  'sbInvoiceDebtorTotalsService',
  'sbRelateContactToMattersMbService',
  'sbMattersMbService',
  'sbPaymentService',
  'sbBillingEventService',
  'sbPaymentPlansService',
  'sbBillingConfigurationService',
  'sbMatterHourlyRateService',
  'sbMatterTotalsService',
];

// opt-out of camel-case where this breaks the data format
const IGNORE_CAMELCASE_CACHES = [
  'sbPaymentProviderSettingsService',
];

const MAX_ROWS_IN_UPSERT_CHUNK = 5000;

/**
  ========================
  WARNING - PLEASE READ
  =======================
  Developer beware! This service is complicated and critical. Before making changes:

  1. Confirm they are necessary
  2. Make sure you know what you are doing
  3. Check with someone else who knows

  DO NOT REFACTOR! This service will be removed. Until then, dont anger the code gods.

  Thanks!
 */

/**
 * This service holds common update/syncing logic used across many other sb-x-service services. It will store/retrieve
 * info from indexedDB (if available)
 *
 * It should be used for full models, e.g. all matters for a firm, rather than an individual matter for a firm.
 *
 * The caches are intended to be used as singletons and together will form the domain model to be used by the
 * controllers to assemble the view model.
 *
 * The calling service name should be used as the name for the cache.
 *
 * The in-memory data should always be consistent.
 *
 * Local storage is used for convenience, but should not be relied on for succesful operation.
 */
angular.module('@sb-itops/services').service('sbGenericCacheService', function($rootScope, $timeout, $interval, $q, $indexedDB, smokeballDBKeyPaths, sbDispatcherService, sbGenericEndpointService, sbEndpointType,
  sbNotifiedOperationP) {
  const log = getLogger('sbGenericCacheService');
  // log.setLogLevel('info');
  const caches = {}, that = this;
  const cachesToInitialize = {};

  let enqueuedUpserts = [];
  let upsertsQueueIsPersisting = false;
  let cacheClearingP = Promise.resolve();
  let hasCacheUpgrades = false;
  let loadedCachesCount = 0;
  let totalCachesCount = 0;

  that.cacheQuery = cacheQuery.api;
  that.getState = getState;
  that.initializeCachesP = initializeCachesP;
  that.getCacheLoadProgress = getCacheLoadProgress;
  that.resetCacheLoadProgress = resetCacheLoadProgress;
  that.clearAllCachesP = clearAllCachesP;
  that.persistChangesetP = persistChangesetP;
  that.setupCache = setupCache;
  that.getCache = getCache;

  cacheQuery.setCaches(caches);

  function getState() {
    return Object
      .values(caches)
      .sort((a, b) => a.name < b.name)
      .map(({ name, newStyle, lastUpdated, sync }) => ({
        name,
        newStyle,
        type: sync && sync.endpoint && sync.endpoint.type,
        lastUpdated,
        count: Object.keys(cacheQuery.api.query(name)).length,
      }));
  }

  /**
  * Initialies all caches by:
  * - running upgrades
  * - initialising cache data
  */
  async function initializeCachesP (preLoadCacheNames, skipInitialUpdate, skipCacheNames) {
    log.info('Start initialising caches');

    // Perform any necessary cache upgrades.
    const {
      upgrades,
      dictVersion,
    } = await upgradeCachesP($indexedDB, $q);

    // dict version == 0, is a new customer or purge error. New customer is the likely option so assume no upgrade
    hasCacheUpgrades = dictVersion > 0 && upgrades.length > 0;
    upgrades.forEach(({ name, hasStorageError }) => {
      if (!caches[name]) {
        log.error(`saw upgrade for unknown cache: ${name}, hasStorageError? ${hasStorageError}`);
        return;
      }
      caches[name].hasStorageError = hasStorageError;
    });

    const preloadCaches = Object.values(cachesToInitialize).filter(cache => preLoadCacheNames.includes(cache.name));
    const cachesToInitializeArray = Object.values(cachesToInitialize).filter(cache => !preLoadCacheNames.includes(cache.name) && !(skipCacheNames || []).includes(cache.name));
    loadedCachesCount = 0;
    totalCachesCount = cachesToInitializeArray.length;
    log.info(`initialising data for ${cachesToInitializeArray.length} caches`);
    await Promise.all(preloadCaches.map(cache => {
      return initializeCacheDataP(cache, skipInitialUpdate).then((loadCacheResult) => {
        ++loadedCachesCount;
        return loadCacheResult;
      })
    }));

    await Promise.all(cachesToInitializeArray.map(cache => {
      return initializeCacheDataP(cache, skipInitialUpdate).then((loadCacheResult) => {
        ++loadedCachesCount;
        return loadCacheResult;
      })
    }));

    log.info('Initialise caches completed.');
  }

  function getCache (name) {
    var cache = caches[name];

    if (!cache) {
      throw new Error('cache not found for: ' + name);
    }

    return cache;
  }


  /**
   * @returns Object<{ loadedCachesCount: number, totalCachesCount: number, hasCacheUpgrades: boolean }>
   */
  function getCacheLoadProgress() {
    return {
      loadedCachesCount,
      totalCachesCount,
      hasCacheUpgrades,
    };
  }

  /**
   * Sets cache counts to 0
   */
  function resetCacheLoadProgress() {
    loadedCachesCount = 0;
    totalCachesCount = 0;
  }

  /**
   * Clear all the caches
   * @param {object} param
   * @param {boolean} param.clearPersistentStorage
   * @returns Promise
   */
  async function clearAllCachesP({ clearPersistentStorage }) {
    // clear the in-memory cache
    Object.values(caches).map((cache) => {
      cache.activePromise = undefined;
      cache.cachedModel = {};
      cache.initialized = false;
      cache.loadPromise = undefined;
      cache.lastUpdated = '';
      cache.isFirstSync = true;
      cache.isStopped = true;

      // Stop polling for updates on the cache.
      if (cache.pollingTimerId) {
        $interval.cancel(cache.pollingTimerId);
        cache.pollingTimerId = undefined;
      }

      // Stop processing messages from the dispatcher service
      if (!_.isEmpty(cache.subscriptions)) {
        sbDispatcherService.deregister(`sbGenericCacheService:${cache.name}`);
      }
    });

    enqueuedUpserts = [];
    upsertsQueueIsPersisting = false;

    if (!clearPersistentStorage) {
      return $q.when();
    }

    // clear indexeddb, waiting for any existing clears to finish before starting another.
    cacheClearingP = cacheClearingP.then(
      () => $indexedDB.openAllStores(function () {
        return Promise.all(Object.values(arguments).map((store) => {
          if (store.storeName === DICT_STORE) {
            log.info(`SKIP - clear cache ${store.storeName}`);
            return $q.when();
          }

          return store
            .clear()
            .then(() => {
              log.info('DONE - clearing store : ' + store.storeName);
              broadcastUpdate({ name: store.storeName });
            })
            .catch((err) => {
              // is this a security risk, maybe show notification to clear browser data??? ctrl + shift + del
              log.error(`error purging store ${store.storeName}`, err);
              if (caches[store.storeName]) {
                caches[store.storeName].hasStorageError = true;
              }
            });
          }))
        })
      )
      .catch(function (err) {
        log.error('error purging all stores: %s', err);
      });
    return cacheClearingP;
  }

  /**
   * persistChangesetP updates the in-memory cache and, if sensible, enqueues the updates for saving to local persistent storage (indexeddb).
   *
   * The in-memory cache remains consistent allowing the user to continue even if a persistent storage operation fails.
   * In this cases (of a storage failure on a cache), a returning user would also have a consistent, albeit outdated, persistent store.
   * The app will load the data from the store and sync any updates since the last succesful update, bringing the persistent and in-memory stores back in sync
   *
   * @param cache       cache to update
   * @param changeset   array of changes
   * @param lastUpdated date of the latest data used to sync future updates
   * @param polling     whether the cache is a sync service - DEPRECATED: parameter is ignored
   * @returns {*}       promise
   * @private
   */
  async function persistChangesetP(cache, changeset, lastUpdated, polling, optimistic) {
    const inserts = _.filter(changeset, function (change) {
      return _.size(change) > 1;
    });
    const hasChanges = (lastUpdated && lastUpdated !== cache.lastUpdated) || _.size(inserts) > 0;
    const isPollingCache = isSyncCache(cache);

    function updateMemoryCache() {
      log.info(`update redux on persist ${cache.name}`, inserts.length);
      cache.updateRedux({ entities: inserts, lastUpdated });

      if (lastUpdated !== undefined && lastUpdated !== 'undefined' && lastUpdated !== '') {
        cache.lastUpdated = lastUpdated;
      }
    }

    function broadcastDataUpdate() {
      if (cache.initialized) {
        log.info('persistChangesetP broadcast sent: %s, %s changes', 'smokeball-data-update-' + cache.name, _.size(changeset));
        broadcastUpdate(cache, changeset);
        //$rootScope.$broadcast('smokeball-data-update-' + cache.name, cache);
      } else {
        log.debug('cache not initialised, broadcast not sent: %s', 'smokeball-data-update-' + cache.name);
      }
    }

    // shortcut to notify load on demand services that we asked the server for the latest data and there was none so they
    // are safe to use the cache
    if (cache.initialized && !isPollingCache && _.isEmpty(changeset)) {
      log.info('Broadcasting update for load on demand service with no new data');
      broadcastUpdate(cache, changeset);
      //$rootScope.$broadcast('smokeball-data-update-' + cache.name, cache);
      return $q.when();
    }

    // process latest updates
    if (hasChanges) {
      log.info('%s inserts found', cache.name, inserts.length);

      updateMemoryCache();

      if (cache.hasStorageError || optimistic) { // return now as it's not safe to update the persistent store
        if (!optimistic) {
          log.error('skipping update to persistent store for %s', cache.name);
        }
        broadcastDataUpdate();
        return $q.when();
      }

      // Enqueue the upserts to be saved to persistent storage in an async manner.
      // The rest of the application doesn't need to wait for the enqueued upserts to take effect, as persistent data is only used during bootstrapping.
      enqueueUpserts({ cache, upserts: inserts });
      broadcastDataUpdate();

    } else {
      log.info('No changes to update for %s', cache.name);
    }
  }

  /**
   * _enqueueUpserts
   *
   * Appends the passed upsert rows to the upsert persistence queue for future persistence.
   *
   * The passed upsert array will be broken into chunks of MAX_ROWS_IN_UPSERT_CHUNK, so, for example,
   * if the passed upsert array has 2 x MAX_ROWS_IN_UPSERT_CHUNK + 1 elements, the enqueuedUpserts array
   * will have 3 elements appended, with upsert array sizes of MAX_ROWS_IN_UPSERT_CHUNK, MAX_ROWS_IN_UPSERT_CHUNK and 1 respectively.
   *
   * If processing of the enqueuedUpserts array is not currently taking place, e.g. because the queue was fully processed, calling
   * _enqueueUpserts will trigger the queue processing to resume. It will be paused once again when the enqueuedUpserts array is empty.
   *
   * There will never be more than one upsert chunk being persisted at any one time, so passing upserts via _enqueueUpserts() ensures
   * that the upserts will be processed in the order they were submitted via _enqueueUpserts().
   *
   * @param {Object} params
   * @param {Object} params.cache The cache to which the upserts belong
   * @param {Array} params.upserts The array of objects (entities) to be upserted to the cache.
   */
  function enqueueUpserts({ cache, upserts }) {
    // To simplify the mental model, upsert enqueing on a cache will be ignored if the cache is stopped.
    // It would be potentially more efficient to keep enqueueing and persisting, because the customer might
    // have the private computer checkbox checked. So in that case, even after logout, we could eek out a few
    // more persists in the queue before they close the tab / refresh the page.
    // But the complexity of such code wouldn't be worth the value gain.
    if (cache.isStopped) {
      return;
    }

    // Defensive code, not sure why this would happen.
    if (!Array.isArray(upserts) || !upserts.length) {
      log.error(`Attempt to enqueue empty / non array upserts was ignored for ${cache.name}`, upserts);
      return;
    }

    // Chunk the upsert requests to prevent indexedDB transaction timeout + any unknown upsert limitations
    // of index DB.
    const chunkedUpserts = upserts.reduce((acc, upsert, index) => {
      const chunkIndex = Math.floor(index / MAX_ROWS_IN_UPSERT_CHUNK);
      acc[chunkIndex] = acc[chunkIndex] || { cache, upserts: [] };
      acc[chunkIndex].upserts.push(upsert);
      return acc;
    }, []);

    // Add each upsert chunk to the upsert queue and start persisting the queue if not already persisting.
    enqueuedUpserts = enqueuedUpserts.concat(chunkedUpserts);
    log.info(`${enqueuedUpserts.length} enqueuedUpserts waiting to be persisted - upsertsQueueIsPersisting(${upsertsQueueIsPersisting})`);
    if (!upsertsQueueIsPersisting) {
      log.info('upsert queue has elements, persisting initiated');
      persistUpserts();
    }
  }

  // async function clearCacheP(cache) {
  //   try {
  //     // This bizzare format is needed because the $indexedDB.openStore promise resolves to the underlying transaction, not the cache store.
  //     // We need to pass in a callback and wrap it in a different promise to be able to await the cache store creation.
  //     const cacheStore = await new Promise((resolve) => $indexedDB.openStore(cache.name, resolve));

  //     await cacheStore.clear();
  //     log.info(`successfully cleared store ${cache.name}`);
  //   } catch (err) {
  //     log.info(`problem clearing store ${cache.name}`);
  //     cache.hasStorageError = true;
  //   }
  // }

  /**
   * persistUpserts
   *
   * Processes the enqueuedUpserts array, committing each entry to persistent storage.
   * This function will continue until all entries in the queue are processed (or removed on cache shutdown)
   *
   */
  async function persistUpserts() {
    // Defensive, persistUpserts shouldn't be called unless upsertsQueueIsPersisting is false.
    // Ensures that the queue is always processed serially.
    if (upsertsQueueIsPersisting || !enqueuedUpserts.length) {
      return;
    }

    upsertsQueueIsPersisting = true;

    while (enqueuedUpserts.length) {
      const { cache, upserts } = enqueuedUpserts.shift();

      if (cache.hasStorageError || cache.isStopped) {
        continue;
      }

      try {
        // Read the angular-indexed-db readme on $indexedDB.openStore.
        // We need to pass in an async callback (which is the scope of our transaction)
        // and the result of openStore is a chained promise of the DB transaction AND our async callback.
        await $indexedDB.openStore(cache.name, async (cacheStore) => {
          // Sync all caches always clear all data on every upsert operation.
          if (cache.sync && cache.sync.endpoint.type === sbEndpointType.SYNC_ALL) {
            log.info('clearing cache for SYNC_ALL cache: ' + cache.name);
            await cacheStore.clear();
          }

          // We've already checked this condition above, so this is purely defensive. We want to prevent any situation like the following:
          //
          // 1. Logout (clear persistence = true)
          // 2. Cache is cleared
          // 3. _persistUpserts adds another record to the cache.
          //
          // If openStore, clear store, upsert are all considered "transactions" from the indexedDB perspective, it's possible that the clear
          // cache for logout could happen after we first check cache.isStopped, but before we do $indexedDB.openStore.
          //
          // TBH I'm not convinced this would ever happen anyway due to indexedDB's transaction model, but no harm being on the safe side.
          if (cache.isStopped) {
            return;
          }

          const upsertResult = await cacheStore.upsert(upserts);
          log.info(`successfully upserted ${upserts.length} rows`, upsertResult);
        });
      } catch (err) {
        log.error(`failed to upsert ${upserts.length} rows for '${cache.name} cache. Cache will run in memory only mode. Error:'`, err);
        log.error(`Failed upserts`, upserts);
        cache.hasStorageError = true;
      }
    }
    upsertsQueueIsPersisting = false;
  }

  async function callRemoteP(cache, deferredLock, prefix) {
    log.debug(`call remote...`);
    const name = cache.name;

    // This is needed to migrate SYNC_SINCE to SYNC_ALL
    if (cache.sync && cache.sync.endpoint.type === sbEndpointType.SYNC_ALL) {
      cache.lastUpdated = '';
    }

    return $q.when(cache.requestConstructor(`${prefix}${cache.lastUpdated}`))
      .then(function (req) {
        log.debug(`req`, req);

        if (req) {
          if (cache.isFirstSync && FAST_SYNC_CACHES.includes(cache.name)) {
            cache.isFirstSync = false;
            return fastSync({ cache, notifiedOperation: sbNotifiedOperationP })
              .then(({ lastUpdated, entityChanges }) => persistChangesetP(cache, entityChanges, lastUpdated))
              .then(() => callRemoteP(cache, deferredLock, prefix));
          }

          // provides support for queueing requests while refreshing
          return sbGenericEndpointService.http(req)
            .then(function (res) {
              // This could happen if a cache request was in flight while we were shutting down the cache.
              if (cache.isStopped) {
                return $q.when(cache.cachedModel);
              }

              let hasMore, newData, changeSet, persistPromise;

              if (res.status === 200 && res.data !== undefined) {
                cache.serverErrors = 0;
                newData = IGNORE_CAMELCASE_CACHES.includes(cache.name) ? res.data : toCamelCase(res.data);
                changeSet = Array.isArray(newData) ? newData : [newData];

                const lastUpdated = cache.sync.endpoint.type === sbEndpointType.SYNC_SINCE ? res.headers('X-sb-syncvalue') : undefined;

                // Determine if there are potentially more entities to be requested.
                if (lastUpdated === undefined || cache.lastUpdated === lastUpdated) {
                  // server counts are base on last updated at the time of sync. There is a small window where the an update occurred between the entity query
                  // and the count query. This would appear that the cache has more data than the server, and can be ignored. If the cache has less data than the
                  // server, we assume there is missing data and we need to force the resync of all data
                  const serverCount = res.headers('X-sb-entitycount') && Number.parseInt(res.headers('X-sb-entitycount'), 10) || undefined;
                  const cacheCount = cacheQuery.count(cache.name);
                  if (Number.isFinite(serverCount) && cacheCount < serverCount) {

                    log.error(`force resync`, JSON.stringify({
                      name: cache.name,
                      responseLastupdated: lastUpdated,
                      cacheLastUpdated: cache.lastUpdated,
                      serverCount,
                      cacheCount: cacheQuery.count(cache.name),
                    }));

                    // Clear data and force full resync - not enabled while we use logging to assess impact
                    // return clearCacheP(cache)
                    //   .then(() => {
                    //     cache.lastUpdated = '';
                    //     cache.isFirstSync = true;
                    //     hasMore = true;
                    //   });
                  }
                  hasMore = false;
                } else {
                  hasMore = true;
                }

                persistPromise = persistChangesetP(cache, changeSet, lastUpdated);

                return $q.when(persistPromise)
                  .then(function () {
                    if (hasMore) {
                      // service is chunked, we have more data to load
                      return callRemoteP(cache, deferredLock, prefix); // recursive call for next set
                    } else {
                      // service not chunked, or no results
                      return cache.cachedModel;
                    }
                  });
              } else {
                log.info('cache %s update failure: %s', name, res.statusText);
                throw new Error('failed cache update for ' + name + ': ' + res.statusText);
              }
            });
        } else {
          log.info('cache %s update skip as request not ready', name);
          return cache.cachedModel;
        }
      })
      .catch(function (err) {
        if (err && (err.status === 401 || err.message === 'Unauthorized')) {
          // User will be logged out, just ignore this result.
        } else {
          // $http err response includes the request config object, which includes the Authorisation header
          // https://docs.angularjs.org/api/ng/service/$http#$http-returns
          if (err?.config?.headers?.Authorization) {
            delete err.config.headers.Authorization;
          }

          if (isServerError(err && err.status)) {
            log.error('cache %s update error', name, err);
          } else {
            // Could be a timeout or other internal http service error. Clients do experience this a fair bit and the err returned is simply the request.
            log.error('Error occured while updating cache', name, err);
          }
          cache.serverErrors++;
          if (cache.serverErrors < 6) {
            log.error('Server error %s for %s, no. of tries %s', err.status, name, cache.serverErrors);
            deferredLock.resolve();
            return callRemoteP(cache, deferredLock, prefix);
          } else {
            log.error('The server for %s appears to be offline', name);
          }
        }
        return cache.cachedModel;
      });
  }

  /**
   * Async call to update from server, returns a promise.
   *
   * If called whilst already making an AJAX call it will NOT make another call. If you absolutely need to ensure
   * a fresh call is made you must call this, then on success re-call.
   *
   * @param name the cache to update.
   * @returns promise which will resolve into the cachedModel for the cache.
   */
  async function updateP(name, prefix = '') {
    const cache = caches[name];
    const deferredLock = $q.defer();
    log.info('updating cache %s', name);

    // This could happen if a timer fires while we are starting a cache shut down.
    if (cache.isStopped) {
      log.info('cache %s is stopped', name);
      return $q.when(cache.cachedModel);
    }

    // if already running, return result of that call instead
    if (cache.activePromise) {
      log.info('cache %s update in progress', name);
      return cache.activePromise;
    }

    cache.activePromise = deferredLock
      .promise
      .finally(function () {
        log.debug('cache %s update finished', name);
        // allow re-call of endpoint
        cache.activePromise = undefined;
      });

    if (cache.requestConstructor) {
      deferredLock.resolve(callRemoteP(cache, deferredLock, prefix));
    } else {
      deferredLock.resolve(cache.cachedModel);
    }

    return cache.activePromise;
  }

  function isServerError(status) {
    return /^(5[0-9][0-9])$/.test(status);
  }

  async function loadCacheP(cache) {
    log.info(`loading ${cache.name} data from store`);

    if (cache.hasStorageError) {
      return $q.resolve();
    }

    // Due to our tightly coupled 'design', _loadCacheP can be called multiple times for the same cache on login/refresh.
    // It's too risky / time consuming to fix it direclty, an easier solution is to just return any existing cache loading promise.
    if (cache.loadPromise) {
      return cache.loadPromise;
    }

    const loadCacheDefer = $q.defer();
    cache.loadPromise = loadCacheDefer.promise;

    loadAllEntitiesFromStoreP(cache).then(() => {
      cache.initialized = true;
      broadcastUpdate(cache, cache.cachedModel);
      loadCacheDefer.resolve();
    })
    .catch((err) => {
      log.error(`error opening store for ${cache.name}: ${err}`);
      cache.hasStorageError = true;
      loadCacheDefer.reject(err);
    });

    return loadCacheDefer.promise;
  }

  async function loadAllEntitiesFromStoreP(cache) {
    if (cache.hasStorageError) {
      log.error(`${cache.name} has storage error, skipping load from store`);
      return Promise.resolve();
    }

    // Read the angular-indexed-db readme on $indexedDB.openStore.
    // We need to pass in an async callback (which is the scope of our transaction)
    // and the result of openStore is a chained promise of the DB transaction AND our async callback.
    return $indexedDB.openStore(cache.name, (cacheStore) => {
      cache.loadedFromStoreCount = 0;
      return cacheStore.getAllKeys()
      .then(cacheKeys =>
        Promise
          .all(cacheKeys.map((key) => cacheStore.find(key).then((entity) => {
            cache.lastUpdated = cache.lastUpdated < entity.$sbSyncValue ? entity.$sbSyncValue : cache.lastUpdated;
            return entity;
          })))
          .then((entities = []) => {
            log.info(`updating redux with ${entities.length} entities for ${cache.name}, lastUpdated ${cache.lastUpdated}`);
            cache.updateRedux({ entities, lastUpdated: cache.lastUpdated });
            cache.loadedFromStoreCount = entities.length;
          }));
        }, 'readonly')
      .catch((err) => {
        // the order that entities are loaded is non-deterministic. If there was an error loading from disk, then
        // we risk having gaps in the cache. Reset the cache sync properties to fetch all data from the server
        cache.lastUpdated = '';
        cache.isFirstSync = true;
        cache.loadedFromStoreCount = 0;
        log.error(`Error loading entities from cache ${cache.name}`, err);
      });
  }

  /**
   * Setup a cache for an sb-service which needs its data stored client side, and needs to be periodically updated
   * @param setupObj a config object or name of the cache (name must be unique).
   * @returns {*}
   */
  function setupCache(setupObj) {
    if (setupObj.sync && !_.isObject(setupObj.sync)) {
      log.error('non-object sync value passed to setupCache: ', setupObj.name, setupObj);
    }

    if (setupObj.updateIntervalMinutes) {
      log.error('updateIntervalMinutes passed directly to setupCache, should be in sync {}: ', setupObj.name, setupObj);
    }

    if (setupObj.subscriptions) {
      log.error('subscriptions passed directly to setupCache, should be in sync {}: ', setupObj.name, setupObj);
    }

    if (setupObj.sync && setupObj.requestConstructor) {
      log.error('requestConstructor passed directly to setupCache with sync service, is derived: ', setupObj.name, setupObj);
    }

    return setupCacheInternal({
      newStyle: true,
      name: setupObj.name,
      sync: setupObj.sync,
      keyPath: smokeballDBKeyPaths[setupObj.name],
      requestConstructor: setupObj.requestConstructor,
      updateRedux: setupObj.updateRedux,
      clearRedux: setupObj.clearRedux,
    });
  }

  function setupCacheInternal(cache) {
    cache.cachedModel = {};
    cache.initialized = false;
    cache.loadPromise = undefined;
    cache.lastUpdated = '';
    cache.serverErrors = 0;
    cache.isFirstSync = true;
    cache.isStopped = false;

    if (caches[cache.name] !== undefined) {
      throw new Error('setupCache called for existing cache: ' + cache.name);
    }

    if (cache.requestConstructor && !_.isFunction(cache.requestConstructor)) {
      throw new Error('setupCache called without valid requestConstructor');
    }

    if (!cache.keyPath) {
      throw new Error('no keyPath found for ' + cache.name + ' ammend itops app.js or pass in keyPath in setupCache()');
    }

    if (!cache.updateRedux) {
      throw new Error(`${cache.name} setupCache called without valid updateRedux`);
    }

    if (cache.sync && (!cache.sync.endpoint || !cache.sync.endpoint.type || !cache.sync.endpoint.stub)) {
      throw new Error('invalid sync endpoint for cache ' + cache.name);
    }

    if (cache.sync && (cache.sync.endpoint.type === sbEndpointType.SYNC_SINCE) && !cache.clearRedux) {
      throw new Error('invalid sync since endpoint for cache ' + cache.name);
    }

    if (cache.sync) {
      cache.subscriptions = cache.sync.subscriptions;
      cache.updateIntervalMinutes = cache.sync.poll;

      if (_.last(cache.sync.endpoint.stub) !== '/') {
        cache.sync.endpoint.stub = cache.sync.endpoint.stub + '/';
      }

      if (cache.sync.requestConstructor && cache.name === 'sbRelateContactToMattersMbService') {
        cache.requestConstructor = cache.sync.requestConstructor;
      } else {
        switch(cache.sync.endpoint.type) {
          case sbEndpointType.SYNC_ALL:
            cache.requestConstructor = sbGenericEndpointService.requestConstructorFactory(cache.sync.endpoint.stub + 'sync', 'all');
            break;
          case sbEndpointType.SYNC_SINCE:
            cache.requestConstructor = sbGenericEndpointService.requestConstructorFactory(cache.sync.endpoint.stub + 'sync', 'since');
            break;
          default:
            throw new Error('unknown sync endpoint type: ' + cache.sync.endpoint.type + ' for cache: ' + cache.name);
        }
      }

      if (cache.subscriptions && _.isString(cache.subscriptions)) {
        cache.subscriptions = [cache.subscriptions];
      }
    }

    if (!cache.updateRedux || !_.isFunction(cache.updateRedux)) {
      throw new Error(`setupCache called without valid updateRedux for ${cache.name}`);
    }

    // defer initialization of cache until an external caller decides that the cache is ready to actually "run", i.e. poll
    // and process queries.
    log.info(`deferring load of cache data for ${cache.name}`);
    cachesToInitialize[cache.name] = cache;

    cache.getState = () => ({
      keyPath: cache.keyPath,
      lastUpdated: cache.lastUpdated,
      updateIntervalMinutes: cache.updateIntervalMinutes,
      subscriptions: cache.subscriptions,
      initialized: cache.initialized,
      hasStorageError: cache.hasStorageError,
      serverErrors: cache.serverErrors,
      sync: cache.sync,
    });

    caches[cache.name] = cache;

    return {
      get: function () { return cache.cachedModel; },
      updateP: function () { return updateP(cache.name); },
      applyChangesetP: function (changeset) { return persistChangesetP(cache, changeset); },
      updateRedux: cache.updateRedux,
    };
  }

  function shouldDoPresync(cache) {
    // some caches dont return a predictable count making data integrity checks impossible
    const excludeCaches = [
      'sbRelateContactToMattersMbService',
      'sbBankReconciliationSetupService2',
    ];
    if (excludeCaches.includes(cache.name)) {
      return false;
    }
    
    return cache.sync && cache.sync.endpoint.type === sbEndpointType.SYNC_SINCE;
  }

  /**
   * We havce observed clients who have gaps in their cache data when reloading the app, but we are unsure why.
   * In order to address this, we have adopted a one-time integrity check in an effort to decide whether we
   * should re-fetch all the data.
   *
   * When initialising a cache we first load the data from disk, keeping a record of the last updated timestamp
   * so that we can ask the server for any chnages we have yet to sync. Once we have all the latest data, we ask
   * the server how many records we should have gien our latest last updated value. If we cant reconcile the
   * values, we resync all the data.
   *
   * It is recognised that an update to a record during this time would result in a differenct count, this is
   * considered an acceptable compromoise
   */
  async function initializeCacheDataP(cache, skipInitialUpdate) {
    cache.isFirstSync = true;
    cache.isStopped = false;

    try {
      // load from disk
      await loadCacheP(cache);

      // send flag for caches to check the entity count too
      const prefix = shouldDoPresync(cache) ? 'presync$' : '';

      // Fetch preSync values
      // In local dev, we skip this request to get the app to load sooner
      // In other envs, we skip this for SYNC_ALL caches to prevent sending
      // duplicate requests - entities will be fetched via the `updateP` after
      // the polling/notification setup
      if (!skipInitialUpdate && shouldDoPresync(cache)) {
        // fetch latest from the server
        await updateP(cache.name, prefix);
      }

      // setup polling
      if (cache.updateIntervalMinutes) {
        log.info('%s will update every %s minutes', cache.name, cache.updateIntervalMinutes);
        cache.pollingTimerId = $interval(function () {
          log.info('%s updating', cache.name);
          updateP(cache.name);
        }, cache.updateIntervalMinutes * 1000 * 60);
      }

      // sync data via notifications
      if (!_.isEmpty(cache.subscriptions)) {
        sbDispatcherService.register(`sbGenericCacheService:${cache.name}`, function (message) {
          if (_.contains(cache.subscriptions, message.action)) {
            log.info('updated cache', cache.name, 'as it matches subscription', message.action);
            updateP(cache.name).then(() => {
              if (message.payload && message.payload.postDispatchEvent) {
                $rootScope.$broadcast(message.payload.postDispatchEvent.event, message.payload.postDispatchEvent.type);
              }
            });
          }
        });
      }

      // one last check in case we missed a notification
      log.info(`doing immediate update of ${cache.name}`);
      updateP(cache.name);

      return;
    } catch (err) {
      log.error(`problem initialising cache ${cache.name}`, err);
      throw err;
    }
  }

  // cache needs to broadcast updates initiated in redux to esnure that legacy code gets notified when changes happen
  store.subscribe(() => {
    const updates = getUpdates();
    const cacheNames = Object.keys(updates);

    if (cacheNames.length === 0) {
      return;
    }

    cacheNames.forEach(cacheName => {
      const cache = caches[cacheName];

      if (!cache) {
        throw new Error(`Attempt to broadcast update to unknown cache ${cacheName}`);
      }

      $timeout(() => broadcastUpdate(caches[cacheName], updates[cacheName]));
    });

    clearUpdates();
  });

  function broadcastUpdate(cache, payload) {
    log.info(`broadcasting update for ${cache.name}`);
    $rootScope.$broadcast('smokeball-data-update-' + cache.name, payload); // tell listeners that the data has changed
  }

  function isSyncCache (cache) {
    // updateIntervalMinutes is set in setupCacheInternal
    // we can't use the cache.sync object as `matter-types-mb-service` uses the old
    // form of setting up a sync cache (the endpoint url wasn't written in a form compatible with the syncing code)
    // Both old and new cache setup styles set `updateIntervalMinutes`.
    return cache.updateIntervalMinutes;
  }

});
