import { gql } from '@apollo/client';

const query = gql`
  query MyobAccessToken {
    myobAccessToken {
      id
      connectionIssuesCount
    }
  }
`;

const notificationIds = [
  'MyobIntegration.MyobAccessTokenUpdated',
  'MyobIntegration.MyobAccessTokenDeleted',
  'BillingIntegrationsNotifications.IntegrationFailedRequestUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MyobConnectionIssue = {
  query,
  notificationIds,
};
