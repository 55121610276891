'use strict';

const { PAYMENT_TYPE } = require('./payment-type');

// The below is migrated from: monorepo/apps/smokeball-billing-web/src/redux/selectors/payment-source.js

/**
 * A default is chosen using the following rules in order of preference:
 * If the matter has > 0 held in Trust, the default is the combined balance, or contact balance if there is no combined
 * If the matter has > 0 held in Operating, the default is the combined balance, or contact balance if there is no combined
 * If the matter has no money held in an account, the default is the payment source marked as isDefault, e.g. Check/Cheque
 */
const getDefaultPaymentSource = (paymentSources) =>
  getDefaultOption(paymentSources, PAYMENT_TYPE.trust) ||
  getDefaultOption(paymentSources, PAYMENT_TYPE.operating) ||
  getDefaultOption(paymentSources);

const getDefaultOption = (options, paymentType) => {
  if (!paymentType) {
    return options.find((option) => option.isDefault);
  }

  const opt =
    options.find((option) => option.paymentType === paymentType && option.isCombinedBalance) ||
    options.find((option) => option.paymentType === paymentType && option.balance > 0);

  return opt;
};

module.exports = {
  getDefaultPaymentSource,
};
