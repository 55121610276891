import { gql } from '@apollo/client';
// We currently use this only to determine if Myob is connected or not so we don't really care about most of the data
const query = gql`
  query MyobAccessToken {
    myobAccessToken {
      id
    }
  }
`;

const notificationIds = ['MyobIntegration.MyobAccessTokenUpdated', 'MyobIntegration.MyobAccessTokenDeleted'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MyobAccessToken = {
  query,
  notificationIds,
};
