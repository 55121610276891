import * as React from 'react';
import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';

import { FEE_MODAL_ID } from 'web/components';
import { FeeContainer } from './Fee.container';
import { feePresentationalComponentModes } from './Fee.forms.container';

const hooks = (props) => ({
  useFeeModalSpecificProps: () => {
    const updatedProps = {};

    // Modals are opened/closed with redux
    // Some instances require a callback when closing the modal
    // E.g. deleting a timer
    if (props.onModalClose) {
      updatedProps.onModalClose = () => {
        props.onModalClose();
        setModalDialogHidden({ modalId: FEE_MODAL_ID });
      };
    }

    // The onNavigateToInvoice still needs to be passed as a prop due to the current routing setup (angular)
    if (props.onNavigateToInvoice) {
      updatedProps.onNavigateToInvoice = () => {
        setModalDialogHidden({ modalId: FEE_MODAL_ID });
        props.onNavigateToInvoice();
      };
    }

    return {
      onModalClose: () => setModalDialogHidden({ modalId: FEE_MODAL_ID }),
      ...updatedProps,
    };
  },
});

export const FeeModalContainer = composeHooks(hooks)((props) => (
  <FeeContainer {...props} mode={feePresentationalComponentModes.MODAL} />
));

FeeModalContainer.displayName = 'FeeModalContainer';

FeeModalContainer.propTypes = {
  fee: PropTypes.object,
  feeId: PropTypes.string,
  hideSaveAndNewButton: PropTypes.bool,
  matterId: PropTypes.string, // Required when creating a fee linked to a matter (e.g. Matter page, Draft invoice page)
  preventDurationFieldSyncUpdates: PropTypes.bool, // E.g. when creating a fee via a timer, selecting an activity should not change the duration value (i.e. time) or type (e.g. FIXED, HOURS)
  scope: PropTypes.string,
  showModal: PropTypes.bool.isRequired, // Use setModalDialogVisible
  // Callbacks
  onFeeSave: PropTypes.func, // Optionally called after a successful save (e.g. deleting the associated timer)
  onModalClose: PropTypes.func,
  onNavigateToInvoice: PropTypes.func, // Not required for Global Quick Add (only create mode)
};

FeeModalContainer.defaultProps = {
  fee: undefined,
  feeId: undefined,
  hideSaveAndNewButton: undefined,
  matterId: undefined,
  preventDurationFieldSyncUpdates: undefined,
  // Callbacks
  onFeeSave: undefined,
  onModalClose: undefined,
  onNavigateToInvoice: undefined,
};
