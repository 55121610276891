import * as React from 'react';
import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';

import { FeeContainer } from './Fee.container';
import { feePresentationalComponentModes } from './Fee.forms.container';

const hooks = (props) => ({
  useFeePopOutEditorSpecificProps: () => {
    const {
      feeId,
      feeList,
      // Callbacks
      onFeeChange,
      onNavigateToInvoice,
      onSetIsExpanded,
    } = props;
    const feeListIds = feeList.map((fee) => fee.id);
    const feeIndex = feeListIds.findIndex((id) => id === feeId);
    const feeListCount = feeListIds.length;

    /**
     * onSetIsExpandedWithRules
     *
     * Adds business logic to the expanding/collapsing of the editor
     *
     * @param {object} params
     * @param {boolean} params.isExpanded
     * @returns A boolean value on whether the expanding/collapsing successfully occurred
     */
    const onSetIsExpandedWithRules = ({ isExpanded }) => {
      const emptyFeeList = !feeListCount;

      if (emptyFeeList) {
        return false;
      }

      if (isExpanded && !feeId) {
        // On expand editor
        onFeeChange({
          id: feeListIds[0],
        });
      } else if (!isExpanded) {
        // On collapse editor
        onFeeChange(undefined);
      }

      onSetIsExpanded(isExpanded);
      return true;
    };

    const onFormCancel = () => {
      onSetIsExpanded(false);
      onFeeChange(undefined);
    };

    const onNavigateToNextFee = () => {
      let nextFeesIndex;
      const isLastFeeRow = feeIndex === feeListCount - 1;

      if (isLastFeeRow) {
        nextFeesIndex = 0;
      } else {
        nextFeesIndex = feeIndex + 1;
      }

      onFeeChange({
        id: feeListIds[nextFeesIndex],
      });
    };

    const onNavigateToPreviousFee = () => {
      let previousFeesIndex;
      const isFirstFeeRow = feeIndex === 0;

      if (isFirstFeeRow) {
        previousFeesIndex = feeListCount - 1;
      } else {
        previousFeesIndex = feeIndex - 1;
      }

      onFeeChange({
        id: feeListIds[previousFeesIndex],
      });
    };

    return {
      feeId,
      feeIndex,
      feeListCount,
      // Callbacks
      onFormCancel,
      onNavigateToInvoice,
      onNavigateToNextFee,
      onNavigateToPreviousFee,
      onSetIsExpanded: (args) => onSetIsExpandedWithRules({ isExpanded: args.isExpanded }),
    };
  },
});

export const FeePopOutEditorContainer = composeHooks(hooks)((props) => (
  <FeeContainer {...props} mode={feePresentationalComponentModes.POP_OUT_EDITOR} />
));

FeePopOutEditorContainer.displayName = 'FeePopOutEditorContainer';

FeePopOutEditorContainer.propTypes = {
  feeId: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
  // Callbacks
  onFeeChange: PropTypes.func.isRequired,
  onNavigateToInvoice: PropTypes.func.isRequired,
  onSetIsExpanded: PropTypes.func.isRequired,
};

FeePopOutEditorContainer.defaultProps = {
  feeId: undefined,
};
