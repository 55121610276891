import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uuid from '@sb-itops/uuid';
import composeHooks from '@sb-itops/react-hooks-compose';

import { facets, hasFacet } from '@sb-itops/region-facets';
import { getRegion } from '@sb-itops/region';
import { useTranslation } from '@sb-itops/react';
import { capitalize } from '@sb-itops/nodash';

import { useForm } from '@sb-itops/redux/forms2/use-form';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { getLogger } from '@sb-itops/fe-logger';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { PrintManually, PrintNow } from '@sb-billing/business-logic/cheques';
import { PRINT_OPERATING_CHEQUE_MODAL_ID } from 'web/components';
import { getIncludingTaxAmount } from '@sb-billing/business-logic/expense/services';

import CreateOperatingChequeModal from './CreateOperatingChequeModal';
import { createOperatingChequeModalFormSchema } from './CreateOperatingChequeModalForm.yup';

const region = getRegion();
const log = getLogger('CreateOperatingChequeModal.forms.container');
const hooks = () => ({
  useCreateOperatingChequeForm: ({
    scope,
    expenses,
    availableOperatingChequeNumber,
    isLoadingAvailableOperatingChequeNumber,
    lastOperatingChequeNumber,
    operatingBankAccountData,
    operatingChequePrintSettings,
    expenseDataLoading,
    expenseIds,
    // Callbacks
    onGetAvailableOperatingChequeNumber,
    onModalClose,
    sbAsyncOperationsService,
    onClearSelectedExpenses,
  }) => {
    const { printMethod: defaultPrintMethod } = operatingChequePrintSettings;
    const { t } = useTranslation();

    const areQueriesLoading = expenseDataLoading;

    const allowChequeNumberDuplication = hasFacet(facets.allowDuplicateCheque);
    const chequeMemoEnabled = hasFacet(facets.chequeMemo);

    const createOperatingChequeForm = useForm({
      scope,
      schema: createOperatingChequeModalFormSchema,
    });
    const {
      formFields,
      formInitialised,
      formSubmitting,
      formValues,
      submitFailed,
      onUpdateFields: onUpdateFieldsOriginal,
      onInitialiseForm,
      onClearForm,
      onSubmitFormWithValidation,
      onValidateForm,
    } = createOperatingChequeForm;

    const isFormDisabled = !formInitialised || formSubmitting;

    // Form intialisation
    const [setOperatingChequePrintOptionOnFormInit, triggerSetOperatingChequePrintOptionOnFormInit] = useState(false);
    const [triggerValidate, setTriggerValidate] = useState(false);
    const [hasInitialisedChequeNumber, setHasInitialisedChequeNumber] = useState(false);
    const [showAddPayToContactForm, setShowAddPayToContactForm] = useState(false);

    const isReadyToInitialiseForm = !areQueriesLoading && !formInitialised;

    if (isReadyToInitialiseForm) {
      const defaultValues = getDefaultValues({ defaultPrintMethod, expenses });
      onInitialiseForm(defaultValues);

      if (defaultValues.chequePrintMethod && defaultValues.chequePrintMethod === PrintManually) {
        if (formInitialised) {
          onOperatingChequePrintOptionsFieldUpdate({ field: 'chequePrintMethod', newValue: PrintManually });
        } else {
          triggerSetOperatingChequePrintOptionOnFormInit(true);
        }
      }
    }

    const { chequeDate, amount, payTo, reference, chequePrintMethod, memo } = formFields;

    const defaultPayToOptions = getDefaultContactTypeaheadOption({ contact: payTo });

    const onUpdateFields = (fieldsToUpdate) => {
      onUpdateFieldsOriginal(fieldsToUpdate);
      validateForm();
    };

    /**
     * Form validation
     */

    function validateForm() {
      // A context is provided to the schema, which provides relevant data that can be used and referenced when validating
      //
      // This is especially useful for certain validation rules that depend on values from both:
      //  * Parent fields
      //  * Sibling fields

      const context = {
        allowChequeNumberDuplication,
        availableOperatingChequeNumber,
        chequeMemoEnabled,
        lastOperatingChequeNumber,
        t,
      };

      onValidateForm(context);
    }

    function onOperatingChequePrintOptionsFieldUpdate({ field, newValue }) {
      const fieldsToUpdate = {
        [field]: newValue,
      };

      // Form can be initialised with the cheque print method set to PrintManually
      // if the firm's default print method setting for operating cheques is
      // PrintManually, in which case we need to fetch the next available number
      // Reference is passed in case they have already filled something out when selecting PrintManually
      if (newValue === PrintManually && field === 'chequePrintMethod') {
        if (!availableOperatingChequeNumber) {
          onGetAvailableOperatingChequeNumber({ chequeNumberFrom: reference?.value });
        } else {
          fieldsToUpdate.reference = availableOperatingChequeNumber;
        }
      } else {
        fieldsToUpdate.reference = undefined;
      }

      onUpdateFields(fieldsToUpdate);
    }

    function onReferenceFieldUpdate({ field, newValue }) {
      const fieldsToUpdate = {
        [field]: newValue,
      };

      // Don't validate on reference change until we fetch the next available
      onUpdateFieldsOriginal(fieldsToUpdate);
    }

    function getChequeNumberDuplicationWarningMessage() {
      // If cheque number duplication is not allowed:
      //  * The form validation will prevent the submission and display the error message
      //  * Therefore, this message is not needed
      if (
        !allowChequeNumberDuplication ||
        chequePrintMethod?.value !== PrintManually ||
        !hasInitialisedChequeNumber ||
        !lastOperatingChequeNumber
      ) {
        return '';
      }

      const chequeLabel = capitalize(t('cheque'));
      const existingChequeNumber =
        availableOperatingChequeNumber?.replace(/^0+/, '') !== reference?.value?.replace(/^0+/, '');

      // If cheque number duplication is allowed:
      //  * We will display the below message as a warning
      return existingChequeNumber
        ? `Warning: ${chequeLabel} reference is already in use. Last ${chequeLabel.toLowerCase()} reference printed was ${lastOperatingChequeNumber}.`
        : '';
    }

    /**
     * Cheque number initialisation - assign the default cheque number
     *
     * The cheque number field is required if Cheque printing method is set to
     * Print Manually. In all other cases the cheque number is set at the time
     * of printing the cheque.
     */
    React.useEffect(() => {
      // Make sure the printing method is set to Print Manually and that we have
      // fetched the next available operating cheque number
      // Only forcefill reference if reference was not filled before and isn't dirty
      // Otherwise we should validate and display an error
      if (!availableOperatingChequeNumber || !!reference?.value || reference?.isDirty || hasInitialisedChequeNumber) {
        return;
      }

      onUpdateFields({ reference: availableOperatingChequeNumber });
      setHasInitialisedChequeNumber(true);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableOperatingChequeNumber]);

    React.useEffect(() => {
      // If form hasn't initialised before we set default, set it here
      if (formInitialised && setOperatingChequePrintOptionOnFormInit) {
        onOperatingChequePrintOptionsFieldUpdate({ field: 'chequePrintMethod', newValue: PrintManually });
        triggerSetOperatingChequePrintOptionOnFormInit(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formInitialised]);

    /**
     * Cheque number validation on user input
     */
    React.useEffect(() => {
      if (chequePrintMethod?.value !== PrintManually || !hasInitialisedChequeNumber) {
        return;
      }

      // Before checking the server for available cheque numbers, ensure the provided cheque number:
      //  1. Is not blank
      //  2. Is numeric
      const isValidChequeNumberToCheck = !!/^[0-9]+$/.test(reference?.value);

      if (isValidChequeNumberToCheck && availableOperatingChequeNumber !== reference?.value) {
        // Need to check server if the new chequeNumber (entered by the user) is available
        onGetAvailableOperatingChequeNumber({ chequeNumberFrom: reference?.value });
      } else {
        setTriggerValidate(!triggerValidate);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reference?.value, hasInitialisedChequeNumber, availableOperatingChequeNumber]);

    React.useEffect(() => {
      if (chequePrintMethod?.value !== PrintManually || !hasInitialisedChequeNumber) {
        return;
      }

      // Changing the form value will trigger immediate validation before we can
      // fetch the new availableOperatingChequeNumber value. Instead we wait until loading
      // is complete. Can't rely on availableOperatingChequeNumber changing because if the
      // user enters any used value, availableOperatingChequeNumber is likely going to stay
      // the same
      if (availableOperatingChequeNumber && isLoadingAvailableOperatingChequeNumber === false) {
        setTriggerValidate(!triggerValidate);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableOperatingChequeNumber, isLoadingAvailableOperatingChequeNumber]);

    React.useEffect(() => {
      if (chequePrintMethod?.value !== PrintManually || !hasInitialisedChequeNumber) {
        return;
      }

      validateForm();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerValidate]);

    // eslint-disable-next-line arrow-body-style
    React.useEffect(() => {
      return () => {
        onClearForm();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function onFormSubmit() {
      try {
        validateForm();

        const isValid = await onSubmitFormWithValidation({
          submitFnP: async (formData) => {
            // Marshal data and send command
            const marshalledData = marshalData({
              formData,
              operatingBankAccountData,
              allowChequeNumberDuplication,
              expenseIds,
              availableOperatingChequeNumber,
            });

            // Save Operating Cheque
            await dispatchCommand({
              type: 'Billing.Accounts.Messages.Commands.CreateOperatingCheque',
              message: marshalledData,
            });
            displaySuccessToUser(`${t('capitalizeAllWords', { val: 'operatingCheque' })} saved successfully`);

            // Open Print Operating Cheque modal if required
            //  * This is only for the "Print Now" option
            const isChequePrintMethodPrintNow = marshalledData.chequePrintMethod === PrintNow;

            if (isChequePrintMethodPrintNow) {
              const operatingChequeId = marshalledData.chequeId;
              setModalDialogVisible({
                modalId: PRINT_OPERATING_CHEQUE_MODAL_ID,
                props: { chequeIds: [operatingChequeId], sbAsyncOperationsService },
              });
            }

            onClearSelectedExpenses();
            onClearForm();
            onModalClose();
          },
        });

        if (!isValid) {
          log.warn('Create operating cheque form validation failed');
        }
      } catch (error) {
        log.error('Failed to create operating cheque', error);
        displayErrorToUser(`Failed to save ${t('operatingCheque')}. Please check your connection and try again.`);
      }
    }

    return {
      // form fields
      chequeDate,
      amount,
      payTo,
      reference,
      chequePrintMethod,
      memo,
      // end form fields
      isLoading: areQueriesLoading || !formInitialised,
      showAddPayToContactForm,
      setShowAddPayToContactForm,
      defaultPayToOptions,
      allowChequeNumberDuplication,
      createOperatingChequeForm,
      showChequeMemo: chequeMemoEnabled,
      formData: formValues,
      formErrors: formFields, // Contains data related to form errors
      formInitialised,
      formSubmitting,
      submitFailed,
      isFormDisabled,
      region,
      chequeNumberDuplicationWarningMessage: getChequeNumberDuplicationWarningMessage(),
      // callbacks
      onSelectContact: (contact) => {
        onUpdateFields({ payTo: contact });
      },
      onUpdateFields,
      onOperatingChequePrintOptionsFieldUpdate,
      onReferenceFieldUpdate,
      onFormSubmit,
    };
  },
});

function getDefaultValues({ defaultPrintMethod, expenses }) {
  return {
    chequeDate: new Date(),
    payTo: undefined,
    memo: undefined,
    chequePrintMethod: defaultPrintMethod,
    reference: undefined,
    amount: expenses.reduce((acc, expense) => acc + getIncludingTaxAmount(expense), 0),
  };
}

/**
 * Provides the relevant field's ContactTypeahead with:
 *  1. A default option or
 *  2. The last contact selected (to preserve data until cleared or marshalling data)
 *
 * This is required for the "Supplier" and "Pay to" fields
 *
 * @param {Object} params
 * @param {Object} params.contact
 * @param {Object} params.contact.displayName
 * @param {Object} params.contact.id
 * @returns {Array<Object>}
 */
function getDefaultContactTypeaheadOption({ contact }) {
  // If id is missing:
  //  * No contact was linked upon form initialisation or the
  //  * Contact was cleared by user
  if (contact?.id?.value) {
    return [
      {
        data: contact,
        label: contact?.displayName?.value,
        value: contact?.id?.value,
      },
    ];
  }

  return [];
}

function marshalData({
  formData,
  operatingBankAccountData,
  allowChequeNumberDuplication,
  expenseIds,
  availableOperatingChequeNumber,
}) {
  // build create operating cheque message
  const operatingChequeId = uuid();
  const operatingBankAccountId = operatingBankAccountData?.id;
  const isPrintManually = formData.chequePrintMethod === PrintManually;
  const operatingCheque = {
    chequeId: operatingChequeId,
    bankAccountId: operatingBankAccountId,
    chequeDate: moment(formData.chequeDate).format('YYYYMMDD'),
    expenseIds,
    isManual: isPrintManually,
    chequeNumber: isPrintManually ? formData.reference || availableOperatingChequeNumber : undefined,
    chequeMemo: formData.memo,
    payToId: formData.payTo?.id,
    allowChequeNumberDuplication,
    chequePrintMethod: formData.chequePrintMethod,
  };

  return operatingCheque;
}

export const CreateOperatingChequeModalFormsContainer = composeHooks(hooks)(CreateOperatingChequeModal);

CreateOperatingChequeModalFormsContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  expenses: PropTypes.array,
  expenseIds: PropTypes.array,
  availableOperatingChequeNumber: PropTypes.string,
  isLoadingAvailableOperatingChequeNumber: PropTypes.bool,
  operatingBankAccountData: PropTypes.object,
  operatingChequePrintSettings: PropTypes.object,
  expenseDataLoading: PropTypes.bool,
  sbAsyncOperationsService: PropTypes.object.isRequired,
  // Callbacks
  onClearSelectedExpenses: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onGetAvailableOperatingChequeNumber: PropTypes.func.isRequired,
};

CreateOperatingChequeModalFormsContainer.defaultProps = {
  expenses: [],
  expenseIds: [],
  availableOperatingChequeNumber: undefined,
  isLoadingAvailableOperatingChequeNumber: undefined,
  operatingBankAccountData: undefined,
  operatingChequePrintSettings: undefined,
  expenseDataLoading: undefined,
};

CreateOperatingChequeModalFormsContainer.displayName = 'CreateOperatingChequeModalFormsContainer';
