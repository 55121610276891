import { gql } from '@apollo/client';
import { apolloEntityOpdateEventTypes } from '../subscriptions';
import { getApolloClient } from '../../client';

export const onSetActivityBillingFee = ({ commandMessage }) => {
  const { id, feeId } = commandMessage;

  const optimisticActivity = {
    __typename: 'Activity',
    id,
    billingFeeId: feeId,
  };

  const apolloClient = getApolloClient();
  const activityIdentifier = apolloClient.cache.identify(optimisticActivity);

  apolloClient.writeFragment({
    id: activityIdentifier,
    fragment: gql`
      fragment OpdateActivity on Activity {
        ${Object.keys(optimisticActivity).join('\n')}
      }
    `,
    data: {
      ...optimisticActivity,
    },
  });

  return {
    eventType: apolloEntityOpdateEventTypes.ENTITY_UPDATED,
    typeName: 'Activity',
    optimisticEntity: optimisticActivity,
  };
};
