import { useMemo } from 'react';
import { todayAsInteger } from '@sb-itops/date';
import { useSort } from '@sb-itops/redux/sort/use-sort';
import { status as invoiceStatusesMap } from '@sb-billing/business-logic/invoice/entities';
import { billingEventPseudoType } from '@sb-billing/business-logic/shared/entities';
import { usePagination, useSubscribedQuery } from 'web/hooks';
import { ContactInvoiceTableData, InvoiceTableStatusesCountsData, InvoiceTableData } from 'web/graphql/queries';
import { sentViaTypes } from '@sb-billing/business-logic/correspondence-history';
import {
  billingType,
  billingFrequencySubType as billingFrequencySubTypeMap,
} from '@sb-billing/business-logic/matters/billing-config';
import { byName as BALANCE_TYPE_BY_NAME } from '@sb-billing/business-logic/bank-account-settings/entities/constants';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { featureActive } from '@sb-itops/feature';

// The invoice table data is used in multiple places: Firm Invoices, Contact
// Invoices, Matter Invoices and Advanced Search Results Invoices. Instead of
// duplicating the same logic in those containers, it's consolidated in this hook

export function useInvoiceTableData({
  scopePagination,
  scopeSorting,
  fetchLimit,
  showStatement = false,
  filters = {},
  bankBalanceType,
  skipQuery,
  searchTerms,
}) {
  // Pagination
  const {
    currentPage: invoicesCurrentPage,
    setPageNumber,
    getPagination,
  } = usePagination({
    scope: scopePagination,
    fetchLimit,
  });

  const invoicesOnPageChange = ({ selected: pageNumber }) => setPageNumber(pageNumber);

  // Sorting
  const { sortBy, setSortBy, sortDirection, setSortDirection } = useSort({
    scope: scopeSorting,
    initialSortBy: 'invoiceNumber',
    initialSortDirection: 'DESC',
  });

  const onSort = (sortProps) => {
    setSortBy(sortProps.sortBy);
    setSortDirection(sortProps.sortDirection);
  };

  // Get query variables from filter
  const invoiceListFilter = useMemo(
    () => convertFiltersToQueryVariables({ filters, bankBalanceType }),
    [filters, bankBalanceType],
  );

  // Query 1: Invoices
  const invoicesQueryResult = useSubscribedQuery(
    featureActive('BB-9790') && filters.contactId ? ContactInvoiceTableData : InvoiceTableData,
    {
      context: { skipRequestBatching: true },
      variables: {
        invoiceListFilter,
        offset: invoicesCurrentPage * fetchLimit,
        limit: fetchLimit,
        sort: !sortBy ? undefined : { fieldNames: [sortBy], directions: [`${sortDirection || 'ASC'}`.toUpperCase()] },
        showStatement,
        // "includeMatterUtbmsSettings" is different from "variables.matterIsUtbmsEnabled"
        //  1. "includeMatterUtbmsSettings" will include MatterBillingConfigurations to the query results (but won't apply any filtering)
        //    * This is required when UTBMS facet is enabled because the "LEDES 1998B" download action is available
        //    * When the user selects invoices, we need knowledge on whether the invoice's matter is UTBMS enabled or not
        //    * UPDATE: this value needs to always be set to true due to current Apollo cache limitations
        //      * Subsequent queries can clear the cache of needed data
        //      * E.g. The matter invoices page has a separate query that fetches the matter's billing configuration (MBC). However, in cases when the subsequent InvoiceTableData query does not include an invoice's MBC in the query results (thus resulting in a null value), it will cause the normalised cache to have a null MBC. This is not desired behaviour if the prior query was expecting the MBC entity data (which is now null).
        //      * A long term solution is being sought
        //  2. "variables.matterIsUtbmsEnabled" will filter the results
        includeMatterUtbmsSettings: true,
        paymentPlanStatusAsOfDate: todayAsInteger(),
        searchTerms,
      },
      skip: skipQuery,
    },
  );

  // Query 2: Invoices total & statuses counts
  // This query fetches the:
  //  1. Total invoices count
  //  2. Invoices count for each invoice status (the below note is relevant to this)
  //
  // A separate query is needed because the query that fetches the invoice list data can filter its results by invoice status
  //  * Whereas this query will always query for all invoice statuses
  //  * This is so the UI will persistently display the relevant count for each status when required
  const invoicesStatusQueryResult = useSubscribedQuery(InvoiceTableStatusesCountsData, {
    context: { skipRequestBatching: true },
    variables: {
      invoiceListFilter: {
        ...invoiceListFilter,
        invoiceStatuses: ['DRAFT', 'FINAL', 'PAID', 'VOID', 'OVERDUE'],
      },
    },
    skip: skipQuery || searchTerms?.length, // Adv. search page invoice list does not require this query
  });

  if (invoicesQueryResult.error) {
    throw new Error(invoicesQueryResult.error);
  }

  if (invoicesStatusQueryResult.error) {
    throw new Error(invoicesStatusQueryResult.error);
  }

  // Invoice list data
  // 1. Invoices
  const { data: invoiceData } = invoicesQueryResult;
  const invoiceListResults = useMemo(
    () =>
      (invoiceData?.invoiceList?.results || []).map((invoice) => {
        // Zero Out Unpaid values on Voided Invoice (BB-9142)
        if (invoice.pseudoStatus === invoiceStatusesMap.VOID && invoice?.totals) {
          const updatedTotalsInvoice = {
            ...invoice,
            totals: {
              ...invoice.totals,
              unpaid: 0,
              unpaidExcInterest: 0,
            },
          };

          return updatedTotalsInvoice;
        }

        return invoice;
      }),
    [invoiceData?.invoiceList?.results],
  );
  const invoiceListTotalCount = invoiceData?.invoiceList?.totalCount;
  // 2. Invoices status counts
  const { data: invoiceStatusesCountData } = invoicesStatusQueryResult;
  const invoiceListTotalStatusCounts = invoiceStatusesCountData?.invoiceList?.totalStatusCounts;

  // TODO: Remove this as part of BB-14243
  const invoiceStatusCounts = { ...(invoiceListTotalStatusCounts || {}) };
  // eslint-disable-next-line no-underscore-dangle
  delete invoiceStatusCounts.__typename;

  const {
    totalCount: invoiceCount,
    hidePagination,
    totalNumberOfPages: invoicesTotalNumberOfPages,
  } = getPagination({ totalCount: invoiceListTotalCount, loading: invoicesQueryResult.loading });

  return {
    invoicesCurrentPage,
    invoicesOnPageChange,
    invoicesOnSort: onSort,
    invoicesSortBy: sortBy,
    invoicesSortDirection: sortDirection,
    invoices: invoiceListResults,
    invoiceCount,
    invoiceDataLoading: invoiceListResults.length === 0 && invoicesQueryResult.loading,
    invoiceStatusCounts,
    invoicesTotalNumberOfPages,
    isPaginationHidden: hidePagination,
  };
}

function convertFiltersToQueryVariables({ filters, bankBalanceType }) {
  // Not every filter/variable is relevant to all invoice lists
  //  * E.g. Certain filters are used on the Firm invoices list, but not on the Contact invoices list
  const variables = {};

  // Recently viewed filter
  if (filters.recent) {
    variables.showUserRecentInvoice = filters.recent;
  }
  // Invoice statuses filter
  variables.invoiceStatuses = filters.invoiceStatuses ?? ['DRAFT', 'FINAL', 'PAID', 'VOID', 'OVERDUE']; // If no filter option is available, we default to sending the entire list of statuses (instead of undefined), otherwise the query won't return any results
  variables.invoiceStatusOverduePivotDate = todayAsInteger();

  // Issue date filter
  variables.issuedDate = convertDateFilterToQueryVariable({ filter: filters.issueDate });

  // Sent statuses filter
  variables.sentStatuses = filters.sentStatuses
    ? convertSentStatusesFilterToQueryVariable({ sentStatuses: filters.sentStatuses })
    : undefined;

  // Send preferences filter
  variables.sendPreferences = convertSendPreferencesFilterToQueryVariable({ sendPreferences: filters.sendPreferences });

  // Online invoice filter
  if (filters.billingEvents?.includes(billingEventPseudoType.INVOICE_VIEWED_ONLINE)) {
    variables.wasViewedOnline = true;
  }

  // Billing type filter
  if (filters.billingTypes && !filters.billingTypes.allSelected) {
    variables.matterBillingTypes = convertBillingTypesFilterToQueryVariable({
      billingTypes: filters.billingTypes.selections,
    });
  }

  // Billing frequency filter
  if (filters.billingFrequencySubTypes?.length) {
    variables.matterBillingFrequencySubTypes = convertBillingFrequencySubTypesFilterToQueryVariable({
      billingFrequencySubTypes: filters.billingFrequencySubTypes,
    });
  }

  // Matter type filter
  if (filters.matterTypes?.length) {
    variables.matterTypeIds = filters.matterTypes;
  }

  // Matter statuses filter
  variables.matterStatuses = convertMatterStatusesFilterToQueryVariable({ matterStatuses: filters.matterStatuses });

  // Attorney responsible filter
  if (filters.attorneysResponsible?.length) {
    variables.matterAttorneyResponsibleIds = filters.attorneysResponsible;
  }

  // Matter balances filter
  if (bankBalanceType === BALANCE_TYPE_BY_NAME.Matter) {
    variables.matterTrustBalance = convertBalanceToNumberRange({ balance: filters.minimumTrustBalance });
  }

  // Matter debtor balances filter
  if (bankBalanceType === BALANCE_TYPE_BY_NAME.MatterContact) {
    variables.matterDebtorTrustBalance = convertBalanceToNumberRange({
      balance: filters.minimumTrustBalance,
    });
    variables.matterDebtorOperatingBalance = convertBalanceToNumberRange({
      balance: filters.minimumOperatingBalance,
    });
  }

  if (filters.contactId) {
    variables.debtorIds = [filters.contactId];
  }

  // Finalised on filter
  variables.finalizedDate = convertDateFilterToQueryVariable({ filter: filters.finalizedOn });

  if (filters.matterId) {
    variables.matterIds = [filters.matterId];
  }

  if (filters.utbmsEnabled) {
    variables.matterIsUtbmsEnabled = filters.utbmsEnabled;
  }

  return variables;
}

/**
 * Generic filter helper functions
 */

function convertDateFilterToQueryVariable({ filter }) {
  if (!filter || (!filter.startDate && !filter.endDate)) {
    return undefined;
  }

  // Strictly returning undefined below because the "Before" filter
  // puts 0 for the startDate
  return {
    from: filter.startDate || undefined,
    to: filter.endDate || undefined,
  };
}

// When a filter has multiple options/toggles to filter by:
//  1. If the filter value is not set, treat the filter as if all options are selected
//  2. If all are selected:
//    * Return "undefined" so that we don't restrict results, in order to avoid extra work for the DB
//  3. If filters are selected:
//    * Send them as normal, so the DB will filter accordingly
function sendSelectedFiltersUnlessAllAreSelected({ reduxToQueryVariablesMap, filters }) {
  if (!filters) {
    return undefined;
  }

  const filterSet = new Set(filters);
  const allFiltersSelected = Object.keys(reduxToQueryVariablesMap).every((filter) => filterSet.has(filter));

  if (allFiltersSelected) {
    return undefined;
  }

  return (filters || []).map((status) => reduxToQueryVariablesMap[status]);
}

function convertBalanceToNumberRange({ balance }) {
  const isValidInteger = Number.isInteger(balance);
  const numberRange = {
    from: balance,
    to: undefined, // "to" field not required for invoice filters
  };

  return isValidInteger ? numberRange : undefined;
}

/**
 * Filter specific functions
 */

// Sent statuses filter
function convertSentStatusesFilterToQueryVariable({ sentStatuses }) {
  const sendStatusesReduxToQueryVariableMap = {
    notSent: 'NOT_SENT',
    sent: 'SUCCESS',
    failed: 'ERROR',
    inProgress: 'IN_PROGRESS',
  };

  return sendSelectedFiltersUnlessAllAreSelected({
    reduxToQueryVariablesMap: sendStatusesReduxToQueryVariableMap,
    filters: [...sentStatuses, 'inProgress'], // Always apply inProgress to match existing functionality
  });
}

// Send preferences filter
function convertSendPreferencesFilterToQueryVariable({ sendPreferences }) {
  const reduxSendPreferencesToQueryVariableMap = {
    [sentViaTypes.EMAIL]: 'EMAIL',
    [sentViaTypes.COMMUNICATE]: 'COMMUNICATE',
    [sentViaTypes.MAIL]: 'MAIL',
    [sentViaTypes.OTHER]: 'OTHER',
  };

  if (hasFacet(facets.eBillingSendingPreference)) {
    reduxSendPreferencesToQueryVariableMap[sentViaTypes.E_BILLING] = 'E_BILLING';
  }

  return sendSelectedFiltersUnlessAllAreSelected({
    reduxToQueryVariablesMap: reduxSendPreferencesToQueryVariableMap,
    filters: sendPreferences,
  });
}

// Billing types filter
function convertBillingTypesFilterToQueryVariable({ billingTypes }) {
  const reduxFilterToBillingTypesMap = {
    contingency: [billingType.CONTINGENCY_$, billingType.CONTINGENCY_P],
    fixed: [billingType.FIXED_FEE, billingType.FIXED_FEE_PER_APPEARANCE],
    time: [billingType.TIME_BASED],
  };

  const result = sendSelectedFiltersUnlessAllAreSelected({
    reduxToQueryVariablesMap: reduxFilterToBillingTypesMap,
    filters: billingTypes,
  });

  return result && Array.isArray(result) ? result.flat() : [];
}

// Matter statuses filter
function convertMatterStatusesFilterToQueryVariable({ matterStatuses }) {
  const reduxMatterStatusesFilterToQueryVariablesMap = {
    Open: 'open',
    Pending: 'pending',
    Closed: 'closed',
    Cancelled: 'cancelled',
    Deleted: 'deleted',
  };

  return sendSelectedFiltersUnlessAllAreSelected({
    reduxToQueryVariablesMap: reduxMatterStatusesFilterToQueryVariablesMap,
    filters: matterStatuses,
  });
}

// Billing frequency filter
function convertBillingFrequencySubTypesFilterToQueryVariable({ billingFrequencySubTypes }) {
  const frequencySubTypes = billingFrequencySubTypes.filter((subType) => subType !== undefined);

  const noneSelected = frequencySubTypes.length === 0;
  const allSelected = Object.values(billingFrequencySubTypeMap).length === frequencySubTypes.length;

  if (noneSelected || allSelected) {
    return undefined;
  }

  return frequencySubTypes;
}
