/* eslint-disable no-console */
import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { regionType } from '@sb-itops/region';
import { featureActive } from '@sb-itops/feature';
import {
  Button,
  CurrencyDisplay,
  CurrencyInput2,
  DatePicker,
  DurationPicker,
  DurationTypePicker,
  EditableTypeahead,
  Icon,
  LinkableText,
  SlidingToggle,
  TimeConversionInput,
  useTranslation,
  FormLabel,
} from '@sb-itops/react';
import { ActivityDropdown, CheckboxListPopup, StaffDropdown, TaskDropdown } from '@sb-billing/react';
import { MatterTypeahead2 } from '@sb-matter-management/react';
import {
  activityCategoryLabels,
  activityCategories,
  taskCategoryLabels,
  taskCategories,
} from '@sb-billing/business-logic/activities/entities/constants';
import {
  durationType as durationTypeEnum,
  entryType as entryTypeEnum,
} from '@sb-billing/business-logic/shared/entities';
import { displayModes } from '@sb-itops/react/duration-picker/DurationPicker';
import { DebugEntity } from 'web/react-redux/components/debug-entity';

import { FeeSourceItemsEntries } from '../fee-source-items-entries';

import Styles from './FeeModal.module.scss';

// Depending on the tax facet, the amount field can display on a different row
const AmountField = ({ amountExclTaxInCents, isFormDisabled, isFormSubmitting, region }) => (
  <div
    className={classNames(
      Styles.feeAmountContainer,
      isFormDisabled && Styles.formDisabled,
      isFormSubmitting && Styles.submitting,
    )}
  >
    <label>Amount</label>
    <CurrencyDisplay name="amount" amount={amountExclTaxInCents} region={region} hideDollar formatAmount />
  </div>
);

export const FeeModalBody = (props) => {
  const {
    activities,
    descriptionFieldMaxLength,
    durationFieldInputDisabled,
    durationFieldTypeDisabled,
    formData,
    formDataMappedObjects,
    formErrors,
    hasDurationTypeChanged,
    initialValues,
    invoiceNumber,
    isAutoTimeFee,
    isFormDisabled,
    isFormSubmitting,
    isNewFee,
    isOnFinalisedInvoice,
    matterBillableMinutes,
    matterSummaries,
    matterSummariesDataLoading,
    matterSummariesHasMore,
    onDeleteFee,
    onHandleTaxOptionsBlur,
    onShowTaxOptionsToggle,
    region,
    setHasDurationTypeChanged,
    setIsSubjectOverridable,
    showTaskField,
    showTaxField,
    showTaxOptions,
    staffMemberOptions,
    subjects,
    taskFieldEnabled,
    tasks,
    taxOptions,
    writeOffFieldEnabled,
    // Callbacks
    onDurationBilledFieldBlur,
    onDurationWorkedFieldBlur,
    onFetchMatterSummaries,
    onFetchMoreMatterSummaries,
    onNavigateToInvoice,
    onUpdateActivityField,
    onUpdateBillableField,
    onUpdateDurationFieldType,
    onUpdateDurationBilledFieldValue,
    onUpdateDurationWorkedFieldValue,
    onUpdateField,
    onUpdateMatterField,
    onUpdateSourceItemsField,
    onUpdateStaffField,
    onUpdateTaskField,
    onUpdateTaxField,
  } = props;
  // Form data
  const {
    amountExclTaxInCents,
    description,
    durationBilled,
    durationWorked,
    durationType,
    feeDate,
    staffId,
    isBillable,
    isWriteOff,
    matterId,
    rateInCents,
    sourceItems,
    subject,
    taxAmountInCents,
    timeBilledInHoursAndMinutes,
    timeWorkedInHoursAndMinutes,
  } = formData;
  // Form data mapped objects
  const { activity, subjectActivity, task } = formDataMappedObjects;

  const { t } = useTranslation();

  return (
    <div className={Styles.feeModalFormContainer}>
      <DebugEntity entityCacheName="sbFeeService" entityId={formData.id} />

      <form
        name="feeLongEntryForm"
        className={classNames('main-form', Styles.feeModalForm, isOnFinalisedInvoice && Styles.multiGridRows)}
        autoComplete="off"
      >
        {isOnFinalisedInvoice && invoiceNumber && (
          <div className={Styles.finalisedInvoiceNotice}>
            <Button onClick={onNavigateToInvoice}>
              ON {t('finalised').toUpperCase()} INVOICE #{invoiceNumber}
            </Button>
            <i className="fa fa-lock" />
          </div>
        )}
        {/* Fee fields */}
        <fieldset disabled={isFormDisabled}>
          <div className={classNames(Styles.feeInputs, isOnFinalisedInvoice && Styles.secondGridRow)}>
            <div className={Styles.row}>
              {/* Date */}
              <div className={Styles.feeDateContainer}>
                <label>Date</label>
                <DatePicker
                  disabled={isFormDisabled}
                  hasError={formErrors?.feeDate?.isInvalid}
                  onSelect={(date) => onUpdateField({ field: 'feeDate', newValue: date })}
                  value={feeDate}
                />
              </div>
              {/* Staff */}
              <div className={classNames(Styles.feeStaffContainer, isFormDisabled && Styles.disabled)}>
                <label>Staff</label>
                <StaffDropdown
                  disabled={isFormDisabled}
                  hasError={formErrors?.staffId?.isInvalid}
                  selectedStaffId={staffId}
                  staffMemberOptions={staffMemberOptions}
                  onSelectionChange={(selectedStaff) => onUpdateStaffField({ selectedStaff })}
                />
              </div>
            </div>
            <div className={Styles.row}>
              {/* Matter */}
              <div className={classNames(Styles.feeMatterContainer, isFormDisabled && Styles.disabled)}>
                <label>Matter</label>
                <MatterTypeahead2
                  disabled={isFormDisabled}
                  hasError={formErrors?.matterId?.isInvalid}
                  isLoading={matterSummariesDataLoading}
                  matters={matterSummaries}
                  maxMenuHeight={250}
                  placeholder="Select a matter..."
                  selectedMatterId={matterId}
                  defaultMatters={initialValues.defaultMatterSummaries}
                  actionList={
                    matterSummariesHasMore === true
                      ? [
                          {
                            displayComponent: (
                              <span>
                                <i className="fa fa-plus" /> &emsp;Show more results
                              </span>
                            ),
                            callback: () => {
                              if (matterSummariesDataLoading) {
                                return;
                              }

                              onFetchMoreMatterSummaries();
                            },
                          },
                        ]
                      : []
                  }
                  onInputChange={onFetchMatterSummaries}
                  onLoadMore={onFetchMoreMatterSummaries}
                  onMatterSelected={(matter) => onUpdateMatterField({ newMatter: matter?.data })}
                />
              </div>
            </div>
            <div className={Styles.row}>
              {/* Activity */}
              {!isAutoTimeFee && (
                <div className={classNames(Styles.feeActivityContainer, isFormDisabled && Styles.disabled)}>
                  <label>Activity</label>
                  <ActivityDropdown
                    activities={activities}
                    disabled={isFormDisabled}
                    hasError={formErrors?.activityId?.isInvalid}
                    hideSelectedOptionDescription
                    noDefaultStyling
                    groupLabelHeight={26}
                    optionLabelHeight={26}
                    placeholder=""
                    selectedActivityCode={activity?.code}
                    onSelectionChange={(selectedActivity) => onUpdateActivityField({ selectedActivity })}
                    selectClassName={Styles.selectContainerStyling}
                  />
                </div>
              )}
              {/* Task */}
              {showTaskField && (
                <div className={classNames(Styles.feeTaskContainer, isFormDisabled && Styles.disabled)}>
                  <label>Task</label>
                  <TaskDropdown
                    disabled={!taskFieldEnabled || isFormDisabled}
                    hasError={formErrors?.taskId?.isInvalid}
                    groupLabelHeight={26}
                    optionLabelHeight={26}
                    placeholder=""
                    selectedTaskCode={task?.code}
                    tasks={tasks}
                    onSelectionChange={(selectedTask) => onUpdateTaskField({ selectedTask })}
                    selectClassName={Styles.selectContainerStyling}
                  />
                </div>
              )}
              {/* Subject */}
              <div className={Styles.feeSubjectContainer}>
                <label>Subject</label>
                <EditableTypeahead
                  id="fee-modal-subject"
                  labelKey="description"
                  disabled={isFormDisabled}
                  hasError={formErrors?.subject?.isInvalid}
                  options={subjects}
                  inputValue={subject}
                  selectedOption={subjectActivity}
                  onValueChange={(newValue) => {
                    onUpdateField({
                      field: 'subject',
                      newValue,
                    });
                    // We don't overwrite if the user has modified the subject line AND the subject line is not blank
                    setIsSubjectOverridable(newValue.trim() === '');
                  }}
                  onOptionSelected={(selectedActivity) => {
                    onUpdateActivityField({ selectedActivity, isSubjectTypeaheadSelection: true });
                  }}
                />
              </div>
            </div>
            <div className={Styles.row}>
              {/* Duration */}
              {!featureActive('BB-13563') && (
                <div className={Styles.feeDurationContainer}>
                  <label>Duration</label>
                  <DurationPicker
                    className={Styles.feeDuration}
                    displayMode={displayModes.STANDARD}
                    duration={durationType === durationTypeEnum.FIXED ? 'N/A' : durationBilled || ''}
                    durationDisabled={durationFieldInputDisabled || isFormDisabled}
                    durationHasError={formErrors?.durationBilled?.isInvalid}
                    durationType={durationType}
                    durationTypeDisabled={durationFieldTypeDisabled || isFormDisabled}
                    onDurationBlur={onDurationBilledFieldBlur}
                    onDurationChanged={(newDurationValue) => onUpdateDurationBilledFieldValue({ newDurationValue })}
                    onDurationTypeChanged={(selectedDurationType) =>
                      onUpdateDurationFieldType({ selectedDurationType })
                    }
                  />
                </div>
              )}
              {featureActive('BB-13563') && (
                <div className={Styles.feeDurationContainer}>
                  <label>Duration Type</label>
                  <DurationTypePicker
                    className={Styles.feeDuration}
                    allowFixed={!isAutoTimeFee}
                    displayMode={displayModes.STANDARD}
                    durationType={durationType}
                    durationTypeDisabled={isFormDisabled}
                    onDurationTypeChanged={(selectedDurationType) => {
                      onUpdateDurationFieldType({ selectedDurationType });
                    }}
                  />
                </div>
              )}
              {/* Time */}
              {!featureActive('BB-13563') && featureActive('BB-5345') && (
                <div
                  className={classNames(
                    Styles.feeTimeContainer,
                    isFormDisabled && Styles.formDisabled,
                    isFormSubmitting && Styles.submitting,
                  )}
                >
                  <label>Time</label>
                  <input
                    className="form-control"
                    type="text"
                    value={durationType === durationTypeEnum.FIXED ? 'N/A' : timeBilledInHoursAndMinutes}
                    disabled
                  />
                </div>
              )}
              {featureActive('BB-13563') && featureActive('BB-5345') && (
                <div
                  className={classNames(
                    Styles.feeTimeContainer,
                    isFormDisabled && Styles.formDisabled,
                    isFormSubmitting && Styles.submitting,
                  )}
                >
                  <FormLabel
                    label="Working"
                    explainerText="Can be used to track the actual working time for this staff member, if different to the time you wish to bill"
                    optional
                    className={Styles.smallerMarginBottom}
                  />
                  <TimeConversionInput
                    className="form-control"
                    convertedValue={durationType === durationTypeEnum.FIXED ? 'N/A' : timeWorkedInHoursAndMinutes}
                    duration={durationType === durationTypeEnum.FIXED ? 'N/A' : durationWorked || ''}
                    durationType={durationType}
                    durationDisabled={durationFieldInputDisabled || isFormDisabled}
                    durationHasError={formErrors?.durationWorked?.isInvalid}
                    onDurationBlur={onDurationWorkedFieldBlur}
                    onDurationChanged={(newDurationValue) => onUpdateDurationWorkedFieldValue({ newDurationValue })}
                    disabled
                    showHrs
                  />
                </div>
              )}
              {featureActive('BB-13563') && featureActive('BB-5345') && (
                <div
                  className={classNames(
                    Styles.feeTimeContainer,
                    isFormDisabled && Styles.formDisabled,
                    isFormSubmitting && Styles.submitting,
                  )}
                >
                  <FormLabel
                    label="Billing"
                    explainerText="Can be changed, independently of working time, to vary the time you wish to bill"
                    optional
                    className={Styles.smallerMarginBottom}
                  />
                  <TimeConversionInput
                    className="form-control"
                    convertedValue={durationType === durationTypeEnum.FIXED ? 'N/A' : timeBilledInHoursAndMinutes}
                    duration={durationType === durationTypeEnum.FIXED ? 'N/A' : durationBilled || ''}
                    durationType={durationType}
                    durationDisabled={durationFieldInputDisabled || isFormDisabled}
                    durationHasError={formErrors?.durationBilled?.isInvalid}
                    onDurationBlur={onDurationBilledFieldBlur}
                    onDurationChanged={(newDurationValue) => onUpdateDurationBilledFieldValue({ newDurationValue })}
                    disabled
                    showHrs
                  />
                </div>
              )}
              {/* Rate */}
              <div className={Styles.feeRateContainer}>
                <label>Rate</label>
                <CurrencyInput2
                  disabled={isFormDisabled}
                  hasError={formErrors?.rateInCents?.isInvalid}
                  name="rate"
                  value={rateInCents}
                  onChange={(e) =>
                    onUpdateField({
                      field: 'rateInCents',
                      newValue: e.target.value || 0,
                    })
                  }
                />
              </div>
              {/* Amount */}
              {(!showTaxField || featureActive('BB-13563')) && (
                <AmountField
                  amountExclTaxInCents={amountExclTaxInCents}
                  isFormDisabled={isFormDisabled}
                  isFormSubmitting={isFormSubmitting}
                  region={region}
                />
              )}
            </div>
            {showTaxField && (
              <div className={Styles.row}>
                {/* Amount */}
                {!featureActive('BB-13563') && (
                  <AmountField
                    amountExclTaxInCents={amountExclTaxInCents}
                    isFormDisabled={isFormDisabled}
                    isFormSubmitting={isFormSubmitting}
                    region={region}
                  />
                )}

                {/* Tax */}
                <div
                  className={classNames(
                    Styles.feeTaxContainer,
                    isFormDisabled && Styles.formDisabled,
                    isFormSubmitting && Styles.submitting,
                  )}
                >
                  <label>{t('tax')}</label>
                  <div className={Styles.feeTax}>
                    <CurrencyDisplay name="tax" amount={taxAmountInCents} region={region} hideDollar formatAmount />
                    <div className={Styles.taxOptions} id="fee-modal-form-tax-option-dropdown-group">
                      <Icon
                        type="ellipsis-v"
                        className={classNames(Styles.taxOptionsIcon, isFormDisabled && Styles.disabled)}
                        onClick={isFormDisabled ? () => {} : onShowTaxOptionsToggle}
                        isRelatable
                      />
                      {showTaxOptions && (
                        <CheckboxListPopup
                          list={taxOptions}
                          onOptionSelected={onUpdateTaxField}
                          onBlur={onHandleTaxOptionsBlur}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {featureActive('BB-13563') && (
                  <div
                    className={classNames(
                      Styles.feeAmountContainer,
                      isFormDisabled && Styles.formDisabled,
                      isFormSubmitting && Styles.submitting,
                    )}
                  >
                    <label>Total</label>
                    <CurrencyDisplay
                      name="total"
                      amount={amountExclTaxInCents + taxAmountInCents}
                      region={region}
                      hideDollar
                      formatAmount
                    />
                  </div>
                )}
              </div>
            )}
            <div className={Styles.row}>
              {/* Billable */}
              <div className={Styles.feeBillableContainer}>
                <label>Billable</label>
                <SlidingToggle
                  disabled={isFormDisabled}
                  id="isFeeBillableToggle"
                  name="isFeeBillableToggle"
                  scope="fee-modal"
                  onChange={(id, newValue) => onUpdateBillableField({ newValue })}
                  selected={isBillable}
                />
              </div>
              {/* Write off */}
              <div className={Styles.feeWriteOffContainer}>
                <label>Write off</label>
                <SlidingToggle
                  disabled={!writeOffFieldEnabled || isFormDisabled}
                  id="isFeeWriteOffToggle"
                  name="isFeeWriteOffToggle"
                  scope="fee-modal"
                  onChange={(id, newValue) => onUpdateField({ field: 'isWriteOff', newValue })}
                  selected={isWriteOff}
                />
              </div>
            </div>
            <div className={Styles.row}>
              {/* Description */}
              {/* Autotime */}
              {isAutoTimeFee && (
                <FeeSourceItemsEntries
                  hasDurationTypeChanged={hasDurationTypeChanged}
                  setHasDurationTypeChanged={setHasDurationTypeChanged}
                  durationType={durationType}
                  feeDurationBilled={durationBilled}
                  feeDurationWorked={durationWorked}
                  initialValue={initialValues.sourceItems}
                  interval={matterBillableMinutes}
                  isDisabled={isFormDisabled}
                  sourceItems={sourceItems}
                  onDeleteFee={onDeleteFee}
                  onSourceItemsChange={onUpdateSourceItemsField}
                />
              )}
              {/* Non autotime */}
              {!isAutoTimeFee && (
                <div className={Styles.feeDescriptionContainer}>
                  <label>Description</label>
                  <textarea
                    className="form-control"
                    disabled={isFormDisabled}
                    onChange={(e) => onUpdateField({ field: 'description', newValue: e.target.value })}
                    rows="5"
                    value={description || ''}
                    maxLength={descriptionFieldMaxLength}
                  />
                </div>
              )}
            </div>

            {!isNewFee && !isOnFinalisedInvoice && (
              <div className={Styles.row}>
                {/* Delete fee */}
                <div className={Styles.deleteFeeLinkContainer}>
                  <LinkableText text="Delete this fee" onClickLink={onDeleteFee} asLink disabled={isFormDisabled} />
                </div>
              </div>
            )}
          </div>
        </fieldset>
        {/* Preview */}
        <div
          className={classNames(
            Styles.feePreviewContainer,
            isFormDisabled && Styles.disabled,
            isOnFinalisedInvoice && Styles.multiGridRows,
          )}
        >
          <label>Preview</label>
          <p>{subject}</p>
          {isAutoTimeFee ? (
            sourceItems?.map(
              ({ description: sourceItemDescription, durationBilled: sourceItemDuration, billable }, index) => {
                const durationInHours = +(sourceItemDuration / 60).toFixed(5);

                return (
                  <p key={index}>
                    - {sourceItemDescription} ({durationInHours.toFixed(2)}hrs{billable ? '' : ' - Non-Billable'})
                  </p>
                );
              },
            )
          ) : (
            <p>{description}</p>
          )}
        </div>
      </form>
    </div>
  );
};

const MatterSummaryType = PropTypes.shape({
  attorneyResponsible: PropTypes.shape({
    id: PropTypes.string.isRequired,
    initials: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  billingConfiguration: PropTypes.shape({
    billingType: PropTypes.string,
    id: PropTypes.string.isRequired,
    isUtbmsEnabled: PropTypes.bool.isRequired,
  }),
  clientDisplay: PropTypes.string.isRequired,
  clientNames: PropTypes.string.isRequired,
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  matterHourlyRate: PropTypes.shape({
    allStaffRate: PropTypes.number,
    billableMinutes: PropTypes.number,
    id: PropTypes.string.isRequired,
    rateOverrideType: PropTypes.oneOf([0, 1, 2, 3, undefined]),
    rateSet: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      versions: PropTypes.arrayOf(
        PropTypes.shape({
          ratesPerStaff: PropTypes.arrayOf(
            PropTypes.shape({
              rate: PropTypes.number.isRequired,
              staffId: PropTypes.string.isRequired,
            }),
          ),
        }),
      ),
    }),
    ratesPerStaff: PropTypes.arrayOf(PropTypes.object),
  }),
  matterNumber: PropTypes.string.isRequired,
  matterStarted: PropTypes.instanceOf(Date),
  matterType: PropTypes.shape({
    name: PropTypes.string,
  }),
  otherSide: PropTypes.arrayOf(PropTypes.object),
  otherSideDisplay: PropTypes.string,
  status: PropTypes.string.isRequired,
  display: PropTypes.string.isRequired,
  matterClientNames: PropTypes.string.isRequired,
  matterStartedISO: PropTypes.string.isRequired,
  typeahead: PropTypes.string.isRequired,
});

const ActivityType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  isBillable: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(Object.values(entryTypeEnum)),
  units: PropTypes.number,
  isTaxInclusive: PropTypes.bool,
  isTaxExempt: PropTypes.bool,
  rateOverrideType: PropTypes.oneOf([0, 1, 2, undefined]),
  allStaffRate: PropTypes.number,
  ratesPerStaff: PropTypes.arrayOf(
    PropTypes.shape({
      staffId: PropTypes.string.isRequired,
      rate: PropTypes.number.isRequired,
    }).isRequired,
  ),
  description: PropTypes.string.isRequired,
  category: PropTypes.oneOf(Object.values(activityCategories)),
});

const TaskType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  category: PropTypes.oneOf(Object.values(taskCategories)),
});

const SourceItemsType = PropTypes.arrayOf(
  PropTypes.shape({
    activityCount: PropTypes.number,
    activityRelatedId: PropTypes.string,
    activityType: PropTypes.number,
    billable: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    durationBilled: PropTypes.number.isRequired,
    durationWorked: PropTypes.number.isRequired,
    id: PropTypes.string, // Added (not part of entity)
    originalBillable: PropTypes.bool,
    sourceActivityIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
).isRequired;

const StaffEntityType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  isFormerStaff: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
});

const StaffMemberOptionType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  entity: StaffEntityType,
});

FeeModalBody.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.oneOf(Object.values(activityCategoryLabels)),
      activities: PropTypes.arrayOf(ActivityType).isRequired,
    }),
  ).isRequired,
  descriptionFieldMaxLength: PropTypes.number.isRequired,
  durationFieldInputDisabled: PropTypes.bool.isRequired,
  durationFieldTypeDisabled: PropTypes.bool.isRequired,
  formData: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    durationBilledInMins: PropTypes.number.isRequired,
    durationWorkedInMins: PropTypes.number.isRequired,
    feeDate: PropTypes.instanceOf(Date),
    staffId: PropTypes.string.isRequired,
    isBillable: PropTypes.bool.isRequired,
    isTaxExempt: PropTypes.bool.isRequired,
    isWriteOff: PropTypes.bool.isRequired,
    isTaxInclusive: PropTypes.bool.isRequired,
    rateInCents: PropTypes.number.isRequired,
    sourceItems: SourceItemsType,
    subject: PropTypes.string,
    // Derived values required in form submission and/or form fields
    amountExclTaxInCents: PropTypes.number.isRequired,
    billableTaxAmountInCents: PropTypes.number.isRequired,
    taxAmountInCents: PropTypes.number.isRequired,
    // Form state not required in form submission (can be edited by user)
    durationBilled: PropTypes.string.isRequired, // Billed Duration field value (hours/units)
    durationWorked: PropTypes.string.isRequired, // Worked Duration field value (hours/units)
    durationType: PropTypes.oneOf(Object.values(durationTypeEnum)).isRequired,
    // Form state not required in form submission (cannot be edited by user)
    billableAmountExclTaxInCents: PropTypes.number.isRequired,
    isInvoicedExternally: PropTypes.bool.isRequired,
    nonBillableAmountExclTaxInCents: PropTypes.number.isRequired,
    timeBilledInHoursAndMinutes: PropTypes.string.isRequired,
    timeWorkedInHoursAndMinutes: PropTypes.string.isRequired,
    // IDs that map with objects used by containers and form components
    activityId: PropTypes.string,
    matterId: PropTypes.string,
    subjectActivityId: PropTypes.string,
    taskId: PropTypes.string,
  }).isRequired,
  formDataMappedObjects: PropTypes.shape({
    activity: ActivityType,
    matter: MatterSummaryType,
    staff: StaffEntityType,
    subjectActivity: ActivityType,
    task: TaskType,
  }).isRequired,
  formErrors: PropTypes.object.isRequired,
  hasDurationTypeChanged: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    defaultMatterSummaries: PropTypes.arrayOf(MatterSummaryType),
    sourceItems: SourceItemsType.isRequired,
  }).isRequired,
  invoiceNumber: PropTypes.number,
  isNewFee: PropTypes.bool.isRequired,
  isAutoTimeFee: PropTypes.bool.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  isFormSubmitting: PropTypes.bool.isRequired,
  isOnFinalisedInvoice: PropTypes.bool.isRequired,
  matterBillableMinutes: PropTypes.number.isRequired,
  matterSummaries: PropTypes.arrayOf(MatterSummaryType.isRequired).isRequired,
  matterSummariesDataLoading: PropTypes.bool.isRequired,
  matterSummariesHasMore: PropTypes.bool.isRequired,
  region: PropTypes.oneOf(Object.values(regionType)).isRequired,
  showTaskField: PropTypes.bool.isRequired,
  showTaxField: PropTypes.bool.isRequired,
  showTaxOptions: PropTypes.bool.isRequired,
  staffMemberOptions: PropTypes.arrayOf(StaffMemberOptionType).isRequired,
  subjects: PropTypes.arrayOf(ActivityType.isRequired).isRequired,
  taskFieldEnabled: PropTypes.bool.isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.oneOf(Object.values(taskCategoryLabels)),
      tasks: PropTypes.arrayOf(TaskType.isRequired),
    }),
  ).isRequired,
  taxOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  writeOffFieldEnabled: PropTypes.bool.isRequired,
  // Callbacks
  onDeleteFee: PropTypes.func.isRequired,
  onDurationBilledFieldBlur: PropTypes.func.isRequired,
  onDurationWorkedFieldBlur: PropTypes.func.isRequired,
  onFetchMatterSummaries: PropTypes.func.isRequired,
  onFetchMoreMatterSummaries: PropTypes.func.isRequired,
  onHandleTaxOptionsBlur: PropTypes.func.isRequired,
  onNavigateToInvoice: PropTypes.func.isRequired,
  onShowTaxOptionsToggle: PropTypes.func.isRequired,
  onUpdateActivityField: PropTypes.func.isRequired,
  onUpdateBillableField: PropTypes.func.isRequired,
  onUpdateDurationFieldType: PropTypes.func.isRequired,
  onUpdateDurationBilledFieldValue: PropTypes.func.isRequired,
  onUpdateDurationWorkedFieldValue: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  onUpdateMatterField: PropTypes.func.isRequired,
  onUpdateSourceItemsField: PropTypes.func.isRequired,
  onUpdateStaffField: PropTypes.func.isRequired,
  onUpdateTaskField: PropTypes.func.isRequired,
  onUpdateTaxField: PropTypes.func.isRequired,
  setHasDurationTypeChanged: PropTypes.func.isRequired,
  setIsSubjectOverridable: PropTypes.func.isRequired,
};

FeeModalBody.defaultProps = {
  invoiceNumber: undefined,
};

FeeModalBody.displayName = 'FeeModalBody';
