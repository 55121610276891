import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { getAuthToken } from 'web/services/user-session-management';
import { selectors } from 'web/redux/features/communicate';
import { getCurrentUsers, getFirmDetails } from '@sb-firm-management/redux/firm-management';
import { CommIframePosition } from 'web/redux/features/communicate/types';
import { WebQueryMatters, WebQueryContacts } from 'web/graphql/queries';
import { ContactCreateEditModal } from 'web/react-redux/components/contact-create-edit-modal';
import * as communicateFeature from 'web/redux/features/communicate';
import { getCommunicateHost } from '@sb-itops/communicate-config';
import { sendMetric } from 'web/services/metrics';
import { AddTaskModal } from '../add-task-modal';
import { ICommunicateToBillingApi, init, ISmokeballToCommunicateApi } from '../../services/communicate-api';
import { TStaff } from '../../services/communicate-api/raw-communicate-types';
import {
  notifyCommunicateOfContactUpdate,
  notifyCommunicateOfMatterUpdate,
} from '../../services/communicate-api/handshaking';
import { requestMatter } from '../../services/communicate-api/incoming-subscriptions';
import { AttachFilesModalContainer } from './AttachFilesModal.container';
import { subscribeToNotifications } from '../../services/subscription-manager';

const scope = 'COMMUNICATE';

export const CommunicateIframe = (props: {
  authToken: string;
  visible: boolean;
  spawned: boolean;
  initialised: boolean;
  staff?: TStaff[];
  position?: CommIframePosition | null;
  matterId?: string | null;
  firm?: { id: string; name: string };
  onClickLink: (options: { type: string; id?: string }) => void;
}) => {
  const [showAttachFilesModal, setShowAttachFilesModal] = useState<
    Parameters<ICommunicateToBillingApi['openAttachFilesDialog']>[0] | false
  >(false);
  const [showAddTaskModal, setShowAddTaskModal] = useState<
    Parameters<ICommunicateToBillingApi['createTask']>[0] | false
  >(false);
  const [showContactModal, setShowContactModal] = useState<
    Parameters<ICommunicateToBillingApi['openEditContactDialog']>[0] | false
  >(false);
  const billingToCommunicateApiRef = useRef<ISmokeballToCommunicateApi | null>(null);

  const communicateMode = useRef<'MAIN' | 'MATTER' | null>(null);

  const dispatch = useDispatch();
  useEffect(() => {
    // if spawned changes from falsey->true then we want to init the Communicate API
    if (props.spawned) {
      const api = init(
        [
          {
            authToken: props.authToken,
            matter: props.matterId
              ? {
                  id: props.matterId,
                  description: '',
                  attorneysResponsibleIds: [],
                  descriptionAutomation: undefined,
                  matterNumber: undefined,
                }
              : null,
            advancedLoggingEnabled: false,
            debugUrl: null,
            previousState: null,
            roles: props.matterId ? [] : null,
          },
        ],
        {
          onClickLink: props.onClickLink,
          setShowAttachFilesModal,
          setShowContactModal,
          setShowAddTaskModal,
        },
      );
      communicateMode.current = props.matterId ? 'MATTER' : 'MAIN';
      billingToCommunicateApiRef.current = api.billingToCommunicateApi;
      if (props.matterId) {
        requestMatter(props.matterId);
      }
      return api.cleanUp;
    }
    return () => {};
  }, [props.spawned, props.matterId]);

  useEffect(() => {
    // can't call api until initialised
    if (props.matterId && props.spawned && props.initialised && billingToCommunicateApiRef.current) {
      // this will also trigger role and contact updates for the matter
      notifyCommunicateOfMatterUpdate([props.matterId]);
    }
  }, [props.matterId, props.spawned, props.initialised]);

  useEffect(() => {
    // can't call api until initialised
    if (props.spawned && props.initialised && billingToCommunicateApiRef.current) {
      billingToCommunicateApiRef.current.updateAuthToken({ authToken: props.authToken });
    }
  }, [props.authToken, props.spawned, props.initialised]);

  useEffect(() => {
    // can't call api until initialised
    if (props.staff && props.spawned && props.initialised && billingToCommunicateApiRef.current) {
      billingToCommunicateApiRef.current.updateStaff({ staff: props.staff });
    }
  }, [props.staff, props.spawned, props.initialised]);

  useEffect(() => {
    // can't call api until initialised
    if (props.firm && props.spawned && props.initialised && billingToCommunicateApiRef.current) {
      billingToCommunicateApiRef.current.updateFirm(props.firm);
    }
  }, [props.firm?.id, props.firm?.name, props.spawned, props.initialised]);

  useEffect(() => {
    sendMetric('NavCommunicate');
    // This container is always rendered so theoretically we wont be unsubbing
    const unsubContactData = subscribeToNotifications({
      notificationIds: WebQueryContacts.notificationIds,
      callback: (payload) => {
        const payloadSplit = payload.split('|');
        if (payloadSplit.length !== 2) {
          return;
        }
        const [action, id] = payloadSplit;
        if (action === 'EntityUpdated' && id) {
          notifyCommunicateOfContactUpdate([id], true);
        }
      },
    });
    const unsubMatterData = subscribeToNotifications({
      notificationIds: WebQueryMatters.notificationIds,
      callback: (payload) => {
        const payloadSplit = payload.split('|');
        if (payloadSplit.length !== 2) {
          return;
        }
        const [action, id] = payloadSplit;
        if (action === 'MatterUpdated' && id) {
          notifyCommunicateOfMatterUpdate([id]);
        }
      },
    });
    return () => {
      unsubContactData();
      unsubMatterData();
    };
  }, []);

  useEffect(() => {
    if (!props.spawned && props.visible) {
      // this detects that the iframe should be visible but has despawned, it will respawn i
      dispatch(
        communicateFeature.actions.setSpawned({
          value: true,
        }),
      );
    }
  }, [props.spawned]);

  useEffect(() => {
    if (props.spawned && !props.visible && communicateMode.current === 'MATTER') {
      // This case covers when the user switches away from a matter level communicate tab
      // it will set it back to top/main level so the badge notifications resume
      init(
        [
          {
            authToken: props.authToken,
            matter: null,
            advancedLoggingEnabled: false,
            debugUrl: null,
            previousState: null,
            roles: props.matterId ? [] : null,
          },
        ],
        {
          onClickLink: props.onClickLink,
          setShowAttachFilesModal,
          setShowContactModal,
          setShowAddTaskModal,
        },
      );
      communicateMode.current = 'MAIN';
    } else if (props.spawned && props.visible && props.matterId && communicateMode.current === 'MAIN') {
      // This case covers when we are still on a matter level and we have previous switched to main (above)
      // it will set it back to the matter if communicate becomes visible without the matter id changing
      init(
        [
          {
            authToken: props.authToken,
            matter: {
              id: props.matterId,
              description: '',
              attorneysResponsibleIds: [],
              descriptionAutomation: undefined,
              matterNumber: undefined,
            },
            advancedLoggingEnabled: false,
            debugUrl: null,
            previousState: null,
            roles: props.matterId ? [] : null,
          },
        ],
        {
          onClickLink: props.onClickLink,
          setShowAttachFilesModal,
          setShowContactModal,
          setShowAddTaskModal,
        },
      );
      communicateMode.current = 'MATTER';
    }
  }, [props.visible]);

  const position: CommIframePosition = props.position || {
    top: 0,
    left: 0,
    height: 0,
    width: 0,
  };

  const style: React.HTMLAttributes<HTMLIFrameElement>['style'] = {
    top: `${position.top}px`,
    left: `${position.left}px`,
    width: `${position.width}px`,
    height: `${position.height}px`,
    position: 'absolute',
    visibility: props.visible ? 'visible' : 'hidden',
    zIndex: props.visible ? 1 : -1,
  };

  const iframeProps = {
    ...position,
    style,
    id: 'CommIframe',
    src: getCommunicateHost(),
  };

  return props.spawned ? (
    <>
      <iframe {...iframeProps} title="communicateEmbedded" />
      {showAttachFilesModal && (
        <AttachFilesModalContainer
          matterId={showAttachFilesModal.matterId}
          conversationId={showAttachFilesModal.conversationId}
          messageText={showAttachFilesModal.messageText}
          onClose={() => setShowAttachFilesModal(false)}
          setAttachFilesDialogResponse={billingToCommunicateApiRef.current?.setAttachFilesDialogResponse}
          authToken={props.authToken}
          addAsLivingFile={showAttachFilesModal.addAsLivingFile}
        />
      )}
      {showAddTaskModal && (
        <AddTaskModal
          task={{ subject: `${showAddTaskModal.message}` }}
          matterId={showAddTaskModal.matterId}
          scope={scope}
          onClose={() => setShowAddTaskModal(false)}
        />
      )}
      {showContactModal && (
        <ContactCreateEditModal
          isVisible={!!showContactModal}
          onClose={() => {
            setShowContactModal(false);
          }}
          onClickLink={props.onClickLink}
          contactId={showContactModal.contactId}
          onContactCreated={() => {}}
          onContactCreatedWhenSaveAndNew={() => {}}
          onContactEdited={() => {}}
        />
      )}
    </>
  ) : null;
};

const Container = ({ onClickLink }) => {
  const initialised: boolean = useSelector(selectors.getInitialised);
  const visible: any = useSelector(selectors.getVisible);
  const spawned: any = useSelector(selectors.getSpawned);
  const position: any = useSelector(selectors.getPosition);
  const matterId: any = useSelector(selectors.getMatterId);
  const authToken: any = getAuthToken();
  const firmDetails: any = getFirmDetails();
  const currentUsers: any = getCurrentUsers();

  const staff =
    currentUsers &&
    currentUsers
      .filter((u) => !u.isFormerStaff)
      .map((u) => ({
        id: u.userId,
        firstName: u.firstName,
        lastName: u.lastName,
        initials: u.initials,
        email: u.email,
        mobilePhone: u.cellNumber,
        profileImageUrl: '',
        colour: {
          fill: null,
          stroke: null,
        },
      }));

  const firm = firmDetails && { id: firmDetails.accountId, name: firmDetails.firmName || '' };

  const props = { visible, spawned, initialised, position, authToken, matterId, staff, firm };

  return <CommunicateIframe {...props} onClickLink={onClickLink} />;
};

export const CommunicateIframeContainer = withReduxProvider(Container);
