import React, { memo, useMemo } from 'react';
import { Table, utils } from '@sb-itops/react/table';
import { LinkableText } from '@sb-itops/react/linkable-text';
import { Checkbox } from '@sb-itops/react/checkbox';
import { LinkFilterButton } from '@sb-itops/react/link-filter-button';
import { useTranslation, Column } from '@sb-itops/react';
import { capitalize } from '@sb-itops/nodash';
import { rateOverrideTypes } from '@sb-billing/business-logic/rates/rate-override-types';

import Styles from './RateSetsMatterTable.module.scss';

const { timestampLocalisedRenderer, checkboxCellWrapperRenderer } = utils;

interface Props {
  currentPage: number;
  isAllMattersMatchingFiltersSelected: boolean;
  onClearSelection: Function;
  onSelectAllMattersMatchingFilters;
  selectedPages: any;
  matters: any[];
  mattersCount: number;
  mattersLoading?: boolean;
  selectedMatterIds: any;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  onClickLink: Function;
  sort: Function;
  onToggleSelectAllMattersOnPage: Function;
  onToggleSelectMatters: Function;
  children?: React.ReactNode;
}

export const RateSetsMatterTable = memo((props: Props) => {
  const {
    currentPage,
    isAllMattersMatchingFiltersSelected,
    onClearSelection,
    onSelectAllMattersMatchingFilters,
    selectedPages,
    onToggleSelectAllMattersOnPage,
  } = props;

  const {
    matters,
    mattersCount,
    mattersLoading = false,
    selectedMatterIds,
    sortBy = 'clientDisplay',
    sortDirection = 'asc',
    onClickLink,
    sort,
  } = props;
  const { t } = useTranslation();

  const selectedMatterCount = Object.keys(selectedMatterIds).length;
  const selectedMatterCountOnPage = useMemo(
    () => matters.filter((matter) => selectedMatterIds[matter.id]).length,
    [matters, selectedMatterIds],
  );

  const isAllMattersOnPageSelected = selectedPages[currentPage] === true;

  const selectedPageCount = Object.entries(selectedPages).filter(([, isSelected]) => isSelected).length;
  const atLeastOnePageIsSelected = selectedPageCount > 0;
  const selectedMattersOnDifferentPages = selectedMatterCount > selectedMatterCountOnPage;

  const isAllMattersMatchingFilterSelectedManually = mattersCount > 0 && selectedMatterCount === mattersCount;
  const isAllMattersSelected = isAllMattersMatchingFiltersSelected || isAllMattersMatchingFilterSelectedManually;

  const showSelectionBanner =
    selectedMatterCount > 0 &&
    (atLeastOnePageIsSelected || selectedMattersOnDifferentPages || isAllMattersOnPageSelected || isAllMattersSelected);

  return (
    <div className={Styles.wrapper}>
      {showSelectionBanner && (
        <div className={Styles.selectAllBanner}>
          {isAllMattersSelected ? (
            <span>
              All <b>{mattersCount > 1 ? mattersCount : ''}</b> matters that match your current filter have been
              selected
            </span>
          ) : (
            <span>
              {isAllMattersOnPageSelected && selectedMatterCount === matters.length ? (
                <>
                  All <b>{selectedMatterCount > 1 ? selectedMatterCount : ''}</b> matters on the page have been selected
                </>
              ) : (
                <>
                  <b>{selectedMatterCount}</b> matter{selectedMatterCount > 1 ? 's have' : ' has'} been selected
                </>
              )}
            </span>
          )}
          <br />
          {
            /* Don't allow selection when all matters matching filters has been manually selected already */
            !mattersLoading && atLeastOnePageIsSelected && (
              <LinkFilterButton onClick={isAllMattersSelected ? onClearSelection : onSelectAllMattersMatchingFilters}>
                <b>
                  {isAllMattersSelected
                    ? 'Clear selection'
                    : `Select all ${mattersCount} matters that match your current filter`}
                </b>
              </LinkFilterButton>
            )
          }
        </div>
      )}

      <Table
        className={Styles.rateSetsMatterTable}
        onRowClick={({ rowData }) => onClickLink({ type: 'matter', id: rowData.id })}
        dataLoading={mattersLoading}
        list={matters}
        sort={sort}
        sortBy={sortBy}
        sortDirection={sortDirection}
      >
        <Column
          key="UNNEEDED"
          dataKey="UNNEEDED"
          label=""
          width={34}
          headerRenderer={checkboxCellWrapperRenderer(
            checkboxHeaderCellRenderer({
              isAllMattersMatchingFiltersSelected,
              onToggleSelectAllMattersOnPage,
              mattersLoading,
              isAllMattersOnPageSelected,
            }),
          )}
          cellRenderer={checkboxCellWrapperRenderer(checkboxCellRenderer({ ...props }))}
          disableSort
        />
        <Column
          className={Styles.matterRef}
          dataKey="matterNumber"
          label="Ref"
          cellRenderer={refRenderer}
          flexGrow={2}
        />
        <Column
          dataKey="clientDisplayName"
          cellRenderer={contactRenderer({ onClickLink })}
          label="Client(s)"
          flexGrow={4}
        />
        <Column
          dataKey="matterType"
          cellDataGetter={({ rowData }) => rowData.matterType?.name}
          label="Matter Type"
          flexGrow={3}
        />
        <Column className={Styles.description} dataKey="description" label="Description" flexGrow={6} />
        <Column
          dataKey="rateSetName"
          label="Rate Set"
          cellDataGetter={({ rowData }) => {
            const matterHourlyRate = rowData.matterHourlyRate;

            if (matterHourlyRate?.rateOverrideType !== rateOverrideTypes.RATE_SET_OVERRIDE) {
              return undefined;
            }

            return matterHourlyRate?.rateSet?.name;
          }}
          flexGrow={3}
        />
        <Column
          className={Styles.attorneyResponsible}
          dataKey="attorneyResponsible"
          cellDataGetter={({ rowData }) => rowData.attorneyResponsible?.initials}
          label={capitalize(t('attorney'))}
          flexGrow={1}
        />
        <Column dataKey="matterStarted" label="Date Opened" flexGrow={2} cellRenderer={timestampLocalisedRenderer} />
      </Table>
    </div>
  );
});

const refRenderer = ({ cellData }) => (
  <div title={cellData} className={Styles.matterRefText}>
    {cellData}
  </div>
);

/**
 * Contact Renderer
 *
 * @param {Object} param
 * @param {func} [param.onClickLink] Link routing function
 * @returns render function with cell and row data
 */
const contactRenderer =
  ({ onClickLink }) =>
  ({ rowData }) => {
    const cellValue = rowData.clients;

    if (!cellValue) {
      return undefined;
    }

    if (typeof cellValue === 'string') {
      return cellValue;
    }

    return cellValue.map(
      (contact, index) =>
        contact && (
          <span key={contact.id}>
            <LinkableText
              text={contact.displayName || 'unknown'}
              onClickLink={() => onClickLink({ type: 'contact', id: contact.id })}
              asLink={contact.displayName && onClickLink} // we dont want to show the display as a link if there is nothing to link to
              inline
            />
            {index !== cellValue.length - 1 && ' | '}
          </span>
        ),
    );
  };

function checkboxHeaderCellRenderer({
  isAllMattersMatchingFiltersSelected,
  isAllMattersOnPageSelected,
  mattersLoading,
  onToggleSelectAllMattersOnPage,
}) {
  return () => (
    <Checkbox
      checked={isAllMattersOnPageSelected || isAllMattersMatchingFiltersSelected}
      disabled={mattersLoading || isAllMattersMatchingFiltersSelected}
      onChange={onToggleSelectAllMattersOnPage}
    />
  );
}

function checkboxCellRenderer({ onToggleSelectMatters, selectedMatterIds, isAllMattersMatchingFiltersSelected }) {
  return ({ rowData }) => (
    <Checkbox
      checked={selectedMatterIds[rowData.id] || isAllMattersMatchingFiltersSelected}
      disabled={isAllMattersMatchingFiltersSelected}
      onChange={() => {
        const matter = rowData;
        onToggleSelectMatters([matter]);
      }}
    />
  );
}

RateSetsMatterTable.displayName = 'RateSetsMatterTable';
