/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import composeHooks from '@sb-itops/react-hooks-compose';
import { useState } from 'react';
import { useMatterDocumentsHook } from 'web/react-redux/routes/billing-view-matter-documents/useMatterDocumentsHook';
import * as messageDisplay from '@sb-itops/message-display';
import { Expanded, Document } from 'types';
import { getLogger } from '@sb-itops/fe-logger';
import { SelectFilesModal } from './SelectFilesModal';

const log = getLogger('SELECT_FILES_MODAL');

interface ISelectFilesModalContainerProps {
  initialSelection?: Record<string, boolean>;
  matterId: string;
  onClose(): void;
  onCancel?(): void;
  onSelectDocuments: (documents: Document[]) => Promise<void>;
}

const hooks = ({
  initialSelection = {},
  matterId,
  onClose,
  onSelectDocuments,
  onCancel,
}: ISelectFilesModalContainerProps) => ({
  useDocumentsTabStore: () => {
    const { setDocuments, selectedFolder, documents, loading, selectedPath, createNewFolder, setSelectedPath, error } =
      useMatterDocumentsHook({
        matterId,
        hideUploadingDocs: true,
      });

    const [expanded, setExpanded] = useState({ root: true } as Expanded);

    const [isSubmitting, setSubmitting] = useState(false);

    const lineItems: Document[] = Object.values(selectedFolder.contents).sort((a: any, b: any) => {
      if (a.type === 'file' && b.type === 'folder') {
        return 1;
      }
      if (b.type === 'file' && a.type === 'folder') {
        return -1;
      }
      return 0;
    }) as any;

    const [selection, setSelection] = useState(initialSelection);

    const selectedKeys = Object.keys(selection).filter((key) => selection[key]);

    const onSelect = async ({ rowData, event }, multi) => {
      // We are only interested in the selected items
      if (event.shiftKey && selectedKeys.length <= 1) {
        // Select all items between current selection and clicked item
        const initialKey = selectedKeys.length ? selectedKeys[0] : null;
        const newKey = rowData.data.id;
        let foundKeys = 0;
        setSelection(
          lineItems.reduce((acc, curr, i) => {
            if (initialKey === curr.data.id) {
              // Dont de-select the initial selection
              foundKeys += 1;
              return acc;
            }
            // Select current selection or if none are selected, select from the first item
            if (newKey === curr.data.id || (!initialKey && i === 0)) {
              foundKeys += 1;
              return { ...acc, [curr.data.id]: !selection[curr.data.id] };
            }
            if (foundKeys === 1) {
              return { ...acc, [curr.data.id]: !selection[curr.data.id] };
            }
            return acc;
          }, selection),
        );
      } else if (event.ctrlKey || event.shiftKey || multi) {
        // Shift key acts like ctrl if more than 1 item is selected
        setSelection({ ...selection, [rowData.data.id]: !selection[rowData.data.id] });
      } else {
        setSelection({ [rowData.data.id]: !selection[rowData.data.id] });
      }
    };

    return {
      isSubmitting,
      selection,
      selectedKeys,
      onSelect,
      lineItems,
      setDocuments,
      createNewFolder,
      selectedFolder,
      selectedPath,
      setSelectedPath,
      expanded,
      setExpanded,
      documents,
      loading: !!loading,
      error,
      onSubmit: async () => {
        try {
          setSubmitting(true);

          const selectedDocuments = findDocumentsInFolder(selectedKeys, documents.contents);

          await onSelectDocuments(selectedDocuments);

          setSubmitting(false);
          onClose();
        } catch (e: any) {
          log.error(e.message);
          messageDisplay.error('Error selecting files, please try again later');
          setSubmitting(false);
          throw e;
        }
      },
      onCancel: () => {
        if (onCancel) {
          onCancel();
        }

        if (onClose) {
          onClose();
        }
      },
    };
  },
});

const findDocumentsInFolder = (documentIds: string[], contents: any, found: Document[] = []): Document[] => {
  documentIds.map((documentId) => {
    if (documentId in contents) {
      found.push(contents[documentId]);
    }
  });

  if (documentIds.length === found.length) return found;

  if (contents) {
    Object.keys(contents).map((key) => {
      if ('contents' in contents[key]) {
        findDocumentsInFolder(documentIds, contents[key].contents, found);
      }
    });
  }

  return found;
};

export const SelectFilesModalContainer = composeHooks(hooks)(SelectFilesModal);
