import { gql } from '@apollo/client';

const query = gql`
  query XeroAccessToken {
    xeroAccessToken {
      id
      organisationName
      connectionIssuesCount
    }
  }
`;

const notificationIds = [
  'XeroIntegration.XeroAccessTokenUpdated',
  'XeroIntegration.XeroAccessTokenDeleted',
  'BillingIntegrationsNotifications.IntegrationFailedRequestUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const XeroConnectionIssue = {
  query,
  notificationIds,
};
