angular.module("sb.billing.webapp").config(function ($stateProvider) {
  "use strict";

  const BASE_DIRECTORY = "ng-routes/home/billing/view-matter/activities";

  $stateProvider.state("home.billing.view-matter.activities", {
    url: "/activities",
    templateUrl: `${BASE_DIRECTORY}/view-matter-activities.html`,
    controller: "sbViewMatterActivitiesController",
    controllerAs: "$ctrl",
    reloadOnSearch: false,
    data: {
      authorized: true,
      navHighlights: ["billing", "matters", "view-matter"],
      tab: { type: "matter" },
    },
  });
});
