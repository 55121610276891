
import { getProductTier, refreshTokenP } from 'web/services/user-session-management';

export const waitForCognitoTokenUpdate = async ({ expectedProductTier, attempts = 1, tryAgainTimeMs, maxAttempts = 5 }) => {
    await refreshTokenP();
    const tokenProductTier = getProductTier();

    // Check if the current product tier matches the expected one
    if (tokenProductTier === expectedProductTier) {
        return;
    }

    if (attempts >= maxAttempts) {
        throw new Error('Max retries reached. Token product tier did not match the expected value.');
    }

    // Retry logic: wait, double the retry time, and retry again
    await new Promise((resolve) => {
        setTimeout(resolve, tryAgainTimeMs);
    });

    await waitForCognitoTokenUpdate({
        expectedProductTier,
        attempts: attempts + 1,
        tryAgainTimeMs: !tryAgainTimeMs ? 2000 : tryAgainTimeMs * 2, // Double the retry time
        maxAttempts,
    });
};
