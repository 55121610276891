import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CollapsibleHeader, ToggleListFilter, PanelExpander } from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';
import { FilterExpandCollapse } from 'web/components';

const ContactListFilterPanel = memo(
  ({
    categoryOptions,
    selectCategory,
    selectedCategory,
    expanded,
    toggleExpanded,
    contactListFilterScope,
    contactStatusCounts,
    contactStatusCountsLoading,
    contactStatusOptions,
    selectedContactStatuses,
    onContactStatusSelect,
  }) =>
    featureActive('BB-7912') ? (
      <FilterExpandCollapse className="panel-filter" scope={contactListFilterScope}>
        {({ hiddenFilters, onToggleFilterVisibility }) => (
          <PanelExpander className="contacts-list-nav panel-filter" expanded={expanded} toggleExpanded={toggleExpanded}>
            {/* Roles Filter */}
            <CollapsibleHeader
              text="Roles"
              onClick={onToggleFilterVisibility}
              name="roles"
              collapsed={hiddenFilters?.roles}
            >
              <ul className="side-menu-list">
                {categoryOptions.map((option) => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <li
                    className={classnames({ active: selectedCategory.key === option.key })}
                    onClick={() => selectCategory(option.key)}
                    key={option.key}
                  >
                    {option.name}
                  </li>
                ))}
              </ul>
            </CollapsibleHeader>

            {/* Contact Status Filter */}
            <CollapsibleHeader
              text="Contact Status"
              onClick={onToggleFilterVisibility}
              name="contactStatus"
              collapsed={hiddenFilters?.contactStatus}
            >
              <ToggleListFilter
                showAllLabel="All"
                showCount
                counterDataLoading={contactStatusCountsLoading}
                countDeleted
                optionCounts={contactStatusCounts}
                options={contactStatusOptions}
                selected={selectedContactStatuses}
                onSelect={onContactStatusSelect}
              />
            </CollapsibleHeader>
          </PanelExpander>
        )}
      </FilterExpandCollapse>
    ) : (
      <PanelExpander className="contacts-list-nav panel-filter" expanded={expanded} toggleExpanded={toggleExpanded}>
        <div className="side-menu-block">
          <h3>Roles</h3>
          <ul className="side-menu-list">
            {categoryOptions.map((option) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                className={classnames({ active: selectedCategory.key === option.key })}
                onClick={() => selectCategory(option.key)}
                key={option.key}
              >
                {option.name}
              </li>
            ))}
          </ul>
        </div>
      </PanelExpander>
    ),
);

ContactListFilterPanel.displayName = 'ContactListFilterPanel';

ContactListFilterPanel.propTypes = {
  contactListFilterScope: PropTypes.string.isRequired,
  contactStatusCounts: PropTypes.shape({
    current: PropTypes.number,
    deleted: PropTypes.number,
  }).isRequired,
  contactStatusCountsLoading: PropTypes.bool.isRequired,
  contactStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  categoryOptions: PropTypes.array.isRequired,
  selectCategory: PropTypes.func.isRequired,
  selectedCategory: PropTypes.object.isRequired,
  selectedContactStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  onContactStatusSelect: PropTypes.func.isRequired,
};

ContactListFilterPanel.defaultProps = {};

export default ContactListFilterPanel;
