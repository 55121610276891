import { Button, Modal } from '@sb-itops/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { SelectFilesModal } from 'web/components';

import Styles from './SendFilesViaCommunicateFormModal.module.scss';
import { SendFilesViaCommunicateForm } from '../send-files-via-communicate-form';

export const SendFilesViaCommunicateFormModal = React.memo(
  ({
    onProcess,
    onCloseModal,
    isSubmitDisabled,
    isVisible,
    scope,
    isLiving,
    matterId,
    onSelectedContactsUpdate,
    onSelectedDocumentsUpdate,
    onStaffIdsUpdate,
    parties,
    partiesLoading,
    selectedContacts,
    selectedDocuments,
    staffIds,
  }) => {
    const [showSelectFilesModal, setShowSelectFilesModal] = useState(false);

    if (!isVisible) {
      return null;
    }

    const addSelectedDocuments = async (documents) => {
      onSelectedDocumentsUpdate(documents);
    };

    const removeSelectedDocument = (document) => {
      const updatedDocuments = selectedDocuments.filter((sd) => sd.data.id !== document.data.id);
      onSelectedDocumentsUpdate(updatedDocuments);
    };

    return (
      <>
        <Modal
          className={Styles.sendFilesViaCommunicateModal}
          isVisible={isVisible}
          title={`${isLiving ? 'Share' : 'Send'} message via Client Portal:`}
          body={
            <SendFilesViaCommunicateForm
              scope={scope}
              isLiving={isLiving}
              matterId={matterId}
              onSelectedContactsUpdate={onSelectedContactsUpdate}
              onStaffIdsUpdate={onStaffIdsUpdate}
              parties={parties}
              partiesLoading={partiesLoading}
              setShowSelectFilesModal={setShowSelectFilesModal}
              removeSelectedDocument={removeSelectedDocument}
              selectedContacts={selectedContacts}
              selectedDocuments={selectedDocuments}
              staffIds={staffIds}
            />
          }
          footer={
            <div className={Styles.buttonContainer}>
              <Button size="full-width" onClick={onProcess} disabled={isSubmitDisabled} locked={isSubmitDisabled}>
                {isLiving ? 'Share' : 'Send'}
              </Button>
              <Button
                size="full-width"
                onClick={onCloseModal}
                disabled={isSubmitDisabled}
                locked={isSubmitDisabled}
                type="secondary"
              >
                Cancel
              </Button>
            </div>
          }
          onModalClose={onCloseModal}
        />
        {showSelectFilesModal && (
          <SelectFilesModal
            initialSelection={selectedDocuments.reduce(
              (selection, document) => ({
                ...selection,
                [document.data.id]: true,
              }),
              {},
            )}
            matterId={matterId}
            onClose={() => setShowSelectFilesModal(false)}
            onSelectDocuments={addSelectedDocuments}
          />
        )}
      </>
    );
  },
);

SendFilesViaCommunicateFormModal.displayName = 'SendFilesViaCommunicateFormModal';

SendFilesViaCommunicateFormModal.propTypes = {
  scope: PropTypes.string.isRequired,
  isLiving: PropTypes.bool,
  matterId: PropTypes.string,
  isSubmitDisabled: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onProcess: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSelectedContactsUpdate: PropTypes.func.isRequired,
  onSelectedDocumentsUpdate: PropTypes.func.isRequired,
  onStaffIdsUpdate: PropTypes.func.isRequired,
  parties: PropTypes.arrayOf(PropTypes.object).isRequired,
  partiesLoading: PropTypes.bool.isRequired,
  selectedContacts: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),
  staffIds: PropTypes.arrayOf(PropTypes.string),
};

SendFilesViaCommunicateFormModal.defaultProps = {
  isLiving: undefined,
  matterId: undefined,
  selectedContacts: [],
  selectedDocuments: undefined,
  staffIds: [],
};
