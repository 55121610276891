import { featureActive } from '@sb-itops/feature';

import { checkMatterPermissionsAndDisplayErrorPopupP } from 'web/services/matter-permissions';
import { hasBillingAccess } from 'web/services/user-session-management';
import { sendMetric } from 'web/services/metrics';
import { ADD_MATTER_WIZARD_MODAL_ID } from 'web/react-redux';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';

angular.module('sb.billing.webapp').service('sbLinkService', function ($state, sbLoggerService) {
  const checkMatterPermsWrapper = async (route, params) => {
    const allowed = await checkMatterPermissionsAndDisplayErrorPopupP(params && params.matterId, { group: "matter-permissions-nav" });
    if (allowed) {
      $state.go(route, params);
    }
  }

  const that = this;
  const log = sbLoggerService.getLogger('sbLinkService');
  const links = {
    matter: (matterId) => {
      if (featureActive('NUCWEB-208') || !hasBillingAccess()) {
        checkMatterPermsWrapper('home.billing.view-matter.dashboard', { matterId });
      } else {
        checkMatterPermsWrapper('home.billing.view-matter.fees', { matterId });
      }
    },
    addMatter: (matterId = '', contactId = '') => {
      if (featureActive('NUCWEB-735') && !matterId) {
        setModalDialogVisible({ modalId: ADD_MATTER_WIZARD_MODAL_ID, props: { contactId } });
      } else {
        $state.go('home.billing.add-matter', { matterId, contactId })
      }
    },
    editMatter: (matterId = '') => $state.go('home.billing.edit-matter', { matterId }),
    dashboard: () => $state.go('home.billing.dashboard', {}),
    matterList: () => $state.go('home.billing.matters', {}),
    leadList: () => $state.go('home.billing.leads', {}),
    contact: (contactId) => $state.go('home.billing.view-contact.details', { contactId }),
    contactBills: (contactId) => $state.go('home.billing.view-contact.bills', { contactId }),
    contactList: () => $state.go('home.billing.contacts.list', {}),
    bulkBill: () => $state.go('home.billing.bills.bulk-bill', {}),
    bills: () => $state.go('home.billing.bills.list', {}),
    timeAndFees: (initialFilterDate) => $state.go('home.billing.entries.time', { initialFilterDate }),
    invoice: (invoiceId, checkId, bankAccountId, debtorId) => $state.go('home.billing.goto-invoice', { invoiceId, checkId, bankAccountId, debtorId }),
    invoiceStatementView: (invoiceStatementId) => $state.go('home.billing.view-invoice-statement.pdf', { invoiceStatementId }),
    invoiceStatementPayments: (invoiceStatementId) => $state.go('home.billing.view-invoice-statement.payments', { invoiceStatementId }),
    receipt: ({ transactionId, bulkDepositId, contactId }) => $state.go('home.billing.deposit-receipt', { transactionId, bulkDepositId, contactId }),
    transferReceipt: ({ transactionId, paymentId }) => $state.go('home.billing.transfer-receipt', { transactionId, paymentId }),
    trustToOfficeTransferReceipt: ({ transactionId, paymentId }) => $state.go('home.billing.trust-to-office-transfer-receipt', { transactionId, paymentId }),
    vendorProofOfPayment: ({ transactionId, paymentId, chequeId }) => $state.go('home.billing.vendor-proof-of-payment', { transactionId, paymentId, chequeId }),
    invoiceProofOfPayment: (invoiceId) => $state.go('home.billing.invoice-proof-of-payment', { invoiceId }),
    bankReconciliation: ({ bankReconciliationId, waitingForNotification = false }) =>
      $state.go('home.billing.bank-reconciliation-pdf', { bankReconciliationId, waitingForNotification }),
    bankReconciliationHome: () => $state.go('home.billing.accounts.bank-reconciliations.trust', {}),
    bankReconciliationHomeSpecificAccount: (trustAccountId) => $state.go('home.billing.accounts.bank-reconciliations.trust.account', { trustAccountId }),
    createBankReconciliation: () => $state.go('home.billing.create-bank-reconciliation'),
    createBankReconciliationSpecificAccount: (trustAccountId) => $state.go('home.billing.create-bank-reconciliation.account', { trustAccountId }),
    bankReconciliationSetup: () => $state.go('home.billing.bank-reconciliation-setup'),
    bankReconciliationSetupSpecificAccount: (trustAccountId) => $state.go('home.billing.bank-reconciliation-setup.account', { trustAccountId }),
    endOfMonth: (endOfMonthReportId, month) => $state.go('home.billing.end-of-month-pdf', { endOfMonthReportId, month }),
    endOfMonthPage: () => $state.go('home.billing.accounts.end-of-month', {}),
    endOfMonthPageSpecificAccount: (trustAccountId) => $state.go('home.billing.accounts.end-of-month.account', { trustAccountId }),
    depositSlip: (depositSlipId) => $state.go('home.billing.view-deposit-slip', { depositSlipId }),
    depositSlips: (page) => $state.go(`home.billing.accounts.deposit-slips.${page}`, {}),
    depositSlipsSpecificAccount: (trustAccountId) => $state.go(`home.billing.accounts.deposit-slips.trust.account`, { trustAccountId }),
    transactions: (page, trustAccountId = '') => $state.go(`home.billing.accounts.transactions.${page}`, { trustAccountId }),
    contactTransactions: (page, { contactId, trustAccountId = '' }) => $state.go(`home.billing.view-contact.transactions.${page}`, { contactId, trustAccountId }),
    matterTransactions: (page, { matterId, cmaId = '', trustAccountId = '' }) => checkMatterPermsWrapper(`home.billing.view-matter.transactions.${page}`, { matterId, cmaId, trustAccountId }),
    paymentPlan: (contactId) => $state.go('home.billing.view-contact.payment-plan', { contactId }),
    controlledMoneyAccount: (cmaId) => $state.go('home.billing.view-matter.transactions.controlled-money', { cmaId }),
    matterArchie: (matterId = '', path = '') => $state.go('home.billing.view-matter.archie', { matterId, path }),
    matterActivities: (matterId) => $state.go('home.billing.view-matter.activities', { matterId }),
    matterIntake: (matterId) => checkMatterPermsWrapper('home.billing.view-matter.intake', { matterId }),
    matterFees: (matterId) => checkMatterPermsWrapper('home.billing.view-matter.fees', { matterId }),
    matterBills: (matterId) => checkMatterPermsWrapper('home.billing.view-matter.bills', { matterId }),
    matterMemos: (matterId, selectedMemoId) => checkMatterPermsWrapper('home.billing.view-matter.memos', { matterId, selectedMemoId }),
    matterTasks: (matterId) => checkMatterPermsWrapper('home.billing.view-matter.tasks', { matterId }),
    matterEvents: (matterId) => checkMatterPermsWrapper('home.billing.view-matter.calendar', { matterId }),
    matterDocuments: (matterId) => checkMatterPermsWrapper('home.billing.view-matter.documents', { matterId }),
    productivityTasks: () => $state.go('home.billing.productivity.tasks', {}),
    productivityCalendar: () => $state.go('home.billing.productivity.calendar', {}),
    activities: () => $state.go('settings.activities.list', {}),
    firmDetails: () => $state.go('settings.firm-details', {}),
    invoiceSettings: () => $state.go('settings.invoice', {}),
    archieSettings: (matterId, path) => $state.go('settings.archie', { path }),
    emailSettings: () => $state.go('preferences.email-settings', {}),
    calendarSettings: () => $state.go('preferences.calendar-settings', {}),
    myDetails: () => $state.go('preferences.my-details', {}),
    accountSecurity: () => $state.go('preferences.account-security', {}),
    manageSubscription: () => $state.go('home.billing.manage-subscription', {}),
    paymentProviders: () =>  $state.go('settings.payment-provider', {}),
    intake: () => $state.go('intake-form-builder', {}),
    dataMigration: () => $state.go('data-migration', {}),
    logout: () => $state.go('logout', {}),
    advancedSearch: (searchTerm) => $state.go('home.billing.advanced-search.matters', { searchTerm }),
    billingReports: ({ contactId, matterId }, { reportId }) => $state.go('home.billing.external-reports', { matterId, contactId, reportId }),
    settingsXero: () => $state.go('settings.xero', {}),
    settingsMyob: () => $state.go('settings.myob', {}),
  };

  that.onClickLink = onClickLink;
  that.onClickLinks = onClickLinks;

  function onClickLink({ type, id, params = [] }) {
    log.info(`click ${type} link ${id}`);

    sendMetric('NavLink', { type })

    if (!links[type]) {
      log.warn(`Unrecognised link type: ${type}`);
    }

    if (!id) {
      log.warn(`Missing link id`);
    }

    links[type](id, ...params);
  }

  // $state.go returns promise, which is useful in case we need to open more then one tabs.
  // I tried call only one $state.go here and await it in react component but it didn't work correctly.
  // I always ended up with only 1 tab opened and "state superseded" errors. Therefore I decided to pass array of states/links and
  // await it here which seems it correctly uses last state and opens multiple tabs.
  //
  // I originally wanted to change onClickLink to support array and make it async but since it is used everywhere,
  // it was safer to create separate function onClickLinks so it can be used only when needed.
  //
  // NB: This correctly works only for links above which actually returns promise (eg. directly return $state.go).
  // Links which are wrapped in checkMatterPermsWrapper don't return promise and wouldn't work correctly.
  // If needed, checkMatterPermsWrapper should be changed and properly tested.
  async function onClickLinks(stateLinks) {
    if (!Array.isArray(stateLinks)) {
      stateLinks = [stateLinks];
    }

    for (let i = 0; i < stateLinks.length; i += 1) {
        const { type, id, params = [] } = stateLinks[i];
        log.info(`click ${type} link ${id}`);

        sendMetric('NavLink', { type })

        if (!links[type]) {
          log.warn(`Unrecognised link type: ${type}`);
        }

        if (!id) {
          log.warn(`Missing link id`);
        }

        await links[type](id, ...params);
    }
  }
});
