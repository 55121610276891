import * as Yup from 'yup';
import { customYupValidators } from '@sb-itops/business-logic/validation/services';
import { PrintManually } from '@sb-billing/business-logic/cheques';
import { capitalize } from '@sb-itops/nodash';

export const createOperatingChequeModalFormSchema = Yup.object().shape({
  amount: Yup.number(),
  chequeDate: Yup.date().required(),
  payTo: Yup.object()
    .shape({
      id: customYupValidators.uuid().required(),
      displayName: Yup.string().required(),
    })
    .default(undefined)
    .required(), // default undefined needed in these old version of yup as required() + shape() always leads to a pass https://github.com/jquense/yup/issues/1455
  reference: Yup.string().when('chequePrintMethod', {
    is: PrintManually,
    then: (referenceField) =>
      referenceField.required().test('reference', '', (reference, { createError, options }) => {
        const { t, lastOperatingChequeNumber, availableOperatingChequeNumber, allowChequeNumberDuplication } =
          options.context;

        const chequeLabel = capitalize(t('cheque'));
        const blankChequeNumber = reference?.length === 0;
        const isChequeNumberNumeric = /^[0-9]+$/.test(reference);
        const existingChequeNumber =
          availableOperatingChequeNumber?.replace(/^0+/, '') !== reference?.replace(/^0+/, '');

        // [1] Value present
        if (blankChequeNumber) {
          return createError({
            path: 'reference',
            message: `Warning: ${chequeLabel} reference is required.`,
          });
        }

        // [2] Numeric value
        if (!isChequeNumberNumeric) {
          return createError({
            path: 'reference',
            message: `Warning: ${chequeLabel} reference must be numeric.`,
          });
        }

        // [3] Unique cheque number
        if (existingChequeNumber) {
          // If duplicate cheques are allowed, we do not create a form error, but we still display the message on the UI to inform of the duplicate
          if (allowChequeNumberDuplication) {
            return true;
          }

          return createError({
            path: 'reference',
            message: `Warning: ${chequeLabel} reference is already in use. Last ${chequeLabel.toLowerCase()} reference printed was ${lastOperatingChequeNumber}.`,
          });
        }

        return true;
      }),
    otherwise: (reference) => reference.nullable().optional(),
  }),
  chequePrintMethod: Yup.string(),
  memo: Yup.string().nullable().optional(), // Not shown in AU, optional in US
});
