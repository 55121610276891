/**
 * NOTICE:
 *
 * This is a Load on Demand compatible route container, meaning that neither
 * this container nor any of the sub-component/containers should have a
 * dependency on:
 * - Entity caches
 * - Angular services
 */
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { useCorrespondenceHistoryTableData } from 'web/hooks/use-correspondence-history-table-data';
import { usePrintCorrespondenceHistoryPdf } from 'web/hooks';
import * as billingAccountsTransactions from 'web/redux/route/home-billing-accounts-transactions';
import { useSelector } from 'react-redux';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { CORRESPONDENCE_HISTORY_MODAL_ID } from 'web/components';

import FirmAccountsTransactionsClientCommunications from './FirmAccountsTransactionsClientCommunications';

const CLIENT_COMMUNICATIONS_LIST_SCOPE = 'firm-accounts-transactions-client-communications-list';
const FETCH_LIMIT = 50;
const numberOfDaysToShow = 90;
const exportSortByMap = {
  contactRelatedItems: 'to', // use "to" column to sort when exporting, as "debtor" is not exported
  invoiceRelatedItems: 'reference',
  operationType: 'operationTypeLabel',
  sentVia: 'sentViaLabel',
  user: 'sentBy',
  sentTimestamp: 'timestamp',
};

const hooks = () => ({
  useTableData: () => {
    const { showMarkAsSentEntries } = useSelector((state) => billingAccountsTransactions.selectors.getFilters(state));
    const fromDate = moment().subtract(numberOfDaysToShow, 'days').startOf('day');

    const tableData = useCorrespondenceHistoryTableData({
      scopePagination: CLIENT_COMMUNICATIONS_LIST_SCOPE,
      scopeSorting: CLIENT_COMMUNICATIONS_LIST_SCOPE,
      fetchLimit: FETCH_LIMIT,
      filters: {
        showMarkAsSentEntries,
        fromDate,
      },
    });

    return {
      ...tableData,
    };
  },
  useCorrespondenceHistoryModal: () => {
    const onOpenCorrespondenceHistoryModal = ({ correspondenceHistoryId }) => {
      setModalDialogVisible({
        modalId: CORRESPONDENCE_HISTORY_MODAL_ID,
        props: {
          correspondenceHistoryId,
        },
      });
    };
    return {
      onOpenCorrespondenceHistoryModal,
    };
  },
});

// These hooks will be called once the hooks above have completed, and will
// receive their props. This is required as we cannot retrieve values from
// adjacent hooks in composeHooks
const dependentHooks = ({
  correspondenceHistorySortBy: sortBy,
  correspondenceHistorySortDirection: sortDirection,
}) => ({
  useExportMenuOptions: ({ sbAsyncOperationsService }) => {
    const { showMarkAsSentEntries } = useSelector((state) => billingAccountsTransactions.selectors.getFilters(state));
    const { printCorrespondenceHistoryPdf, isLoadingCorrespondenceHistoryPdf } = usePrintCorrespondenceHistoryPdf();
    const sort = !sortBy
      ? undefined
      : { fieldNames: [exportSortByMap[sortBy] || sortBy], directions: [`${sortDirection || 'DESC'}`.toUpperCase()] };

    const onPrintPdf = async () => {
      await printCorrespondenceHistoryPdf({ filters: { numberOfDaysToShow, sort, showMarkAsSentEntries } });
    };

    const onExportCsv = async () => {
      try {
        sbAsyncOperationsService.startExportCorrespondenceHistoryCsv({
          numberOfDaysToShow,
          sort,
          showMarkAsSentEntries,
        });
      } catch (error) {
        throw new Error('Failed to export client communications history. Please try again later.');
      }
    };

    const exportMenuOptions = [
      {
        id: 'print-pdf',
        label: 'Print',
        onClick: onPrintPdf,
      },
      {
        id: 'export-csv',
        label: 'CSV',
        onClick: onExportCsv,
      },
    ];
    return {
      reportGenerationInProgress: isLoadingCorrespondenceHistoryPdf,
      exportMenuOptions,
    };
  },
});

export const FirmAccountsTransactionsClientCommunicationsContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(dependentHooks)(FirmAccountsTransactionsClientCommunications))),
);

FirmAccountsTransactionsClientCommunicationsContainer.displayName =
  'FirmAccountsTransactionsClientCommunicationsContainer';

FirmAccountsTransactionsClientCommunicationsContainer.propTypes = {
  onClickLink: PropTypes.func,
};
FirmAccountsTransactionsClientCommunicationsContainer.defaultProps = {
  onClickLink: undefined,
};
