'use strict';

import { getAccountId } from 'web/services/user-session-management';
import { integrations } from '@sb-billing/business-logic/integration-history/entities';
import myobLogo from '../../assets/integration-myob.png'

angular.module('sb.billing.webapp').component('sbMyobLogin', {
  templateUrl: 'ng-components/myob/myob-login.html',
  bindings: { isConnected: '<', onLoginStarted: '<?', onLoginTimeout: '<?', onLoginComplete: '<?', disconnectMyob: '<?' },
  controller: function(
    sbLoggerService,
    sbEnvironmentConfigService,
    sbNotifiedOperationP,
    sbMessageDisplayService,
    sbMyobIntegrationService
  ) {  
    const log = sbLoggerService.getLogger('sbMyobLogin');
    
    const ctrl = this;
    ctrl.integration = integrations.MYOB;
    ctrl.logoSrc = myobLogo
    ctrl.onEssentialsConnect = onEssentialsConnect;
    ctrl.onAccountRightConnect = onAccountRightConnect;
    ctrl.onConnectClick = onConnectClick;
    ctrl.onReconnectClick = onReconnectClick;

    ctrl.$onInit = () => {
      const accountId = getAccountId();
      const smokeballDomain = sbEnvironmentConfigService.getDotNetHostUrl();
      const smokeballMyobIntegrationUrl = `${smokeballDomain}/integration/Myob/MyobHome?accountId=${accountId}`;
      ctrl.myobUrlApiV0 = `${smokeballMyobIntegrationUrl}&apiVersion=0`
      ctrl.myobUrlApiV1 = `${smokeballMyobIntegrationUrl}&apiVersion=1`
    };

    async function onConnectClick(opts) {
      const isLegacy = !!(opts && opts.legacy);

      if (isLegacy) {
        window.open(ctrl.myobUrlApiV0, '_blank', 'noopener,noreferrer');
        return onEssentialsConnect();
      }

      window.open(ctrl.myobUrlApiV1, '_blank', 'noopener,noreferrer');
      return onAccountRightConnect();
    }

    async function onReconnectClick() {
      try {
        // before we reconnect, we need to determine which version of Myob we are connected to
        const isLegacy = sbMyobIntegrationService.getIntegrationVersion() !== 1;
        // We may reconsider disconnecting Myob in future but it removes the Myob settings so user has to select all account again after reconnection.
        // Not disconnecting Myob makes it work similar to Xero. With Xero, we disconnect during the reconnection process, but it keeps the saved settings.
        // await ctrl.disconnectMyob();
        onConnectClick({ legacy: isLegacy });
      } catch (err) {
        log.info('Failed to reconnect Myob');
      }
    }

    async function onEssentialsConnect() {
      if (ctrl.onLoginStarted) {
        ctrl.onLoginStarted();
      }

      // this will load the access token
      await waitForAuthedNotificationP();
      ctrl.onLoginComplete();

      // this will send the notification to .NET that will refresh the businesses for this user
      sbMyobIntegrationService.refreshBusinesses();

      // this will load the business table in mysql and we will load the cache based on the businesses and the token
      await waitForBusinesses();
    }

    async function onAccountRightConnect() {
      if (ctrl.onLoginStarted) {
        ctrl.onLoginStarted();
      }

      // this will load the access token
      await waitForAuthedNotificationP();
      ctrl.onLoginComplete();

      // this will send the notification to .NET that will refresh the businesses for this user
      sbMyobIntegrationService.refreshBusinesses();

      // this will load the business table in mysql and we will load the cache based on the businesses and the token
      await waitForBusinesses();
    }

    function waitForBusinesses() {
      // wait until the businesses are updated, omce we are done we have to send the notification to the MyobService to sync all the data
      return sbNotifiedOperationP(() => Promise.resolve(), {
        completionNotification: 'MyobIntegration',
        completionFilterFn: message => message === 'MyobBusinessesUpdated',
        timeoutMs: 300000
      });
    }

    function waitForAuthedNotificationP() {
      return sbNotifiedOperationP(() => Promise.resolve(), {
        completionNotification: 'MyobIntegration',
        completionFilterFn: message => message === 'MyobAccessTokenUpdated',
        timeoutMs: 300000
      }).catch(handleError);
    }

    function handleError(e) {
      if (ctrl.onLoginTimeout) {
        ctrl.onLoginTimeout();
      }
      log.error('MYOB authorisation failed', e);
      sbMessageDisplayService.warn(
        sbMessageDisplayService.builder().text('MYOB authorization is taking longer than expected, please try again later')
      );
    }
  }
});
