/**
 * NOTICE:
 *
 * This is a Load on Demand compatible route container, meaning that neither
 * this container nor any of the sub-component/containers should have a
 * dependency on:
 * - Entity caches
 * - Angular services
 */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import composeHooks from '@sb-itops/react-hooks-compose';

import { usePrintCorrespondenceHistoryPdf } from 'web/hooks';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import * as billingMatterTransactions from 'web/redux/route/home-billing-matter-transactions';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { CORRESPONDENCE_HISTORY_MODAL_ID } from 'web/components';

import { useCorrespondenceHistoryTableData } from 'web/hooks/use-correspondence-history-table-data';
import { MatterClientCommunications } from './MatterClientCommunications';

const exportSortByMap = {
  contactRelatedItems: 'to', // use "to" column to sort when exporting, as "debtor" is not exported
  invoiceRelatedItems: 'reference',
  operationType: 'operationTypeLabel',
  sentVia: 'sentViaLabel',
  user: 'sentBy',
  sentTimestamp: 'timestamp',
};

const FETCH_LIMIT = 50;
const CLIENT_COMMUNICATIONS_LIST_SCOPE = 'matter-client-communications-list';

const hooks = ({ matterId }) => ({
  useTableData: () => {
    const { showMarkAsSentEntries } = useSelector((state) => billingMatterTransactions.selectors.getFilters(state));

    const {
      correspondenceHistory,
      correspondenceHistoryCurrentPage,
      correspondenceHistoryOnPageChange,
      correspondenceHistoryOnSort,
      correspondenceHistorySortBy,
      correspondenceHistorySortDirection,
      correspondenceHistoryDataLoading,
      correspondenceHistoryTotalNumberOfPages,
      hidePagination,
    } = useCorrespondenceHistoryTableData({
      scopePagination: `${CLIENT_COMMUNICATIONS_LIST_SCOPE}/pagination`,
      scopeSorting: `${CLIENT_COMMUNICATIONS_LIST_SCOPE}/sorting`,
      fetchLimit: FETCH_LIMIT,
      filters: {
        showMarkAsSentEntries,
        matterId,
      },
    });

    const onOpenCorrespondenceHistoryModal = ({ correspondenceHistoryId }) => {
      setModalDialogVisible({
        modalId: CORRESPONDENCE_HISTORY_MODAL_ID,
        props: {
          correspondenceHistoryId,
        },
      });
    };

    return {
      correspondenceHistory,
      correspondenceHistoryCurrentPage,
      correspondenceHistoryDataLoading,
      correspondenceHistorySortBy,
      correspondenceHistorySortDirection,
      correspondenceHistoryTotalNumberOfPages,
      hidePagination,
      showMarkAsSentEntries,
      // Callbacks
      onOpenCorrespondenceHistoryModal,
      onCorrespondenceHistoryOnPageChange: correspondenceHistoryOnPageChange,
      onCorrespondenceHistoryOnSort: correspondenceHistoryOnSort,
    };
  },
});

const dependentHooks = ({
  correspondenceHistorySortBy: sortBy,
  correspondenceHistorySortDirection: sortDirection,
  matterId,
  sbAsyncOperationsService,
  showMarkAsSentEntries,
}) => ({
  useExportMenuOptions: () => {
    const { printCorrespondenceHistoryPdf, isLoadingCorrespondenceHistoryPdf } = usePrintCorrespondenceHistoryPdf();

    const sort = !sortBy
      ? undefined
      : { fieldNames: [exportSortByMap[sortBy] || sortBy], directions: [`${sortDirection || 'ASC'}`.toUpperCase()] };

    const onPrintPdf = async () => {
      await printCorrespondenceHistoryPdf({ filters: { matterId, sort, showMarkAsSentEntries } });
    };

    const onExportCsv = async () => {
      try {
        sbAsyncOperationsService.startExportCorrespondenceHistoryCsv({
          matterId,
          sort,
          showMarkAsSentEntries,
        });
      } catch (error) {
        throw new Error('Failed to export client communications history. Please try again later.');
      }
    };

    const exportMenuOptions = [
      {
        id: 'print-pdf',
        label: 'Print',
        onClick: onPrintPdf,
      },
      {
        id: 'export-csv',
        label: 'CSV',
        onClick: onExportCsv,
      },
    ];
    return {
      isGeneratingPDFReport: isLoadingCorrespondenceHistoryPdf,
      exportMenuOptions,
    };
  },
});

export const MatterClientCommunicationsContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(dependentHooks)(MatterClientCommunications))),
);

MatterClientCommunicationsContainer.displayName = 'MatterClientCommunicationsContainer';

MatterClientCommunicationsContainer.propTypes = {
  sbAsyncOperationsService: PropTypes.object.isRequired, // LOD Expense modal -> Operating Cheque Print now modal + CSV exports
  matterId: PropTypes.string.isRequired,
  onClickLink: PropTypes.func.isRequired,
};

MatterClientCommunicationsContainer.defaultProps = {};
