import { useEffect } from 'react';
import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import { XeroConnectionIssue, MyobConnectionIssue, IntegrationFailedRequests } from 'web/graphql/queries';
import { useSubscribedQuery } from 'web/hooks';
import {
  integrations,
  failedRequestType,
  failedRequestTarget,
} from '@sb-billing/business-logic/integration-history/entities';
import { daysAgo } from '@sb-itops/date';
import { getLogger } from '@sb-itops/fe-logger';
import { showMessage, closeMessage } from './message';
import { useLastConnectionIssuesCount, useLastFailedTransactionsCount } from './use-last-count-hook';

const log = getLogger('PageFailedTransactionsNotifierIntegration.container');

// Purpose of this component is to show a toast message if firm has any failed transactions or connection issues with Xero/Myob.
//
// We use number of connection issues and failed transactions to decide if we want to show the message.
// Message is shown if the error count is greater than the last count we saved in Redux. On load, we assume last count 0.
// This way, we show the message on page load if there are any errors and then anytime new errors occur.
//
// Please note
// We fetch failed transactions only if there is no connection issue. This is mainly so we don't overfetch, until we need it.
// Therefore, if there is a connection issue, we don't know how many failed transactions there are.
//
// Consider this scenario:
// - User loads main page and there is a connection issue (we don't fetch all failed transactions)
// - They go to integration settings and reconnect the integration
// - They replay failed transactions
// - They go back to main app
// At this point, this would show the message for failed transactions, because they are not fully replayed yet and we don't have last count saved.
// So we think that last error count is 0 (by default) but query returns more than 0 errors (replay takes up to 60s).
// For this reason, we update lastConnectionIssuesCount and lastFailedTransactionsCount count in IntegrationAccountingSettingsHeader
// component, which is component that handles replay/ignore of failed transactions.
// This way, returning to main app from the settings right after replaying messages will not show the message.

const hooks = () => ({
  useIntegration: ({ integration }) => {
    const queryConnection = integration === integrations.XERO ? XeroConnectionIssue : MyobConnectionIssue;

    const { data: connectionData, loading: connectionDataLoading } = useSubscribedQuery(queryConnection, {});
    const accessToken =
      integration === integrations.XERO ? connectionData?.xeroAccessToken : connectionData?.myobAccessToken;

    // We need to load transactions only when there is no connection issue.
    // We are still subscribed to notifications so if notification comes, both queries fire. This is correct, as we
    const skipQuery = !accessToken?.id || connectionDataLoading || accessToken?.connectionIssuesCount > 0 === true;
    const sevenDaysAgoDate = daysAgo(7).toISOString(); // begining of day 7 days ago

    const { data: failedRequestsData } = useSubscribedQuery(IntegrationFailedRequests, {
      skip: skipQuery,
      variables: {
        filter: {
          since: sevenDaysAgoDate,
          integrationTargets: [failedRequestTarget[integration]],
          failureTypes: [failedRequestType.TRANSACTION, failedRequestType.CONNECTION],
          includeIgnored: false,
          includeResolved: false,
        },
      },
    });

    return {
      accessToken,
      failedTransactions: failedRequestsData?.integrationFailedRequests,
    };
  },
});

const dependentHooks = () => ({
  useConnectionIssue: ({ integration, accessToken, onClickLink }) => {
    const { lastConnectionIssuesCount, setLastConnectionIssuesCount } = useLastConnectionIssuesCount({ integration });

    // Show Connection error message if the count is greater than the last count
    useEffect(() => {
      const { id, connectionIssuesCount } = accessToken || {};
      const lastCount = lastConnectionIssuesCount || 0;

      if (id && typeof connectionIssuesCount === 'number') {
        if (lastCount < connectionIssuesCount) {
          // Close the other message if it's open and open Connection issues message
          closeMessage({ integration, messageType: failedRequestType.TRANSACTION });
          showMessage({
            integration,
            messageType: failedRequestType.CONNECTION,
            onClickLink,
            count: connectionIssuesCount,
          });
          log.info(`Showing failed connection message for ${integration}|${connectionIssuesCount}`);
        }

        if (lastCount !== connectionIssuesCount) {
          setLastConnectionIssuesCount(connectionIssuesCount);
        }

        if (connectionIssuesCount === 0) {
          closeMessage({ integration, messageType: failedRequestType.CONNECTION });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken]);
  },
  useTransactionsIssue: ({ integration, failedTransactions, accessToken, onClickLink }) => {
    const { lastFailedTransactionsCount, setLastFailedTransactionsCount } = useLastFailedTransactionsCount({
      integration,
    });

    // Show Transaction error message if the count is greater than the last count
    useEffect(() => {
      const failedTransactionsCount = failedTransactions?.length || 0;
      const lastCount = lastFailedTransactionsCount || 0;

      // We can show the message only if the integration is connected and there is no connection issue
      if (!!accessToken?.id && accessToken?.connectionIssuesCount === 0 && Array.isArray(failedTransactions)) {
        if (lastCount < failedTransactionsCount) {
          // Close the other message if it's open and open Transaction issues message
          closeMessage({ integration, messageType: failedRequestType.CONNECTION });
          showMessage({
            integration,
            messageType: failedRequestType.TRANSACTION,
            onClickLink,
            count: failedTransactionsCount,
          });
          log.info(`Showing failed transactions message for ${integration}|${failedTransactionsCount}`);
        }

        if (lastCount !== failedTransactionsCount) {
          setLastFailedTransactionsCount(failedTransactionsCount);
        }

        if (failedTransactionsCount === 0) {
          closeMessage({ integration, messageType: failedRequestType.TRANSACTION });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [failedTransactions, accessToken]);
  },
});

export const PageFailedTransactionsNotifierIntegrationContainer = composeHooks(hooks)(
  composeHooks(dependentHooks)(() => null),
);

PageFailedTransactionsNotifierIntegrationContainer.displayName = 'PageFailedTransactionsNotifierIntegrationContainer';

PageFailedTransactionsNotifierIntegrationContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  integration: PropTypes.string.isRequired,
};
