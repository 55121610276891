import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import * as yup from 'yup';

import { getLogger } from '@sb-itops/fe-logger';
import {
  error as displayErrorToUser,
  success as displaySuccessToUser,
  builder as messageDisplayBuilder,
} from '@sb-itops/message-display';
import { withOnLoad, useTranslation } from '@sb-itops/react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import {
  interpolatedDescriptionRegexes,
  transactionType as transactionTypes,
} from '@sb-billing/business-logic/transactions/entities/constants';
import {
  buildBulkTtoChequeDescription,
  buildChequeDescription,
  isTransactionReversible,
} from '@sb-billing/business-logic/transactions/services';
import {
  type as BALANCE_TYPE,
  byName as BALANCE_BY_NAME,
} from '@sb-billing/business-logic/bank-account-settings/entities/constants';
import { useSubscribedQuery, useCacheQuery } from 'web/hooks';
import { getContactDisplay as getContactDisplayFromContact } from '@sb-customer-management/business-logic/contacts/services';
import { TrustChequeDetailsModalData, InitBankAccountSettings, ContactOption } from 'web/graphql/queries';

import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { todayAsInteger } from '@sb-itops/date';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { TrustChequeDetailsModal } from './TrustChequeDetailsModal';

export const TRUST_CHEQUE_DETAILS_MODAL_ID = 'TRUST_CHEQUE_DETAILS_MODAL_ID';
const SCOPE = 'trust-cheque-details-modal';
const log = getLogger('TrustChequeDetailsModal.container');

const TrustChequeDetailsModalSchema = yup.object().shape({
  reason: yup.string().required(),
});

/*
 *  Trust cheques can be created from two types of payments:
 *  - Vendor Payments (from trust)
 *    - Vendor payments can be split between multiple contacts (US/Contact matter balance) or single contact
 *    - This is determined by multiPaymentId on the VendorPayment
 *    - The paymentId on the transaction in payments array on the Trust Cheque will be a VendorPayment entity
 *  - Invoice Payments (from trust, aka TTO or trust to office)
 *    - TTOs have two types:
 *      - Single (paying one invoice)
 *      - Bulk (paying multiple invoices... but can also be a single invoice)
 *    - Whether it is bulk is determined by the isTrustToOffice field on the Trust Cheque
 *    - Single invoice payments can also be split between multiple contacts (US/Contact matter balance)
 *
 * If a TTO is split between multiple invoices, or multiple contacts, or a vendor is split between multiple contacts, we need to show different reversal warnings
 * as all transactions will be reversed if one of these are reversed
 */

const hooks = (props) => ({
  useTrustChequeDetailsModalDataQuery: () => {
    const { t } = useTranslation();
    const { data, loading, error } = useSubscribedQuery(TrustChequeDetailsModalData, {
      variables: {
        id: props.chequeId,
      },
    });

    if (error) {
      throw new Error(error);
    }

    const trustCheque = data?.trustCheque || {};
    const { id, chequeDate, chequeMemo, lastUpdated, isTrustToOffice: isBulkTto, payments = [] } = trustCheque;

    const initialAccumulator = {
      amount: 0,
      createdBy: undefined,
      systemDate: undefined,
      reversed: undefined,
      reversalReason: undefined,
      reason: undefined,
      matter: undefined,
      matterDisplay: undefined,
      description: undefined,
      source: undefined,
      payor: undefined,
      payorDisplay: undefined,
      transactionType: undefined,
      descriptionContactToFetch: undefined,
      bankAccountId: undefined,
      isReversible: true,
      // showBulkTtoReversalWarning is different to isBulkTto
      // bulk TTOs looks at trustCheque.isTrustToOffice and multipayment id and controls whether we show the invoice payment table
      // however bulk TTOs with only one payment and one invoice do not show the reversal warning
      showBulkTtoReversalWarning: false,
      isSplitVendorPayment: false,
      isSplitSingleInvoicePayment: false,
      reversalData: {},
      contactsMap: {},
      mattersMap: {},
      bankAccountsMap: {},
      invoicesMap: {},
    };

    const {
      amount,
      createdBy,
      systemDate,
      reversed,
      reversalReason,
      reason,
      matterDisplay,
      description,
      source,
      payorDisplay,
      transactionType,
      descriptionContactToFetch,
      showBulkTtoReversalWarning,
      isSplitVendorPayment,
      isSplitSingleInvoicePayment,
      bankAccountId,
      isReversible,
      reversalData,
      contactsMap,
      mattersMap,
      bankAccountsMap,
      invoicesMap,
    } = payments.reduce((acc, trustChequePayment) => {
      const { transaction, payment } = trustChequePayment;

      // Common fields
      acc.amount += transaction?.amount || 0;
      if (!acc.matter) {
        acc.matter = transaction?.matter;
        acc.matterDisplay = getMatterDisplay(acc.matter, acc.matter?.matterType?.name, true);
      }
      if (!acc.createdBy) {
        acc.createdBy = transaction?.user;
      }
      if (!acc.systemDate) {
        acc.systemDate = lastUpdated || transaction?.lastUpdated;
      }
      if (!acc.reversed) {
        acc.reversed = transaction?.reversed;
      }
      if (!acc.reason) {
        acc.reason = transaction?.reason;
      }

      if (!acc.transactionType) {
        acc.transactionType = transaction?.type;
      }

      if (acc.isReversible) {
        // isReversible is true until we find a non-reversible transaction or payment
        // then becomes and stays false
        acc.isReversible = isTransactionReversible({ transaction, payment });
      }

      // Vendor payment specific fields
      if (acc.transactionType === transactionTypes.VendorPayment) {
        if (!acc.description) {
          acc.description = buildChequeDescription({
            description: truncateVendorPaymentDescription(transaction?.description),
            reason: acc.reversed ? acc.reversalReason : acc.reason,
            isReversed: acc.reversed,
            hasReasonFieldFacet: hasFacet(facets.reasonField),
          });
        }
        if (!acc.payorDisplay) {
          acc.payorDisplay = getContactDisplayFromContact(transaction?.contact, { showLastNameFirst: false });
        }
        if (!acc.source) {
          acc.source = transaction?.source;
        }
        acc.descriptionContactToFetch = parseContactIdFromDescription(transaction?.description);
        if (!(transaction?.contact?.id in acc.contactsMap)) {
          acc.contactsMap[transaction?.contact?.id] = transaction?.contact;
        }
        if (!(transaction?.matter?.id in acc.mattersMap)) {
          acc.mattersMap[transaction?.matter?.id] = transaction?.matter;
        }
        if (!acc.reversalReason) {
          acc.reversalReason = transaction?.reversedToTransaction?.reason;
        }
        if (!acc.reversed) {
          acc.bankAccountId = transaction?.bankAccount?.id;
          acc.reversalData = {
            paymentId: payment?.id,
            bankAccountId: transaction?.bankAccount?.id,
            transactionIdToReverse: transaction?.id,
          };
          acc.isSplitVendorPayment = acc.isSplitVendorPayment || !!payment.multiPaymentId;
        }
      }

      // Invoice Payment specific fields
      if (acc.transactionType === transactionTypes.InvoicePayment) {
        if (!acc.description) {
          // Bulk descriptions are built after the reduce on 140
          acc.description = buildChequeDescription({
            description: transaction?.description,
            reason: acc.reversed ? acc.reversalReason : acc.reason,
            isReversed: acc.reversed,
            hasReasonFieldFacet: hasFacet(facets.reasonField),
          });
        }
        if (!acc.payor) {
          acc.payorDisplay = getContactDisplayFromContact(payment?.payor, { showLastNameFirst: false });
        }
        if (!(payment?.sourceAccount?.id in acc.bankAccountsMap)) {
          acc.bankAccountsMap[payment?.sourceAccount?.id] = payment?.sourceAccount;
        }
        if (!(payment?.destinationAccount?.id in acc.bankAccountsMap)) {
          acc.bankAccountsMap[payment?.destinationAccount?.id] = payment?.destinationAccount;
        }
        if (!acc.source) {
          acc.source = getBankAccountName(payment?.sourceAccount, t);
        }
        if (!acc.reversalReason) {
          acc.reversalReason = payment?.reversalReason;
        }
        payment?.invoices?.forEach((paymentInvoice) => {
          const { invoice } = paymentInvoice;
          if (!(invoice?.id in acc.invoicesMap)) {
            acc.invoicesMap[invoice?.id] = {
              ...invoice,
              amount: paymentInvoice.amount,
            };
          }
        });

        if (!acc.reversed) {
          acc.bankAccountId = payment?.sourceAccount?.id;
          acc.reversalData = {
            paymentId: payment?.id,
            allowOverdraw: hasFacet(facets.allowOverdraw),
          };
          if (isBulkTto) {
            // If it's true stay true
            acc.showBulkTtoReversalWarning =
              acc.showBulkTtoReversalWarning || payments.length > 1 || payment.invoices.length > 1;
          } else {
            acc.isSplitSingleInvoicePayment = acc.isSplitSingleInvoicePayment || payments.length > 1;
          }
        }
      }

      return acc;
    }, initialAccumulator);

    let bulkDescription;
    if (isBulkTto) {
      bulkDescription = buildBulkTtoChequeDescription({
        reason: reversed ? reversalReason : reason,
        isReversed: reversed,
        hasReasonFieldFacet: hasFacet('reasonField'),
        invoicesMap,
        t,
      });
    }

    return {
      chequeId: id,
      amount: Math.abs(amount),
      matterDisplay,
      effectiveDate: chequeDate,
      description,
      bulkDescription,
      source,
      payorDisplay,
      createdBy,
      systemDate,
      memo: chequeMemo,
      trustChequePayments: payments,
      isBulkTto,
      isReversed: reversed,
      isReversible,
      bankAccountId,
      reason,
      showReasonField: hasFacet('reasonField'),
      reversalReason,
      showBulkTtoReversalWarning,
      isSplitSingleInvoicePayment,
      isSplitVendorPayment,
      showSplitVendorPaymentReversalWarning: isSplitVendorPayment,
      descriptionContactToFetch,
      transactionType,
      reversalData,
      contactsMap,
      mattersMap,
      bankAccountsMap,
      invoicesMap,
      // other
      isLoading: loading,
    };
  },
  useBankAccountSettingsData: () => {
    const { data: bankAccountSettingsData } = useCacheQuery(InitBankAccountSettings.query);
    const isMatterContactBalanceFirm =
      bankAccountSettingsData?.bankAccountSettings?.bankBalanceType === BALANCE_BY_NAME[BALANCE_TYPE.matterContact];

    return {
      isMatterContactBalanceFirm,
    };
  },
});

const dependentHooks = (props) => ({
  useContactQuery: ({ descriptionContactToFetch, contactsMap, isLoading }) => {
    const { data, loading, error } = useSubscribedQuery(ContactOption, {
      skip: !descriptionContactToFetch,
      variables: {
        contactId: descriptionContactToFetch,
      },
    });

    if (descriptionContactToFetch && error) {
      throw new Error(error);
    }

    const contact = data?.contact || {};
    const { id } = contact;
    if (id) {
      // eslint-disable-next-line no-param-reassign
      contactsMap[id] = contact;
    }

    return {
      isLoading: isLoading || loading,
      contactsMap,
    };
  },
  useFormFields: ({ transactionType, reversalData }) => {
    const { t } = useTranslation();
    const [isReverseCollapsed, setReverseCollapsed] = useState(true);
    const reasonInputRef = useRef(null);

    useEffect(() => {
      if (!isReverseCollapsed && reasonInputRef.current) {
        reasonInputRef.current.focus();
      }
    }, [isReverseCollapsed]);

    const {
      formFields,
      submitFailed,
      formInitialised,
      onInitialiseForm,
      onClearForm,
      onUpdateFieldValues,
      onSubmitFormWithValidation,
      onValidateForm,
    } = useForm({ scope: SCOPE, schema: TrustChequeDetailsModalSchema });

    const onToggleReversal = ({ isDelete }) => {
      onUpdateFieldValues('hideCheque', isDelete);
      setReverseCollapsed(!isReverseCollapsed);
    };

    const onReasonChangedHandler = () => {
      onUpdateFieldValues('reason', reasonInputRef.current.value);
    };

    return {
      reasonField: formFields.reason,
      isReverseCollapsed,
      reasonInputRef,
      submitFailed,
      formInitialised,
      onValidateForm,
      onReasonChangedHandler,
      onToggleReversal,
      onLoad: () => {
        onInitialiseForm({
          reason: '',
          hideCheque: false,
        });
        const onUnload = onClearForm;
        return onUnload;
      },
      onProcessReversal: async () => {
        onValidateForm();
        await onSubmitFormWithValidation({
          submitFnP: async (finalFormValues) => {
            if (!transactionType) {
              log.error('Transaction type is required to reverse a trust cheque');
              displayErrorToUser(
                messageDisplayBuilder()
                  .title(`${finalFormValues.hideCheque ? 'deletion' : 'reversal'} not processed`)
                  .text(`Transaction type is required to reverse a ${t('trustCheque')}`),
              );
              return;
            }

            try {
              if (transactionType === transactionTypes.VendorPayment) {
                await dispatchCommand({
                  type: 'Billing.Accounts.Messages.Commands.ReverseVendorPayment',
                  message: {
                    paymentId: reversalData?.paymentId,
                    bankAccountId: reversalData?.bankAccountId,
                    transactionIdToReverse: reversalData?.transactionIdToReverse,
                    reason: finalFormValues.reason,
                    hideTransactions: finalFormValues.hideCheque,
                    effectiveDate: todayAsInteger(),
                  },
                });
                props.onClose();
                displaySuccessToUser(`Payment ${finalFormValues.hideCheque ? 'deletion' : 'reversal'} processed`);
              } else if (transactionType === transactionTypes.InvoicePayment) {
                await dispatchCommand({
                  type: 'Billing.Invoicing.Messages.Commands.ReversePayment',
                  message: {
                    paymentId: reversalData?.paymentId,
                    reason: finalFormValues.reason,
                    allowOverdraw: reversalData?.allowOverdraw,
                    hideTransactions: finalFormValues.hideCheque,
                    effectiveDate: todayAsInteger(),
                  },
                });
                props.onClose();
                displaySuccessToUser(
                  `Invoice payment ${finalFormValues.hideCheque ? 'deletion' : 'reversal'} processed`,
                );
              } else {
                log.error('Invalid transaction type for a trust cheque reversal');
                displayErrorToUser(
                  messageDisplayBuilder()
                    .title(`${finalFormValues.hideCheque ? 'deletion' : 'reversal'} not processed`)
                    .text(`Invalid transaction type for a ${t('trustCheque')} reversal`),
                );
              }
            } catch (err) {
              props.onClose();
              log.error('Problem processing reversal', err);
              displayErrorToUser(
                messageDisplayBuilder()
                  .title(`${finalFormValues.hideCheque ? 'deletion' : 'reversal'} not processed`)
                  .text(
                    `Failed to process ${
                      transactionType === transactionTypes.InvoicePayment ? 'invoice' : ''
                    } payment ${
                      finalFormValues.hideCheque ? 'deletion' : 'reversal'
                    }. Please check your connection and try again`,
                  ),
              );
            }
          },
        });
      },
    };
  },
});

function parseContactIdFromDescription(description) {
  const contactIdMatch = [...description.matchAll(interpolatedDescriptionRegexes.CONTACT_ID)];
  if (contactIdMatch) {
    return contactIdMatch[0][1];
  }
  return undefined;
}

function truncateVendorPaymentDescription(description) {
  // Vendor payment descriptions don't show the matter, just the payment to x
  const truncationPoint = description.indexOf(' for ');
  return truncationPoint ? `${description.slice(0, truncationPoint)}` : description;
}

export const TrustChequeDetailsModalContainer = withApolloClient(
  composeHooks(hooks)(composeHooks(dependentHooks)(withOnLoad(TrustChequeDetailsModal))),
);

TrustChequeDetailsModalContainer.displayName = 'TrustChequeDetailsModal.container';

TrustChequeDetailsModalContainer.propTypes = {
  chequeId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  // function/callbacks
  onClose: PropTypes.func.isRequired,
};

TrustChequeDetailsModalContainer.defaultProps = {};
