'use strict';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  const BASE_DIRECTORY = 'ng-routes/home/billing/productivity';

  $stateProvider
    .state('home.billing.productivity', {
      url: '/productivity',
      reloadOnSearch: false,
      template: `<div class="productivity-container" ui-view></div>`,
      controller: 'ProductivityController',
      controllerAs: '$ctrl',
      abstract: true,
    })
    .state('home.billing.productivity.tasks', {
      url: '/tasks',
      reloadOnSearch: false,
      templateUrl: BASE_DIRECTORY + '/tasks/tasks.html',
      controller: 'ProductivityController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'productivity', 'tasks']
      }
    })
    .state('home.billing.productivity.calendar', {
      url: '/calendar',
      reloadOnSearch: false,
      templateUrl: BASE_DIRECTORY + '/calendar/calendar.html',
      controller: 'ProductivityController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'productivity', 'calendar']
      }
    })
    .state('home.billing.productivity.activities', {
      url: '/activities',
      reloadOnSearch: false,
      templateUrl: BASE_DIRECTORY + '/activities/activities.html',
      controller: 'ProductivityController',
      controllerAs: '$ctrl',
      data: {
        authorized: true,
        navHighlights: ['billing', 'productivity', 'activities']
      }
    });
});
