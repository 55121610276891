import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { StatelessSelect } from '@sb-itops/react/select';
import { t } from '@sb-itops/localisation-web';
import {
  CollapsibleHeader,
  PaginationSelector,
  PanelExpander,
  Spinner,
  ToggleListFilter,
  Button,
} from '@sb-itops/react';
import { RateSetsMatterTable, MatterTypeSelector, FilterExpandCollapse } from 'web/components';
import { RateSetBulkApplyModal } from 'web/react-redux/components/rate-set-bulk-apply-modal';
import { DateListFilter } from 'web/react-redux';
import { StaffSelectorList } from '@sb-firm-management/react';
import { ContactTypeahead2 } from '@sb-customer-management/react';
import Styles from './FirmRateSets.module.scss';

export const FirmRateSets = memo((props) => {
  const { matterFilterScope, onClickLink, expanded, toggleExpanded } = props;
  const { matters, mattersLoading } = props;
  const {
    isAllMattersMatchingFiltersSelected,
    selectedMatterIds,
    selectedPages,
    onClearSelection,
    onSelectAllMattersMatchingFilters,
    onToggleSelectAllMattersOnPage,
    onToggleSelectMatters,
  } = props;
  const { isPaginationHidden, mattersCurrentPage, mattersTotalCount, mattersTotalNumberOfPages, mattersOnPageChange } =
    props;
  const { mattersOnSort, mattersSortBy, mattersSortDirection } = props;
  const { staffMembers, staffSelectedIds, staffOnToggle, staffOnSelect, staffOnDeselectAll } = props;
  const {
    mattersOnSelectStatus,
    mattersSelectedStatusIds,
    mattersStatusAllLabel,
    mattersStatusOptions,
    matterStatusCounts,
  } = props;
  const { matterTypes, matterTypesOnSelect, matterTypeSelectedIds } = props;
  const { clientsOnSelect } = props;
  const {
    clientOptions,
    clientOptionsDataLoading,
    clientOptionsHasMore,
    onFetchClientOptions,
    onFetchMoreClientOptions,
  } = props;
  const {
    isRateSetModalVisible,
    isSubmitting,
    setIsRateSetModalVisible,
    selectedBulkApplyRateSetOption,
    onRateSetBulkApplyOptionSelect,
    onRateSetBulkApplySave,
  } = props;
  const { rateSetsLoading, rateSetOptions, rateSetSelectedIds, rateSetsOnSelect } = props;
  const { onDateListChange, fromDate, toDate, dateRangeType } = props;
  const noMatterSelected = Object.keys(selectedMatterIds).length === 0;

  return (
    <div className={Styles.firmRateSets}>
      <div className={classnames('master-detail-panel', Styles.mainBody)}>
        {/* Start Filter Panel */}
        <div className={Styles.mattersSidePanel}>
          <FilterExpandCollapse className="panel-filter" scope={matterFilterScope}>
            {({ hiddenFilters, onToggleFilterVisibility }) => (
              <PanelExpander className="panel-filter" expanded={expanded} toggleExpanded={toggleExpanded}>
                {/* Client Filter */}
                <CollapsibleHeader
                  text="Client"
                  onClick={onToggleFilterVisibility}
                  name="client"
                  collapsed={hiddenFilters?.client}
                >
                  <ContactTypeahead2
                    className={Styles.filterSelection}
                    contactOptions={clientOptions}
                    contactOptionsHasMore={clientOptionsHasMore}
                    // hasError={isErrorField(formErrors?.clientId)}
                    isClearable
                    isLoading={clientOptionsDataLoading}
                    maxMenuHeight={250}
                    placeholder="All clients"
                    // Callbacks
                    noOptionsMessageFn={() => 'Start typing to get a list of clients...'}
                    onContactSelected={(option) => {
                      if (!option) {
                        clientsOnSelect([]);
                      } else {
                        clientsOnSelect([option.value]);
                      }
                      onClearSelection();
                    }}
                    onFetchContactOptions={onFetchClientOptions}
                    onFetchMoreContactOptions={onFetchMoreClientOptions}
                  />
                </CollapsibleHeader>

                {/* Rate sets Filter */}
                <CollapsibleHeader
                  text="Current Rate Set"
                  onClick={onToggleFilterVisibility}
                  name="rateSet"
                  collapsed={hiddenFilters?.rateSet}
                >
                  <StatelessSelect
                    className={Styles.filterSelection}
                    isLoading={rateSetsLoading}
                    selectedOption={rateSetSelectedIds}
                    options={rateSetOptions}
                    placeholder="Any Rate Sets"
                    isClearable
                    onValueChange={(option) => {
                      if (!option) {
                        rateSetsOnSelect([]);
                      } else {
                        rateSetsOnSelect([option.value]);
                      }
                      onClearSelection();
                    }}
                  />
                </CollapsibleHeader>

                {/* Matter Type Filter */}
                <CollapsibleHeader
                  text="Matter Type"
                  onClick={onToggleFilterVisibility}
                  name="matterType"
                  collapsed={hiddenFilters?.matterType}
                >
                  {matterTypes ? (
                    <MatterTypeSelector
                      matterTypes={matterTypes}
                      onSelectMatterType={(types) => {
                        matterTypesOnSelect(types);
                        onClearSelection();
                      }}
                      selectedMatterTypeIds={matterTypeSelectedIds}
                    />
                  ) : (
                    <Spinner className={Styles.matterTypesLoading} small />
                  )}
                </CollapsibleHeader>

                {/* Date opened Filter */}
                <CollapsibleHeader
                  text="Date Opened"
                  onClick={onToggleFilterVisibility}
                  name="dateOpened"
                  collapsed={hiddenFilters?.dateOpened}
                >
                  <DateListFilter
                    onChange={(dateFilter) => {
                      onDateListChange(dateFilter);
                      onClearSelection();
                    }}
                    className="side-menu-list"
                    selectedFilter={dateRangeType}
                    from={fromDate}
                    to={toDate}
                    showThisMonth={false}
                    showLastMonth={false}
                    showBefore={false}
                  />
                </CollapsibleHeader>

                {/* Matter Status Filter */}
                <CollapsibleHeader
                  text="Matter Status"
                  onClick={onToggleFilterVisibility}
                  name="matterStatus"
                  collapsed={hiddenFilters?.matterStatus}
                >
                  <ToggleListFilter
                    onSelect={(status) => {
                      mattersOnSelectStatus(status);
                      onClearSelection();
                    }}
                    options={mattersStatusOptions}
                    optionCounts={matterStatusCounts}
                    selected={mattersSelectedStatusIds}
                    showAllLabel={mattersStatusAllLabel}
                    showCount
                    countDeleted
                  />
                </CollapsibleHeader>

                {/* Attorney Responsible Filter */}
                <CollapsibleHeader
                  text={t('personResponsible')}
                  onClick={onToggleFilterVisibility}
                  name="attorneyResponsible"
                  collapsed={hiddenFilters?.attorneyResponsible}
                >
                  <StaffSelectorList
                    staff={staffMembers}
                    selectedStaffIds={staffSelectedIds}
                    onToggleStaff={(staff) => {
                      staffOnToggle(staff);
                      onClearSelection();
                    }}
                    onSelectStaff={(staff) => {
                      staffOnSelect(staff);
                      onClearSelection();
                    }}
                    onDeselectAllStaff={() => {
                      staffOnDeselectAll();
                      onClearSelection();
                    }}
                    showNoneOption
                  />
                </CollapsibleHeader>
              </PanelExpander>
            )}
          </FilterExpandCollapse>
        </div>
        {/* End Filter Panel */}

        <div className={classnames('panel-body', Styles.panelContent)}>
          <div className="ribbon panel">
            <Button
              title={noMatterSelected ? 'Select at least one matter before applying a rate set' : ''}
              onClick={() => setIsRateSetModalVisible(true)}
              disabled={noMatterSelected}
            >
              Apply Rate Set
            </Button>
          </div>
          <RateSetsMatterTable
            currentPage={mattersCurrentPage}
            isAllMattersMatchingFiltersSelected={isAllMattersMatchingFiltersSelected}
            matters={matters}
            mattersCount={mattersTotalCount}
            mattersLoading={mattersLoading}
            selectedMatterIds={selectedMatterIds}
            selectedPages={selectedPages}
            sortBy={mattersSortBy}
            sortDirection={mattersSortDirection}
            onClearSelection={onClearSelection}
            onClickLink={onClickLink}
            onSelectAllMattersMatchingFilters={onSelectAllMattersMatchingFilters}
            onToggleSelectAllMattersOnPage={onToggleSelectAllMattersOnPage}
            onToggleSelectMatters={onToggleSelectMatters}
            sort={mattersOnSort}
          />
          <PaginationSelector
            name="firmRateSets"
            className={Styles.paginationSection}
            hidePagination={isPaginationHidden}
            numberOfPagesDisplayed={10}
            selectedPage={mattersCurrentPage}
            totalNumberOfPages={mattersTotalNumberOfPages}
            onPageChange={mattersOnPageChange}
          />
        </div>
      </div>
      <RateSetBulkApplyModal
        isSubmitting={isSubmitting}
        isVisible={isRateSetModalVisible}
        selectedBulkApplyRateSetOption={selectedBulkApplyRateSetOption}
        onCloseModal={() => {
          setIsRateSetModalVisible(false);
          onRateSetBulkApplyOptionSelect(undefined);
        }}
        onRateSetChange={onRateSetBulkApplyOptionSelect}
        onSave={() => onRateSetBulkApplySave()}
      />
    </div>
  );
});

FirmRateSets.displayName = 'FirmRateSets';
FirmRateSets.propTypes = {
  clientOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      searchText: PropTypes.string,
    }),
  ),
  clientSelectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  clientOptionsDataLoading: PropTypes.bool,
  clientOptionsHasMore: PropTypes.bool,
  dateRangeType: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  fromDate: PropTypes.number.isRequired, // effective date YYYYMMDD
  isAllMattersMatchingFiltersSelected: PropTypes.bool.isRequired,
  isPaginationHidden: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isRateSetModalVisible: PropTypes.bool.isRequired,
  matterFilterScope: PropTypes.string.isRequired,
  matters: PropTypes.arrayOf(PropTypes.object).isRequired,
  mattersCurrentPage: PropTypes.number.isRequired,
  mattersLoading: PropTypes.bool.isRequired,
  mattersOnSort: PropTypes.func.isRequired,
  mattersOnSelectStatus: PropTypes.func.isRequired,
  mattersSelectedStatusIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  mattersStatusAllLabel: PropTypes.string.isRequired,
  matterStatusCounts: PropTypes.object.isRequired,
  mattersStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,
  mattersSortBy: PropTypes.string.isRequired,
  mattersSortDirection: PropTypes.string.isRequired,
  mattersTotalCount: PropTypes.number.isRequired,
  mattersTotalNumberOfPages: PropTypes.number.isRequired,
  matterTypes: PropTypes.arrayOf(PropTypes.object),
  matterTypeSelectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedMatterIds: PropTypes.object.isRequired,
  selectedPages: PropTypes.object.isRequired,
  selectedBulkApplyRateSetOption: PropTypes.object,
  rateSetsLoading: PropTypes.bool.isRequired,
  rateSetOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      searchText: PropTypes.string,
    }),
  ),
  rateSetSelectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  staffMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      initials: PropTypes.string,
    }),
  ).isRequired,
  staffSelectedIds: PropTypes.object.isRequired,
  toDate: PropTypes.number.isRequired, // effective date YYYYMMDD
  // functions
  clientsOnSelect: PropTypes.func.isRequired,
  mattersOnPageChange: PropTypes.func.isRequired,
  matterTypesOnSelect: PropTypes.func.isRequired,
  onClearSelection: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onDateListChange: PropTypes.func.isRequired,
  onRateSetBulkApplyOptionSelect: PropTypes.func.isRequired,
  onRateSetBulkApplySave: PropTypes.func.isRequired,
  onFetchClientOptions: PropTypes.func.isRequired,
  onFetchMoreClientOptions: PropTypes.func.isRequired,
  onSelectAllMattersMatchingFilters: PropTypes.func.isRequired,
  onToggleSelectAllMattersOnPage: PropTypes.func.isRequired,
  onToggleSelectMatters: PropTypes.func.isRequired,
  rateSetsOnSelect: PropTypes.func.isRequired,
  setIsRateSetModalVisible: PropTypes.func.isRequired,
  staffOnDeselectAll: PropTypes.func.isRequired,
  staffOnSelect: PropTypes.func.isRequired,
  staffOnToggle: PropTypes.func.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
};

FirmRateSets.defaultProps = {
  clientOptions: undefined,
  clientOptionsDataLoading: false,
  clientOptionsHasMore: false,
  rateSetOptions: undefined,
  matterTypes: undefined,
  selectedBulkApplyRateSetOption: undefined,
};
