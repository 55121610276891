import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';

import { getInvoiceDebtorTotalsLookup } from '@sb-billing/business-logic/split-billing';
import { toCamelCase } from '@sb-itops/camel-case';
import { error as displayErrorToUser } from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import composeHooks from '@sb-itops/react-hooks-compose';
import { fetchPostP } from '@sb-itops/redux/fetch';
import uuid from '@sb-itops/uuid';

import { subscribeToNotifications } from 'web/services/subscription-manager';

import { SplitBillingInvoicePreview } from './SplitBillingInvoicePreview';

const log = getLogger('SplitBillingInvoicePreview.container');

const hooks = () => ({
  useSplitBillingInvoicePreviewDataMap: ({ invoicePreviewData, fetchEntryDetails }) => {
    const [isDownloadingAll, setIsDownloadingAll] = useState(false);
    const invoiceClients = useMemo(() => invoicePreviewData?.invoice?.clients || [], [invoicePreviewData]);
    const debtorOptions = useMemo(
      () =>
        invoiceClients.map((client) => ({
          label: client.debtorLabel,
          value: client.contactId,
        })) || [],
      [invoiceClients],
    );

    const [selectedDebtorOption, setSelectedDebtorOption] = useState(debtorOptions[0] || {});

    useEffect(() => {
      setSelectedDebtorOption(debtorOptions[0] || {});
    }, [debtorOptions]);

    // create a lookup for getting split billing debtorRatio/invoiceNumberSuffix by debtorId
    const splitBillingDebtorLookup = useMemo(() => {
      const { splitBillingSettings } = invoicePreviewData.invoice;
      let suffixChar = 'A'.charCodeAt(0);

      return splitBillingSettings
        ? splitBillingSettings.debtors.reduce((acc, debtor) => {
            const updatedDebtor = { ...debtor, invoiceNumberSuffix: String.fromCharCode(suffixChar) }; // Create a new object with the updated property
            suffixChar += 1;
            acc[updatedDebtor.debtorId] = updatedDebtor;
            return acc;
          }, {})
        : {};
    }, [invoicePreviewData]);

    const invoiceDebtorTotalsLookup = useMemo(
      () =>
        getInvoiceDebtorTotalsLookup({
          invoiceTotals: invoicePreviewData.invoiceTotals,
          splitBillingSettings: invoicePreviewData.invoice.splitBillingSettings,
        }),
      [invoicePreviewData],
    );

    const debtorInvoicePreviewDataMap = useMemo(
      () =>
        invoiceClients.reduce((acc, contact) => {
          const contactId = contact.contactId;

          const contactInvoicePreviewData = {
            ...invoicePreviewData,
            invoice: {
              ...invoicePreviewData.invoice,
              debtors: [invoicePreviewData.invoice.debtors.find((debtor) => debtor.id === contactId)] || [],
              invoiceNumberSuffix: splitBillingDebtorLookup[contactId].invoiceNumberSuffix,
              clients: [contact],
              debtorTotals: invoiceDebtorTotalsLookup[contactId], // this property would only exists when split billing is enabled
            },
          };

          return {
            ...acc,
            [contactId]: contactInvoicePreviewData,
          };
        }, {}),
      [invoiceClients, invoiceDebtorTotalsLookup, invoicePreviewData, splitBillingDebtorLookup],
    );

    const requestClientMapping = useMemo(() => ({}), []);

    const onDownloadAll = async () => {
      try {
        setIsDownloadingAll(true);
        await Promise.all(
          Object.keys(debtorInvoicePreviewDataMap).map(async (contactId) => {
            const requestId = uuid();
            requestClientMapping[requestId] = contactId; // store the requestId and contactId mapping for the notification callback to identify the correct client
            const contactInvoicePreviewData = debtorInvoicePreviewDataMap[contactId];
            await fetchPostP({
              path: `/billing/invoice-pdf/preview/:accountId/presigned-url-notified/`,
              fetchOptions: {
                body: JSON.stringify({ ...contactInvoicePreviewData, requestId, fetchEntryDetails }),
              },
            });
          }),
        );
      } catch (error) {
        setIsDownloadingAll(false);
        log.error('Error occurred while fetching invoice PDF preview', error);
        displayErrorToUser('Error occurred while generating invoice PDF preview');
      }
    };

    useEffect(() => {
      const unsubReadyNotification = subscribeToNotifications({
        notificationIds: ['InvoicePreviewPdfReady'],
        callback: async (payload) => {
          const message = toCamelCase(JSON.parse(payload));

          // multiple users from same firm could be previewing different draft invoices
          if (requestClientMapping[message.requestId] && message.previewUrl) {
            const contactId = requestClientMapping[message.requestId];
            const invoiceNumber = debtorInvoicePreviewDataMap[contactId].invoice.invoiceNumber;
            const invoiceDebtorName = debtorInvoicePreviewDataMap[contactId].invoice.clients[0].name;
            try {
              const preSignedUrl = message.previewUrl;
              const pdfFileResponse = await fetch(preSignedUrl);
              const fileBlob = await pdfFileResponse.blob();
              FileSaver.saveAs(fileBlob, `invoice-${invoiceNumber}-${invoiceDebtorName}.pdf`);
            } catch (error) {
              log.error('Error occurred while downloading invoice PDF blob for preview: ', error);
              displayErrorToUser('Error occurred while downloading invoice PDF preview');
            } finally {
              setIsDownloadingAll(false);
            }
          }
        },
      });

      const unsubFailNotification = subscribeToNotifications({
        notificationIds: ['InvoicePreviewPdfFail'],
        callback: async (payload) => {
          const message = toCamelCase(JSON.parse(payload));

          // multiple users from same firm could be previewing different draft invoices
          if (requestClientMapping[message.requestId]) {
            log.error('Error occurred while generating invoice PDF preview on server: ', message.error);
            displayErrorToUser('Error occurred while downloading invoice PDF preview');
            setIsDownloadingAll(false);
          }
        },
      });

      const unsub = () => {
        unsubReadyNotification();
        unsubFailNotification();
      };

      return unsub;
    }, [debtorInvoicePreviewDataMap, fetchEntryDetails, requestClientMapping]);

    return {
      debtorOptions,
      debtorInvoicePreviewDataMap,
      selectedDebtorOption,
      isDownloadingAll,
      setSelectedDebtorOption,
      onDownloadAll,
    };
  },
});

export const SplitBillingInvoicePreviewContainer = composeHooks(hooks)(SplitBillingInvoicePreview);

SplitBillingInvoicePreviewContainer.displayName = 'SplitBillingInvoicePreviewContainer';

SplitBillingInvoicePreviewContainer.propTypes = {
  invoiceId: PropTypes.string,
  invoicePreviewData: PropTypes.shape({
    invoice: PropTypes.object, // invoice version
    // invoice version must also contain
    // 1. retainerRequestAmount (if applicable)
    // 2. status (label)
    // 3. clients (array of contact objects with address details)
    // 4. footer (decoded html)
    invoiceTotals: PropTypes.object, // invoice totals
    invoiceSettings: PropTypes.object, // invoice settings template
    payments: PropTypes.arrayOf(PropTypes.object), // invoice payments
    priorBalances: PropTypes.object, // supplementary table: prior balance
    statement: PropTypes.object, // supplementary tables: invoice history, account summary, transaction history
    firmDetails: PropTypes.shape({
      abn: PropTypes.string,
      acn: PropTypes.acn,
    }),
    matter: PropTypes.shape({
      billingConfiguration: PropTypes.object,
    }),
    staff: PropTypes.object, // sbFirmManagementMbService.details, should probably fix the naming once we understand what this really is for
    fees: PropTypes.arrayOf(PropTypes.object), // feeVersion with feeId & feeEarner, once BB-12895 is ON only feeId will be sent
    expenses: PropTypes.arrayOf(PropTypes.object), // expenseVersion with expenseId & expenseEarner, once BB-12895 is ON only expenseId will be sent
  }),
  fetchEntryDetails: PropTypes.bool.isRequired, // force the fetching of entry details on the server end
  showSampleIndicator: PropTypes.bool,
};

SplitBillingInvoicePreviewContainer.defaultProps = {
  showSampleIndicator: false,
};
