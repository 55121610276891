import composeHooks from '@sb-itops/react-hooks-compose';
import { useState } from 'react';
import { useMatterDocumentsHook } from 'web/react-redux/routes/billing-view-matter-documents/useMatterDocumentsHook';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import * as messageDisplay from '@sb-itops/message-display';
import { Expanded, Document, File } from 'types';
import { getLogger } from '@sb-itops/fe-logger';
import { FileDetailsDocument } from 'web/graphql/types/graphql';
import { getApolloClient } from 'web/services/apollo';
import { nonNullishIdGuard } from '@sb-itops/type-helpers';
import { sendMetric } from 'web/services/metrics';
import { AttachFilesModal } from './AttachFilesModal';

const log = getLogger('ATTACH_FILES_MODAL');

interface IAttachFilesModalContainerProps {
  matterId: string;
  conversationId: string;
  authToken: string;
  messageText: string | null;
  setAttachFilesDialogResponse?(args: {
    matterId: string;
    conversationId: string;
    messageText: string | null;
    action: 'Send' | 'Cancel';
  }): void;
  onClose(): void;
  addAsLivingFile?: boolean;
}

const hooks = ({
  matterId,
  onClose,
  messageText,
  conversationId,
  authToken,
  setAttachFilesDialogResponse,
  addAsLivingFile = false,
}: IAttachFilesModalContainerProps) => ({
  useDocumentsTabStore: () => {
    const { setDocuments, selectedFolder, documents, loading, selectedPath, createNewFolder, setSelectedPath, error } =
      useMatterDocumentsHook({
        matterId,
        hideUploadingDocs: true,
      });

    const [expanded, setExpanded] = useState({ root: true } as Expanded);

    const [message, setMessage] = useState(messageText || '');
    const [isSubmitting, setSubmitting] = useState(false);

    const lineItems: Document[] = Object.values(selectedFolder.contents).sort((a: any, b: any) => {
      if (a.type === 'file' && b.type === 'folder') {
        return 1;
      }
      if (b.type === 'file' && a.type === 'folder') {
        return -1;
      }
      return 0;
    }) as any;

    const [selection, setSelection] = useState({});

    const selectedKeys = Object.keys(selection).filter((key) => selection[key]);

    const onSelect = async ({ rowData, event }, multi) => {
      // We are only interested in the selected items
      if (rowData.type === 'folder') {
        setSelectedPath(selectedPath ? `${selectedPath}.${rowData.data.id}` : rowData.data.id);
      } else if (event.shiftKey && selectedKeys.length <= 1) {
        // Select all items between current selection and clicked item
        const initialKey = selectedKeys.length ? selectedKeys[0] : null;
        const newKey = rowData.data.id;
        let foundKeys = 0;
        setSelection(
          lineItems.reduce((acc, curr, i) => {
            if (initialKey === curr.data.id) {
              // Dont de-select the initial selection
              foundKeys += 1;
              return acc;
            }
            // Select current selection or if none are selected, select from the first item
            if (newKey === curr.data.id || (!initialKey && i === 0)) {
              foundKeys += 1;
              return { ...acc, [curr.data.id]: !selection[curr.data.id] };
            }
            if (foundKeys === 1) {
              return { ...acc, [curr.data.id]: !selection[curr.data.id] };
            }
            return acc;
          }, selection),
        );
      } else if (event.ctrlKey || event.shiftKey || multi) {
        // Shift key acts like ctrl if more than 1 item is selected
        setSelection({ ...selection, [rowData.data.id]: !selection[rowData.data.id] });
      } else {
        setSelection({ [rowData.data.id]: !selection[rowData.data.id] });
      }
    };

    return {
      isSubmitting,
      selection,
      selectedKeys,
      onSelect,
      lineItems,
      setDocuments,
      createNewFolder,
      selectedFolder,
      selectedPath,
      setSelectedPath,
      expanded,
      setExpanded,
      documents,
      loading: !!loading,
      error,
      message,
      setMessage,
      onSubmit: async () => {
        sendMetric('CommunicateAttachFiles');
        if (!setAttachFilesDialogResponse) {
          return;
        }
        if (!selectedKeys.length) {
          return;
        }
        try {
          setSubmitting(true);
          const apolloClient = getApolloClient();
          const fileDetails = await apolloClient.query({
            query: FileDetailsDocument,
            variables: { ids: selectedKeys },
            fetchPolicy: 'network-only',
          });

          const findVersion = (key: string) => {
            const details = (fileDetails.data.fileDetails || []).filter(nonNullishIdGuard);
            const found = details.find((i) => i.id === key);
            if (found && found.versions && found.versions[0]) {
              return found.versions[0];
            }
            return undefined;
          };

          let filesNotUploadedYet = false;

          const filesToAdd = selectedKeys.map((key) => {
            const file = selectedFolder.contents[key] as File;
            const fileVersion = findVersion(key);
            if (fileVersion?.isCancelled) {
              messageDisplay.error(`The upload of ${file.data.name + file.data.fileExtension} was cancelled`);
              filesNotUploadedYet = true;
              return {};
            }
            if (fileVersion?.isUploaded === false) {
              messageDisplay.error(`${file.data.name + file.data.fileExtension} has not finished uploading yet`);
              filesNotUploadedYet = true;
              return {};
            }
            return {
              id: key,
              versionId: fileVersion?.versionId,
              fileName: `${file.data.name}${file.data.fileExtension}`,
              size: file.data.sizeBytes,
              isFolder: false,
              isLivingFile: !!addAsLivingFile,
            };
          });
          if (filesNotUploadedYet) {
            setSubmitting(false);
            return;
          }

          await dispatchCommand({
            type: 'ItOps.Communicate.Messages.AddFilesToCommunicate',
            message: {
              matterId,
              conversationId,
              files: filesToAdd,
              authToken,
              message,
              source: 'AttachFilesModal',
            },
          });

          setAttachFilesDialogResponse({
            matterId,
            conversationId,
            messageText: message,
            action: 'Send',
          });
          setSubmitting(false);
          onClose();
        } catch (e: any) {
          log.error(e.message);
          messageDisplay.error('Error attaching file, please try again later');
          setSubmitting(false);
        }
      },
      onClose: () => {
        if (!setAttachFilesDialogResponse) {
          return;
        }
        setAttachFilesDialogResponse({
          matterId,
          conversationId,
          messageText: message,
          action: 'Cancel',
        });
        onClose();
      },
    };
  },
});

export const AttachFilesModalContainer = composeHooks(hooks)(AttachFilesModal);
