import { dateToInteger } from '@sb-itops/date';
import { PrintNotApplicable, printMethodsByValue, PrintNow, PrintManually } from '@sb-billing/business-logic/cheques';
import uuid from '@sb-itops/uuid';
import { sort as sortItems } from '@sb-itops/sort';
import { featureActive } from '@sb-itops/feature';
import { surchargeTypeValues, defaultSurchargeSettings } from '@sb-billing/business-logic/invoice-surcharge';
import { entryType as invoiceEntryType } from '@sb-billing/business-logic/invoice/entities';
import { encodeHtml } from '@sb-billing/encode-decode-html-invoice-settings';

// Processes form to produce an invoice object that can be saved
export const marshallInvoice = ({
  invoice,
  matter,
  selectedEntryEntities,
  formValues,
  totals,
  surchargeEnabled,
  allowOverdraw,
  supportsTtoNumbering,
  config,
  provideShowRetainerOption,
  feeTaxRate,
  merchantPaymentReference,
  splitBillingSettings,
}) => {
  const matterId = invoice?.matter?.id || matter?.id;

  const configOptions = config?.invoiceAdditionalOptions || {};
  const eInvoiceOptions = config?.eInvoiceOptions || {};
  const sectionOptions = config.sectionOptions || {};

  // ensure if show retainer setting was ticked (overridden) by user on the draft invoice screen,
  // and subsequently the minmum trust retainer is turned off for the matter. When user returns
  // to the draft invoice screen, we need to ensure show retainer setting is consistent with the
  // updated matter billing configuratin setting. This has to be this way because the overridden
  // user setting is stored in a separate redux slice and is being fetched in getCurrentInvoiceTemplate.
  // This also addresses the scenario where the draft invoice was saved with showRetainer set to true.
  if (configOptions.showRetainer && !provideShowRetainerOption) {
    configOptions.showRetainer = false;
  }

  const surcharge = formValues.surcharge;
  let discount = formValues.discount;

  if (!discount?.enabled || (!discount?.fixedDiscount && !discount?.percentage)) {
    discount = null;
  }

  const payments = (formValues.multiPayments || []).length ? formValues.multiPayments : formValues.quickPayments;
  const effectiveDate = dateToInteger(new Date());
  const draftQuickPayments = Object.values(payments || []).reduce((acc, payment) => {
    if (payment && payment.sourceAccountId && payment.amount) {
      const chequePrintActive =
        formValues.chequePrintingMethod && formValues.chequePrintingMethod !== PrintNotApplicable;
      const newPayment = {
        ...payment,
        effectiveDate,
        allowOverdraw,
        isElectronicPayment:
          payment.source === 'Trust' && formValues.chequePrintingMethod === PrintNotApplicable && supportsTtoNumbering,
        chequePrintActive,
        chequePrintMethod: chequePrintActive
          ? printMethodsByValue[formValues.chequePrintingMethod].code
          : printMethodsByValue[PrintManually].code,
      };

      if (payment.source === 'Trust' && chequePrintActive) {
        // TODO: This uuid reallocation of id everytime marshallInvoice() is called is potentially problematic
        newPayment.transferBetweenAccountsTransactionId =
          formValues.chequePrintingMethod === PrintNow ? uuid() : undefined;
        if (formValues.chequePrintingMethod === PrintManually) {
          newPayment.reference = formValues.trustChequeReference;
        }
      }

      acc.push(newPayment);
    }

    return acc;
  }, []);

  const quickPayments = {
    payments: sortItems(draftQuickPayments, ['source'], ['desc']),
    saveType: formValues.saveType,
  };

  // if the draft invoice hasnt been saved yet, then its definitely an original invoice
  const isOriginalInvoice = featureActive('BB-1761');

  const debtors = (formValues.selectedContacts || []).map((debtor, index) => {
    if (index === 0) {
      return { id: debtor.id, ratio: 1.0 }; // first debtor ratio set to 1 until we have payment splitting capability
    }
    return { id: debtor.id, ratio: 0 }; // other debtor ratio set to 0 until we have payment splitting capability
  });

  // derive feeLineItemConfiguration from feeListOrSummary && feeListAppend
  let feeLineItemConfiguration = 0;
  if (formValues.showFeesEntriesAs === 'SUMMARY') {
    feeLineItemConfiguration = formValues.feeListAppend ? 2 : 1;
  }

  // derive expenseLineItemConfiguration from expenseListOrSummary && expenseListAppend
  let expenseLineItemConfiguration = 0;
  if (formValues.showExpenseEntriesAs === 'SUMMARY') {
    expenseLineItemConfiguration = formValues.expenseListAppend ? 2 : 1;
  }

  const invoiceTitle = config.titleText;
  const invoiceTitleLine2 = config.subtitleText;

  const invoiceId = formValues.invoiceId;

  const data = {
    invoiceId,
    matterId,
    invoiceVersion: {
      invoiceId,
      matterId,
      templateId: config?.template?.id,
      invoiceTitle,
      invoiceTitleLine2,
      debtors,
      invoiceNumber: invoice?.invoiceNumber,
      issuedDate: +dateToInteger(formValues.issueDate),
      dueDate: +dateToInteger(formValues.dueDate),
      isOriginalInvoice,
      merchantPaymentReference,
      splitBillingSettings,
      entries: [],
      // These two overrides appear to be legacy parameters
      feesTotalOverride: invoice?.feesTotalOverride,
      expensesTotalOverride: invoice?.expensesTotalOverride,
      hasBeenFinalized: invoice?.hasBeenFinalized,
      discount:
        discount?.type === undefined || discount?.enabled === false
          ? undefined
          : {
              type: discount.type,
              fixedDiscount: Math.min(discount.fixedDiscount || 0, totals.feeTotal - totals.writtenOffFeeTotal),
              percentage: discount.percentage,
              applyOnlyToFees: true,
            },
      surcharge:
        !surchargeEnabled || !surcharge
          ? undefined
          : {
              type: surcharge.type,
              fixedSurcharge: surcharge.type === surchargeTypeValues.FIXED ? surcharge.fixedSurcharge || 0 : 0,
              percentageBp: surcharge.type === surchargeTypeValues.PERCENTAGE ? surcharge.percentageBp || 0 : 0,
              applyTo:
                surcharge.type !== surchargeTypeValues.NONE ? surcharge.applyTo : defaultSurchargeSettings.applyTo,
              description:
                surcharge.type !== surchargeTypeValues.NONE
                  ? surcharge.description
                  : defaultSurchargeSettings.description,
            },
      layout: {
        expenseLineItemConfiguration,
        feeLineItemConfiguration,
        showStaffNameOnEntries: !!configOptions.showStaffNameOnEntries,
        feeSummaryLineDescription: formValues.feeSummaryLineDescription,
        expenseSummaryLineDescription: formValues.expenseSummaryLineDescription,
        showNonBillableFees: !!formValues.showNonBillableFees,
        showNonBillableExpenses: !!formValues.showNonBillableExpenses,
        showDescriptionForEntries: !!configOptions.showDescriptionForEntries,
        showRateOnEntries: !!configOptions.showRateOnEntries,
        showDurationOnEntries: !!configOptions.showDurationOnEntries,
      },
      additionalOptions: {
        showInvoiceSummary: !!configOptions.showInvoiceSummary,
        showAccountSummary: !!configOptions.showAccountSummary,
        showSummaryForTimekeepers: !!configOptions.showSummaryForTimekeepers,
        showTimekeeperRole: !!configOptions.showTimekeeperRole,
        showTransactionHistory: !!configOptions.showTransactionHistory,
        hidePriorBalance: !!configOptions.hidePriorBalance,
        hidePaymentSummary: !!configOptions.hidePaymentSummary,
        showHoursSummary: !!configOptions.showHoursSummary,
        hideFeeTotal: !!configOptions.hideFeeTotal,
        hideFeeTotalSummary: !!configOptions.hideFeeTotalSummary,
        hideDueDate: !!configOptions.hideDueDate,
        hideTaxDisplay: !!configOptions.hideTaxDisplay,
        showLessFundsInTrust: featureActive('BB-6398') ? !!formValues.showLessFundsInTrust : undefined,
        showRetainer: featureActive('BB-6908') ? !!configOptions.showRetainer : undefined,
        hideAmountAndTaxPerLineItem: !configOptions.hideFeeTotal ? !!configOptions.hideAmountAndTaxPerLineItem : false,
        appendExpenseReceipts: featureActive('BB-10092') ? !!configOptions.appendExpenseReceipts : undefined,
        showItemNumbers: featureActive('BB-12394') ? !!configOptions.showItemNumbers : undefined,
      },
      sectionOptions: featureActive('BB-12385') ? sectionOptions : undefined,
      eInvoiceOptions:
        featureActive('BB-5725') && featureActive('BB-6865')
          ? {
              enableDescriptionOnDemand: !!eInvoiceOptions.enableDescriptionOnDemand,
              descriptionOnDemandLineItemText: eInvoiceOptions.descriptionOnDemandLineItemText,
            }
          : undefined,
      status: 0, // draft invoice
      feeTaxRate,
      footer: encodeHtml({ footer: config.footer }).footer,
      summary: formValues.summary,
    },
    quickPayments: quickPayments && quickPayments.payments,
    _saveType: quickPayments && quickPayments.saveType,
  };

  (selectedEntryEntities || []).forEach((entry) => {
    const isFee = entry.feeType === invoiceEntryType.FIXED || entry.feeType === invoiceEntryType.TIME;
    if (isFee) {
      data.invoiceVersion.entries.push({ type: entry.feeType, id: entry.id, feeEntity: entry });
    } else {
      data.invoiceVersion.entries.push({ type: invoiceEntryType.EXPENSE, id: entry.id, expenseEntity: entry });
    }
  });

  data.isNewInvoice = !invoice?.invoiceNumber;

  return data;
};
