import React from 'react';
import { Button, RealLoadingBar, Checkbox, Modal, Column } from '@sb-itops/react';
import classNames from 'classnames';
import { Table, utils } from '@sb-itops/react/table';
import { Documents, Expanded, Folder, Document } from 'types';
import Styles from './Modal.module.scss';
import { FileTree } from '../documents-tab/file-tree';
import { IconSelector } from '../documents-tab/icon-selector';
import folderSrc from '../../assets/Page-1.svg';

const { timestampLocalisedRenderer } = utils;
export interface ISelectFilesModalProps {
  onCancel: () => void;
  onSubmit: () => void;
  isSubmitting: boolean;
  selection: Record<string, boolean>;
  onSelect: (data: { rowData: any; event: any }, multi: boolean) => void;
  documents: Documents;
  lineItems: Document[];
  selectedFolder: Folder;
  selectedKeys: string[];
  selectedPath: string;
  setSelectedPath: (payload: string) => void;
  loading: boolean;
  expanded: Expanded;
  setExpanded: (payload: Expanded) => void;
}

const CheckboxRenderer =
  (selectedItems: Record<string, boolean>, onSelect: ISelectFilesModalProps['onSelect']) =>
  ({ rowData }) => (
    <div className={Styles.alignCenter}>
      <Checkbox
        className={Styles.checkbox}
        onChange={() => onSelect({ rowData, event: {} }, true)}
        checked={!!selectedItems[rowData.data.id]}
      />
    </div>
  );

const IconRenderer = ({ rowData }) => (
  <div className={Styles.alignCenter}>
    {rowData.type === 'folder' ? (
      <img className="icon" alt="folder" src={folderSrc} />
    ) : (
      <IconSelector isSolid extension={rowData.data.fileExtension} />
    )}
  </div>
);

const NameRenderer = ({ rowData }) => (
  <div className={Styles.item}>
    <div className={Styles.text}>{rowData.data.name}</div>
    <div className={Styles.loading}>
      <RealLoadingBar loading={rowData.loading !== undefined} progress={rowData.loading || 0} />
    </div>
  </div>
);
const DateCellRenderer = ({ rowData, dataKey }) => (
  <div>{timestampLocalisedRenderer({ cellData: rowData.data[dataKey] })}</div>
);

const DateTimeCellRenderer = ({ rowData, dataKey }) => (
  <div>{timestampLocalisedRenderer({ cellData: rowData.data[dataKey], format: 'hhmm a DD/MM/YYYY' })}</div>
);

export const SelectFilesModal = (props: ISelectFilesModalProps) => (
  <Modal
    className={Styles.modal}
    title="Select Attachments"
    body={
      <div className={Styles.container}>
        <div className={Styles.filesBrowser}>
          <FileTree
            documents={props.documents}
            expanded={props.expanded}
            setExpanded={props.setExpanded}
            setSelectedPath={props.setSelectedPath}
            selectedPath={props.selectedPath}
          />
          <Table
            className={Styles.attachFilesTable}
            list={props.lineItems}
            dataLoading={props.loading}
            onRowClick={props.onSelect}
            rowClassName={() => classNames(Styles.row)}
          >
            <Column
              key="icon"
              dataKey="icon"
              label=""
              width={34}
              cellRenderer={CheckboxRenderer(props.selection, props.onSelect)}
              disableSort
            />
            <Column key="icon" dataKey="icon" label="" width={34} cellRenderer={IconRenderer} disableSort />
            <Column disableSort key="name" dataKey="name" label="Name" cellRenderer={NameRenderer} flexGrow={2} />
            <Column
              key="dateModified"
              dataKey="dateModified"
              label="Date modified"
              cellRenderer={DateTimeCellRenderer}
              width={160}
            />
            <Column
              key="dateCreated"
              dataKey="dateCreated"
              label="Date created"
              cellRenderer={DateCellRenderer}
              width={120}
            />
          </Table>
        </div>
      </div>
    }
    footer={
      <>
        <Button
          disabled={props.loading || props.isSubmitting || props.selectedKeys.length === 0}
          size="full-width"
          onClick={() => props.onSubmit()}
          locked={props.loading}
        >
          {`Select ${props.selectedKeys.length} files`}
        </Button>
        <Button disabled={props.isSubmitting} size="full-width" onClick={props.onCancel}>
          Cancel
        </Button>
      </>
    }
    onModalClose={props.onCancel}
  />
);
