/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { BillingFrequencyTreeSelect } from '@sb-billing/react';
import { StaffSelectorListContainer } from '@sb-firm-management/react';
import { dateRangeTypes } from '@sb-itops/date';
import {
  Button,
  CollapsibleHeader,
  MessageBar,
  NumericFilter,
  PaginationSelector,
  PanelExpander,
  Spinner,
  ToggleListFilter,
  useTranslation,
} from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';

import { UnbilledMatterEntries, MatterTypeSelector, FilterExpandCollapse } from 'web/components';
import { CreateInvoicesTable } from 'web/react-redux/components/create-invoices-table';
import { DateListFilter } from 'web/react-redux';

import { BulkCreateInvoicesModal } from 'web/components/bulk-create-invoices-modal';
import Styles from './BulkCreateInvoicesRoute.module.scss';

export const BulkCreateInvoicesRoute = memo((props) => {
  const { t } = useTranslation();
  const {
    // filter panel
    expanded,
    matterFilterScope,
    toggleExpanded, // toggle-expand filter panel

    // filter values
    groupByClient,
    showAllMatters,
    minimumUnbilledAmount,
    dateListFilter,
    billingTypesSelected,
    billingTypeFilterOptions,
    billingFrequencySubTypesSelected,
    matterTypesSelected,
    matterFilterStatusSelected,
    selectedAttorneys,
    minimumTrustBalance,
    showOperatingBalanceFilter,
    minimumOperatingBalance,
    entryTypesSelected,
    entryTypeFilterOptions,

    // filter select options
    matterTypes,
    matterStatusFilterOptions,
    staffMembers,

    // filter change handlers
    resetFilters,
    setGroupByClient,
    setAllMatters,
    onChangeMinUnbilled,
    onChangeIssueDateFilter,
    onBillingTypeFilterChanged,
    onEntryTypeFilterChanged,
    onBillingFrequencyFilterChanged,
    onMatterTypeFilterChanged,
    onSelectMatterStatusFilter,
    setAttorneysFilter,
    onMinimumTrustBalanceChanged,
    onMinimumTrustBalanceCleared,
    onMinimumOperatingBalanceChanged,
    onMinimumOperatingBalanceCleared,

    // unbilled matters table
    clientMatters,
    deselectAllMatters,
    expandedClients,
    includeNonBillableItems,
    isBulkCreateInvoicesInProgress,
    isUsingContactMatterBalance,
    matters,
    mattersLoading,
    onClickLink,
    onClickRow,
    onToggleExpandClient,
    onToggleSelectMatters,
    selectedMatterIds,
    selectedMatters,

    // unbilled matter entries side panel
    matterIdToShowUnbilledEntries,
    matterEntriesLoading,
    matterBillableEntries,
    matterNonBillableEntries,
    preselectedExpenseIds,
    preselectedFeeIds,

    // bulk create modal
    closeBulkCreateInvoicesModal,
    isBulkCreateInvoicesModalVisible,
    openBulkCreateInvoicesModal,
    paymentDueDays,

    // pagination
    hidePagination,
    mattersCurrentPage,
    mattersTotalNumberOfPages,
    mattersOnPageChange,

    // sorting
    mattersOnSort,
    mattersSortBy,
    mattersSortDirection,

    // other
    onNavigateTo,
    sbAsyncOperationsService,
  } = props;

  const selectedMatterCount = Object.keys(selectedMatterIds).length;

  return (
    <div className={Styles.bulkCreateInvoicesRoute}>
      <div className={classnames('master-detail-panel', Styles.mainBody)}>
        {/* Start Filter Panel */}
        <div className={Styles.filterSidePanel}>
          <FilterExpandCollapse className="panel-filter" scope={matterFilterScope}>
            {({ hiddenFilters, onToggleFilterVisibility }) => (
              <PanelExpander
                className="panel-filter"
                onResetFilters={resetFilters}
                expanded={expanded}
                toggleExpanded={toggleExpanded}
              >
                {/* List / Group by Mode */}
                <CollapsibleHeader
                  text="List"
                  name="showList"
                  onClick={onToggleFilterVisibility}
                  collapsed={hiddenFilters.showList}
                />
                {!hiddenFilters.showList && (
                  <ul className="side-menu-list">
                    <li onClick={() => setGroupByClient(false)} className={classnames({ active: !groupByClient })}>
                      A-Z
                    </li>
                    <li onClick={() => setGroupByClient(true)} className={classnames({ active: groupByClient })}>
                      Grouped by Client
                    </li>
                  </ul>
                )}

                {/* Show all Matter or Unbilled Matter with a minimum amount */}
                <CollapsibleHeader
                  text="Show"
                  name="showAllMatters"
                  onClick={onToggleFilterVisibility}
                  collapsed={hiddenFilters.showAllMatters}
                />
                {!hiddenFilters.showAllMatters && (
                  <ul className="side-menu-list">
                    <li onClick={() => setAllMatters(true)} className={classnames({ active: showAllMatters })}>
                      All unbilled matters
                    </li>
                    <li
                      onClick={() => setAllMatters(false)}
                      className={classnames({ active: !showAllMatters, 'matter-unbilled-picker-filter': true })}
                    >
                      <NumericFilter
                        startText={`All with ${t('currencySymbol')} `}
                        endText="unbilled"
                        value={minimumUnbilledAmount}
                        onInputValueChanged={onChangeMinUnbilled}
                      />
                    </li>
                  </ul>
                )}

                <CollapsibleHeader
                  text="For Entries Dated"
                  name="restrictDate"
                  onClick={onToggleFilterVisibility}
                  collapsed={hiddenFilters.restrictDate}
                />
                {!hiddenFilters.restrictDate && (
                  <DateListFilter className="side-menu-list" onChange={onChangeIssueDateFilter} {...dateListFilter} />
                )}
                {featureActive('BB-9228') && (
                  <>
                    <CollapsibleHeader
                      text="Entry Type"
                      onClick={onToggleFilterVisibility}
                      name="entryType"
                      collapsed={hiddenFilters.entryType}
                    />
                    {!hiddenFilters.entryType && (
                      <div>
                        <ToggleListFilter
                          hideShowAllOption
                          selected={entryTypesSelected}
                          options={entryTypeFilterOptions}
                          onSelect={onEntryTypeFilterChanged}
                        />
                      </div>
                    )}
                  </>
                )}
                <CollapsibleHeader
                  text="Billing Type"
                  onClick={onToggleFilterVisibility}
                  name="billingType"
                  collapsed={hiddenFilters.billingType}
                />
                {!hiddenFilters.billingType && (
                  <div>
                    <ToggleListFilter
                      selected={billingTypesSelected}
                      options={billingTypeFilterOptions}
                      onSelect={onBillingTypeFilterChanged}
                    />
                  </div>
                )}
                {featureActive('BB-11704') && (
                  <>
                    <CollapsibleHeader
                      text="Billing Frequency"
                      onClick={onToggleFilterVisibility}
                      name="billingFrequency"
                      collapsed={hiddenFilters.billingFrequency}
                    />
                    {!hiddenFilters.billingFrequency && (
                      <BillingFrequencyTreeSelect
                        selectedBillingFrequencySubTypes={billingFrequencySubTypesSelected}
                        onSelect={onBillingFrequencyFilterChanged}
                      />
                    )}
                  </>
                )}

                {/* Matter Type Filter */}
                <CollapsibleHeader
                  text="Matter Type"
                  onClick={onToggleFilterVisibility}
                  name="matterType"
                  collapsed={hiddenFilters.matterType}
                >
                  {matterTypes ? (
                    <MatterTypeSelector
                      matterTypes={matterTypes}
                      onSelectMatterType={onMatterTypeFilterChanged}
                      selectedMatterTypeIds={matterTypesSelected}
                    />
                  ) : (
                    <Spinner className={Styles.matterTypesLoading} small />
                  )}
                </CollapsibleHeader>

                {/* Matter Status Filter */}
                <CollapsibleHeader
                  text="Matter Status"
                  onClick={onToggleFilterVisibility}
                  name="matterStatus"
                  collapsed={hiddenFilters.matterStatus}
                >
                  <ToggleListFilter
                    onSelect={onSelectMatterStatusFilter}
                    options={matterStatusFilterOptions}
                    selected={matterFilterStatusSelected}
                  />
                </CollapsibleHeader>

                {/* Attorney Responsible Filter */}
                <CollapsibleHeader
                  text={t('personResponsible')}
                  onClick={onToggleFilterVisibility}
                  name="attorneyResponsible"
                  collapsed={hiddenFilters.attorneyResponsible}
                >
                  <StaffSelectorListContainer
                    staff={staffMembers}
                    onStaffSelectionChange={setAttorneysFilter}
                    initiallySelectedStaffIds={selectedAttorneys}
                    showNoneOption
                  />
                </CollapsibleHeader>

                {/* Trust/Operating Balance Filters */}
                <CollapsibleHeader
                  text={isUsingContactMatterBalance ? 'Debtor Balances' : 'Matter Balances'}
                  name="debtorBalances"
                  onClick={onToggleFilterVisibility}
                  collapsed={hiddenFilters.debtorBalances}
                />
                {!hiddenFilters.debtorBalances && (
                  <div className={Styles.debtorBalances}>
                    <NumericFilter
                      startText={`At least ${t('currencySymbol')} `}
                      endText={`in ${t('trust')}`}
                      value={minimumTrustBalance}
                      onInputValueChanged={onMinimumTrustBalanceChanged}
                      onClearClick={onMinimumTrustBalanceCleared}
                    />
                    {showOperatingBalanceFilter && (
                      <NumericFilter
                        startText={`At least ${t('currencySymbol')} `}
                        endText={`in ${t('operating')}`}
                        value={minimumOperatingBalance}
                        onInputValueChanged={onMinimumOperatingBalanceChanged}
                        onClearClick={onMinimumOperatingBalanceCleared}
                      />
                    )}
                  </div>
                )}
              </PanelExpander>
            )}
          </FilterExpandCollapse>
        </div>
        {/* End Filter Panel */}

        <div className={classnames('panel-body', Styles.mainPanel)}>
          <div className="ribbon panel panel-primary">
            <Button onClick={() => openBulkCreateInvoicesModal()} disabled={selectedMatterCount <= 0}>
              Create {selectedMatterCount ? `(${selectedMatterCount})` : ``}
            </Button>
          </div>
          {isBulkCreateInvoicesInProgress && (
            <div className={Styles.messageBar}>
              <MessageBar messageType="warn">
                A bulk invoice request is currently being processed - this table will update with new entries on
                completion.
              </MessageBar>
            </div>
          )}
          {isBulkCreateInvoicesModalVisible && (
            <BulkCreateInvoicesModal
              isVisible={isBulkCreateInvoicesModalVisible}
              selectedMatters={selectedMatters}
              startDate={
                !dateListFilter.selectedFilter || dateListFilter.selectedFilter === dateRangeTypes.ALL
                  ? undefined
                  : dateListFilter.startDate
              }
              endDate={
                !dateListFilter.selectedFilter || dateListFilter.selectedFilter === dateRangeTypes.ALL
                  ? undefined
                  : dateListFilter.endDate
              }
              onCloseModal={closeBulkCreateInvoicesModal}
              onNavigateTo={onNavigateTo}
              deselectAllMatters={deselectAllMatters}
              paymentDueDays={paymentDueDays}
              selectedEntryTypes={entryTypesSelected}
            />
          )}
          <div className={Styles.unbilledMatters}>
            <div className={Styles.unbilledMattersTable}>
              <CreateInvoicesTable
                clientMatters={clientMatters}
                displayMode={groupByClient ? 'groupByClient' : undefined}
                matters={matters}
                mattersLoading={mattersLoading}
                expandedContacts={expandedClients}
                onClickLink={onClickLink}
                onClickRow={onClickRow}
                onCollapseToggle={onToggleExpandClient}
                onMissingLinkClick={(matterId) => {
                  onNavigateTo('home.billing.create-bill', { matterId });
                }}
                onToggleSelectMatters={onToggleSelectMatters}
                selectedMatter={matterIdToShowUnbilledEntries}
                selectedMatters={selectedMatterIds}
                sort={mattersOnSort}
                sortBy={mattersSortBy}
                sortDirection={mattersSortDirection}
                showUnbillable={includeNonBillableItems}
              />
              <PaginationSelector
                className={Styles.paginationSection}
                hidePagination={hidePagination}
                numberOfPagesDisplayed={10}
                selectedPage={mattersCurrentPage}
                totalNumberOfPages={mattersTotalNumberOfPages}
                onPageChange={mattersOnPageChange}
              />
            </div>
            {matterIdToShowUnbilledEntries &&
              matters.find((matter) => matter.matterId === matterIdToShowUnbilledEntries) && (
                <div className={Styles.unbilledMatterEntries}>
                  <UnbilledMatterEntries
                    matterId={matterIdToShowUnbilledEntries}
                    loading={matterEntriesLoading}
                    billableEntries={matterBillableEntries}
                    nonBillableEntries={matterNonBillableEntries}
                    preselectedExpenseIds={preselectedExpenseIds}
                    preselectedFeeIds={preselectedFeeIds}
                    onClickLink={onClickLink}
                    onNavigateTo={onNavigateTo}
                    sbAsyncOperationsService={sbAsyncOperationsService}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
});

BulkCreateInvoicesRoute.displayName = 'BulkCreateInvoicesRoute';
BulkCreateInvoicesRoute.propTypes = {
  // filter panel
  matterFilterScope: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired, // whether left hand filter is shown
  toggleExpanded: PropTypes.func.isRequired, // toggle filter show/hide

  // filter values
  groupByClient: PropTypes.bool.isRequired,
  showAllMatters: PropTypes.bool.isRequired,
  minimumUnbilledAmount: PropTypes.number.isRequired,
  dateListFilter: PropTypes.shape({
    // filter input values
    selectedFilter: PropTypes.string,
    before: PropTypes.number, // effective date YYYYMMDD
    from: PropTypes.number, // effective date YYYYMMDD
    to: PropTypes.number, // effective date YYYYMMDD
    // filter output values
    startDate: PropTypes.number, // effective date YYYYMMDD
    endDate: PropTypes.number, // effective date YYYYMMDD
  }).isRequired,
  billingTypesSelected: PropTypes.array,
  billingFrequencySubTypesSelected: PropTypes.array,
  matterTypesSelected: PropTypes.arrayOf(PropTypes.string),
  matterFilterStatusSelected: PropTypes.array,
  selectedAttorneys: PropTypes.array.isRequired,
  minimumTrustBalance: PropTypes.number,
  showOperatingBalanceFilter: PropTypes.bool.isRequired,
  minimumOperatingBalance: PropTypes.number,
  entryTypesSelected: PropTypes.arrayOf(PropTypes.string),

  // filter select options
  billingTypeFilterOptions: PropTypes.array.isRequired,
  matterTypes: PropTypes.arrayOf(PropTypes.object),
  matterStatusFilterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,
  staffMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      initials: PropTypes.string,
    }),
  ).isRequired,
  entryTypeFilterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,

  // filter change handlers
  resetFilters: PropTypes.func.isRequired,
  setGroupByClient: PropTypes.func.isRequired,
  setAllMatters: PropTypes.func.isRequired,
  onChangeMinUnbilled: PropTypes.func.isRequired,
  onChangeIssueDateFilter: PropTypes.func.isRequired,
  onBillingTypeFilterChanged: PropTypes.any.isRequired,
  onBillingFrequencyFilterChanged: PropTypes.func.isRequired,
  onMatterTypeFilterChanged: PropTypes.func.isRequired,
  onSelectMatterStatusFilter: PropTypes.func.isRequired,
  setAttorneysFilter: PropTypes.func.isRequired,
  onMinimumTrustBalanceChanged: PropTypes.func.isRequired,
  onMinimumTrustBalanceCleared: PropTypes.func.isRequired,
  onMinimumOperatingBalanceChanged: PropTypes.func.isRequired,
  onMinimumOperatingBalanceCleared: PropTypes.func.isRequired,
  onEntryTypeFilterChanged: PropTypes.func.isRequired,

  // unbilled matters table
  clientMatters: PropTypes.arrayOf(PropTypes.object).isRequired,
  deselectAllMatters: PropTypes.func.isRequired,
  expandedClients: PropTypes.object.isRequired,
  includeNonBillableItems: PropTypes.bool,
  isBulkCreateInvoicesInProgress: PropTypes.bool.isRequired,
  isUsingContactMatterBalance: PropTypes.bool.isRequired,
  matters: PropTypes.arrayOf(PropTypes.object).isRequired,
  mattersLoading: PropTypes.bool.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
  onToggleExpandClient: PropTypes.func.isRequired,
  onToggleSelectMatters: PropTypes.func.isRequired,
  selectedMatterIds: PropTypes.object,
  selectedMatters: PropTypes.object,

  // unbilled matter entries side panel
  matterIdToShowUnbilledEntries: PropTypes.string,
  matterEntriesLoading: PropTypes.bool.isRequired,
  matterBillableEntries: PropTypes.arrayOf(PropTypes.object).isRequired,
  matterNonBillableEntries: PropTypes.arrayOf(PropTypes.object).isRequired,
  preselectedExpenseIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  preselectedFeeIds: PropTypes.arrayOf(PropTypes.string).isRequired,

  // bulk create modal
  closeBulkCreateInvoicesModal: PropTypes.func.isRequired,
  isBulkCreateInvoicesModalVisible: PropTypes.bool,
  openBulkCreateInvoicesModal: PropTypes.func.isRequired,
  paymentDueDays: PropTypes.number.isRequired,

  // pagination
  hidePagination: PropTypes.bool.isRequired,
  mattersCurrentPage: PropTypes.number.isRequired,
  mattersOnPageChange: PropTypes.func.isRequired,
  mattersTotalNumberOfPages: PropTypes.number.isRequired,

  // sorting
  mattersOnSort: PropTypes.func.isRequired,
  mattersSortBy: PropTypes.string.isRequired,
  mattersSortDirection: PropTypes.string.isRequired,

  // other
  onNavigateTo: PropTypes.func.isRequired,
  sbAsyncOperationsService: PropTypes.object.isRequired,
};

BulkCreateInvoicesRoute.defaultProps = {
  billingTypesSelected: undefined,
  billingFrequencySubTypesSelected: [],
  includeNonBillableItems: false,
  isBulkCreateInvoicesModalVisible: false,
  matterIdToShowUnbilledEntries: undefined,
  matterTypes: undefined,
  matterTypesSelected: [],
  entryTypesSelected: [],
  matterFilterStatusSelected: undefined,
  minimumTrustBalance: undefined,
  minimumOperatingBalance: undefined,
  selectedMatterIds: {},
  selectedMatters: {},
};
