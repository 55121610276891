const { getRegion, regionType } = require('@sb-itops/region');
const facets = require('./facets');

const facetsAU = Object.freeze({
  [facets.allowAutoChargeCreditCard]: true,
  [facets.allowDuplicateCheque]: true,
  [facets.allowEmailAttachment]: true,
  [facets.allowOverdraw]: true,
  [facets.authorisedByField]: true,
  [facets.autoGeneratedReference]: true,
  [facets.bankDetailsForDirectDebit]: true,
  [facets.bankNameAndBranchNameRequiredForTrustAccount]: true,
  [facets.bankNameForDepositFunds]: true,
  [facets.bankRecCannotReconcileAcrossMultipleMonths]: true,
  [facets.blockTrustTransactionsOnClosedMatter]: true,
  [facets.BPAY]: true,
  [facets.bsbAccountNumberForBankAccounts]: true,
  [facets.businessDXAddress]: true,
  [facets.businessNumber]: true,
  [facets.checkUnpresentedChequeBalanceOnDeleteMatter]: true,
  [facets.chequeTrustPaymentTypes]: true,
  [facets.CMA]: true,
  [facets.CMAWithholdingTax]: true,
  [facets.clientCoversFeeOnDeposits]: true,
  [facets.complianceTransactionDescriptionFormatting]: true,
  [facets.complianceTransactionPaidToReversalFormatting]: true,
  [facets.complianceTransactionReasonFormatting]: true,
  [facets.contactBankBalance]: true,
  [facets.contingencyBillingType]: true,
  [facets.createPDFReceiptOnDepositDefaultSettingTrue]: true,
  [facets.creditNotePaymentReference]: true,
  [facets.eInvoiceUserDefinedSwitch]: true,
  [facets.electronicPayment]: true,
  [facets.endOfMonthReport]: true,
  [facets.integrationFeeWise]: true,
  [facets.integrationMyob]: true,
  [facets.integrationMyobInvoiceAndPayment]: true,
  [facets.integrationStripe]: true,
  [facets.integrationXero]: true,
  [facets.integrationXeroInvoice]: true,
  [facets.interest]: true,
  [facets.invoiceAttachment]: true,
  [facets.includeDescriptionInMatterDisplay]: true,
  [facets.matterBalance]: true,
  [facets.matterClientAddressRequiredForTrustDeposit]: true,
  [facets.matterClientRequiredForTrustDeposit]: true,
  [facets.matterDescriptionRequired]: true,
  [facets.matterToMatterTransferDescription]: true,
  [facets.matterTrustBalanceByDate]: true,
  [facets.overpaymentMustGoToTrust]: true,
  [facets.operatingAccountDetail]: true,
  [facets.pdfAttachment]: true,
  [facets.reasonField]: true,
  [facets.reportHeaderWithClientAddressForMatter]: true,
  [facets.reportJournalTransfersShowAuthorisedByColumn]: true,
  [facets.reportJournalTransfersShowReferenceColumn]: true,
  [facets.reportPreparedBySignature]: true,
  [facets.reportShowClientAddressesForMatter]: true,
  [facets.reportShowMatterTypeColumn]: true,
  [facets.reportShowPaidToColumn]: true,
  [facets.reportShowSeparateMatterDetailsColumns]: true,
  [facets.saveCreditCard]: true,
  [facets.showInvoiceNumbersForTransactions]: true,
  [facets.showPayToFieldForDisbursement]: true,
  [facets.showTrustBalance]: true,
  [facets.sourceAccountDetails]: true,
  [facets.stateAbbreviated]: true,
  [facets.statutoryDepositMatter]: true,
  [facets.tax]: true,
  [facets.timeKeeperInitials]: true,
  [facets.ttoDefaultDateFilters]: true,
  [facets.ttoNumbering]: true,
  [facets.transactionNumberingPrefix]: true,
  [facets.transactionsByEnteredDate]: true,
  [facets.transactionsByFilters]: true,
  [facets.trustAccountPerState]: true,
  [facets.trustLedger]: true,
  [facets.trustPaymentBankTransferType]: true,
});

const facetsGB = Object.freeze({
  [facets.allowAutoChargeCreditCard]: true,
  [facets.allowDuplicateCheque]: true,
  [facets.allowOverdraw]: true,
  [facets.authorisedByField]: true,
  [facets.autoGeneratedReference]: true,
  [facets.bankDetailsForDirectDebit]: true,
  [facets.bankNameAndBranchNameRequiredForTrustAccount]: true,
  [facets.bankNameForDepositFunds]: true,
  [facets.bankRecCannotReconcileAcrossMultipleMonths]: true,
  [facets.blockTrustTransactionsOnClosedMatter]: true,
  [facets.bsbAccountNumberForBankAccounts]: true,
  [facets.businessDXAddress]: true,
  [facets.businessNumber]: true,
  [facets.checkUnpresentedChequeBalanceOnDeleteMatter]: true,
  [facets.chequeTrustPaymentTypes]: true,
  [facets.CMA]: true,
  [facets.companyNumber]: true,
  [facets.contactBankBalance]: true,
  [facets.cookieConsent]: true,
  [facets.createPDFReceiptOnDepositDefaultSettingTrue]: true,
  [facets.creditNotePaymentReference]: true,
  [facets.displayExpenseWithFees]: true,
  [facets.eInvoiceRequiresAuthentication]: true,
  [facets.electronicPayment]: true,
  [facets.endOfMonthReport]: true,
  [facets.invoiceAttachment]: true,
  [facets.invoiceHideTaxDisplay]: true,
  [facets.includeDescriptionInMatterDisplay]: true,
  [facets.integrationFeeWise]: true,
  [facets.integrationStripe]: true,
  [facets.integrationXero]: true,
  [facets.integrationXeroInvoice]: true,
  [facets.interest]: true,
  [facets.invoiceShowPaidToSupplier]: true,
  [facets.matterBalance]: true,
  [facets.matterClientAddressRequiredForTrustDeposit]: true,
  [facets.matterClientRequiredForTrustDeposit]: true,
  [facets.matterDescriptionRequired]: true,
  [facets.matterToMatterTransferDescription]: true,
  [facets.matterTrustBalanceByDate]: true,
  [facets.overpaymentMustGoToTrust]: true,
  [facets.operatingAccountDetail]: true,
  [facets.originalInvoice]: true,
  [facets.pdfAttachment]: true,
  [facets.reasonField]: true,
  [facets.reportHeaderWithClientAddressForMatter]: true,
  [facets.reportJournalTransfersShowAuthorisedByColumn]: true,
  [facets.reportJournalTransfersShowReferenceColumn]: true,
  [facets.reportPreparedBySignature]: true,
  [facets.reportShowClientAddressesForMatter]: true,
  [facets.reportShowMatterTypeColumn]: true,
  [facets.reportShowPaidToColumn]: true,
  [facets.reportShowSeparateMatterDetailsColumns]: true,
  [facets.saveCreditCard]: true,
  [facets.showInvoiceNumbersForTransactions]: true,
  [facets.showPayToFieldForDisbursement]: true,
  [facets.showTrustBalance]: true,
  [facets.sourceAccountDetails]: true,
  [facets.tax]: true,
  [facets.timeKeeperInitials]: true,
  [facets.ttoDefaultDateFilters]: true,
  [facets.ttoNumbering]: true,
  [facets.transactionNumberingPrefix]: true,
  [facets.transactionsByFilters]: true,
  [facets.trustAccountBankFee]: true,
  [facets.trustAccountInterest]: true,
  [facets.trustLedger]: true,
});

const facetsUS = Object.freeze({
  [facets.allowAutoChargeCreditCard]: true,
  [facets.allowEmailAttachment]: true,
  [facets.autoAllocatePayments]: true,
  [facets.bulkFinalise]: true,
  [facets.canCloseTrustAccountWithWarning]: true,
  [facets.chequeMemo]: true,
  [facets.cityStateZipInReportHeader]: true,
  [facets.combineAttachment]: true,
  [facets.contingencyBillingType]: true,
  [facets.creditMemo]: true,
  [facets.eBillingSendingPreference]: true,
  [facets.deleteTransaction]: true,
  [facets.echeque]: true,
  [facets.eInvoiceUserDefinedSwitch]: true,
  [facets.finaliseWithPayments]: true,
  [facets.integrationFeeWise]: true,
  [facets.integrationLawpay]: true,
  [facets.integrationQuickBooks]: true,
  [facets.integrationXero]: true,
  [facets.interest]: true,
  [facets.leadWithNoReferenceIsBillable]: true,
  [facets.matterBalancesSettings]: true,
  [facets.matterContactBalance]: true,
  [facets.matterFieldSeparateRow]: true,
  [facets.operatingAccount]: true,
  [facets.operatingChequeVendorAddress]: true,
  [facets.operatingRetainerByFilters]: true,
  [facets.printLaterChequeOnly]: true,
  [facets.protectedFundsListingShowContactColumn]: true,
  [facets.reportAccountBalancesShowPayor]: true,
  [facets.reportAccountBalancesShowReceiptNumber]: true,
  [facets.reportCashBookPaymentsShowDescriptionColumn]: true,
  [facets.reportCashBookPaymentsShowPaymentTypeColumn]: true,
  [facets.reportHeaderSimple]: true,
  [facets.reportJournalTransfersShowDescriptionColumn]: true,
  [facets.reportShowReasonAsPartOfDescription]: true,
  [facets.saveCreditCard]: true,
  [facets.transactionReverseWarning]: true,
  [facets.trustAccountPerState]: true,
  [facets.trustContacts]: true,
  [facets.trustPaymentReasonField]: true,
  [facets.utbms]: true,
});

const regionFacets = {
  [regionType.AU]: facetsAU,
  [regionType.GB]: facetsGB,
  [regionType.US]: facetsUS,
};

const hasFacet = (facet, { forcedRegion } = {}) => {
  const isValidFacet = !!Object.values(regionFacets).map(Object.keys).flat().includes(facet);

  if (!isValidFacet) {
    throw new Error(`Facet not supported in any region: ${facet}`);
  }

  const region = forcedRegion || getRegion();

  if (!region) {
    throw new Error(`No region supplied`);
  }

  if (!regionType[region]) {
    throw new Error(`Region unsupported: ${region}`);
  }

  return !!regionFacets[region][facet];
};

module.exports = {
  hasFacet,
  facets,
};
