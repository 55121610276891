import React from 'react';
import { LinkableText, Column } from '@sb-itops/react';
import { Table } from '@sb-itops/react/table';
import { fetchGetP } from '@sb-itops/redux/fetch';
import * as messageDisplay from '@sb-itops/message-display';
import { Staff, DocumentResults } from 'types';
import { sendMetric } from 'web/services/metrics';
import Styles from './DocumentList.module.scss';
import { IconSelector } from './icon-selector';

const ROW_HEIGHT = 76;

export interface IDocumentListProps {
  entities?: DocumentResults[];
  searchMeetsMinLength: boolean;
  hideMatter?: boolean;
  loading?: boolean;
  matterNames?: Record<string, string>;
  getPersonByUserId: (id: string) => Staff;
  onClickLink?: (data: { type: string; id: string }) => void;
}

const IconRenderer = ({ rowData }) => (
  <div className={Styles.alignCenter}>
    <IconSelector isSolid extension={rowData.extension} />
  </div>
);

const regexp = /(.*?)#B#(.*?)#E#/g;

const ContentHighlightRenderer = (content: string) => {
  const element: (string | JSX.Element)[] = [];
  let index = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const m of content.matchAll(regexp)) {
    element.push(m[1]);
    element.push(
      <span key={m.index} className={Styles.highlight}>
        {m[2]}
      </span>,
    );
    index = (m.index || 0) + m[0].length;
  }
  element.push(content.slice(index, content.length));
  return element;
};
const NameRenderer = ({ rowData }) => (
  <div className={Styles.alignCenter}>
    {rowData.fileNameHighlight ? ContentHighlightRenderer(rowData.fileNameHighlight) : rowData.fileName}
  </div>
);

const MatterNameRenderer =
  (matterNames: Record<string, string>, onClickLink: (data: { type: string; id: string }) => void) =>
  ({ rowData }) => (
    <LinkableText
      text={matterNames[rowData.matterId]}
      onClickLink={() => onClickLink({ type: 'matter', id: rowData.matterId })}
      asLink
      inline
    />
  );

const customRowRenderer = ({ className, columns, index, key, style, rowData, onRowClick }) => {
  const a11yProps = { 'aria-rowindex': index + 1 };
  return (
    <div
      onClick={(event) => onRowClick({ event, index, rowData })}
      className={className}
      {...a11yProps}
      key={key}
      style={style}
    >
      <div
        style={{
          ...style,
          display: 'flex',
          height: `${rowData.contentHighlight ? ROW_HEIGHT / 2 : ROW_HEIGHT}px`,
          top: 0,
        }}
      >
        {columns}
      </div>
      {!!rowData.contentHighlight && (
        <div
          className={Styles.highlightContainer}
          style={{ ...style, height: `${ROW_HEIGHT / 2}px`, top: `${ROW_HEIGHT / 2}px`, paddingRight: '12px' }}
        >
          <div className={Styles.textContainer}>{ContentHighlightRenderer(rowData.contentHighlight || '')}</div>
        </div>
      )}
    </div>
  );
};

const onDownload = async ({ rowData }) => {
  messageDisplay.info('Your download will start shortly, please wait');
  sendMetric('DocumentsDownload');
  const {
    body: { downloadUrl },
  } = await fetchGetP({
    path: `/matter-management/matter/file/:accountId/${rowData.matterId}/${rowData.fileId}/download`,
    fetchOptions: {},
  });
  // Download the file
  const link = document.createElement('a');

  link.href = downloadUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const DownloadIconRenderer = ({ rowData }) => (
  <div
    className={Styles.alignCenter}
    onClick={(e) => {
      e.stopPropagation();
      onDownload({ rowData });
    }}
  >
    <i className="icon icon-download-2" />
  </div>
);

export const DocumentList = ({
  entities,
  getPersonByUserId,
  matterNames,
  onClickLink,
  hideMatter,
  searchMeetsMinLength,
}: IDocumentListProps) => (
  <div className={Styles.container}>
    {!searchMeetsMinLength && <div className={Styles.warning}>Searches must contain at least 3 characters</div>}
    <Table
      className={Styles.table}
      onRowClick={onDownload}
      rowHeight={ROW_HEIGHT}
      list={entities || []}
      rowRenderer={customRowRenderer}
      dataLoading={!entities}
    >
      <Column key="icon" dataKey="icon" label="" width={34} cellRenderer={IconRenderer} disableSort />
      <Column disableSort key="fileName" dataKey="fileName" label="Name" flexGrow={2} cellRenderer={NameRenderer} />
      {!hideMatter && onClickLink && (
        <Column
          key="matterId"
          dataKey="matterId"
          label="Matter"
          flexGrow={1}
          cellRenderer={MatterNameRenderer(matterNames || {}, onClickLink)}
        />
      )}
      <Column
        disableSort
        key="authorId"
        dataKey="authorId"
        label="Author"
        flexGrow={1}
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer={({ rowData }) => (
          <div className={Styles.alignCenter}>
            {rowData.authorId ? getPersonByUserId(rowData.authorId)?.name : null}
          </div>
        )}
      />
      <Column key="download" dataKey="download" label="" width={40} cellRenderer={DownloadIconRenderer} disableSort />
    </Table>
  </div>
);
