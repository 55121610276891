/* eslint-disable no-param-reassign */
import composeHooks from '@sb-itops/react-hooks-compose';
import moment from 'moment';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { FEE_MODAL_ID } from 'web/components';
import { useMemo, useState } from 'react';
import { useSubscribedLazyQuery, useCacheQuery } from 'web/hooks';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { debounce } from '@sb-itops/nodash';
import { todayAsInteger } from '@sb-itops/date';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import { MatterSummaries, InitTimers, InitTodaysFeeDuration } from 'web/graphql/queries';
import { Timers } from './Timers';
import {
  createTimerP,
  deleteTimerP,
  pauseTimerP,
  resumeTimerP,
  updateTimerDescriptionP,
  updateTimerMatterIdP,
} from './timer-api';

function getMatterTypeaheadDefaultValue({ matter }) {
  if (!matter) {
    return null;
  }

  const typeahead = [
    matter.matterNumber,
    matter.clientDisplay,
    matter.otherSideDisplay,
    matter.matterType?.name,
    matter.attorneyResponsible?.name,
    matter.attorneyResponsible?.initials,
    matter.description,
  ];
  const defaultMatterSummary = {
    ...matter,
    display: getMatterDisplay(matter, matter.matterType?.name),
    matterClientNames: matter.clientNames,
    matterStarted: matter.matterStarted ? new Date(matter.matterStarted) : undefined,
    matterStartedISO: matter.matterStarted ? moment(matter.matterStarted, 'YYYYMMDD').toISOString() : '',
    typeahead: typeahead.filter((m) => m).join(' '),
  };
  return defaultMatterSummary;
}

const hooks = () => ({
  useTimers: () => {
    const timersQuery = useCacheQuery(InitTimers.query);

    const timers = timersQuery?.data?.timers || [];

    const createTimer = () => {
      createTimerP(timersQuery);
    };

    const deleteTimer = async (timerId) => {
      deleteTimerP(timerId, timersQuery);
    };

    const pauseTimer = async (timerId) => {
      pauseTimerP(timerId, timersQuery);
    };

    const resumeTimer = async (timerId) => {
      resumeTimerP(timerId, timersQuery);
    };

    const setDescription = async (timerId, desc) => {
      updateTimerDescriptionP(timerId, desc || '', timersQuery);
    };

    const setMatter = async (timerId, matter) => {
      updateTimerMatterIdP(timerId, matter, timersQuery);
    };

    const createEntry = async (timerId) => {
      pauseTimer(timerId);
      showModal(timerId);
    };

    function showModal(timerId) {
      const timer = timers.find((t) => timerId === t.timerId);

      if (timer) {
        setModalDialogVisible({
          modalId: FEE_MODAL_ID,
          props: {
            scope: 'Timers/fee-modal',
            hideSaveAndNewButton: true,
            matterId: timer.matterId,
            fee: { duration: Math.round(timer.elapsedSeconds / 60), description: timer.description },
            preventDurationFieldSyncUpdates: true,
            onFeeSave: () => deleteTimer(timerId),
          },
        });
      }
    }

    return {
      pauseTimer,
      resumeTimer,
      deleteTimer,
      setDescription,
      setMatter,
      createEntry,
      createTimer,
      timers,
      loadingMatters: timersQuery.loading,
      defaultMatterSummaries: timers.map((t) => getMatterTypeaheadDefaultValue({ matter: t.matter })).filter(Boolean),
    };
  },
  useMatterSummaryData: () => {
    const [getMatterSummaries, matterSummariesResult] = useSubscribedLazyQuery(MatterSummaries, {
      context: { skipRequestBatching: true },
      variables: {
        includeMatterHourlyRate: false,
        filter: {
          matterStatus: ['pending', 'open'],
          rateSetDate: todayAsInteger(),
        },
        searchText: '',
        offset: 0,
        limit: 25,
        sort: {
          fieldNames: ['statusOpen', 'matterStarted'],
          directions: ['DESC', 'DESC'],
        },
      },
    });

    const results = matterSummariesResult.data?.matterSearch?.results;

    const matterSummaries = useMemo(() => {
      const summaries = !results?.length
        ? []
        : results.map((matter) => {
            const typeahead = [
              matter.matterNumber,
              matter.clientDisplay,
              matter.otherSideDisplay,
              matter.matterType?.name,
              matter.attorneyResponsible?.name,
              matter.attorneyResponsible?.initials,
              matter.description,
            ];

            const matterStartedISO = matter.matterStarted ? moment(matter.matterStarted, 'YYYYMMDD').toISOString() : '';

            return {
              ...matter,
              display: getMatterDisplay(matter, matter.matterType?.name),
              matterClientNames: matter.clientNames,
              matterStarted: matter.matterStarted ? new Date(matter.matterStarted) : undefined,
              matterStartedISO,
              typeahead: typeahead.filter((m) => m).join(' '),
            };
          });

      return summaries;
    }, [results]);

    const getMatterSummariesBySearchText = debounce(
      (searchText = '') => {
        getMatterSummaries({
          variables: {
            searchText,
            offset: 0,
          },
        });
      },
      300, // wait in milliseconds
      { leading: false },
    );

    const onFetchMatterSummaries = (searchText = '') => {
      // When the matter typeahead (Select) loses focus, it executes this
      // function with an empty string, returning different results.
      if (searchText.length > 2) {
        getMatterSummariesBySearchText(searchText);
      }

      return searchText;
    };

    const onFetchMoreMatterSummaries = async () => {
      if (!matterSummariesResult.data?.matterSearch?.pageInfo?.hasNextPage || !matterSummariesResult.fetchMore) {
        return undefined;
      }

      const fetchMoreResults = await matterSummariesResult.fetchMore({
        variables: {
          offset: matterSummariesResult.data.matterSearch.results.length || 0,
        },
      });

      return fetchMoreResults;
    };

    return {
      matterSummaries,
      matterSummariesDataLoading: matterSummariesResult.loading,
      matterSummariesHasMore: matterSummariesResult.data?.matterSearch?.pageInfo?.hasNextPage || false,
      onFetchMatterSummaries,
      onFetchMoreMatterSummaries,
    };
  },
  useFeeDurationData: () => {
    const result = useCacheQuery(InitTodaysFeeDuration.query, { variables: InitTodaysFeeDuration.variables });

    const billableDuration = result?.data?.todaysFeeDuration?.billableDuration || 0;
    const totalDuration = result?.data?.todaysFeeDuration?.totalDuration || 0;
    const nonBillableDuration = totalDuration - billableDuration;

    const feeChartData = [
      {
        // Durations are stored as minutes regardless of the firms settings
        Billable: (billableDuration / 60).toFixed(2),
        'Non-Billable': (nonBillableDuration / 60).toFixed(2),
      },
    ];

    return {
      feeChartData,
      feeChartDomain: [0, Math.max(Number(feeChartData[0].Billable), Number(feeChartData[0]['Non-Billable']))],
    };
  },
  useFilterTabs: () => {
    const [expanded, setExpanded] = useState(false);

    return {
      expanded,
      toggleExpanded: () => {
        setExpanded((state) => !state);
      },
    };
  },
  useRefresh: () => {
    const result = useCacheQuery(InitTimers.query);

    const timers = result?.data?.timers || [];

    const activeTimer = timers.find((t) => t.active);
    return {
      activeTimer: activeTimer ? { ...activeTimer } : undefined,
    };
  },
});

export const TimersContainer = withApolloClient(composeHooks(hooks)(Timers));
