import React, { useState } from 'react';
import { LinkableText, Spinner, Column } from '@sb-itops/react';
import { Table } from '@sb-itops/react/table';
import { Staff, EventResults } from 'types';
import { getStaffByPersonId } from '@sb-firm-management/redux/firm-management';
import { ADD_EVENT_MODAL_ID } from 'web/components';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { getApolloClient } from 'web/services/apollo';
import { EventData } from 'web/graphql/queries';
import Styles from './EventList.module.scss';

const ROW_HEIGHT = 76;

export interface IEventListProps {
  entities?: EventResults[];
  searchMeetsMinLength: boolean;
  hideMatter?: boolean;
  loading?: boolean;
  matterNames?: Record<string, string>;
  getPersonByUserId: (id: string) => Staff;
  onClickLink?: (data: { type: string; id: string }) => void;
}

const regexp = /(.*?)#B#(.*?)#E#/g;

const ContentHighlightRenderer = (content: string) => {
  const element: (string | JSX.Element)[] = [];
  let index = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const m of content.matchAll(regexp)) {
    element.push(m[1]);
    element.push(
      <span key={m.index} className={Styles.highlight}>
        {m[2]}
      </span>,
    );
    index = (m.index || 0) + m[0].length;
  }
  element.push(content.slice(index, content.length));
  return element;
};

const MatterNameRenderer =
  (matterNames: Record<string, string>, onClickLink: (data: { type: string; id: string }) => void) =>
  ({ rowData }) => (
    <LinkableText
      text={matterNames[rowData.matterId]}
      onClickLink={() => onClickLink({ type: 'matter', id: rowData.matterId })}
      asLink
      inline
    />
  );

const resourceIdExtractor = /Value="(.*?)" \/>\n/g;

export const EventList = ({
  entities,
  matterNames,
  onClickLink,
  hideMatter,
  searchMeetsMinLength,
}: IEventListProps) => {
  const [loadingId, setLoadingId] = useState('');

  const customRowRenderer = ({ className, columns, index, key, style, rowData, onRowClick }) => {
    const a11yProps = { 'aria-rowindex': index + 1 };
    return (
      <div
        onClick={(event) => loadingId !== rowData.eventId && onRowClick({ event, index, rowData })}
        className={className}
        {...a11yProps}
        key={key}
        style={style}
      >
        <div style={{ ...style, display: 'flex', height: `${ROW_HEIGHT / 2}px`, top: 0 }}>{columns}</div>
        <div
          className={Styles.highlightContainer}
          style={{ ...style, height: `${ROW_HEIGHT / 2}px`, top: `${ROW_HEIGHT / 2}px`, paddingRight: '12px' }}
        >
          <div className={Styles.textContainer}>{ContentHighlightRenderer(rowData.subjectHighlight || '')}</div>
          <div className={Styles.textContainer}>{ContentHighlightRenderer(rowData.locationHighLight || '')}</div>
          <div className={Styles.textContainerSmall} />
          <div className={Styles.textContainerSmall} />
        </div>
        {loadingId === rowData.eventId && (
          <div className={Styles.loadingEvent}>
            <Spinner small />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={Styles.container}>
      {!searchMeetsMinLength && <div className={Styles.warning}>Searches must contain at least 3 characters</div>}
      <Table
        className={Styles.table}
        onRowClick={async ({ rowData }) => {
          // The search result does not contain all the data we need to open the event
          const apolloClient = getApolloClient();
          setLoadingId(rowData.eventId);

          const eventData = await apolloClient.query({
            query: EventData,
            variables: { id: rowData.eventId },
          });
          setModalDialogVisible({ modalId: ADD_EVENT_MODAL_ID, props: eventData?.data?.event || {} });
          setLoadingId('');
        }}
        rowHeight={ROW_HEIGHT}
        list={entities || []}
        rowRenderer={customRowRenderer}
        dataLoading={!entities}
      >
        <Column disableSort key="subject" dataKey="subject" label="Subject" flexGrow={2} />
        <Column disableSort key="location" dataKey="location" label="Location" flexGrow={2} />
        {!hideMatter && onClickLink && (
          <Column
            key="matterId"
            dataKey="matterId"
            label="Matter"
            disableSort
            flexGrow={1}
            cellRenderer={MatterNameRenderer(matterNames || {}, onClickLink)}
          />
        )}
        <Column
          disableSort
          key="attendees"
          dataKey="attendees"
          label="Attendees"
          flexGrow={1}
          cellRenderer={({ rowData }) =>
            rowData.resourceId
              ? Array.from((rowData.resourceId || '').matchAll(resourceIdExtractor), (x: any) => x[1])
                  .map((resourceId) => getStaffByPersonId(resourceId)?.initials || '')
                  .filter(Boolean)
                  .join(', ')
              : null
          }
        />
      </Table>
    </div>
  );
};
