import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { featureActive } from '@sb-itops/feature';
import { todayAsInteger } from '@sb-itops/date';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { useTranslation } from '@sb-itops/react';
import { PAYMENT_SOURCE } from '@sb-billing/business-logic/payment-source';
import {
  getMinChargeAmountInCents,
  calculateFeeDetails,
  extractFeeSchedule,
  isFirmCardSavingEnabledForBankAccount,
  isErrorSmokeballPreChargeError,
} from '@sb-billing/business-logic/payment-provider/services';
import { getRegion } from '@sb-itops/region';
import { getLogger } from '@sb-itops/fe-logger';
import * as messageDisplay from '@sb-itops/message-display';
import { feeCoverageModes } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { subscribeToNotifications } from 'web/services/subscription-manager';
import { getAccountId, getUserId } from 'web/services/user-session-management';
import {
  createBatchClientInvoicePaymentChargeRequest,
  createSaveCardRequest,
} from '@sb-billing/business-logic/payment-provider/requests';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { InvoiceStatementAddPaymentModal, MODAL_STEP } from './InvoiceStatementAddPaymentModal';
import { invoiceStatementAddPaymentFormSchema } from './InvoiceStatementAddPaymentForm.yup';
import { marshalData } from './marshal-data';

const REGION = getRegion();
const log = getLogger('InvoiceStatementAddPaymentModal.forms.container');
const ALLOCATE_FUNDS_GROUP_NAME = 'ALLOCATE_FUNDS';

const hooks = () => ({
  useConfig: () => ({
    supportsEcheque: hasFacet(facets.echeque),
    showReasonField: hasFacet(facets.reasonField) && featureActive('BB-5508'),
  }),
  useAddPaymentForm: ({
    scope,
    onModalClose,

    activeProviderType,
    activeProviderFormattedSettings,
    operatingAccount,
    areDefaultsLoading,

    invoiceStatementLoading,
    debtorId,
    defaultDebtor,
    invoiceIds,

    paymentSourceOptions,
    hasPaymentProviderConfiguredForSource,

    invoiceSummariesLoading,
    invoiceSummaries,
    onFetchInvoiceSummaries,
  }) => {
    const { t } = useTranslation();
    const [reasonOverridden, setReasonOverridden] = useState(false);
    const [takePaymentNowDisabled, setTakePaymentNowDisabled] = useState(true);
    const [showAddPaidByContactForm, setShowAddPaidByContactForm] = useState(false);

    const addPaymentForm = useForm({
      scope,
      schema: invoiceStatementAddPaymentFormSchema,
    });
    const {
      formFields,
      formInitialised,
      formSubmitting,
      formValues,
      submitFailed,
      formValid,
      onClearForm,
      onInitialiseForm,
      onValidateForm,
      onSubmitFormWithValidation,
    } = addPaymentForm;

    // On load
    useEffect(
      () => {
        // Fetch invoice summaries for the invoiceIds
        // This is so we can use the invoice summaries when initialising the form
        if (!invoiceStatementLoading) {
          onFetchInvoiceSummaries({ invoiceIds });
        }

        // Cleanup
        return () => onClearForm();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [invoiceStatementLoading],
    );

    const paymentSourceSelected = paymentSourceOptions.find((pso) => pso?.value === formValues?.paymentSourceId);
    const totalPayments = Object.values(formValues?.payments || {}).reduce(
      (runningTotal, invoicePayment) => runningTotal + invoicePayment,
      0,
    );

    const validateForm = () => {
      const minAmountAllowed = activeProviderType
        ? getMinChargeAmountInCents({ providerType: activeProviderType, region: REGION })
        : 0;

      const validateCtx = {
        t,
        minAmountAllowed,
      };

      onValidateForm(validateCtx);
    };

    const onSetFieldValue = ({ field, value }) => {
      addPaymentForm.onFieldValueSet(field, value);
      if (submitFailed) {
        validateForm();
      }
    };
    const onUpdateFieldValues = (fieldValues) => {
      addPaymentForm.onUpdateFields(fieldValues);
      if (submitFailed) {
        validateForm();
      }
    };

    /**
     * Form initialisation
     */
    const isReadyToInitialiseForm =
      !areDefaultsLoading && !formInitialised && !invoiceSummariesLoading && !invoiceStatementLoading;

    if (isReadyToInitialiseForm) {
      const defaultValues = getDefaultValues();
      onInitialiseForm(defaultValues);
    }

    function getDefaultValues() {
      return {
        paidById: debtorId,
        // We need to store both contact id and contact itself. This is so we can run validation against the id fields and error them,
        // but still have the contact object itself so we can add it in default contact options. When we set an object in the forms,
        // it doesn't keep the field propertires (such as isInvalid, invalidReason etc.) so it is not the best for validation purposes.
        paidByContact: defaultDebtor,
        effectiveDate: todayAsInteger(),
        comment: undefined,
        amount: 0,
        reference: undefined,
        reason: undefined,
        chequeMemo: undefined,
        paymentSourceId: paymentSourceOptions.find((s) => s.isDefault)?.value,
        takePaymentNow: false,
        paymentType: 'CLIENT',
        payments: undefined,
        // take payment now fields
        saveCardDetails: false,
        paymentMethod: undefined,
      };
    }

    const updateDefaultReason = () => {
      if (reasonOverridden) {
        return;
      }
      onUpdateFieldValues({ reason: '' });
    };

    const defaultPaidByContactOptions = getContactTypeaheadDefaultOptions({
      contact: formValues.paidByContact,
    });

    const onTakePaymentNowToggled = (_, val) => {
      if (val === true) {
        const effectiveDate = todayAsInteger();
        onUpdateFieldValues({
          takePaymentNow: val,
          effectiveDate,
          reference: '',
        });
        return;
      }

      onUpdateFieldValues({ takePaymentNow: val });
    };

    const onReferenceChange = (newReference) => {
      addPaymentForm.onUpdateFields({ reference: newReference });
    };

    const onPaymentsListChange = (invoicePayments) => {
      updateDefaultReason({ paymentSource: paymentSourceSelected });
      onSetFieldValue({ field: 'payments', value: invoicePayments });
    };

    const onReasonChange = (newReason) => {
      setReasonOverridden(true);
      onUpdateFieldValues({ reason: newReason });
    };

    const onPaymentSourceChange = (newPaymentSource) => {
      const fieldsToUpdate = { paymentSourceId: newPaymentSource?.value };

      if (activeProviderType && formValues?.takePaymentNow) {
        fieldsToUpdate.reference = '';
      }

      if (activeProviderType) {
        const paymentProviderIsConfigured = hasPaymentProviderConfiguredForSource(newPaymentSource);
        if (newPaymentSource?.paymentSource === PAYMENT_SOURCE.creditCard && paymentProviderIsConfigured) {
          setTakePaymentNowDisabled(false);
        } else if (
          hasFacet(facets.echeque) &&
          newPaymentSource?.paymentSource === PAYMENT_SOURCE.eCheck &&
          paymentProviderIsConfigured
        ) {
          setTakePaymentNowDisabled(true);
          const effectiveDate = todayAsInteger();
          fieldsToUpdate.takePaymentNow = true;
          fieldsToUpdate.reference = '';
          fieldsToUpdate.effectiveDate = effectiveDate;
        } else {
          setTakePaymentNowDisabled(true);
          fieldsToUpdate.takePaymentNow = false;
        }
      }

      onUpdateFieldValues(fieldsToUpdate);
      updateDefaultReason({ paymentSource: newPaymentSource });
    };

    const onEffectiveDateChange = (newEffectiveDate) => {
      onUpdateFieldValues({ effectiveDate: newEffectiveDate });
    };

    const onSelectPaidBy = (newPaidByOption) => {
      onUpdateFieldValues({ paidById: newPaidByOption?.value });
      onSetFieldValue({
        field: 'paidByContact',
        value: contactOptionToContact(newPaidByOption),
      });
    };

    const getMarshalledData = ({ formData, providerSpecificChargeData }) =>
      marshalData({
        formData,
        paymentSourceSelected,
        operatingAccount,
        invoiceSummaries,
        activeProviderType,
        activeProviderFormattedSettings,
        providerSpecificChargeData,
      });

    const onAddPayment = async () => {
      try {
        await onSubmitFormWithValidation({
          submitFnP: async (formData) => {
            log.info('formData', formData);
            const data = getMarshalledData({ formData, providerSpecificChargeData: undefined });

            await addBatchPayment(data);

            messageDisplay.success('Payment added successfully');
            onModalClose();
          },
        });
      } catch (error) {
        log.error('Failed to save expense', error);
        messageDisplay.error('Failed to process batch payments');
      }
    };

    return {
      isModalLoading: areDefaultsLoading || !formInitialised,
      isReferenceReadOnly: !!(activeProviderType && formValues?.takePaymentNow),
      isTakePaymentNowDisabled: takePaymentNowDisabled,
      showTakePaymentNowField: !!activeProviderType,
      showPaymentPlanPaymentsWithInterestWarning: shouldShowPaymentPlanPaymentsWithInterestWarning({
        invoiceSummaries,
        payments: formValues?.payments,
      }),
      paymentSourceSelected,
      // form
      formData: formValues,
      formErrors: formFields, // Contains data related to form errors
      submitFailed,
      formSubmitting,
      formValid,
      validateForm,
      onSubmitFormWithValidation,
      balance: (formValues?.amount || 0) - totalPayments,
      // we specifically want to display errors for reference, amount, effectiveDate
      footerErrorMessages: getFooterErrorMessages({
        formValues,
        formFields,
        invoiceSummaries,
        submitFailed,
        t,
      }),
      // callbacks
      getMarshalledData,
      onUpdateFieldValues,
      onTakePaymentNowToggled,
      onPaymentsListChange,
      onReasonChange,
      onPaymentSourceChange,
      onEffectiveDateChange,
      onReferenceChange,
      onSelectPaidBy,
      onAddPayment,
      // typeaheads
      defaultPaidByContactOptions,
      // inline add contact
      showAddPaidByContactForm,
      setShowAddPaidByContactForm,
    };
  },
});

const dependentHooks = () => ({
  useTakePayment: ({
    formData: formValues,
    formSubmitting,
    formValid,
    validateForm,
    onSubmitFormWithValidation,
    activeProviderFormattedSettings,
    activeProviderType,
    operatingAccount,
    paidByContactDetailsLoading,
    onGetPaidByContactDetails,
    paidByContactDetails,
    getMarshalledData,
    firmName,
    loggedInStaffName,
    onModalClose,
    onAddPayment,
    invoiceSummariesLoading,
  }) => {
    const { t } = useTranslation();
    const [currentModalStep, setCurrentModalStep] = useState(MODAL_STEP.ADDING_PAYMENT);
    const [totalChargeAmount, setTotalChargeAmount] = useState(0); // number in modal footer
    const [triggerChargeFormSubmit, setTriggerChargeFormSubmit] = useState(false);
    const [chargeFormReadyForSubmit, setChargeFormReadyForSubmit] = useState(false);
    // Note: this data should not be used in constructing the charge when onChargeFormSubmit fires.
    // Tokenised data will be passed to onChargeFormSubmit() for that purpose.
    // This data is provided by the charge form pre-submission in case the fee calculator requires
    // knowledge related to what has been entered in the charge form, e.g. credit card type.
    const [chargeFormData, setChargeFormData] = useState();
    const [paymentFormData, setPaymentFormData] = useState();

    const isReadyToTakePayment = formValid && chargeFormReadyForSubmit && !paidByContactDetailsLoading;
    const isModalSubmitting = triggerChargeFormSubmit || formSubmitting;

    const clientIsCoveringFee = !!activeProviderFormattedSettings?.clientCoversFeeOnPayments;

    /** Charge notification handling */
    const onTakePaymentSuccess = useCallback(() => {
      messageDisplay.success(
        getTakePaymentMessage(true, {
          amount: paymentFormData?.smokeballFormData?.totalAmount,
          invoices: paymentFormData?.smokeballFormData?.invoices,
          payorDisplayName: paymentFormData?.paidByContactDetails?.displayNameFirstLast,
          source: paymentFormData?.smokeballFormData?.source,
          t,
        }),
      );

      setPaymentFormData(undefined);
      setTriggerChargeFormSubmit(false);
      onModalClose();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentFormData, t]);

    const onTakePaymentFailed = useCallback(
      ({ failureMessage }) => {
        messageDisplay.error(
          getTakePaymentMessage(false, {
            amount: paymentFormData?.smokeballFormData?.totalAmount,
            invoices: paymentFormData?.smokeballFormData?.invoices,
            payorDisplayName: paymentFormData?.paidByContactDetails?.displayNameFirstLast,
            source: paymentFormData?.smokeballFormData?.source,
            failureMessage,
            t,
          }),
        );

        setPaymentFormData(undefined);
        setTriggerChargeFormSubmit(false);
      },
      [paymentFormData, t],
    );

    useEffect(() => {
      const callback = (response) => {
        const message = JSON.parse(response);

        if (message.status !== 'FAILED') {
          onTakePaymentSuccess();
        } else {
          onTakePaymentFailed({ failureMessage: message?.smokeballResponse?.failure?.message });
        }
      };
      const unsub = subscribeToNotifications({
        notificationIds: ['PaymentProviderChargeCompleted'],
        callback,
      });
      return unsub;
    }, [onTakePaymentFailed, onTakePaymentSuccess]);

    const onModalButtonClick = async () => {
      validateForm();

      messageDisplay.dismissGroup(ALLOCATE_FUNDS_GROUP_NAME);
      const hasPayments = Object.values(formValues?.payments || {}).length > 0;

      if (!hasPayments && formValues?.takePaymentNow === true) {
        messageDisplay.warn(
          messageDisplay
            .builder()
            .text(`Please allocate funds to at least one invoice`)
            .group(ALLOCATE_FUNDS_GROUP_NAME),
        );
        // fall through so form fails and highlight fields
      }

      if (formValues.takePaymentNow === true) {
        if (currentModalStep === MODAL_STEP.TAKING_PAYMENT) {
          setTriggerChargeFormSubmit(true); // manually trigger charge for submission (onTakePayment function)
        } else if (currentModalStep === MODAL_STEP.ADDING_PAYMENT) {
          await onSubmitFormWithValidation({
            submitFnP: async (formData) => {
              onGetPaidByContactDetails(formData?.paidById);
              setCurrentModalStep(MODAL_STEP.TAKING_PAYMENT);
            },
          });
        }
      } else {
        onAddPayment();
      }
    };

    const onTakePayment = async (providerFormData) => {
      log.info(chargeFormData, providerFormData);
      try {
        await onSubmitFormWithValidation({
          submitFnP: async (formData) => {
            log.info('formData', formData);
            const smokeballFormData = getMarshalledData({ formData, providerSpecificChargeData: chargeFormData });

            const staffName = loggedInStaffName || 'firm staff member';
            const feeCoverageMode = activeProviderFormattedSettings?.clientCoversFeeOnPayments
              ? feeCoverageModes.CLIENT_PAYS
              : feeCoverageModes.FIRM_PAYS;
            setPaymentFormData({ providerFormData, smokeballFormData, paidByContactDetails });

            const isCardSavingEnabled =
              operatingAccount?.id &&
              isFirmCardSavingEnabledForBankAccount({
                formattedProviderSpecificSettings: activeProviderFormattedSettings,
                providerType: activeProviderType,
                bankAccountId: operatingAccount.id,
                bankAccountType: bankAccountTypeEnum.OPERATING,
              });

            if (isCardSavingEnabled && smokeballFormData?.saveCardDetails) {
              const savedCard = await onSaveCard({
                providerType: activeProviderType,
                saveCardFormData: { providerFormData, smokeballFormData },
              });

              if (savedCard) {
                await makeCharge({
                  providerType: activeProviderType,
                  paymentFormData: {
                    providerFormData: {
                      ...providerFormData,
                      paymentToken: {
                        ...providerFormData.paymentToken,
                        id: savedCard.token,
                      },
                    },
                    smokeballFormData,
                  },
                  staffName,
                  firmName,
                  feeCoverageMode,
                  t,
                });
              }
            } else {
              await makeCharge({
                providerType: activeProviderType,
                paymentFormData: { providerFormData, smokeballFormData },
                staffName,
                firmName,
                feeCoverageMode,
                t,
              });
            }
          },
        });
      } catch (err) {
        if (isErrorSmokeballPreChargeError(err)) {
          const errText = `The card was declined by the card issuer: ${err.message}`;
          messageDisplay.error(errText);
        } else {
          messageDisplay.error('Failed to process batch payments');
        }
        setTriggerChargeFormSubmit(false);
      }
    };

    const onChargeFormDataChange = (providerSpecificChargeData) => {
      setChargeFormData(providerSpecificChargeData);
      // Since charge form change may change fee and therefore total amount charged, we must recalculate
      if (clientIsCoveringFee) {
        const feeSchedule = clientIsCoveringFee
          ? extractFeeSchedule({
              providerType: activeProviderType,
              formattedProviderSpecificSettings: activeProviderFormattedSettings,
              bankAccountId: operatingAccount?.id,
            })
          : undefined;

        const feeDetails =
          clientIsCoveringFee &&
          calculateFeeDetails({
            providerType: activeProviderType,
            feeSchedule,
            desiredAmountInCents: formValues?.amount || 0,
            providerSpecificFields: providerSpecificChargeData,
          });

        setTotalChargeAmount(feeDetails.effectiveAmountInCents);
      }
    };

    return {
      isSubmitDisabled: shouldDisableSubmit({
        currentModalStep,
        isModalSubmitting,
        isReadyToTakePayment,
        isDataLoading: invoiceSummariesLoading,
      }),
      isSubmitLocked: isModalSubmitting,
      currentModalStep,
      totalChargeAmount,
      clientIsCoveringFee,
      onModalButtonClick,

      onChargeFormDataChange,
      onChargeFormSubmit: onTakePayment,
      onChargeFormReadyForSubmit: (isReady) => setChargeFormReadyForSubmit(!!isReady),
      triggerChargeFormSubmit,
    };
  },
});

const getTakePaymentMessage = (success, { amount, invoices, payorDisplayName, source, failureMessage, t }) => {
  const amountWithSymbol = t('cents', { val: amount });

  let message = `A payment of ${amountWithSymbol} `;

  if (invoices.length > 1) {
    message += `for ${invoices.length} invoices `;
  } else {
    message += `against invoice #${invoices[0].invoiceNumber} `;
  }

  message += success ? `was successfully charged ` : `failed to be charged `;

  message += `to ${payorDisplayName}'s ${source}. ${!success ? failureMessage || '' : ''}`;

  return message;
};

const onSaveCard = async ({ providerType, saveCardFormData }) => {
  const payorId = saveCardFormData?.smokeballFormData?.payorId;
  const bankAccountId = saveCardFormData?.smokeballFormData?.bankAccountId;
  const accountId = getAccountId();

  try {
    const newCardRequest = createSaveCardRequest({
      accountId,
      bankAccountId,
      contactId: payorId,
      providerType,
      saveCardFormData,
    });

    const path = `/billing/payment-provider/save-card/${providerType.toLowerCase()}/${accountId}/`;
    const fetchOptions = { body: JSON.stringify(newCardRequest) };
    const response = await fetchPostP({ path, fetchOptions });

    return response.body;
  } catch (err) {
    messageDisplay.error('Failed to save card details');
    throw err;
  }
};

const makeCharge = async ({ providerType, paymentFormData, staffName, firmName, feeCoverageMode, t }) => {
  const newCharge = createBatchClientInvoicePaymentChargeRequest({
    accountId: getAccountId(),
    requestedByUserId: getUserId(),
    providerType,
    paymentFormData,
    staffName,
    firmName,
    feeCoverageMode,
    t,
  });

  const path = `/billing/payment-provider/charge/${providerType.toLowerCase()}/${newCharge.accountId}/`;
  const fetchOptions = { body: JSON.stringify(newCharge) };
  const response = await fetchPostP({ path, fetchOptions });

  return response.body;
};

const addBatchPayment = async (data) => {
  const accountId = getAccountId();
  const payload = { ...data, userId: getUserId() };

  const path = `/billing/payment/${accountId}/batch`;
  const fetchOptions = { body: JSON.stringify(payload) };
  const response = await fetchPostP({ path, fetchOptions });

  return response.body;
};

const shouldDisableSubmit = ({ currentModalStep, isReadyToTakePayment, isModalSubmitting, isDataLoading }) => {
  if (currentModalStep === MODAL_STEP.TAKING_PAYMENT && !isReadyToTakePayment) {
    return true;
  }

  return isModalSubmitting || isDataLoading;
};

const getFooterErrorMessages = ({ formValues, formFields, invoiceSummaries, submitFailed, t }) => {
  const footerErrorMessages = [];
  // We want to avoid default error message when field is empty or 0, we just error it out without a message
  if (
    submitFailed &&
    Number.isFinite(formValues?.amount) &&
    formValues?.amount > 0 &&
    formFields?.amount?.invalidReason
  ) {
    footerErrorMessages.push(formFields?.amount?.invalidReason);
  }
  // We want to avoid default error message when field is empty so we show error message only when it is not
  if (submitFailed && formValues?.effectiveDate && formFields?.effectiveDate?.invalidReason) {
    footerErrorMessages.push(formFields?.effectiveDate?.invalidReason);
  }
  // This is not really error which would block submission, but rather a warning
  if (shouldShowAnticipatedDisbursementWarning({ invoiceSummaries, payments: formValues?.payments })) {
    footerErrorMessages.push(`A selected invoice contains an unpaid anticipated ${t('expense')}`);
  }

  return footerErrorMessages;
};

const shouldShowPaymentPlanPaymentsWithInterestWarning = ({ invoiceSummaries, payments }) =>
  !!(invoiceSummaries || []).find((invoiceSummary) => {
    const paymentAmount = payments?.[invoiceSummary.id];
    const hasPaymentPlan = !!invoiceSummary?.listItemProperties?.activePaymentPlanDetails;
    const unpaidExcInterest = invoiceSummary?.totals?.unpaidExcInterest || 0;
    return Number.isFinite(paymentAmount) && hasPaymentPlan && paymentAmount > unpaidExcInterest;
  });

const shouldShowAnticipatedDisbursementWarning = ({ invoiceSummaries, payments }) =>
  !!(
    featureActive('BB-9573') &&
    (invoiceSummaries || []).find((invoiceSummary) => {
      const paymentAmount = payments?.[invoiceSummary.id];
      const hasAD = !!invoiceSummary?.listItemProperties?.hasUnpaidAnticipatedDisbursements;
      return Number.isFinite(paymentAmount) && paymentAmount > 0 && hasAD;
    })
  );

const contactOptionToContact = (contactOption) => {
  if (!contactOption) {
    return undefined;
  }

  return { id: contactOption.value, displayName: contactOption.label };
};

const getContactTypeaheadDefaultOptions = ({ contact }) => {
  const options = [];

  if (contact) {
    options.push({ data: contact, label: contact.displayNameFull || contact.displayName, value: contact.id });
  }

  return options;
};

export const InvoiceStatementAddPaymentModalFormsContainer = composeHooks(hooks)(
  composeHooks(dependentHooks)(InvoiceStatementAddPaymentModal),
);

InvoiceStatementAddPaymentModalFormsContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  contactId: PropTypes.string,
  onClickLink: PropTypes.func,
  onModalClose: PropTypes.func.isRequired,
  activeProviderType: PropTypes.string,
  activeProviderFormattedSettings: PropTypes.object.isRequired,
  operatingAccount: PropTypes.object.isRequired,
  areDefaultsLoading: PropTypes.bool.isRequired,
  defaultContact: PropTypes.object,

  paidByContactDetailsLoading: PropTypes.bool.isRequired,
  onGetPaidByContactDetails: PropTypes.func.isRequired,
  paidByContactDetails: PropTypes.object,
  firmName: PropTypes.string,
  loggedInStaffName: PropTypes.string,

  // payment source
  paymentSourceOptions: PropTypes.arrayOf(PropTypes.object),
  hasPaymentProviderConfiguredForSource: PropTypes.func.isRequired,
  // invoice summaries
  invoiceSummariesLoading: PropTypes.bool.isRequired,
  invoiceSummaries: PropTypes.arrayOf(PropTypes.object),
  onFetchInvoiceSummaries: PropTypes.func.isRequired,
};

InvoiceStatementAddPaymentModalFormsContainer.defaultProps = {
  contactId: undefined,
  onClickLink: () => {},
  activeProviderType: undefined,
  defaultContact: undefined,
  firmName: undefined,
  loggedInStaffName: undefined,
  invoiceSummaries: undefined,
  paidByContactDetails: undefined,
};

InvoiceStatementAddPaymentModalFormsContainer.displayName = 'InvoiceStatementAddPaymentModalFormsContainer';
