import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import NavTab from 'web/react-redux/components/nav-tabs/NavTab';
import * as Styles from './NavTabs.module.scss';

const NavTabs = ({
  tabsInfo,
  tooltipOpen,
  showTooltip,
  hideTooltip,
  contextOpen,
  showContext,
  hideContext,
  menuOptions,
}) =>
  Object.keys(tabsInfo).length > 0 && (
    <div className={classnames('left', Styles.sbHeaderTabs)}>
      <div className={classnames('nav', Styles.navTabs, Styles.uiRefresh)}>
        {Object.keys(tabsInfo).map((tabId) => {
          const { id, primaryLabel, secondaryLabel, tooltip, kbd, icon, isActive, onNavigateToTab, onCloseTab } =
            tabsInfo[tabId];

          return (
            <NavTab
              key={tabId}
              id={id}
              primaryLabel={primaryLabel}
              secondaryLabel={secondaryLabel}
              tooltip={tooltip}
              kbd={kbd}
              icon={icon}
              isActive={isActive}
              onNavigateToTab={onNavigateToTab}
              onCloseTab={onCloseTab}
              isTooltipOpen={tooltipOpen[tabId] === true}
              showTooltip={() => showTooltip(tabId)}
              hideTooltip={() => hideTooltip(tabId)}
              isContextOpen={contextOpen[tabId] === true}
              showContext={(e) => showContext(e, tabId)}
              hideContext={() => hideContext(tabId)}
              menuOptions={menuOptions}
            />
          );
        })}
      </div>
    </div>
  );

NavTabs.displayName = 'NavTabs';

NavTabs.propTypes = {
  tabsInfo: PropTypes.object.isRequired,
  tooltipOpen: PropTypes.object.isRequired,
  showTooltip: PropTypes.func.isRequired,
  hideTooltip: PropTypes.func.isRequired,
  contextOpen: PropTypes.object.isRequired,
  showContext: PropTypes.func.isRequired,
  hideContext: PropTypes.func.isRequired,
  menuOptions: PropTypes.array.isRequired,
};

export default NavTabs;
