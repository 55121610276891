import React from 'react';
import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';
import { LinkableText } from '@sb-itops/react';

const { amountCellLocalisedRenderer } = utils;

const PaymentsTable = React.memo(
  ({ payments, onSort, sortDirection, sortBy, showPayor, onClickLink, onNavigateAway, showInvoice }) => {
    const paidByCellRenderer = ({ rowData }) => {
      const paidBy = rowData?.paidBy;
      return (
        <LinkableText
          text={paidBy?.displayName || 'unknown'}
          onClickLink={() => {
            onClickLink({ type: 'contact', id: paidBy?.id });
            onNavigateAway();
          }}
          asLink
          inline
        />
      );
    };

    const invoiceCellRenderer = ({ rowData }) => {
      const invoice = rowData?.invoice;
      return (
        <LinkableText
          text={`#${invoice?.invoiceNumber}`}
          onClickLink={() => {
            onClickLink({ type: 'invoice', id: invoice?.id });
            onNavigateAway();
          }}
          asLink
          inline
        />
      );
    };

    return (
      <Table list={payments} sort={onSort} sortDirection={sortDirection} sortBy={sortBy}>
        {showInvoice && <Column dataKey="invoice" label="Invoice" flexGrow={1} cellRenderer={invoiceCellRenderer} />}
        {showPayor && <Column dataKey="paidBySort" label="Paid By" flexGrow={2} cellRenderer={paidByCellRenderer} />}
        <Column dataKey="source" label="Source" flexGrow={1} />
        <Column
          className="right-align"
          dataKey="amount"
          label="Amount"
          width={130}
          cellRenderer={amountCellLocalisedRenderer}
          footerRenderer={amountCellLocalisedRenderer}
        />
      </Table>
    );
  },
);

PaymentsTable.propTypes = {
  payments: PropTypes.array,
  onSort: PropTypes.func,
  sortDirection: PropTypes.string,
  sortBy: PropTypes.string,
  showPayor: PropTypes.bool,
  showInvoice: PropTypes.bool,
  onClickLink: PropTypes.func,
  onNavigateAway: PropTypes.func,
};

PaymentsTable.defaultProps = {
  payments: [],
  onSort: () => {},
  onClickLink: () => {},
  onNavigateAway: () => {},
  sortDirection: undefined,
  sortBy: undefined,
  showPayor: false,
  showInvoice: false,
};

PaymentsTable.displayName = 'PaymentsTable';

export default PaymentsTable;
