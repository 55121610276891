import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useState, useEffect } from 'react';
import {
  deleteContact,
  fetchById as fetchContactById,
  getById as getContactById,
  restoreDeletedContact,
} from '@sb-customer-management/redux/contacts';
import { getContactDisplay, getById as getContactSummaryById } from '@sb-customer-management/redux/contacts-summary';
import { GoogleMarkerFromAddress } from 'web/components';
import { setModalDialogVisible, setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { useSelector } from 'react-redux';
import { featureActive } from '@sb-itops/feature';
import { useIsMounted } from '@sb-itops/react';

import { BillingViewContactDetailsRoute } from './BillingViewContactDetailsRoute';
import { extractAddresses } from './extractAddresses';

const deleteContactModalId = 'delete-contact-confirmation';
const restoreContactModalId = 'restore-contact-confirmation';

// fetchContactById = getting contact ENTITY (not summary) from API
async function getOrganisationEntities(contact) {
  let contactOrganizationEntities = [];
  // check both for backwards compatability
  if (contact?.representativeOfs?.length) {
    contactOrganizationEntities = contact.representativeOfs.map((id) => fetchContactById(id));
  } else if (contact?.representativeOf) {
    contactOrganizationEntities.push(fetchContactById(contact.representativeOf));
  }
  return Promise.all(contactOrganizationEntities);
}

function getRepresentativeOfEntities(contact) {
  let contactOrganizationEntities = [];

  // new format
  if (contact?.representativeOfs?.length) {
    contactOrganizationEntities = contact.representativeOfs.map((id) => getContactSummaryById(id));
  } else if (contact?.representativeOf) {
    // backwards compatible
    contactOrganizationEntities.push(getContactSummaryById(contact.representativeOf));
  }
  return contactOrganizationEntities;
}

const hooks = ({ contactId, onClickLink }) => ({
  useState: () => {
    // This hook needs to rerender when redux updates. useSelector is used to achieve this, even if it is not necessary for getContactSummaryById
    const rawContact = useSelector(() => getContactSummaryById(contactId)) || {};
    const isMounted = useIsMounted();

    const contactCard = {
      ...rawContact,
      id: rawContact.entityId,
      displayName: getContactDisplay(rawContact?.id || rawContact?.entityId, {
        hideDeletedStatus: featureActive('BB-7912'),
      }),
      phone: rawContact?.phone || rawContact?.phone2,
      type: rawContact?.type ? rawContact?.type.toLowerCase() : '',
      representativeOfs: getRepresentativeOfEntities(rawContact).reduce((acc, org) => {
        if (org) {
          // We only need a subset of the properties for any organisation
          acc.push({
            id: org.entityId,
            displayName: org.displayName,
          });
        }
        return acc;
      }, []),
    };

    const [activeAddressType, setActiveAddressType] = useState();

    const contactEntity = useSelector(() => getContactById(contactId) || {});

    useEffect(
      () => {
        // React doesnt allow async hooks
        (async () => {
          const contactDetailsEntity = (await fetchContactById(contactId)) || {};
          const initialContactRelatedEntities = {
            ...contactDetailsEntity,
            representativeOfs: await getOrganisationEntities(rawContact),
          };
          // Cannot useState on an unmounted component
          if (isMounted?.current) {
            setActiveAddressType(extractAddresses(initialContactRelatedEntities)?.[0]?.type);
          }
        })();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [contactId],
    );

    // Selectors such as getContactSummaryById must be wrapped in useSelector otherwise
    // they will only run when the container is rendered/re-rendered.
    // While we use selectors for the main contact, we also need to subscribe to any
    // changes to the entities in contactEntity.people
    const contactCards = useSelector(() => [
      contactCard,
      ...(contactEntity.people?.map((person) => {
        const { cell, email, phone, phone2, removed } = getContactSummaryById(person.entity.id) || {};

        return {
          displayName: getContactDisplay(person.entity.id, {
            hideDeletedStatus: featureActive('BB-7912'),
          }),
          phone,
          phone2,
          cell,
          email,
          id: person.entity.id,
          type: 'person',
          removed,
        };
      }) || []),
    ]);

    const addresses = extractAddresses(contactEntity);
    const activeAddress =
      addresses.find((address) => address.type === activeAddressType) ||
      (addresses.length > 0 ? addresses[0] : undefined); // On opdate where an address is added when there was none, there is no active address type, just take first

    return {
      contactCards,
      addresses,
      activeAddress,
      activeAddressType,
      setActiveAddressType,
    };
  },
  useModals: () => {
    const [editContactId, setEditContactId] = useState('');
    const [showContactModal, setShowContactModal] = useState(false);
    const [deleteContactId, setDeleteContactId] = useState('');
    const [restoreContactId, setRestoreContactId] = useState('');

    return {
      editContactId,
      setEditContactId,
      showContactModal,
      setShowContactModal,
      deleteContactModalId,
      onDeleteContact: () => {
        setModalDialogHidden({ modalId: deleteContactModalId });
        deleteContact(deleteContactId);
        setDeleteContactId('');

        // If the contact who we are viewing was deleted, navigate back to the contact list
        if (deleteContactId === contactId) {
          onClickLink({ type: 'contactList' });
        }
      },
      showDeleteModal: (id) => {
        setModalDialogVisible({ modalId: deleteContactModalId });
        setDeleteContactId(id);
      },
      restoreContactModalId,
      onRestoreContact: () => {
        setModalDialogHidden({ modalId: restoreContactModalId });
        restoreDeletedContact(restoreContactId);
        setRestoreContactId('');
      },
      showRestoreModal: (id) => {
        setModalDialogVisible({ modalId: restoreContactModalId });
        setRestoreContactId(id);
      },
    };
  },
});

// Need to wrap the route in a Redux Provider in order for the child components to access the store
export const BillingViewContactDetailsRouteWithReduxEntitiesContainer = withReduxProvider(
  composeHooks(hooks)(GoogleMarkerFromAddress(BillingViewContactDetailsRoute)),
);

BillingViewContactDetailsRouteWithReduxEntitiesContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  contactId: PropTypes.string.isRequired,
};

BillingViewContactDetailsRouteWithReduxEntitiesContainer.defaultProps = {};
