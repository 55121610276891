import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LinkableText, Modal, Button, buttonTypes } from '@sb-itops/react';
import { TriangleExclamation } from 'web/components/icons';
import classnames from 'classnames';

import Styles from './Message.module.scss';

export const MessageConnection = ({ onReconnectClick, integrationName }) => {
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <>
      <div className={classnames(Styles.messageContainer, Styles.warning)}>
        <TriangleExclamation fill="#8a6d3b" />
        &nbsp; There seems to be a connection issue.&nbsp;
        <LinkableText
          text={`Reconnect to ${integrationName}`}
          onClickLink={() => {
            setModalOpened(true);
          }}
          asLink
        />
      </div>
      {modalOpened && confirmReconnectModal({ onReconnectClick, integrationName, setModalOpened })}
    </>
  );
};

const confirmReconnectModal = ({ integrationName, onReconnectClick, setModalOpened }) => (
  <Modal
    className={Styles.messageModal}
    isVisible
    title={`Reconnect to ${integrationName}`}
    body={
      <div className={Styles.modalBody}>
        <p>
          {`When you reconnect to ${integrationName}, you may be asked to login to your ${integrationName} account. Ensure you have login access to your ${integrationName} account before continuing.`}
        </p>
      </div>
    }
    footer={
      <div className={Styles.modalFooter}>
        <Button
          type={buttonTypes.primary}
          onClick={() => {
            setModalOpened(false);
            onReconnectClick();
          }}
        >
          Continue
        </Button>
        <Button
          type={buttonTypes.secondary}
          onClick={() => {
            setModalOpened(false);
          }}
        >
          Close
        </Button>
      </div>
    }
    onModalClose={() => {
      setModalOpened(false);
    }}
  />
);

MessageConnection.displayName = 'MessageConnection';

MessageConnection.propTypes = {
  onReconnectClick: PropTypes.func.isRequired,
  integrationName: PropTypes.string.isRequired,
};
MessageConnection.defaultProps = {};
