import React from 'react';
import { SortIndicator } from 'react-virtualized';
import classnames from 'classnames';
import { Translation } from '../localisation';

export function headerCellRenderer({ label, dataKey, sortBy, sortDirection, disableSort, className }) {
  const classNames = classnames(
    sortBy === dataKey && 'sorter-column',
    disableSort && 'non-sortable-column',
    'header-cell-inner',
    className,
  );

  return (
    <div className={classNames} title={label}>
      <span className="header-label">{label}</span>
      <span>{sortBy === dataKey && <SortIndicator sortDirection={sortDirection} />}</span>
    </div>
  );
}

// props should contain one of ts|yyyymmdd|date and optionally format
export const dateLocalisedRenderer = (props) => <Translation>{(t) => t('date', { ...props })}</Translation>;
export const yyyymmddLocalisedRenderer = ({ cellData, format = 'DD/MM/YYYY' }) =>
  cellData ? <Translation>{(t) => t('date', { yyyymmdd: cellData, format })}</Translation> : null;
export const timestampLocalisedRenderer = ({ cellData, format = 'DD/MM/YYYY' }) =>
  cellData ? <Translation>{(t) => t('date', { ts: cellData, format })}</Translation> : null;
export const unixLocalisedRenderer = ({ cellData, format = 'DD/MM/YYYY' }) =>
  cellData ? <Translation>{(t) => t('date', { unix: cellData, format })}</Translation> : null;

/**
 * evaluates the condition function passed and executes the render with the arguments passed by the Table component
 * @param {function} condition function that will be executed with cellData and rowData
 * @param {function} tableRenderer function that will be executed with cellData and rowData if condition return true
 * @returns {any} whatever the renderer returns
 */
export function conditionalRenderer(condition, tableRenderer) {
  return ({ cellData, rowData }) => condition({ cellData, rowData }) && tableRenderer({ cellData, rowData });
}

// shows nothing or the absolute value of the amount (2dp)
export const amountCellLocalisedRenderer = ({ cellData }) => (
  <Translation>{(t) => (!cellData ? '' : t('cents', { val: cellData, absolute: true }))}</Translation>
);

// shows 0 or the signed value of the amount (2dp)
export const balanceCellLocalisedRenderer = ({ cellData, classNames = '' }) => (
  <Translation>
    {(t) => <div className={classNames}>{t('cents', { val: cellData || 0, absolute: false })}</div>}
  </Translation>
);

export function tickRenderer({ cellData }) {
  return cellData ? (
    <div className="center-align">
      <i className="billable-icon" />
    </div>
  ) : (
    ''
  );
}

export function statusCellRenderer({ cellData }) {
  return cellData.charAt(0).toUpperCase() + cellData.slice(1).toLowerCase();
}

export function durationCellRenderer({ cellData }) {
  return !cellData ? '' : (cellData / 60).toFixed(2);
}

export const amountCellLocalisedRendererWithWaived = ({ cellData, rowData }) => (
  <Translation>
    {(t) =>
      rowData.waived ? (
        <span className="amount--waived">{t('cents', { val: cellData, absolute: true })}</span>
      ) : (
        t('cents', { val: cellData, absolute: true })
      )
    }
  </Translation>
);

export const amountCellLocalisedRendererWithZero = ({ cellData }) => (
  <Translation>{(t) => t('cents', { val: cellData, absolute: true })}</Translation>
);

/**
 * Wrapper for cell with Checkbox only.
 * Purpose of this wrapper is to wrap checkbox in label which spans in the entire cell
 * and therefore click in the cell changes checkbox state instead of invoking onRowClick
 */
export const checkboxCellWrapperRenderer = (CheckboxComponent) => (props) => (
  <div className="checkbox-cell-wrapper" onClick={(e) => e.stopPropagation()}>
    <label>
      <CheckboxComponent {...props} />
    </label>
  </div>
);
