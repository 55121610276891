import React, { useState, useEffect } from 'react';
import { useSubscribedQuery } from 'web/hooks';
import { fetchGetP } from '@sb-itops/redux/fetch';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { useTranslation } from '@sb-itops/react';

import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import {
  InitialCalendarSyncStatus,
  MicrosoftIdentity,
  CalendarSyncSettings,
  MicrosoftCalendars,
} from 'web/graphql/queries';
import * as messageDisplay from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import { subscribeToNotifications } from 'web/services/subscription-manager';
import { sendMetric } from 'web/services/metrics';
import { CalendarSettings } from './CalendarSettings';

const log = getLogger('calendar_sync_settings');

const CALENDAR_SCOPE = 'calendar';

let win: Window | null = null;

const Container = () => {
  const [microsoftAccountNameFetch, setMicrosoftAccountName] = useState('');
  const [isSyncing, setIsSyncing] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState('');
  const calendarSyncResults = useSubscribedQuery(CalendarSyncSettings, {
    onCompleted: ({ calendarSyncSettings }) => {
      if (disableForm && !!calendarSyncSettings?.microsoftCalendarId) {
        messageDisplay.success('Your calendar has been enabled.');
      } else if (disableForm && !calendarSyncSettings?.microsoftId) {
        messageDisplay.success('Your M365 account has been disabled.');
      } else if (disableForm && !calendarSyncSettings?.microsoftCalendarId) {
        messageDisplay.success('Your calendar has been disabled.');
      }
      setSelectedCalendar(calendarSyncSettings?.microsoftCalendarId);
      // Activate form again after save completes
      setDisableForm(false);
    },
    // Required to trigger onCompleted if result is the same
    notifyOnNetworkStatusChange: true,
  });
  const microsoftIdentityResults = useSubscribedQuery(MicrosoftIdentity, {
    notifyOnNetworkStatusChange: true,
  });
  const initialCalendarSyncStatusResults = useSubscribedQuery(InitialCalendarSyncStatus, {});

  useEffect(() => {
    const newIsSyncing = initialCalendarSyncStatusResults?.data?.initialCalendarSyncStatus?.status === 1;
    if (!newIsSyncing && isSyncing) {
      messageDisplay.success('Sync complete.');
    }
    setIsSyncing(newIsSyncing);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCalendarSyncStatusResults?.data?.initialCalendarSyncStatus?.status]);

  // subscribe to CalendarSyncSettingsNotifications.MicrosoftCalendarAuthenticationFailed for toast and cancelling progress
  const { t } = useTranslation();

  useEffect(() => {
    const unsub = subscribeToNotifications({
      notificationIds: ['CalendarSyncSettingsNotifications.MicrosoftCalendarAuthenticationFailed'],
      callback: () => {
        setInProgress(false);
        setDisableForm(false);
        messageDisplay.error(`${t('authorisation')} not successful. Please try again.`);
      },
    });
    return unsub;
  }, []);

  const microsoftCalendarResults = useSubscribedQuery(MicrosoftCalendars, {
    notifyOnNetworkStatusChange: true,
  });

  const microsoftAccountName =
    calendarSyncResults?.data?.calendarSyncSettings?.microsoftAccountDisplayDetails || microsoftAccountNameFetch;
  const isAuthed =
    microsoftIdentityResults?.data?.microsoftIdentity?.microsoftId &&
    microsoftIdentityResults?.data?.microsoftIdentity?.authorizedScopes?.includes?.(CALENDAR_SCOPE);

  // Close window if we detect the auth is done
  useEffect(() => {
    if (win && isAuthed) {
      win.close();
      win = null;
      setInProgress(false);
    }
    if (isAuthed) {
      // Use endpoint to fetch details
      const ENDPOINT = `/itops/authorisation/microsoft/:accountId/getUserDisplay?microsoftAccountId=${microsoftIdentityResults?.data?.microsoftIdentity?.microsoftId}`;

      fetchGetP({
        path: ENDPOINT,
      })
        .then((response) => {
          setMicrosoftAccountName(response.body);
        })
        .catch(() => {});
    }
  }, [isAuthed]);

  const calendars = (microsoftCalendarResults?.data?.microsoftCalendars || []).filter((calendar) => !!calendar.canEdit);

  const onAuthorise = async () => {
    setInProgress(true);
    win = window.open('about:blank', '_blank');

    try {
      const ENDPOINT = '/itops/authorisation/microsoft/:accountId/getAuthURL';
      const response = await fetchGetP({
        path: ENDPOINT,
      });

      if (win) {
        win.location = response.body;
        win.focus();
      }

      // Detect window close so we can refetch
      const timer = setInterval(() => {
        if (!win) {
          clearInterval(timer);
        } else if (win.closed) {
          // Window was manually closed
          win = null;
          clearInterval(timer);
          microsoftIdentityResults.refetch();
          setInProgress(false);
        }
      }, 1000);
    } catch (e) {
      if (win) {
        win.close();
        win = null;
        setInProgress(false);
      }
      log.error(e);
    }
  };
  const onDisableCalendar = async () => {
    setDisableForm(true);
    const previousValue = calendarSyncResults?.data?.calendarSyncSettings?.microsoftCalendarId;

    try {
      await dispatchCommand({
        type: 'Calendaring.ManageCalendars.Messages.Commands.SaveMicrosoftCalendarSyncSettings',
        message: {
          isSyncEnabled: false,
          microsoftAccountId: microsoftIdentityResults?.data?.microsoftIdentity?.microsoftId,
          microsoftCalendarId: null,
          microsoftAccountDisplayDetails: microsoftAccountName || null,
        },
      });

      // Form not reenabled until graphql call completes in onCompleted callback of CalendarSyncSettings
    } catch (e: any) {
      messageDisplay.error('Failed to save calendar');
      setSelectedCalendar(previousValue);
      setDisableForm(false);
    }
  };
  const onDeauthorise = async () => {
    setDisableForm(true);
    await dispatchCommand({
      type: 'Calendaring.ManageCalendars.Messages.Commands.SaveMicrosoftCalendarSyncSettings',
      message: {
        isSyncEnabled: false,
        microsoftAccountId: null,
        microsoftCalendarId: null,
        microsoftAccountDisplayDetails: null,
      },
    });
    const ENDPOINT = '/itops/authorisation/microsoft/:accountId/deauth';

    try {
      await fetchGetP({
        path: ENDPOINT,
        responseType: 'text',
      });
    } catch (e) {
      messageDisplay.error(
        `De-${t('authorisation')} not successful. Please try again and contact support if the problem persists.`,
      );
      log.error(e);
    }
  };
  const changedForm = selectedCalendar !== calendarSyncResults?.data?.calendarSyncSettings?.microsoftCalendarId;

  const onSave = async () => {
    if (disableForm || !changedForm) {
      return;
    }
    sendMetric('CalendarSyncSave');
    setDisableForm(true);
    setIsSyncing(true);
    const previousValue = calendarSyncResults?.data?.calendarSyncSettings?.microsoftCalendarId;

    try {
      await dispatchCommand({
        type: 'Calendaring.ManageCalendars.Messages.Commands.SaveMicrosoftCalendarSyncSettings',
        message: {
          isSyncEnabled: true,
          microsoftAccountId: microsoftIdentityResults?.data?.microsoftIdentity?.microsoftId,
          microsoftCalendarId: selectedCalendar,
          microsoftAccountDisplayDetails: microsoftAccountName,
        },
      });
      await dispatchCommand({
        type: 'Calendaring.ManageCalendars.Messages.Commands.SaveMicrosoftCalendarInitialSyncStatus',
        message: {
          runningTotalMessageCount: 0,
          runningProcessedMessageCount: 0,
          status: 1,
        },
      });

      // Form not reenabled until graphql call completes in onCompleted callback of CalendarSyncSettings
    } catch (e: any) {
      messageDisplay.error('Failed to save calendar');
      setSelectedCalendar(previousValue);
      setDisableForm(false);
    }
  };
  const onCancel = async () => {
    if (win) {
      win.close();
      win = null;
    }
    setInProgress(false);
  };

  return (
    <CalendarSettings
      {...{
        disableForm: disableForm || isSyncing,
        loading: microsoftIdentityResults.loading || initialCalendarSyncStatusResults.loading,
        isSyncing,
        calendarLoading: calendarSyncResults.loading || microsoftCalendarResults.loading,
        microsoftAccountName,
        refreshCalendars: microsoftCalendarResults.refetch,
        calendars,
        selectedCalendar,
        changedForm,
        setSelectedCalendar,
        isAuthed,
        inProgress,
        onDisableCalendar,
        onDeauthorise,
        onAuthorise,
        onSave,
        onCancel,
      }}
    />
  );
};

export const CalendarSettingsContainer = withApolloClient(Container);
