import { subscribeToNotifications } from 'web/services/subscription-manager';
import { getLogger } from '@sb-itops/fe-logger';

const log = getLogger('services.subscription');

export const waitForProductIdUpdatedNotification = async ({ timeoutMs = 5000 } = {}) =>
    new Promise((resolve) => {
        let unsub;
        const timeoutId = setTimeout(() => {
            if (unsub) {
            unsub();
            }
            log.warn(`Timed out waiting for notifications: productIdUpdatedReceived`);
            resolve();
        }, timeoutMs);
        unsub = subscribeToNotifications({
            notificationIds: ['ProductRegistrationNotifications.ProductIdUpdated'],
            callback: () => {
            clearTimeout(timeoutId);
            if (unsub) {
                unsub();
            }
            resolve();
            },
        });
    });