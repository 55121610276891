import { gql } from '@apollo/client';
import { expenseEntityFragment } from '../fragments/expenseEntityFragment.graphql';
import { feeEntityFragment } from '../fragments/feeEntityFragment.graphql';
import { invoiceTemplateFragment } from '../fragments/invoiceTemplateFragment.graphql';

const query = gql`
  query DraftInvoiceExistingInvoice($invoiceId: ID!) {
    invoice(id: $invoiceId) {
      id
      invoicePdfVersionId
      versionIds
      additionalOptions {
        appendExpenseReceipts
        hideAmountAndTaxPerLineItem
        hideDueDate
        hideFeeTotal
        hideFeeTotalSummary
        hidePaymentSummary
        hidePriorBalance
        showAccountSummary
        showHoursSummary
        showInvoiceSummary
        showLessFundsInTrust
        showRetainer
        showSummaryForTimekeepers
        showTimekeeperRole
        showTransactionHistory
        showItemNumbers
      }
      debtors {
        id
        contact {
          id
          displayName
          displayNameFull
        }
      }
      description
      discount {
        type
        fixedDiscount
        percentage
        description
        applyOnlyToFees
      }
      dueDate
      eInvoiceOptions {
        enableDescriptionOnDemand
        descriptionOnDemandLineItemText
      }
      entries {
        id
        versionId
        type
        feeEntity {
          ...feeEntityFragment
        }
        expenseEntity {
          ...expenseEntityFragment
        }
      }
      expensesOverallSummary
      feesOverallSummary
      feeTaxRate
      finalizedTimestamp
      footer
      hasBeenFinalized
      invoiceNumber
      invoiceTitle
      invoiceTitleLine2
      isOriginalInvoice
      issuedDate
      layout {
        expenseLineItemConfiguration
        expenseSummaryLineDescription
        feeLineItemConfiguration
        feeSummaryLineDescription
        includeNonBillableItems
        showDescriptionForEntries
        showDurationOnEntries
        showNonBillableExpenses
        showNonBillableFees
        showRateDurationOnEntries
        showRateOnEntries
        showStaffNameOnEntries
      }
      matter {
        id
      }
      merchantPaymentReference
      paidDate
      retainerRequestAmount
      splitBillingSettings {
        isEnabled
        debtors {
          debtorId
          debtorRatio
          invoiceNumberSuffix
        }
        splitMethod
        remainderDebtorId
      }
      status
      summary
      surcharge {
        type
        fixedSurcharge
        percentageBp
        description
        applyTo
      }
      template {
        ...invoiceTemplateFragment
      }
      userId
      validFrom
      validTo
      versionId
    }
  }

  ${expenseEntityFragment}
  ${feeEntityFragment}
  ${invoiceTemplateFragment}
`;

const notificationIds = [
  'ExpensesNotifications.ExpenseUpdated',
  'ExpensesNotifications.ExpenseDeleted',
  'FeesNotifications.AccountFeesUpdated',
  'InvoicingNotifications.InvoiceUpdated',
  'InvoicingNotifications.InvoiceSettingsTemplateUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const DraftInvoiceExistingInvoice = {
  query,
  notificationIds,
};
