/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { MatterIcon, InvoiceIcon, Timer, InvoiceDottedIcon, BookOpenCheck, LeadIcon } from 'web/components/icons';
import { BarChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import { useTranslation, Chip, LoadingPlaceholderElement } from '@sb-itops/react';
import classNames from 'classnames';
import moment from 'moment';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { featureActive } from '@sb-itops/feature';
import { WelcomeModal } from 'web/react-redux/components/welcome-modal';
import { PromoCardBase, PromoCard } from 'web/react-redux/components/promo-card';
import invoicePromoSrc from 'web/assets/invoice-promo.png';
import twofaPromoSrc from 'web/assets/2fa-promo.png';
import Carousel from 'react-multi-carousel';
import { sendMetric } from 'web/services/metrics';
import { DailyDigestWidget } from '../../components/daily-digest-widget';
import Styles from './BillingDashboardRoute.module.scss';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: 1400 },
    items: 1,
    partialVisibilityGutter: 200,
  },
  smallDesktop: {
    breakpoint: { max: 1400, min: 1200 },
    items: 1,
    partialVisibilityGutter: 100,
  },
  smallestDesktop: {
    breakpoint: { max: 1200, min: 0 },
    items: 1,
    partialVisibilityGutter: 50,
  },
};

const getTotalTag = (matterTotals) => {
  if (matterTotals?.unpaidExcInterest) {
    return <Chip label="Unpaid" />;
  }
  if (matterTotals?.unbilled) {
    return <Chip label="Unbilled" />;
  }
  return '';
};

const CustomTickRenderer = (tickProps) => (
  <text
    key={tickProps.payload.value}
    x={tickProps.x}
    y={tickProps.y}
    stroke={tickProps.stroke}
    textAnchor={tickProps.textAnchor}
  >
    {tickProps.payload.value.split(' ').map((v, i) => (
      <tspan
        key={tickProps.payload.value + v}
        fill={i ? '#6B7280' : '#000'}
        x={tickProps.x}
        dy={`${String(0.71 * (i + 1))}em`}
      >
        {v}
      </tspan>
    ))}
  </text>
);

export const BillingDashboardRoute = (props) => {
  const {
    onClickLink,
    onFilterInvoiceStatus,
    onFilterByRecent,
    forgetAccountPageParams,
    userViewedMessages,
    showGetPaidPromoCard,
    show2FAPromoCard,
    showGetPaidPromoBanner,
    show2FAPromoBanner,
    showWelcomeModal,
    onDismissPaid,
    onDismiss2FA,
    onSetup2FA,
    onFindOutMore2FA,
  } = props;
  const { t } = useTranslation();

  // Both graphs should have the same max height
  const graphYAxisDomain = [
    -0.1,
    Math.max(
      ...props.fees.summaryPerDay.map((d) => d.Billable),
      ...props.fees.summaryPerDay.map((d) => d['Non-Billable']),
      ...props.fees.summaryToday.map((d) => d.Billable),
      ...props.fees.summaryToday.map((d) => d['Non-Billable']),
      8,
    ),
  ];

  // Default ticks should be 0, 2, 4, 6, 8
  // Otherwise we should 5 ticks
  const tickSize = graphYAxisDomain[1] / 4;
  const ticks = [0, Math.round(tickSize), Math.round(tickSize * 2), Math.round(tickSize * 3), graphYAxisDomain[1]];

  const promos = [
    showGetPaidPromoCard && (
      <PromoCardBase
        key="getPaidFaster"
        showCard
        onDismiss={onDismissPaid}
        onSetup={() => {
          sendMetric('promoCardClicked', { scope: 'GET_PAID_PROMO' });
          onClickLink({ type: 'paymentProviders' });
        }}
        title="Get Paid Faster"
        text="Accept online card payments for invoices, trust deposits and top-ups with Smokeball Payments."
      >
        <div className={Styles.promoImages}>
          <img src={invoicePromoSrc} alt="get-paid" />
        </div>
      </PromoCardBase>
    ),
    show2FAPromoCard && (
      <PromoCardBase
        key="mandatoryTwoFA"
        showCard
        onDismiss={onDismiss2FA}
        onSetup={onSetup2FA}
        title="Mandatory 2FA is coming"
        text="We're mandating two-factor authentication after 10 December 2025 for all Smokeball users."
        onFindOutMore={onFindOutMore2FA}
      >
        <div className={Styles.promoImages}>
          <img src={twofaPromoSrc} alt="smokeball" />
        </div>
      </PromoCardBase>
    ),
  ];

  return (
    <div className={Styles.billingDashboardRoute}>
      <div className="page-top-bar">
        <div className="page-name">
          <h1 className="page-name-heading">Dashboard</h1>
        </div>
        <div className="sb-tab-header">
          <ul className="nav nav-pills">
            <li className="active">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>Dashboard</a>
            </li>
          </ul>
        </div>
      </div>
      <div className={Styles.wrapper}>
        {showGetPaidPromoBanner && (
          <PromoCard
            scope="GET_PAID_PROMO"
            onSetup={() => {
              sendMetric('promoCardClicked', { scope: 'GET_PAID_PROMO' });
              onClickLink({ type: 'paymentProviders' });
            }}
            title="Get Paid Faster"
            text="Accept online card payments for invoices, trust deposits and top-ups with Smokeball Payments."
          >
            <div className={Styles.promoImages}>
              <img src={invoicePromoSrc} alt="get-paid" />
            </div>
          </PromoCard>
        )}
        {show2FAPromoBanner && (
          <PromoCard
            scope="2FA_PROMO"
            onSetup={onSetup2FA}
            title="Mandatory 2FA is coming"
            text="We're mandating two-factor authentication after 10 December 2025 for all Smokeball users."
            onFindOutMore={onFindOutMore2FA}
          >
            <div className={Styles.promoImages}>
              <img src={twofaPromoSrc} alt="smokeball" />
            </div>
          </PromoCard>
        )}
        {showGetPaidPromoCard && show2FAPromoCard ? (
          <Carousel
            className={Styles.carousel}
            showDots={false}
            arrows
            rtl={false}
            infinite
            partialVisible
            responsive={responsive}
          >
            {promos}
          </Carousel>
        ) : (
          <div className={Styles.carousel}>{promos}</div>
        )}
        <div className={Styles.widgetsContainer}>
          <div className={Styles.widgets}>
            <div className={Styles.dualRow}>
              <div className={Styles.accounts}>
                <div className={Styles.floater}>
                  <div className={Styles.heading}>Today&apos;s Billable</div>
                  {props.accounts.loading &&
                    [...Array(2)].map((_, i) => (
                      <div key={i} className={Styles.item}>
                        <LoadingPlaceholderElement isLine />
                      </div>
                    ))}
                  {!props.accounts.loading && (
                    <>
                      <div className={Styles.item}>
                        <div className={classNames(Styles.icon, Styles.greyIcon)}>
                          <Timer />
                        </div>
                        <div title={props.fees.summaryToday[0]?.Billable} className={Styles.amountLarge}>
                          {props.fees.summaryToday[0]?.Billable}
                          <span className={Styles.amountTitleLarge}>hrs</span>
                        </div>
                      </div>
                      <div className={classNames(Styles.item)}>
                        <div className={classNames(Styles.icon, Styles.greenIcon)}>
                          <InvoiceIcon />
                        </div>
                        <div
                          title={t('cents', { val: props.fees.summaryToday[0]?.BillableAmount || 0 })}
                          className={Styles.amountLarge}
                        >
                          {t('cents', { val: props.fees.summaryToday[0]?.BillableAmount || 0 })}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className={Styles.accounts}>
                <div className={Styles.floater}>
                  <div className={Styles.heading}>Last 7 Days Billable</div>
                  {props.accounts.loading &&
                    [...Array(2)].map((_, i) => (
                      <div key={i} className={Styles.item}>
                        <LoadingPlaceholderElement isLine />
                      </div>
                    ))}
                  {!props.accounts.loading && (
                    <>
                      <div className={Styles.item}>
                        <div className={classNames(Styles.icon, Styles.greyIcon)}>
                          <Timer />
                        </div>
                        <div title={props.fees.totalBillableHours} className={Styles.amountLarge}>
                          {props.fees.totalBillableHours}
                          <span className={Styles.amountTitleLarge}>hrs</span>
                        </div>
                      </div>
                      <div className={classNames(Styles.item, Styles.middle)}>
                        <div className={classNames(Styles.icon, Styles.greenIcon)}>
                          <InvoiceIcon />
                        </div>
                        <div title={t('cents', { val: props.fees.totalBillable })} className={Styles.amountLarge}>
                          {t('cents', { val: props.fees.totalBillable })}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {featureActive('BB-13753') && (
              <div className={Styles.dailyDigest}>
                <div className={Styles.floater}>
                  <DailyDigestWidget onClickLink={onClickLink} />
                </div>
              </div>
            )}
            <div className={Styles.dualRow}>
              <div className={Styles.billingGraph}>
                <div className={Styles.floater}>
                  <div className={Styles.heading}>
                    Billable vs Non-billable<div className={Styles.subTitle}>Last 7 days</div>
                  </div>
                  <a onClick={() => onClickLink({ type: 'timeAndFees' })} className={Styles.link}>
                    Go To Time/Fees
                    <div className={Styles.chartHighlight} />
                  </a>
                  <ResponsiveContainer height={300}>
                    <BarChart
                      width={500}
                      height={300}
                      onClick={(chartInfo) => {
                        if (chartInfo?.activePayload?.[0]?.payload?.date) {
                          onClickLink({ type: 'timeAndFees', id: chartInfo?.activePayload?.[0]?.payload?.date });
                        } else {
                          onClickLink({ type: 'timeAndFees', id: moment() });
                        }
                      }}
                      data={props.fees.summaryPerDay.map((d) => ({
                        // Add range to data
                        ...d,
                        Billable: [-0.1, d.Billable],
                        'Non-Billable': [-0.1, d['Non-Billable']],
                      }))}
                      margin={{
                        top: 5,
                        right: 0,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <Tooltip
                        position={{ y: 50 }}
                        labelFormatter={() => {}}
                        formatter={(value) => `${value[1] || 0} hrs`}
                      />
                      <XAxis height={50} axisLine={false} tickLine={false} dataKey="name" tick={CustomTickRenderer} />
                      <YAxis ticks={ticks} tickLine={false} axisLine={false} domain={graphYAxisDomain} />
                      <Legend height={22} iconType="square" align="left" />
                      <Bar dataKey="Billable" radius={[5, 5, 5, 5]} fill="#4BE9A7" />
                      <Bar dataKey="Non-Billable" radius={[5, 5, 5, 5]} fill="#DDE0E9" />
                    </BarChart>
                  </ResponsiveContainer>
                  <ResponsiveContainer height={300}>
                    <BarChart
                      width={150}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 5,
                        bottom: 5,
                      }}
                      onClick={(chartInfo) => {
                        if (chartInfo?.activePayload?.[0]?.payload?.date) {
                          onClickLink({ type: 'timeAndFees', id: chartInfo?.activePayload?.[0]?.payload?.date });
                        } else {
                          onClickLink({ type: 'timeAndFees', id: moment() });
                        }
                      }}
                      data={props.fees.summaryToday.map((d) => ({
                        // Add range to data to show a small line even when data is 0
                        ...d,
                        Billable: [-0.1, d.Billable],
                        'Non-Billable': [-0.1, d['Non-Billable']],
                      }))}
                    >
                      <Tooltip
                        position={{ y: 50 }}
                        labelFormatter={() => {}}
                        formatter={(value) => `${value[1] || 0} hrs`}
                      />
                      <XAxis height={50} tick={CustomTickRenderer} axisLine={false} tickLine={false} dataKey="name" />
                      <YAxis tickLine={false} axisLine={false} hide domain={graphYAxisDomain} />
                      <Bar dataKey="Billable" radius={[5, 5, 5, 5]} fill="#4BE9A7" />
                      <Bar dataKey="Non-Billable" radius={[5, 5, 5, 5]} fill="#DDE0E9" />
                      <Legend height={22} iconType="square" align="left" payload={[]} />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className={Styles.outstandingInvoicesAndReconconciliations}>
                {props.accounts.loading && (
                  <>
                    <div className={Styles.floater}>
                      <div className={Styles.title}>Outstanding Invoices</div>
                      <LoadingPlaceholderElement isLine />
                    </div>
                    <div className={Styles.floater}>
                      <div className={Styles.title}>{`Unreconciled ${t('trust')} Transactions`}</div>
                      <LoadingPlaceholderElement isLine />
                    </div>
                  </>
                )}
                {!props.accounts.loading && (
                  <>
                    <div
                      className={classNames(Styles.floater, Styles.pointer)}
                      onClick={() => {
                        onFilterInvoiceStatus(['OVERDUE']);
                        onClickLink({ type: 'bills' });
                      }}
                    >
                      {!props.invoiceData.invoiceCount.final && !props.invoiceData.invoiceCount.overdue ? (
                        <div className={Styles.title}>No Outstanding Invoices</div>
                      ) : (
                        <>
                          <div className={Styles.title}>
                            Outstanding Invoices (
                            {(props.invoiceData?.invoiceCount?.final || 0) +
                              (props.invoiceData?.invoiceCount?.overdue || 0)}
                            )
                          </div>
                          <BarChart
                            margin={{ top: 5, right: 0, bottom: 5, left: 0 }}
                            width={250}
                            height={30}
                            layout="vertical"
                            data={[props.invoiceData.invoiceCount]}
                          >
                            <XAxis type="number" hide domain={[0, 'dataMax']} />
                            <YAxis type="category" hide />
                            <Bar dataKey="count60plus" fill="#A6110D" stackId="a" />
                            <Bar dataKey="count30to60" fill="#EF2F2A" stackId="a" />
                            <Bar dataKey="count15to30" fill="#F5803D" stackId="a" />
                            <Bar dataKey="count1to15" fill="#F5E256" stackId="a" />
                          </BarChart>
                          <div className={classNames(Styles.text, Styles.break)}>
                            <div className={classNames(Styles.darkRed, Styles.square)} /> More than 60 days
                          </div>
                          <div className={classNames(Styles.text, Styles.break)}>
                            <div className={classNames(Styles.red, Styles.square)} />
                            30-60 days
                          </div>
                          <div className={classNames(Styles.text, Styles.break)}>
                            <div className={classNames(Styles.orange, Styles.square)} />
                            15-30 days
                          </div>
                          <div className={classNames(Styles.text, Styles.break)}>
                            <div className={classNames(Styles.yellow, Styles.square)} />
                            1-15 days
                          </div>
                          <a>View All</a>
                        </>
                      )}
                    </div>
                    <div className={Styles.floater}>
                      <div className={Styles.title}>
                        {props.numTransactionsNotReconciled} {`Unreconciled ${t('trust')} Transactions`}
                      </div>
                      <div className={Styles.text}>Last reconciled</div>
                      {!props.lastReconciled && <div className={Styles.title}>Never</div>}
                      {!!props.lastReconciled && (
                        <div className={Styles.title}>
                          {moment().startOf('day').diff(moment(props.lastReconciled, 'YYYYMMDD'), 'days')} days ago on{' '}
                          {t('date', { yyyymmdd: props.lastReconciled })}
                        </div>
                      )}
                      <a onClick={() => onClickLink({ type: 'bankReconciliationHome' })}>View All</a>
                    </div>
                  </>
                )}
              </div>
            </div>
            {(!!props.recentMatters.matters.length || props.recentMatters.loading) && (
              <div className={classNames(Styles.floater, Styles.recentMattersContainer)}>
                <div className={Styles.heading}>
                  Recent Matters
                  <a onClick={() => onClickLink({ type: 'matterList' })}>View All</a>
                </div>
                <div className={Styles.recentMatters}>
                  {props.recentMatters.loading &&
                    [...Array(5)].map((_, i) => (
                      <div key={i} className={Styles.innerCard}>
                        <LoadingPlaceholderElement />
                      </div>
                    ))}
                  {props.recentMatters.matters.map((m) => (
                    <div
                      key={m.id}
                      onClick={() => onClickLink({ type: 'matter', id: m.id })}
                      className={Styles.innerCard}
                    >
                      <div className={Styles.top}>
                        <div className={classNames(Styles.shape, Styles.orangeIcon)}>
                          {m.isLead ? <LeadIcon /> : <MatterIcon />}
                        </div>
                        {getTotalTag(m?.matterTotals)}
                      </div>
                      <div className={Styles.clients}>
                        {(m.clients || []).map((c, index) => (
                          <div key={`${m.id}${c.id || ''}`} className={Styles.client}>
                            {index > 0 && ' | '}
                            {c.displayName}
                          </div>
                        ))}
                      </div>
                      <div className={Styles.matterType}>{m.matterType.name}</div>
                      <div className={Styles.matter}>{m.description}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className={Styles.dualRowSecond}>
              <div className={classNames(Styles.invoicing, Styles.accounts, Styles.totals)}>
                {props.accounts.loading && (
                  <div className={Styles.floater}>
                    <div className={Styles.heading}>
                      Invoicing
                      <a onClick={() => onClickLink({ type: 'bills' })}>View All</a>
                    </div>
                    {[...Array(2)].map((_, i) => (
                      <div key={i} className={Styles.item}>
                        <LoadingPlaceholderElement isLine />
                      </div>
                    ))}
                  </div>
                )}
                {!props.accounts.loading && (
                  <div className={Styles.floater}>
                    <div className={Styles.heading}>
                      Invoicing
                      <a onClick={() => onClickLink({ type: 'bills' })}>View All</a>
                    </div>
                    <div onClick={() => onClickLink({ type: 'bulkBill' })} className={Styles.item}>
                      <div className={classNames(Styles.icon, Styles.greenIcon)}>
                        <InvoiceDottedIcon />
                      </div>
                      <div title={t('cents', { val: props?.accounts.totals?.unbilled })} className={Styles.amount}>
                        {t('cents', { val: props?.accounts.totals?.unbilled })}
                        <div className={Styles.amountTitle}>Unbilled</div>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        onFilterByRecent(false);
                        onFilterInvoiceStatus(['FINAL', 'OVERDUE']);
                        onClickLink({ type: 'bills' });
                      }}
                      className={classNames(Styles.item)}
                    >
                      <div className={classNames(Styles.icon, Styles.greenIcon)}>
                        <InvoiceIcon />
                      </div>
                      <div title={t('cents', { val: props?.accounts?.totals?.unpaid })} className={Styles.amount}>
                        {t('cents', { val: props?.accounts?.totals?.unpaid })}
                        <div className={Styles.amountTitle}>Unpaid ({props.invoiceData.invoiceCount.final || 0})</div>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        onFilterByRecent(false);
                        onFilterInvoiceStatus(['OVERDUE']);
                        onClickLink({ type: 'bills' });
                      }}
                      className={classNames(Styles.item)}
                    >
                      <div className={classNames(Styles.icon, Styles.redIcon)}>
                        <InvoiceIcon />
                      </div>
                      <div title={t('cents', { val: props?.accounts?.totals?.overdue })} className={Styles.amount}>
                        {t('cents', { val: props?.accounts?.totals?.overdue })}
                        <div className={Styles.amountTitle}>
                          Overdue ({props.invoiceData?.invoiceCount?.overdue || 0})
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        forgetAccountPageParams();
                        onClickLink({ type: 'transactions', id: 'credit-note-ledger' });
                      }}
                      className={classNames(Styles.item)}
                    >
                      <div className={classNames(Styles.icon, Styles.greenIcon)}>
                        <InvoiceIcon />
                      </div>
                      <div title={t('cents', { val: props?.accounts?.totals?.credit })} className={Styles.amount}>
                        {t('cents', { val: props?.accounts?.totals?.credit })}
                        <div className={Styles.amountTitle}>Credit</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={classNames(Styles.accounting, Styles.accounts, Styles.totals)}>
                {props.accounts.loading && (
                  <div className={Styles.floater}>
                    <div className={Styles.heading}>
                      Accounts
                      <a
                        onClick={() => {
                          forgetAccountPageParams();
                          onClickLink({ type: 'transactions', id: 'trust', params: ['all'] });
                        }}
                      >
                        View All
                      </a>
                    </div>
                    {[...Array(2)].map((_, i) => (
                      <div key={i} className={Styles.item}>
                        <LoadingPlaceholderElement isLine />
                      </div>
                    ))}
                  </div>
                )}
                {!props.accounts.loading && (
                  <div className={Styles.floater}>
                    <div className={Styles.heading}>
                      Accounts
                      <a
                        onClick={() => {
                          forgetAccountPageParams();
                          onClickLink({ type: 'transactions', id: 'trust', params: ['all'] });
                        }}
                      >
                        View All
                      </a>
                    </div>
                    {hasFacet(facets.operatingAccount) && (
                      <div
                        onClick={() => {
                          forgetAccountPageParams();
                          onClickLink({ type: 'transactions', id: 'operating' });
                        }}
                        className={classNames(Styles.item)}
                      >
                        <div className={classNames(Styles.icon, Styles.blueIcon)}>
                          <BookOpenCheck />
                        </div>
                        <div title={t('cents', { val: props?.accounts?.totals?.operating })} className={Styles.amount}>
                          {t('cents', { val: props?.accounts?.totals?.operating })}
                          <div className={Styles.amountTitle}>{t('operatingRetainer')}</div>
                        </div>
                      </div>
                    )}
                    <div
                      onClick={() => {
                        forgetAccountPageParams();
                        onClickLink({ type: 'transactions', id: 'trust', params: ['all'] });
                      }}
                      className={Styles.item}
                    >
                      <div className={classNames(Styles.icon, Styles.blueIcon)}>
                        <BookOpenCheck />
                      </div>
                      <div title={t('cents', { val: props?.accounts?.totals?.trust })} className={Styles.amount}>
                        {t('cents', { val: props?.accounts?.totals?.trust })}
                        <div className={Styles.amountTitle}>{t('trust')}</div>
                      </div>
                    </div>
                    {props?.accounts?.showControlledMoneyAccount && (
                      <div className={classNames(Styles.item, Styles.CMA)}>
                        <div className={classNames(Styles.icon, Styles.blueIcon)}>
                          <BookOpenCheck />
                        </div>
                        <div
                          title={t('cents', { val: props?.accounts?.totals?.controlledMoney })}
                          className={Styles.amount}
                        >
                          {t('cents', { val: props?.accounts?.totals?.controlledMoney })}
                          <div className={Styles.amountTitle}>{t('CMA')}</div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showWelcomeModal && <WelcomeModal onClickLink={onClickLink} viewedMessages={userViewedMessages} />}
      {featureActive('BB-13823-DEBUG') && <WelcomeModal onClickLink={onClickLink} viewedMessages={[]} />}
    </div>
  );
};

BillingDashboardRoute.displayName = 'BillingDashboardRoute';
