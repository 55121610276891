import React from 'react';
import PropTypes from 'prop-types';
import { Table, Column, utils } from '@sb-itops/react/table';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import { LinkableText } from '@sb-itops/react';

const { amountCellLocalisedRenderer } = utils;

const PaymentsTable = React.memo(
  ({ payments, summary, onSort, sortDirection, sortBy, showPayor, onClickLink, onNavigateAway }) => {
    const invoiceCellRenderer = ({ rowData }) => {
      const invoice = rowData?.invoice;
      return (
        <LinkableText
          text={`#${invoice?.invoiceNumber}`}
          onClickLink={() => {
            onClickLink({ type: 'invoice', id: invoice?.id });
            onNavigateAway();
          }}
          asLink
          inline
        />
      );
    };

    const matterCellRenderer = ({ rowData }) => {
      const matter = rowData?.matter;
      const matterDisplay = getMatterDisplay(matter, matter?.matterType?.name);
      return (
        <LinkableText
          text={matterDisplay}
          onClickLink={() => {
            onClickLink({ type: 'matter', id: matter?.id });
            onNavigateAway();
          }}
          asLink
          inline
        />
      );
    };

    const payorCellRenderer = ({ rowData }) => {
      const payor = rowData?.payor;
      return (
        <LinkableText
          text={payor?.displayName || 'unknown'}
          onClickLink={() => {
            onClickLink({ type: 'contact', id: payor?.id });
            onNavigateAway();
          }}
          asLink
          inline
        />
      );
    };

    return (
      <Table list={payments} sort={onSort} sortDirection={sortDirection} sortBy={sortBy} showFooter summary={summary}>
        <Column
          dataKey="invoiceSort"
          label="Invoice"
          className="left-align"
          width={100}
          cellRenderer={invoiceCellRenderer}
          footerRenderer={() => ''}
        />
        <Column
          dataKey="matterSort"
          label="Matter"
          flexGrow={1}
          cellRenderer={matterCellRenderer}
          footerRenderer={() => ''}
        />
        {showPayor && <Column dataKey="payorSort" label="Paid By" flexGrow={1} cellRenderer={payorCellRenderer} />}
        <Column
          className="right-align"
          dataKey="amount"
          label="Amount"
          width={130}
          cellRenderer={amountCellLocalisedRenderer}
          footerRenderer={amountCellLocalisedRenderer}
        />
      </Table>
    );
  },
);

PaymentsTable.propTypes = {
  payments: PropTypes.array,
  summary: PropTypes.object,
  onSort: PropTypes.func,
  sortDirection: PropTypes.string,
  sortBy: PropTypes.string,
  showPayor: PropTypes.bool,
  onClickLink: PropTypes.func,
  onNavigateAway: PropTypes.func,
};

PaymentsTable.defaultProps = {
  payments: [],
  summary: {},
  onSort: () => {},
  onClickLink: () => {},
  onNavigateAway: () => {},
  sortDirection: undefined,
  sortBy: undefined,
  showPayor: false,
};

PaymentsTable.displayName = 'PaymentsTable';

export default PaymentsTable;
