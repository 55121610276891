window.moment = require('moment');
window.jQuery = require('jquery');
require('lodash');

require('angular');
require('@sb-itops/angular-indexed-db/angular-indexed-db');
require('angular-base64');

const { initialise } = require('@sb-itops/environment-config');
initialise();

angular.module('@sb-itops/services', ['indexedDB', 'base64']);

const dbKeyPaths = {
  testCache: 'testKey',
  sbAccountService: 'account',
  sbMatterAutoNumberDefinitionService: 'accountId',
  sbMatterTypesService: 'id',
  sbFeeService: 'feeId', // Removed
  sbExpenseService: 'expenseId',
  sbFirmmanagementService: 'id',
  sbMatterService: 'matterId',
  sbBillingMattersService: 'matterId', // dead
  sbContactsService: 'id',
  sbMatterRelationsService: 'key',
  sbBillingActivityService: 'activityId',
  sbInvoicingService: 'invoiceId',
  sbInvoiceMatterTotalsService: 'matterId', // This table was removed
  sbTaxConfigService: 'accountId', // This is no longer required
  sbTaxGroupService: 'taxGroupId', // This is no longer required
  sbContactsMbService: 'entityId',
  sbMattersMbService: 'matterId',
  sbMatterTypeConfigurationsService: 'matterTypeId',
  sbMatterTypesMbService: 'matterTypeId',
  sbFirmManagementMbService: 'details',
  sbRelateContactToMattersMbService: 'key',
  sbContactMbService: 'id', //removed
  sbSimpleContactMbService: 'id',
  sbContactMbServiceLookup: 'entityId',
  sbMatterListingMbService: 'matterId',
  sbTasksMbService: 'id',
  sbMatterFileIndicesMbService: 'matterId',
  sbCommentsMbService: 'taskId',
  sbCalenderResourcesMbService: 'id',
  sbMatterNotesMbService: 'matterId',
  sbTaskMatterMappingsMbService: 'matterId',
  sbMatterNoteCategoriesMbService: 'id',
  sbDefaultTaxGroupsService: 'accountId', // removed
  sbInvoiceSettingsService: 'details',
  sbBillingTemplateService: 'layoutId', // no longer used
  sbBillingAccountService: 'id', // dead -replaced with sbBankAccountService
  sbDefaultBankAccountsService: 'accountId',
  sbBankAccountService: 'id',
  sbTransactionService: 'id',
  sbBankAccountBalancesService: 'id',
  sbMatterTotalsService: 'matterId',
  sbInvoiceTotalsService: 'invoiceId',
  sbInvoiceDebtorTotalsService: 'id',
  sbPaymentService: 'paymentId',
  sbStaffHourlyRateService: 'staffId',
  sbMatterHourlyRateService: 'matterId',
  sbUserSettingsService: 'name',
  sbOobErrorsService: 'oobErrorId',
  sbFeeConfigurationService: 'accountId',
  sbOptimisticUpdatesService: 'orbId',
  sbQuickBooksIntegrationService: 'accountId', // replaced by sbQuickbooksService
  sbQuickBooksAccountsService: 'accountId', // replaced by sbQuickbooksService
  sbQuickBooksService: 'accountId',
  sbMatterBillingConfigurationService: 'matterId',
  dict: 'key',
  sbLawPayService: 'details',
  sbTrustReceiptSettingsService: 'bankAccountId',
  sbTrustChequePrintSettingsService: 'bankAccountId',
  sbTransactionReceiptService: 'id',
  sbBankReconciliationService: 'id',
  sbDepositSlipService: 'id',
  sbTrustChequeService: 'chequeId',
  sbGstTaxSettingsService: 'accountId',
  sbTrustRetainersService: 'matterId',
  sbBillingConfigurationService: 'matterId',
  sbXeroService: 'accountId',
  sbDefaultMatterBillingConfigurationService: 'accountId',
  sbRegisteredUsersService: 'firmId',
  sbInterestSettingsService: 'matterId',
  sbAccountRemindersService: 'id',
  sbAccountRemindersSettingsService: 'accountId',
  sbMyobBusinessService: 'id',
  sbMyobIntegrationService: 'id',
  sbVendorPaymentsService: 'paymentId',
  sbUtbmsSettingsService: 'accountId',
  sbCustomerBillingConfigurationService: 'entityId',
  sbStaffFeeConfigurationService: 'staffId',
  sbUtbmsActivityCodeService: 'code',
  sbUtbmsTaskCodeService: 'code',
  sbCorrespondenceHistoryService: 'id',
  sbCorrespondenceHistoryFullService: 'id',
  sbBulkDepositService: 'id',
  sbMatterInvoiceSettingsService: 'matterId',
  sbMatterEmailSettingsService: 'matterId',
  sbMatterCommunicationSettingsService: 'matterId',
  sbInvoiceSettingsTemplateService: 'id',
  sbExternalReportsService: 'id',
  sbEndOfMonthReportsService: 'id',
  sbBankAccountSettingsService: 'accountId',
  sbControlledMoneyAccountSettingsService: 'accountId',
  sbInvoiceSettingsTemplateFilesService: 'id',
  sbAutoNumberingPrefixesService: 'bankAccountId',
  sbPaymentPlansService: 'id',
  sbPaymentPlanEventsService: 'id',
  sbBulkFinalizeSettingsService: 'accountId',
  sbOperatingChequePrintSettingsService: 'bankAccountId',
  sbOperatingChequesService: 'chequeId',
  sbReportConfigService: 'reportId',
  sbInvoiceEmailSettingsService: 'accountId',
  sbBillingEventService: 'id',
  sbEInvoiceSettingsService: 'accountId',
  sbPaymentProviderSettingsService: 'accountId',
  sbEntityBankDetailsService: 'id',
  sbSubscriptionService: 'accountId',
  sbRecentInvoiceService: 'userId',
  sbRecentMatterService: 'userId',
  sbRateSetsService: 'id',
  sbUserBillingAttributesService: 'userId',
  sbBalanceProtectionService: 'id',
  sbBankReconciliationSetupService2: 'bankAccountId',
  sbPaymentProviderSettingsService2: 'accountId',
  sbExpensePaymentDetailsService: 'id',
  sbCustomTaskCodeService: 'id',
};

angular.module('@sb-itops/services').config(function($indexedDBProvider) {
  $indexedDBProvider
    .connection('smokeballDB')
    .upgradeDatabase(1, function (event, db) {
      db.createObjectStore('config');
    })
    .upgradeDatabase(2, function (event, db) {
      db.deleteObjectStore('config');
      db.createObjectStore('config', { keyPath: 'configKey' });
    })
    .upgradeDatabase(3, function (event, db) {
      db.createObjectStore('sbAccountService', { keyPath: dbKeyPaths.sbAccountService });
      db.createObjectStore('sbMatterTypesService', { keyPath: dbKeyPaths.sbMatterTypesService });
      db.createObjectStore('sbFirmmanagementService', { keyPath: dbKeyPaths.sbFirmmanagementService });
      db.createObjectStore('sbMatterService', { keyPath: dbKeyPaths.sbMatterService });
      db.createObjectStore('sbBillingMattersService', { keyPath: dbKeyPaths.sbBillingMattersService });
      db.createObjectStore('sbContactsService', { keyPath: dbKeyPaths.sbContactsService });
      db.createObjectStore('sbMatterRelationsService', { keyPath: dbKeyPaths.sbMatterRelationsService });
      db.createObjectStore('sbBillingActivityService', { keyPath: dbKeyPaths.sbBillingActivityService });
    })
    .upgradeDatabase(4, function (event, db) {
      db.createObjectStore('testCache', { keyPath: 'testKey' });
    })
    .upgradeDatabase(5, function (event, db) {
      db.createObjectStore('sbInvoicingService', { keyPath: dbKeyPaths.sbInvoicingService });
    })
    .upgradeDatabase(6, function (event, db) {
      db.deleteObjectStore('sbInvoicingService');
      db.createObjectStore('sbInvoicingService', { keyPath: dbKeyPaths.sbInvoicingService });
    })
    .upgradeDatabase(7, function (event, db) {
      db.deleteObjectStore('sbInvoicingService');
      db.createObjectStore('sbInvoicingService', { keyPath: dbKeyPaths.sbInvoicingService });
    })
    .upgradeDatabase(8, function (event, db) {
      db.createObjectStore('sbFirmTaxConfigService', { keyPath: dbKeyPaths.sbFirmTaxConfigService });
    })
    .upgradeDatabase(9, function (event, db) {
      db.deleteObjectStore('sbFirmTaxConfigService');
      db.createObjectStore('sbTaxConfigService', { keyPath: dbKeyPaths.sbTaxConfigService });
    })
    .upgradeDatabase(10, function (event, db) {
      db.createObjectStore('sbTaxGroupService', { keyPath: dbKeyPaths.sbTaxGroupService });
    })
    .upgradeDatabase(11, function (event, db) {
      db.deleteObjectStore('sbTaxGroupService');
      db.createObjectStore('sbTaxGroupService', { keyPath: dbKeyPaths.sbTaxGroupService });
    })
    .upgradeDatabase(12, function (event, db) {
      db.createObjectStore('sbContactsMbService', { keyPath: dbKeyPaths.sbContactsMbService });
    })
    .upgradeDatabase(13, function (event, db) {
      db.createObjectStore('sbMattersMbService', { keyPath: dbKeyPaths.sbMattersMbService });
    })
    .upgradeDatabase(14, function (event, db) {
      db.createObjectStore('sbMatterTypesMbService', { keyPath: dbKeyPaths.sbMatterTypesMbService });
    })
    .upgradeDatabase(15, function (event, db) {
      db.createObjectStore('sbFirmManagementMbService', { keyPath: dbKeyPaths.sbFirmManagementMbService });
    })
    .upgradeDatabase(16, function (event, db) {
      db.createObjectStore('sbTasksMbService', { keyPath: dbKeyPaths.sbTasksMbService });
    })
    .upgradeDatabase(17, function (event, db) {
      db.createObjectStore('sbRelateContactToMattersMbService', { keyPath: dbKeyPaths.sbRelateContactToMattersMbService });
    })
    .upgradeDatabase(18, function (event, db) {
      db.createObjectStore('sbContactMbService', { keyPath: dbKeyPaths.sbContactMbService });
    })
    .upgradeDatabase(19, function (event, db) {
      db.createObjectStore('sbMatterListingMbService', { keyPath: dbKeyPaths.sbMatterListingMbService });
    })
    .upgradeDatabase(20, function (event, db) {
      db.deleteObjectStore('sbMatterListingMbService');
      db.createObjectStore('sbMatterListingMbService', { keyPath: dbKeyPaths.sbMatterListingMbService });
    })
    .upgradeDatabase(21, function (event, db) {
      db.createObjectStore('sbMatterFileIndicesMbService', { keyPath: dbKeyPaths.sbMatterFileIndicesMbService });
    })
    .upgradeDatabase(22, function (event, db) {
      db.createObjectStore('sbCommentsMbService', { keyPath: dbKeyPaths.sbCommentsMbService });
    })
    .upgradeDatabase(23, function (event, db) {
      db.createObjectStore('sbMatterNotesMbService', { keyPath: dbKeyPaths.sbMatterNotesMbService });
    })
    .upgradeDatabase(24, function (event, db) {
      db.createObjectStore('sbTaskMatterMappingsMbService', { keyPath: dbKeyPaths.sbTaskMatterMappingsMbService });
    })
    .upgradeDatabase(25, function (event, db) {
      db.createObjectStore('sbCalenderResourcesMbService', { keyPath: dbKeyPaths.sbCalenderResourcesMbService });
    })
    .upgradeDatabase(26, function (event, db) {
      db.createObjectStore('sbMatterNoteCategoriesMbService', { keyPath: dbKeyPaths.sbMatterNoteCategoriesMbService });
    })
    .upgradeDatabase(27, function (event, db) {
      db.createObjectStore('sbContactMbServiceLookup', { keyPath: dbKeyPaths.sbContactMbServiceLookup });
    })
    .upgradeDatabase(28, function (event, db) {
      db.deleteObjectStore('sbRelateContactToMattersMbService');
      db.createObjectStore('sbRelateContactToMattersMbService', { keyPath: dbKeyPaths.sbRelateContactToMattersMbService });
    })
    .upgradeDatabase(29, function (event, db) {
      db.deleteObjectStore('sbContactsMbService');
      db.createObjectStore('sbContactsMbService', { keyPath: dbKeyPaths.sbContactsMbService });
      db.deleteObjectStore('sbMattersMbService');
      db.createObjectStore('sbMattersMbService', { keyPath: dbKeyPaths.sbMattersMbService });
    })
    .upgradeDatabase(30, function (event, db) {
      db.deleteObjectStore('sbTaxGroupService');
      db.createObjectStore('sbTaxGroupService', { keyPath: dbKeyPaths.sbTaxGroupService });
    })
    .upgradeDatabase(31, function (event, db) {
      db.createObjectStore('sbFeeService', { keyPath: dbKeyPaths.sbFeeService });
    })
    .upgradeDatabase(32, function (event, db) {
      db.createObjectStore('sbExpenseService', { keyPath: dbKeyPaths.sbExpenseService });
    })
    .upgradeDatabase(33, function (event, db) {
      db.createObjectStore('sbUserTimerService', { keyPath: dbKeyPaths.sbUserTimerService });
    })
    .upgradeDatabase(34, function (event, db) {
      db.createObjectStore('sbInvoiceMatterTotalsService', { keyPath: dbKeyPaths.sbInvoiceMatterTotalsService }); // deleted
    })
    .upgradeDatabase(35, function (event, db) {
      db.createObjectStore('sbDefaultTaxGroupsService', { keyPath: dbKeyPaths.sbDefaultTaxGroupsService });
    })
    .upgradeDatabase(36, function (event, db) {
      db.createObjectStore('sbInvoiceSettingsService', { keyPath: dbKeyPaths.sbInvoiceSettingsService });
    })
    .upgradeDatabase(37, function (event, db) {
      db.createObjectStore('sbBillingTemplateService', { keyPath: dbKeyPaths.sbBillingTemplateService });
    })
    .upgradeDatabase(38, function (event, db) {
      db.createObjectStore('sbBillingAccountService', { keyPath: dbKeyPaths.sbBillingAccountService });
    })
    .upgradeDatabase(39, function (event, db) {
      db.createObjectStore('sbDefaultBankAccountsService', { keyPath: dbKeyPaths.sbDefaultBankAccountsService });
    })
    .upgradeDatabase(40, function (event, db) {
      db.deleteObjectStore('sbBillingAccountService');
      db.createObjectStore('sbBankAccountService', { keyPath: dbKeyPaths.sbBankAccountService });
    })
    .upgradeDatabase(41, function (event, db) {
      db.createObjectStore('sbTransactionService', { keyPath: dbKeyPaths.sbTransactionService });
    })
    .upgradeDatabase(42, function (event, db) {
      db.createObjectStore('sbBankAccountBalancesService', { keyPath: dbKeyPaths.sbBankAccountBalancesService });
    })
    .upgradeDatabase(43, function (event, db) {
      db.createObjectStore('sbMatterTotalsService', { keyPath: dbKeyPaths.sbMatterTotalsService });
    })
    .upgradeDatabase(44, function (event, db) {
      db.createObjectStore('sbInvoiceTotalsService', { keyPath: dbKeyPaths.sbInvoiceTotalsService });
    })
    .upgradeDatabase(45, function (event, db) {
      db.deleteObjectStore('sbBillingMattersService');
    })
    .upgradeDatabase(46, function (event, db) {
      db.deleteObjectStore('sbInvoiceMatterTotalsService');
    })
    .upgradeDatabase(47, function (event, db) {
      db.createObjectStore('sbPaymentService', { keyPath: dbKeyPaths.sbPaymentService });
    })
    .upgradeDatabase(48, function (event, db) {
      db.createObjectStore('sbStaffHourlyRateService', { keyPath: dbKeyPaths.sbStaffHourlyRateService });
    })
    .upgradeDatabase(49, function (event, db) {
      db.deleteObjectStore('sbTaxConfigService');
    })
    .upgradeDatabase(50, function (event, db) {
      db.deleteObjectStore('sbTaxGroupService');
    })
    .upgradeDatabase(51, function (event, db) {
      db.deleteObjectStore('sbDefaultTaxGroupsService');
    })
    .upgradeDatabase(52, function (event, db) {
      db.createObjectStore('sbMatterHourlyRateService', { keyPath: dbKeyPaths.sbMatterHourlyRateService });
    })
    .upgradeDatabase(53, function (event, db) {
      db.deleteObjectStore('sbInvoiceSettingsService');
      db.createObjectStore('sbInvoiceSettingsService', { keyPath: dbKeyPaths.sbInvoiceSettingsService });
    })
    .upgradeDatabase(54, function (event, db) {
      db.createObjectStore('sbUserSettingsService', { keyPath: dbKeyPaths.sbUserSettingsService });
    })
    .upgradeDatabase(55, function (event, db) {
      db.createObjectStore('sbOobErrorsService', { keyPath: dbKeyPaths.sbOobErrorsService });
    })
    .upgradeDatabase(56, function (event, db) {
      db.createObjectStore('sbFeeConfigurationService', { keyPath: dbKeyPaths.sbFeeConfigurationService });
    })
    .upgradeDatabase(57, function (event, db) {
      db.createObjectStore('sbOptimisticUpdatesService', { keyPath: dbKeyPaths.sbOptimisticUpdatesService });
    })
    .upgradeDatabase(58, function (event, db) {
      db.deleteObjectStore('sbBillingTemplateService');
    })
    .upgradeDatabase(59, function (event, db) {
      db.createObjectStore('sbQuickBooksIntegrationService', { keyPath: dbKeyPaths.sbQuickBooksIntegrationService });
      db.createObjectStore('sbQuickBooksAccountsService', { keyPath: dbKeyPaths.sbQuickBooksAccountsService });
    })
    .upgradeDatabase(60, function (event, db) {
      db.deleteObjectStore('sbQuickBooksAccountsService');
      db.deleteObjectStore('sbQuickBooksIntegrationService');
      db.createObjectStore('sbQuickBooksService', { keyPath: dbKeyPaths.sbQuickBooksService });
    })
    .upgradeDatabase(61, function (event, db) {
      db.createObjectStore('sbSimpleContactMbService', { keyPath: dbKeyPaths.sbSimpleContactMbService });
    })
    .upgradeDatabase(62, function (event, db) {
      db.createObjectStore('sbMatterBillingConfigurationService', { keyPath: dbKeyPaths.sbMatterBillingConfigurationService });
    })
    .upgradeDatabase(63, function (event, db) {
      db.createObjectStore('dict', { keyPath: dbKeyPaths.dict });
    })
    .upgradeDatabase(64, function (event, db) {
      db.createObjectStore('sbLawPayService', { keyPath: dbKeyPaths.sbLawPayService });
    })
    .upgradeDatabase(65, function (event, db) {
      db.deleteObjectStore('sbLawPayService');
      db.createObjectStore('sbLawPayService', { keyPath: dbKeyPaths.sbLawPayService });
    })
    .upgradeDatabase(66, function (event, db) {
      db.createObjectStore('sbTrustReceiptSettingsService', { keyPath: dbKeyPaths.sbTrustReceiptSettingsService });
    })
    .upgradeDatabase(67, function (event, db) {
      db.createObjectStore('sbTransactionReceiptService', { keyPath: dbKeyPaths.sbTransactionReceiptService });
    })
    .upgradeDatabase(68, function (event, db) {
      db.createObjectStore('sbBankReconciliationService', { keyPath: dbKeyPaths.sbBankReconciliationService });
    })
    .upgradeDatabase(69, function (event, db) {
      db.createObjectStore('sbBankReconciliationSetupService', { keyPath: dbKeyPaths.sbBankReconciliationSetupService });
    })
    .upgradeDatabase(70, (event, db) => {
      db.createObjectStore('sbDepositSlipService', { keyPath: dbKeyPaths.sbDepositSlipService });
    })
    .upgradeDatabase(71, function (event, db) {
      db.createObjectStore('sbTrustChequePrintSettingsService', { keyPath: dbKeyPaths.sbTrustChequePrintSettingsService });
    })
    .upgradeDatabase(72, function (event, db) {
      db.createObjectStore('sbTrustChequeService', { keyPath: dbKeyPaths.sbTrustChequeService });
    })
    .upgradeDatabase(73, function (event, db) {
      db.createObjectStore('sbGstTaxSettingsService', { keyPath: dbKeyPaths.sbGstTaxSettingsService });
    })
    .upgradeDatabase(74, function (event, db) {
      db.createObjectStore('sbXeroService', { keyPath: dbKeyPaths.sbXeroService });
    })
    /*
     * This was sent out as part of a patch for version 3.223. It has been implemented in non patched versions as upgrade 77.
      .upgradeDatabase(75, function(event, db) {
        db.deleteObjectStore('sbXeroService');
        db.createObjectStore('sbXeroService', {keyPath: 'accountId'});
      })*/
    .upgradeDatabase(76, function (event, db) {
      db.createObjectStore('sbBillingConfigurationService', { keyPath: dbKeyPaths.sbBillingConfigurationService });
    })
    .upgradeDatabase(77, function (event, db) {// (1) Added to patch indexeddb
      db.deleteObjectStore('sbXeroService');
      db.createObjectStore('sbXeroService', { keyPath: dbKeyPaths.sbXeroService });
    })
    .upgradeDatabase(78, function (event, db) {
      db.createObjectStore('sbDefaultMatterBillingConfigurationService', { keyPath: dbKeyPaths.sbDefaultMatterBillingConfigurationService });
    })
    .upgradeDatabase(79, function (event, db) {
      db.createObjectStore('sbTrustRetainersService', { keyPath: dbKeyPaths.sbTrustRetainersService });
    })
    .upgradeDatabase(80, function (event, db) {
      db.createObjectStore('sbInterestSettingsService', { keyPath: dbKeyPaths.sbInterestSettingsService })
    })
    .upgradeDatabase(81, function (event, db) {
      db.createObjectStore('sbRegisteredUsersService', { keyPath: dbKeyPaths.sbRegisteredUsersService });
    })
    .upgradeDatabase(82, function (event, db) {
      db.createObjectStore('appState', { keyPath: 'appStateKey' });
    })
    .upgradeDatabase(83, function (event, db) {
      db.deleteObjectStore('sbTrustChequeService');
      db.createObjectStore('sbTrustChequeService', { keyPath: dbKeyPaths.sbTrustChequeService });
    })
    .upgradeDatabase(84, function (event, db) {
      db.createObjectStore('sbAccountRemindersService', { keyPath: dbKeyPaths.sbAccountRemindersService });
    })
    .upgradeDatabase(85, function (event, db) {
      db.createObjectStore('sbAccountRemindersSettingsService', { keyPath: dbKeyPaths.sbAccountRemindersSettingsService });
    })
    .upgradeDatabase(86, function (event, db) {
      db.deleteObjectStore('sbContactMbService');
    })
    .upgradeDatabase(87, function (event, db) {
      db.createObjectStore('sbMyobIntegrationService', { keyPath: dbKeyPaths.sbMyobIntegrationService });
    })
    .upgradeDatabase(88, (event, db) => {
      db.createObjectStore('sbMyobBusinessService', { keyPath: dbKeyPaths.sbMyobBusinessService });
    })
    .upgradeDatabase(89, function (event, db) {
      db.createObjectStore('sbVendorPaymentsService', { keyPath: dbKeyPaths.sbVendorPaymentsService });
    })
    .upgradeDatabase(90, function (event, db) {
      // We need to do this because there was a feature switch around 87 and 88. Some users may have run 87 and 88, some may not have.
      try {
        db.deleteObjectStore('sbMyobBusinessService');
      }
      catch (err) {
        // Swallow.
      }

      try {
        db.deleteObjectStore('sbMyobIntegrationService');
      }
      catch (err) {
        // Swallow.
      }
      db.createObjectStore('sbMyobIntegrationService', { keyPath: dbKeyPaths.sbMyobIntegrationService });
      db.createObjectStore('sbMyobBusinessService', { keyPath: dbKeyPaths.sbMyobBusinessService });
    })
    .upgradeDatabase(91, function (event, db) {
      db.createObjectStore('sbUtbmsSettingsService', { keyPath: dbKeyPaths.sbUtbmsSettingsService });
    })
    .upgradeDatabase(92, function (event, db) {
      db.createObjectStore('sbUtbmsActivityCodeService', { keyPath: dbKeyPaths.sbUtbmsActivityCodeService });
      db.createObjectStore('sbUtbmsTaskCodeService', { keyPath: dbKeyPaths.sbUtbmsTaskCodeService });
    })
    .upgradeDatabase(93, function (event, db) {
      db.createObjectStore('sbCustomerBillingConfigurationService', { keyPath: dbKeyPaths.sbCustomerBillingConfigurationService });
      db.createObjectStore('sbStaffFeeConfigurationService', { keyPath: dbKeyPaths.sbStaffFeeConfigurationService });
    })
    .upgradeDatabase(94, function (event, db) {
      db.createObjectStore('sbCorrespondenceHistoryService', { keyPath: dbKeyPaths.sbCorrespondenceHistoryService });
    })
    .upgradeDatabase(95, function (event, db) {
      db.createObjectStore('sbMatterInvoiceSettingsService', { keyPath: dbKeyPaths.sbMatterInvoiceSettingsService });
    })
    .upgradeDatabase(96, function (event, db) {
      db.createObjectStore('sbInvoiceSettingsTemplateService', { keyPath: dbKeyPaths.sbInvoiceSettingsTemplateService });
    })
    .upgradeDatabase(97, function (event, db) {
      db.createObjectStore('sbExternalReportsService', { keyPath: dbKeyPaths.sbExternalReportsService });
    })
    .upgradeDatabase(98, function (event, db) {
      db.createObjectStore('sbBankAccountSettingsService', { keyPath: dbKeyPaths.sbBankAccountSettingsService });
    })
    .upgradeDatabase(99, function (event, db) {
      db.createObjectStore('sbEndOfMonthReportsService', { keyPath: dbKeyPaths.sbEndOfMonthReportsService });
    })
    .upgradeDatabase(100, function (event, db) {
      db.createObjectStore('sbInvoiceSettingsTemplateFilesService', { keyPath: dbKeyPaths.sbInvoiceSettingsTemplateFilesService });
    })
    .upgradeDatabase(101, function (event, db) {
      db.createObjectStore('sbAutoNumberingPrefixesService', { keyPath: dbKeyPaths.sbAutoNumberingPrefixesService });
    })
    .upgradeDatabase(102, function (event, db) {
      db.createObjectStore('sbPaymentPlansService', { keyPath: dbKeyPaths.sbPaymentPlansService });
    })
    .upgradeDatabase(103, function (event, db) {
      db.createObjectStore('sbPaymentPlanEventsService', { keyPath: dbKeyPaths.sbPaymentPlanEventsService });
    })
    .upgradeDatabase(104, function (event, db) {
      db.createObjectStore('sbBulkFinalizeSettingsService', { keyPath: dbKeyPaths.sbBulkFinalizeSettingsService });
    })
    .upgradeDatabase(105, function (event, db) {
      db.createObjectStore('sbReportConfigService', { keyPath: dbKeyPaths.sbReportConfigService });
    })
    .upgradeDatabase(106, function (event, db) {
      db.createObjectStore('sbOperatingChequePrintSettingsService', { keyPath: dbKeyPaths.sbOperatingChequePrintSettingsService });
      db.createObjectStore('sbOperatingChequesService', { keyPath: dbKeyPaths.sbOperatingChequesService });
    })
    .upgradeDatabase(107, function (event, db) {
      db.deleteObjectStore('sbExternalReportsService');
    })
    .upgradeDatabase(108, function (event, db) {
      db.createObjectStore('sbInvoiceEmailSettingsService', { keyPath: dbKeyPaths.sbInvoiceEmailSettingsService });
    })
    .upgradeDatabase(109, function (event, db) {
      db.createObjectStore('sbBillingEventService', { keyPath: dbKeyPaths.sbBillingEventService });
    })
    .upgradeDatabase(110, function (event, db) {
      db.createObjectStore('sbEInvoiceSettingsService', { keyPath: dbKeyPaths.sbEInvoiceSettingsService });
    })
    .upgradeDatabase(111, function (event, db) {
      db.createObjectStore('sbPaymentProviderSettingsService', { keyPath: dbKeyPaths.sbPaymentProviderSettingsService });
    })
    .upgradeDatabase(112, function (event, db) {
      db.createObjectStore('sbEntityBankDetailsService', { keyPath: dbKeyPaths.sbEntityBankDetailsService });
    })
    .upgradeDatabase(113, function (event, db) {
      db.deleteObjectStore('sbLawPayService');
    })
    .upgradeDatabase(114, function (event, db) {
      db.createObjectStore('sbMatterTypeConfigurationsService', { keyPath: dbKeyPaths.sbMatterTypeConfigurationsService });
    })
    .upgradeDatabase(115, function (event, db) {
      db.createObjectStore('sbMatterAutoNumberDefinitionService', { keyPath: dbKeyPaths.sbMatterAutoNumberDefinitionService });
    })
    .upgradeDatabase(116, function (event, db) {
      db.createObjectStore('sbSubscriptionService', { keyPath: dbKeyPaths.sbSubscriptionService });
    })
    .upgradeDatabase(117, function (event, db) {
      db.createObjectStore('sbUserBillingAttributesService', { keyPath: dbKeyPaths.sbUserBillingAttributesService });
    })
    .upgradeDatabase(118, function (event, db) {
      db.createObjectStore('sbRecentInvoiceService', { keyPath: dbKeyPaths.sbRecentInvoiceService });
      db.createObjectStore('sbRecentMatterService', { keyPath: dbKeyPaths.sbRecentMatterService });
    })
    .upgradeDatabase(119, function (event, db) {
      db.createObjectStore('sbControlledMoneyAccountSettingsService', { keyPath: dbKeyPaths.sbControlledMoneyAccountSettingsService });
    })
    .upgradeDatabase(120, function (event, db) {
      db.createObjectStore('sbTaskingService', { keyPath: dbKeyPaths.sbTaskingService });
    })
    .upgradeDatabase(121, function (event, db) {
      db.createObjectStore('sbBalanceProtectionService', { keyPath: dbKeyPaths.sbBalanceProtectionService });
    })
    .upgradeDatabase(122, function (event, db) {
      db.createObjectStore('sbMatterEmailSettingsService', { keyPath: dbKeyPaths.sbMatterEmailSettingsService });
    })
    .upgradeDatabase(123, function (event, db) {
      db.deleteObjectStore('sbOptimisticUpdatesService');
    })
    .upgradeDatabase(124, function(event, db) {
      db.createObjectStore('sbOptimisticUpdatesService', {keyPath: dbKeyPaths.sbOptimisticUpdatesService});
    })
    .upgradeDatabase(125, function (event, db) {
      db.deleteObjectStore('sbTaskingService');
    })
    .upgradeDatabase(126, function (event, db) {
      db.createObjectStore('sbBankReconciliationSetupService2', { keyPath: 'bankAccountId' });
    })
    .upgradeDatabase(127, function (event, db) {
      db.createObjectStore('sbPaymentProviderSettingsService2', { keyPath: dbKeyPaths.sbPaymentProviderSettingsService2 });
    })
    .upgradeDatabase(128, function (event, db) {
      db.createObjectStore('sbExpensePaymentDetailsService', { keyPath: dbKeyPaths.sbExpensePaymentDetailsService });
    })
    .upgradeDatabase(129, function (event, db) {
      db.createObjectStore('sbCorrespondenceHistoryFullService', { keyPath: dbKeyPaths.sbCorrespondenceHistoryFullService });
    })
    .upgradeDatabase(130, function (event, db) {
      db.deleteObjectStore('sbBankReconciliationSetupService');
    })
    .upgradeDatabase(131, function (event, db) {
      db.deleteObjectStore('sbPaymentProviderSettingsService2');
    })
    .upgradeDatabase(132, function (event, db) {
      db.createObjectStore('sbRateSetsService', { keyPath: dbKeyPaths.sbRateSetsService });
    })
    .upgradeDatabase(133, function (event, db) {
      db.createObjectStore('sbCustomTaskCodeService', { keyPath: dbKeyPaths.sbCustomTaskCodeService });
    })
    .upgradeDatabase(134, function (event, db) {
      db.createObjectStore('sbMatterCommunicationSettingsService', { keyPath: dbKeyPaths.sbMatterCommunicationSettingsService });
    })
    .upgradeDatabase(135, function (event, db) {
      db.deleteObjectStore('sbFeeService');
    })
    .upgradeDatabase(136, function (event, db) {
      db.deleteObjectStore('sbTransactionReceiptService');
      db.createObjectStore('sbTransactionReceiptService', { keyPath: dbKeyPaths.sbTransactionReceiptService });
    })
    .upgradeDatabase(137, function (event, db) {
      db.createObjectStore('sbBulkDepositService', { keyPath: dbKeyPaths.sbBulkDepositService });
    })
    .upgradeDatabase(138, function (event, db) {
      db.deleteObjectStore('sbUserTimerService');
    })
    .upgradeDatabase(139, function (event, db) {
      db.createObjectStore('sbInvoiceDebtorTotalsService', { keyPath: dbKeyPaths.sbInvoiceDebtorTotalsService });
    })
    ;
});

angular.module('@sb-itops/services').value('smokeballDBKeyPaths', dbKeyPaths);
require('./src');
