'use strict';

/**
 * Get the split billing settings should be used for draft invoice screen
 * @param {object} params
 * @param {Object} params.invoiceVersion
 * @param {Object} params.matterSplitBillingSettings
 * @returns {Object}
 */
function deriveInvoiceSplitBillingSettings({ invoiceVersion, matterSplitBillingSettings }) {
  // split billing settings should be fetch from
  // 1) If the invoice has a split billing setting we use it
  // 2) if not exist, fetch from matter settings
  let splitBillingSettings;

  if (invoiceVersion?.splitBillingSettings) {
    splitBillingSettings = { ...invoiceVersion?.splitBillingSettings };
  } else if (matterSplitBillingSettings) {
    splitBillingSettings = { ...matterSplitBillingSettings };
  }
  return splitBillingSettings;
}

module.exports = {
  deriveInvoiceSplitBillingSettings,
};
