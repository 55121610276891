import { useSelector } from 'react-redux';
import composeHooks from '@sb-itops/react-hooks-compose';
import { selectors as appSelectors } from 'web/redux/features/application-state';
import { getOwningCompany, getProductTier } from 'web/services/user-session-management';
import { getFirmDetails } from '@sb-firm-management/redux/firm-management';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { Topbar } from './Topbar';

const hooks = () => ({
  useBranding: () => {
    const isTriConveyBranded = getOwningCompany() === 'TriConvey';
    return { isTriConveyBranded, isGrowProsper: ['SMK002', 'SMK003'].includes(getProductTier()) };
  },
  useFirmDetails: () => {
    const firmDetails = useSelector(() => getFirmDetails());
    if (firmDetails && firmDetails.firmName) {
      return { firmName: firmDetails.firmName };
    }
    return { firmName: '' };
  },
  useVersion: () => {
    // eslint-disable-next-line no-undef
    const buildVersion = SB_BUILD_VERSION; // global
    const serverVersion = useSelector(appSelectors.getServerVersion);

    return {
      buildVersion,
      serverVersion,
    };
  },
});

export const TopbarContainer = withReduxProvider(composeHooks(hooks)(Topbar));
