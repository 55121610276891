import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DatePicker, PaginationSelector, Button, Spinner } from '@sb-itops/react';
import { integerToDate } from '@sb-itops/date';
import { activityCategories } from '@sb-billing/business-logic/activities/entities/constants';
import { entryType as activityEntryTypes } from '@sb-billing/business-logic/shared/entities';
import { FeeSummaryChart, StaffDropdown } from '@sb-billing/react';

import { QuickFeeEntry, FeeTable, FeePopOutEditor } from 'web/components';

import Styles from './FirmFeeEntries.module.scss';

export const FirmFeeEntries = ({
  scope,
  activityDataLoading,
  chartDataLoading,
  chartFees,
  utbmsEnabledForFirm,
  utbmsCodesRequiredByFirm,
  billingIncrementsMins,
  userPrefersDurationsAsUnits,
  registeredForGst,
  taxRateBasisPoints,
  selectedDate,
  onChangeFilterDate,
  selectedChartDay,
  onChartDayChange,
  selectedChartMonth,
  onChartMonthChange,
  staffMemberOptions,
  selectedStaffMember,
  onStaffMemberChange,
  matterSummaries,
  matterSummariesDataLoading,
  matterSummariesHasMore,
  onFetchMatterSummaries,
  onFetchMoreMatterSummaries,
  activities,
  tasks,
  currentFee,
  currentFeePage,
  feeDataLoading,
  fees,
  hidePagination,
  showFeeDateColumn,
  showFeeSidePanel,
  showMatterColumn,
  showStaffInitialsColumn,
  showTaskColumn,
  showTaxColumns,
  sortBy,
  sortDirection,
  totalNumberOfFeePages,
  onClickLink,
  onSetCurrentFee,
  onFeeListPageChange,
  onSort,
  onRowClick,
  onSetShowFeeSidePanel,
  isGeneratingPDFReport,
  onGeneratePDFReport,
  onGenerateCSVReport,
}) => (
  <div className={Styles.firmFeeEntries}>
    <div className={Styles.feeListAndPopoutSection}>
      <div className={classnames('panel-body', Styles.feeListSection)}>
        {/* Filter section */}
        <div className={classnames(Styles.filterSection, 'ribbon')}>
          <div className={Styles.datePicker}>
            <DatePicker value={selectedDate} minDate={integerToDate(19700101)} onSelect={onChangeFilterDate} />
          </div>
          <div className={classnames(Styles.staffPicker, 'input-group')}>
            <StaffDropdown
              staffMemberOptions={staffMemberOptions}
              placeholder="Select staff member..."
              selectedStaffId={selectedStaffMember?.id}
              onSelectionChange={onStaffMemberChange}
              selectClassName={Styles.selectContainerStyling}
            />
          </div>
          <div className={Styles.btnSpacer} />
          <Button className={Styles.download} onClick={onGeneratePDFReport}>
            {isGeneratingPDFReport && <Spinner small />}
            Print
          </Button>
          <Button className={Styles.download} onClick={onGenerateCSVReport}>
            CSV
          </Button>
        </div>

        {/* Quick fee entry section */}
        <div className={Styles.quickFeeEntrySection}>
          <QuickFeeEntry
            scope={scope}
            matterSummaries={matterSummaries}
            matterSummariesDataLoading={matterSummariesDataLoading}
            matterSummariesHasMore={matterSummariesHasMore}
            onFetchMatterSummaries={onFetchMatterSummaries}
            onFetchMoreMatterSummaries={onFetchMoreMatterSummaries}
            activities={activities}
            tasks={tasks}
            quickAddDisabled={!selectedStaffMember || activityDataLoading || chartDataLoading}
            showMatterField
            billingIncrementsMins={billingIncrementsMins}
            showTasksField={utbmsEnabledForFirm}
            utbmsCodesRequiredByFirm={utbmsCodesRequiredByFirm}
            preferDurationAsUnits={userPrefersDurationsAsUnits}
            staffRateConfig={selectedStaffMember}
            registeredForGst={registeredForGst}
            taxRateBasisPoints={taxRateBasisPoints}
            feeDateYYYYMMDD={selectedChartDay}
          />
        </div>
        <div className={Styles.feeTableSection}>
          <FeeTable
            currentFeeId={currentFee?.id}
            currentFeePage={currentFeePage}
            dataLoading={feeDataLoading}
            fees={fees}
            showFeeDateColumn={showFeeDateColumn}
            showMatterColumn={showMatterColumn}
            showStaffInitialsColumn={showStaffInitialsColumn}
            showTaskColumn={showTaskColumn}
            showTaxColumns={showTaxColumns}
            sortBy={sortBy}
            sortDirection={sortDirection}
            taxRateBasisPoints={taxRateBasisPoints}
            totalNumberOfFeePages={totalNumberOfFeePages}
            onClickLink={onClickLink}
            onClickRow={onRowClick}
            onFeeListPageChange={onFeeListPageChange}
            onSort={onSort}
          />
        </div>
        <PaginationSelector
          name="firmFees"
          className={Styles.paginationSection}
          hidePagination={hidePagination}
          numberOfPagesDisplayed={10}
          selectedPage={currentFeePage}
          totalNumberOfPages={totalNumberOfFeePages}
          onPageChange={onFeeListPageChange}
        />
      </div>
      <div className={Styles.popoutEditorSection}>
        <FeePopOutEditor
          feeId={currentFee?.id}
          feeList={fees}
          isExpanded={showFeeSidePanel}
          onFeeChange={onSetCurrentFee}
          onNavigateToInvoice={(invoiceId) => onClickLink({ type: 'invoice', id: invoiceId })}
          onSetIsExpanded={onSetShowFeeSidePanel}
          scope={`${scope}/fee-pop-out-editor`}
        />
      </div>
    </div>
    <div className={Styles.feeSummariesSection}>
      <FeeSummaryChart
        fees={chartFees}
        dataLoading={chartDataLoading}
        selectedDayYYYYMMDD={selectedChartDay}
        onDayChange={onChartDayChange}
        selectedMonthYYYYMM={selectedChartMonth}
        onMonthChange={onChartMonthChange}
      />
    </div>
    <div className={Styles.feePrint}>
      <iframe id="feePdf" title="smokeball-report-viewer" />
    </div>
  </div>
);

FirmFeeEntries.displayName = 'FirmFeeEntries';

const FeeType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  matter: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
});

const StaffEntityType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  isFormerStaff: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
});

const StaffMemberOptionType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  entity: StaffEntityType,
});

FirmFeeEntries.propTypes = {
  scope: PropTypes.string.isRequired,
  activityDataLoading: PropTypes.bool.isRequired, // Temporary hack until we get the activities on init
  chartDataLoading: PropTypes.bool,
  chartFees: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentFee: FeeType,
  currentFeePage: PropTypes.number.isRequired,
  fees: PropTypes.arrayOf(PropTypes.object).isRequired,
  feeDataLoading: PropTypes.bool,
  hidePagination: PropTypes.bool,
  utbmsEnabledForFirm: PropTypes.bool.isRequired,
  utbmsCodesRequiredByFirm: PropTypes.bool.isRequired,
  billingIncrementsMins: PropTypes.number.isRequired,
  userPrefersDurationsAsUnits: PropTypes.bool,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  registeredForGst: PropTypes.bool,
  taxRateBasisPoints: PropTypes.number,
  onChangeFilterDate: PropTypes.func.isRequired,
  staffMemberOptions: PropTypes.arrayOf(StaffMemberOptionType),
  selectedStaffMember: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rate: PropTypes.number,
  }),
  onStaffMemberChange: PropTypes.func.isRequired,
  matterSummaries: PropTypes.arrayOf(
    PropTypes.shape({
      // Fields used in MatterTypeahead
      id: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      typeahead: PropTypes.string.isRequired,
      clientDisplay: PropTypes.string,
      otherSideDisplay: PropTypes.string,
      matterStarted: PropTypes.instanceOf(Date).isRequired,
      matterStartedISO: PropTypes.string.isRequired,
      matterNumber: PropTypes.string,
      // Fields required to create a fee
      billingConfiguration: PropTypes.shape({
        isUtbmsEnabled: PropTypes.bool.isRequired,
        billingType: PropTypes.string,
      }),
      matterHourlyRate: PropTypes.shape({
        rateOverrideType: PropTypes.oneOf([0, 1, 2, 3, undefined]),
        allStaffRate: PropTypes.number,
        ratesPerStaff: PropTypes.arrayOf(
          PropTypes.shape({
            staffId: PropTypes.string.isRequired,
            rate: PropTypes.number.isRequired,
          }).isRequired,
        ),
        billableMinutes: PropTypes.number,
      }),
      matterType: PropTypes.shape({
        name: PropTypes.string,
      }),
    }).isRequired,
  ).isRequired,
  matterSummariesDataLoading: PropTypes.bool,
  matterSummariesHasMore: PropTypes.bool,
  onFetchMatterSummaries: PropTypes.func,
  onFetchMoreMatterSummaries: PropTypes.func,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      isBillable: PropTypes.bool.isRequired,
      type: PropTypes.oneOf(Object.values(activityEntryTypes)),
      units: PropTypes.number,
      isTaxInclusive: PropTypes.bool,
      isTaxExempt: PropTypes.bool,
      rateOverrideType: PropTypes.oneOf([0, 1, 2, undefined]),
      allStaffRate: PropTypes.number,
      ratesPerStaff: PropTypes.arrayOf(
        PropTypes.shape({
          staffId: PropTypes.string.isRequired,
          rate: PropTypes.number.isRequired,
        }).isRequired,
      ),
      description: PropTypes.string.isRequired,
      category: PropTypes.oneOf(Object.values(activityCategories)),
    }).isRequired,
  ).isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  showFeeDateColumn: PropTypes.bool,
  showMatterColumn: PropTypes.bool,
  showStaffInitialsColumn: PropTypes.bool,
  showTaskColumn: PropTypes.bool,
  showTaxColumns: PropTypes.bool,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  totalNumberOfFeePages: PropTypes.number.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onSetCurrentFee: PropTypes.func.isRequired,
  onFeeListPageChange: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  selectedChartDay: PropTypes.string.isRequired,
  onChartDayChange: PropTypes.func.isRequired,
  selectedChartMonth: PropTypes.string.isRequired,
  onChartMonthChange: PropTypes.func.isRequired,
  showFeeSidePanel: PropTypes.bool.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onSetShowFeeSidePanel: PropTypes.func.isRequired,
  isGeneratingPDFReport: PropTypes.bool.isRequired,
  onGeneratePDFReport: PropTypes.func.isRequired,
  onGenerateCSVReport: PropTypes.func.isRequired,
};

FirmFeeEntries.defaultProps = {
  chartDataLoading: false,
  currentFee: undefined,
  feeDataLoading: false,
  hidePagination: false,
  selectedStaffMember: undefined,
  matterSummariesDataLoading: undefined,
  matterSummariesHasMore: undefined,
  onFetchMatterSummaries: undefined,
  onFetchMoreMatterSummaries: undefined,
  showFeeDateColumn: undefined,
  showMatterColumn: undefined,
  showStaffInitialsColumn: undefined,
  showTaskColumn: undefined,
  showTaxColumns: undefined,
  staffMemberOptions: undefined,
  registeredForGst: false,
  taxRateBasisPoints: undefined,
  userPrefersDurationsAsUnits: undefined,
};
