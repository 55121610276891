import React from 'react';
import PropTypes from 'prop-types';
import { Button, buttonTypes, StatelessSelect } from '@sb-itops/react';
import { InvoicePreviewContainer } from './InvoicePreview.container';
import Styles from './SplitBillingInvoicePreview.module.scss';

export const SplitBillingInvoicePreview = (props) => {
  const {
    debtorInvoicePreviewDataMap,
    debtorOptions,
    selectedDebtorOption,
    setSelectedDebtorOption,
    isDownloadingAll,
    onDownloadAll,
  } = props;
  return (
    <div className={Styles.splitBillingInvoicePreview}>
      {debtorOptions.length > 1 && (
        <>
          <div className={Styles.actionBar}>
            <StatelessSelect
              className={Styles.debtorSelect}
              options={debtorOptions}
              selectedOption={selectedDebtorOption}
              onChange={(newDebtor) => setSelectedDebtorOption(newDebtor)}
            />
            <Button
              className={Styles.downloadAllLink}
              type={buttonTypes.link}
              onClick={onDownloadAll}
              disabled={isDownloadingAll}
            >
              <span>DOWNLOAD ALL</span>
            </Button>
          </div>
          <InvoicePreviewContainer
            {...props}
            invoicePreviewData={debtorInvoicePreviewDataMap[selectedDebtorOption.value]}
          />
        </>
      )}
    </div>
  );
};

SplitBillingInvoicePreview.propTypes = {
  debtorOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  debtorInvoicePreviewDataMap: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      invoice: PropTypes.object, // invoice version
      // invoice version must also contain
      // 1. retainerRequestAmount (if applicable)
      // 2. status (label)
      // 3. clients (array of contact objects with address details)
      // 4. footer (decoded html)
      invoiceTotals: PropTypes.object, // invoice totals
      invoiceSettings: PropTypes.object, // invoice settings template
      payments: PropTypes.arrayOf(PropTypes.object), // invoice payments
      priorBalances: PropTypes.object, // supplementary table: prior balance
      statement: PropTypes.object, // supplementary tables: invoice history, account summary, transaction history
      firmDetails: PropTypes.shape({
        abn: PropTypes.string,
        // eslint-disable-next-line react/no-typos
        acn: PropTypes.acn,
      }),
      matter: PropTypes.shape({
        billingConfiguration: PropTypes.object,
      }),
      staff: PropTypes.object, // sbFirmManagementMbService.details, should probably fix the naming once we understand what this really is for
      fees: PropTypes.arrayOf(PropTypes.object), // feeVersion with feeId & feeEarner, once BB-12895 is ON only feeId will be sent
      expenses: PropTypes.arrayOf(PropTypes.object), // expenseVersion with expenseId & expenseEarner, once BB-12895 is ON only expenseId will be sent
    }),
  }).isRequired,
  fetchEntryDetails: PropTypes.bool.isRequired, // force the fetching of entry details on the server end
  showSampleIndicator: PropTypes.bool,
  selectedDebtorOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  isDownloadingAll: PropTypes.bool,
  setSelectedDebtorOption: PropTypes.func.isRequired,
  onDownloadAll: PropTypes.func.isRequired,
};

SplitBillingInvoicePreview.defaultProps = {
  isDownloadingAll: false,
  showSampleIndicator: false,
  selectedDebtorOption: [],
};
