import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PaginationSelector, DropdownMenuButton } from '@sb-itops/react';
import { CorrespondenceHistoryTable } from 'web/components/correspondence-history-table';
import Styles from './FirmAccountsTransactionsClientCommunications.module.scss';

const FirmAccountsTransactionsClientCommunications = React.memo(
  ({
    // sorting
    correspondenceHistorySortBy: sortBy,
    correspondenceHistorySortDirection: sortDirection,
    correspondenceHistoryOnSort: onSort,

    // export
    exportMenuOptions,

    // table
    correspondenceHistory,
    onOpenCorrespondenceHistoryModal,

    // pagination
    hidePagination,
    correspondenceHistoryCurrentPage,
    correspondenceHistoryDataLoading,
    correspondenceHistoryTotalNumberOfPages,
    correspondenceHistoryOnPageChange,
    onClickLink,
    reportGenerationInProgress,
  }) => (
    <div className={classnames('panel-body', Styles.clientCommunicationsRoute)}>
      <div className="ribbon panel panel-primary">
        <DropdownMenuButton
          label="Export"
          menuOptions={exportMenuOptions}
          showSpinner={reportGenerationInProgress}
          disabled={reportGenerationInProgress}
        />
      </div>
      <CorrespondenceHistoryTable
        onClickLink={onClickLink}
        onClickRow={onOpenCorrespondenceHistoryModal}
        dataLoading={correspondenceHistoryDataLoading}
        onSort={onSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        correspondenceHistory={correspondenceHistory}
      />
      <PaginationSelector
        name="firmClientCommunications"
        className={Styles.paginationSection}
        hidePagination={hidePagination}
        numberOfPagesDisplayed={10}
        selectedPage={correspondenceHistoryCurrentPage}
        totalNumberOfPages={correspondenceHistoryTotalNumberOfPages}
        onPageChange={correspondenceHistoryOnPageChange}
      />
      <div className={Styles.correspondenceHistoryPrint}>
        <iframe id="correspondenceHistoryPdf" title="smokeball-report-viewer" />
      </div>
    </div>
  ),
);

FirmAccountsTransactionsClientCommunications.displayName = 'FirmAccountsTransactionsClientCommunications';

FirmAccountsTransactionsClientCommunications.propTypes = {
  correspondenceHistory: PropTypes.array,
  correspondenceHistoryCurrentPage: PropTypes.number.isRequired,
  correspondenceHistoryDataLoading: PropTypes.bool.isRequired,
  hidePagination: PropTypes.bool.isRequired,
  correspondenceHistorySortBy: PropTypes.string.isRequired,
  correspondenceHistorySortDirection: PropTypes.string.isRequired,
  correspondenceHistoryOnSort: PropTypes.func.isRequired,
  exportMenuOptions: PropTypes.array.isRequired,
  reportGenerationInProgress: PropTypes.bool.isRequired,
  correspondenceHistoryTotalNumberOfPages: PropTypes.number.isRequired,
  correspondenceHistoryOnPageChange: PropTypes.func.isRequired,
  // Callbacks
  onClickLink: PropTypes.func.isRequired,
  onOpenCorrespondenceHistoryModal: PropTypes.func.isRequired,
};

FirmAccountsTransactionsClientCommunications.defaultProps = {
  correspondenceHistory: [],
};

export default FirmAccountsTransactionsClientCommunications;
