import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button } from '@sb-itops/react/button';
import { isModalVisible } from '@sb-itops/redux/modal-dialog';
import { Spinner } from '@sb-itops/react/spinner';
import { sort as sortItems, sortDirections } from '@sb-itops/sort';

import { PaymentPlanChart } from '@sb-billing/react/payment-plan-chart';
import { paymentPlanStatuses } from '@sb-billing/business-logic/payment-plan/entities/constants';
import { PaymentPlanInformationBox } from '@sb-billing/react/payment-plan-information-boxes';
import { PaymentPlanPaymentsTable } from '@sb-billing/react/payment-plan-payments-table';
import { ClosePaymentPlanModalDialog } from '@sb-billing/react/close-payment-plan-modal-dialog';

import { featureActive } from '@sb-itops/feature';
import { CollapsibleHeader, useTranslation } from '@sb-itops/react';
import { DebugEntity } from '../../components/debug-entity';
import Styles from './ContactPaymentPlan.module.scss';
import { ExpandableEntityList, entityTypes } from '../../components/expandable-entity-list';
import { PaymentPlanModalDialog } from '../../components/payment-plan-modal-dialog';
import ContactPaymentPlanFilters from './contact-payment-plan-filters/ContactPaymentPlanFilters';

const expandableLists = {
  MATTERS_LIST: 'MATTERS_LIST',
  CONTACTS_LIST: 'CONTACTS_LIST',
  UPCOMING_PAYMENTS: 'UPCOMING_PAYMENTS',
};

const ContactPaymentPlan = memo(
  ({
    showDebug,
    isLoading,
    paymentPlanId,
    paymentPlan,
    activePaymentPlanId,
    contactId,
    paymentPlanModalId,
    showRightPanel,
    startDate,
    lastUpdatedOn,
    lastUpdatedBy,
    lastInstallmentDate,
    paymentsTableSortDirection,
    pastPaymentPlans,
    mattersIncludedInPaymentPlan,
    mattersNotIncludedInPaymentPlan,
    expandedLists,
    isCurrentPaymentPlanActive,
    isContactOwingMoney,
    paymentPlanEvents,
    // callbacks
    triggerAutoCharge,
    onPaymentsTableSortDirectionChange,
    onFilterChanged,
    onToggleClick,
    onCreateNewPaymentPlan,
    onClickLink,
    onAddPayment,
    onEditPaymentPlan,
    closePaymentPlanModalId,
    onShowClosePaymentPlanConfirmation,
    onClosePaymentPlan,
    onNewPaymentPlanCreated,
    refetch,
  }) => {
    const { t } = useTranslation();
    const renderRightPanel = () => (
      <>
        {paymentPlan.status === paymentPlanStatuses.CLOSED && (
          <div className={Styles.planStatusBox}>
            {' '}
            Closed on {t('date', { ts: lastUpdatedOn })} <br />
            by {lastUpdatedBy}{' '}
          </div>
        )}
        {mattersIncludedInPaymentPlan.length > 0 && (
          <ExpandableEntityList
            label="Matter(s) Included"
            expanded={!expandedLists.includes(expandableLists.MATTERS_LIST)}
            list={mattersIncludedInPaymentPlan.map((matter) => ({
              entityType: entityTypes.MATTER,
              entity: matter,
            }))}
            onClickLink={onClickLink}
            onToggleClick={() => onToggleClick(expandableLists.MATTERS_LIST)}
          />
        )}
        <ExpandableEntityList
          label="Matter(s) Not Included"
          expanded={!expandedLists.includes(expandableLists.CONTACTS_LIST)}
          list={mattersNotIncludedInPaymentPlan.map((matter) => ({
            entityType: entityTypes.MATTER,
            entity: matter,
          }))}
          onClickLink={onClickLink}
          onToggleClick={() => onToggleClick(expandableLists.CONTACTS_LIST)}
        />
        {!mattersNotIncludedInPaymentPlan.length && !expandedLists.includes(expandableLists.CONTACTS_LIST) && (
          <div className={Styles.contactWithoutMatters}>
            This debtor is not on any other matters with an outstanding amount.
          </div>
        )}
        <div className={Styles.topPadding}>
          <CollapsibleHeader
            text="Upcoming Payments"
            onClick={() => onToggleClick(expandableLists.UPCOMING_PAYMENTS)}
            name="Upcoming Payments"
            collapsed={expandedLists.includes(expandableLists.UPCOMING_PAYMENTS)}
          />
        </div>
        {!expandedLists.includes(expandableLists.UPCOMING_PAYMENTS) && (
          <>
            {paymentPlan.installments.reduce((acc, installment) => {
              if (acc.length < 5 && moment(installment.date, 'YYYYMMDD').isAfter(moment())) {
                acc.push(
                  <div key={installment.date} className={Styles.upcomingPayments}>
                    <span>{t('date', { yyyymmdd: installment.date })}</span>
                    <span>{t('cents', { val: installment.amount })}</span>
                  </div>,
                );
              }
              return acc;
            }, [])}
            <div className={classnames(Styles.contactWithoutMatters, Styles.topPadding)}>
              Up to the next five payments are displayed above.
            </div>
          </>
        )}
      </>
    );

    const isPaymentPlanModalVisible = isModalVisible({ modalId: paymentPlanModalId });

    return (
      <>
        {isPaymentPlanModalVisible && (
          <PaymentPlanModalDialog
            selectedPaymentPlanId={(isCurrentPaymentPlanActive && paymentPlanId) || undefined}
            paymentPlanModalId={paymentPlanModalId}
            modalDialogTitle={`${(isCurrentPaymentPlanActive && paymentPlanId && 'Edit') || 'Create'} Payment Plan`}
            contactId={contactId}
            onNewPaymentPlanCreated={onNewPaymentPlanCreated}
          />
        )}
        <ClosePaymentPlanModalDialog
          closePaymentPlanModalId={closePaymentPlanModalId}
          onClosePaymentPlan={onClosePaymentPlan}
        />
        <div className={classnames('master-detail-panel', Styles.panelContainer)}>
          {(pastPaymentPlans.length > 0 || activePaymentPlanId) && (
            <div className={classnames('panel-filter', Styles.leftPanel)}>
              <ContactPaymentPlanFilters
                selectedPaymentPlanId={paymentPlanId}
                pastPaymentPlans={sortItems(pastPaymentPlans, ['startDate'], [sortDirections.DESC])}
                activePaymentPlanId={activePaymentPlanId}
                onFilterChanged={onFilterChanged}
              />
            </div>
          )}
          <div className={classnames('panel-body', Styles.viewPanel)}>
            {isLoading && (
              <div className={Styles.spinnerContainer}>
                <Spinner />
              </div>
            )}
            {!isLoading && (
              <div className={Styles.viewToolbar}>
                <div>
                  {featureActive('BB-9674') && (
                    <button onClick={() => refetch()} type="button">
                      Trigger GraphQL
                    </button>
                  )}
                </div>
                {!isCurrentPaymentPlanActive && !activePaymentPlanId && (
                  <Button
                    className={Styles.toolbarButton}
                    onClick={onCreateNewPaymentPlan}
                    disabled={!isContactOwingMoney}
                    type="tertiary"
                  >
                    create new plan
                  </Button>
                )}
                {isCurrentPaymentPlanActive && (
                  <>
                    <Button className={Styles.toolbarButton} type="tertiary" onClick={() => onAddPayment(contactId)}>
                      add payment
                    </Button>
                    <Button className={Styles.toolbarButton} type="tertiary" onClick={onEditPaymentPlan}>
                      edit plan
                    </Button>
                    <Button
                      className={Styles.toolbarButton}
                      disabled={!isCurrentPaymentPlanActive}
                      type="tertiary"
                      onClick={onShowClosePaymentPlanConfirmation}
                    >
                      close plan
                    </Button>
                    {showDebug && (
                      <Button
                        className={Styles.toolbarButton}
                        disabled={!paymentPlan.autoCharge}
                        type="tertiary"
                        onClick={triggerAutoCharge}
                      >
                        trigger next auto charge installment
                      </Button>
                    )}
                  </>
                )}
              </div>
            )}
            {!isLoading && (
              <div className={Styles.viewContainer}>
                {isContactOwingMoney &&
                  !paymentPlanId &&
                  (pastPaymentPlans.length === 0 && !activePaymentPlanId
                    ? 'This contact has no payment plans.'
                    : 'This contact does not have a current payment plan.')}
                {isContactOwingMoney === undefined && ''}
                {!paymentPlanId && !isContactOwingMoney && 'Creating a payment plan requires an outstanding balance.'}
                {paymentPlanId && (
                  <div className={Styles.paymentPlanView}>
                    <DebugEntity entityCacheName="sbPaymentPlansService" entityId={paymentPlanId} />
                    <div className={Styles.chart}>
                      <PaymentPlanChart paymentPlan={paymentPlan} paymentPlanEvents={paymentPlanEvents} height={400} />
                    </div>
                    <div className={Styles.boxes}>
                      <PaymentPlanInformationBox paymentPlan={paymentPlan} paymentPlanEvents={paymentPlanEvents} />
                    </div>
                    <div className={Styles.planDescriptionLabel}>
                      Plan started on {t('date', { yyyymmdd: startDate })} by {lastUpdatedBy}.{' '}
                      {lastInstallmentDate && `Projected end is ${t('date', { yyyymmdd: lastInstallmentDate })}.`}
                    </div>
                    <div className={Styles.paymentsTable}>
                      <PaymentPlanPaymentsTable
                        paymentPlanId={paymentPlanId}
                        paymentPlanEvents={paymentPlanEvents}
                        sortDirection={paymentsTableSortDirection}
                        onPaymentsSortDirectionChange={onPaymentsTableSortDirectionChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {showRightPanel && (
            <div className={classnames('panel-filter', Styles.rightPanel)}> {renderRightPanel()} </div>
          )}
        </div>
      </>
    );
  },
);

ContactPaymentPlan.displayName = 'ContactPaymentPlan';

ContactPaymentPlan.propTypes = {
  showDebug: PropTypes.bool,
  isLoading: PropTypes.bool,
  paymentPlanId: PropTypes.string,
  paymentPlan: PropTypes.object,
  contactId: PropTypes.string,
  paymentPlanModalId: PropTypes.string.isRequired,
  showRightPanel: PropTypes.bool,
  paymentsTableSortDirection: PropTypes.string,
  startDate: PropTypes.number,
  lastUpdatedOn: PropTypes.string,
  lastUpdatedBy: PropTypes.string,
  lastInstallmentDate: PropTypes.number,
  pastPaymentPlans: PropTypes.array,
  activePaymentPlanId: PropTypes.string,
  mattersIncludedInPaymentPlan: PropTypes.arrayOf(PropTypes.object),
  mattersNotIncludedInPaymentPlan: PropTypes.arrayOf(PropTypes.object),
  expandedLists: PropTypes.arrayOf(PropTypes.string),
  isCurrentPaymentPlanActive: PropTypes.bool,
  isContactOwingMoney: PropTypes.bool,
  closePaymentPlanModalId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.object,
  refetch: PropTypes.func,
  paymentPlanEvents: PropTypes.array,
  // callbacks
  triggerAutoCharge: PropTypes.func.isRequired,
  onCreateNewPaymentPlan: PropTypes.func.isRequired,
  onPaymentsTableSortDirectionChange: PropTypes.func.isRequired,
  onFilterChanged: PropTypes.func.isRequired,
  onToggleClick: PropTypes.func.isRequired,
  onAddPayment: PropTypes.func.isRequired,
  onEditPaymentPlan: PropTypes.func.isRequired,
  onShowClosePaymentPlanConfirmation: PropTypes.func.isRequired,
  onClosePaymentPlan: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onNewPaymentPlanCreated: PropTypes.func.isRequired,
};

ContactPaymentPlan.defaultProps = {
  showDebug: false,
  isLoading: false,
  paymentPlanId: undefined,
  paymentPlan: undefined,
  contactId: undefined,
  showRightPanel: false,
  paymentsTableSortDirection: undefined,
  lastInstallmentDate: undefined,
  startDate: undefined,
  lastUpdatedOn: undefined,
  lastUpdatedBy: undefined,
  pastPaymentPlans: [],
  activePaymentPlanId: undefined,
  mattersIncludedInPaymentPlan: [],
  paymentPlanEvents: [],
  mattersNotIncludedInPaymentPlan: [],
  expandedLists: undefined,
  isCurrentPaymentPlanActive: undefined,
  isContactOwingMoney: undefined,
  loading: false,
  error: {},
  refetch: undefined,
};

export default ContactPaymentPlan;
