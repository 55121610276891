import * as React from 'react';
import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import {
  isPayButtonEnabledForMatter,
  payButtonEnabledOptionValues,
} from '@sb-billing/business-logic/matters/invoice-settings';
import { buildContactToAddress } from '@sb-customer-management/business-logic/contacts/services';
import { regionType } from '@sb-itops/region';
import { capitalize } from '@sb-itops/nodash';
import { hasFacet, facets } from '@sb-itops/region-facets';
import {
  appendPaymentAndEInvoicePlaceholders,
  getContactFirstAndLastNames,
  getDefaultCommunicateMessage,
} from '@sb-billing/business-logic/invoice-via-communicate';
import { determineEInvoiceEnabled } from '@sb-billing/business-logic/einvoice';
import { featureActive } from '@sb-itops/feature';
import { getUserId } from 'web/services/user-session-management';
import { getLogger } from '@sb-itops/fe-logger';
import { yupSchemaValidator } from '@sb-itops/business-logic/validation/services';
import { dot as nestedObjectToFlattened } from 'dot-object';
import { InvoiceCommunicateModal } from './InvoiceCommunicateModal';

import { multiTabInvoiceCommunicateFormSchema } from './MultiTabInvoiceCommunicateForm.yup';

const log = getLogger('MultiTabInvoiceCommunicateModal.forms.container');

/**
 * Validation function that will validate each tab
 *
 * @param {object} formFields
 * @returns {object}
 */
function validateFn(formFields) {
  const tabsFormDataErrors = Object.entries(formFields.tabsFormData).reduce((acc, [debtorId, tabFormData]) => {
    if (tabFormData.includeDebtor) {
      const errors = yupSchemaValidator(tabFormData, multiTabInvoiceCommunicateFormSchema);
      if (Object.keys(errors).length) {
        acc[debtorId] = errors;
      }
    }
    return acc;
  }, {});

  // Forms 2 expects errors to be reported as flattened dot object notation.
  const errors = Object.keys(tabsFormDataErrors).length
    ? nestedObjectToFlattened({ tabsFormData: tabsFormDataErrors })
    : {};

  return errors;
}

/**
 * Builds the communicate requests required to send an invoice to multiple debtors
 *
 * @param {object} params
 * @param {object} params.formValues
 * @param {string[]} params.invoiceIds
 * @param {object} params.invoiceTotalDurationsByIdMap
 * @returns {object}
 */
function convertToInvoiceCommunicateRequests({ formValues, invoiceIds, invoiceTotalDurationsByIdMap }) {
  if (!formValues || !invoiceIds || !invoiceTotalDurationsByIdMap) {
    return [];
  }

  const invoiceCommunicateRequests = Object.entries(formValues.tabsFormData).reduce((acc, [debtorId, tabFormData]) => {
    const { debtorFirstName, debtorLastName, fromUserId, includeDebtor, message, toAddress } = tabFormData;

    if (!includeDebtor) {
      return acc;
    }

    const request = {
      invoiceIds,
      invoiceTotalDurationsByIdMap,
      debtorId,
      template: {
        debtorFirstName,
        debtorLastName,
        fromUserId,
        message,
        toAddress,
      },
    };

    acc.push(request);
    return acc;
  }, []);

  return invoiceCommunicateRequests;
}

const hooks = () => ({
  useInvoiceCommunicateModalForm: ({
    firmCanIncludePaymentLinks,
    firmEInvoiceSettings,
    invoiceCommunicateModalData,
    invoiceIds,
    invoiceTotalDurationsByIdMap,
    isLoadingQuery,
    region,
    scope,
    showPreviewButton,
    // Callbacks
    onModalClose,
    onPreview,
    onSend,
  }) => {
    const invoiceCommunicateForm = useForm({ scope, validateFn });
    const { formFields, formInitialised, formSubmitting, formValid } = invoiceCommunicateForm;
    const selectedTabId = invoiceCommunicateForm.formValues.selectedTabId;

    // Multi-tab form is only for single invoice
    const invoice = invoiceCommunicateModalData?.invoices[0];

    /**
     * Communicate preview
     */

    const [isLoadingPreview, setIsLoadingPreview] = React.useState(showPreviewButton);
    const [previewData, setPreviewData] = React.useState({});
    const previewDataForSelectedTab = previewData[selectedTabId] || {};

    const onPreviewToggled = () => {
      setPreviewData({
        ...previewData,
        [selectedTabId]: {
          ...previewDataForSelectedTab,
          isPreviewMode: !previewDataForSelectedTab.isPreviewMode,
        },
      });
    };

    React.useEffect(() => {
      (async () => {
        const initialPreview = previewDataForSelectedTab.isPreviewMode === undefined;

        if (formInitialised && showPreviewButton && (previewDataForSelectedTab.isPreviewMode || initialPreview)) {
          const invoiceCommunicateRequests = convertToInvoiceCommunicateRequests({
            formValues: invoiceCommunicateForm.formValues,
            invoiceIds,
            invoiceTotalDurationsByIdMap,
          });

          // Convert to previews
          const previews = await Promise.all(
            invoiceCommunicateRequests.map(async (invoiceCommunicateRequest) => {
              const previewValues = await onPreview({
                invoiceCommunicateRequest,
              });

              return {
                debtorId: invoiceCommunicateRequest.debtorId,
                isPreviewMode: initialPreview ? true : previewData[invoiceCommunicateRequest.debtorId].isPreviewMode,
                previewMessage: previewValues.message,
              };
            }),
          );

          // Store previews by debtorId
          const previewsByDebtorId = previews.reduce((acc, preview) => {
            acc[preview.debtorId] = preview;
            return acc;
          }, {});

          setPreviewData(previewsByDebtorId);

          // For the initial loading of the modal
          if (isLoadingPreview) {
            setIsLoadingPreview(false);
          }
        }
      })();
      // invoiceCommunicateModalData is required to re-render the previews from query refetches
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formInitialised, invoiceCommunicateModalData, previewDataForSelectedTab.isPreviewMode]);

    /**
     * Form initialisation
     */

    function getDefaulFormValues() {
      const { matter } = invoice;

      const eInvoiceEnabled =
        featureActive('BB-5725') && hasFacet(facets.eInvoiceUserDefinedSwitch)
          ? determineEInvoiceEnabled({
              matterInvoiceSettings: matter?.matterInvoiceSettings,
              eInvoiceSettings: firmEInvoiceSettings,
            })
          : featureActive('BB-5725');

      const payButtonEnabledForMatter = matter?.matterInvoiceSettings
        ? isPayButtonEnabledForMatter({ matterInvoiceSettings: matter.matterInvoiceSettings })
        : firmCanIncludePaymentLinks;

      const communicateMessage = appendPaymentAndEInvoicePlaceholders({
        message: getDefaultCommunicateMessage(),
        isMultiDebtorInvoice: true,
        eInvoiceEnabled,
        paymentProviderEnabledForInvoicePayment: firmCanIncludePaymentLinks,
        payButtonEnabledForMatter,
      });

      const tabsFormData = invoice?.debtors?.reduce(
        (acc, debtor) => {
          const { contact } = debtor;
          const { contactFirstName, contactLastName } = getContactFirstAndLastNames({
            isToAddressReadOnly: invoiceCommunicateForm.formValues.isToAddressReadOnly,
            contact,
          });
          const toAddress = buildContactToAddress({ contact, region });

          // toAddress field is disabled when:
          //  1. Valid toAddress
          //  2. For contacts of type "organisation" or "groupOfPeople" without emails (to prevent the user from sending the communicate request)
          const isToAddressReadOnly =
            !!toAddress || (!toAddress && (contact.type === 'organisation' || contact.type === 'groupOfPeople'));

          const tabFormDataForDebtor = {
            debtorDisplayName: contact.displayNameFirstLast,
            debtorFirstName: contactFirstName,
            debtorLastName: contactLastName,
            debtorType: capitalize(contact.type),
            fromUserId: getUserId(),
            includeDebtor: true,
            isToAddressReadOnly,
            message: communicateMessage,
            toAddress,
          };

          acc.tabsFormData[debtor.id] = tabFormDataForDebtor;
          return acc;
        },
        {
          selectedTabId: invoice?.debtors[0]?.id,
          tabsFormData: {},
        },
      );

      return tabsFormData;
    }

    const initialiseForm = !isLoadingQuery && !formInitialised;

    if (initialiseForm) {
      const defaultValues = getDefaulFormValues();
      invoiceCommunicateForm.onInitialiseForm(defaultValues);
      invoiceCommunicateForm.onValidateForm();
    }

    React.useEffect(
      () => () => invoiceCommunicateForm.onClearForm(),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    /**
     * Form updates
     */

    function onUpdateFormData({ fieldUpdates }) {
      invoiceCommunicateForm.onUpdateFields(fieldUpdates);
      invoiceCommunicateForm.onValidateForm();
    }

    function onFieldValueUpdated(field, newValue) {
      const newFieldValue = {
        tabsFormData: {
          [selectedTabId]: {
            [field]: newValue,
          },
        },
      };

      onUpdateFormData({ fieldUpdates: newFieldValue });
    }

    /**
     * Form submission
     */

    async function onFormSubmit() {
      try {
        invoiceCommunicateForm.onValidateForm();

        const isValid = await invoiceCommunicateForm.onSubmitFormWithValidation({
          submitFnP: async (formData) => {
            const invoiceCommunicateRequests = convertToInvoiceCommunicateRequests({
              formValues: formData,
              invoiceIds,
              invoiceTotalDurationsByIdMap,
            });

            onSend({ invoiceCommunicateRequests });
          },
        });

        if (!isValid) {
          log.warn('Invoice communicate modal form validation failed');
          return;
        }

        onModalClose();
      } catch (error) {
        log.error('Failed to send invoice communicate', error);
      }
    }

    /**
     * Tabs
     */

    const { tabsFormData } = formFields;
    const selectedTabFormData = tabsFormData?.[selectedTabId];

    const contactDisplayNameByIdMap = React.useMemo(() => {
      if (!tabsFormData) {
        return {};
      }

      const debtorIds = Object.keys(tabsFormData);
      const mappedResult = Object.values(tabsFormData).reduce((acc, tabFormData, index) => {
        acc[debtorIds[index]] = tabFormData.debtorDisplayName.value;
        return acc;
      }, {});
      return mappedResult;
    }, [tabsFormData]);

    const tabs = tabsFormData
      ? Object.entries(tabsFormData).map(([debtorId, tabFormData]) => ({
          text: contactDisplayNameByIdMap[debtorId],
          id: debtorId,
          debtorIncluded: tabsFormData[debtorId].includeDebtor.value,
          tabValid: Object.values(tabFormData).every((field) => field.isValid),
        }))
      : [];

    const atLeastOneTabWillSendEmail = tabsFormData
      ? Object.values(tabsFormData).some((tabFormData) => tabFormData.includeDebtor.value)
      : false;

    function onChangeTab({ newlySelectedTabId }) {
      onUpdateFormData({
        fieldUpdates: {
          selectedTabId: newlySelectedTabId,
        },
      });
    }

    return {
      formInitialised,
      isLoading: isLoadingQuery || isLoadingPreview,
      isSubmitDisabled: !formValid || formSubmitting || !atLeastOneTabWillSendEmail,
      showIncludeDebtor: true,
      showPreviewButton: true,
      ...selectedTabFormData,
      isPreviewMode: previewDataForSelectedTab.isPreviewMode ?? true,
      previewMessage: previewDataForSelectedTab.previewMessage ?? '',
      // Callbacks
      onFieldValueUpdated,
      onFormSubmit,
      onPreviewToggled,
      // Tabs
      ...selectedTabFormData,
      selectedTabId,
      tabs,
      onChangeTab,
    };
  },
});

const dependentHooks = () => ({});

export const MultiTabInvoiceCommunicateModalFormsContainer = composeHooks(hooks)(
  composeHooks(dependentHooks)(InvoiceCommunicateModal),
);

MultiTabInvoiceCommunicateModalFormsContainer.displayName = 'MultiTabInvoiceCommunicateModalFormsContainer';

const DebtorEntityType = PropTypes.shape({
  contact: PropTypes.shape({
    id: PropTypes.string.isRequired,
    customLetterName: PropTypes.string,
    displayNameFirstLast: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    letterNameFormat: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
});

const MatterEntityType = PropTypes.shape({
  id: PropTypes.string,
  matterInvoiceSettings: PropTypes.shape({
    id: PropTypes.string,
    eInvoiceEnabledOption: PropTypes.number,
    payButtonEnabledOption: PropTypes.oneOf(Object.values(payButtonEnabledOptionValues)),
  }),
});

MultiTabInvoiceCommunicateModalFormsContainer.propTypes = {
  firmCanIncludePaymentLinks: PropTypes.bool.isRequired,
  invoiceIds: PropTypes.arrayOf(PropTypes.string),
  invoiceTotalDurationsByIdMap: PropTypes.object.isRequired,
  isLoadingQuery: PropTypes.bool.isRequired,
  region: PropTypes.oneOf(Object.values(regionType)).isRequired,
  scope: PropTypes.string.isRequired,
  showPreviewButton: PropTypes.bool.isRequired,
  // Required props but initially undefined
  firmEInvoiceSettings: PropTypes.shape({
    eInvoiceDisabled: PropTypes.bool.isRequired,
  }),
  invoiceCommunicateModalData: PropTypes.shape({
    invoices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        debtors: PropTypes.arrayOf(DebtorEntityType),
        matter: MatterEntityType,
      }),
    ),
  }),
  // Callbacks
  onModalClose: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
};

MultiTabInvoiceCommunicateModalFormsContainer.defaultProps = {
  firmEInvoiceSettings: undefined,
  invoiceCommunicateModalData: undefined,
};
