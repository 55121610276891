import PropTypes from 'prop-types';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import composeHooks from '@sb-itops/react-hooks-compose';
import {
  InitUserBillingAttributes,
  MatterStatusCounts,
  MatterTableData,
  MatterTypesFilter,
  StaffMembersList,
} from 'web/graphql/queries';
import * as panelExpanderFeature from '@sb-itops/redux/panel-expander';
import { useDispatch, useSelector } from 'react-redux';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { useCacheQuery, usePagination, useSubscribedQuery } from 'web/hooks';
import { useSort } from '@sb-itops/redux/sort/use-sort';
import { useMultipleItemSelection } from '@sb-itops/redux/multi-item-select/use-multiple-item-selection';
import { useRef } from 'react';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { useSingleItemSelection } from '@sb-itops/redux/single-item-select/use-single-item-selection';
import { useTranslation } from '@sb-itops/react';
import { capitalize } from '@sb-itops/nodash';

import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { hasBillingAccess } from 'web/services/user-session-management';

import { BillingMattersRoute } from './BillingMattersRoute';

const FETCH_LIMIT = 50;
const SCOPE = 'BillingMattersRoute';
const SCOPE_PAGINATION = `${SCOPE}/pagination`;
const SCOPE_SORTING = `${SCOPE}/sorting`;
const SCOPE_FILTER_STATUS = `${SCOPE}/statusFilter`;
const SCOPE_FILTER_STAFF = `${SCOPE}/staffFilter`;
const SCOPE_FILTER_MATTER_TYPE = `${SCOPE}/matterType`;
const SCOPE_FILTER_TRUST_BALANCE = `${SCOPE}/matterTrustBalance`;
const SCOPE_FILTER_MATTER_RECENT = `${SCOPE}/matterRecentOnly`;

const hooks = () => ({
  useFilterTabs: () => {
    const { selectors, actions } = useScopedFeature(panelExpanderFeature, `${SCOPE}/mattersRoutePanel`);
    const expanded = useSelector(selectors.getExpanded);
    const dispatch = useDispatch();

    return {
      expanded,
      toggleExpanded: () => {
        dispatch(actions.toggleExpanded());
      },
    };
  },
  useMatterStatusFilter: () => {
    const statusFilter = useRef(false);
    const {
      selectedItems: mattersSelectedStatus,
      toggleItem: mattersOnToggleStatus,
      selectItems: mattersOnSelectStatus,
      deselectAllItems: mattersOnDeselectAllStatus,
    } = useMultipleItemSelection({
      scope: SCOPE_FILTER_STATUS,
    });

    if (!statusFilter.current) {
      mattersOnSelectStatus(['Open']);
      statusFilter.current = true;
    }

    const mattersSelectedStatusIds = Object.keys(mattersSelectedStatus).map((status) => status.toLowerCase());

    return {
      mattersSelectedStatusIds,
      mattersOnToggleStatus,
      mattersOnSelectStatus,
      mattersOnDeselectAllStatus,
    };
  },
  useStaffMemberFilter: () => {
    const {
      selectedItems: staffSelectedIds,
      toggleItem: staffOnToggle,
      selectItems: staffOnSelect,
      deselectAllItems: staffOnDeselectAll,
    } = useMultipleItemSelection({
      scope: SCOPE_FILTER_STAFF,
    });

    return { staffSelectedIds, staffOnToggle, staffOnSelect, staffOnDeselectAll };
  },
  useMatterTypeSelection: () => {
    const { selectedItems, selectItems: matterTypesOnSelect } = useMultipleItemSelection({
      scope: SCOPE_FILTER_MATTER_TYPE,
    });

    return { matterTypeSelectedIds: Object.keys(selectedItems), matterTypesOnSelect };
  },
  useMatterTrustBalanceFilter: () => {
    const {
      formValues: matterTrustBalanceFormValues,
      onUpdateFieldValues: matterTrustBalanceOnUpdateForm,
      onClearForm: matterTrustBalanceOnClearForm,
    } = useForm({
      scope: SCOPE_FILTER_TRUST_BALANCE,
    });

    return { matterTrustBalanceFormValues, matterTrustBalanceOnUpdateForm, matterTrustBalanceOnClearForm };
  },
  useMatterRecentOnlyFilter: () => {
    const { selectedItem: matterRecentlyViewed, setSelectedItem } = useSingleItemSelection({
      scope: SCOPE_FILTER_MATTER_RECENT,
      initialSelection: false,
    });

    return {
      matterRecentlyViewedToggle: () => setSelectedItem(!matterRecentlyViewed),
      matterRecentlyViewed,
    };
  },
  usePagination: () => {
    const {
      currentPage: mattersCurrentPage,
      setPageNumber,
      getPagination,
    } = usePagination({
      scope: SCOPE_PAGINATION,
      fetchLimit: FETCH_LIMIT,
    });

    const mattersOnPageChange = ({ selected: pageNumber }) => setPageNumber(pageNumber);

    return { mattersCurrentPage, mattersOnPageChange, getPagination };
  },
  usePermissions: () => ({
    hasBillingAccess: hasBillingAccess(),
  }),
  useSorting: () => {
    const {
      sortBy: mattersSortBy,
      setSortBy,
      sortDirection: mattersSortDirection,
      setSortDirection,
    } = useSort({
      scope: SCOPE_SORTING,
      initialSortBy: featureActive('BB-14072') ? 'matterNumber' : 'clientDisplayName',
      initialSortDirection: featureActive('BB-14072') ? 'desc' : 'asc',
    });

    const mattersOnSort = (sortProps) => {
      setSortBy(sortProps.sortBy);
      setSortDirection(sortProps.sortDirection);
    };

    return { mattersOnSort, mattersSortBy, mattersSortDirection };
  },
  useScopes: () => ({
    matterFilterScope: `${SCOPE}/matterFilter`,
  }),
  useProps: () => {
    const { t } = useTranslation();
    return {
      allowTrustOverdraw: hasFacet(facets.allowOverdraw),
      // BB-6595 has been permanently turned on so can be cleaned up
      // See https://smokeball.slack.com/archives/C04UD6EHY67/p1682487075955479
      excludeBillableLeadMatters: !featureActive('BB-6595') || featureActive('NUCWEB-170'),
      excludeFormerStaff: true,
      excludeStaffWithoutMatter: true,
      includeDeleted: true,
      mattersStatusAllLabel: 'All',
      mattersStatusOptions: [
        { id: 'open', label: 'Open' },
        { id: 'pending', label: 'Pending' },
        { id: 'closed', label: 'Closed' },
        { id: 'deleted', label: 'Deleted' },
        { id: 'cancelled', label: capitalize(t('cancelled')) },
      ],
    };
  },
});

const queryHooks = ({
  excludeBillableLeadMatters,
  excludeFormerStaff,
  excludeStaffWithoutMatter,
  getPagination,
  matterRecentlyViewed,
  matterTrustBalanceFormValues,
  matterTypeSelectedIds,
  includeDeleted,
  mattersCurrentPage,
  mattersSelectedStatusIds,
  mattersSortBy,
  mattersSortDirection,
  mattersStatusOptions,
  staffSelectedIds,
}) => ({
  useUserBillingAttributesData: () => {
    const { data, error, loading: userViewedLoading } = useCacheQuery(InitUserBillingAttributes.query);

    if (error) {
      throw new Error(error);
    }

    const userViewedMessages = data?.userBillingAttributes?.viewedMessages || [];

    return { userViewedMessages, userViewedLoading };
  },
  useMatterStatusFilterData: () => {
    const {
      data,
      error,
      loading: matterStatusCountsLoading,
    } = useSubscribedQuery(MatterStatusCounts, {
      variables: {
        filter: {
          excludeBillableLeadMatters,
          matterStatus: mattersStatusOptions.map(({ id }) => id),
        },
      },
    });

    if (error) {
      throw new Error(error);
    }

    const matterStatusCounts = (data?.matterStatusCounts || []).reduce((acc, { status, count }) => {
      acc[status] = count;
      return acc;
    }, {});

    return { matterStatusCounts, matterStatusCountsLoading };
  },
  useStaffMemberFilterData: () => {
    const { data, error } = useSubscribedQuery(
      StaffMembersList,
      {
        variables: { filter: { excludeFormerStaff, excludeStaffWithoutMatter } },
      },
      {
        notificationIds: [...StaffMembersList.notificationIds, 'MatterManagementWebQuery.MatterUpdated'],
      },
    );

    if (error) {
      throw new Error(error);
    }

    const staffMembers = data?.staffMembersList?.results || [];

    return { staffMembers };
  },
  useMatterTypesFilterData: () => {
    const matterTypes = useRef();
    const { data, error, loading } = useSubscribedQuery(MatterTypesFilter, {
      variables: {
        filter: {
          excludeMatterTypeWithoutMatter: true,
          excludeLeads: featureActive('NUCWEB-170'),
        },
      },
    });

    if (error) {
      throw new Error(error);
    }

    if (!loading && data?.matterTypeList?.results) {
      matterTypes.current = data?.matterTypeList?.results;
    }

    return { matterTypes: matterTypes.current };
  },
  useMatterTableData: () => {
    const { minimumTrustBalance, maximumTrustBalance } = matterTrustBalanceFormValues;
    const skipMattersQuery = mattersSelectedStatusIds.length === 0;

    const {
      data,
      error,
      loading: mattersLoading,
    } = useSubscribedQuery(
      MatterTableData,
      {
        context: { skipRequestBatching: true },
        skip: skipMattersQuery,
        variables: {
          matterListFilter: {
            excludeBillableLeadMatters,
            includeDeleted,
            matterStatus: mattersSelectedStatusIds,
            matterTypes: matterTypeSelectedIds,
            trustBalanceMinimum: minimumTrustBalance,
            trustBalanceMaximum: maximumTrustBalance,
            staffMembersIds: Object.keys(staffSelectedIds),
            showUserRecentMatter: matterRecentlyViewed,
          },
          offset: mattersCurrentPage * FETCH_LIMIT,
          limit: FETCH_LIMIT,
          sort: !mattersSortBy
            ? undefined
            : { fieldNames: [mattersSortBy], directions: [`${mattersSortDirection || 'ASC'}`.toUpperCase()] },
          includeIsOverdrawn: hasFacet(facets.allowOverdraw),
        },
      },
      {
        notificationIds: matterRecentlyViewed
          ? [...MatterTableData.notificationIds, 'WebQueryBillingMattersNotifications.RecentMattersUpdated']
          : MatterTableData.notificationIds,
      },
    );

    if (error) {
      throw new Error(error);
    }

    const matters = data?.matterList?.results || [];
    const mattersTotalCount = data?.matterList?.totalCount || 0;

    const { hidePagination: isPaginationHidden, totalNumberOfPages: mattersTotalNumberOfPages } = getPagination({
      totalCount: mattersTotalCount,
      loading: mattersLoading,
    });

    return {
      mattersLoading,
      matters,
      isPaginationHidden,
      mattersTotalNumberOfPages,
    };
  },
});

export const BillingMattersRouteContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(queryHooks)(BillingMattersRoute))),
);

BillingMattersRouteContainer.displayName = 'BillingMattersRouteContainer';

BillingMattersRouteContainer.propTypes = {
  onClickLink: PropTypes.func,
};

BillingMattersRouteContainer.defaultProps = {
  onClickLink: () => {},
};
