import * as React from 'react';
import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import { featureActive } from '@sb-itops/feature';
import { integerToDate } from '@sb-itops/date';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import {
  entryType as entryTypeEnum,
  durationType as durationTypeEnum,
} from '@sb-billing/business-logic/shared/entities';
import { activityCategories } from '@sb-billing/business-logic/activities/entities/constants';
import {
  calculateFeeDescriptionMaxLength,
  deriveFeeBillableStatus,
  deriveNewFeeBillableStatus,
  accumulateSourceItemsDurations,
  calculateDurationFieldValue,
  calculateFeeAmounts,
  generateAutoTimeFeeSummary,
  generateTimeInHoursAndMinutes,
} from '@sb-billing/business-logic/fee/services';
import { getLogger } from '@sb-itops/fe-logger';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import uuid from '@sb-itops/uuid';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import { getMatterBillableMinutes } from '@sb-billing/business-logic/matters/billing-config';
import { fuzzyTime, convertDurationToType } from '@sb-billing/business-logic/shared/services';
import { deriveRate } from '@sb-billing/business-logic/rates';
import {
  deriveActivityRate,
  convertToActivityGroupArray,
  getApplicableActivityCategories,
  getPreferredActivityDurationType,
  getPreferredTaskDurationType,
  convertToTaskGroupArray,
} from '@sb-billing/business-logic/activities/services';
import { useTranslation } from '@sb-itops/react';
import { facets, hasFacet } from '@sb-itops/region-facets';
import { getRegion } from '@sb-itops/region';

import { FeeModal } from 'web/components';
import { FeePopOutEditor } from './FeePopOutEditor';

import { feeFormSchema } from './FeeForm.yup';

const { useState, useEffect, useMemo } = React;

const REGION = getRegion();
const { convertMinsToUnits, convertUnitsToHours, getMinutesFuzzy, roundToInterval } = fuzzyTime;

const log = getLogger('Fee.forms.container');

export const feePresentationalComponentModes = Object.freeze({
  MODAL: 'MODAL',
  POP_OUT_EDITOR: 'POP_OUT_EDITOR',
});

/**
 * All form related data and functionality for fees
 *
 *  For example (but not limited to):
 *  1. Initialisation
 *  2. Form state and updates
 *  3. Keeping dependent fields in sync
 */

/**
 * getMatterTypeaheadDefaultValue
 *
 * For an existing fee, provide the default data for the matter (typeahead) field
 *
 * @param {Object} params
 * @param {Object} params.matter
 * @returns {MatterSummaries}
 */
function getMatterTypeaheadDefaultValue({ matter }) {
  if (!matter) {
    return [];
  }

  const typeahead = [
    matter.matterNumber,
    matter.clientDisplay,
    matter.otherSideDisplay,
    matter.matterType?.name,
    matter.attorneyResponsible?.name,
    matter.attorneyResponsible?.initials,
    matter.description,
  ];
  const defaultMatterSummaries = [
    {
      ...matter,
      display: getMatterDisplay(matter, matter.matterType?.name),
      matterClientNames: matter.clientNames,
      matterStarted: matter.matterStarted ? new Date(matter.matterStarted) : undefined,
      matterStartedISO: matter.matterStarted ? moment(matter.matterStarted, 'YYYYMMDD').toISOString() : '',
      typeahead: typeahead.filter((m) => m).join(' '),
    },
  ];
  return defaultMatterSummaries;
}

/**
 * getDurationValue
 *
 * Determines the duration value for a given activity duration type
 *
 * @param {Object} params
 * @param {durationTypeEnum} params.durationType
 * @param {number} params.durationInMins
 * @param {number} interval
 * @returns {string}
 */
function getDurationValue({ durationType, durationInMins, interval }) {
  const isFixedFee = durationType === durationTypeEnum.FIXED;

  if (isFixedFee) {
    return '0';
  }

  let duration;
  const isTimeFeeInUnits = durationType === durationTypeEnum.UNITS;
  const isTimeFeeInHours = durationType === durationTypeEnum.HOURS;

  if (isTimeFeeInUnits) {
    duration = convertMinsToUnits({
      mins: durationInMins,
      interval,
    });
  } else if (isTimeFeeInHours) {
    duration = +(durationInMins / 60).toFixed(5);
  }

  return duration.toString();
}

/**
 * getInitiallyLinkedMatter
 *
 * During the initialisation process, a fee can be intrinsically linked to a matter (this can change if a new matter is selected)
 *  e.g. new fees on a matter page (linked to said matter)
 *  e.g. existing fees (linked to whatever matter was selected when saving)
 *
 * Fees can also not have an intrinsic link to a matter too
 *  e.g. new fees via the global quick add ("+" component)
 *
 * @param {Object} params
 * @param {Object} [params.fee]
 * @param {boolean} params.isNewFee
 * @param {Object} [params.matter]
 *
 * @returns {Object|undefined}
 */
function getInitiallyLinkedMatter({ fee, isNewFee, matter }) {
  // Existing fees
  if (!isNewFee && fee) {
    return fee.matter;
  }

  // New fee with a connection to a matter (e.g. matter page)
  if (isNewFee && matter) {
    return matter;
  }

  // New fee without a connection to a matter (e.g. global quick add)
  return undefined;
}

/**
 * deriveRateForNewFee
 *
 * Fees rates are normally applied from a staff member's default rate.
 *
 * However, there are multiple ways to override fee rates. This needs to be taken into consideration when creating new fees.
 *
 * NB: existing fees just need to apply the rate saved on the fee.
 *
 * @param {Object} params
 * @param {Object} params.activity
 * @param {Object} params.matterHourlyRate
 * @param {Object} params.staffRateConfig
 *
 * @returns {number}
 */
function deriveRateForNewFee({ activity, matterHourlyRate, staffRateConfig }) {
  // Setup users do not have staffRateConfig
  const derivedRateInCents =
    staffRateConfig?.id && featureActive('BB-10835')
      ? deriveRate({
          staffId: staffRateConfig.id,
          activity,
          staffRate: staffRateConfig.rate,
          matterHourlyRate,
        })
      : deriveActivityRate({
          activity,
          staffRateConfig,
          matterRateConfig: matterHourlyRate,
        });

  return derivedRateInCents;
}

/**
 * areUserInteractiveFieldsDirty
 *
 * The fee form consists of:
 *  1. Normal fields
 *    - Fields that users can directly interact with or change (e.g. rate field)
 *  2. Derived fields
 *    - Fields that users cannot directly interact with or change (e.g. amount field)
 *    - These fields are indirectly updated when a normal field is updated (e.g. updating the rate field will update the amount field)
 *
 * When the fee form is initialised, the form is considered dirty (even if the user has not done anything yet) because the derived fields will be calculated and update the form accordingly.
 *
 * This function will return a boolean value on whether a user has directly updated/touched any normal field.
 *
 * @param {Object} params
 * @param {Object} params.formFields provided by useForm
 * @param {boolean} params.isAutoTimeFee
 * @returns {boolean}
 */
function areUserInteractiveFieldsDirty({ formFields, isAutoTimeFee }) {
  const derivedFieldsMap = {
    durationBilledInMins: true,
    durationWorkedInMins: true,
    amountExclTaxInCents: true,
    billableTaxAmountInCents: true,
    taxAmountInCents: true,
    roundedDurationBilled: true,
    roundedDurationWorked: true,
    timeBilledInHoursAndMinutes: true,
    timeWorkedInHoursAndMinutes: true,
    // Additional fields for auto time fees
    billableAmountExclTaxInCents: true,
    nonBillableAmountExclTaxInCents: true,
    // Duration value field:
    //   Standard fees - Editable
    //   Auto time fees - Not editable (it is derived from source item durations)
    durationBilled: isAutoTimeFee,
    durationWorked: isAutoTimeFee,
  };

  const allFields = Object.values(formFields);
  const isFormDirty = allFields.some((field) => !derivedFieldsMap[field.key] && field.isDirty);

  return isFormDirty;
}

const hooks = (props) => ({
  useFeeForm: () => {
    const [isFeeDeleting, setIsFeeDeleting] = useState(false);
    const [sourceItemsInitialValue, setSourceItemsInitialValue] = useState([]);
    const [initialiseDerivedValues, setInitialiseDerivedValues] = useState(false);
    const [isSubjectOverridable, setIsSubjectOverridable] = useState(true);
    const [hasDurationTypeChanged, setHasDurationTypeChanged] = useState(false);

    const feeForm = useForm({ scope: props.scope, schema: feeFormSchema });

    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
      return () => {
        onClearForm();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
      activities,
      billingIncrementsMins,
      fee,
      isAutoTimeFee,
      isExpanded,
      isNewFee,
      isUtbmsEnabledForFirm,
      loading,
      matter,
      matterSummaries,
      onFeeSave,
      onModalClose,
      onNavigateToInvoice: _onNavigateToInvoice,
      preferDurationAsUnits,
      staffMemberOptions,
      staffRateConfig,
      tasks,
      utbmsCodesRequiredByFirm,
    } = props;

    const { formInitialised, formFields, formSubmitting, formValues, submitFailed, onClearForm, onResetForm } = feeForm;

    const feeId = isNewFee ? uuid() : fee?.id;
    const invoice = fee?.invoice;
    const isOnDraftInvoice = !!invoice && !fee?.finalized;
    const isOnFinalisedInvoice = !!invoice && fee?.finalized;
    const invoiceNumber = invoice?.invoiceNumber;
    const isFormDisabled = !!fee?.isInvoicedExternally || isOnFinalisedInvoice || formSubmitting || isFeeDeleting;
    const preferredDurationType = preferDurationAsUnits ? durationTypeEnum.UNITS : durationTypeEnum.HOURS;

    const onNavigateToInvoice = () => (invoice ? _onNavigateToInvoice(invoice.id) : undefined);

    /**
     * Form initialisation
     */

    // Before initialising form data, wait for GraphQL data to complete
    const isReadyToInitialiseForm =
      !loading &&
      (fee || isNewFee) &&
      !formInitialised &&
      // PopOutEditor component is automatically mounted on all relevant pages.
      // When a modal is opened on the same route, we want to avoid initialising
      // the same values in the PopOutEditor
      (props.mode !== feePresentationalComponentModes.POP_OUT_EDITOR ||
        (props.mode === feePresentationalComponentModes.POP_OUT_EDITOR && isExpanded));

    if (isReadyToInitialiseForm) {
      const defaultValues = getDefaultFieldValues();

      feeForm.onInitialiseForm(defaultValues);
      setSourceItemsInitialValue(defaultValues.sourceItems);
      setInitialiseDerivedValues(true);
      // Set duration type changed back to false when navigating between fee in RHS fee modal to avoid incorrect group fee
      if (hasDurationTypeChanged) {
        setHasDurationTypeChanged(false);
      }
    }

    // When a new/different fee is selected (FeePopOutEditor), reset form state appropriately
    const reinitialiseForm = formInitialised && !isNewFee && feeId !== formValues.id;
    if (reinitialiseForm) {
      const defaultValues = getDefaultFieldValues();
      onResetForm(defaultValues);
      setInitialiseDerivedValues(true);
      // Set duration type changed back to false when navigating between fee in RHS fee modal to avoid incorrect group fee
      if (hasDurationTypeChanged) {
        setHasDurationTypeChanged(false);
      }
    }

    const defaultMatterSummaries = formInitialised
      ? getMatterTypeaheadDefaultValue({
          matter: getInitiallyLinkedMatter({
            fee,
            isNewFee,
            matter,
          }),
        })
      : [];

    function getDefaultDurationWorked({ durationWorkedRaw, durationBilledInMins }) {
      if (!featureActive('BB-13563') || durationWorkedRaw === null) {
        return durationBilledInMins;
      }

      return durationWorkedRaw;
    }

    function getDefaultFieldValues() {
      const {
        id,
        amountIncludesTax,
        description: subject, // Subject field
        duration: durationBilledInMins,
        durationWorked: durationWorkedRaw,
        feeActivityId,
        feeDate,
        feeEarnerStaff,
        feeType,
        isBillable,
        isInvoicedExternally,
        isTaxExempt,
        notes: description, // Description field
        rate: rateInCents,
        sourceItems,
        utbmsActivityCode,
        utbmsTaskCode,
        createdFromActivityId,
        waived: isWriteOff, // Write off field
      } = fee || {};

      const durationWorkedInMins = getDefaultDurationWorked({ durationWorkedRaw, durationBilledInMins });

      const linkedMatter = getInitiallyLinkedMatter({
        fee,
        isNewFee,
        matter,
      });

      const defaultMatterBillableMinutes = linkedMatter
        ? getMatterBillableMinutes({
            matterHourlyRate: linkedMatter.matterHourlyRate,
            firmBillableMinutes: billingIncrementsMins,
          })
        : billingIncrementsMins;
      const isFixedFee = feeType === entryTypeEnum.FIXED;
      const defaultActivity = isNewFee
        ? undefined
        : activities.find((activity) => activity.code === (utbmsTaskCode ? utbmsActivityCode : feeActivityId));
      const getDefaultDurationType = () => {
        if (!isNewFee && isFixedFee) {
          return durationTypeEnum.FIXED;
        }

        return preferDurationAsUnits ? durationTypeEnum.UNITS : durationTypeEnum.HOURS;
      };
      const durationType = getDefaultDurationType();

      // The LOD FeeSourceItemsEntries component requires ids and uses duration in mins
      const generateSourceItemsWithIds = () => {
        if (!sourceItems) {
          return [];
        }

        const sourceItemsWithIds = sourceItems.map((sourceItem) => {
          const { duration: durationBilled, durationWorked, ...rest } = sourceItem; // Destructure to rename duration to durationBilled
          return {
            ...rest,
            durationBilled,
            durationWorked: durationWorked ?? durationBilled, // Default durationWorked to durationBilled if null
            originalBillable: sourceItem.originalBillable ?? false, // Default originalBillable to false if null or undefined
            id: uuid(),
          };
        });
        return sourceItemsWithIds;
      };

      const deriveIsBillable = () => {
        if (isNewFee) {
          return deriveNewFeeBillableStatus({
            matterBillingType: linkedMatter?.billingConfiguration?.billingType,
            activity: undefined,
            taskCode: undefined,
            durationType,
          });
        }

        // When null, it represents a fee with both billable and non-billable source items
        const derivedIsBillable =
          deriveFeeBillableStatus({
            isBillable,
            sourceItems,
          }) === null
            ? true
            : isBillable;

        return derivedIsBillable;
      };

      const getDefaultDurationInMins = ({ durationInMins }) => {
        // Fee timer
        if (isNewFee && durationInMins) {
          // Need to handle cases where the supplied prefilled durationInMinutes is less than the defaultMatterBillableMinutes (e.g. 0m)
          // In such cases, we use defaultMatterBillableMinutes (otherwise, the prefilled duration)
          const roundedMinutes =
            defaultMatterBillableMinutes > durationInMins ? defaultMatterBillableMinutes : durationInMins;

          const result = roundToInterval({
            mins: roundedMinutes,
            interval: defaultMatterBillableMinutes,
          });

          return result;
        }

        // Fee entity
        if (durationInMins) {
          return durationInMins;
        }

        // General default
        return isFixedFee ? 0 : 60;
      };

      const getDefaultDurationValue = ({ durationInMins }) => {
        if (isNewFee && !durationInMins) {
          return '1';
        }

        const result = getDurationValue({
          // Duration field value (hours/units)
          durationInMins: getDefaultDurationInMins({ durationInMins }),
          durationType,
          interval: defaultMatterBillableMinutes,
        });

        return result;
      };

      const defaultValues = {
        id,
        description: description || '',
        durationBilledInMins: getDefaultDurationInMins({ durationInMins: durationBilledInMins }),
        durationWorkedInMins: getDefaultDurationInMins({ durationInMins: durationWorkedInMins }),
        feeDate: feeDate ? integerToDate(feeDate) : moment().toDate(),
        feeType: isFixedFee ? entryTypeEnum.FIXED : entryTypeEnum.TIME,
        isBillable: deriveIsBillable(),
        isInvoicedExternally: isInvoicedExternally || false,
        isTaxExempt: isTaxExempt || false,
        isTaxInclusive: amountIncludesTax || false,
        isWriteOff: isWriteOff || false,
        rateInCents: isNewFee
          ? deriveRateForNewFee({
              activity: undefined,
              matterHourlyRate: linkedMatter?.matterHourlyRate,
              staffRateConfig,
            })
          : rateInCents,
        sourceItems: generateSourceItemsWithIds() || [],
        staffId: isNewFee ? staffRateConfig?.id : feeEarnerStaff?.id,
        subject: subject || '',
        // Derived values required in form submission (initialised in dependentHooks)
        amountExclTaxInCents: 0,
        billableTaxAmountInCents: 0,
        taxAmountInCents: 0,
        // Form state not required in form submission (can be edited by user)
        durationBilled: getDefaultDurationValue({ durationInMins: durationBilledInMins }),
        durationWorked: getDefaultDurationValue({ durationInMins: durationWorkedInMins }),
        durationType,
        // Form state not required in form submission (cannot be edited by user)
        billableAmountExclTaxInCents: 0,
        nonBillableAmountExclTaxInCents: 0,
        roundedDurationBilled: '',
        roundedDurationWorked: '',
        timeBilledInHoursAndMinutes: '1:00',
        timeWorkedInHoursAndMinutes: '1:00',
        // IDs that map with objects (see below)
        activityId: defaultActivity?.id || '',
        matterId: linkedMatter?.id || '',
        subjectActivityId: '',
        taskId: utbmsTaskCode || '',
        createdFromActivityId,
        // Required for form validation
        isUtbmsActivity: defaultActivity?.category === activityCategories.UTBMS,
        utbmsCodesRequiredForMatter:
          (!isAutoTimeFee && utbmsCodesRequiredByFirm && linkedMatter?.billingConfiguration?.isUtbmsEnabled) || false,
      };
      return defaultValues;
    }

    // The form state contains IDs
    // The container and nested components use objects
    // These objects will be mapped back into IDs during field updates
    const objectFields = new Set(['activity', 'matter', 'staff', 'subjectActivity', 'task']);
    const currentActivity = activities.find((activity) => activity.id === formValues.activityId);
    const currentMatter =
      matterSummaries?.find(({ id }) => id === formValues.matterId) ||
      defaultMatterSummaries?.find(({ id }) => id === formValues.matterId);
    const currentSubjectActivity = activities.find((activity) => activity.id === formValues.subjectActivityId);
    const currentTask = tasks.find((task) => task.id === formValues.taskId || task.code === formValues.taskId); // taskId is task.code of custom task code
    const currentStaff = staffMemberOptions.find((staff) => staff.entity.id === formValues.staffId)?.entity;

    const formDataMappedObjects = {
      activity: currentActivity,
      matter: currentMatter,
      staff: currentStaff,
      subjectActivity: currentSubjectActivity,
      task: currentTask,
    };

    /**
     * Form submission
     */

    const marshalDurationWorked = (formData) => {
      // Backwards compatibility with feature off
      if (!featureActive('BB-13563')) {
        return null;
      }

      return formData.durationType === durationTypeEnum.FIXED ? 0 : formData.durationWorkedInMins;
    };

    async function saveFee(formData) {
      const sourceItemsToSave = (formData.sourceItems || []).map((item) => {
        const { durationBilled, durationWorked, ...rest } = item;
        return {
          ...rest,
          duration: durationBilled,
          durationWorked: featureActive('BB-13563') ? durationWorked : null,
        };
      });

      const marshalledData = {
        createdFromActivityId: formData.createdFromActivityId,
        amountIncludesTax: formData.isTaxInclusive,
        description: formData.subject,
        duration: formData.durationType === durationTypeEnum.FIXED ? 0 : formData.durationBilledInMins, // Duration Billed
        durationWorked: marshalDurationWorked(formData), // Duration Worked
        feeActivityId: currentActivity?.category === activityCategories.CUSTOM ? currentActivity?.code : null,
        feeDate: moment(formData.feeDate).format('YYYYMMDD'),
        feeEarnerStaffId: formData.staffId,
        feeId,
        feeType: formData.feeType,
        feeVersionId: uuid(),
        isBillable: deriveFeeBillableStatus({
          isBillable: formData.isBillable,
          sourceItems: formData.sourceItems,
        }),
        isInvoicedExternally: formData.isInvoicedExternally,
        isTaxExempt: formData.isTaxExempt,
        matterId: formData.matterId,
        notes: formData.description,
        rate: formData.rateInCents,
        sourceItems: sourceItemsToSave,
        utbmsActivityCode: currentActivity?.category === activityCategories.UTBMS ? currentActivity?.code : null,
        utbmsTaskCode: currentTask ? currentTask.code : null,
        waived: formData.isBillable && formData.isWriteOff, // Only billable fees can be written off
        // Derived values
        amount: formData.amountExclTaxInCents,
        billableTax: formData.billableTaxAmountInCents,
        tax: formData.taxAmountInCents,
      };

      await dispatchCommand({ type: 'Billing.Fees.Commands.SaveFee', message: marshalledData });

      return marshalledData;
    }

    /**
     * onFormSubmit
     *
     * This function is called when the form is submitted and:
     *  1. Validates form
     *  2. Marshals the data
     *  3. Dispatches the save fee command
     *
     * @param {Object} params
     * @param {boolean} [params.saveAndNew] Keeps the fee modal open and partially resets the form so user can create another fee (NB: some field values are kept)
     * @returns {Promise}
     */
    async function onFormSubmit(args) {
      try {
        const context = {
          isTimeWorkedEnabled: featureActive('BB-13563'),
        };
        feeForm.onValidateForm(context);

        const isValid = await feeForm.onSubmitFormWithValidation({
          submitFnP: async (formData) => {
            const marshalledData = await saveFee(formData);

            if (onFeeSave) {
              onFeeSave({
                marshalledData,
              });
            }
          },
        });

        if (!isValid) {
          log.warn('Fee form validation failed');
          return;
        }

        if (isNewFee) {
          displaySuccessToUser('Time/Fee saved successfully');
        }

        if (props.mode === feePresentationalComponentModes.MODAL) {
          if (args?.saveAndNew) {
            onResetForm({
              ...formValues,
              activityId: '',
              description: '',
              durationBilled: '1', // durationBilledInMins will be reset and recalculated accordingly from this value (in applyNewFormData)
              durationWorked: '1', // durationBilledInMins will be reset and recalculated accordingly from this value (in applyNewFormData)
              durationType: preferredDurationType,
              rateInCents: deriveRateForNewFee({
                activity: undefined,
                matterHourlyRate: formDataMappedObjects.matter.matterHourlyRate,
                staffRateConfig: {
                  id: formDataMappedObjects.staff.id,
                  rate: formDataMappedObjects.staff.rate,
                },
              }),
              subject: '',
              taskId: '',
            });
            setInitialiseDerivedValues(true);
            setIsSubjectOverridable(true);
            return;
          }

          // Is an optional prop for Fee containers, unless the modal is being used
          if (onModalClose) {
            onModalClose();
          }
        }
      } catch (error) {
        log.error('Failed to save fee', error);
        displayErrorToUser('Failed to save fee. Please check your connection and try again.');
      }
    }

    /**
     * Form updates
     */

    function onUpdateFormData(fieldUpdates) {
      // Map objects back into IDs, when updating the form state
      const mappedFieldUpdates = Object.entries(fieldUpdates).reduce((acc, [fieldName, fieldValue]) => {
        if (objectFields.has(fieldName)) {
          acc[`${fieldName}Id`] = fieldValue?.id;
        } else {
          acc[fieldName] = fieldValue;
        }

        return acc;
      }, {});
      feeForm.onUpdateFields(mappedFieldUpdates);

      if (mappedFieldUpdates.sourceItems) {
        // onFieldValueSet is required to update arrays
        feeForm.onFieldValueSet('sourceItems', mappedFieldUpdates.sourceItems);
      }

      if (submitFailed) {
        const context = {
          isTimeWorkedEnabled: featureActive('BB-13563'),
        };
        feeForm.onValidateForm(context);
      }
    }

    // Dynamically determine max length constraint
    // Description field on form maps to fee notes
    const descriptionFieldMaxLength = calculateFeeDescriptionMaxLength(fee?.notes);

    /**
     * Fee deletion
     */

    async function onDeleteFee() {
      try {
        const message = {
          feeId,
          feeVersionId: uuid(),
          source: `web-fee-forms/${props.mode}`, // Used for debugging purpose BB-12944
        };
        setIsFeeDeleting(true);
        await dispatchCommand({ type: 'Billing.Fees.Commands.DeleteFee', message });
      } catch (error) {
        log.error('Failed to delete fee', error);
        displayErrorToUser('Failed to delete fee. Please check your connection and try again.');
      } finally {
        setIsFeeDeleting(false);
        if (props.mode === feePresentationalComponentModes.MODAL && onModalClose) {
          onModalClose();
        }
      }
    }

    return {
      defaultMatterSummaries,
      descriptionFieldMaxLength,
      formData: formValues,
      formDataMappedObjects,
      formErrors: formFields, // Contains data related to form errors
      loading,
      formInitialised,
      hasDurationTypeChanged,
      invoiceNumber,
      initialiseDerivedValues,
      isAutoTimeFee,
      isFormDisabled,
      isFormSubmitting: formSubmitting,
      isOnDraftInvoice,
      isOnFinalisedInvoice,
      isSubjectOverridable,
      isUtbmsEnabledForFirm,
      matterSummaries,
      preferDurationAsUnits,
      sourceItemsInitialValue,
      onClearForm,
      onDeleteFee,
      onDeriveRateForNewFee: deriveRateForNewFee,
      onSetInitialiseDerivedValues: setInitialiseDerivedValues,
      onNavigateToInvoice,
      onFormSubmit,
      onUpdateFormData,
      setHasDurationTypeChanged,
      setIsSubjectOverridable,
    };
  },
});

const dependentHooks = (props) => ({
  // General fee form data/functionality
  useFeeForm: () => {
    const {
      activities,
      billingIncrementsMins,
      formDataMappedObjects,
      isUtbmsEnabledForFirm,
      tasks,
      utbmsCodesRequiredByFirm,
    } = props;

    const { t } = useTranslation();
    const [showTaxOptions, setShowTaxOptions] = useState(false);
    const [durationBilledHasEdits, setDurationBilledHasEdits] = useState(false);
    const [durationWorkedHasEdits, setDurationWorkedHasEdits] = useState(false);

    const matterBillableMinutes = formDataMappedObjects?.matter
      ? getMatterBillableMinutes({
          matterHourlyRate: formDataMappedObjects.matter.matterHourlyRate,
          firmBillableMinutes: billingIncrementsMins,
        })
      : billingIncrementsMins;
    const matterBillingConfig = formDataMappedObjects?.matter?.billingConfiguration;
    const isUtbmsEnabled = isUtbmsEnabledForFirm && !!matterBillingConfig?.isUtbmsEnabled;

    // Provides the available options in the subject typeahead dropdown
    const subjects = useMemo(() => {
      const options = activities.filter(
        (activity) => isUtbmsEnabled || activity.category === activityCategories.CUSTOM,
      );
      return options;
    }, [activities, isUtbmsEnabled]);

    const activityGroupArray = useMemo(() => {
      const availableActivityCategories = getApplicableActivityCategories({
        utbmsEnabledForMatter: isUtbmsEnabled,
        utbmsCodesRequiredByFirm,
      });
      return convertToActivityGroupArray({ activities, filter: { types: availableActivityCategories } });
    }, [activities, isUtbmsEnabled, utbmsCodesRequiredByFirm]);
    const [taskGroupArray, setTaskGroupArray] = useState([]);

    useEffect(() => {
      setTaskGroupArray(convertToTaskGroupArray({ tasks, filter: {} }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tasks, isUtbmsEnabled]);

    // Wait for required data before proceeding
    if (props.loading || !props.formInitialised) {
      return {};
    }

    const {
      defaultMatterSummaries,
      descriptionFieldMaxLength,
      formData,
      formErrors,
      initialiseDerivedValues,
      invoiceNumber,
      isAutoTimeFee,
      isFormDisabled,
      isFormSubmitting,
      isNewFee,
      isOnFinalisedInvoice,
      isSubjectOverridable,
      matterSummaries,
      matterSummariesDataLoading,
      matterSummariesHasMore,
      onDeleteFee,
      onDeriveRateForNewFee,
      onFetchMatterSummaries,
      onFetchMoreMatterSummaries,
      onNavigateToInvoice,
      onSetAutoTimeSummary,
      onSetInitialiseDerivedValues,
      onUpdateFormData,
      preferDurationAsUnits,
      preventDurationFieldSyncUpdates,
      setHasDurationTypeChanged,
      setIsSubjectOverridable,
      sourceItemsInitialValue,
      staffMemberOptions,
      taxRateBasisPoints,
    } = props;
    const {
      billableAmountExclTaxInCents,
      durationBilled,
      durationWorked,
      durationType,
      isTaxExempt,
      isTaxInclusive,
      nonBillableAmountExclTaxInCents,
      roundedDurationBilled,
      roundedDurationWorked,
      sourceItems,
    } = formData;

    /**
     * Derived field values
     *
     * Values calculated off other form field values
     */
    // Initialise derived values on:
    //  1. Initial render
    //  2. "Save & New"
    //  3. When the RHS panel is re-opened (after closing it) as this is treated as an initialisation (not reinitialisation)
    if (initialiseDerivedValues) {
      applyNewFormData({});
      onSetInitialiseDerivedValues(false);
    }
    /**
     * Additional or modified data required by fields
     */

    const taxOptions = [
      { id: 'isTaxInclusive', label: t('taxInclusive'), selected: isTaxInclusive, disabled: isFormDisabled },
      { id: 'isTaxExempt', label: t('taxExempt'), selected: isTaxExempt, disabled: isFormDisabled },
    ];

    const dataRequiredByFields = {
      activities: activityGroupArray,
      descriptionFieldMaxLength,
      initialValues: {
        defaultMatterSummaries,
        sourceItems: sourceItemsInitialValue,
      },
      isAutoTimeFee,
      matterBillableMinutes,
      matterSummaries,
      matterSummariesDataLoading,
      matterSummariesHasMore,
      region: REGION,
      staffMemberOptions,
      subjects,
      tasks: taskGroupArray,
      taxOptions,
    };

    /**
     * Field display and controls
     *
     * Data and functions related to a field's display or state (e.g. disabled/enabled)
     */

    const onHandleTaxOptionsBlur = (event) => {
      // Ignore tax options blur if show tax options button is clicked
      // The show tax options button will toggle
      if (event?.relatedTarget?.parentNode?.id === 'fee-modal-form-tax-option-dropdown-group') {
        return;
      }
      setShowTaxOptions(false);
    };

    const fieldDisplayAndControls = {
      durationFieldInputDisabled: durationType === durationTypeEnum.FIXED || isAutoTimeFee,
      durationFieldTypeDisabled: isAutoTimeFee,
      isFormDisabled,
      isFormSubmitting,
      taskFieldEnabled: isUtbmsEnabled,
      showTaskField: hasFacet(facets.utbms) && !isAutoTimeFee,
      showTaxField: hasFacet(facets.tax),
      showTaxOptions,
      writeOffFieldEnabled: formData.isBillable,
      onHandleTaxOptionsBlur,
      onShowTaxOptionsToggle: () => setShowTaxOptions(!showTaxOptions),
    };

    /**
     * Callbacks required by fields
     */
    const callbacksRequiredByFields = {
      onDurationBilledFieldBlur,
      onDurationWorkedFieldBlur,
      onFetchMatterSummaries,
      onFetchMoreMatterSummaries,
      onUpdateActivityField,
      onUpdateBillableField,
      onUpdateDurationFieldType,
      onUpdateDurationBilledFieldValue,
      onUpdateDurationWorkedFieldValue,
      onUpdateField,
      onUpdateMatterField,
      onUpdateSourceItemsField,
      onUpdateStaffField,
      onUpdateTaskField,
      onUpdateTaxField,
    };

    /**
     * AutoTime fees
     */

    // An AutoTime summary is generated and displayed on the modal form footer
    if (isAutoTimeFee) {
      const accumulatedSourceItemsDurations = accumulateSourceItemsDurations({
        sourceItems,
        interval: matterBillableMinutes,
      });
      const newAutoTimeSummary = generateAutoTimeFeeSummary({
        billableAmountExclTaxInCents,
        billableDurationInMins: accumulatedSourceItemsDurations.billableDurationInMins,
        currencySign: t('cents', { val: 0 }).substring(0, 1),
        nonBillableAmountExclTaxInCents,
        nonBillableDurationInMins: accumulatedSourceItemsDurations.nonBillableDurationInMins,
      });
      onSetAutoTimeSummary(newAutoTimeSummary);
    }

    /**
     * Form updates
     */

    function applyNewFormData(newFormData) {
      const newestFormData = { ...formData, ...newFormData };
      const isUnitsType = newestFormData.durationType === durationTypeEnum.UNITS;
      const isHoursType = newestFormData.durationType === durationTypeEnum.HOURS;
      const timeFee = isUnitsType || isHoursType;
      const getInterval = () => {
        const matterFieldUpdated = 'matter' in newestFormData;
        // Use existing value
        if (!matterFieldUpdated) {
          return matterBillableMinutes;
        }
        // Use new matter's value
        if (matterFieldUpdated && newestFormData.matter) {
          return getMatterBillableMinutes({
            matterHourlyRate: newestFormData.matter.matterHourlyRate,
            firmBillableMinutes: billingIncrementsMins,
          });
        }
        // Cleared matter so use firm
        return billingIncrementsMins;
      };

      if (timeFee) {
        // AutoTime fees
        if (isAutoTimeFee) {
          const updatedSourceItemsDurations = accumulateSourceItemsDurations({
            sourceItems: newestFormData.sourceItems,
            interval: getInterval(),
          });

          const sourceItemsDurationInMins =
            updatedSourceItemsDurations.billableDurationInMins + updatedSourceItemsDurations.nonBillableDurationInMins;
          const sourceItemsDurationWorkedInMins = updatedSourceItemsDurations.workedDurationInMins;
          const newestFeeAmounts = calculateFeeAmounts({
            billableDurationInMins: updatedSourceItemsDurations.billableDurationInMins,
            durationInMins: sourceItemsDurationInMins,
            durationType: newestFormData.durationType,
            isBillable: newestFormData.isBillable,
            isTaxExempt: newestFormData.isTaxExempt,
            isTaxFacetEnabled: hasFacet(facets.tax),
            isTaxInclusive: newestFormData.isTaxInclusive,
            rateInCents: newestFormData.rateInCents,
            taxRateBasisPoints,
            region: REGION,
          });

          // Derive time field
          const timeBilledInHoursAndMinutes = generateTimeInHoursAndMinutes({
            durationInMins: sourceItemsDurationInMins,
          });
          const timeWorkedInHoursAndMinutes = generateTimeInHoursAndMinutes({
            durationInMins: sourceItemsDurationWorkedInMins,
          });

          const getDurationBilled = ({ durationInMins }) => {
            if (durationInMins) {
              return durationInMins;
            }

            const durationInHrs =
              updatedSourceItemsDurations.billableDurationInHours +
              updatedSourceItemsDurations.nonBillableDurationInHours;
            const durationInUnits =
              updatedSourceItemsDurations.billableDurationInUnits +
              updatedSourceItemsDurations.nonBillableDurationInUnits;

            return `${isHoursType ? durationInHrs : durationInUnits}`;
          };

          const getDurationWorked = ({ durationInMins }) => {
            if (durationInMins) {
              return durationInMins;
            }

            const durationInHrs = updatedSourceItemsDurations.workedDurationInHours;
            const durationInUnits = updatedSourceItemsDurations.workedDurationInUnits;

            return `${isHoursType ? durationInHrs : durationInUnits}`;
          };

          const formDataToUpdate = {
            ...newFormData,
            durationBilled: getDurationBilled({ durationInMins: newestFormData.durationBilled }),
            durationWorked: getDurationWorked({ durationInMins: newestFormData.durationWorked }),
            sourceItems: newestFormData.sourceItems,
            durationBilledInMins: sourceItemsDurationInMins,
            durationWorkedInMins: sourceItemsDurationWorkedInMins,
            // Derived values used in form submission
            amountExclTaxInCents: newestFeeAmounts.amountExclTaxInCents,
            billableTaxAmountInCents: newestFeeAmounts.billableTaxAmountInCents,
            taxAmountInCents: newestFeeAmounts.taxAmountInCents,
            // Derived values used in form
            timeBilledInHoursAndMinutes,
            timeWorkedInHoursAndMinutes,
            // AutoTime fee summary
            billableAmountExclTaxInCents: newestFeeAmounts.billableAmountExclTaxInCents,
            nonBillableAmountExclTaxInCents: newestFeeAmounts.nonBillableAmountExclTaxInCents,
          };

          // The fee duration field needs to be appropriately rounded when necessary
          // This value is to be provided in the form state and updated via a separate function (onDurationBilledFieldBlur/onDurationWorkedFieldBlur, which is passed as a callback into FeeSourceItemsEntries)
          const roundedBilledHours = +(
            roundToInterval({ mins: sourceItemsDurationInMins, interval: getInterval() }) / 60
          ).toFixed(5);
          const roundedWorkedHours = +(
            roundToInterval({ mins: sourceItemsDurationWorkedInMins, interval: getInterval() }) / 60
          ).toFixed(5);
          const newestRoundedDurationBilled = isUnitsType
            ? `${Math.ceil(+durationBilled || 1)}`
            : `${roundedBilledHours}`;
          formDataToUpdate.roundedDurationBilled = newestRoundedDurationBilled;
          const newestRoundedDurationWorked = isUnitsType
            ? `${Math.ceil(+durationWorked || 1)}`
            : `${roundedWorkedHours}`;
          formDataToUpdate.roundedDurationWorked = newestRoundedDurationWorked;
          onUpdateFormData(formDataToUpdate);
        } else {
          // Standard time fees (HOURS/UNITS)
          const wasHoursType = durationType === durationTypeEnum.HOURS;
          const billedHours = isUnitsType
            ? convertUnitsToHours({ units: +newestFormData.durationBilled, interval: getInterval() })
            : newestFormData.durationBilled;
          const rawBilledMins = getMinutesFuzzy({
            duration: billedHours,
            interval: getInterval(),
            withRounding: true,
          });
          const workedHours = isUnitsType
            ? convertUnitsToHours({ units: +newestFormData.durationWorked, interval: getInterval() })
            : newestFormData.durationWorked;
          const rawWorkedMins = getMinutesFuzzy({
            duration: workedHours,
            interval: getInterval(),
            withRounding: true,
          });

          // Prevents unsupported fuzzy minutes values in "HOURS" time entry mode
          if (isHoursType && wasHoursType && (rawBilledMins === undefined || rawWorkedMins === undefined)) {
            return;
          }
          const billedMins = rawBilledMins === undefined ? 0 : rawBilledMins;
          const workedMins = rawWorkedMins === undefined ? 0 : rawWorkedMins;

          // Derive time field
          const timeBilledInHoursAndMinutes = generateTimeInHoursAndMinutes({
            durationInMins: billedMins,
          });
          const timeWorkedInHoursAndMinutes = generateTimeInHoursAndMinutes({
            durationInMins: workedMins,
          });

          // Used for onDurationBilledFieldBlur/onDurationWorkedFieldBlur
          const roundedBilledHours = +(roundToInterval({ mins: billedMins, interval: getInterval() }) / 60).toFixed(5);
          const roundedWorkedHours = +(roundToInterval({ mins: workedMins, interval: getInterval() }) / 60).toFixed(5);
          const newestRoundedDurationBilled = isUnitsType
            ? `${Math.ceil(+durationBilled || 1)}`
            : `${roundedBilledHours}`;
          const newestRoundedDurationWorked = isUnitsType
            ? `${Math.ceil(+durationWorked || 1)}`
            : `${roundedWorkedHours}`;
          const newestFeeAmounts = calculateFeeAmounts({
            durationInMins: billedMins,
            durationType: newestFormData.durationType,
            isBillable: newestFormData.isBillable,
            isTaxExempt: newestFormData.isTaxExempt,
            isTaxFacetEnabled: hasFacet(facets.tax),
            isTaxInclusive: newestFormData.isTaxInclusive,
            rateInCents: newestFormData.rateInCents,
            taxRateBasisPoints,
            region: REGION,
          });

          onUpdateFormData({
            ...newFormData,
            durationBilledInMins: billedMins,
            durationWorkedInMins: workedMins,
            // Derived values used in form submission
            amountExclTaxInCents: newestFeeAmounts.amountExclTaxInCents,
            billableTaxAmountInCents: newestFeeAmounts.billableTaxAmountInCents,
            taxAmountInCents: newestFeeAmounts.taxAmountInCents,
            // Derived values used in form
            roundedDurationBilled: newestRoundedDurationBilled,
            roundedDurationWorked: newestRoundedDurationWorked,
            timeBilledInHoursAndMinutes,
            timeWorkedInHoursAndMinutes,
          });
        }
      } else {
        // Fixed fees
        // Updating a FIXED fee is relatively simpler compared to TIME fees.
        //
        // However, when updating a fee's duration type to FIXED (from HOURS/UNITS), preserve the key form state variable of durationBilledInMins, instead of updating it.
        // Then, it can be reused to derive the duration and time field values if the user switches back from FIXED to HOURS/UNITS.
        // Effectively, we can present back the last "state" of their HOURS/UNITS fee values.
        //
        // In the case where the user does not switch back, we set durationBilledInMins as 0 when marshalling the data for fixed fees.
        const newestFeeAmounts = calculateFeeAmounts({
          durationInMins: newestFormData.durationBilledInMins,
          durationType: newestFormData.durationType,
          isBillable: newestFormData.isBillable,
          isTaxExempt: newestFormData.isTaxExempt,
          isTaxFacetEnabled: hasFacet(facets.tax),
          isTaxInclusive: newestFormData.isTaxInclusive,
          rateInCents: newestFormData.rateInCents,
          taxRateBasisPoints,
          region: REGION,
        });
        onUpdateFormData({
          ...newFormData,
          // The only derived values a fixed fee requires
          amountExclTaxInCents: newestFeeAmounts.amountExclTaxInCents,
          billableTaxAmountInCents: newestFeeAmounts.billableTaxAmountInCents,
          taxAmountInCents: newestFeeAmounts.taxAmountInCents,
        });
      }
    }

    // Simple field updates
    function onUpdateField({ field, newValue }) {
      applyNewFormData({
        [field]: newValue,
      });
    }

    /** Field updates that are more complex and/or require syncing */

    function onUpdateActivityField({ selectedActivity, isSubjectTypeaheadSelection }) {
      // Clearing the subject field
      if (!selectedActivity) {
        applyNewFormData({
          subjectActivity: undefined,
        });
        return;
      }

      const dataToUpdate = {};
      const isUtbmsActivity = selectedActivity.category === activityCategories.UTBMS;

      if (hasFacet(facets.utbms)) {
        // Clear task unless it is a UTBMS activity
        if (!isUtbmsActivity) {
          dataToUpdate.taskId = undefined;
        }
        dataToUpdate.isUtbmsActivity = isUtbmsActivity;
      }

      if (hasFacet(facets.tax)) {
        // Convert to boolean as expected by validation/schema
        // Some activities may have these attributes as null (possibly due to legacy data)
        dataToUpdate.isTaxExempt = !!selectedActivity.isTaxExempt;
        dataToUpdate.isTaxInclusive = !!selectedActivity.isTaxInclusive;
      }

      if (isNewFee) {
        const newFeeRate = onDeriveRateForNewFee({
          activity: selectedActivity,
          matterHourlyRate: formDataMappedObjects.matter?.matterHourlyRate,
          staffRateConfig: {
            id: formDataMappedObjects.staff.id,
            rate: formDataMappedObjects.staff.rate,
          },
        });
        const selectedTask = formDataMappedObjects.task;
        // Keep current duration type if:
        // 1. UTBMS activity (no duration type is specified)
        // 2. Creating a fee via a timer
        const activityDurationType =
          isUtbmsActivity || preventDurationFieldSyncUpdates
            ? formData.durationType
            : getPreferredActivityDurationType({
                activity: selectedActivity,
                preferDurationAsUnits,
                billingIncrementsMins: matterBillableMinutes,
              });

        dataToUpdate.durationType = activityDurationType;
        dataToUpdate.feeType = isUtbmsActivity ? formData.feeType : selectedActivity.type;
        // Keep current duration if:
        // 1. UTBMS activity (no duration is specified)
        // 2. Creating a fee via a timer
        dataToUpdate.durationBilled =
          isUtbmsActivity || preventDurationFieldSyncUpdates
            ? formData.durationBilled
            : calculateDurationFieldValue({
                durationType: activityDurationType,
                durationInMins: selectedActivity.durationMins || 0, // Can be null
                interval: matterBillableMinutes,
              });
        dataToUpdate.durationWorked = dataToUpdate.durationBilled;

        const activityIsBillable = deriveNewFeeBillableStatus({
          matterBillingType: formDataMappedObjects.matter?.billingConfiguration?.billingType,
          activity: selectedActivity,
          taskCode: dataToUpdate.taskId ? selectedTask : undefined,
          durationType: activityDurationType,
        });

        dataToUpdate.isBillable = activityIsBillable;
        dataToUpdate.isWriteOff = activityIsBillable ? formData.isWriteOff : false;
        dataToUpdate.rateInCents = newFeeRate;
        const activitySubject =
          selectedTask && isUtbmsActivity
            ? `${selectedActivity.description} ${selectedTask.description}`
            : selectedActivity.description;
        dataToUpdate.subject = isSubjectOverridable || isSubjectTypeaheadSelection ? activitySubject : formData.subject;
      }
      if (!isNewFee) {
        if (isSubjectTypeaheadSelection) {
          dataToUpdate.subject = selectedActivity.description;
        }
      }
      applyNewFormData({
        activity: selectedActivity,
        subjectActivity: selectedActivity,
        ...dataToUpdate,
      });
    }

    function onDurationBilledFieldBlur() {
      // If the user leaves the duration field and are currently in duration type of hours,
      // we need to replace the currently entered duration with the rounded and fuzzy time
      // decoded value if present.
      if (durationType === durationTypeEnum.HOURS) {
        applyNewFormData({
          durationBilled: durationBilled ? roundedDurationBilled || durationBilled : undefined,
        });
      }
      // If duration worked is clean, make duration worked same as duration billed
      if (featureActive('BB-13563') && isNewFee && !durationWorkedHasEdits) {
        applyNewFormData({
          durationWorked: durationBilled || undefined,
        });
      }
    }

    function onDurationWorkedFieldBlur() {
      // If the user leaves the duration field and are currently in duration type of hours,
      // we need to replace the currently entered duration with the rounded and fuzzy time
      // decoded value if present.
      if (durationType === durationTypeEnum.HOURS) {
        applyNewFormData({
          durationWorked: durationWorked ? roundedDurationWorked || durationWorked : undefined,
        });
      }
      // If duration billed is clean, make duration billed same as duration worked
      if (featureActive('BB-13563') && isNewFee && !durationBilledHasEdits) {
        applyNewFormData({
          durationBilled: durationWorked || undefined,
        });
      }
    }

    function onUpdateDurationBilledFieldValue({ newDurationValue }) {
      const inUnits = formData.durationType === durationTypeEnum.UNITS;
      const invalid = inUnits && !Number.isInteger(+newDurationValue);
      if (invalid) {
        return;
      }
      applyNewFormData({
        durationBilled: newDurationValue,
      });
      setDurationBilledHasEdits(true);
    }

    function onUpdateDurationWorkedFieldValue({ newDurationValue }) {
      const inUnits = formData.durationType === durationTypeEnum.UNITS;
      const invalid = inUnits && !Number.isInteger(+newDurationValue);
      if (invalid) {
        return;
      }
      applyNewFormData({
        durationWorked: newDurationValue,
      });
      setDurationWorkedHasEdits(true);
    }

    function onUpdateDurationFieldType({ selectedDurationType }) {
      const selectedFixedType = selectedDurationType === durationTypeEnum.FIXED;
      const wasFixedType = formData.durationType === durationTypeEnum.FIXED;
      if (selectedDurationType === durationType) {
        return;
      }
      if (selectedFixedType) {
        applyNewFormData({
          durationBilled: '0',
          durationWorked: '0',
          durationType: selectedDurationType,
          feeType: entryTypeEnum.FIXED,
        });
        return;
      }
      if (wasFixedType) {
        applyNewFormData({
          durationBilled: calculateDurationFieldValue({
            durationInMins: formData.durationBilledInMins,
            durationType: selectedDurationType,
            interval: matterBillableMinutes,
          }),
          durationWorked: calculateDurationFieldValue({
            durationInMins: formData.durationWorkedInMins,
            durationType: selectedDurationType,
            interval: matterBillableMinutes,
          }),
          durationType: selectedDurationType,
          feeType: entryTypeEnum.TIME,
        });
        return;
      }
      // Conversion for when switching from HOURS/UNITS TO UNITS/HOURS
      const newDurationBilled = convertDurationToType({
        duration: formData.durationBilled,
        durationType: formData.durationType,
        targetDurationType: selectedDurationType,
        interval: matterBillableMinutes,
      });
      const newDurationWorked = convertDurationToType({
        duration: formData.durationWorked,
        durationType: formData.durationType,
        targetDurationType: selectedDurationType,
        interval: matterBillableMinutes,
      });

      applyNewFormData({
        durationBilled: newDurationBilled,
        durationWorked: newDurationWorked,
        durationType: selectedDurationType,
        feeType: entryTypeEnum.TIME,
      });

      // when feature on, duration type change no longer controlled by group fee duration type button, it's controlled by the fee body duration type button
      if (featureActive('BB-13563') && isAutoTimeFee) {
        onUpdateSourceItemsByChangingDurationType({ selectedDurationType });
      }
    }

    function onUpdateSourceItemsByChangingDurationType({ selectedDurationType }) {
      let updatedSourceItems = [...sourceItems];
      const changingToUnits = selectedDurationType === durationTypeEnum.UNITS;

      if (changingToUnits) {
        // When changing from hours to units,
        // Handle the case where the interval has changed since last save (e.g. 1m to 6m)
        // The duration billed (in mins) value needs to update accordingly to the new interval
        updatedSourceItems = sourceItems.map((sourceItem) => {
          const newDurationBilledInUnits = convertMinsToUnits({
            mins: sourceItem.durationBilled,
            interval: matterBillableMinutes,
          });
          const newDurationBilledInMins = getMinutesFuzzy({
            duration: `${newDurationBilledInUnits}u`,
            interval: matterBillableMinutes,
            withRounding: true,
          });
          const newDurationWorkedInUnits = convertMinsToUnits({
            mins: sourceItem.durationWorked,
            interval: matterBillableMinutes,
          });
          const newDurationWorkedInMins = getMinutesFuzzy({
            duration: `${newDurationWorkedInUnits}u`,
            interval: matterBillableMinutes,
            withRounding: true,
          });

          return {
            ...sourceItem,
            durationBilled: newDurationBilledInMins,
            durationWorked: newDurationWorkedInMins,
          };
        });
      }

      onUpdateSourceItemsField({
        newSourceItems: updatedSourceItems,
        newDurationType: selectedDurationType,
      });

      setHasDurationTypeChanged(true);
    }

    function onUpdateMatterField({ newMatter }) {
      const dataToUpdate = {};

      if (isNewFee) {
        setIsSubjectOverridable(true);
        // When changing/clearing the matter field in create mode,
        // clear activity/UTBMS related fields
        dataToUpdate.activityId = undefined;
        dataToUpdate.taskId = undefined;
        dataToUpdate.isUtbmsActivity = false;
        dataToUpdate.subject = props?.fee?.description && isNewFee ? formData.subject : '';
        dataToUpdate.rateInCents = onDeriveRateForNewFee({
          activity: formDataMappedObjects.activity,
          matterHourlyRate: newMatter?.matterHourlyRate,
          staffRateConfig: {
            id: formDataMappedObjects.staff.id,
            rate: formDataMappedObjects.staff.rate,
          },
        });
        if (newMatter) {
          dataToUpdate.isBillable = deriveNewFeeBillableStatus({
            matterBillingType: newMatter.billingConfiguration?.billingType,
            durationType: formData.durationType,
          });
          dataToUpdate.utbmsCodesRequiredForMatter =
            (!isAutoTimeFee && utbmsCodesRequiredByFirm && newMatter?.billingConfiguration?.isUtbmsEnabled) || false;
        } else {
          dataToUpdate.isBillable = true;
        }
      }
      applyNewFormData({
        matter: newMatter,
        matterId: newMatter?.id,
        subjectActivity: undefined,
        ...dataToUpdate,
      });
    }

    function onUpdateStaffField({ selectedStaff }) {
      const dataToUpdate = {};

      if (isNewFee) {
        dataToUpdate.rateInCents = onDeriveRateForNewFee({
          activity: formDataMappedObjects.activity,
          matterHourlyRate: formDataMappedObjects.matter?.matterHourlyRate,
          staffRateConfig: {
            id: selectedStaff.id,
            rate: selectedStaff.rate,
          },
        });
      }
      applyNewFormData({ ...dataToUpdate, staff: selectedStaff });
    }

    function onUpdateTaskField({ selectedTask }) {
      const dataToUpdate = {};
      const selectedActivity = formDataMappedObjects.activity;
      const isUtbmsActivity = selectedActivity?.category === activityCategories.UTBMS;
      // Clear activity unless it is a UTBMS activity
      if (selectedActivity && !isUtbmsActivity) {
        dataToUpdate.activityId = undefined;
        dataToUpdate.subjectActivity = undefined;
      }
      if (isNewFee) {
        const taskDescription = selectedTask.description;
        // UTBMS tasks are always (initially) set to billable, custom tasks will have `isBillable` property
        dataToUpdate.isBillable = selectedTask.isBillable ?? true;
        const activitySubject = isUtbmsActivity
          ? `${selectedActivity.description} ${taskDescription}`
          : taskDescription;
        dataToUpdate.subject = isSubjectOverridable ? activitySubject : formData.subject;

        // Custom UTBMS task codes has `entryType` property, which helps determine the duration type.
        // Standard UTBMS task codes does not have `entryType`, so duration type should remain unchanged.
        const newDurationType =
          selectedTask.entryType !== undefined
            ? getPreferredTaskDurationType({
                taskCode: selectedTask,
                preferDurationAsUnits,
              })
            : formData.durationType;

        // If moving from HOUR/UNIT to FIXED, set default duration to 0 similar to activity selection.
        let newDuration = formData.durationBilled;
        let newFeeType = formData.feeType;
        if (formData.durationType !== durationTypeEnum.FIXED && newDurationType === durationTypeEnum.FIXED) {
          newDuration = '0';
          newFeeType = entryTypeEnum.FIXED;
        }

        // If moving from fixed duration, for user safety, we wipe out the 0 duration to ensure
        // they need to re-enter a duration.
        if (formData.durationType === durationTypeEnum.FIXED && newDurationType !== durationTypeEnum.FIXED) {
          newDuration = undefined;
          newFeeType = entryTypeEnum.TIME;
        }

        dataToUpdate.durationType = newDurationType;
        dataToUpdate.durationBilled = newDuration;
        dataToUpdate.feeType = newFeeType;
      }
      applyNewFormData({
        task: selectedTask,
        ...dataToUpdate,
      });
    }

    function onUpdateTaxField(optionId, isEnabled) {
      const newIsTaxInclusiveValue = optionId === 'isTaxInclusive' && isEnabled;
      const newIsTaxExemptValue = optionId === 'isTaxExempt' && isEnabled;
      applyNewFormData({
        isTaxInclusive: newIsTaxInclusiveValue,
        isTaxExempt: newIsTaxExemptValue,
      });
    }

    function onUpdateBillableField({ newValue }) {
      const dataToUpdate = {};
      if (newValue === false) {
        dataToUpdate.isWriteOff = false;
      }
      if (isAutoTimeFee) {
        const updatedSourceItems = formData.sourceItems.map((sourceItem) => ({
          ...sourceItem,
          billable: newValue,
        }));
        dataToUpdate.sourceItems = updatedSourceItems;
      }
      applyNewFormData({
        isBillable: newValue,
        ...dataToUpdate,
      });
    }

    function onUpdateSourceItemsField({ newSourceItems, newFeeDurationBilled, newFeeDurationWorked, newDurationType }) {
      const dataToUpdate = {};

      dataToUpdate.durationBilled = newFeeDurationBilled; // If not given, we use the calculated value in applyNewFormData
      dataToUpdate.durationWorked = newFeeDurationWorked; // If not given, we use the calculated value in applyNewFormData
      dataToUpdate.durationType = newDurationType || formData.durationType;

      const isBillable = newSourceItems.some((sourceItem) => sourceItem.billable === true);

      if (!isBillable) {
        dataToUpdate.isWriteOff = false;
      }

      applyNewFormData({
        sourceItems: newSourceItems,
        isBillable,
        ...dataToUpdate,
      });
    }

    return {
      ...callbacksRequiredByFields,
      ...dataRequiredByFields,
      ...fieldDisplayAndControls,
      formData,
      formErrors,
      invoiceNumber,
      isNewFee,
      isOnFinalisedInvoice,
      onDeleteFee,
      onNavigateToInvoice,
    };
  },
  // Fee modal form specific data/functionality
  useFeeModalForm: () => {
    if (props.mode === feePresentationalComponentModes.MODAL) {
      return {
        hideSaveAndNewButton: !props.isNewFee || !!props.hideSaveAndNewButton,
      };
    }

    return {};
  },
  // Fee pop out editor form specific data/functionality
  useFeePopoutEditorForm: () => {
    if (props.mode === feePresentationalComponentModes.POP_OUT_EDITOR) {
      const { formData, onClearForm, onFormCancel, onSetIsExpanded } = props;
      // Clear form state when cancelling or collapsing editor
      const onCollapseFeePopOutEditorAndClearForm = () => {
        const isEditorCollapsed = onSetIsExpanded({ isExpanded: false });

        // The editor can only be collapsed (">>" button) if a fee list is present
        // Keep form state, unless the editor has collapsed
        //
        // e.g. A user can navigate to an empty fee list (via the fee chart). On a failed collapse attempt, do not clear the form.
        if (isEditorCollapsed) {
          onClearForm();
        }
      };
      // Editor can be "cancelled" without restriction
      const onFormCancelAndClearForm = () => {
        onFormCancel();
        onClearForm();
      };
      const onExpandFeePopOutEditor = () => onSetIsExpanded({ isExpanded: true });

      return {
        hideFormActionButtons: formData.isInvoicedExternally,
        onCollapseFeePopOutEditor: onCollapseFeePopOutEditorAndClearForm,
        onExpandFeePopOutEditor,
        onFormCancel: onFormCancelAndClearForm,
      };
    }

    return {};
  },
});

export const FeeFormsContainer = composeHooks(hooks)(
  composeHooks(dependentHooks)((props) => {
    switch (props.mode) {
      case feePresentationalComponentModes.MODAL:
        return <FeeModal {...props} />;
      case feePresentationalComponentModes.POP_OUT_EDITOR:
        return (
          <FeePopOutEditor
            {...props}
            heading={getMatterDisplay(props.fee?.matter, props.fee?.matter?.matterType?.name)}
            isFormDirty={areUserInteractiveFieldsDirty({
              formFields: props.formErrors,
              isAutoTimeFee: props.isAutoTimeFee,
            })}
          />
        );
      default:
        throw new Error(`Unknown fee mode: ${props.mode}`);
    }
  }),
);

FeeFormsContainer.displayName = 'FeeFormsContainer';

FeeFormsContainer.propTypes = {
  fee: PropTypes.object, // fee entity
  mode: PropTypes.oneOf(Object.values(feePresentationalComponentModes)).isRequired,
  onNavigateToInvoice: PropTypes.func,
  scope: PropTypes.string.isRequired,
  /** Fee Pop Out Editor specific */
  /** Fee Modal specific */
  hideSaveAndNewButton: PropTypes.bool,
  matter: PropTypes.object,
  onFeeSave: PropTypes.func,
  onModalClose: PropTypes.func,
  preventDurationFieldSyncUpdates: PropTypes.bool,
  showModal: PropTypes.bool,
};

FeeFormsContainer.defaultProps = {
  fee: undefined,
  onNavigateToInvoice: undefined,
  /** Fee Pop Out Editor specific */
  /** Fee Modal specific */
  hideSaveAndNewButton: undefined,
  matter: undefined,
  onFeeSave: undefined,
  preventDurationFieldSyncUpdates: undefined,
};
