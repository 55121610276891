'use strict';

/**
 * getSuplierPaidDisplayValue
 * Determines the supplier paid value for given expense entity. This is currently used in following places:
 *  - invoice PDF
 *  - eInvoice summary PDF
 *  - eInvoice portal
 *
 * @param {Expense} expense Expense entity from graphQL which contains expensePaymentDetails
 *
 * @returns {String}
 */
function getSuplierPaidDisplayValue(expense) {
  if (!expense.expensePaymentDetails) {
    // expensePaymentDetails don't exist if expense is created in desktop.
    // such an expense is equivalent to cost recovery & is not payable
    return 'N/A';
  }

  const { isPaid, isPayable } = expense.expensePaymentDetails;

  if (expense.isAnticipated) {
    // cost recovery is anticipated & supplier Paid is False - Show as N
    // cost recovery is anticipated & supplier Paid is True - Show as Y
    return isPaid ? 'Y' : 'N';
  }

  // cost recovery is a cost recovery & is not payable to supplier show N/A
  // cost recovery is a cost recovery & is payable to supplier & marked as paid, show Y
  if (!isPayable) {
    return 'N/A';
  }
  return isPaid ? 'Y' : '';
}

module.exports = {
  getSuplierPaidDisplayValue,
};
