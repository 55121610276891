'use strict';

/**
 * groupFees
 * Groups the fixed and time fees and their respective total billable amount (incl. tax)
 *
 * @param {Object} invoice
 * @param {boolean} [isExpanded=undefined] If set, coerces the isExpanded properties, otherwise only fees with detail requests will be shown
 * @param {Array<Fee>} [feeEntries=undefined] If set, this array is used instead of the invoice.fee.entries
 *
 * @returns {Object}
 */
function groupFees({ invoice, isExpanded, feeEntries }) {
  const fees = feeEntries || (invoice.fee ? invoice.fee.entries || [] : []);
  const descriptionOnDemandRequests = invoice.descriptionsOnDemand ? invoice.descriptionsOnDemand.requests : [];

  const { fixedFees, fixedFeesTotalBillableAmountInclTax } = groupFixedFees(fees);
  const { expandableItems, expandedItems, timeFeesGroupedByStaff, timeFeesTotalBillableAmountInclTax } = groupTimeFees({
    fees,
    descriptionOnDemandRequests,
    isExpanded,
  });

  const groupedFees = {
    expandableItems,
    expandedItems,
    fixedFees,
    fixedFeesTotalBillableAmountInclTax,
    timeFeesGroupedByStaff,
    timeFeesTotalBillableAmountInclTax,
  };

  return groupedFees;
}

/**
 * groupFixedFees
 * Groups the fixed fees and accumulates their total billable amount (incl. tax)
 *
 * @param {Object[]} fees Invoice fee entries
 *
 * @returns {Object}
 */
function groupFixedFees(fees) {
  const fixedFees = (fees && fees.filter((f) => f.feeType === 0)) || [];

  const result = fixedFees.reduce(
    (acc, fee) => {
      acc.fixedFees.push(fee);
      acc.fixedFeesTotalBillableAmountInclTax += fee.billableAmountInclTax;

      return acc;
    },
    {
      fixedFees: [],
      fixedFeesTotalBillableAmountInclTax: 0,
    },
  );

  return result;
}

/**
 * groupTimeFees
 * Groups time fees by fee earner (staff) and by date
 *
 * @param {Object} invoice
 * @param {boolean} [isExpanded=undefined] If set, coerces the isExpanded properties, otherwise only fees with detail requests will be shown
 * @param {Object[]} descriptionOnDemandRequests
 *
 * @returns {Object}
 */
function groupTimeFees({ fees, isExpanded, descriptionOnDemandRequests }) {
  let expandableItems = 0;
  let expandedItems = 0;
  // For "display expense with fees", we consider the expense as a time fee
  const timeFees = (fees && fees.filter((fee) => fee.feeType === 1 || fee.isExpenseAsFee)) || [];

  const timeFeesGroupedByStaff = timeFees.reduce((acc, fee) => {
    const feeBillableAmountExclTax = fee.waived ? 0 : fee.billableAmountExclTax;
    const feeBillableAmountInclTax = fee.waived ? 0 : fee.billableAmountInclTax;
    const feeEarnerId = fee.feeEarner && fee.feeEarner.personId;

    const descriptionOnDemandRequest = descriptionOnDemandRequests.find(
      (dodRequest) => dodRequest.feeEarnerStaffId === feeEarnerId && `${dodRequest.feeDate}` === fee.feeDate,
    );
    const setExpanded = isExpanded || (descriptionOnDemandRequest !== undefined && isExpanded === undefined);

    if (!acc[feeEarnerId]) {
      expandableItems += 2; // Fee earner and date
      if (setExpanded) {
        expandedItems += 2;
      }
      const feeEarnerName = `${fee.feeEarner.firstName} ${fee.feeEarner.lastName}`;

      acc[feeEarnerId] = {
        isExpanded: setExpanded,
        feeEarnerName,
        totalDuration: fee.durationNum || 0,
        hasAllFixedFees: fee.duration === 'FIXED',
        hasSomeFixedFees: fee.duration === 'FIXED',
        personId: feeEarnerId,
        billableAmountExclTax: feeBillableAmountExclTax,
        billableAmountInclTax: feeBillableAmountInclTax,
        feesByDate: {
          [fee.feeDate]: {
            isExpanded: setExpanded,
            date: fee.feeDate,
            billableAmountExclTax: feeBillableAmountExclTax,
            billableAmountInclTax: feeBillableAmountInclTax,
            fees: [fee],
            descriptionOnDemandRequest,
          },
        },
      };

      return acc;
    }

    if (!acc[feeEarnerId].feesByDate[fee.feeDate]) {
      expandableItems += 1;
      if (setExpanded) {
        expandedItems += 1;
      }

      acc[feeEarnerId].billableAmountExclTax += feeBillableAmountExclTax;
      acc[feeEarnerId].billableAmountInclTax += feeBillableAmountInclTax;
      acc[feeEarnerId].totalDuration += fee.durationNum || 0;
      acc[feeEarnerId].hasAllFixedFees = acc[feeEarnerId].hasAllFixedFees && fee.duration === 'FIXED';
      acc[feeEarnerId].hasSomeFixedFees = acc[feeEarnerId].hasSomeFixedFees || fee.duration === 'FIXED';
      acc[feeEarnerId].feesByDate[fee.feeDate] = {
        isExpanded: setExpanded,
        date: fee.feeDate,
        billableAmountExclTax: feeBillableAmountExclTax,
        billableAmountInclTax: feeBillableAmountInclTax,
        fees: [fee],
        descriptionOnDemandRequest,
      };

      return acc;
    }

    acc[feeEarnerId].billableAmountExclTax += feeBillableAmountExclTax;
    acc[feeEarnerId].billableAmountInclTax += feeBillableAmountInclTax;
    acc[feeEarnerId].totalDuration += fee.durationNum || 0;
    acc[feeEarnerId].hasAllFixedFees = acc[feeEarnerId].hasAllFixedFees && fee.duration === 'FIXED';
    acc[feeEarnerId].hasSomeFixedFees = acc[feeEarnerId].hasSomeFixedFees || fee.duration === 'FIXED';
    acc[feeEarnerId].feesByDate[fee.feeDate].fees.push(fee);
    acc[feeEarnerId].feesByDate[fee.feeDate].billableAmountExclTax += feeBillableAmountExclTax;
    acc[feeEarnerId].feesByDate[fee.feeDate].billableAmountInclTax += feeBillableAmountInclTax;

    return acc;
  }, {});

  const timeFeesTotalBillableAmountInclTax = Object.values(timeFeesGroupedByStaff).reduce(
    (acc, val) => acc + val.billableAmountInclTax,
    0,
  );

  const result = {
    timeFeesGroupedByStaff,
    timeFeesTotalBillableAmountInclTax,
    expandedItems,
    expandableItems,
  };

  return result;
}

module.exports = {
  groupFees,
  groupFixedFees,
  groupTimeFees,
};
