import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { CollapsibleHeader, ToggleListFilter, PanelExpander } from '@sb-itops/react';
import { FilterExpandCollapse } from 'web/components';

const ContactPaymentPlanListFilterPanel = memo(
  ({
    expanded,
    toggleExpanded,
    contactListFilterScope,
    contactStatusCounts,
    contactStatusCountsLoading,
    contactStatusOptions,
    selectedContactStatuses,
    onContactStatusSelect,
  }) => (
    <FilterExpandCollapse className="panel-filter" scope={contactListFilterScope}>
      {({ hiddenFilters, onToggleFilterVisibility }) => (
        <PanelExpander className="contacts-list-nav panel-filter" expanded={expanded} toggleExpanded={toggleExpanded}>
          {/* Contact Status Filter */}
          <CollapsibleHeader
            text="Contact Status"
            onClick={onToggleFilterVisibility}
            name="contactStatus"
            collapsed={hiddenFilters?.contactStatus}
          >
            <ToggleListFilter
              showAllLabel="All"
              showCount
              counterDataLoading={contactStatusCountsLoading}
              countDeleted
              optionCounts={contactStatusCounts}
              options={contactStatusOptions}
              selected={selectedContactStatuses}
              onSelect={onContactStatusSelect}
            />
          </CollapsibleHeader>
        </PanelExpander>
      )}
    </FilterExpandCollapse>
  ),
);

ContactPaymentPlanListFilterPanel.displayName = 'ContactPaymentPlanListFilterPanel';

ContactPaymentPlanListFilterPanel.propTypes = {
  contactListFilterScope: PropTypes.string.isRequired,
  contactStatusCounts: PropTypes.shape({
    current: PropTypes.number,
    deleted: PropTypes.number,
  }).isRequired,
  contactStatusCountsLoading: PropTypes.bool.isRequired,
  contactStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  selectedContactStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  onContactStatusSelect: PropTypes.func.isRequired,
};

ContactPaymentPlanListFilterPanel.defaultProps = {};

export default ContactPaymentPlanListFilterPanel;
