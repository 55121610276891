import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { productTierIdsByName } from '@sb-finance/business-logic/subscription/entities/constants';
import { waitForCognitoTokenUpdate } from './wait-for-cognito-token-update';
import { waitForProductIdUpdatedNotification } from './wait-for-product-id-update';
import { waitForLDFlagRefresh } from './wait-for-ld-flag-refresh';

const updateSmokeballProductTier = async ({ smokeballProductId }) => {
  const waitForNotificationsPromise = waitForProductIdUpdatedNotification();

  // Let the backend know to change the firm's product tier.
  await dispatchCommand({
    type: 'ProductRegistration.Commands.SetProductIdForFirmProductAccount',
    message: { productTierId: smokeballProductId },
  });

  await waitForNotificationsPromise;
  await waitForCognitoTokenUpdate({ expectedProductTier: smokeballProductId });
  // LD toggles based on segments will be out of sync so we must refresh them manually
  await waitForLDFlagRefresh();
};

export const revertToBillNow = () => updateSmokeballProductTier({ smokeballProductId: productTierIdsByName.BILL });
export const trialBoostNow = () => updateSmokeballProductTier({ smokeballProductId: productTierIdsByName.BOOST });
