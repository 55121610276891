import { gql } from '@apollo/client';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

const query = gql`
  query CorrespondenceHistoryTableData(
    $filter: CorrespondenceHistoryListFilter
    $offset: Int!
    $limit: Int!
    $sort: ListSort
  ) {
    correspondenceHistoryList(filter: $filter, offset: $offset, limit: $limit, sort: $sort) {
      totalCount
      results {
        id
        sentTimestamp
        operationType
        relatedItems {
          id
          type
          invoiceEntity {
            id
            invoiceNumber
          }
          contactEntity {
            id
            displayName
            displayNameFirstLast
          }
        }
        sentVia
        lastUpdated
        to
        user {
          id
          person {
            id
            name
          }
        }
      }
    }
  }
`;

const notificationIds = ['BillingSharedNotifications.CorrespondenceHistoryUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const CorrespondenceHistoryTableData = {
  query,
  notificationIds,
};
