import React from 'react';
import { Button, PaginationSelector } from '@sb-itops/react';
import classnames from 'classnames';
import { TaskFilters, TaskTable, AddTaskModal, AddPhoneMessageModal } from 'web/components';
import { Task, Person } from 'types';
import Styles from './TasksTab.module.scss';

interface ITasksTabProps {
  tasks: Task[];
  showMattersInList?: boolean;
  onSaveTask: (task: Task, isNewEntity: boolean) => Promise<void>;
  view: string;
  onClickLink: (options: { type: string; id: string }) => void;
  sortDirection: 'ASC' | 'DESC';
  sortBy: string;
  setSort: (payload: { sortBy: string; sortDirection: 'asc' | 'desc' }) => void;
  allowMatterSwitching: boolean;
  scope: string;
  matterId: string;
  showCreateEditModal: {
    completer: Person;
    show: boolean;
    task: Task;
  };
  showAddPhoneMessageModal: {
    completer: Person;
    show: boolean;
    task: Task;
  };
  onOpenCreateTask: () => void;
  onOpenAddPhoneMessage: () => void;
  onOpenEditTask: () => void;
  onCloseCreateEditModal: () => void;
  onCloseAddPhoneMessageModal: () => void;
  loading: boolean;
  taskViewCounts: any;
  totalNumberOfTaskPages: number;
  onTaskListPageChange: (page: number) => void;
  currentTaskPage: number;
}

export const TasksTab = ({
  loading,
  tasks,
  allowMatterSwitching,
  showMattersInList,
  showCreateEditModal,
  showAddPhoneMessageModal,
  onOpenCreateTask,
  onOpenAddPhoneMessage,
  onOpenEditTask,
  onCloseCreateEditModal,
  onCloseAddPhoneMessageModal,
  onClickLink,
  scope,
  onSaveTask,
  view,
  sortBy,
  sortDirection,
  setSort,
  taskViewCounts,
  matterId,
  totalNumberOfTaskPages,
  onTaskListPageChange,
  currentTaskPage,
}: ITasksTabProps) => (
  <div className={classnames('master-detail-panel', Styles.container)}>
    <TaskFilters scope={scope} taskViewCounts={taskViewCounts} loading={loading} isMatterPage={!!matterId} />
    {/* The tasks length is only 0 on the initial load, for fetchMore calls for lazy loading we use the isNextPageLoading property on the infinite table */}
    <div className={classnames('panel-body', Styles.tasksTab)}>
      <div className={classnames('ribbon', 'panel', 'panel-primary')}>
        <Button onClick={onOpenCreateTask}>Add Task</Button>
        <Button onClick={onOpenAddPhoneMessage}>New Phone Message</Button>
        {showAddPhoneMessageModal.show && (
          <AddPhoneMessageModal
            allowMatterSwitching={allowMatterSwitching}
            onClose={onCloseAddPhoneMessageModal}
            scope={scope}
            task={showAddPhoneMessageModal.task}
            completer={showAddPhoneMessageModal.completer}
            matterId={matterId}
          />
        )}
        {showCreateEditModal.show && (
          <AddTaskModal
            allowMatterSwitching={allowMatterSwitching}
            onClose={onCloseCreateEditModal}
            scope={scope}
            task={showCreateEditModal.task}
            completer={showCreateEditModal.completer}
            matterId={matterId}
          />
        )}
      </div>
      <div className={classnames(Styles.tasksTable)}>
        <TaskTable
          tasks={tasks}
          sortBy={sortBy}
          sortDirection={sortDirection}
          setSort={setSort}
          showMatter={showMattersInList}
          onClickTaskRow={onOpenEditTask}
          onClickLink={onClickLink}
          onSaveTask={onSaveTask}
          view={view}
          loading={loading}
        />
        <div className={Styles.paginationSection}>
          <PaginationSelector
            name="tasks"
            numberOfPagesDisplayed={10}
            selectedPage={currentTaskPage}
            totalNumberOfPages={totalNumberOfTaskPages}
            onPageChange={onTaskListPageChange}
          />
        </div>
      </div>
    </div>
  </div>
);

TasksTab.displayName = 'TasksTab';
