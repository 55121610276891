import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { ADD_PAYMENT_MODAL_ID } from 'web/components';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withApolloClient, withReduxProvider } from 'web/react-redux/hocs';
import { debounce } from '@sb-itops/nodash';
import {
  AddPaymentModalBankAccounts,
  AddPaymentModalContactDetails,
  AddPaymentModalData,
  AddPaymentModalInvoiceSummaries,
  BankReconciliationLatestCompletedData,
  InitBankAccountSettings,
  InitTrustChequePrintSettings,
  InitPaymentProviderSettings,
  InitOperatingBankAccount,
  InitStaffSettings,
  InitFirmDetails,
  MatterSummaries,
  MatterSummaryData,
  TrustChequeAvailableNumbers,
} from 'web/graphql/queries';
import {
  useCacheQuery,
  useContactTypeaheadData,
  usePaymentSourcesData,
  useSubscribedLazyQuery,
  useSubscribedQuery,
  useFirmUtbmsSettings,
} from 'web/hooks';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import {
  type as BALANCE_TYPE,
  byName as BALANCE_BY_NAME,
} from '@sb-billing/business-logic/bank-account-settings/entities/constants';
import { getDefaultTrustChequePrintSettings } from '@sb-billing/business-logic/cheques';
import { getLogger } from '@sb-itops/fe-logger';
import { getRegion } from '@sb-itops/region';
import {
  convertSettingsFromGQL,
  isPaymentProviderEnabledForBankAccount,
} from '@sb-billing/business-logic/payment-provider/services';
import { useTranslation } from '@sb-itops/react';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { PAYMENT_TYPE, PAYMENT_SOURCE } from '@sb-billing/business-logic/payment-source';
import {
  paymentMethodTypes,
  paymentMethodTypesByProvider,
} from '@sb-billing/business-logic/payment-provider/entities/constants';
import { paymentMethods as lawpayPaymentMethods } from '@sb-billing/business-logic/payment-provider/services/lawpay';
import { useQuery } from '@apollo/client';
import { todayAsInteger } from '@sb-itops/date';
import { isStatutoryDepositMatter } from '@sb-billing/business-logic/bank-account/services';
import { AddPaymentModalFormsContainer } from './AddPaymentModal.forms.container';

const REGION = getRegion();
const log = getLogger('AddPaymentModal.container');

const hooks = () => ({
  useAddPaymentModalProps: () => {
    const onModalClose = () => setModalDialogHidden({ modalId: ADD_PAYMENT_MODAL_ID });

    return {
      onModalClose,
    };
  },
  useFirmUtbmsSettings: () => {
    const { isUtbmsEnabledForFirm } = useFirmUtbmsSettings();
    return {
      isUtbmsEnabledForFirm,
    };
  },
  useModalBankAccounts: () => {
    const {
      data: bankAccountsData,
      loading: bankAccountsLoading,
      error: bankAccountsError,
    } = useSubscribedQuery(AddPaymentModalBankAccounts, {
      skip: !hasFacet(facets.statutoryDepositMatter),
      variables: {
        bankAccountFilter: {
          accountTypes: ['TRUST'],
        },
      },
    });

    if (bankAccountsError) {
      throw new Error(bankAccountsError);
    }

    // Instead of using all trust acounts, we are interested in only those with Statutory Deposit Matter
    const firmTrustAccountsWithStatutoryDepositMatter = useMemo(
      () =>
        (bankAccountsData?.bankAccounts || []).reduce((acc, bankAccount) => {
          if (bankAccount.statutoryDepositMatterId) {
            acc.push(bankAccount);
          }
          return acc;
        }, []),
      [bankAccountsData],
    );

    return {
      firmTrustAccountsLoading: bankAccountsLoading,
      firmTrustAccountsWithStatutoryDepositMatter,
    };
  },
  useBankReconciliationData: () => {
    const [getBankRecLatestCompleted, bankRecLatestCompletedResults] = useSubscribedLazyQuery(
      BankReconciliationLatestCompletedData,
      {
        variables: {},
      },
    );

    const onFetchBankRecLatestCompleted = ({ bankAccountId }) => {
      if (!bankAccountId) {
        // eslint-disable-next-line no-console
        console.warn('onFetchBankRecLatestCompleted missing bankAccountId');
        return;
      }

      getBankRecLatestCompleted({
        variables: {
          bankAccountId,
        },
      });
    };

    const { bankReconciliationLatestCompletedByBankAccount, bankReconciliationSetup } =
      bankRecLatestCompletedResults?.data || {};

    return {
      bankReconciliationLatestCompletedByBankAccount,
      bankReconciliationSetup,
      bankReconciliationLoading: bankRecLatestCompletedResults.loading,
      onFetchBankRecLatestCompleted,
    };
  },
  useStaffData: () => {
    const { data: staffData } = useCacheQuery(InitStaffSettings.query);
    const loggedInStaffMember = staffData?.loggedInStaff || {};

    return {
      loggedInStaffName: loggedInStaffMember.name,
    };
  },
  useFirmData: () => {
    const { data: firmData } = useCacheQuery(InitFirmDetails.query);
    const firm = firmData?.firm || {};

    return {
      firmName: firm.name,
    };
  },
  useDefaultData: ({ matterId, contactId }) => {
    const {
      data: matterData,
      loading: defaultMatterDataLoading,
      error: matterDataError,
    } = useSubscribedQuery(MatterSummaryData, {
      skip: !matterId,
      variables: {
        id: matterId,
      },
    });

    const {
      data: defaultContactData,
      loading: defaultContactDataLoading,
      error: defaultContactDataError,
    } = useQuery(AddPaymentModalData, {
      skip: !contactId,
      variables: {
        contactId,
      },
    });

    if (matterDataError) {
      throw new Error(matterDataError);
    }
    if (defaultContactDataError) {
      throw new Error(defaultContactDataError);
    }

    return {
      areDefaultsLoading: !!(defaultMatterDataLoading || defaultContactDataLoading),
      defaultMatter: matterData?.matter,
      defaultContact: defaultContactData?.contact,
    };
  },

  useContactDetails: () => {
    const [getContactDetails, contactDetailsResult] = useSubscribedLazyQuery(AddPaymentModalContactDetails, {
      variables: {},
    });

    const onGetPaidByContactDetails = (contactId) => {
      if (!contactId) {
        return;
      }
      getContactDetails({
        variables: { contactId },
      });
    };

    return {
      onGetPaidByContactDetails,
      paidByContactDetailsLoading: contactDetailsResult.loading,
      paidByContactDetails: contactDetailsResult.data?.contact,
    };
  },
  useMatterSummariesData: () => {
    const [getMatterSummaries, matterSummariesResult] = useSubscribedLazyQuery(MatterSummaries, {
      context: { skipRequestBatching: true },
      variables: {
        includeMatterHourlyRate: false,
        filter: {
          matterStatus: ['pending', 'open'],
        },
        limit: 25,
        sort: {
          fieldNames: ['statusOpen', 'matterStarted'],
          directions: ['DESC', 'DESC'],
        },
      },
    });

    const results = matterSummariesResult.data?.matterSearch?.results;

    const matterSummaries = useMemo(() => {
      const summaries = !results?.length
        ? []
        : results.map((matter) => {
            const typeahead = [
              matter.matterNumber,
              matter.clientDisplay,
              matter.otherSideDisplay,
              matter.matterType?.name,
              matter.attorneyResponsible?.name,
              matter.attorneyResponsible?.initials,
              matter.description,
            ];

            const matterStartedISO = matter.matterStarted ? moment(matter.matterStarted, 'YYYYMMDD').toISOString() : '';

            return {
              ...matter,
              display: getMatterDisplay(matter, matter.matterType?.name),
              matterClientNames: matter.clientNames,
              matterStarted: matter.matterStarted ? new Date(matter.matterStarted) : undefined,
              matterStartedISO,
              typeahead: typeahead.filter((m) => m).join(' '),
            };
          });

      return summaries;
    }, [results]);

    const getMatterSummariesBySearchText = debounce(
      (searchText = '') => {
        getMatterSummaries({
          variables: {
            searchText,
            offset: 0,
          },
        });
      },
      300, // wait in milliseconds
      { leading: false },
    );

    const onFetchMatterSummaries = (searchText = '') => {
      // When the matter typeahead (Select) loses focus, it executes this
      // function with an empty string, returning different results.
      if (searchText.length > 2) {
        getMatterSummariesBySearchText(searchText);
      }

      return searchText;
    };

    const onFetchMoreMatterSummaries = async () => {
      if (!matterSummariesResult.data?.matterSearch?.pageInfo?.hasNextPage) {
        return undefined;
      }

      const fetchMoreResults = await matterSummariesResult.fetchMore({
        variables: {
          offset: matterSummariesResult.data.matterSearch.results.length || 0,
        },
      });

      return fetchMoreResults;
    };

    return {
      matterSummaries,
      matterSummariesDataLoading: matterSummariesResult.loading,
      matterSummariesHasMore: matterSummariesResult.data?.matterSearch?.pageInfo?.hasNextPage || false,
      onFetchMatterSummaries,
      onFetchMoreMatterSummaries,
    };
  },
  useContactTypeaheadData: () => {
    // The "debtor" and "paid by" fields both need their own data/functions from the useContactTypeaheadData hook
    //  * This is to ensure independence
    //  * E.g. Searching/results on one field won't affect the other
    const {
      contactOptions: debtorContactOptions,
      contactOptionsDataLoading: debtorContactOptionsDataLoading,
      contactOptionsHasMore: debtorContactOptionsHasMore,
      onFetchContactOptions: onFetchDebtorContactOptions,
      onFetchMoreContactOptions: onFetchMoreDebtorContactOptions,
    } = useContactTypeaheadData();
    const {
      contactOptions: paidByContactOptions,
      contactOptionsDataLoading: paidByContactOptionsDataLoading,
      contactOptionsHasMore: paidByContactOptionsHasMore,
      onFetchContactOptions: onFetchPaidByContactOptions,
      onFetchMoreContactOptions: onFetchMorePaidByContactOptions,
    } = useContactTypeaheadData();

    return {
      // debtor field
      debtorContactOptions,
      debtorContactOptionsDataLoading,
      debtorContactOptionsHasMore,
      onFetchDebtorContactOptions,
      onFetchMoreDebtorContactOptions,
      // Paid By field
      paidByContactOptions,
      paidByContactOptionsDataLoading,
      paidByContactOptionsHasMore,
      onFetchPaidByContactOptions,
      onFetchMorePaidByContactOptions,
    };
  },
  useBankAccountSettingsData: () => {
    const { data: bankAccountSettingsData } = useCacheQuery(InitBankAccountSettings.query);
    const isMatterContactBalanceFirm =
      bankAccountSettingsData?.bankAccountSettings?.bankBalanceType === BALANCE_BY_NAME[BALANCE_TYPE.matterContact];
    const createPDFReceiptOnTrustPayment =
      bankAccountSettingsData?.bankAccountSettings?.createPDFReceiptOnTrustPayment || false;

    return {
      isMatterContactBalanceFirm,
      createPDFReceiptOnTrustPayment,
    };
  },
  useTrustChequePrintSettings: () => {
    const { data: trustChequePrintSettingsData } = useCacheQuery(InitTrustChequePrintSettings.query);
    const allTrustChequePrintSettings = trustChequePrintSettingsData?.trustChequePrintSettings || [];

    const isTrustChequePrintingActiveForBankAccountId = (bankAccountId) => {
      const trustAccountChequeSettings =
        bankAccountId && allTrustChequePrintSettings.find((settings) => settings.id === bankAccountId);

      return (trustAccountChequeSettings || getDefaultTrustChequePrintSettings({ region: REGION })).printingActive;
    };

    return {
      isTrustChequePrintingActiveForBankAccountId,
    };
  },
  usePaymentProviderSettings: () => {
    const { data: paymentProviderSettingsData } = useCacheQuery(InitPaymentProviderSettings.query);

    const { activeProvider, providers } = paymentProviderSettingsData?.paymentProviderSettings || {};
    const activeProviderFormattedSettings = activeProvider
      ? convertSettingsFromGQL({ providerType: activeProvider, formattedSettingsFromGQL: providers?.[activeProvider] })
      : {};

    return {
      activeProviderType: activeProvider,
      activeProviderFormattedSettings,
    };
  },
  useOperatingBankAccount: () => {
    const { data: operatingBankAccountData } = useCacheQuery(InitOperatingBankAccount.query);
    const operatingAccount = operatingBankAccountData?.bankAccounts?.[0];

    return {
      operatingAccount,
    };
  },
  useInvoiceSummaries: () => {
    const [getInvoiceSummaries, invoiceSummariesResult] = useSubscribedLazyQuery(AddPaymentModalInvoiceSummaries, {
      variables: {
        sort: {
          fieldNames: ['dueDate'],
          directions: ['ASC'],
        },
        paymentPlanStatusAsOfDate: todayAsInteger(),
      },
    });

    const results = invoiceSummariesResult.data?.invoiceList?.results;

    const invoiceSummaries = useMemo(() => {
      // In order to display message, that matter/debtor doesn't have any invoices, we need to distingush between
      // no invoices and between "we can't tell if there are any invoices".
      // Therefore, we return 'undefined' if we can't tell (no matterId/debtorID specific) and array of invoices otherwise.
      if (Object.keys(invoiceSummariesResult?.variables?.invoiceFilter || {}).length === 0) {
        return undefined;
      }

      const resultsWithSplitBillingInvoices = (results || []).reduce((acc, invoice) => {
        if (invoice.splitBillingSettings?.isEnabled && invoice.splitBillingSettings?.debtors?.length) {
          const invoiceDebtorTotalsLookup = invoice.debtorTotals.reduce((lookup, debtorTotal) => {
            // eslint-disable-next-line no-param-reassign
            lookup[debtorTotal.debtorId] = debtorTotal;
            return lookup;
          }, {});

          const invoiceDebtorContactLookup = invoice.debtors.reduce((lookup, debtor) => {
            // eslint-disable-next-line no-param-reassign
            lookup[debtor.id] = debtor.contact;
            return lookup;
          }, {});

          for (let i = 0; i < invoice.splitBillingSettings.debtors.length; i += 1) {
            const splitBillingDebtor = invoice.splitBillingSettings.debtors[i];
            const invoiceDebtorTotals = invoiceDebtorTotalsLookup[splitBillingDebtor.debtorId];

            // include only unpaid child invoices
            if (invoiceDebtorTotals.unpaid > 0) {
              acc.push({
                ...invoice,
                totals: {
                  total: invoiceDebtorTotals.total,
                  interest: invoiceDebtorTotals.interest,
                  paid: invoiceDebtorTotals.paid,
                  unpaidExcInterest: invoiceDebtorTotals.unpaidExcInterest,
                  unpaid: invoiceDebtorTotals.unpaid,
                },
                debtorId: splitBillingDebtor.debtorId,
                debtorDisplayName: invoiceDebtorContactLookup[splitBillingDebtor.debtorId]?.displayName,
                invoiceNumberSuffix: splitBillingDebtor.invoiceNumberSuffix,
              });
            }
          }
        } else {
          acc.push(invoice);
        }

        return acc;
      }, []);

      return resultsWithSplitBillingInvoices;
    }, [results, invoiceSummariesResult?.variables?.invoiceFilter]);

    const onFetchInvoiceSummaries = ({ matterId, debtorId } = {}) => {
      const invoiceFilter = {};
      if (matterId) {
        invoiceFilter.matterIds = [matterId];
      }
      if (debtorId) {
        invoiceFilter.debtorIds = [debtorId];
      }
      // We add status only if matterId or debtorId is specified. This will cause that we get no results if we have neither
      if (matterId || debtorId) {
        invoiceFilter.invoiceStatuses = ['FINAL'];
      }

      return getInvoiceSummaries({ variables: { invoiceFilter } });
    };

    return {
      invoiceSummariesLoading: invoiceSummariesResult.loading,
      invoiceSummaries,
      onFetchInvoiceSummaries,
    };
  },
  useTrustChequeData: () => {
    const [getAvailableTrustChequeNumbers, trustChequeNumberResults] = useSubscribedLazyQuery(
      TrustChequeAvailableNumbers,
      {
        context: { skipRequestBatching: true },
        variables: {},
      },
    );

    const onFetchAvailableTrustChequeNumbers = debounce(
      ({ bankAccountId, trustChequeReference }) => {
        if (!bankAccountId) {
          log.warn('onFetchAvailableTrustChequeNumbers missing bankAccountId');
          return;
        }

        if (trustChequeReference && !/^[0-9]+$/.test(trustChequeReference)) {
          log.warn('onFetchAvailableTrustChequeNumbers cannot fetch non-numeric cheque reference');
          return;
        }

        getAvailableTrustChequeNumbers({
          variables: {
            filter: {
              bankAccountId,
              chequeNumberFrom: trustChequeReference,
              quantity: 1,
            },
          },
        });
      },
      300, // wait in milliseconds
      { leading: false },
    );

    const data = trustChequeNumberResults.data?.trustChequeAvailableNumbers;

    const lastTrustChequeNumber = data?.lastChequeNumber;
    const nextTrustChequeNumber = data?.availableChequeNumbers?.length ? data.availableChequeNumbers[0] : undefined;

    return {
      lastTrustChequeNumber,
      nextTrustChequeNumber,
      trustChequeNumberBankAccountId: data?.bankAccountId,
      trustChequeNumberLoading: trustChequeNumberResults.loading,
      onFetchAvailableTrustChequeNumbers,
    };
  },
});

const dependentHooks = () => ({
  useStatutoryDepositMatter: ({ firmTrustAccountsLoading, firmTrustAccountsWithStatutoryDepositMatter }) => {
    const checkIsStatutoryDepositMatter = ({ matterId }) => {
      if (firmTrustAccountsLoading || !matterId) {
        return false;
      }

      return isStatutoryDepositMatter({
        matterId,
        trustBankAccounts: firmTrustAccountsWithStatutoryDepositMatter,
        supportsStatutoryDepositMatter: hasFacet(facets.statutoryDepositMatter),
      });
    };

    return { checkIsStatutoryDepositMatter };
  },
  usePaymentSourcesData: ({
    isMatterContactBalanceFirm,
    activeProviderType,
    activeProviderFormattedSettings,
    operatingAccount,
  }) => {
    const { t } = useTranslation();
    const { paymentSourceOptions, paymentSourceOptionsLoading, onFetchPaymentSourceOptions } = usePaymentSourcesData({
      allowOverdraw: hasFacet(facets.allowOverdraw),
      includeCombined: false,
      includeDirectOptions: true,
      isMatterContactBalanceFirm,
    });

    const onGetPaymentSourceOptions = ({ matterId, effectiveDate } = {}) =>
      onFetchPaymentSourceOptions({
        matterId,
        effectiveDate,
        accountTypes: [bankAccountTypeEnum.TRUST, bankAccountTypeEnum.OPERATING],
      });

    const hasPaymentProviderConfiguredForSource = useCallback(
      (paymentSource) => {
        if (!operatingAccount?.id) {
          return false;
        }

        const getPaymentMethodFromSource = () => {
          // This is used for Lawpay only, other payment providers just ignore paymentMethod.
          // We just add it anyway to avoid check for specific payment provider.
          switch (paymentSource.paymentSource) {
            case PAYMENT_SOURCE.creditCard:
              return lawpayPaymentMethods.CREDIT_CARD;
            case PAYMENT_SOURCE.eCheck:
              return lawpayPaymentMethods.ECHEQUE;
            default:
              return null;
          }
        };

        return isPaymentProviderEnabledForBankAccount({
          formattedProviderSpecificSettings: activeProviderFormattedSettings,
          providerType: activeProviderType,
          bankAccountId: operatingAccount?.id,
          providerSpecificFields: { paymentMethod: getPaymentMethodFromSource() },
        });
      },
      [activeProviderFormattedSettings, activeProviderType, operatingAccount?.id],
    );

    const paymentSourceOptionsMemo = useMemo(() => {
      const paymentSources = [...paymentSourceOptions];

      const supportedPaymentMethodTypes = paymentMethodTypesByProvider[activeProviderType];
      const eChequePaymentSource = {
        label: t('echeque'),
        paymentType: PAYMENT_TYPE.direct,
        paymentSource: PAYMENT_SOURCE.eCheck,
        value: PAYMENT_SOURCE.eCheck,
      };

      if (
        hasFacet(facets.echeque) &&
        !!activeProviderType &&
        (supportedPaymentMethodTypes || []).includes(paymentMethodTypes.DIRECT_DEBIT) &&
        hasPaymentProviderConfiguredForSource(eChequePaymentSource)
      ) {
        paymentSources.push(eChequePaymentSource);
      }

      return paymentSources;
    }, [paymentSourceOptions, activeProviderType, hasPaymentProviderConfiguredForSource, t]);

    return {
      paymentSourceOptions: paymentSourceOptionsMemo,
      paymentSourceOptionsLoading,
      onGetPaymentSourceOptions,
      hasPaymentProviderConfiguredForSource,
    };
  },
});

export const AddPaymentModalContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(dependentHooks)(AddPaymentModalFormsContainer))),
);

AddPaymentModalContainer.propTypes = {
  showModal: PropTypes.bool.isRequired,
  scope: PropTypes.string.isRequired,
  printCheques: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  matterId: PropTypes.string,
  contactId: PropTypes.string,
};

AddPaymentModalContainer.defaultProps = {
  matterId: undefined,
  contactId: undefined,
};

AddPaymentModalContainer.displayName = 'AddPaymentModalContainer';
