// this is based on getPaymentSources in smokeball-billing-web/src/redux/selectors/payment-source.js but is not same
import { useMemo, useCallback, useState } from 'react';
import { PAYMENT_TYPE, getDirectPaymentOptions } from '@sb-billing/business-logic/payment-source';
import { PaymentSourcesOperatingCreditData, PaymentSourcesTrustData } from 'web/graphql/queries';
import {
  bankAccountState,
  bankAccountStateByValue,
  bankAccountTypeEnum,
} from '@sb-billing/business-logic/bank-account/entities/constants';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { useTranslation } from '@sb-itops/react';
import { useSubscribedLazyQuery } from './use-subscribed-lazy-query';

export function usePaymentSourcesData({
  allowOverdraw,
  includeDirectOptions = true,
  isMatterContactBalanceFirm,
  // The "includeCombined" prop is different from the "isMatterContactBalanceFirm" prop
  //  1. isMatterContactBalanceFirm
  //    * A bank account type where funds have both a matter and contact connection (the other type just being a matter type)
  //  2. includeCombined
  //    * A combined payment source is an option to select a bank account that has deposits from multiple contacts
  //    * If selected, the user will then manually allocate the amounts paid by each contact
  //      * E.g. Person 1 pays 60% and person 2 pays 40% of the invoice balance
  //    * This prop controls whether we want to include this "combined" matter contact type bank account in the payment source list (not to be confused by "combined" meaning a matter and contact bank account type)
  //      * E.g. Add payment modal we set this to false, but on the Add invoice payment modal, we set it to true
  includeCombined,
}) {
  const { t } = useTranslation();
  const [lastMatterId, setLastMatterId] = useState();
  const [getPaymentSourcesOperatingCredit, paymentSourcesOperatingCreditResult] = useSubscribedLazyQuery(
    PaymentSourcesOperatingCreditData,
    {
      variables: {
        includeContactBalances: isMatterContactBalanceFirm,
        includeMatterBalances: !isMatterContactBalanceFirm || (isMatterContactBalanceFirm && includeCombined),
      },
    },
  );
  const [getPaymentSourcesTrust, paymentSourcesTrustResult] = useSubscribedLazyQuery(PaymentSourcesTrustData, {
    variables: {
      includeContactBalances: isMatterContactBalanceFirm,
      includeMatterBalances: !isMatterContactBalanceFirm || (isMatterContactBalanceFirm && includeCombined),
      trustBankAccountFilter: {
        checkTransactions: true,
        state: [bankAccountStateByValue[bankAccountState.OPEN]],
      },
    },
  });

  const queryDataOperatingCredit = paymentSourcesOperatingCreditResult.data;
  const queryDataTrust = paymentSourcesTrustResult.data;

  // We memoize the direct options and fullPaymentSourceOptions below so that
  // we can use useEffect in the container to update the selected value if the
  // options have changed.
  //
  // If we are in the process of loading Operating/Credit or Trust accounts
  // we will return the direct options so that the form can be displayed as soon
  // as possible
  const directOptions = useMemo(
    () => (includeDirectOptions ? getDirectPaymentOptions(t) : []),
    [includeDirectOptions, t],
  );

  const paymentSourceOptions = useMemo(() => {
    if (!lastMatterId || !queryDataOperatingCredit || !queryDataTrust) {
      return directOptions;
    }

    const bankAccountOptions = buildOptions({
      queryDataOperatingCredit,
      queryDataTrust,
      includeCombined,
      isMatterContactBalanceFirm,
      allowOverdraw,
      t,
      lastMatterId,
    });

    return directOptions.concat(bankAccountOptions);
  }, [
    lastMatterId,
    queryDataOperatingCredit,
    queryDataTrust,
    t,
    directOptions,
    isMatterContactBalanceFirm,
    allowOverdraw,
    includeCombined,
  ]);

  const onFetchPaymentSourceOptions = useCallback(
    ({
      matterId,
      effectiveDate,
      accountTypes = [bankAccountTypeEnum.TRUST, bankAccountTypeEnum.OPERATING, bankAccountTypeEnum.CREDIT],
    }) => {
      setLastMatterId(matterId);
      if (!matterId) {
        return;
      }

      let includeTrustAccounts = false;
      let includeOperatingOrCreditAccounts = false;
      const operatingOrCreditAccountTypes = [];

      accountTypes.forEach((accountType) => {
        if (accountType === bankAccountTypeEnum.TRUST) {
          includeTrustAccounts = true;
        } else if (accountType === bankAccountTypeEnum.CREDIT || accountType === bankAccountTypeEnum.OPERATING) {
          includeOperatingOrCreditAccounts = true;
          operatingOrCreditAccountTypes.push(accountType);
        }
      });

      if (includeOperatingOrCreditAccounts) {
        getPaymentSourcesOperatingCredit({
          variables: {
            operatingOrCreditBankAccountsFilter: {
              accountTypes: operatingOrCreditAccountTypes, // OPERATING, CREDIT, or both
              state: [bankAccountStateByValue[bankAccountState.OPEN]],
            },
            bankAccountBalanceFilter: { matterId },
          },
        });
      }
      if (includeTrustAccounts) {
        getPaymentSourcesTrust({
          variables: {
            matterId,
            balanceAsOfDate: effectiveDate, // used only if includeTrustAccountsWithBalanceAsOfDate is true
            includeTrustAccountsWithBalanceAsOfDate: !!(effectiveDate && allowOverdraw),
            bankAccountBalanceFilter: { matterId },
          },
        });
      }
    },
    [getPaymentSourcesOperatingCredit, getPaymentSourcesTrust, allowOverdraw],
  );

  return {
    paymentSourceOptions,
    paymentSourceOptionsLoading: paymentSourcesOperatingCreditResult.loading || paymentSourcesTrustResult.loading,
    onFetchPaymentSourceOptions,
  };
}

function buildOptions({
  queryDataOperatingCredit,
  queryDataTrust,
  t,
  includeCombined,
  isMatterContactBalanceFirm,
  allowOverdraw,
  lastMatterId,
}) {
  const { bankAccounts } = queryDataOperatingCredit;
  const { matterBalanceTrust, matterTrustBankAccounts } = queryDataTrust;

  // extract operating and credit accounts
  const { creditAccount, operatingAccount } = (bankAccounts || []).reduce((acc, bankAccount) => {
    if (bankAccount.accountType === bankAccountTypeEnum.OPERATING) {
      acc.operatingAccount = bankAccount;
    } else if (bankAccount.accountType === bankAccountTypeEnum.CREDIT) {
      acc.creditAccount = bankAccount;
    }
    return acc;
  }, {});

  // We need to sort trust accounts by name as they are not sorted by default.
  const trustAccounts = (matterTrustBankAccounts || []).slice().sort((a, b) => {
    const left = getBankAccountName(a, t).toUpperCase();
    const right = getBankAccountName(b, t).toUpperCase();
    if (left < right) {
      return -1;
    }
    if (left > right) {
      return 1;
    }
    return 0;
  });

  const trustAccountsWithBalanceAsOfDate = matterBalanceTrust;

  if (isMatterContactBalanceFirm) {
    return getMatterContactBalanceOptions({
      trustAccounts,
      operatingAccount,
      creditAccount,
      t,
      includeCombined,
      lastMatterId,
    });
  }

  return getMatterBalanceOptions({
    trustAccounts,
    trustAccountsWithBalanceAsOfDate,
    operatingAccount,
    creditAccount,
    t,
    allowOverdraw,
    lastMatterId,
  });
}

function getMatterBalanceOptions({
  trustAccounts,
  trustAccountsWithBalanceAsOfDate,
  operatingAccount,
  creditAccount,
  t,
  allowOverdraw,
  lastMatterId,
}) {
  const matterBalanceAsOfDateByTrustAccountId = (trustAccountsWithBalanceAsOfDate || []).reduce((acc, mb) => {
    acc[mb.bankAccountId] = mb;
    return acc;
  }, {});

  const opts = [];

  // Trust Accounts
  if (Array.isArray(trustAccounts)) {
    trustAccounts.forEach((trustAccount) => {
      const matterTrustBalanceEntity = trustAccount.bankAccountBalances?.matterBalances?.find(
        (mb) => mb.matterId === lastMatterId,
      );
      const matterTrustBalance = matterTrustBalanceEntity?.availableBalance || 0;
      const matterTrustProtectedBalance = matterTrustBalanceEntity?.protectedBalance || 0;
      const trustAccountName = `${getBankAccountName(trustAccount, t)}:`;
      const trustBankAccountId = trustAccount.id;

      // In AU with the overdrawn feature, the trust balance should be updated dynamically
      // on the amount change or the effective date change.
      const matterTrustBalanceAtDate = matterBalanceAsOfDateByTrustAccountId[trustBankAccountId]
        ? matterBalanceAsOfDateByTrustAccountId[trustBankAccountId].availableBalance
        : matterTrustBalance;

      const includeMatterTrust = allowOverdraw ? Number.isFinite(matterTrustBalance) : matterTrustBalance;

      if (includeMatterTrust) {
        opts.push({
          label: `${trustAccountName} ${t('cents', { val: matterTrustBalanceAtDate })}`,
          paymentType: PAYMENT_TYPE.trust,
          balance: matterTrustBalance,
          balanceAtDate: matterTrustBalanceAtDate,
          protectedBalance: matterTrustProtectedBalance,
          value: trustBankAccountId,
          bankAccountId: trustBankAccountId,
          trustToOfficeNumberingSettings: trustAccount.trustToOfficeNumberingSettings,
        });
      }
    });
  }

  // Operating Account
  if (operatingAccount) {
    const matterOperatingBalanceEntity = operatingAccount.bankAccountBalances?.matterBalances?.find(
      (mb) => mb.matterId === lastMatterId,
    );
    const matterOperatingBalance = matterOperatingBalanceEntity?.balance || 0;

    if (matterOperatingBalance) {
      opts.push({
        label: `${t('operatingRetainer')} ${t('cents', { val: matterOperatingBalance })}`,
        paymentType: PAYMENT_TYPE.operating,
        balance: matterOperatingBalance,
        protectedBalance: 0,
        value: PAYMENT_TYPE.operating,
        bankAccountId: operatingAccount.id,
      });
    }
  }

  // Credit Account
  if (creditAccount) {
    const matterCreditBalanceEntity = creditAccount.bankAccountBalances?.matterBalances?.find(
      (mb) => mb.matterId === lastMatterId,
    );
    const matterCreditBalance = matterCreditBalanceEntity?.balance || 0;

    if (matterCreditBalance) {
      opts.push({
        label: `Credit ${t('cents', { val: matterCreditBalance })}`,
        paymentType: PAYMENT_TYPE.credit,
        balance: matterCreditBalance,
        protectedBalance: 0,
        value: PAYMENT_TYPE.credit,
        bankAccountId: creditAccount.id,
      });
    }
  }

  return opts;
}

function getMatterContactBalanceOptions({
  trustAccounts,
  operatingAccount,
  creditAccount,
  t,
  includeCombined,
  lastMatterId,
}) {
  const opts = [];

  // Trust Accounts
  if (Array.isArray(trustAccounts)) {
    trustAccounts.forEach((trustAccount) => {
      const matterTrustBalanceEntity = trustAccount.bankAccountBalances?.matterBalances?.find(
        (mb) => mb.matterId === lastMatterId,
      );
      const matterContactTrustBalances = (trustAccount.bankAccountBalances?.contactBalances || []).filter(
        (cb) => cb.matterId === lastMatterId,
      );

      const matterTrustBalance = matterTrustBalanceEntity?.availableBalance || 0;
      const matterTrustProtectedBalance = matterTrustBalanceEntity?.protectedBalance || 0;
      const trustAccountName = `${getBankAccountName(trustAccount, t)}:`;

      if (includeCombined && matterContactTrustBalances.length > 1 && matterTrustBalance) {
        opts.push({
          label: `${trustAccountName} ${t('cents', { val: matterTrustBalance })} (Total Combined)`,
          paymentType: PAYMENT_TYPE.trust,
          balance: matterTrustBalance,
          protectedBalance: matterTrustProtectedBalance,
          isCombinedBalance: true,
          value: trustAccount.id,
          bankAccountId: trustAccount.id,
          trustToOfficeNumberingSettings: trustAccount.trustToOfficeNumberingSettings,
          payors: buildPayorsData({ matterContactBalances: matterContactTrustBalances }),
        });
      }

      matterContactTrustBalances.forEach((mcb) => {
        opts.push({
          label: `${trustAccountName} ${t('cents', { val: mcb.availableBalance })} (${
            mcb?.contact?.displayName || ''
          })`,
          paymentType: PAYMENT_TYPE.trust,
          balance: mcb.availableBalance,
          protectedBalance: mcb.protectedBalance || 0,
          contactId: mcb.contactId,
          contactDisplayName: mcb?.contact?.displayNameFull || mcb?.contact?.displayName || '',
          value: `${trustAccount.id}-${mcb.contactId}`,
          bankAccountId: trustAccount.id,
          trustToOfficeNumberingSettings: trustAccount.trustToOfficeNumberingSettings,
        });
      });
    });
  }

  // Operating Account
  if (operatingAccount) {
    const matterContactOperatingBalances = (operatingAccount.bankAccountBalances?.contactBalances || []).filter(
      (cb) => cb.matterId === lastMatterId,
    );
    const matterOperatingBalanceEntity = operatingAccount.bankAccountBalances?.matterBalances?.find(
      (mb) => mb.matterId === lastMatterId,
    );
    const matterOperatingBalance = matterOperatingBalanceEntity?.balance || 0;

    if (includeCombined && matterContactOperatingBalances.length > 1 && matterOperatingBalance) {
      opts.push({
        label: `${t('operatingRetainer')} ${t('cents', { val: matterOperatingBalance })} (Total Combined)`,
        paymentType: PAYMENT_TYPE.operating,
        balance: matterOperatingBalance,
        protectedBalance: 0,
        isCombinedBalance: true,
        value: PAYMENT_TYPE.operating,
        bankAccountId: operatingAccount.id,
        payors: buildPayorsData({ matterContactBalances: matterContactOperatingBalances }),
      });
    }

    matterContactOperatingBalances.forEach((mcb) => {
      opts.push({
        label: `${t('operatingRetainer')} ${t('cents', { val: mcb.balance })} (${mcb?.contact?.displayName || ''})`,
        paymentType: PAYMENT_TYPE.operating,
        balance: mcb.balance,
        protectedBalance: 0,
        contactId: mcb.contactId,
        contactDisplayName: mcb?.contact?.displayNameFull || mcb?.contact?.displayName || '',
        value: `${PAYMENT_TYPE.operating}-${mcb.contactId}`,
        bankAccountId: operatingAccount.id,
      });
    });
  }

  // Credit Account
  if (creditAccount) {
    const matterContactCreditBalances = (creditAccount.bankAccountBalances?.contactBalances || []).filter(
      (cb) => cb.matterId === lastMatterId,
    );
    const matterCreditBalanceEntity = creditAccount.bankAccountBalances?.matterBalances?.find(
      (mb) => mb.matterId === lastMatterId,
    );
    const matterCreditBalance = matterCreditBalanceEntity?.balance || 0;

    if (includeCombined && matterContactCreditBalances.length > 1 && matterCreditBalance) {
      opts.push({
        label: `Credit ${t('cents', { val: matterCreditBalance })} (Total Combined)`,
        paymentType: PAYMENT_TYPE.credit,
        balance: matterCreditBalance,
        protectedBalance: 0,
        isCombinedBalance: true,
        value: PAYMENT_TYPE.credit,
        bankAccountId: creditAccount.id,
        payors: buildPayorsData({ matterContactBalances: matterContactCreditBalances }),
      });
    }

    matterContactCreditBalances.forEach((mcb) => {
      opts.push({
        label: `Credit ${t('cents', { val: mcb.balance })} (${mcb?.contact?.displayName || ''})`,
        paymentType: PAYMENT_TYPE.credit,
        balance: mcb.balance,
        protectedBalance: 0,
        contactId: mcb.contactId,
        contactDisplayName: mcb?.contact?.displayNameFull || mcb?.contact?.displayName || '',
        value: `${PAYMENT_TYPE.credit}-${mcb.contactId}`,
        bankAccountId: creditAccount.id,
      });
    });
  }

  return opts;
}

/**
 * Required for split payments on combined source accounts
 */
function buildPayorsData({ matterContactBalances }) {
  const payors = matterContactBalances.map((contactBalance) => ({
    id: contactBalance.contactId,
    available: contactBalance.availableBalance,
    displayName: contactBalance.contact.displayName,
  }));

  return payors;
}
